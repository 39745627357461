import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  MoreButton,
  Spinner,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import ScoreModal from 'components/scores/modal/ScoreModal';
import { getStepContent } from 'components/scores/show/ConfigurationSteps';
import ChannelGuidelinesTable from 'components/scores/table/ChannelGuidelinesTable';
import { sortObjectArray } from 'utilities/array';
import { scoreDataScorePath } from 'utilities/routes';
import styles from './Configuration.module.css';

const propTypes = {
  scoreId: PropTypes.number.isRequired,
};

function Configuration({ scoreId }) {
  const [initialStep, setInitialStep] = useState();
  const [scoreData, setScoreData] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchProps = async () => {
      const response = await fetch(scoreDataScorePath(scoreId, { type: 'configuration' }));

      if (response.ok) {
        const data = await response.json();

        setScoreData(data);
      } else {
        addToast('Something went wrong, please reload to try again.', {
          size: 'large',
          type: 'error',
        });
      }
    };

    fetchProps();
  }, [scoreId]);

  if (!scoreData) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  const {
    canEditSelected,
    canEditWeight,
    description,
    guidelines,
    label,
  } = scoreData;

  const guidelineObject = guidelines.reduce((grouped, guideline) => {
    if (!guideline.isSelected) return grouped;

    const newGrouped = { ...grouped };
    const { channelKey } = guideline;
    if (newGrouped[channelKey]) {
      newGrouped[channelKey].guidelines.push(guideline);
    } else {
      newGrouped[channelKey] = {
        channelKey,
        channelLabel: guideline.channelLabel,
        guidelines: [guideline],
      };
    }

    return newGrouped;
  }, {});
  const selectedGuidelinesByChannel = Object.values(guidelineObject);
  const sortedSelectedGuidelines = sortObjectArray(selectedGuidelinesByChannel, 'channelLabel');
  const hasGuidelines = sortedSelectedGuidelines.length > 0;

  const comboOptions = [];
  if (canEditSelected) {
    comboOptions.push({
      label: 'Add/Remove Guidelines',
      onClick: () => {
        setInitialStep(1);
        setShowModal(true);
      },
    });
  }

  if (canEditWeight && hasGuidelines) {
    comboOptions.push({
      label: 'Edit Guideline Weights',
      onClick: () => {
        setInitialStep(2);
        setShowModal(true);
      },
    });
  }

  return (
    <>
      <Card>
        { comboOptions.length > 0 && (
          <div className={styles.row}>
            <MoreButton options={comboOptions} />
          </div>
        ) }
        { hasGuidelines && (
          <div className={styles.channelTableContainer}>
            { sortedSelectedGuidelines.map(({
              channelKey,
              channelLabel,
              guidelines: channelGuidelines,
            }) => (
              <ChannelGuidelinesTable
                key={channelKey}
                channelKey={channelKey}
                channelLabel={channelLabel}
                guidelines={channelGuidelines}
              />
            )) }
          </div>
        ) }
        { !hasGuidelines && (
          <div className="t-empty">No Guidelines Added</div>
        ) }
      </Card>
      <ScoreModal
        description={description}
        getStepContent={getStepContent}
        guidelines={guidelines}
        initialStep={initialStep}
        isOpen={showModal}
        label={label}
        scoreId={scoreId}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}

Configuration.propTypes = propTypes;

export default Configuration;
