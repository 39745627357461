import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CollapsibleWidget from 'components/molecules/CollapsibleWidget';
import styles from './VideoShots.module.css';

export const propTypes = {
  shots: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number,
      endTime: PropTypes.number,
      startTime: PropTypes.number,
      url: PropTypes.string,
    }),
  ),
};

const defaultProps = {
  shots: [],
};

function VideoShots({ shots }) {
  if (shots.length === 0) return null;

  const headerText = `Video Shots (${shots.length})`;
  const labelClasses = classNames('t-label-1', styles.label);

  return (
    <CollapsibleWidget bodyClassName={styles.videoShots} headerText={headerText}>
      {
        shots.map((shot) => {
          const {
            duration,
            endTime,
            startTime,
            url,
          } = shot;

          return (
            <div key={`${startTime}-${duration}`} className={styles.videoShot}>
              <div className={styles.image}>
                <img alt="Screenshot" src={url} />
              </div>
              <div className={styles.column}>
                <div>
                  <div className={labelClasses}>Time</div>
                  <div>
                    { `${startTime} - ${endTime}` }
                  </div>
                </div>
                <div className="u-marginAbove">
                  <div className={labelClasses}>Duration</div>
                  <div>
                    { duration }
                    s
                  </div>
                </div>
              </div>
            </div>
          );
        })
      }
    </CollapsibleWidget>
  );
}

VideoShots.propTypes = propTypes;
VideoShots.defaultProps = defaultProps;

export default VideoShots;
