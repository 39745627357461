import React from "react"
import Column from "components/reusable/column"
import PropTypes from "prop-types"
import ComparisonDataContainer from "components/containers/comparison_data_container"
import HorizontalGaugeComparisonGroup from "components/comparison/horizontal_gauge_comparison_group"
import Row from "components/reusable/row"

# This is responsible for displaying a comparison of groupings between two groups of posts.
# Data is displayed with a horizontal gauge.
class HorizontalGaugeComparison extends React.Component
  NUM_DATA_POINTS_AT_A_TIME = 10

  @propTypes =
    # Options for looking at metrics. Values should correspond to methods on rows ex. row.value, row.test_view.
    # Ex. [{label: 'Engagement', value: 'value'}, {label: 'Test', value: 'test_view'}...]
    dataViewOptions: PropTypes.array
    left: PropTypes.shape(
      # Rows should have values for dataView options(ex. value, test_view), percent, label, id.
      # If no dataView options are provided, we will use value as the default view.
      rows: PropTypes.array.isRequired,
      title: PropTypes.string.isRequired
    ).isRequired
    metric: PropTypes.string              # Selected metric ex. :engagement, :count
    metricSelect: PropTypes.shape(        # Options for metrics. Should be links at this point.
      selected: PropTypes.string,         # Currently selected link
      options: PropTypes.array            # Label/value pairs where value is a link
    )
    right: PropTypes.shape(
      # Rows should have values for dataView options(ex. value, test_view), percent, label, id.
      # If no dataView options are provided, we will use value as the default view.
      rows: PropTypes.array.isRequired,
      title: PropTypes.string.isRequired
    ).isRequired
    title: PropTypes.string               # Overall title for the comparison

  constructor: (props) ->
    super(props)

    @state =
      dataView: 'value'
      numDataPoints: NUM_DATA_POINTS_AT_A_TIME

    if props.dataViewOptions
      @state['dataView'] = props.dataViewOptions[0].value

  handleDataViewChange: (dataView) =>
    @setState(dataView: dataView)

  handleSeeMoreChange: =>
    @setState(numDataPoints: this.state.numDataPoints + NUM_DATA_POINTS_AT_A_TIME)

  renderHorizontalGaugeComparisonGroup: (side, color) ->
    `<HorizontalGaugeComparisonGroup
      color={ColorUtil.color(color)}
      rows={this.props[side].rows.slice(0, this.state.numDataPoints)}
      metric={this.props.metric}
      title={this.props[side].title}
      view={this.state.dataView}
    />`

  render: ->
    dataViewSelectParams =
      if @props.dataViewOptions
        onChange: @handleDataViewChange
        options: @props.dataViewOptions
        selected: @state.dataView

    seeMoreParams =
      if @props.left.rows.length > @state.numDataPoints || @props.right.rows.length > @state.numDataPoints
        onClick: @handleSeeMoreChange

    `<ComparisonDataContainer
      dataViewSelectParams={dataViewSelectParams}
      metricSelectParams={this.props.metricSelect}
      seeMoreParams={seeMoreParams}
      title={this.props.title}
    >
      <Row>
        <Column size={6}>
          {this.renderHorizontalGaugeComparisonGroup('left', ColorUtil.color('postGroupOrange'))}
        </Column>
        <Column size={6}>
          {this.renderHorizontalGaugeComparisonGroup('right', ColorUtil.color('postGroupBlue'))}
        </Column>
      </Row>
    </ComparisonDataContainer>`

export default HorizontalGaugeComparison
