import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Tooltip,
} from '@makeably/creativex-design-system';
import AuditFilterCollection from 'components/audit/audit_filter_collection.coffee';
import { chartProps as BarChartProps } from 'components/charts/BarChart';
import DoughnutChart, { propTypes as DoughnutChartProps } from 'components/charts/DoughnutChart';
import TabbedChart from 'components/charts/TabbedChart';
import TopLevelStats from 'components/dashboard_components/TopLevelStats';

/* eslint-disable react/forbid-prop-types */
export const propTypes = {
  filters: PropTypes.object.isRequired,
  ageBarGraphs: PropTypes.arrayOf(
    PropTypes.shape({
      chart: PropTypes.shape(BarChartProps),
      dataLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  ageGraph: PropTypes.shape(DoughnutChartProps),
  hasData: PropTypes.bool,
  peopleDataPresent: PropTypes.bool,
  sexBarGraphs: PropTypes.arrayOf(
    PropTypes.shape({
      chart: PropTypes.shape(BarChartProps),
      dataLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  sexGraph: PropTypes.shape(DoughnutChartProps),
  skinToneBarGraphs: PropTypes.arrayOf(
    PropTypes.shape({
      chart: PropTypes.shape(BarChartProps),
      dataLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  skinToneGraph: PropTypes.shape(DoughnutChartProps),
  topLevelStats: PropTypes.shape({
    postPercent: PropTypes.number,
    posts: PropTypes.number,
    spend: PropTypes.string,
    spendPercent: PropTypes.number,
  }),
};
/* eslint-enable react/forbid-prop-types */

const defaultProps = {
  ageBarGraphs: undefined,
  ageGraph: undefined,
  hasData: false,
  peopleDataPresent: undefined,
  sexBarGraphs: undefined,
  sexGraph: undefined,
  skinToneBarGraphs: undefined,
  skinToneGraph: undefined,
  topLevelStats: undefined,
};

function ComboChartCard(props) {
  const {
    barCharts,
    doughnutChart,
    peopleDataPresent,
    tabColor,
  } = props;

  if (peopleDataPresent) {
    return (
      <div className="representationComboChart">
        <DoughnutChart
          chart={doughnutChart}
          hideLegend
        />
        <TabbedChart
          charts={barCharts}
          layoutPadding={65}
          tabColor={tabColor}
          tickPadding={75}
          type="bar"
          bodyContainedTabs
          static
        />
      </div>
    );
  }

  return (
    <div className="t-body-1 u-textAlignCenter">
      No content with people for selected filters
    </div>
  );
}
ComboChartCard.propTypes = {
  barCharts: PropTypes.arrayOf(
    PropTypes.shape({
      chart: PropTypes.shape(BarChartProps),
      dataLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  doughnutChart: PropTypes.shape(DoughnutChartProps).isRequired,
  peopleDataPresent: PropTypes.bool.isRequired,
  tabColor: PropTypes.string,
};
ComboChartCard.defaultProps = {
  tabColor: 'rose',
};

function sigFigsForValue(value) {
  return value < 0.1 ? 1 : 0;
}

function statProps({
  postPercent,
  posts,
  spend,
  spendPercent,
}) {
  return [
    {
      format: 'number',
      label: 'Total Posts',
      value: posts,
    },
    {
      format: 'percent',
      label: 'Posts with People',
      sigFigs: sigFigsForValue(postPercent),
      tooltip: 'This represents the number of posts with at least 1 person. Illustrations or '
             + 'animations of people are not counted towards this total, and a person\'s '
             + 'face must be visible in at least 1 frame of the creative to count (creatives '
             + 'that feature body parts only, such as hands, are excluded from this count).',
      value: postPercent,
    },
    {
      format: 'string',
      label: 'Total Spend',
      value: spend,
    },
    {
      format: 'percent',
      label: 'Spend - People Posts',
      sigFigs: sigFigsForValue(spendPercent),
      tooltip: 'This represents the total spend invested in content that has at least 1 person '
             + 'featured in the image or video.',
      tooltipPosition: 'left',
      value: spendPercent,
    },
  ];
}

function Overview(props) {
  const {
    ageBarGraphs,
    ageGraph,
    filters: {
      authenticityToken,
      existingFilters,
      filterOptions,
      formProps,
      redirectOptions,
      dateRangeProps,
    },
    hasData,
    peopleDataPresent,
    sexBarGraphs,
    sexGraph,
    skinToneBarGraphs,
    skinToneGraph,
    topLevelStats,
  } = props;

  return (
    <div className="representationOverview">
      <AuditFilterCollection
        authenticityToken={authenticityToken}
        dateRangeProps={dateRangeProps}
        existingFilters={existingFilters}
        filterOptions={filterOptions}
        formProps={formProps}
        redirectOptions={redirectOptions}
      />
      { !hasData && (
        <p className="emptyView">{ 'We couldn\'t find any data that matches your filter and/or date criteria.' }</p>
      ) }
      { hasData && (
        <>
          <TopLevelStats stats={statProps(topLevelStats)} />
          <Card>
            <div className="u-flexRow u-spaceBetween u-marginBelowMd">
              <div>
                <div className="u-flexRow">
                  <h5 className="u-marginRightSm u-marginBelowSm">Skin Tone</h5>
                  <Tooltip label="We use the Fitzpatrick Scale to assess skin type, which classifies skin tone into 6 categories based on its response to UV light, with Type I being the lightest and type VI the darkest." />
                </div>
                <div className="t-caption-1">Frequency of people shown by skin type.</div>
              </div>

            </div>
            <ComboChartCard
              barCharts={skinToneBarGraphs}
              doughnutChart={skinToneGraph}
              peopleDataPresent={peopleDataPresent}
              tabColor="beige"
            />
          </Card>
          <Card>
            <div className="u-marginBelowMd">
              <h5 className="u-marginBelowSm">Gender</h5>
              <div className="t-caption-1">Frequency of people shown by gender.</div>
            </div>
            <ComboChartCard
              barCharts={sexBarGraphs}
              doughnutChart={sexGraph}
              peopleDataPresent={peopleDataPresent}
              tabColor="rose"
            />
          </Card>
          <Card>
            <div className="u-marginBelowMd">
              <h5 className="u-marginBelowSm">Age</h5>
              <div className="t-caption-1">Frequency of people shown by age group.</div>
            </div>
            <ComboChartCard
              barCharts={ageBarGraphs}
              doughnutChart={ageGraph}
              peopleDataPresent={peopleDataPresent}
              tabColor="lightblue"
            />
          </Card>
          <div className="push" />
        </>
      ) }
    </div>
  );
}

Overview.propTypes = propTypes;
Overview.defaultProps = defaultProps;

export default Overview;
