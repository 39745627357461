import React from "react"
import Typeahead from "components/reusable/typeahead"
import PropTypes from "prop-types"

# This component is responsible for generating a typeahead for a GuidelineDetail form.
class GuidelineTypeahead extends React.Component
  @propTypes =
    fetchUrl: PropTypes.string
    inputName: PropTypes.string
    selected: PropTypes.arrayOf(
      PropTypes.shape(
        icon: null, # presenter add_option adds an icon prop that is nil by default; unused here
        label: PropTypes.string,
        value: PropTypes.string
      )
    )

  constructor: (props) ->
    super(props)
    @state =
      isLoading: false
      selectedTags: @props.selected || []
      tagOptions: []

  onSearch: (query) =>
    @setState(isLoading: true)
    fetch(
      @props.fetchUrl + "?q=#{query}"
    ).then((resp) ->
      resp.json()
    ).then((json) =>
      @setState(tagOptions: json.data, isLoading: false)
    )

  handleTypeaheadChange: (selectedTags) =>
    @setState(selectedTags: selectedTags)

  render: ->
    # Only need to submit the tag name with the form
    formValues = _.pluck(@state.selectedTags, 'value')

    `<Typeahead
      bodyContainer={true}
      formValue={formValues}
      inputName={this.props.inputName}
      isLoading={this.state.isLoading}
      onChange={this.handleTypeaheadChange}
      onSearch={this.onSearch}
      options={this.state.tagOptions}
      placeholder="Select visual tags"
      paginationText="Display additional visual tags"
      selected={this.state.selectedTags}
    />`

export default GuidelineTypeahead
