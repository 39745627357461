import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
} from '@makeably/creativex-design-system';
import MediaSpendFilter, { mediaSpendReportProps } from 'components/contract/MediaSpendFilter';
import MediaSpendVisualization from 'components/contract/MediaSpendVisualization';
import ReportTags from 'components/reporting/ReportTags';
import StringHelper from 'components/utils/StringHelper';
import { getObjFilterTest } from 'utilities/filtering';
import { removeProperty } from 'utilities/object';
import { toSpend } from 'utilities/string';
import styles from './MediaSpendUsage.module.css';
import {
  getMonthsArray,
  getMonthBins,
} from './utilities';

const propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  mediaSpendCap: PropTypes.number,
  mediaSpendReports: PropTypes.arrayOf(mediaSpendReportProps),
};

const defaultProps = {
  endDate: undefined,
  mediaSpendCap: undefined,
  mediaSpendReports: [],
};

const segments = [
  {
    label: 'Market',
    value: 'marketName',
  },
  {
    label: 'Brand',
    value: 'brandName',
  },
  {
    label: 'Channel',
    value: 'channelName',
  },
  {
    label: 'Guideline',
    value: 'guidelines',
  },
  {
    label: 'Asset\nType',
    value: 'assetType',
  },
];

function getMonthItem(spendBins, month) {
  const spend = spendBins[month] ?? 0;

  return {
    month: { value: month },
    totalSpend: {
      label: toSpend(spend),
      value: spend,
    },
  };
}

function getItems(mediaSpendReports, startDate, endDate) {
  const months = getMonthsArray(startDate, endDate);
  const spendBins = getMonthBins(mediaSpendReports, 'spend');

  return months.map((month) => getMonthItem(spendBins, month));
}

function MediaSpendUsage({
  endDate: endDateStr,
  mediaSpendCap,
  mediaSpendReports,
  startDate: startDateStr,
}) {
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [filteredMediaSpendReports, setMediaSpendReports] = useState(mediaSpendReports);
  const [mediaSpendTotal, setMediaSpendTotal] = useState(0);
  const startDate = StringHelper.stringToDate(startDateStr);
  const endDate = StringHelper.stringToDate(endDateStr);

  useEffect(() => {
    const filterTest = getObjFilterTest(currentFilters);
    setMediaSpendReports(mediaSpendReports.filter(filterTest));
  }, [mediaSpendReports, currentFilters]);

  useEffect(() => {
    setItems(getItems(filteredMediaSpendReports, startDate, endDate));
  }, [filteredMediaSpendReports]);

  useEffect(() => {
    const totalSpend = filteredMediaSpendReports.reduce((total, report) => total + report.spend, 0);
    setMediaSpendTotal(totalSpend);
  }, [filteredMediaSpendReports]);

  const removeCurrentFilter = (key) => {
    setCurrentFilters((last) => removeProperty(last, key));
  };

  return (
    <Card padding={false}>
      <div className={styles.controls}>
        <div>
          <MediaSpendFilter
            isOpen={filterOpen}
            mediaSpendReports={mediaSpendReports}
            segments={segments}
            selections={currentFilters}
            onClose={() => setFilterOpen(false)}
            onOpen={() => setFilterOpen(true)}
            onSelectionsChange={setCurrentFilters}
          />
        </div>
        <ReportTags
          filters={currentFilters}
          removeFilter={removeCurrentFilter}
          segments={segments}
          onFilterClick={() => setFilterOpen(true)}
        />
      </div>
      <Divider />
      <MediaSpendVisualization
        endDate={endDateStr}
        hasFilters={Object.keys(currentFilters).length > 0}
        items={items}
        mediaSpendCap={mediaSpendCap}
        mediaSpendTotal={mediaSpendTotal}
        startDate={startDateStr}
      />
    </Card>
  );
}

MediaSpendUsage.propTypes = propTypes;
MediaSpendUsage.defaultProps = defaultProps;

export default MediaSpendUsage;
