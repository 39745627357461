// Helper functions for formatting item values into text
// Any way that keyed values are traslated to text on the site should be captured here
// (i.e. channel names, names of categories)
// This will serve as a repository for all text translations, and keep them consistent site-wide
//
// To add a new format, add a formatItemXXX function and call it in formatItemText
import { updateValues } from 'utilities/object';

// expects values in the form 2022_05_21
// TODO add times: 2022_11_07:17_07_32
function formatItemDate(value) {
  const parts = value.split('_');
  if (parts.length !== 3) {
    return 'Unknown Date';
  }
  const year = parseInt(parts[0]);
  const monthIndex = parseInt(parts[1]) - 1;
  const day = parseInt(parts[2]);

  const date = new Date(year, monthIndex, day);
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-US', options);
}

function formatItemCurrency(value, fractionDigits) {
  const maximumFractionDigits = fractionDigits ? 2 : 0;

  const options = {
    currency: 'USD',
    maximumFractionDigits,
    style: 'currency',
  };

  const formatter = new Intl.NumberFormat('en-US', options);

  return formatter.format(value);
}

function formatItemNumber(value) {
  const options = {
    style: 'decimal',
    maximumFractionDigits: 0,
  };

  const formatter = new Intl.NumberFormat('en-US', options);

  return formatter.format(value);
}

export function formatItemText(value, format) {
  switch (format) {
    case 'date':
      return formatItemDate(value);
    case 'currency':
      return formatItemCurrency(value, false);
    case 'decimalCurrency':
      return formatItemCurrency(value, true);
    case 'number':
      return formatItemNumber(value);
    default:
      throw new Error('formatItemText: Unknown format');
  }
}

export function setItemText(item) {
  const setText = (data) => {
    if (data?.value !== undefined && data?.format !== undefined) {
      return {
        ...data,
        text: formatItemText(data.value, data.format),
      };
    }
    return data;
  };

  return updateValues(item, setText);
}

export function setItemsText(items) {
  return items.map((item) => setItemText(item));
}
