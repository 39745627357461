import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { MessageModal } from '@makeably/creativex-design-system';
import PercentInput from 'components/admin/matching_configs/PercentInput';
import { titleize } from 'utilities/string';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  assetType: PropTypes.string,
  brandId: PropTypes.number,
  brandName: PropTypes.string,
  companyId: PropTypes.number,
  id: PropTypes.number,
  threshold: PropTypes.number,
};

const defaultProps = {
  assetType: undefined,
  brandId: undefined,
  brandName: undefined,
  companyId: undefined,
  id: undefined,
  threshold: undefined,
};

function ThresholdModal({
  assetType,
  brandId,
  brandName,
  companyId,
  id,
  isOpen,
  onClose,
  onSave,
  threshold,
}) {
  const [currentThreshold, setCurrentThreshold] = useState();

  useEffect(() => {
    setCurrentThreshold(threshold);
  }, [threshold]);

  const handleSave = () => {
    const data = {
      assetType,
      companyId,
      brandId,
      id,
      threshold: currentThreshold,
    };

    onSave(data);
    onClose();
  };

  return (
    <MessageModal
      actionButtonDisabled={currentThreshold === threshold}
      actionButtonLabel="Set Threshold"
      isOpen={isOpen}
      title={`Set Minimum Threshold Percentage: ${brandName}`}
      onActionButtonClick={handleSave}
      onClose={onClose}
    >
      <PercentInput
        label={`${titleize(assetType)} Threshold (0 - 100)`}
        value={currentThreshold ?? 0}
        onChange={(value) => setCurrentThreshold(value)}
      />
    </MessageModal>
  );
}

ThresholdModal.propTypes = propTypes;
ThresholdModal.defaultProps = defaultProps;

export default ThresholdModal;
