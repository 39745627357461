import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
} from '@makeably/creativex-design-system';
import EvaluationPage, { formName } from 'components/admin/representation/EvaluationPage';
import {
  adminRepresentationIndexPath,
  adminRepresentationPath,
} from 'utilities/routes';
import styles from './EvaluationEdit.module.css';

const propTypes = {
  id: PropTypes.number.isRequired,
  organization: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
  unreviewableReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function EvaluationModal({
  id,
  organization,
  organizationId,
  unreviewableReasons,
}) {
  const [formIncomplete, setFormIncomplete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleEvaluate = async () => {
    const formData = new FormData(document[formName]);

    try {
      setIsLoading(true);
      setShowError(false);
      const response = await fetch(adminRepresentationPath(id), {
        method: 'PATCH',
        body: formData,
      });

      if (response.ok) {
        const success = await response.json();

        if (success) {
          window.location.href = adminRepresentationIndexPath({
            org_id: organizationId,
            state: 'needs_review',
          });
        } else {
          setShowError(true);
        }
      } else {
        setShowError(true);
      }
    } catch (error) {
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      { showError && <h4 className={styles.error}>Evaluation Error: Please Try Again</h4> }
      <EvaluationPage
        id={id}
        organization={organization}
        setFormIncomplete={setFormIncomplete}
        setLoading={setIsLoading}
        showError={() => setShowError(true)}
        unreviewableReasons={unreviewableReasons}
      />
      <div className={styles.actions}>
        <Button
          disabled={isLoading || formIncomplete}
          label="Evaluate"
          onClick={handleEvaluate}
        />
      </div>
    </Card>
  );
}

EvaluationModal.propTypes = propTypes;
export default EvaluationModal;
