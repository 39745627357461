import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  MessageModal,
  TextArea,
  TextInput,
} from '@makeably/creativex-design-system';
import { track } from 'utilities/mixpanel';
import styles from './EditReportDetailsModal.module.css';

const MAX_NAME_LENGTH = 50;
const MAX_DESCRIPTION_LENGTH = 100;

const propTypes = {
  description: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onReportEdit: PropTypes.func.isRequired,
};

function EditReportDetailsModal({
  description: initialDescription,
  isOpen,
  title: initialTitle,
  onClose,
  onReportEdit,
}) {
  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);

  useEffect(() => {
    if (isOpen) {
      setDescription(initialDescription);
      setTitle(initialTitle);
    }
  }, [isOpen]);

  const setDetails = () => {
    onReportEdit({
      description,
      title,
    });
    onClose();

    if (title !== initialTitle || description !== initialDescription) {
      track('update_report_name_and_description', {
        title,
        description,
      });
    }
  };

  const containerClasses = classNames(
    'u-flexColumn',
    styles.input,
  );

  return (
    <MessageModal
      actionButtonDisabled={title === ''}
      actionButtonLabel="Update"
      isOpen={isOpen}
      size="custom"
      title="Edit Name and Description"
      onActionButtonClick={setDetails}
      onClose={onClose}
    >
      <div className="u-flexColumn u-gap-24">
        <div className={containerClasses}>
          <TextInput
            label="Name"
            maxLength={MAX_NAME_LENGTH}
            value={title}
            onChange={setTitle}
          />
          <div className="t-caption-1 u-flexRow u-justifyEnd">
            { `${title.length}/${MAX_NAME_LENGTH}` }
          </div>
        </div>
        <div className="u-flexColumn">
          <TextArea
            label="Description"
            maxLength={MAX_DESCRIPTION_LENGTH}
            placeholder="Optional"
            size="large"
            value={description}
            onChange={setDescription}
          />
          <div className="t-caption-1 u-flexRow u-justifyEnd">
            { `${description.length}/${MAX_DESCRIPTION_LENGTH}` }
          </div>
        </div>
      </div>
    </MessageModal>
  );
}

EditReportDetailsModal.propTypes = propTypes;

export default EditReportDetailsModal;
