// Displays all of the post and creative related information on the evaluation page
// Includes things such as:
//  - Post Copy
//  - Image/Video Player
//  - Computer Vision Results
//  - Creative Details
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import EvaluationGridSelector, { defaultGridOptions } from 'components/admin/EvaluationGridSelector';
import Asset from 'components/audit/EvaluationPageAsset';
import CollapsibleWidget from 'components/reusable/collapsible_widget.coffee';

const ASSET_WIDTH = 300;
const THUMBNAIL_WIDTH = 200;

const textScreenShotType = PropTypes.shape({
  instructions: PropTypes.string,
  screenshots: PropTypes.arrayOf(
    PropTypes.shape({
      screenshot: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
});

export const propTypes = {
  altTextEnabled: PropTypes.bool.isRequired,
  creativeDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
  isVideo: PropTypes.bool.isRequired,
  ocrTags: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.number,
      text: PropTypes.string,
    }),
  ).isRequired,
  preflight: PropTypes.bool.isRequired,
  altText: PropTypes.string,
  audioTranscription: PropTypes.shape({
    language: PropTypes.string,
    transcription: PropTypes.string,
  }),
  campaignDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ),
  creativeUrl: PropTypes.string,
  platformDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
  ),
  postCopy: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string,
      version: PropTypes.number,
    }),
  ),
  postUrl: PropTypes.string,
  textScreenshotsSection: PropTypes.shape({
    contrast: textScreenShotType,
    height: textScreenShotType,
  }),
  thumbnail: PropTypes.shape({
    ocrTags: PropTypes.arrayOf(
      PropTypes.shape({
        startTime: PropTypes.number,
        text: PropTypes.string,
      }),
    ),
    url: PropTypes.string,
  }),
  videoShots: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number,
      endTime: PropTypes.number,
      startTime: PropTypes.number,
      url: PropTypes.string,
    }),
  ),
};

const defaultProps = {
  altText: undefined,
  audioTranscription: undefined,
  campaignDetails: [],
  creativeUrl: undefined,
  platformDetails: [],
  postCopy: [],
  postUrl: undefined,
  textScreenshotsSection: ({
    contrast: ({
      instructions: undefined,
      screenshots: undefined,
    }),
    height: ({
      instructions: undefined,
      screenshots: undefined,
    }),
  }),
  thumbnail: undefined,
  videoShots: [],
};

function PostCopy({ copyVersions }) {
  return (
    <CollapsibleWidget className="evalPageSection" headerText="Post Copy" isCollapsed>
      {
        copyVersions.map(({ copy, version }) => {
          let versionContent;
          if (version) versionContent = <div className="asLabel">{ `Version ${version}` }</div>;

          return (
            <div key={version || 1} className="evalPageSection-postCopy">
              { versionContent }
              <div>{ copy }</div>
            </div>
          );
        })
      }
    </CollapsibleWidget>
  );
}
PostCopy.propTypes = {
  copyVersions: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string,
      version: PropTypes.number,
    }),
  ).isRequired,
};

function AltText({ text }) {
  return (
    <CollapsibleWidget className="evalPageSection" headerText="Alt text" isCollapsed>
      { text }
    </CollapsibleWidget>
  );
}

AltText.propTypes = {
  text: PropTypes.string.isRequired,
};

function renderOcrTag({ startTime, text }, key) {
  return (
    <div key={key} className="evalPageSection-ocrTag">
      <span>{ text }</span>
      <span>{ startTime }</span>
    </div>
  );
}

function ThumbnailInfo({ thumbnail }) {
  if (!thumbnail) return null;

  const {
    ocrTags,
    url,
  } = thumbnail;

  let thumbnailTags;
  if (ocrTags && ocrTags.length > 0) {
    thumbnailTags = (
      <CollapsibleWidget className="evalPageSection" headerText="Thumbnail OCR" isCollapsed>
        { ocrTags.map((tag, idx) => renderOcrTag(tag, idx)) }
      </CollapsibleWidget>
    );
  }

  return (
    <div>
      <CollapsibleWidget className="evalPageSection" headerText="Thumbnail Image">
        <img alt="Thumbnail" src={url} width={THUMBNAIL_WIDTH} />
      </CollapsibleWidget>
      { thumbnailTags }
    </div>
  );
}
ThumbnailInfo.propTypes = {
  thumbnail: PropTypes.shape({
    ocrTags: PropTypes.arrayOf(
      PropTypes.shape({
        startTime: PropTypes.number,
        text: PropTypes.string,
      }),
    ),
    url: PropTypes.string,
  }),
};
ThumbnailInfo.defaultProps = { thumbnail: undefined };

function AudioTranscription({ audioTranscription }) {
  if (!audioTranscription) return null;

  const {
    language,
    transcription,
  } = audioTranscription;

  return (
    <CollapsibleWidget className="evalPageSection" headerText="Audio Tags" isCollapsed>
      <div className="asLabel">Language</div>
      <div className="u-marginBelow">
        { language }
      </div>
      <div className="asLabel">Transcription</div>
      <div>
        { transcription }
      </div>
    </CollapsibleWidget>
  );
}
AudioTranscription.propTypes = {
  audioTranscription: PropTypes.shape({
    language: PropTypes.string,
    transcription: PropTypes.string,
  }),
};
AudioTranscription.defaultProps = { audioTranscription: undefined };

function OcrTags({ tags }) {
  if (!tags.length > 0) return null;

  return (
    <CollapsibleWidget className="evalPageSection" headerText="OCR Tags" isCollapsed>
      { tags.map((tag, idx) => renderOcrTag(tag, idx)) }
    </CollapsibleWidget>
  );
}
OcrTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.number,
      text: PropTypes.string,
    }),
  ).isRequired,
};

function TextScreenshots({
  header, instructions, screenshots,
}) {
  if (screenshots == null) return null;
  if (screenshots.length === 0) return null;

  return (
    <CollapsibleWidget className="evalPageSection" headerText={header} isCollapsed>
      <div className="evalPageSection-TextHeight">
        <div className="evalPageSection-ScreenshotInstructions">
          { instructions }
        </div>
        {
          screenshots.map((screenshot) => (
            <div key={screenshot.screenshot} className="u-marginBelow">
              <img alt="Screenshot" src={screenshot.screenshot} />
              <span>{ ` ${screenshot.text}` }</span>
            </div>
          ))
        }
      </div>
    </CollapsibleWidget>
  );
}
TextScreenshots.propTypes = {
  header: PropTypes.string,
  instructions: PropTypes.string,
  screenshots: PropTypes.arrayOf(
    PropTypes.shape({
      screenshot: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};
TextScreenshots.defaultProps = {
  header: '',
  instructions: '',
  screenshots: [],
};

function CreativeDetails({ details }) {
  return (
    <CollapsibleWidget className="evalPageSection" headerText="Creative Details" isCollapsed>
      {
        details.map(({ label, value }) => (
          <div key={`${label}-${value}`} className="evalPageSection-creativeDetail">
            <div className="asLabel">{ label }</div>
            <div>{ value }</div>
          </div>
        ))
      }
    </CollapsibleWidget>
  );
}
CreativeDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
};

function VideoShots({ isVideo, shots }) {
  if (!isVideo) return null;

  const headerText = `Video Shots (${shots.length})`;

  return (
    <CollapsibleWidget className="evalPageSection" headerText={headerText} isCollapsed>
      <div className="evalPageSection-videoShots">
        {
          shots.map((shot) => {
            const {
              duration,
              endTime,
              startTime,
              url,
            } = shot;

            return (
              <div key={`${startTime}-${duration}`} className="evalPageSection-videoShot">
                <div className="evalPageSection-img">
                  <img alt="Screenshot" src={url} />
                </div>
                <div className="u-flexColumn">
                  <div>
                    <div className="asLabel">Time</div>
                    <div>
                      { `${startTime} - ${endTime}` }
                    </div>
                  </div>
                  <div className="u-marginAbove">
                    <div className="asLabel">Duration</div>
                    <div>
                      { duration }
                      s
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </CollapsibleWidget>
  );
}
VideoShots.propTypes = {
  isVideo: PropTypes.bool.isRequired,
  shots: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number,
      endTime: PropTypes.number,
      startTime: PropTypes.number,
      url: PropTypes.string,
    }),
  ),
};
VideoShots.defaultProps = { shots: [] };

function renderCampaignDetails(campaignDetails) {
  if (campaignDetails.length === 0) return null;

  return (
    <CollapsibleWidget
      className="evalPageSection"
      headerText="Campaign Details"
      isCollapsed
    >
      {
        campaignDetails.map(({ label, value }) => (
          <div key={`${label}-${value}`} className="evalPageSection-campaignDetail">
            <div className="asLabel">{ label }</div>
            <div>{ value }</div>
          </div>
        ))
      }
    </CollapsibleWidget>
  );
}

function renderPlatformDetails(platformDetails) {
  if (platformDetails.length === 0) return null;

  const renderValue = (label, value) => {
    if (typeof value === 'string') return <div>{ value }</div>;

    return value.sort().map((v) => <div key={`${label}-${v}`}>{ v }</div>);
  };

  return (
    <CollapsibleWidget
      className="evalPageSection"
      headerText="Platform Details"
      isCollapsed
    >
      {
        platformDetails.map(({ label, value }) => (
          <div key={`${label}`} className="evalPageSection-platformDetail">
            <div className="asLabel">{ label }</div>
            { renderValue(label, value) }
          </div>
        ))
      }
    </CollapsibleWidget>
  );
}

function PostInfo({
  altText,
  audioTranscription,
  campaignDetails,
  creativeDetails,
  creativeUrl,
  isVideo,
  platformDetails,
  ocrTags,
  postCopy,
  postUrl,
  preflight,
  textScreenshotsSection,
  thumbnail,
  altTextEnabled,
  videoShots,
}) {
  const [gridOptions, setGridOptions] = useState(defaultGridOptions);

  return (
    <>
      { !preflight && <PostCopy copyVersions={postCopy} /> }
      { preflight && altTextEnabled && altText !== '' && <AltText text={altText} /> }
      <ThumbnailInfo thumbnail={thumbnail} />
      <Asset
        className="evalPageSection"
        gridOptions={gridOptions}
        isVideo={isVideo}
        sourceUrl={creativeUrl}
        width={ASSET_WIDTH}
      />
      <EvaluationGridSelector
        callback={setGridOptions}
        gridOptions={gridOptions}
      />
      <div className="u-marginBelow">
        { postUrl && (
          <a className="u-marginRight" href={postUrl} rel="noreferrer" target="_blank">
            <Icon color="grey" name="externalLink" />
          </a>
        ) }
        { creativeUrl && (
          <a href={creativeUrl} rel="noreferrer" target="_blank">
            <Icon color="grey" name="download" />
          </a>
        ) }
      </div>
      <OcrTags tags={ocrTags} />
      <TextScreenshots
        header="Text Contrast"
        instructions={textScreenshotsSection.contrast.instructions}
        screenshots={textScreenshotsSection.contrast.screenshots}
      />
      <TextScreenshots
        header="Text Height"
        instructions={textScreenshotsSection.height.instructions}
        screenshots={textScreenshotsSection.height.screenshots}
      />
      <AudioTranscription audioTranscription={audioTranscription} />
      { renderCampaignDetails(campaignDetails) }
      <CreativeDetails details={creativeDetails} />
      { renderPlatformDetails(platformDetails) }
      <VideoShots
        isVideo={isVideo}
        shots={videoShots}
      />
    </>
  );
}

PostInfo.propTypes = propTypes;
PostInfo.defaultProps = defaultProps;

export default PostInfo;
