import React from "react"
import PropTypes from "prop-types"

# A higher order component that is responsible for rendering forms
FormContainer = (Component) -> class extends React.Component
  @propTypes =
    authenticityToken: PropTypes.string
    componentProps: PropTypes.object.isRequired
    formId: PropTypes.string.isRequired
    formMethod: PropTypes.string.isRequired
    formUrl: PropTypes.string.isRequired

  renderAuthenticityToken: ->
    return unless @props.authenticityToken
    `<input type="hidden" name="authenticity_token" value={this.props.authenticityToken}/>`

  render: ->
    # NB: For patch forms, we need the form method to be post
    #     This is so the form works properly with rails
    formMethod = if @props.formMethod is 'patch' then 'post' else @props.formMethod

    `<form
      action={this.props.formUrl}
      className={this.props.className}
      id={this.props.formId}
      method={formMethod}
    >
      <input type="hidden" name="_method" value={this.props.formMethod}/>
      {this.renderAuthenticityToken()}
      <Component {...this.props.componentProps} />
    </form>`

export default FormContainer
