import React from "react"
import Column from "components/reusable/column"
import PostAssets from "components/reusable/post_assets"
import Row from "components/reusable/row"
import PropTypes from "prop-types"

# This component is used to display multiple groups of post assets.
# Ex. If you display 10 posts per channel.
#
# TODO: Extract CSS to avoid dependency on existing analyticsPosts CSS... probably also move haml to use this.
#       CSS location: stylesheets/analytics/_posts_overview.scss
#       HAML location: views/analytics/posts/index.html.haml
class AssetGroups extends React.Component
  @propTypes =
    groups: PropTypes.arrayOf(
      PropTypes.shape(
        assets: PropTypes.array # See PostAssets posts field for details
        label: PropTypes.string
      )
    )
    noAssetsMessage: PropTypes.string # Message to display if no assets appear for group


  renderAssets: (assets) ->
    if assets && assets.length > 0
      `<PostAssets className='analyticsPosts-assets'
                   centered={false}
                   posts={assets}
                   key={_.uniqueId("post-assets")}
      />`
    else
      `<span>
        {this.props.noAssetsMessage}
      </span>`


  renderAssetGroup: (group) =>
    assets = this.renderAssets(group.assets)
    `<Row className='analyticsPosts-channelRow'
          key={_.uniqueId('assetGroupsRow-')}
          styleName='valign-wrapper no-margin'
    >
      <Column size={1}>
        {group.label}
      </Column>
      <Column size={11}>
        {assets}
      </Column>
    </Row>`

  render: ->
    `<div>
      {this.props.groups.map(this.renderAssetGroup)}
    </div>`

export default AssetGroups
