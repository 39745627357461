import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Icon,
  Tag,
  Tooltip,
} from '@makeably/creativex-design-system';
import { scorePath } from 'utilities/routes';
import {
  titleize,
  toPercent,
} from 'utilities/string';
import styles from './ScoreDetails.module.css';

const processingTooltip = 'Our system is calculating your new score configuration and will \n'
  + 'publish it after completion. Changes to the number of guidelines \n'
  + 'and their weights are prohibited during processing.';

export const scoreProps = {
  createdAt: PropTypes.string.isRequired,
  description: PropTypes.string,
  guidelineCount: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  isDefault: PropTypes.bool.isRequired,
  isStandard: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  scoreType: PropTypes.string,
  status: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};

const propTypes = {
  ...scoreProps,
  description: PropTypes.string,
  showDetailsButton: PropTypes.bool,
  showDivider: PropTypes.bool,
  titleContent: PropTypes.node,
};

const defaultProps = {
  description: undefined,
  showDetailsButton: false,
  showDivider: false,
  titleContent: undefined,
};

function getProcessingCopy(stateCounts) {
  const pending = stateCounts?.pending ?? 0;
  const total = stateCounts?.total ?? 0;
  if (total === 0 || total === pending) {
    return 'Calculating';
  }

  return `Calculating (${toPercent((total - pending) / total)})`;
}

function statusCopy(status, stateCounts) {
  switch (status) {
    case 'processing':
      return getProcessingCopy(stateCounts);
    default:
      return titleize(status);
  }
}

function ScoreDetails({
  createdAt,
  description,
  guidelineCount,
  id,
  isDefault,
  isStandard,
  label,
  showDetailsButton,
  showDivider,
  status,
  titleContent,
  updatedAt,
  stateCounts,
}) {
  return (
    <>
      <div className={styles.cardHeaderRow}>
        <div className={styles.scoreLabel}>
          { isStandard && (
            <div className="u-marginRight-8">
              <Icon color="current" name="cxIcon" />
            </div>
          ) }
          <h5>{ label }</h5>
          { isStandard && <div className={styles.trademark}>&trade;</div> }
          { isDefault && (
            <div className="u-marginLeft-8">
              <Tag label="Default" />
            </div>
          ) }
        </div>
        { titleContent }
      </div>
      <div className="t-body-1">{ description }</div>
      { showDivider && <Divider margin /> }
      <div className={styles.detailsRow}>
        <div className={styles.detailsContainer}>
          <div className={styles.detailItem}>
            <div className="t-caption-1">Guidelines</div>
            <div className="t-body-1">{ guidelineCount }</div>
          </div>
          <div className={styles.detailItem}>
            <div className="t-caption-1">Created</div>
            <div className="t-body-1">{ createdAt }</div>
          </div>
          <div className={styles.detailItem}>
            <div className="t-caption-1">Updated</div>
            <div className="t-body-1">{ updatedAt }</div>
          </div>
          <div className={styles.detailItem}>
            <div className="t-caption-1">Status</div>
            <div className={styles.tooltipContainer}>
              <div className="t-body-1">{ statusCopy(status, stateCounts) }</div>
              { status === 'processing' && <Tooltip label={processingTooltip} /> }
            </div>
          </div>
        </div>
        { showDetailsButton && (
          <Button
            iconRight="arrowRight"
            label="View Details"
            url={scorePath(id)}
            variant="tertiary"
          />
        ) }
      </div>
    </>
  );
}

ScoreDetails.propTypes = propTypes;
ScoreDetails.defaultProps = defaultProps;

export default ScoreDetails;
