import { addToast } from 'components/organisms/Toasts';
import {
  apiGet,
  apiPost,
  apiPatch,
  apiDestroy,
} from 'utilities/requests';

// A wrapper for api requests (from controllers extending Api::BaseController).
// Displays toast messages on success/failure.
// Optionally uses the errors array passed back from the controller for providing error toasts.

const defaultOptions = {
  showToasts: true,
  successMessage: 'Success!',
  failureMessage: 'Unknown error!',

  // If true, will add toasts for errors from the response json body, if present on an error.
  // These will be displayed _instead_ of the failureMessage.
  showErrorsFromResponse: false,
};

const defaultGetOptions = {
  ...defaultOptions,

  // It's rare to want to toast on a GET. You must manually pass in `showToasts: true` to do so.
  showToasts: false,
};

function displayToasts(response, {
  showToasts,
  successMessage,
  failureMessage,
  showErrorsFromResponse,
}) {
  if (!showToasts) return;

  if (response.ok) {
    addToast(successMessage);
  } else if (showErrorsFromResponse && response.errors?.length) {
    response.errors.forEach((error) => addToast(error, { type: 'error' }));
  } else {
    addToast(failureMessage, { type: 'error' });
  }
}

export async function get(url, displayOptions) {
  const response = await apiGet(url);

  displayToasts(response, {
    ...defaultGetOptions,
    ...displayOptions,
  });

  return response;
}

export async function post(url, body, displayOptions) {
  const response = await apiPost(url, body);

  displayToasts(response, {
    ...defaultOptions,
    ...displayOptions,
  });

  return response;
}

export async function patch(url, body, displayOptions) {
  const response = await apiPatch(url, body);

  displayToasts(response, {
    ...defaultOptions,
    ...displayOptions,
  });

  return response;
}

export async function destroy(url, displayOptions) {
  const response = await apiDestroy(url);

  displayToasts(response, {
    ...defaultOptions,
    ...displayOptions,
  });

  return response;
}
