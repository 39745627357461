import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  active: PropTypes.bool,
};

const defaultProps = {
  active: false,
};

function StepSeparator(props) {
  const { active } = props;

  const classes = classNames(
    'stepper-stepSeparator',
    { 'stepper-stepSeparator--active': active },
  );

  return (
    <div className={classes} />
  );
}

StepSeparator.propTypes = propTypes;
StepSeparator.defaultProps = defaultProps;

export default StepSeparator;
