import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Pagination,
} from '@makeably/creativex-design-system';
import QueueTable from 'components/admin/review_queue/QueueTable';
import Filters, { filterProps } from 'components/filters/AdminAuditFilters';
import { HiddenInput } from 'components/reusable/HiddenInput';
import styles from './ChallengeQueue.module.css';

const challengeProps = {
  brand: PropTypes.string,
  campaignStatus: PropTypes.string,
  channel: PropTypes.string,
  dateSubmitted: PropTypes.string,
  id: PropTypes.number,
  link: PropTypes.string,
  market: PropTypes.string,
  organization: PropTypes.string,
  status: PropTypes.string,
  timeRemaining: PropTypes.number,
};

const propTypes = {
  challenges: PropTypes.arrayOf(PropTypes.shape(challengeProps)).isRequired,
  filters: filterProps.isRequired,
  formUrl: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageSizeLimit: PropTypes.number.isRequired,
  viewState: PropTypes.oneOf(['pending', 'reviewed', 'escalated']).isRequired,
  count: PropTypes.number,
};

const defaultProps = {
  count: undefined,
};

function getHeaders(view) {
  const allHeaders = [
    {
      label: 'ID',
      value: 'id',
    },
    {
      label: 'Campaign Status',
      value: 'campaignStatus',
    },
    {
      label: 'Organization',
      value: 'organization',
    },
    {
      label: 'Brand',
      value: 'brand',
    },
    {
      label: 'Market',
      value: 'market',
    },
    {
      label: 'Channel',
      value: 'channel',
    },
    {
      label: 'Time Remaining',
      value: 'timeRemaining',
      filter: ['pending', 'escalated'],
    },
    {
      label: 'Earliest Date Submitted',
      value: 'dateSubmitted',
    },
  ];

  return allHeaders.filter((header) => (header.filter ? header.filter.includes(view) : true));
}

function ChallengeQueue({
  count,
  challenges,
  filters,
  formUrl,
  page,
  pageSizeLimit,
  viewState,
}) {
  const formRef = useRef(null);
  const [formLoading, setFormLoading] = useState(false);
  const [nextPage, setNextPage] = useState(page);
  const isChangingPage = nextPage !== page;

  useEffect(() => {
    if (isChangingPage) {
      formRef.current.submit();
    }
  }, [isChangingPage]);

  function navigateToPage(newPage) {
    setFormLoading(true);
    setNextPage(newPage);
  }

  function renderPagination() {
    const total = viewState === 'reviewed' ? null : count;

    return (
      <div className={styles.pagination}>
        <Pagination
          currentPage={page}
          perPage={pageSizeLimit}
          total={total}
          onPageChange={(newPage) => navigateToPage(newPage)}
        />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.filters}>
        <Filters
          ref={formRef}
          filters={filters}
          formUrl={formUrl}
          loading={formLoading}
          viewState={viewState}
        >
          { isChangingPage
              && <HiddenInput name="page" value={nextPage} /> }
        </Filters>
      </div>
      <div className={styles.table}>
        <Card>
          <QueueTable
            headers={getHeaders(viewState)}
            items={challenges}
          />
          { renderPagination() }
        </Card>
      </div>
    </div>
  );
}

ChallengeQueue.propTypes = propTypes;
ChallengeQueue.defaultProps = defaultProps;

export default ChallengeQueue;
