import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './TierRangeChart.module.css';

const tierCutoffMax = 100;
const minRangePercent = 3;
const rangeChartWidth = 400;

const propTypes = {
  tiers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      minimumCutoff: PropTypes.number,
      rank: PropTypes.string,
    }),
  ).isRequired,
};

function TierRangeChart({ tiers }) {
  let max = tierCutoffMax;
  const tiersWithRange = tiers.map((tier) => {
    const { minimumCutoff } = tier;
    const barWidthPercent = (Math.max(max - minimumCutoff, 0) + minRangePercent) / tierCutoffMax;
    max = minimumCutoff;

    return {
      ...tier,
      barWidthPercent,
    };
  }).reverse();

  const barWidthSum = tiersWithRange.reduce((sum, { barWidthPercent }) => sum + barWidthPercent, 0);
  const normalizeFactor = 1.0 / barWidthSum;

  return (
    <div className={styles.container}>
      <div className="u-flexRow">
        { tiersWithRange.map(({ barWidthPercent, rank }) => (
          <div
            key={rank}
            className={classNames(styles.rangeBar, styles[rank])}
            style={{ width: `${barWidthPercent * normalizeFactor * rangeChartWidth}px` }}
          />
        )) }
      </div>
      { tiersWithRange.map(({ label, rank }) => (
        <div key={rank} className="u-flexRow">
          <div className={classNames(styles.key, styles[rank])} />
          { label }
        </div>
      )) }
    </div>
  );
}

TierRangeChart.propTypes = propTypes;

export default TierRangeChart;
