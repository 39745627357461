import React from "react"
import Button from "components/reusable/button"
import Card from "components/reusable/card"
import Column from "components/reusable/column"
import Loader from "components/reusable/Loader"
import Row from "components/reusable/row"
import Select from "components/reusable/select"
import TextInput from "components/reusable/text_input"
import Typeahead from "components/reusable/typeahead"
import PropTypes from "prop-types"

# Responsible for the form for generating a category for both admin and user categories
# For reference, state changes are async... see link to docs below for more infor
#   https://facebook.github.io/react/docs/state-and-lifecycle.html#state-updates-may-be-asynchronous
class CategoryForm extends React.Component
  FORM_PREFIX = 'category_form'
  CATEGORY_HELP = "It takes approximately one hour for new categories " +
    "and changes to existing category to be reflected in your dashboard."
  SECTION_HELP = "Terms must be separated by commas. Do not include # in " +
    "front of hashtags or @ in front of mentioned accounts."
  OPERATOR_FIELD =
    field: 'logical_operator'
    inputNames:
      have: "#{FORM_PREFIX}[have][logical_operator]"
      not_have: "#{FORM_PREFIX}[not_have][logical_operator]"
  LABEL_FIELD =
    field: 'label'
    label: 'Category Name'
    inputName: "#{FORM_PREFIX}[label]"
  DESCRIPTION_FIELD =
    field: 'description'
    label: 'Category Description (keep it short, only relevant to admin)'
    inputName: "#{FORM_PREFIX}[description]"
  CLASSIFICATION_FIELD =
    field: 'category_classification_id'
    label: 'Classification'
    inputName: "#{FORM_PREFIX}[category_classification_id]"

  @propTypes =
    # In order to support forms in rails we need this token for the form
    admin: PropTypes.bool
    authenticityToken: PropTypes.string.isRequired
    categoryForm: PropTypes.shape(
      categoryClassificationId: PropTypes.number
      description: PropTypes.string
      filter: PropTypes.object
      filterErrors: PropTypes.string
      label: PropTypes.string
    )
    deleteCategoryLink: PropTypes.string
    formMethod: PropTypes.string.isRequired
    formUrl: PropTypes.string.isRequired       # Where we want to sumbit the form
    readOnly: PropTypes.bool

  @defaultProps =
    admin: false
    readOnly: true

  constructor: (props) ->
    super(props)

    @sections = CategoryUtil.sections()
    @sectionFields = CategoryUtil.sectionFields(FORM_PREFIX)

    @state =
      categoryClassificationId: @props.categoryForm.categoryClassificationId || ''
      description: @props.categoryForm.description
      label: @props.categoryForm.label
      categoryOptions: []
      categoryClassificationOptions: []
      openCalls: 0 # the category form does not work properly until all data is loaded
      tagOptions: []
      countryOptions: []

    for section in _.pluck(@sections, 'section')
      # sectionFilterObj is like:
      # {operator: 'and', fields: [{field: 'categories', value: []}, {field: hashtags, value: "tag1,tag2"}]}
      sectionFilterObj = @props.categoryForm.filter[section]
      @state[section] = { logical_operator: sectionFilterObj.operator }

      for fieldObj in sectionFilterObj.fields
        @state[section][fieldObj.field] = fieldObj.value

  componentDidMount: ->
    # Populate form data
    if @props.admin
      @getFormData('allOptions')
      @getFormData('categoryOptions') # NB: Slower, so lets make it a separate call
      @getFormData('countryOptions')
    else
      @getFormData('categoryOptions', false)

  # -- Helper Functions -- #

  getFormData: (dataOption, isAdmin = true) ->
    # Note, state changes are async, update with alternate setState synatax
    @setState((prevState, props) -> { openCalls: prevState.openCalls + 1 })

    $.get(
      '/api/category_forms/form_options',
      data_option: dataOption,
      is_admin: isAdmin
    ).done((response) =>
      updatedData = {}

      # Set form data
      for dataSet in response
        updatedData[dataSet.dataSection] = dataSet.data

        switch dataSet.dataSection
          # Find all the currently selected categories
          when 'categoryOptions', 'countryOptions'
            for section in _.pluck(@sections, 'section')
              formUtil = _.findWhere(this.sectionFields, { optionName: dataSet.dataSection })
              updatedData[section] =
                @updateMultiSelectOptions(
                  dataSet: dataSet
                  have: section
                  section: $.extend(true, {}, @state[section])
                  objectName: formUtil.objectName
                  field: formUtil.field
                )

      @setState(updatedData)
    ).fail( ->
      Materialize.toast("Oops... something went wrong.", 'rounded')
    ).always(=>
      # Note, state changes are async, update with alternate setState synatax
      @setState((prevState, props) -> { openCalls: prevState.openCalls - 1 })
    )

  # this method initializes params for the typeahead.
  # TODO: This has been refactored for readability from it's original implementation, but it needs
  # some additional love to get up to speed with our other react components. There are a few issues
  # here, but they stem from one core idea: data is duplicated in various forms in the state --
  # params['dataSet'].data is an array representation of the data and params['dataSet'].objects is
  # a hash, and this has been coded to utilize both, which creates a lot of far-reaching changes
  # when we need to update the behavior.
  updateMultiSelectOptions: (params) ->
    dataSet = params['dataSet']        # response from server.
    field = params['field']            # 'categories', ...
    have = params['have']              # have/have_not
    objectName = params['objectName']  # 'categoryObjects', ...
    section =  params['section']       # hash that updates state
    section[objectName] = []
    for id in @state[have][field]
      object = dataSet.objects[id]
      continue unless object
      section[objectName].push(object)
    section

  # -- Value Change Handlers -- #

  handleCategoryClassificationChange: (classificationId) =>
    @setState(categoryClassificationId: classificationId)

  handleLabelChange: (value) =>
    @setState(label: value)

  handleDescriptionChange: (value) =>
    @setState(description: value)

  # -- Section Specific Value Change Handlers -- #

  handleLogicalOperatorChange: (event) =>
    inputObj = event.target
    section = inputObj.dataset.section
    updatedSection = $.extend(true, {}, @state[section])
    updatedSection[OPERATOR_FIELD.field] = if inputObj.checked then 'or' else 'and'
    updatedState = {}
    updatedState[section] = updatedSection
    @setState(updatedState)

  # NB: We need a closure here to make sure we have access to the correct input field and section
  handleTextInputValueChange: (section, inputField) =>
    (value) =>
      updatedState = {}
      updatedSection = $.extend(true, {}, @state[section])
      updatedSection[inputField] = value
      updatedState[section] = updatedSection
      @setState(updatedState)

  # updates the state based on user's selection.
  handleTypeaheadChange: (section, sectionField) =>
    (objects) =>
      updatedSection = $.extend(true, {}, @state[section])
      updatedSection[sectionField.objectName] = objects
      updatedSection[sectionField.field] = _.pluck(objects, 'value')
      updatedState = {}
      updatedState[section] = updatedSection
      @setState(updatedState)

  # -- Render Functions -- #

  renderSectionField: (section) =>
    (sectionField) =>
      if sectionField.field is 'categories'
        @renderTypeahead(section, sectionField, sectionField.field)
      else if _.contains(['tags', 'country'], sectionField.field)
        if @props.admin
          @renderTypeahead(section, sectionField, sectionField.field)
      else
        @renderTextInput(section, sectionField)

  # updates the options for the typeahead based on user's input.
  # response from the server is an array with one element.
  updateTypeaheadOptions: (response) =>
    resp = response[0]
    updatedState = {}
    updatedState[resp.dataSection] = resp.data
    @setState(updatedState)

  renderTypeahead: (section, sectionField) ->
    dropup = section is 'not_have'
    formValue = @state[section][sectionField.field]
    onChange = @handleTypeaheadChange(section, sectionField)
    options = @state[sectionField.optionName]
    selected = @state[section][sectionField.objectName]
    paginationText = "Display additional #{sectionField.field}..."

    onSearch =
      if sectionField.field is 'tags'
        # tag values are placed in the filter, not ids
        selected = @state[section]['tags'].map((tag) -> { label: tag, value: tag })
        CategoryUtil.onTypeaheadChange(sectionField.optionName, @updateTypeaheadOptions)
      else
        undefined

    `<Row key={section + "_" + sectionField.field}>
      <Column className="no-padding">
        <Typeahead
          data-section={section}
          dropup={dropup}
          formValue={formValue}
          inputName={sectionField.inputNames[section]}
          label={sectionField.label}
          labelKey="label"
          multiple={true}
          onChange={onChange}
          onSearch={onSearch}
          options={options}
          paginationText={paginationText}
          selected={selected}
        />
      </Column>
    </Row>`

  renderTextInput: (section, sectionField) ->
    inputId = "textInput-#{section}-#{sectionField.field}"

    `<Row key={section + "_" + sectionField.field}>
      <Column className="no-padding">
        <TextInput
          id={inputId}
          inputName={sectionField.inputNames[section]}
          label={sectionField.label}
          onChange={this.handleTextInputValueChange(section, sectionField.field)}
          placeholder={sectionField.placeholder}
          type='textarea'
          value={this.state[section][sectionField.field]}
        />
      </Column>
    </Row>`

  renderCategoryDelete: ->
    # NB: We don't allow the delete button to show up on the category form if
    #     the form is on the admin view or if there is no delete link
    return if @props.admin or _.isEmpty(@props.deleteCategoryLink)

    `<Column size={4} className='offset-s1 no-padding'>
      <div className='right-align'>
        <Button
          confirm='Are you sure you want to delete this category?'
          method='delete'
          label='Delete Category'
          link={this.props.deleteCategoryLink}
        />
      </div>
    </Column>`

  renderCategoryDescription: ->
    return unless @props.admin
    `<Row>
      <Column className="no-padding">
        <TextInput
          id='categoryDescription-input'
          inputName={DESCRIPTION_FIELD.inputName}
          label={DESCRIPTION_FIELD.label}
          onChange={this.handleDescriptionChange}
          type='textarea'
          value={this.state.description}
        />
      </Column>
    </Row>`

  renderLogicalOperatorSwitch: (sectionObj) ->
    return unless sectionObj.hasLogicalOperator

    section = sectionObj.section
    orFilter = @state[section][OPERATOR_FIELD.field] is 'or'

    `<div className="switch">
      <label>
        AND
        <input
          checked={orFilter}
          name={OPERATOR_FIELD.inputNames[section]}
          onChange={this.handleLogicalOperatorChange}
          data-section={section}
          type="checkbox"
          value='or'
        />
        <span className="lever"></span>
        OR
      </label>
    </div>`

  renderHelpText: (text) ->
    `<span className='categoryCardForm-helpText'>{text}</span>`

  renderSection: (sectionObj) =>
    logicalOperatorSwitch = @renderLogicalOperatorSwitch(sectionObj)
    helpText = @renderHelpText(SECTION_HELP) if sectionObj.section is 'have'

    `<Row key={sectionObj.section}>
      <Column className='no-padding'>
        <h5>{sectionObj.label}</h5>
        {helpText}
      </Column>
      <Column size={4} className="input-field">
        {logicalOperatorSwitch}
      </Column>
      <Column className='no-padding' size={8}>
        {this.sectionFields.map(this.renderSectionField(sectionObj.section))}
      </Column>
    </Row>`

  renderLoader: ->
    return unless @state.openCalls > 0
    `<Column className='center-align'>
       <div className='flash'>
         <h5 className='no-margin'> Loading Form Details... </h5>
       </div>
       <Loader />
     </Column>`

  renderErrorMessages: ->
    return unless @props.categoryForm.filterErrors
    `<Column className='no-padding center-align'>
      <div className='error-flash'>
        <span className='no-margin'>{this.props.categoryForm.filterErrors}</span>
      </div>
    </Column>`

  renderClassificationSelection: ->
    return if !@props.admin or _.isEmpty(@state.categoryClassificationOptions)

    options =
      [
        {
          optgroup: 'Unclassified',
          options: [
            {
              label: 'No Classification',
              value: ''
            }
          ]
        },
        {
          optgroup: 'Classifications',
          options: @state.categoryClassificationOptions
        }
      ]


    `<Row>
      <Column size={4} styleName='no-padding'>
        <label>{CLASSIFICATION_FIELD.label}</label>
        <Select
          className="btn-spacing"
          inputName={CLASSIFICATION_FIELD.inputName}
          options={options}
          selected={this.state.categoryClassificationId}
          onChange={this.handleCategoryClassificationChange}
        />
      </Column>
    </Row>`

  renderCategoryForm: (ContainerComponent) ->
    `<ContainerComponent className='categoryCardForm'>
      <Row>
        {this.renderLoader()}
        {this.renderErrorMessages()}
        <Column size={7} className="no-padding adminCategoryCardForm-labelInput">
          <TextInput
            id='categoryLabel'
            inputName={LABEL_FIELD.inputName}
            label={LABEL_FIELD.label}
            onChange={this.handleLabelChange}
            value={this.state.label}
          />
        </Column>
        {this.renderCategoryDelete()}
      </Row>
      {this.renderCategoryDescription()}
      {this.renderClassificationSelection()}
      <Row>
        <Column className='no-padding'>
          {this.renderHelpText(CATEGORY_HELP)}
        </Column>
      </Row>
      {this.sections.map(this.renderSection)}
      <Row>
        <Column>
          <input type="submit" value="Save Category" className="btn"/>
        </Column>
      </Row>
    </ContainerComponent>`

  formContent: ->
    if @props.admin
      `<Row>
        <Column>
          {this.renderCategoryForm(Card)}
        </Column>
      </Row>`
    else
      @renderCategoryForm('div')

  render: ->
    formProps =
      id: "category_form"
      action: @props.formUrl
      method: 'post'

    `<form {...formProps}>
      <input type="hidden" name="_method" value={this.props.formMethod} />
      <input type="hidden" name="authenticity_token" value={this.props.authenticityToken} />
      {this.formContent()}
    </form>`

export default CategoryForm
