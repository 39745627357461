import React from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker,
  Dropdown,
  Toggle,
} from '@makeably/creativex-design-system';
import { dateOptionProps } from 'components/reporting/shared';

const propTypes = {
  customRangeProps: PropTypes.shape({
    customDatesEnabled: PropTypes.bool,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
  dateOptions: PropTypes.arrayOf(dateOptionProps).isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedDateRange: PropTypes.shape({
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  setUseCustomDates: PropTypes.func.isRequired,
  useCustomDates: PropTypes.bool.isRequired,
  selectedDateOption: dateOptionProps,
};

const defaultProps = {
  selectedDateOption: undefined,
};

function ReportDatePicker({
  customRangeProps,
  dateOptions,
  handleDateChange,
  handleDateRangeChange,
  loading,
  selectedDateOption,
  selectedDateRange,
  setUseCustomDates,
  useCustomDates,
}) {
  return (
    <div className="u-flexRow u-gap-16">
      { customRangeProps.customDatesEnabled && (
        <Toggle
          checked={useCustomDates}
          label="Dynamic Date Range"
          onChange={() => setUseCustomDates((prev) => !prev)}
        />
      ) }
      { useCustomDates && (
        <DatePicker
          disabled={loading}
          endDate={selectedDateRange.endDate}
          maxDate={customRangeProps.endDate}
          minDate={customRangeProps.startDate}
          startDate={selectedDateRange.startDate}
          onEndDateChange={(date) => handleDateRangeChange(date, 'endDate')}
          onStartDateChange={(date) => handleDateRangeChange(date, 'startDate')}
        />
      ) }
      { !useCustomDates && (
        <Dropdown
          disabled={loading}
          menuProps={{ size: 'medium' }}
          options={dateOptions}
          selected={selectedDateOption}
          size="medium"
          onChange={handleDateChange}
        />
      ) }
    </div>
  );
}

ReportDatePicker.propTypes = propTypes;
ReportDatePicker.defaultProps = defaultProps;

export default ReportDatePicker;
