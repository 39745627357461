// Doughnut chart
//
// @note: For the following props
//  - Overview: Will include an overview on the right hand side (as seen on
//      page /audit/overview)
//  - HideLegend: Use sparingly. Hides the legend, from a data viz point of
//      view the legend is helpful
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DoughnutOverview from 'components/charts/DoughnutOverview';
import StringHelper from 'components/utils/StringHelper';
// Do not remove this line, we don't always want chart data labels and they
// are automatically included on all charts without this line
Chart.unregister(ChartDataLabels);

export const chartProps = {
  chartType: PropTypes.oneOf([
    'currency',
    'largeCurrency',
    'largeNumber',
    'number',
    'percentage',
  ]),
  colors: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.number),
  labels: PropTypes.arrayOf(PropTypes.string),
};

export const layoutProps = {
  hideLegend: PropTypes.bool,
  overview: PropTypes.bool,
};

export const propTypes = {
  chart: PropTypes.shape(chartProps),
  className: PropTypes.string,
  hasData: PropTypes.bool,
  hideLegend: PropTypes.bool,
  overview: PropTypes.bool,
};

const defaultProps = {
  chart: undefined,
  className: undefined,
  hasData: true,
  hideLegend: false,
  overview: false,
};

function renderOverview(includeOverview, chart) {
  if (!includeOverview) return null;

  const {
    chartType,
    data,
    labels,
  } = chart;

  const values = [];

  data.forEach((datum, idx) => {
    values.push({
      label: labels[idx],
      value: datum,
    });
  });

  return <DoughnutOverview chartType={chartType} values={values} />;
}

function tooltipOptions(chartType) {
  return {
    callbacks: {
      label({ label, raw }) {
        const opts = {};
        if (chartType === 'percentage') {
          opts.sigFigs = 2;
        }

        const displayValue = StringHelper.format(raw, chartType, opts);

        return `${label}: ${displayValue}`;
      },
    },
  };
}

class DoughnutChart extends React.Component {
  static chartOptions(legendOptions) {
    return ({
      plugins: {
        ...legendOptions,
      },
      cutout: '60%',
      maintainAspectRatio: false,
    });
  }

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = undefined;
  }

  componentDidMount() {
    const doughnutChartRef = this.chartRef.current.getContext('2d');

    const {
      chart: {
        chartType,
        colors,
        data,
        labels,
      },
      hideLegend,
    } = this.props;

    const chartData = {
      datasets: [{
        backgroundColor: colors,
        data,
      }],
      labels,
    };

    const legendOptions = {
      legend: {
        display: !hideLegend,
        position: 'bottom',
      },
    };

    const options = DoughnutChart.chartOptions(legendOptions, chartType);
    options.plugins.tooltip = tooltipOptions(chartType);

    this.chart = new Chart(doughnutChartRef, {
      data: chartData,
      options,
      type: 'doughnut',
    });
  }

  componentDidUpdate(prevProps) {
    const { chart: prevChart } = prevProps;
    const { chart: currentChart } = this.props;

    if (prevChart !== currentChart) {
      const { chart } = this;
      const { config } = chart;

      const {
        chart: {
          chartType,
          colors,
          data,
          labels,
        },
      } = this.props;

      config.data.labels = labels;
      config.data.datasets[0].data = data;
      config.data.datasets[0].backgroundColor = colors;
      config.options.plugins.tooltip = tooltipOptions(chartType);

      chart.update();
      chart.resize();
    }
  }

  render() {
    const {
      chart,
      className,
      hasData,
      overview,
    } = this.props;

    const classes = classNames(
      className,
      'doughnutChart',
      { 'doughnutChart--withOverview': overview },
    );

    let content = 'No data to display';

    if (hasData && chart.data.length > 0) {
      content = <canvas ref={this.chartRef} />;
    }

    return (
      <div className={classes}>
        <div className="doughnutChart-canvas">
          { content }
        </div>
        { renderOverview(overview, chart) }
      </div>
    );
  }
}

DoughnutChart.propTypes = propTypes;
DoughnutChart.defaultProps = defaultProps;

export default DoughnutChart;
