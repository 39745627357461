// Used for filtering Pre-flight and In-flight queues
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormGroupContainer,
  Select,
  Widget,
  WidgetContainer,
  WidgetContent,
  WidgetHeader,
} from '@duik/it';
import MonthSelector from 'components/molecules/MonthSelector';
import SearchableDropdownSelect, { propTypes as selectDropdownProps } from 'components/molecules/SearchableDropdownSelect';
import Slider from 'components/molecules/Slider';
import FormSubmit from 'components/reusable/FormSubmit';
import {
  HiddenInput,
  HiddenInputs,
} from 'components/reusable/HiddenInput';
import ArrayHelper from 'components/utils/ArrayHelper';
import ObjectHelper from 'components/utils/ObjectHelper';

const dropdownSelectProps = {
  ...selectDropdownProps,
  type: PropTypes.oneOf(['select']),
};

const sliderProps = {
  activeOption: PropTypes.arrayOf(PropTypes.number),
  field: PropTypes.string,
  label: PropTypes.string,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  max: PropTypes.number,
  min: PropTypes.number,
  type: PropTypes.oneOf(['slider']),
};

const monthPickerProps = {
  activeOption: PropTypes.string,
  allDates: PropTypes.bool,
  field: PropTypes.string,
  label: PropTypes.string,
  labelTooltip: PropTypes.string,
  options: PropTypes.shape({
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
  }),
  type: PropTypes.oneOf(['date']),
};

export const filterProps = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape(dropdownSelectProps),
    PropTypes.shape(sliderProps),
    PropTypes.shape(monthPickerProps),
  ]),
);

const propTypes = {
  filters: filterProps.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  contractScope: PropTypes.string,
  formId: PropTypes.string,
  formRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  // Must include one of formUrl or onFormSubmit
  formUrl: PropTypes.string,
  orgId: PropTypes.string,
  viewState: PropTypes.oneOf(['pending', 'reviewed', 'unreviewable', 'escalated']),
  onFormSubmit: PropTypes.func,
};

const defaultProps = {
  children: undefined,
  contractScope: undefined,
  formId: 'filteringForm',
  formRef: undefined,
  formUrl: undefined,
  onFormSubmit: undefined,
  orgId: undefined,
  viewState: undefined,
};

const allObject = ObjectHelper.valueToObject('all', true);
const assetType = 'asset_type';
const contractScopeField = 'contract_scope';
const timeRemaining = 'time_remaining';
const numberOfCreatives = 'number_of_creatives';
const datePosted = 'date_range';
const showAllDates = 'allDates';

function renderSelector(state, filter, callback) {
  const {
    field,
    label,
    labelTooltip,
    options,
  } = filter;

  const activeOption = state[field];
  let filterSelector;

  if (field === datePosted) {
    const allDatesSelected = state[showAllDates];

    filterSelector = (
      <div key={label}>
        <MonthSelector
          activeOption={activeOption}
          disabled={allDatesSelected}
          label={label}
          labelTooltip={labelTooltip}
          name={field}
          options={options}
          onChange={callback}
        />
        <Checkbox
          checked={allDatesSelected}
          className="u-marginAboveSm"
          label="All Dates"
          onChange={(_e) => callback(!allDatesSelected, showAllDates)}
        />
      </div>
    );
  } else if (field === assetType || field === contractScopeField) {
    filterSelector = (
      <Select
        key={label}
        activeOption={activeOption}
        label={label}
        menuPosition="bottom-right"
        name={field}
        options={options}
        block
        onOptionClick={callback}
      />
    );
  } else {
    filterSelector = (
      <SearchableDropdownSelect
        key={label}
        activeOption={activeOption}
        label={label}
        name={field}
        options={options}
        multiple
        onOptionClick={callback}
      />
    );
  }

  return filterSelector;
}

function renderHiddenInputs(state) {
  const hiddenInputs = [];
  const formFieldKeys = Object.keys(state).filter((key) => key !== showAllDates && state[key]);

  formFieldKeys.forEach((key) => {
    const value = state[key];

    if ([timeRemaining, numberOfCreatives].includes(key)) {
      const validCreatives = JSON.stringify(value) !== JSON.stringify([1, 10]);
      const validTime = JSON.stringify(value) !== JSON.stringify([0, 24]);
      const valid = key === 'time_remaining' ? validTime : validCreatives;

      if (valid) {
        hiddenInputs.push(
          <HiddenInput
            key={key}
            name={key}
            value={value.join('_')}
          />,
        );
      }
    } else if (key === datePosted) {
      const hiddenInputValue = state[showAllDates] ? 'all' : value;

      hiddenInputs.push(
        <HiddenInput key={key} name={key} value={hiddenInputValue} />,
      );
    } else if (Array.isArray(value)) {
      if (!ArrayHelper.objectInArray(value, allObject)) {
        hiddenInputs.push(
          <HiddenInputs
            key={key}
            name={`${key}[]`}
            values={ArrayHelper.values(value)}
          />,
        );
      }
    } else {
      const inputValue = Array.isArray(value) ? value[0] : value.value;
      if (inputValue !== allObject.value) {
        hiddenInputs.push(
          <HiddenInput
            key={key}
            name={key}
            value={inputValue}
          />,
        );
      }
    }
  });

  return hiddenInputs;
}

function renderFormSubmit(loading) {
  return (
    <FormSubmit
      label="Apply"
      loading={loading}
      primary
    />
  );
}

function renderControlledFormSubmit(loading, onFormSubmit) {
  return (
    <Button
      isLoading={loading}
      primary
      onClick={() => onFormSubmit()}
    >
      Apply
    </Button>
  );
}

class AdminAuditFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    props.filters.forEach((filter) => {
      const {
        activeOption,
        allDates,
        field,
      } = filter;

      if (field) {
        this.state[field] = activeOption;

        if (field === datePosted) {
          this.state[showAllDates] = allDates;
        }
      }
    });
  }

  onInputChange(valueObj, name) {
    let values;

    this.setState((prevState) => {
      const simpleFilters = [
        assetType,
        contractScopeField,
        datePosted,
        numberOfCreatives,
        showAllDates,
        timeRemaining,
      ];

      if (simpleFilters.includes(name)) {
        values = valueObj;
      } else {
        values = prevState[name];

        if (ArrayHelper.objectInArray(values, allObject)) {
          if (valueObj.length > 1) {
            values = ArrayHelper.removeObjectFromArray(valueObj, allObject);
          }
        } else if (ArrayHelper.objectInArray(valueObj, allObject) || valueObj.length === 0) {
          values = [allObject];
        } else {
          values = valueObj;
        }
      }

      return ({ [name]: values });
    });
  }

  clearFilters() {
    const { filters: oldFilters } = this.props;
    const filters = {};

    oldFilters.forEach(
      (
        {
          type,
          field,
          max,
          min,
        },
      ) => {
        if (field) {
          if (type === 'select') {
            filters[field] = [allObject];
          } else if (type === 'slider') {
            filters[field] = [min, max];
          }
        }
      },
    );

    this.setState(filters);
  }

  render() {
    const {
      children,
      contractScope,
      filters,
      formId,
      formRef,
      formUrl,
      loading,
      onFormSubmit,
      orgId,
      viewState,
    } = this.props;

    const dropdownFilters = [];
    const sliders = [];

    filters.forEach((filter) => {
      const { type } = filter;
      if (type === 'slider') {
        sliders.push(filter);
      } else {
        dropdownFilters.push(filter);
      }
    });

    let formSubmitButton;
    if (onFormSubmit) {
      formSubmitButton = renderControlledFormSubmit(loading, onFormSubmit);
    } else {
      formSubmitButton = renderFormSubmit(loading);
    }

    return (
      <WidgetContainer className="adminAuditQueue-filters">
        <Widget>
          <WidgetHeader>
            Filter By
          </WidgetHeader>
          <WidgetContent>
            <form ref={formRef} action={formUrl} id={formId} method="get">
              { renderHiddenInputs(this.state) }
              { viewState && <HiddenInput name="state" value={viewState} /> }
              { children }
              { contractScope
                  && <HiddenInput name="contract_scope" value={contractScope} /> }
              { orgId
                  && <HiddenInput name="org_id" value={orgId} /> }
              <FormGroupContainer>
                {
                  dropdownFilters.map((filter) => (
                    renderSelector(this.state, filter, (obj, n) => this.onInputChange(obj, n))
                  ))
                }
                {
                  sliders.map((filter) => {
                    const { field } = filter;
                    const { [field]: value } = this.state;
                    return (
                      <Slider
                        key={field}
                        options={filter}
                        value={value}
                        onChange={(newValue) => this.onInputChange(newValue, field)}
                      />
                    );
                  })
                }
                <div className="u-flexRow u-spaceBetween u-marginAboveLg">
                  <Button className="u-marginRightSm" clear onClick={() => this.clearFilters()}>
                    Clear
                  </Button>
                  { formSubmitButton }
                </div>
              </FormGroupContainer>
            </form>
          </WidgetContent>
        </Widget>
      </WidgetContainer>
    );
  }
}

AdminAuditFilters.propTypes = propTypes;
AdminAuditFilters.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <AdminAuditFilters formRef={ref} {...props} />);
