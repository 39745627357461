import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from '@makeably/creativex-design-system';
import { arrayIf } from 'utilities/array';
import * as routes from 'utilities/routes';

export const abilitiesProps = PropTypes.shape({
  canViewChallenge: PropTypes.bool.isRequired,
  canViewProcessControl: PropTypes.bool.isRequired,
  canViewRepresentation: PropTypes.bool.isRequired,
  canViewTrialGuideline: PropTypes.bool.isRequired,
  canViewValidation: PropTypes.bool.isRequired,
  isFullInternal: PropTypes.bool.isRequired,
});

export const paramsProps = PropTypes.shape({
  id: PropTypes.string,
});

export const propTypes = {
  abilities: abilitiesProps.isRequired,
  companyName: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  homeUrl: PropTypes.string.isRequired,
  params: paramsProps.isRequired,
  logoUrl: PropTypes.string,
};

const defaultProps = {
  logoUrl: undefined,
};

function getAdmin() {
  return {
    icon: 'dashboard',
    label: 'To Admin',
    options: [
      {
        label: 'To Admin',
        urls: [
          routes.adminUsersPath(),
        ],
      },
    ],
  };
}

function getGeneral(abilities) {
  const { isFullInternal } = abilities;

  return {
    icon: 'dashboard',
    label: 'Act As Company Admin', // TODO: Restore to 'General' once more sections are added
    options: [
      ...arrayIf(isFullInternal,
        {
          label: 'Act As Company Admin',
          urls: [
            routes.adminsInternalUsersPath(),
          ],
        }),
    ],
  };
}

function getConnections(abilities) {
  const { isFullInternal } = abilities;

  return {
    icon: 'dashboard',
    label: 'Connections',
    options: [
      ...arrayIf(isFullInternal,
        {
          label: 'Google Ads',
          urls: [
            routes.internalGoogleAdsAccountsPath(),
            routes.newInternalGoogleAdsAccountPath(),
            routes.internalGoogleAdsIntegrationsPath(),
          ],
        },
        {
          label: 'Admin DV360',
          urls: [
            routes.adminDv360IndexPath(),
          ],
        }),
    ],
  };
}

function getReview(abilities, ids) {
  const { isFullInternal } = abilities;
  const { id, reviewGuideId } = ids;

  return {
    icon: 'dashboard',
    label: 'Review',
    options: [
      ...arrayIf(isFullInternal,
        {
          label: 'Review Guides Home',
          urls: [
            routes.internalReviewGuidesPath(),
            routes.editInternalReviewGuidePath(id),
            routes.internalReviewGuideSubscriptionsPath(reviewGuideId),
            routes.newInternalReviewGuidePath(),
            routes.newInternalReviewGuideSubscriptionPath(reviewGuideId),
          ],
        },
        {
          label: 'Admin Challenge Queue',
          urls: [
            routes.adminAuditChallengesPath(),
          ],
        }),
    ],
  };
}

function getEngineering(abilities) {
  const { isFullInternal } = abilities;

  return {
    icon: 'dashboard',
    label: 'Engineering',
    options: [
      ...arrayIf(isFullInternal,
        {
          label: 'Sidekiq',
          urls: [
            routes.sidekiqWebPath(),
          ],
          useNewTab: true,
        },
        {
          label: 'Code Usage',
          urls: [
            routes.coverbandWebPath(),
          ],
          useNewTab: true,
        }),
    ],
  };
}

export function getMenu(abilities, params) {
  const ids = {
    id: params.id ?? '0',
    reviewGuideId: params.reviewGuideId ?? 0,
  };

  const all = [
    getAdmin(),
    getGeneral(abilities),
    getConnections(abilities),
    getReview(abilities, ids),
    getEngineering(abilities),
  ];

  return all.filter((section) => section !== null);
}

function getUserOptions() {
  return [
    {
      label: 'Sign Out',
      url: routes.logoutPath(),
    },
  ];
}

function InternalNavigation({
  abilities,
  companyName,
  fullName,
  homeUrl,
  logoUrl,
  params,
}) {
  const menu = getMenu(abilities, params);
  const user = {
    companyName,
    fullName,
    logoUrl,
  };
  const userOptions = getUserOptions();

  return (
    <Nav
      color="white"
      currentParams={window.location.search}
      currentUrl={window.location.pathname}
      homeUrl={homeUrl}
      menu={menu}
      user={user}
      userOptions={userOptions}
    />
  );
}

InternalNavigation.propTypes = propTypes;
InternalNavigation.defaultProps = defaultProps;

export default InternalNavigation;
