import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Icon,
  Table,
} from '@makeably/creativex-design-system';
import { adminRepresentationIndexPath } from 'utilities/routes';
import styles from './Overview.module.css';

const propTypes = {
  isCxAdmin: PropTypes.bool.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      id: PropTypes.number,
      name: PropTypes.string,
      total: PropTypes.number,
    }),
  ).isRequired,
};

function headers(isCxAdmin) {
  const vals = [
    { value: 'Company' },
    { value: 'Needs Review' },
  ];

  if (isCxAdmin) {
    vals.push({ value: 'Data Processing' });
  }

  return vals;
}

function formatItem(
  {
    active,
    id,
    name,
    total,
  },
  isCxAdmin,
) {
  const params = {
    org_id: id,
    state: 'needs_review',
  };

  const cells = [
    { value: <a href={adminRepresentationIndexPath(params)}>{ name }</a> },
    { value: total },
  ];

  if (isCxAdmin) {
    const icon = (
      <Icon
        color={active ? 'green' : 'grey'}
        name={active ? 'checkCircle' : 'xCircle'}
      />
    );
    cells.push({ value: icon });
  }

  return {
    key: id.toString(),
    cells,
  };
}

function Overview({ isCxAdmin, organizations }) {
  const items = organizations.map((org) => formatItem(org, isCxAdmin));

  return (
    <div className="col-8">
      <Card>
        <Table
          className={styles.table}
          headers={headers(isCxAdmin)}
          rows={items}
        />
      </Card>
    </div>
  );
}

Overview.propTypes = propTypes;

export default Overview;
