// note: Will need to be updated to work for landscape style templates
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  BasicModal,
  CloseButton,
} from '@makeably/creativex-design-system';
import VideoControls from 'components/molecules/VideoControls';
import VideoWrapper from 'components/molecules/VideoWrapper';
import styles from './SafeZoneModal.module.css';

// NOTE: this is the default margin added to design system modals
const MODAL_OUTSIDE_MARGIN = 48;
const MODAL_FULL_OUTSIDE_MARGIN = MODAL_OUTSIDE_MARGIN * 2;
// note: templates must have a consistent padding on each side. In this case 300px of
// padding is added for a total of 600px horizontally and vertically
const TEMPLATE_PADDING = 300;
const TEMPLATE_FULL_PADDING = TEMPLATE_PADDING * 2;

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  source: PropTypes.shape({
    creativeUrl: PropTypes.string,
    isVideo: PropTypes.bool,
  }).isRequired,
  templateUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

function SafeZoneModal({
  isOpen,
  onClose,
  source: {
    creativeUrl,
    isVideo,
  },
  templateUrl,
}) {
  const templateRef = useRef(null);
  const videoRef = useRef(null);

  const [clientHeight, setClientHeight] = useState();
  const [clientWidth, setClientWidth] = useState();
  const [creativeStyle, setCreativeStyle] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalStyle, setModalStyle] = useState({});
  const [templateStyle, setTemplateStyle] = useState({});
  const [videoControlHeight, setVideoControlHeight] = useState();
  const [videoControlStyle, setVideoControlStyle] = useState({});

  const videoControlRef = (element) => {
    if (element) {
      setVideoControlHeight(element.getBoundingClientRect().height);
    }
  };

  const setClientSize = () => {
    setClientHeight(window.innerHeight);
    setClientWidth(window.innerWidth);
  };

  useEffect(() => {
    if (!isOpen) return undefined;

    setClientSize();

    window.addEventListener('resize', setClientSize);

    return () => {
      window.removeEventListener('resize', setClientSize);
    };
  }, [isOpen]);

  const determineHeight = (height) => (
    // NOTE: for videos, we need to subtract the height of the video controls to leave room for it
    // to be added below the video. For the height bounded case, we subtract the video control
    // height to preserve the padding below the controls.
    isVideo ? height - videoControlHeight : height
  );

  useEffect(() => {
    const template = templateRef.current;

    if (!template || !isLoaded) return;

    const fullTemplateHeight = template.naturalHeight;
    const nonPaddingHeight = fullTemplateHeight - TEMPLATE_FULL_PADDING;
    const nonPaddingHeightPercent = nonPaddingHeight / fullTemplateHeight;

    const fullTemplateWidth = template.naturalWidth;
    const nonPaddingWidth = fullTemplateWidth - TEMPLATE_FULL_PADDING;
    const nonPaddingWidthPercent = nonPaddingWidth / fullTemplateWidth;

    const templateAspectRatio = fullTemplateWidth / fullTemplateHeight;

    // NOTE: for videos, modal will not resize correctly without
    // factoring in the height of the video controls
    const usableClientHeight = determineHeight(clientHeight);
    const clientAspectRatio = clientWidth / usableClientHeight;

    let templateHeight;
    let templateWidth;

    const isBoundedByHeight = templateAspectRatio <= clientAspectRatio;
    if (isBoundedByHeight) {
      const modalHeight = clientHeight - MODAL_FULL_OUTSIDE_MARGIN;
      templateHeight = determineHeight(modalHeight);
      templateWidth = templateHeight * templateAspectRatio;

      setModalStyle({ height: clientHeight - MODAL_FULL_OUTSIDE_MARGIN });
    } else {
      templateWidth = clientWidth - MODAL_FULL_OUTSIDE_MARGIN;
      templateHeight = templateWidth / templateAspectRatio;
      // NOTE: for videos, add the video controls height to leave room for the video controls with
      // appropriate padding below them. This is necessary because in the width bound case, it
      // takes the height of the largest height by default (the template).
      const modalHeight = isVideo ? templateHeight + videoControlHeight : templateHeight;

      setModalStyle({
        height: modalHeight,
        width: clientWidth - MODAL_FULL_OUTSIDE_MARGIN,
      });
    }

    setTemplateStyle({
      height: templateHeight,
      width: templateWidth,
    });

    // note: calculating creative dimensions by multiplying the resized template
    // dimension by the percent of the original template that is not padding.
    const creativeHeight = templateHeight * nonPaddingHeightPercent;
    const creativeWidth = templateWidth * nonPaddingWidthPercent;

    const resizedTemplatePadding = ((templateHeight - creativeHeight) / 2);

    setCreativeStyle({
      height: creativeHeight,
      top: resizedTemplatePadding,
      width: creativeWidth,
    });

    if (isVideo) {
      setVideoControlStyle({
        width: creativeWidth,
        top: creativeHeight + resizedTemplatePadding,
      });
    }
  }, [clientHeight, clientWidth, isLoaded, videoControlHeight]);

  return (
    <BasicModal
      isOpen={isOpen}
      padding={false}
      size="custom"
      onClose={onClose}
    >
      <div className={styles.container} style={modalStyle}>
        <div className={styles.creative} style={creativeStyle}>
          { !isVideo && <img alt="audited creative" src={creativeUrl} /> }
          { isVideo && <VideoWrapper source={creativeUrl} videoRef={videoRef} /> }
        </div>
        <div className={styles.template} style={templateStyle}>
          <img
            ref={templateRef}
            alt="safe zone template"
            src={templateUrl}
            onLoad={() => setIsLoaded(true)}
          />
        </div>
        { isVideo && (
          <div ref={videoControlRef} className={styles.controls} style={videoControlStyle}>
            <VideoControls videoRef={videoRef} />
          </div>
        ) }
        <div className={styles.close}>
          <CloseButton onClick={onClose} />
        </div>
      </div>
    </BasicModal>
  );
}

SafeZoneModal.propTypes = propTypes;

export default SafeZoneModal;
