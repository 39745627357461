import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
} from '@makeably/creativex-design-system';
import CheckModal from 'components/admin/review_queue/CheckModal';
import Metric from 'components/admin/review_queue/inflight/Metric';
import PreflightBanner from 'components/admin/review_queue/preflight/PreflightBanner';
import ItemsFilters from 'components/molecules/ItemsFilters';
import ItemsTable from 'components/molecules/ItemsTable';
import { getNextIndex } from 'utilities/array';
import { getItemSortBy } from 'utilities/item';
import { generateItemButton } from 'utilities/itemElement';
import {
  filterItems,
  getOptions,
  updateSelections,
} from 'utilities/itemFilter';
import { titleize } from 'utilities/string';
import {
  changePage,
  getPage,
  getParams,
  updateParams,
} from 'utilities/url';
import styles from './Checks.module.css';
import {
  getFilterSelections,
  updateFilterParams,
} from './shared';

const propTypes = {
  checks: PropTypes.arrayOf(
    PropTypes.shape({
      assetTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      brands: PropTypes.arrayOf(PropTypes.string).isRequired,
      channels: PropTypes.arrayOf(PropTypes.string).isRequired,
      id: PropTypes.number.isRequired,
      markets: PropTypes.arrayOf(PropTypes.string).isRequired,
      definition: PropTypes.string,
      hourAge: PropTypes.number,
    }),
  ).isRequired,
  guidelineId: PropTypes.number.isRequired,
  isPreflight: PropTypes.bool.isRequired,
  contractScopeLabel: PropTypes.string,
  dataDateLabel: PropTypes.string,
};

const defaultProps = {
  contractScopeLabel: undefined,
  dataDateLabel: undefined,
};

const baseHeaders = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'definition',
    label: 'Definition',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
  {
    key: 'assetType',
    label: 'Asset Type',
  },
];
const preflightHeaders = [
  ...baseHeaders,
  {
    key: 'hourAge',
    label: 'Hours Left',
  },
];

const baseFilterDims = [
  {
    key: 'definition',
    label: 'Definition',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
  {
    key: 'assetType',
    label: 'Asset Type',
  },
];
const inflightFilterDims = baseFilterDims.concat({
  key: 'adAccount',
  label: 'Ad Account',
});

const filterKeys = (isPreflight) => {
  const dims = isPreflight ? baseFilterDims : inflightFilterDims;
  return dims.map(({ key }) => key);
};

function getId(id, onIdClick) {
  return {
    value: id,
    element: generateItemButton(id, () => onIdClick(id)),
  };
}

function parseOptions(arr) {
  return arr.map((str) => {
    const [value, label] = str.split('::');

    if (!label) {
      return {
        label: value,
        value,
      };
    }
    return {
      label,
      value,
    };
  });
}

function getMultipleValue(multiples) {
  const options = parseOptions(multiples);
  const text = options.length > 1 ? 'Multiple' : options[0].label;
  const value = options.length > 1 ? 'Multiple' : options[0].value;

  return {
    filter: options.map((option) => option.value),
    options,
    text,
    value,
  };
}

function getItems(checks, onIdClick, isPreflight) {
  return checks.map((check) => ({
    assetType: { value: titleize(check.assetTypes[0]) },
    brand: getMultipleValue(check.brands),
    channel: getMultipleValue(check.channels),
    definition: { value: check.definition || 'N/A' },
    id: getId(check.id, onIdClick),
    market: getMultipleValue(check.markets),
    ...(isPreflight && { hourAge: { value: check.hourAge } }),
    ...(!isPreflight && { adAccount: getMultipleValue(check.adAccounts) }),
  }));
}

function Checks({
  checks,
  contractScopeLabel,
  dataDateLabel,
  guidelineId,
  isPreflight,
}) {
  const params = getParams(window);
  const filterDimensions = isPreflight ? baseFilterDims : inflightFilterDims;
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState({
    key: 'count',
    asc: false,
  });
  const [sortedItems, setSortedItems] = useState(items);
  const [page, setPage] = useState(getPage(params));
  const [evaluateId, setEvaluateId] = useState(null);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState({});
  const [filteredItems, setFilteredItems] = useState(items);

  const updateFilterSelections = (selections) => {
    setFilterSelections(selections);
    updateFilterParams(params, filterKeys(isPreflight), selections);
    updateParams(params, window);
  };

  useEffect(() => {
    const allItems = getItems(checks, setEvaluateId, isPreflight);
    const options = getOptions(filterDimensions, allItems);
    const selections = getFilterSelections(params, filterKeys(isPreflight), options);

    setItems(allItems);
    setFilterOptions(options);
    updateFilterSelections(selections);
  }, [checks]);

  useEffect(() => {
    setFilteredItems(filterItems(items, filterSelections));
  }, [items, filterSelections]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(filteredItems.slice().sort(byKeyDir));
    }
  }, [filteredItems, sort]);

  const handleFilterSelect = (key, selected) => {
    setPage(1);
    updateFilterSelections(updateSelections(filterSelections, key, selected));
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    changePage(newPage, params, window);
  };

  const handleCheckComplete = (id) => {
    const index = sortedItems.findIndex((item) => item.id.value === id);
    const nextIndex = getNextIndex(index, sortedItems.length);
    setItems((last) => last.filter((item) => item.id.value !== id));

    if (nextIndex >= 0) {
      setEvaluateId(sortedItems[nextIndex].id.value);
    } else {
      setEvaluateId(null);
      updateFilterSelections({});
    }
  };

  const getHeaders = () => (isPreflight ? preflightHeaders : baseHeaders);

  return (
    <>
      <Card>
        <div className={styles.metrics}>
          { !isPreflight && (
            <>
              <Metric
                label="Date Posted"
                value={dataDateLabel}
              />
              <Metric
                label="Contract Scope"
                value={contractScopeLabel}
              />
            </>
          ) }
        </div>
        <div className={styles.filters}>
          <ItemsFilters
            dimensions={filterDimensions}
            options={filterOptions}
            selections={filterSelections}
            onSelect={handleFilterSelect}
          />
          <Button
            label="Clear Filters"
            variant="tertiary"
            onClick={() => updateFilterSelections({})}
          />
          { isPreflight && <PreflightBanner /> }
        </div>
        <ItemsTable
          className={styles.table}
          headers={getHeaders()}
          items={sortedItems}
          page={page}
          sort={sort}
          onPageChange={handlePageChange}
          onSortChange={(value) => setSort(value)}
        />
        { filteredItems.length === 0 && (
          <div className={`t-empty ${styles.empty}`}>
            There are no checks remaining that match all filters
          </div>
        ) }
      </Card>
      <CheckModal
        guidelineId={guidelineId}
        id={evaluateId}
        isPreflight={isPreflight}
        onClose={() => setEvaluateId(null)}
        onComplete={(id) => handleCheckComplete(id)}
      />
    </>
  );
}

Checks.propTypes = propTypes;
Checks.defaultProps = defaultProps;

export default Checks;
