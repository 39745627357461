import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Tooltip,
} from '@makeably/creativex-design-system';
import EvaluationPageGuidelineChecks, { propTypes as guidelineCheckProps } from 'components/admin/EvaluationPageGuidelineChecks';
import DsTabs from 'components/molecules/DsTabs';
import { HiddenInput } from 'components/reusable/HiddenInput';
import PostRequestNavDropdown, { propTypes as dropdownProps } from 'components/reusable/PostRequestNavDropdown';
import { adminAuditPostsEscalateChallengePath } from 'utilities/routes';
import styles from './EvaluationPageForm.module.css';

const MARK_REVIEWABLE_FORM_NAME = 'markReviewableForm';
const ESCALATE_AUDIT_POST_FORM_NAME = 'escalateAuditPostForm';

const formActionProps = {
  reviewableUrl: PropTypes.string, // url to mark the asset as reviewable
  skipUrl: PropTypes.string, // url of the next audit post to be reviewed
  unreviewableOptions: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape(dropdownProps.options),
    ),
  }),
};

export const propTypes = {
  ...formActionProps,
  formToken: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
  guidelineChecks: PropTypes.arrayOf(
    PropTypes.shape(guidelineCheckProps.guidelineChecks),
  ).isRequired,
  isReviewable: PropTypes.bool.isRequired, // is the audit post reviewable?
  organizationId: PropTypes.number.isRequired,
  postId: PropTypes.number.isRequired,
  source: PropTypes.shape({
    creativeUrl: PropTypes.string,
    isVideo: PropTypes.bool,
  }).isRequired,
  challengeState: PropTypes.oneOf(['pending', 'reviewed', 'escalated']),
  singleCheckReview: PropTypes.bool,
};

const defaultProps = {
  challengeState: undefined,
  singleCheckReview: false,
};

function renderSkipButton(skipUrl) {
  return (
    <div className="u-marginLeft">
      <Button
        label="Skip"
        url={skipUrl}
        variant="secondary"
      />
    </div>
  );
}

function renderUnreviewableDropdown({ options }) {
  return (
    <div className="u-marginLeft">
      <PostRequestNavDropdown
        buttonProps={{ lg: true }}
        buttonText="Mark as Unreviewable"
        options={options}
      />
    </div>
  );
}

function renderReviewableButton() {
  return (
    <div className="u-marginLeft">
      <Button
        label="Mark as Reviewable"
        variant="secondary"
        onClick={() => document[MARK_REVIEWABLE_FORM_NAME].submit()}
      />
    </div>
  );
}

function renderEscalateButton() {
  return (
    <div className={styles.actions}>
      <Button
        label="Escalate"
        variant="secondary"
        onClick={() => document[ESCALATE_AUDIT_POST_FORM_NAME].submit()}
      />
      <Tooltip label="For any scores you are unsure of" />
    </div>
  );
}

function formIncomplete(props) {
  const {
    guidelineChecks,
    isReviewable,
  } = props;

  const hasUncheckedRules = guidelineChecks.findIndex((check) => check.passed === null) >= 0;
  const hasMissingJustifications = guidelineChecks
    .some((check) => ['exemption', 'freeformJustification'].includes(check.challengeUserReason)
      && check.reviewerJustification === '');

  return isReviewable && (hasUncheckedRules || hasMissingJustifications);
}

function renderGuidelineCheckHiddenInputs(guidelineChecks) {
  return guidelineChecks.map((check) => {
    const {
      id,
      passed,
      checkEditable,
      reviewerJustification,
      challengeUserReason,
    } = check;

    // automated guidelines are never editable but can have a challenge
    // and challenged reviewer justification
    // this check allows for the challenge to be passed through
    // to the update call for automated guidelines
    if (!checkEditable && !challengeUserReason) return null;

    // a blank checkbox is equivalent to a failed guideline check
    const inputValue = passed === null ? false : passed;
    const hiddenInputs = [
      <HiddenInput key={id} name="guideline_checks[][id]" value={id} />,
      <HiddenInput key={`${id}_passed`} name="guideline_checks[][passed]" value={inputValue} />,
    ];

    if (challengeUserReason) {
      hiddenInputs.push(
        <HiddenInput
          key={`${id}_is_challenged`}
          name="guideline_checks[][is_challenged]"
          value
        />,
      );
    }
    if (reviewerJustification !== '') {
      hiddenInputs.push(
        <HiddenInput
          key={`${id}_reviewer_justification`}
          name="guideline_checks[][reviewer_justification]"
          value={reviewerJustification}
        />,
      );
    }

    return hiddenInputs;
  });
}

// This is the form used by the admin preflight/inflight queue
// to evaluate an audit post.
class EvaluationPageForm extends React.Component {
  constructor(props) {
    super(props);

    const { guidelineChecks } = this.props;

    this.state = {
      activeTab: 'Partially Automated Rules',
      guidelineChecks: guidelineChecks.map((check) => ({
        ...check,
        initialPassed: check.passed,
        reviewerJustification: check.noChangeCopy,
      })),
    };
  }

  onGuidelineClick(id) {
    this.setState((prevState) => {
      const updatedGuidelineChecks = prevState.guidelineChecks;
      const updatedCheck = updatedGuidelineChecks.find((check) => check.id === id);
      updatedCheck.passed = !updatedCheck.passed;

      if (updatedCheck.passed === updatedCheck.initialPassed) {
        if (updatedCheck.noChangeCopy) {
          updatedCheck.reviewerJustification = updatedCheck.noChangeCopy;
        }
      } else if (updatedCheck.changeCopy) {
        updatedCheck.reviewerJustification = updatedCheck.changeCopy;
      }

      return { guidelineChecks: updatedGuidelineChecks };
    });
  }

  onGuidelineJustificationChange(id, reviewerJustification) {
    this.setState((prevState) => {
      const updatedGuidelineChecks = prevState.guidelineChecks;
      const updatedCheck = updatedGuidelineChecks.find((check) => check.id === id);
      updatedCheck.reviewerJustification = reviewerJustification;

      return { guidelineChecks: updatedGuidelineChecks };
    });
  }

  getTabs() {
    return [
      {
        label: 'Partially Automated Rules',
        onClick: () => this.setState({ activeTab: 'Partially Automated Rules' }),
      },
      {
        label: 'Automated Rules',
        onClick: () => this.setState({ activeTab: 'Automated Rules' }),
      },
    ];
  }

  renderFormActions() {
    const {
      challengeState,
      isReviewable,
      postId,
      reviewableUrl,
      skipUrl,
      unreviewableOptions,
    } = this.props;

    const { guidelineChecks } = this.state;

    const isDisabled = formIncomplete({
      guidelineChecks,
      isReviewable,
    });

    const postEditable = guidelineChecks.some((check) => {
      const automatedCheckChallenge = check.automated && check.challengeUserReason;

      return check.checkEditable || automatedCheckChallenge;
    });

    if (!postEditable) return null;

    const options = unreviewableOptions?.options;

    return (
      <div className={styles.actionRow}>
        <div className={styles.actions}>
          <Button
            disabled={isDisabled}
            label="Evaluate"
            type="submit"
          />
          { skipUrl && renderSkipButton(skipUrl) }
          { options && options.length > 0 && renderUnreviewableDropdown(unreviewableOptions) }
          { reviewableUrl && renderReviewableButton() }
        </div>
        { challengeState === 'pending' && renderEscalateButton(postId) }

      </div>
    );
  }

  render() {
    const {
      challengeState,
      formToken,
      formUrl,
      organizationId,
      postId,
      reviewableUrl,
      singleCheckReview,
      source,
    } = this.props;
    const {
      activeTab,
      guidelineChecks,
    } = this.state;

    return (
      <Card>
        <form action={formUrl} method="post">
          <HiddenInput name="_method" value="patch" />
          <HiddenInput name="authenticity_token" value={formToken} />
          { this.renderFormActions() }
          { !singleCheckReview && (
            <DsTabs
              currentTab={activeTab}
              tabs={this.getTabs()}
              variant="button"
            />
          ) }
          { renderGuidelineCheckHiddenInputs(guidelineChecks) }
          <EvaluationPageGuidelineChecks
            callback={(id) => this.onGuidelineClick(id)}
            guidelineChecks={guidelineChecks}
            organizationId={organizationId}
            source={source}
            view={activeTab}
            onGuidelineJustificationChange={
              (id, justification) => this.onGuidelineJustificationChange(id, justification)
            }
          />
        </form>
        { reviewableUrl && (
          <form action={reviewableUrl} method="post" name={MARK_REVIEWABLE_FORM_NAME}>
            <HiddenInput name="_method" value="put" />
            <HiddenInput name="authenticity_token" value={formToken} />
          </form>
        ) }
        { challengeState === 'pending' && (
          <form action={adminAuditPostsEscalateChallengePath()} method="post" name={ESCALATE_AUDIT_POST_FORM_NAME}>
            <HiddenInput name="audit_post_id" value={postId} />
            <HiddenInput name="authenticity_token" value={formToken} />
          </form>
        ) }
      </Card>
    );
  }
}

EvaluationPageForm.propTypes = propTypes;
EvaluationPageForm.defaultProps = defaultProps;

export default EvaluationPageForm;
