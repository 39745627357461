// Component for a Marketing Data Short
// @note: Not super generic at this point in time
// on the next iteration see if it is possible
// to abstract out a bit, or make this component
// more specifically named
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@duik/it';
import { Icon } from '@makeably/creativex-design-system';
import NavProgressBar from 'components/navigation/NavProgressBar';
import ButtonLink from 'components/reusable/ButtonLink';

function Content({ ctaLink, step }) {
  let content;
  const key = `dataShort-content-${step}`;
  const classes = classNames(
    'dataShort-content',
    { animate__animated: [1, 2, 3, 5].includes(step) },
    { animate__fadeInUp: [1, 2, 3, 5].includes(step) },
  );

  switch (step) {
    case 'cover':
      content = (
        <div key={key} className={classes}>
          <div className="dataShort-contentHeader">
            <b>The Rising Need for Creative Quality</b>
            { ' ' }
            and 5 Best Practices to Increase ROI
          </div>
          <div className="dataShort-contentBody">
            Today, creative drives the majority of campaign impact but is the
            least understood part of the marketing mix. We’ve combed through
            hundreds of studies by Facebook, Twitter, YouTube, and Snap to
            isolate 5 near-universal creative best practices repeatedly proven to
            drive brand lift and ROI.
          </div>
        </div>
      );
      break;
    case 1:
      content = (
        <div key={key} className={classes}>
          The number of ads has gone up by a
          { ' ' }
          <b>factor of 6</b>
          { ' ' }
          in the last 20 years while the number of advertisers
          { ' ' }
          <b>nearly halved</b>
          .
          <sup>1</sup>
        </div>
      );
      break;
    case 2:
      content = (
        <div key={key} className={classes}>
          Our data shows out of some of the world&apos;s
          best advertisers, only
          { ' ' }
          <b>20% of their creatives</b>
          { ' ' }
          meet  their standards of excellence.
        </div>
      );
      break;
    case 3:
      content = (
        <div key={key} className={classes}>
          Up to
          { ' ' }
          <b>80% of a campaign&apos;s ROI</b>
          { ' ' }
          derives from the creative itself, while targeting and media
          planning typically account for only
          { ' ' }
          <b>5% to 35% of total impact</b>
          .
          <sup>2</sup>
        </div>
      );
      break;
    case 4:
      content = (
        <div key={key} className={classes}>
          <div className="animate__animated animate__fadeIn">
            Digital ad spend now makes up
            { ' ' }
            <b>54% of total ad spend</b>
            , with most of it going to channels like Facebook, YouTube, and
            Snapchat.
            <sup>3</sup>
          </div>
          <div className="dataShort-contentBody animate__animated dataShort--beigeText animate__fadeIn">
            Closing the gap between quantity of creatives and quality of
            creative execution on these channels could help marketers see
            some of their biggest gains in ROI.
          </div>
        </div>
      );
      break;
    case 5:
      content = (
        <div key={key} className={classes}>
          Facebook, YouTube, Snapchat, and Twitter have data-driven creative
          best practices - shown to deliver 30% higher unaided brand recall,
          28% longer view time, and a
          { ' ' }
          <b>24% increase in positive interest</b>
          .
          <sup>4</sup>
        </div>
      );
      break;
    case 6:
      content = (
        <div key={key} className={classes}>
          <div className="dataShort-contentHeader animate__animated animate__fadeInDown">
            { 'Best Practices \nfor Creative Quality:' }
          </div>
          <div className="dataShort-contentBody animate__animated  animate__fadeInUp animate__delay-1s">
            <div>
              Capture attention
              { ' ' }
              <div className="highlight">fast</div>
            </div>
            <div>
              Keep it
              { ' ' }
              <div className="highlight">snappy</div>
            </div>
            <div>
              Showcase
              { ' ' }
              <div className="highlight">people</div>
              { ' and ' }
              <div className="highlight">products</div>
            </div>
            <div>
              Amp up your
              { ' ' }
              <div className="highlight">audio</div>
              { ' ' }
              game
            </div>
            <div>
              Create for
              { ' ' }
              <div className="highlight">mobile</div>
            </div>
          </div>
        </div>
      );
      break;
    case 7:
      content = (
        <div key={key} className={classes}>
          <div className="dataShort-contentHeader animate__animated dataShort--beigeText animate__fadeInUp">
            { 'Capture \nattention ' }
            <b>fast.</b>
          </div>
          <div className="dataShort-contentBody animate__animated animate__fadeInUp animate__delay-1s">
            Tactically, &quot;brand early and often&quot; is optimal,
            delivering better ad and brand recall with no negative impact on
            viewer reaction.
            <sup>5</sup>
            { ' ' }
            To achieve this in-feed, your opening imagery needs to be able to
            stop a thumb from scrolling.
          </div>
        </div>
      );
      break;
    case 8:
      content = (
        <div key={key} className={classes}>
          <div className="dataShort-contentHeader animate__animated animate__fadeInUp">
            Keep it
            { ' ' }
            <b>snappy.</b>
          </div>
          <div className="dataShort-contentBody animate__animated animate__fadeInUp animate__delay-1s">
            Ensure your creative has a single idea if you want people to
            remember it.
            <sup>6</sup>
            { ' ' }
            Use one message and short copy (ads with less text tend to
            perform better).
            <sup>7</sup>
            { ' ' }
            Cap your creative at 15 seconds or fewer. According to Facebook,
            the top-performing Stories ads are under 10 seconds in length. On
            Snap, the sweet spot is 5-6 seconds.
          </div>
        </div>
      );
      break;
    case 9:
      content = (
        <div key={key} className={classes}>
          <div className="dataShort-contentHeader animate__animated animate__fadeInUp">
            Showcase
            { ' ' }
            <b>people</b>
            { '\nand ' }
            <b>products.</b>
          </div>
          <div className="dataShort-contentBody animate__animated animate__fadeInUp animate__delay-1s">
            The best creative depends on the desired outcome. Video ads
            showcasing human interaction are more likely to generate market
            share, profit, and sales gain than ads that don&apos;t.
            <sup>8</sup>
            { ' ' }
            If the goal is to drive direct response and web traffic, start
            your video with a product image as there&apos;s a 24% increase in
            positive interest and a 34% increase in click intent.
            <sup>9</sup>
          </div>
        </div>
      );
      break;
    case 10:
      content = (
        <div key={key} className="dataShort-content">
          <div className="dataShort-contentHeader animate__animated animate__fadeInUp">
            Amp up your
            { ' \n' }
            <b>audio</b>
            { ' ' }
            game.
          </div>
          <div className="dataShort-contentBody animate__animated animate__fadeInUp animate__delay-1s">
            Audio rules vary by platform, so you need to take a
            channel-specific approach. Facebook and Twitter default to
            sound-off for ads, so use text and graphics to help tell your
            story. Captions help viewers follow your story without sound.
            Captioned videos on Twitter drove 28% longer watch times.
            <sup>10</sup>
          </div>
        </div>
      );
      break;
    case 11:
      content = (
        <div key={key} className={classes}>
          <div className="dataShort-contentHeader animate__animated animate__fadeInUp">
            { 'Create \nfor ' }
            <b>mobile.</b>
          </div>
          <div className="dataShort-contentBody animate__animated animate__fadeInUp animate__delay-1s">
            Most people access social media on mobiles. People hold their
            phones vertically 98% of the time, which means that square and
            vertical layouts are essential for strong ad performance.
            <sup>11</sup>
            { ' ' }
            Mobile-first production leads to different choices in framing,
            composition, and narrative storylines. Mobile ads should be easy
            to follow on a small screen and be bite-sized in nature.
          </div>
        </div>
      );
      break;
    case 12:
      content = (
        <div key={key} className={classes}>
          <div className="animate__animated animate__fadeInUp">
            Sources
          </div>
          <div className="animate__animated animate__fadeInUp animate__delay-1s">
            <ol className="dataShort-contentBody">
              <li>Nielsen via Campaign</li>
              <li>Catalina via ARF, 2017</li>
              <li>eMarketer, 2020</li>
              <li>YouTube, 2019; Facebook, 2019; Twitter, 2020</li>
              <li>YouTube, 2019; Facebook, 2019; Twitter, 2020</li>
              <li>Kantar MIllward Brown</li>
              <li>Facebook and Oracle, Dec 2017</li>
              <li>Achtung! Orlando Wood, 2020</li>
              <li>GroupM + Twitter, MetrixLab, Video Optimization, October 2019</li>
              <li>GroupM + Twitter, MetrixLab, Video Optimization, October 2019</li>
              <li>Ogilvy, 2017</li>
            </ol>
          </div>
        </div>
      );
      break;
    case 'conclusion':
      content = (
        <div key={key} className={classes}>
          <div className="animate__animated animate__fadeInUp">
            About CreativeX
          </div>
          <div className="dataShort-contentBody animate__animated animate__fadeInUp animate__delay-1s">
            <b>CreativeX</b>
            { ' ' }
            powers creative excellence for the world&apos;s most loved
            brands. By analyzing creativity at scale, our technology aims to
            advance creative expression through the clarity of data. Used
            globally by brands like Unilever, Mondelez, Heineken, ABI, Google,
            and more, CreativeX measures and improves creative quality,
            efficiency, and consistency across all their creatives worldwide.
          </div>
          <div className="dataShort-cta animate__animated animate__fadeInUp animate__delay-2s">
            <ButtonLink
              label="Learn More"
              path={ctaLink}
              trackingEventName="Data Short CTA"
              lg
              primary
            />
          </div>
        </div>
      );
      break;
    default:
      content = null;
  }

  return content;
}

function renderImage(step) {
  const cdnUrl = 'https://picassolabs.s3.amazonaws.com';
  const basePath = '/assets/landing-pages/data-shorts/creative-best-practices/';

  // To force the animated pngs to reload every time,
  // append a timestamp so that the browser doesn't cache
  // the image and make it static
  const timestamp = Date.now().toString();
  const imgUrl = `${basePath}${step}.png?${timestamp}`;

  const style = {
    backgroundImage: `url(${cdnUrl + imgUrl})`,
  };

  return <div className="dataShort-graphic" style={style} />;
}

function Graphic({ step }) {
  let graphic;
  const classes = classNames(
    'dataShort-graphic',
    { animate__animated: [1, 6].includes(step) },
    { animate__fadeIn: [1, 6].includes(step) },
    { 'animate__delay-1s': step === 1 },
    { 'animate__delay-3s': step === 6 },
    { 'dataShort--teal': step === 'conclusion' },
  );

  switch (step) {
    case 3:
    case 5:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
      graphic = renderImage(step);
      break;
    case 'cover':
    case 6:
    case 'conclusion':
      graphic = <div className={classes} />;
      break;
    case 1:
      graphic = (
        <div className={classes}>
          <div className="movingBar" />
          <div className="movingBar" />
        </div>
      );
      break;
    case 2:
      graphic = (
        <div className={classes}>
          <div className="circle dataShort--beige" />
          <div className="wedgeContainer">
            <div className="wedge dataShort--purple">
              <div className="wedgeNumber dataShort--beigeText animate__animated animate__delay-1s animate__fadeIn">
                20
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case 4:
      graphic = (
        <div className="curtainAnimation">
          <div className="topCurtain dataShort--orange" />
          <div className="arrows" />
          <div className="animate__animated animate__fadeIn animate__delay-2s">
            <div className="bottomCurtain dataShort--blue" />
          </div>
          <div className="dataShort-graphic animate__animated animate__fadeInUp animate__delay-4s" />
        </div>
      );
      break;
    default:
      graphic = null;
  }

  return graphic;
}

function slideClasses(step) {
  return classNames(
    'dataShort',
    `dataShort--${step}`,
    { 'dataShort--beige': [3, 4, 'conclusion'].includes(step) },
    { 'dataShort--blue': [6, 12].includes(step) },
    { 'dataShort--peach': [2, 8].includes(step) },
    { 'dataShort--purple': [5, 9].includes(step) },
    { 'dataShort--red': step === 10 },
    { 'dataShort--teal': step === 'cover' },
    { 'dataShort--violet': [1, 7, 11].includes(step) },
    { 'dataShort--blackText': [2, 4, 'conclusion'].includes(step) },
    { 'dataShort--purpleText': step === 3 },
  );
}

class DataShort extends React.Component {
  static stepCount() {
    return 12;
  }

  static isCover(step) {
    return step === 'cover';
  }

  static isConclusion(step) {
    return step === 'conclusion';
  }

  constructor(props) {
    super(props);

    this.state = {
      step: 'cover',
    };
  }

  prevStep() {
    this.setState((prevState) => {
      let step;

      if (DataShort.isConclusion(prevState.step)) {
        step = DataShort.stepCount();
      } else if (prevState.step === 1) {
        step = 'cover';
      } else {
        step = prevState.step - 1;
      }

      return { step };
    });
  }

  nextStep() {
    this.setState((prevState) => {
      let step;

      if (DataShort.isCover(prevState.step)) {
        step = 1;
      } else if (prevState.step === DataShort.stepCount()) {
        step = 'conclusion';
      } else {
        step = prevState.step + 1;
      }

      return { step };
    });
  }

  renderPrevButton(step) {
    if (DataShort.isCover(step)) return null;

    return (
      <Button className="dataShort-navLeft" clear onClick={() => this.prevStep()}>
        <Icon color="white" name="chevronLeft" noWrapper />
      </Button>
    );
  }

  renderNextButton(step) {
    if (DataShort.isConclusion(step)) return null;

    return (
      <Button className="dataShort-navRight" clear onClick={() => this.nextStep()}>
        <Icon color="white" name="chevronRight" noWrapper />
      </Button>
    );
  }

  render() {
    const { step } = this.state;

    let navBar;
    if (!DataShort.isCover(step) && !DataShort.isConclusion(step)) {
      navBar = <NavProgressBar currentStep={step} stepCount={DataShort.stepCount()} />;
    }

    let wordmark;
    if (DataShort.isCover(step)) {
      wordmark = <div className="dataShort-wordmark" />;
    }

    let link;
    if (DataShort.isConclusion(step)) {
      const { ctaLink } = this.props;
      link = ctaLink;
    }

    return (
      <div className={slideClasses(step)}>
        { navBar }
        { wordmark }
        { this.renderPrevButton(step) }
        <Content ctaLink={link} step={step} />
        <Graphic step={step} />
        { this.renderNextButton(step) }
      </div>
    );
  }
}

DataShort.propTypes = {
  ctaLink: PropTypes.string.isRequired,
};

export default DataShort;
