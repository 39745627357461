import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Icon,
  Table,
} from '@makeably/creativex-design-system';
import { editAdminCompanySsoConfigurationPath } from 'utilities/routes';
import styles from './Show.module.css';

const propTypes = {
  companyId: PropTypes.number.isRequired,
  ssoConfig: PropTypes.shape({
    attributes: PropTypes.arrayOf(
      PropTypes.shape({
        cxKey: PropTypes.string,
        idpKey: PropTypes.string,
      }),
    ),
    certificate: PropTypes.string,
    entityId: PropTypes.string,
    id: PropTypes.number,
    idpDisplayName: PropTypes.string,
    targetUrl: PropTypes.string,
    urlDisplayName: PropTypes.string,
  }).isRequired,
};

function Show({
  companyId,
  ssoConfig: {
    attributes,
    certificate,
    entityId,
    id,
    idpDisplayName,
    targetUrl,
    urlDisplayName,
  },
}) {
  const rows = [
    {
      cells: [
        {
          className: styles.column,
          value: 'Url Display Name',
        },
        { value: urlDisplayName },
      ],
      key: 'urlDisplayName',
    },
    {
      cells: [
        { value: 'Entity ID' },
        { value: entityId },
      ],
      key: 'entityId',
    },
    {
      cells: [
        { value: 'Target URL' },
        { value: targetUrl },
      ],
      key: 'targetUrl',
    },
    {
      cells: [
        { value: 'Certificate' },
        {
          className: styles.certificate,
          value: certificate,
        },
      ],
      key: 'certificate',
    },
    {
      cells: [
        { value: 'Identity Provider' },
        { value: idpDisplayName },
      ],
      key: 'idp',
    },
  ];

  const attributeHeaders = [
    { value: 'Identity Provider Key' },
    { value: '' },
    { value: 'CreativeX Key' },
  ];
  const rightArrow = <Icon name="arrowRight" />;
  const attributeRows = attributes.map(({ cxKey, idpKey }) => ({
    cells: [
      { value: idpKey },
      { value: rightArrow },
      { value: cxKey },
    ],
    key: idpKey,
  }));

  return (
    <Card>
      <div className={styles.header}>
        <h5>SSO Configuration</h5>
        <Button
          label="Edit"
          url={editAdminCompanySsoConfigurationPath(companyId, id)}
          variant="tertiary"
        />
      </div>
      <Divider />
      <Table className={styles.table} rows={rows} />
      <h5 className={styles.title}>Attributes</h5>
      <Divider />
      <Table
        className={styles.table}
        headers={attributeHeaders}
        rows={attributeRows}
      />
    </Card>
  );
}

Show.propTypes = propTypes;

export default Show;
