import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: '',
};

function Pill({
  active,
  disabled,
  label,
  onChange,
  title,
  value,
}) {
  const classes = classNames(
    'pill',
    {
      active,
      disabled,
    },
  );

  const handleClick = () => {
    if (disabled) return;
    if (!active) {
      onChange(value);
    }
  };

  const renderLabel = <span>{ label }</span>;

  return (
    <button
      className={classes}
      title={title}
      type="button"
      onClick={handleClick}
    >
      { renderLabel }
    </button>
  );
}

Pill.propTypes = propTypes;
Pill.defaultProps = defaultProps;

export default Pill;
