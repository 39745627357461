import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'components/account_setup/shared_steps/AdAccountForm';
import Table, { propTypes as tableProps } from 'components/molecules/Table';

const propTypes = {
  addMixpanelData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  table: PropTypes.shape({
    headers: tableProps.headers,
    rows: tableProps.rows,
  }).isRequired,
};

function getUniqueCount(rows, attribute) {
  return [...new Set(rows.map((row) => row.data[attribute].value))].length;
}

function Confirmation({
  addMixpanelData,
  formData,
  table: {
    headers,
    rows: propRows,
  },
}) {
  useEffect(() => {
    addMixpanelData({
      totalAdAccounts: propRows.length,
      uniqueBrands: getUniqueCount(propRows, 'brand'),
      uniqueMarkets: getUniqueCount(propRows, 'market'),
    });
  }, []);

  const rows = propRows.map((propRow) => {
    const row = propRow;
    row.key = `${row.data.businessCenterApiId}_${row.data.apiId}`;
    return row;
  });

  return (
    <div>
      <Table
        headers={headers}
        rows={rows}
        title="Account Connections"
      />
      <Form
        adAccounts={rows.map((row) => row.data)}
        formData={formData}
      />
    </div>
  );
}

Confirmation.propTypes = propTypes;

export default Confirmation;
