import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
} from '@duik/it';
import { HiddenInput } from 'components/reusable/HiddenInput';
import SearchableSelect from 'components/reusable/SearchableSelect';

const propTypes = {
  input: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
};

class SimpleSearchableSelect extends React.Component {
  constructor(props) {
    super(props);

    const { options } = this.props;

    this.state = {
      activeOption: options[0],
    };
  }

  render() {
    const { activeOption } = this.state;
    const { input, options } = this.props;

    return (
      <FormGroup>
        <HiddenInput name={input} value={activeOption.value} />
        <div className="u-flexRow">
          <SearchableSelect
            activeOption={activeOption}
            options={options}
            onOptionClick={
              (option) => this.setState({ activeOption: option })
            }
          />
        </div>
      </FormGroup>
    );
  }
}

SimpleSearchableSelect.propTypes = propTypes;

export default SimpleSearchableSelect;
