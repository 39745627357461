import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CollapsibleWidget from 'components/molecules/CollapsibleWidget';
import styles from './PostCopy.module.css';

export const propTypes = {
  postCopy: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string,
      version: PropTypes.number,
    }),
  ),
};

export const defaultProps = {
  postCopy: undefined,
};

function PostCopy({ postCopy }) {
  if (!postCopy) return null;

  return (
    <CollapsibleWidget headerText="Post Copy">
      {
        postCopy.map(({ copy, version }) => (
          <div key={version || 1} className={styles.postCopy}>
            { version && (<div className={classNames('t-label-1', styles.versionLabel)}>{ `Version ${version}` }</div>) }
            <div>{ copy }</div>
          </div>
        ))
      }
    </CollapsibleWidget>
  );
}

PostCopy.propTypes = propTypes;
PostCopy.defaultProps = defaultProps;

export default PostCopy;
