import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  BasicModal,
  Button,
  CloseButton,
  Dropdown,
  Spinner,
} from '@makeably/creativex-design-system';
import CheckDisplay from 'components/admin/review_queue/CheckDisplay';
import EvaluationBanner from 'components/admin/review_queue/preflight/PreflightEvaluationBanner';
import {
  editAdminReviewGuidelineCheckUrl,
  propsAdminReviewGuidelineCheckPath,
} from 'utilities/routes';
import styles from './CheckModal.module.css';
import {
  isBool,
  postEvaluated,
  postUnreviewable,
  processCheckData,
} from './shared';

const propTypes = {
  guidelineId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  id: PropTypes.number,
  isPreflight: PropTypes.bool,
};

const defaultProps = {
  id: undefined,
  isPreflight: false,
};

const unreviewableDefault = null;

function getUnreviewableOptions(unreviewableReasons) {
  if (!unreviewableReasons) return [];

  const labels = {
    wrong_brand: 'Wrong Brand',
    wrong_market: 'Wrong Market',
    missing_or_broken_creative: 'Missing or Broken Creative',
    other: 'Other',
  };
  const defaultOption = {
    disabled: true,
    label: 'Mark Reviewable',
    value: unreviewableDefault,
  };
  const options = unreviewableReasons.map((value) => ({
    label: labels[value],
    value,
  }));

  return [defaultOption, ...options];
}

function updateUnreviewableDefault(disabled) {
  return (options) => (
    options.map((option) => {
      if (option.value === unreviewableDefault) {
        return {
          ...option,
          disabled,
        };
      }
      return option;
    })
  );
}

async function getCheck(guidelineId, id) {
  try {
    const url = propsAdminReviewGuidelineCheckPath(guidelineId, id);
    const response = await fetch(url);
    return response.json();
  } catch (e) {
    return { reviewable: false };
  }
}

function renderLoader(showLoader) {
  if (!showLoader) return null;

  return (
    <div className={styles.loader}>
      <Spinner />
      <h4>Loading...</h4>
    </div>
  );
}

function CheckModal({
  guidelineId,
  id,
  isPreflight,
  onClose,
  onComplete,
}) {
  const [check, setCheck] = useState(null);
  const [passed, setPassed] = useState(null);
  const [unreviewableOptions, setUnreviewableOptions] = useState([]);
  const [unreviewableSelection, setUnreviewableSelection] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [needsValidation, setNeedsValidation] = useState(false);
  const isUnreviewable = Boolean(unreviewableSelection);
  const isActionable = isUnreviewable ? true : (isBool(passed) && !needsValidation);
  const checkLoaded = Boolean(check);
  const [checkShown, setCheckShown] = useState(false);

  useEffect(() => {
    const updateCheck = async () => {
      const data = await getCheck(guidelineId, id);

      if (data.reviewable) {
        setCheck(processCheckData(data));
        setUnreviewableOptions(getUnreviewableOptions(data.availableUnreviewableReasons));
      } else {
        onComplete(id);
      }
    };

    setIsBusy(true);

    if (id) {
      setCheckShown(false);
      updateCheck(id);
    }
  }, [id]);

  useEffect(() => {
    if (check && checkShown) {
      setIsBusy(false);
      setPassed(check.passed);
      setNeedsValidation(check.cv && isBool(check.passed));
    }
  }, [check, checkShown]);

  const handleClose = () => {
    setCheck(null);
    onClose();
  };

  const handleReviewableChange = (option) => {
    if (option.value === unreviewableDefault) {
      setUnreviewableSelection(null);
      setUnreviewableOptions(updateUnreviewableDefault(true));
    } else {
      setUnreviewableSelection(option);
      setUnreviewableOptions(updateUnreviewableDefault(false));
    }
  };

  const handleAction = async () => {
    setCheck(null);
    setIsBusy(true);

    if (isUnreviewable && !isPreflight) {
      await postUnreviewable(guidelineId, id, unreviewableSelection.value);
    } else {
      await postEvaluated(guidelineId, id, passed, check.reviewStartAt);
    }

    setUnreviewableSelection(null);
    onComplete(id);
  };

  const renderControls = () => (
    <div className={styles.controlsWrapper}>
      <div className={styles.controls}>
        <Button
          label="Close"
          variant="tertiary"
          onClick={handleClose}
        />
        { !isPreflight && (
          <Dropdown
            disabled={isBusy}
            menuProps={{
              size: 'medium',
              position: 'top-right',
            }}
            options={unreviewableOptions}
            placeholder="Mark as Unreviewable"
            selected={unreviewableSelection}
            size="medium"
            onChange={handleReviewableChange}
          />
        ) }
        <Button
          disabled={isBusy || !isActionable}
          label={isUnreviewable ? 'Save' : 'Evaluate'}
          variant="primary"
          onClick={handleAction}
        />
      </div>
      { isPreflight && <EvaluationBanner /> }
    </div>
  );

  return (
    <BasicModal
      footer={renderControls()}
      header={<CloseButton onClick={handleClose} />}
      isOpen={Boolean(id)}
      onClose={handleClose}
    >
      { checkLoaded && (
        <CheckDisplay
          check={check}
          isBusy={isBusy}
          needsValidation={needsValidation}
          passed={passed}
          url={editAdminReviewGuidelineCheckUrl(guidelineId, check.id)}
          onAssetReady={() => setCheckShown(true)}
          onNeedsValidationChange={(value) => setNeedsValidation(value)}
          onPassedChange={(value) => setPassed(value)}
        />
      ) }
      { renderLoader(!checkLoaded) }
    </BasicModal>
  );
}

CheckModal.propTypes = propTypes;
CheckModal.defaultProps = defaultProps;

export default CheckModal;
