// Items are objects with keys and values for displaying data
// An item could represent an account, user, etc, and might be displayed as a row in a table
// Each item should have an 'id' with a unique value, and several other display keys/values
//
// Each display value is itself an object with the following keys:
//   value: a sortable string or number
//   format: a type that describes how to transform 'value' into 'label' (i.e. 'date', OPTIONAL)
//   label: a searchable string ('value' is searched if 'label' is not defined, OPTIONAL)
//   display: an object that describes how to transform 'label' into 'element' (OPTIONAL)
//   element: an HTML display element (link, highlighted label, etc., OPTIONAL)
//
// Items are sorted by 'value'
// Items are searched by 'label', with fallback to 'value' if 'label' is not defined
// Items display their content as 'element', with fallback to 'label', and then 'value'
//
// Functions for generating 'label' from 'format' and 'value' are in itemText.js
// Functions for generating 'element' from 'display' and 'label' are in itemElement.jsx
import PropTypes from 'prop-types';
import { getCsv } from 'utilities/csv';

export const itemValueProps = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]).isRequired,
  display: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  element: PropTypes.element,
  format: PropTypes.string,
  ignoreFilter: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
  ),
  text: PropTypes.string, // TODO: Remove once all items are update to use label instead of text
});
export const itemProps = PropTypes.objectOf(itemValueProps);

function getDimensionLabel(dimension) {
  return dimension?.label ?? dimension?.text ?? dimension?.value ?? '';
}

function getDimensionConcise(dimension) {
  return dimension?.concise ?? dimension?.label ?? dimension?.value ?? '';
}

export function getItemLabel(item, key) {
  return getDimensionLabel(item[key]);
}

export function getItemConcise(item, key) {
  return getDimensionConcise(item[key]);
}

// TODO: Remove getItemText once all items are update to use label instead of text
export function getItemText(item, key) {
  console.warn('getItemText is deprecated. Use getItemLabel instead'); // eslint-disable-line no-console

  return getDimensionLabel(item[key]);
}

export function getItemContent(item, key) {
  const dimension = item[key];

  return dimension?.element ?? getDimensionLabel(dimension);
}

// CSV exporting helper functions
function getItemCsvText(item, key) {
  if (item[key]?.format === 'date') {
    const value = item[key]?.value ?? '';
    return value.replaceAll('_', '-');
  }
  const itemLabel = getItemLabel(item, key);

  if (typeof itemLabel === 'number') {
    return itemLabel;
  }

  if (itemLabel.includes(',')) {
    return `"${itemLabel}"`;
  }
  return itemLabel;
}

// Convert items to a CSV string
// The CSV columns are defined by categories, an array of objects
// Each object has the form: { key, label }
// - label is the CSV label at the top of each column
// - key determines which data is read from each item to display in that column
export function getItemsCsv(items, categories) {
  const keys = categories.map((category) => category.key);
  const labelRow = categories.map((category) => category.label.replaceAll('\n', ' ')).join(',');
  const rows = items.map((item) => (
    keys.map((key) => getItemCsvText(item, key)).join(',')
  ));
  return [labelRow, ...rows].join('\n');
}

// Convert items to a CSV string when using the ConfigurableTable component
export function getItemsConfigurableCsv(items, categories) {
  const resolvedCategories = categories.map((category) => ({
    ...category,
    valueFn: (item) => getItemCsvText(item, category.key),
  }));
  return getCsv(items, resolvedCategories);
}

// Sorting helper functions
export function getItemSortBy(key, asc) {
  return (a, b) => {
    const aValue = a[key]?.value;
    const bValue = b[key]?.value;

    if (aValue === bValue) {
      return 0;
    }

    // Sort null last
    if (aValue === null) {
      return 1;
    }
    if (bValue === null) {
      return -1;
    }

    if (aValue > bValue) {
      return asc ? 1 : -1;
    }

    return asc ? -1 : 1;
  };
}
