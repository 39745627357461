// File is used for business logic / display logic for the Facebook Ad Account
// linking
import AdAccountAssignment from 'components/account_setup/meta_ads/steps/AdAccountAssignment';
import AdAccountSelection from 'components/account_setup/meta_ads/steps/AdAccountSelection';
import BusinessCenterSelection from 'components/account_setup/shared_steps/BusinessCenterSelection';
import Confirmation from 'components/account_setup/shared_steps/Confirmation';

const META_BASE_URL = 'https://www.facebook.com/business/help';
const CX_INVITE_USER_URL = '/settings/users/new';
const CX_META_ADS_START = '/auth/meta_ads';

class StepHelper {
  static getContentTag(view) {
    switch (view) {
      case 'business_managers':
        return BusinessCenterSelection;
      case 'ad_accounts':
        return AdAccountSelection;
      case 'associate_ad_accounts':
        return AdAccountAssignment;
      case 'confirmation':
        return Confirmation;
      default:
        return null;
    }
  }

  static getInstructionContent(view) {
    switch (view) {
      case 'business_managers':
        return 'Select a Business Manager to assign CreativeX Partner Access. '
          + 'This will enable the sharing of the account\'s assets.';
      case 'business_managers_missing_data':
        return [
          {
            preLink: 'There are no available Business Managers. Please ',
            linkObject: {
              href: CX_META_ADS_START,
              label: 'log in',
            },
            postLink: ' using a different Facebook account, or ',
          },
          {
            linkObject: {
              href: CX_INVITE_USER_URL,
              label: 'invite',
            },
            postLink: ' a user to CreativeX with the required access to complete your connections.',
          },
        ];
      case 'business_managers_none_admin':
        return [
          {
            preLink: 'Your user account does not have admin access to '
              + 'the following Business Managers. Please',
            linkObject: {
              href: CX_META_ADS_START,
              label: 'log in',
            },
            postLink: ' using a different Facebook account, or ',
          },
          {
            linkObject: {
              href: CX_INVITE_USER_URL,
              label: 'invite',
            },
            postLink: ' a user to CreativeX with the required access to complete your connections.',
          },
        ];
      case 'ad_accounts':
        return {
          preLink: 'Select Ad Accounts to assign CreativeX the',
          linkObject: {
            href: `${META_BASE_URL}/155909647811305?id=829106167281625`,
            label: 'Analyst Role',
          },
          postLink: '. This will enable ads, reports, transactions, and audience viewing. '
            + 'Click "Load More" to display additional ad accounts from the Business Manager.',
        };
      case 'associate_ad_accounts':
        return 'Associate each Ad Account with its correct brand, market, and partner.';
      case 'confirmation':
        return 'Confirm each Ad Account is associated with the correct brand, market, and partner.';
      default:
        return null;
    }
  }

  static getEmptyMessage() {
    return 'No available Business Managers';
  }
}

export default StepHelper;
