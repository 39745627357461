import React from "react"
import PropTypes from "prop-types"
import { HiddenInput } from 'components/reusable/HiddenInput'
import {
  Button,
  FormGroup,
  FormGroupContainer,
  Widget,
  WidgetContent,
  WidgetHeader,
  WidgetTable
} from  "@duik/it"
import {
  Toggle,
} from '@makeably/creativex-design-system'

# Used for filtering pending assets for admin
class ClassifierSubscriptionForm extends React.Component
  @propTypes =
    authenticityToken: PropTypes.string
    formUrl: PropTypes.string.isRequired
    orgOptions: PropTypes.array
    selectedOrgs: PropTypes.array

  constructor: (props) ->
    super(props)
    @state =
      selectedOrgs: props.selectedOrgs

  onOptionToggle: (event) =>
    orgId = event.target.name
    orgIds =
      if @state.selectedOrgs.includes(orgId)
        # value alredy selected, now we want to remove it
        _.without(@state.selectedOrgs, orgId)
      else
        @state.selectedOrgs.concat([orgId])

    @setState(selectedOrgs: orgIds)

  renderSelectedOption: (optionId, idx) =>
    option = _.findWhere(@props.orgOptions, { value: optionId })

    `<tr key={idx}>
      <td>{option.value}</td>
      <td>{option.label}</td>
    </tr>`

  hiddenSelectedInputs: (optionId, idx) ->
    `<HiddenInput
      key={idx}
      name="classifier_subscription[organization_ids][]"
      value={optionId}
    />`

  renderToggle: (option, idx) =>
    checked = _.contains(@state.selectedOrgs, option.value)

    `<FormGroup key={idx}>
      <Toggle
        checked={checked}
        name={option.value}
        label={option.label}
        right
        onChange={this.onOptionToggle}
      />
    </FormGroup>`

  render: ->
    formId = 'subscriptionForm'

    `<div className='row'>
      <div className='col s6'>
        <Widget>
          <WidgetHeader>
            <h5>Companies with Subscriptions</h5>
          </WidgetHeader>
          <WidgetTable>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {this.state.selectedOrgs.map(this.renderSelectedOption)}
            </tbody>
          </WidgetTable>
        </Widget>
      </div>
      <div className='col s6'>
        <Widget>
          <WidgetHeader>
            <h5>Subscription Form</h5>
            <Button
              Component='input'
              className='right'
              form={formId}
              name='commit'
              primary
              type='submit'
              value='Save Subscriptions'
            />
          </WidgetHeader>
          <WidgetContent>
            <form action={this.props.formUrl} method='post' id={formId}>
              <input type="hidden" name="_method" value='put'/>
              <input type="hidden" name="authenticity_token" value={this.props.authenticityToken}/>
              {this.state.selectedOrgs.map(this.hiddenSelectedInputs)}
              <FormGroupContainer>
                {this.props.orgOptions.map(this.renderToggle)}
              </FormGroupContainer>
            </form>
          </WidgetContent>
        </Widget>
      </div>
    </div>`

export default ClassifierSubscriptionForm
