import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
} from '@duik/it';
import TableHeader, { propTypes as headerProps } from 'components/molecules/TableHeader';
import TableRowItem, { propTypes as rowProps } from 'components/molecules/TableRowItem';

export const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  emptyMessage: PropTypes.node,
  headers: headerProps.columns,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      columns: rowProps.columns,
      // eslint-disable-next-line react/forbid-prop-types
      data: PropTypes.object,
      key: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  className: undefined,
  emptyMessage: 'No Data',
  headers: [],
  rows: [],
  title: undefined,
};

function renderRows(rows, headers, emptyMessage) {
  if (rows.length === 0) {
    return <tr><td colSpan={headers.length}>{ emptyMessage }</td></tr>;
  }

  return (
    rows.map(({ columns, key }) => (
      <TableRowItem
        key={key}
        columns={columns}
      />
    ))
  );
}

function Table(props) {
  const {
    children,
    className,
    emptyMessage,
    headers,
    rows,
    title,
  } = props;

  const classes = classNames(className, 'table');

  return (
    <div className={classes}>
      { title && <h5 className="tableTitle">{ title }</h5> }
      <Widget>
        <table className="card-table">
          <TableHeader
            columns={headers}
          />
          <tbody>
            { renderRows(rows, headers, emptyMessage) }
          </tbody>
        </table>
        {
          children
            && (
              <WidgetContent>
                { children }
              </WidgetContent>
            )
        }
      </Widget>
    </div>
  );
}

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default Table;
