import React, {
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Divider,
  Spinner,
} from '@makeably/creativex-design-system';
import {
  titleize,
  pluralize,
} from 'utilities/string';
import { post } from 'utilities/toastyRequests';

const propTypes = {
  excelTemplate: PropTypes.string.isRequired,
  googleTemplate: PropTypes.string.isRequired,
  postUrl: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

function BulkUploadPage({
  type,
  postUrl,
  excelTemplate,
  googleTemplate,
}) {
  const hiddenFileInput = useRef(null);
  const [isLoading, setLoading] = useState(false);

  const clickFileInput = () => {
    hiddenFileInput.current.click();
  };

  const submitForm = async (e) => {
    if (e.target.files?.length !== 1) {
      return;
    }

    setLoading(true);

    const file = e.target.files[0];
    const data = new FormData();
    data.append('file', file);

    await post(postUrl, data, {
      successMessage: 'Bulk upload processing. You will receive an email when it is finished.',
      showErrorsFromResponse: true,
    });

    setLoading(false);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Card>
      <div className="u-flexRow u-justifySpaceBetween u-marginBottom-16">
        <div>
          <h5 className="u-marginBottom-8">
            { `Upload ${titleize(type)} Codes in Bulk` }
          </h5>
          <div className="t-subtitle">
            { `Pair multiple taxonomy codes with their ${pluralize(type)} in CreativeX at once by uploading a CSV.` }
          </div>
        </div>
        <AddNewButton
          disabled={isLoading}
          label="Upload CSV"
          onClick={clickFileInput}
        />
        <input
          ref={hiddenFileInput}
          accept=".csv,.xlsx,.xls"
          name="file"
          style={{ display: 'none' }}
          type="file"
          onChange={submitForm}
        />
      </div>
      <Divider />
      <div className="u-flexRow u-gap-8 u-marginTop-16 u-marginBottom-16">
        <a href={excelTemplate} rel="noreferrer" target="_blank">
          <Button
            iconLeft="download"
            label="Download Template"
            variant="secondary"
          />
        </a>
        <a href={googleTemplate} rel="noreferrer" target="_blank">
          <Button
            iconLeft="externalLink"
            label="Open in Google Sheets"
            variant="secondary"
          />
        </a>
      </div>
      <div className="u-marginBottom-8">
        You will receive an email if the upload has been successful.
      </div>
      <div className="u-marginBottom-8">
        <ul>
          <li>
            Do not edit any of the column headers or the file will not be accepted
          </li>
          <li>
            Remember to change your file into a “.csv” before uploading
          </li>
        </ul>
      </div>
      <div className="u-marginBottom-8">
        Please reach out to&nbsp;
        <a href="mailto: support@creativex.com">support@creativex.com</a>
          &nbsp;if you have any issues with your upload.
      </div>
    </Card>
  );
}

BulkUploadPage.propTypes = propTypes;

export default BulkUploadPage;
