import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Tag,
} from '@makeably/creativex-design-system';
import Filter from 'components/molecules/Filter';
import styles from './GuidelineFilter.module.css';

const optionProps = PropTypes.objectOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
);

const propTypes = {
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: optionProps.isRequired,
  selections: optionProps.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

function GuidelineFilter({
  dimensions,
  isOpen,
  onClose,
  onOpen,
  onSelect,
  options,
  selections,
}) {
  const renderTags = () => (
    Object.keys(selections).map((key) => {
      const vals = selections[key];
      const dimensionLabel = dimensions.find((dim) => dim.value === key).label;
      const first = vals[0].label;
      const label = (vals.length === 1) ? first : `${dimensionLabel} (${vals.length})`;

      return <Tag key={key} label={label} />;
    })
  );

  const renderClearFilterButton = () => {
    if (!selections || Object.keys(selections).length === 0) {
      return null;
    }
    return (
      <Button
        label="Clear Filters"
        variant="tertiary"
        onClick={() => onSelect({})}
      />
    );
  };

  return (
    <>
      <div className={styles.controls}>
        <Button
          iconLeft="filter"
          label="Filter"
          variant="secondary"
          onClick={onOpen}
        />
        { renderTags() }
        { renderClearFilterButton() }
      </div>
      <Filter
        isOpen={isOpen}
        options={options}
        segments={dimensions}
        selections={selections}
        onClose={onClose}
        onSelectionsChange={onSelect}
      />
    </>
  );
}

GuidelineFilter.propTypes = propTypes;

export default GuidelineFilter;
