import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from '@makeably/creativex-design-system';
import { HiddenInput } from 'components/reusable/HiddenInput';

const propTypes = {
  partnerOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

function Dv360PartnerSelect({
  partnerOptions,
}) {
  const [partnerId, setPartnerId] = useState('');

  return (
    <div>
      <HiddenInput name="partner_id" value={partnerId} />
      <div className="u-flexColumn">
        <RadioGroup
          options={partnerOptions}
          value={partnerId}
          onChange={(e) => setPartnerId(e.target.value)}
        />
      </div>
    </div>
  );
}

Dv360PartnerSelect.propTypes = propTypes;

export default Dv360PartnerSelect;
