import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Column from "components/reusable/column"
import ImageCardWithMetrics from "components/category/image_card_with_metrics"
import Row from "components/reusable/row"

# Responsible for displaying a collection of image cards.
# See ImageCardWithMetrics component for details
class ImageCardWithMetricsCollection extends React.Component
  @propTypes =
    collection: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired
    message: PropTypes.string
    modalCallback: PropTypes.func
    orientation: PropTypes.oneOf(['horizontal', 'vertical'])
    title: PropTypes.string

  @defaultProps =
    orientation: 'vertical'

  renderCard: (imageCard) =>
    modalCallback = @props.modalCallback
    `<ImageCardWithMetrics
      className='imageCardCollection-card'
      key={imageCard.id}
      modalCallback={modalCallback}
      {...imageCard}
    />`

  renderTitle: ->
    return unless @props.title
    `<Column>
       <h5>{this.props.title}</h5>
     </Column>`

  renderContent: ->
    classes = classNames(
      'imageCardCollection',
      'imageCardCollection-messageContainer': @props.collection.length is 0,
      'imageCardCollection--horizontal': @props.orientation is 'horizontal'
    )

    contentStyles = classNames(
      'center': @props.collection.length is 0
    )

    if @props.collection.length > 0
      content = @props.collection.map(@renderCard)
    else
      content = `<h5>{this.props.message}</h5>`

    `<Column styleName={contentStyles}>
      <div className={classes}>
        {content}
      </div>
    </Column>`

  render: ->
    `<Row>
      {this.renderTitle()}
      {this.renderContent()}
    </Row>`

export default ImageCardWithMetricsCollection
