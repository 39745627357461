import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActionSelectModal from 'components/creative_lifecycle/modal/ActionSelectModal';
import AssetDeactivateModal from 'components/creative_lifecycle/modal/AssetDeactivateModal';
import AssetUpdatePartnerModal from 'components/creative_lifecycle/modal/AssetUpdatePartnerModal';

const propTypes = {
  campaignId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  selectedUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

function getContentByKey(key) {
  switch (key) {
    case 'editActionSelect':
      return ActionSelectModal;
    case 'updateAssetPartner':
      return AssetUpdatePartnerModal;
    case 'deactivateAsset':
      return AssetDeactivateModal;
    default:
      return null;
  }
}

function EditCampaignModal(props) {
  const [contentKey, setContentKey] = useState('editActionSelect');

  const ContentComponent = getContentByKey(contentKey);

  return (
    <ContentComponent
      {...props}
      onActionButtonClick={(key) => setContentKey(key)}
    />
  );
}

EditCampaignModal.propTypes = propTypes;

export default EditCampaignModal;
