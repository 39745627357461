// This file is deprecated. New content should be added to 'utilities/string'
import MomentUtils from '@date-io/moment';
import pluralize from 'pluralize';
import titleize from 'titleize';

class StringHelper {
  static format(value, format, opts = {}) {
    let formattedValue;

    if (format === 'currency') {
      formattedValue = StringHelper.stringToCurrency(value, opts.sigFigs);
    } else if (format === 'largeCurrency') {
      formattedValue = StringHelper.stringToCurrency(value, opts.sigFigs, true);
    } else if (format === 'percent' || format === 'percentage') {
      formattedValue = StringHelper.percentageDisplay(value, opts.sigFigs);
    } else if (format === 'number') {
      formattedValue = Intl.NumberFormat('en-US').format(value);
    } else if (format === 'largeNumber') {
      formattedValue = Intl.NumberFormat('en', { notation: 'compact' }).format(value);
    } else {
      formattedValue = value;
    }

    return formattedValue;
  }

  // Use to convert a decimal into a percentage
  // Provide the number of significant digits to display
  // e.g. if the end result you want is 5.34%, and the server has
  // returned 0.0534234568
  static percentageDisplay(value, significantDigits = 0) {
    const opts = {
      minimumFractionDigits: significantDigits,
      style: 'percent',
    };
    return Intl.NumberFormat('en-US', opts).format(value);
  }

  // Truncates a provided string the the provided maxLength
  // Appends the ellipses after the maxLength so new length
  // will be maxLength + 3
  static truncateString(value, maxLength = 30) {
    if (value.length > maxLength) {
      const trimmedString = value.substring(0, maxLength);
      return `${trimmedString}...`;
    }
    return value;
  }

  static titleize(string) {
    const decamelized = StringHelper.decamelize(string);
    return titleize(decamelized);
  }

  // Split lowercase sequences followed by uppercase character.
  // Uses a space as a separator
  // `adAccounts` → `ad Accounts`
  static decamelize(string) {
    return string.replace(/([\p{Lowercase_Letter}\d])(\p{Uppercase_Letter})/gu, '$1 $2');
  }

  static singularize(string) {
    return pluralize.singular(string);
  }

  static invalidString(str) {
    return !StringHelper.validString(str);
  }

  static validString(str) {
    return (str && str.length > 0);
  }

  // Turns given Date into a string ex. '2020-01-01'
  //
  // We prefer this method to toISOString() since we were seeing issues with British timezone
  //   dates returning off by 1 dates around daylight savings.
  // Ex: new Date(2021, 9, 1).toISOString() will return 2021-9-30 instead of 2021-10-1
  static dateToString(date) {
    if (date) {
      const d = new Date(date);
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

      return (`${ye}-${mo}-${da}`);
    }

    return null;
  }

  static stringToDate(dateString) {
    // Avoid timezone issues
    if (dateString) {
      const moment = new MomentUtils();
      return moment.parse(dateString).toDate();
    }

    return null;
  }

  // Note: Currently does not include decimal/fraction digits
  // If you need decimal values in the future, pass in a flag
  // and default to false and maxFractionDigits = 0
  static stringToCurrency(number, sigFigs = 3, compact = false) {
    const opts = {
      currency: 'USD',
      maximumFractionDigits: 0,
      style: 'currency',
    };

    if (compact) opts.notation = 'compact';
    if (sigFigs) opts.maximumSignificantDigits = sigFigs;

    return Intl.NumberFormat('en-US', opts).format(number);
  }
}

export default StringHelper;
