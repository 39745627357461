import React from 'react';
import BulkUploadPage from 'components/taxonomies/BulkUploadPage';
import { bulkCreateApiCompanyBrandCodesPath } from 'utilities/routes';

const BRAND_EXCEL_TEMPLATE = 'https://picassolabs.s3.amazonaws.com/files/CreativeX%20Bulk%20Brand%20Code%20Upload%20Template.xlsx';
const BRAND_GOOGLE_TEMPLATE = 'https://docs.google.com/spreadsheets/d/1n-I2tpmcNQlbdq_ULYUNBOatxOlopjcQlC7TtS9Myxg/copy';

function BrandBulkUpload() {
  return (
    <BulkUploadPage
      excelTemplate={BRAND_EXCEL_TEMPLATE}
      googleTemplate={BRAND_GOOGLE_TEMPLATE}
      postUrl={bulkCreateApiCompanyBrandCodesPath()}
      type="brand"
    />
  );
}

export default BrandBulkUpload;
