import { enLabels } from './en';

// Label Mapping
const labels = {
  en: enLabels,
};

// Browser default language
export function language() {
  return navigator.language ?? 'en-US';
}

// Browser language without region
function simpleLanguage() {
  return language().split('-')[0];
}

const defaultLang = 'en';

function getTrObj(obj) {
  const lang = simpleLanguage();
  const langSupported = lang in obj;

  if (process.env.NODE_ENV === 'development' && !langSupported) {
    /* eslint-disable-next-line no-console */
    console.warn(`${lang} is not supported.`);
  }

  return langSupported ? obj[lang] : obj[defaultLang];
}

export function tr(key, subKey = undefined) {
  const trObj = getTrObj(labels);
  let trObjVal = trObj[key];

  if (subKey && typeof trObjVal === 'object') {
    trObjVal = trObjVal[subKey];
  }

  return trObjVal || undefined;
}
