import React from 'react';
import PropTypes from 'prop-types';
import SelectableTableCell from 'components/molecules/SelectableTableCell';

export const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.node).isRequired,
  disabled: PropTypes.bool,
  hasSelect: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  disabled: false,
  hasSelect: false,
  onClick: undefined,
  selectable: false,
  selected: false,
};

function TableHeader(props) {
  const {
    columns,
    disabled,
    hasSelect,
    onClick,
    selectable,
    selected,
  } = props;

  let selectableCell;
  if (selectable) {
    selectableCell = (
      <SelectableTableCell
        checked={selected}
        disabled={disabled}
        header
        onChange={() => onClick()}
      />
    );
  } else if (hasSelect) {
    selectableCell = <th aria-label="select" />;
  }

  return (
    <thead>
      <tr>
        { selectableCell }
        {
          columns.map((column, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <th key={idx}>{ column }</th>
          ))
        }
      </tr>
    </thead>
  );
}

TableHeader.propTypes = propTypes;
TableHeader.defaultProps = defaultProps;

export default TableHeader;
