// TODO: Remove this wrapper once there are no haml files rendering icons
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';

const propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  noWrapper: PropTypes.bool,
};

const defaultProps = {
  color: undefined,
  noWrapper: undefined,
};

function DsIcon({
  color,
  name,
  noWrapper,
}) {
  return (
    <Icon
      color={color}
      name={name}
      noWrapper={noWrapper}
    />
  );
}

DsIcon.propTypes = propTypes;
DsIcon.defaultProps = defaultProps;

export default DsIcon;
