import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormGroupContainer,
} from '@duik/it';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import FormSubmit from 'components/reusable/FormSubmit';
import { HiddenInput } from 'components/reusable/HiddenInput';
import EditPasswordForm from 'components/settings/users/EditPasswordForm';

const propTypes = {
  resetPasswordToken: PropTypes.string.isRequired,
  rules: PropTypes.shape({
    letter: PropTypes.boolean,
    minLength: PropTypes.number,
    number: PropTypes.boolean,
    symbol: PropTypes.boolean,
  }).isRequired,
  url: PropTypes.string.isRequired,
};

function EditPassword(props) {
  const {
    resetPasswordToken,
    rules,
    url,
  } = props;

  return (
    <form action={url} method="post">
      <HiddenInput name="_method" value="put" />
      <HiddenInput name="user[reset_password_token]" value={resetPasswordToken} />
      <AuthenticityTokenInput />
      <FormGroupContainer>
        <EditPasswordForm rules={rules} />
        <FormGroup>
          <FormSubmit
            label="Change Password"
            primary
          />
        </FormGroup>
      </FormGroupContainer>
    </form>
  );
}

EditPassword.propTypes = propTypes;

export default EditPassword;
