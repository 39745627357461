import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Search,
} from '@makeably/creativex-design-system';
import ThresholdModal from 'components/admin/matching_configs/ThresholdModal';
import ItemsTable from 'components/molecules/ItemsTable';
import { getItemSortBy } from 'utilities/item';
import { searchItemsByKey } from 'utilities/itemSearch';
import { getAuthenticityToken } from 'utilities/requests';
import {
  adminCompanyCoreAssetsPath,
  adminCompanyMatchingConfigPath,
  adminCompanyMatchingConfigsPath,
} from 'utilities/routes';
import { toPercent } from 'utilities/string';
import styles from './Thresholds.module.css';

const configProps = PropTypes.shape({
  // @note: thresholds is an aggregate string of matching_config data: asset_type::id::threshold
  thresholds: PropTypes.arrayOf(PropTypes.string).isRequired,
  brandId: PropTypes.number,
  brandName: PropTypes.string,
  companyId: PropTypes.number,
  coreAssetCount: PropTypes.number,
});

const propTypes = {
  baseConfig: configProps.isRequired,
  brandConfigs: PropTypes.arrayOf(configProps).isRequired,
  companyConfig: configProps.isRequired,
};

const COMPANY_BRAND_ID = 0;
const COMPANY_LABEL = 'Company Default';

const headers = [
  {
    key: 'coreAssets',
    label: 'Core Assets By Brand',
  },
  {
    key: 'imageThreshold',
    label: 'Image Threshold',
  },
  {
    key: 'videoThreshold',
    label: 'Video Threshold',
  },
];

async function postThreshold(url, method, {
  assetType, brandId, threshold,
}) {
  try {
    const formData = new FormData();
    formData.append('asset_type', assetType);
    formData.append('authenticity_token', getAuthenticityToken());
    formData.append('threshold', threshold);
    if (brandId !== COMPANY_BRAND_ID) {
      formData.append('organization_brand_id', brandId);
    }

    const response = await fetch(url, {
      method,
      body: formData,
    });
    return response.json();
  } catch {
    return false;
  }
}

async function updateThreshold({
  companyId, id, ...rest
}) {
  const url = adminCompanyMatchingConfigPath(companyId, id);

  return postThreshold(url, 'PATCH', rest);
}

async function createThreshold({ companyId, ...rest }) {
  const url = adminCompanyMatchingConfigsPath(companyId);

  return postThreshold(url, 'POST', rest);
}

function getTypeThreshold(config, assetType) {
  const strs = config?.thresholds;
  const str = strs?.find((s) => s?.startsWith(`${assetType}::`));

  if (!str) {
    return {};
  }

  const parts = str.split('::');
  const id = parseInt(parts[1]);
  const threshold = parseFloat(parts[2]);

  return {
    id: Number.isNaN(id) ? null : id,
    threshold: Number.isNaN(threshold) ? null : threshold,
  };
}

function getDefaultsByType(companyConfig, baseConfig) {
  const { threshold: companyImage } = getTypeThreshold(companyConfig, 'image');
  const { threshold: companyVideo } = getTypeThreshold(companyConfig, 'video');
  const { threshold: baseImage } = getTypeThreshold(baseConfig, 'image');
  const { threshold: baseVideo } = getTypeThreshold(baseConfig, 'video');

  return {
    image: companyImage ?? baseImage ?? 0,
    video: companyVideo ?? baseVideo ?? 0,
  };
}

function getCoreAssets({
  brandId,
  brandName,
  companyId,
  coreAssetCount,
}) {
  const isCompany = !brandId;
  const label = isCompany ? COMPANY_LABEL : `${brandName} (${coreAssetCount})`;
  const value = isCompany ? '' : label;
  const brand = isCompany ? null : brandId;
  const url = adminCompanyCoreAssetsPath(companyId, { brand });

  return {
    value,
    element: <a href={url}>{ label }</a>,
  };
}

function getConfigBrandId({ brandId }) {
  return brandId ?? COMPANY_BRAND_ID;
}

function getConfigBrandName({ brandName }) {
  return brandName ?? COMPANY_LABEL;
}

function getThreshold(config, defaultsByType, assetType, setModalData) {
  const { id, threshold } = getTypeThreshold(config, assetType);
  const value = threshold ?? defaultsByType[assetType] ?? 0;
  const modalData = {
    assetType,
    brandId: getConfigBrandId(config),
    brandName: getConfigBrandName(config),
    companyId: config.companyId,
    id,
    threshold: value,
  };

  return {
    element: (
      <button
        className={`t-body-1 ${styles.button}`}
        type="button"
        onClick={() => setModalData(modalData)}
      >
        { toPercent(value) }
      </button>
    ),
    value,
  };
}

function getItem(config, defaultsByType, setModalData) {
  return {
    brandId: { value: getConfigBrandId(config) },
    brandName: { value: getConfigBrandName(config) },
    companyId: { value: config.companyId },
    id: { value: getConfigBrandId(config) },
    imageThreshold: getThreshold(config, defaultsByType, 'image', setModalData),
    coreAssets: getCoreAssets(config),
    videoThreshold: getThreshold(config, defaultsByType, 'video', setModalData),
  };
}

function Thresholds({
  baseConfig,
  companyConfig,
  brandConfigs,
}) {
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const [searchedItems, setSearchedItems] = useState(items);
  const [sort, setSort] = useState({
    key: 'coreAssets',
    asc: true,
  });
  const [sortedItems, setSortedItems] = useState(items);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    const defaultsByType = getDefaultsByType(companyConfig, baseConfig);
    const allConfigs = [companyConfig, ...brandConfigs];

    setItems(allConfigs.map((config) => getItem(config, defaultsByType, setModalData)));
  }, [brandConfigs, companyConfig, baseConfig]);

  useEffect(() => {
    setSearchedItems(searchItemsByKey(items, 'brandName', search));
  }, [items, search]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);

    setSortedItems(searchedItems.slice().sort(byKeyDir));
  }, [searchedItems, sort]);

  const saveThreshold = async (data) => {
    if (data.id) {
      await updateThreshold(data);
    } else {
      await createThreshold(data);
    }
    window.location.reload();
  };

  return (
    <>
      <div className={styles.page}>
        <Card className={styles.card}>
          <div className={styles.search}>
            <Search
              placeholder="Search brand"
              size="large"
              value={search}
              onChange={(value) => setSearch(value)}
            />
          </div>
          <div className={styles.spacer} />
          <ItemsTable
            className={styles.table}
            headers={headers}
            items={sortedItems}
            maxWidthSize="custom"
            sort={sort}
            onSortChange={(value) => setSort(value)}
          />
          { sortedItems.length === 0 && (
            <div className={`t-empty ${styles.empty}`}>
              There are no brands to display
            </div>
          ) }
        </Card>
      </div>
      <ThresholdModal
        assetType={modalData?.assetType}
        brandId={modalData?.brandId}
        brandName={modalData?.brandName}
        companyId={modalData?.companyId}
        id={modalData?.id}
        isOpen={Boolean(modalData)}
        threshold={modalData?.threshold}
        onClose={() => setModalData(null)}
        onSave={saveThreshold}
      />
    </>
  );
}

Thresholds.propTypes = propTypes;

export default Thresholds;
