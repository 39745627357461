import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import SelectableTable, { propTypes as tableProps } from 'components/organisms/SelectableTable';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import ArrayHelper from 'components/utils/ArrayHelper';
import { titleize } from 'utilities/string';

const propTypes = {
  addMixpanelData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  inputName: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  table: PropTypes.shape({
    headers: tableProps.headers,
    rows: tableProps.rows,
    title: PropTypes.string,
  }).isRequired,
  onFormAction: PropTypes.func.isRequired,
};

function TableSelection({
  addMixpanelData,
  formData: {
    id,
    url,
  },
  inputName,
  itemName,
  table: {
    headers,
    rows,
    title,
  },
  onFormAction,
}) {
  const [allRowsSelected, setAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const disabledSelectedRows = rows.filter(({ disabledSelected }) => disabledSelected);

    // Update select all checkbox
    const selectedRowCount = selectedRows.length + disabledSelectedRows.length;
    const isAllRowsSelected = selectedRowCount === rows.length;
    setAllSelected(isAllRowsSelected);

    // Update if form is continuable
    onFormAction(selectedRows.length > 0);

    // Update mixpanel data
    const mixpanelKey = `total${titleize(itemName)}`;
    addMixpanelData({ [mixpanelKey]: selectedRows.length });
  }, [selectedRows]);

  const handleAllSelect = () => {
    // @note: Do the reverse of the current value of allSelected check
    if (allRowsSelected) {
      setSelectedRows([]);
    } else {
      const updatedRows = rows
        .filter(({ disabled, disabledSelected }) => !disabled && !disabledSelected)
        .map(({ data }) => data);
      setSelectedRows(updatedRows);
    }
  };

  const handleRowSelect = (selectedRow) => {
    setSelectedRows(ArrayHelper.withOrWithoutObject([...selectedRows], selectedRow));
  };

  return (
    <>
      <SelectableTable
        headers={headers}
        rows={rows}
        selected={selectedRows}
        title={title}
        selectAll
        onAllSelect={handleAllSelect}
        onRowSelect={handleRowSelect}
      />
      <form action={url} id={id} method="post">
        <AuthenticityTokenInput />
        {
          selectedRows.map(({ name, value }) => (
            <React.Fragment key={value}>
              <HiddenInput
                name={`${inputName}[][value]`}
                value={value}
              />
              <HiddenInput
                name={`${inputName}[][name]`}
                value={name}
              />
            </React.Fragment>
          ))
        }
      </form>
    </>
  );
}

TableSelection.propTypes = propTypes;

export default TableSelection;
