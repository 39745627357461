import React from 'react';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
} from '@duik/it';
import { MultipleDropdown } from '@makeably/creativex-design-system';
import TextStepper from 'components/molecules/TextStepper';
import CardHeader from 'components/reusable/CardHeader';
import FormSubmit from 'components/reusable/FormSubmit';
import { HiddenInput } from 'components/reusable/HiddenInput';
import ArrayHelper from 'components/utils/ArrayHelper';

export const propTypes = {
  adAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  authId: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
  header: PropTypes.shape({
    currentStep: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
};

function renderSelectedAdAccounts(adAccounts) {
  if (!adAccounts.length > 0) return null;

  return (
    <div>
      <h5>Selected Ad Account(s)</h5>
      <div>
        {
          adAccounts.map(({
            label, name, value,
          }) => (
            <div key={value} className="u-marginBelowSm">
              <HiddenInput name="accounts[][id]" value={value} />
              <HiddenInput name="accounts[][name]" value={name} />
              { label }
            </div>
          ))
        }
      </div>
    </div>
  );
}

function formIncomplete(adAccounts) {
  return adAccounts.length === 0;
}

class AdAccountSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAdAccounts: [],
    };
  }

  handleAdAccountSelect(option) {
    this.setState((prevState) => {
      let { selectedAdAccounts } = prevState;
      selectedAdAccounts = ArrayHelper.withOrWithoutObject(selectedAdAccounts, option);

      return { selectedAdAccounts };
    });
  }

  render() {
    const {
      adAccounts,
      authId,
      formUrl,
      header,
    } = this.props;

    const {
      selectedAdAccounts,
    } = this.state;

    const subheader = () => {
      const text = 'Please select the ad account(s) you’d like to connect to this CreativeX account.';
      if (adAccounts.some((account) => account.disabled)) {
        return (`${text} \nGreyed out accounts have already been connected.`);
      }

      return text;
    };

    return (
      <div>
        <Widget>
          <TextStepper currentStep={header.currentStep} steps={header.steps} />
          <CardHeader
            header="Ad Accounts"
            subHeader={subheader()}
          />
          <WidgetContent>
            <div className="u-flexColumn u-justifySpaceBetween">
              <div className="u-flexRow u-justifySpaceBetween">
                <MultipleDropdown
                  label="Available Ad Accounts"
                  menuProps={{ size: 'medium' }}
                  options={adAccounts}
                  placeholder="Select..."
                  selected={selectedAdAccounts}
                  onChange={(option) => this.handleAdAccountSelect(option)}
                />
                <form
                  action={formUrl}
                  className="selectedTwitterAdAccounts"
                  id="adAccountSelect"
                  method="get"
                >
                  <HiddenInput name="auth_id" value={authId} />
                  { renderSelectedAdAccounts(selectedAdAccounts) }
                </form>
              </div>
              <div className="u-marginAbove u-textAlignRight">
                <FormSubmit
                  disabled={formIncomplete(selectedAdAccounts)}
                  form="adAccountSelect"
                  label="Select Ad Accounts"
                  trackingLabel="Select Twitter Ad Accounts"
                  primary
                />
              </div>
            </div>
          </WidgetContent>
        </Widget>
      </div>
    );
  }
}

AdAccountSelect.propTypes = propTypes;

export default AdAccountSelect;
