import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@duik/it';
import { track } from 'utilities/mixpanel';

export const propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  lg: PropTypes.bool,
  primary: PropTypes.bool,
  trackingEventName: PropTypes.string,
};

const defaultProps = {
  lg: false,
  primary: false,
  trackingEventName: undefined,
};

function ButtonLink(props) {
  const {
    label,
    lg,
    path,
    primary,
    trackingEventName,
  } = props;

  const mixpanelTracking = () => {
    const eventName = trackingEventName || label;
    track(`Click ${eventName}`, { source: window.location.pathname });
  };

  return (
    <Button
      Component="a"
      href={path}
      lg={lg}
      primary={primary}
      onClick={mixpanelTracking}
    >
      { label }
    </Button>
  );
}

ButtonLink.propTypes = propTypes;
ButtonLink.defaultProps = defaultProps;

export default ButtonLink;
