import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Select from "components/reusable/select"

# This component is a wrapper for date range inputs (and preset select dropdown)
# Can also render pre-set values in a select dropdownd (presets must be passed in)
class DateRange extends React.Component
  @propTypes =
    endDate: PropTypes.string                       # date rendered as a string
    endDateInputName: PropTypes.string              # name of input (when placed on dom)
    formId: PropTypes.string                        # form to tie to for submissions
    onSelect: PropTypes.func
    # Default ordering is Start Date / End Date / Preset Select and is set in the render function
    # This will override that order
    order: PropTypes.arrayOf(
      PropTypes.oneOf(['select', 'start_date', 'end_date'])
    )
    orientation: PropTypes.oneOf(['row', 'column']) # self-explanatory
    presetDateRangeProps: PropTypes.shape(
      # NB: optionToDateRange object is of the following format:
      # ex: {last_30_to_date: {start_date: x, end_date: y}...}
      # The keys are values present in the selectProps.options array
      # Used so when you select a preset option, you can get the date range
      # of that option with this object
      optionToDateRange: PropTypes.object
      selectedOption: PropTypes.string            # Value of selected option
      selectProps: PropTypes.shape(
        options: PropTypes.arrayOf(
          PropTypes.shape(
            label: PropTypes.string
            value: PropTypes.string                 # *see optionToDateRange
          )
        )
      )
    )
    # presetInputName is the name of input for preset select (when placed on dom),
    # when provided it will include a callback on updates to the preset date range selection,
    # in addition to the start and end dates
    presetInputName: PropTypes.string
    startDate: PropTypes.string                     # date rendered as a string
    startDateInputName: PropTypes.string            # name of input (when placed on dom)

  @defaultProps =
    orientation: 'row'

  constructor: (props) ->
    super(props)

    selectedPresetDateRange =
      if @props.presetDateRangeProps and @props.presetDateRangeProps.selectedOption
        @props.presetDateRangeProps.selectedOption
      else
        ''

    @state =
      selectedPresetDateRange: selectedPresetDateRange

  classes: ->
    classNames(
      "dateRange",
      "widget--dateRange"
      "widget--dateRange-#{@props.orientation}"
    )

  handleChange: (event) =>
    @props.onSelect(event.target.value, { inputName: event.target.name })
    @setState(selectedPresetDateRange: '')

  # call onSelect for the startDate and endDate values that are defined in the defaults
  onPresetSelectChange: (option) =>
    dateRange = @props.presetDateRangeProps.optionToDateRange[option]
    @props.onSelect(dateRange.startDate, { inputName: @props.startDateInputName })
    @props.onSelect(dateRange.endDate, { inputName: @props.endDateInputName })
    @props.onSelect(option, { inputName: @props.presetInputName }) if @props.presetInputName
    @setState(selectedPresetDateRange: option)

  renderPresetOptions: ->
    return unless @props.presetDateRangeProps

    if @props.order
      selectStyle =
        order: _.indexOf(@props.order, 'select')

    `<Select
      {...this.props.presetDateRangeProps.selectProps}
      inputName={this.props.presetInputName}
      onChange={this.onPresetSelectChange}
      selected={this.state.selectedPresetDateRange}
      style={selectStyle}
    />`

  renderDateSelection: (inputName, value, date_option) ->
    if @props.order
      inputStyle =
        order: _.indexOf(@props.order, date_option)

    dateInputProps =
      className: 'datepicker'
      form: @props.formId
      id: inputName
      name: inputName
      onChange: @handleChange
      style: inputStyle
      type: 'date'
      value: value

    `<input {...dateInputProps} />`

  render: ->
    `<div className={this.classes()}>
       {this.renderDateSelection(this.props.startDateInputName, this.props.startDate, 'start_date')}
       {this.renderDateSelection(this.props.endDateInputName, this.props.endDate, 'end_date')}
       {this.renderPresetOptions()}
     </div>`

export default DateRange
