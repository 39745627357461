import React from "react"

# A dumb component for ui indication that you're at the end of a list.
class EndOfList extends React.Component

  render: ->
    `<div className="end-of-pagination">
      <hr/>
      <div className="no-results-icon-wrapper">
        <div className="no-results-icon"></div>
      </div>
    </div>`

export default EndOfList
