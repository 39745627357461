import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

class Row extends React.Component
  @propTypes =
    styleName: PropTypes.string

  @defaultProps =
    className: null

  classes: ->
    classNames(
      'row',
      this.props.className,
      this.props.styleName
    )

  render: ->
    `<div className={this.classes()}>
      {this.props.children}
     </div>`

export default Row
