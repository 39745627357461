import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
} from '@makeably/creativex-design-system';
import DataPointsFilter, { dataPointProps } from 'components/contract/DataPointsFilter';
import DataPointVisualization from 'components/contract/DataPointVisualization';
import { addToast } from 'components/organisms/Toasts';
import ReportTags from 'components/reporting/ReportTags';
import StringHelper from 'components/utils/StringHelper';
import { getObjFilterTest } from 'utilities/filtering';
import { removeProperty } from 'utilities/object';
import { getAuthenticityToken } from 'utilities/requests';
import { createCsvContractDataPointUsagePath } from 'utilities/routes';
import { toCount } from 'utilities/string';
import styles from './DataPointUsage.module.css';
import {
  getMonthsArray,
  getMonthBins,
} from './utilities';

const propTypes = {
  startDate: PropTypes.string.isRequired,
  dataPoints: PropTypes.arrayOf(dataPointProps),
  dataPointsCap: PropTypes.number,
  endDate: PropTypes.string,
};

const defaultProps = {
  dataPoints: [],
  dataPointsCap: undefined,
  endDate: undefined,
};

const segments = [
  {
    label: 'Market',
    value: 'marketName',
  },
  {
    label: 'Brand',
    value: 'brandName',
  },
  {
    label: 'Channel',
    value: 'channelName',
  },
  {
    label: 'Guideline',
    value: 'guidelineName',
  },
  {
    label: 'Campaign\nStatus',
    value: 'campaignStatus',
  },
  {
    label: 'Asset\nType',
    value: 'assetType',
  },
];

function getMonthItem(costBins, month) {
  const cost = costBins[month] ?? 0;

  return {
    month: { value: month },
    totalCost: {
      label: toCount(cost),
      value: cost,
    },
  };
}

function getItems(dataPoints, startDate, endDate) {
  const months = getMonthsArray(startDate, endDate);
  const costBins = getMonthBins(dataPoints, 'cost');

  return months.map((month) => getMonthItem(costBins, month));
}

async function generateCsv() {
  const response = await fetch(
    createCsvContractDataPointUsagePath(),
    {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getAuthenticityToken(),
      },
    },
  );

  return response.json();
}

function DataPointUsage({
  dataPoints,
  dataPointsCap,
  endDate: endDateStr,
  startDate: startDateStr,
}) {
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [filteredDataPoints, setFilteredDataPoints] = useState(dataPoints);
  const [dataPointsCost, setDataPointsCost] = useState(0);
  const startDate = StringHelper.stringToDate(startDateStr);
  const endDate = StringHelper.stringToDate(endDateStr);

  useEffect(() => {
    const filterTest = getObjFilterTest(currentFilters);
    setFilteredDataPoints(dataPoints.filter(filterTest));
  }, [dataPoints, currentFilters]);

  useEffect(() => {
    setItems(getItems(filteredDataPoints, startDate, endDate));
  }, [filteredDataPoints]);

  useEffect(() => {
    const totalCost = filteredDataPoints.reduce((total, dataPoint) => total + dataPoint.cost, 0);
    setDataPointsCost(totalCost);
  }, [filteredDataPoints]);

  const handleGenerateCsv = async () => {
    const data = await generateCsv();
    if (data.success) {
      addToast('We are generating your CSV and will email you a download link when done. Generation time depends on the size of your data.', {
        size: 'large',
        type: 'success',
      });
    } else {
      addToast('Something went wrong, please try again.', {
        size: 'large',
        type: 'error',
      });
    }
  };

  const removeCurrentFilter = (key) => {
    setCurrentFilters((last) => removeProperty(last, key));
  };

  return (
    <Card padding={false}>
      <div className={styles.controls}>
        <div className={styles.buttons}>
          <DataPointsFilter
            dataPoints={dataPoints}
            isOpen={filterOpen}
            segments={segments}
            selections={currentFilters}
            onClose={() => setFilterOpen(false)}
            onOpen={() => setFilterOpen(true)}
            onSelectionsChange={setCurrentFilters}
          />
          <Button
            label="Generate CSV"
            variant="primary"
            onClick={handleGenerateCsv}
          />
        </div>
        <ReportTags
          filters={currentFilters}
          removeFilter={removeCurrentFilter}
          segments={segments}
          onFilterClick={() => setFilterOpen(true)}
        />
      </div>
      <Divider />
      <DataPointVisualization
        dataPointsCap={dataPointsCap}
        dataPointsCost={dataPointsCost}
        endDate={endDateStr}
        hasFilters={Object.keys(currentFilters).length > 0}
        items={items}
        startDate={startDateStr}
      />
    </Card>
  );
}

DataPointUsage.propTypes = propTypes;
DataPointUsage.defaultProps = defaultProps;

export default DataPointUsage;
