import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Tooltip } from '@makeably/creativex-design-system'

class Card extends React.Component
  @propTypes =
    header: PropTypes.string                # header for card
    headerTooltipProps: PropTypes.shape(
      tooltipText: PropTypes.string
    )                                             # see Tooltip.js.jsx.coffee
    constrainHeight: PropTypes.bool         # Should card be the height of the contents or constrained?
    collapsible: PropTypes.bool             # Should card header collapse card body on click
    isCollapsed: PropTypes.bool             # initial state of card (isCollapsed?)

  @defaultProps: ->
    isCollapsed: false
    collapsible: false

  constructor: (props) ->
    super(props)

    @state = {
      isCollapsed: props.isCollapsed && props.collapsible,
    }

  classes: ->
    classNames(
      'card',
      'card-withHeader': @props.header,
      'card-constrainHeight': @props.constrainHeight,
      'card-withHeader--collapseBody': @state.isCollapsed
      @props.className
    )

  toggleBody: =>
    return unless @props.collapsible
    @setState(isCollapsed: !@state.isCollapsed)

  renderHeaderTooltip: ->
    return unless @props.headerTooltipProps?.tooltipText
    `<Tooltip label={this.props.headerTooltipProps.tooltipText} />`

  renderHeader: ->
    return unless @props.header
    tooltip = @renderHeaderTooltip()
    headerProps = {
      className: classNames('card-header', 'card-header--withTooltip': tooltip)
    }
    headerProps.onClick = @toggleBody if @props.collapsible

    `<div {...headerProps}>
       {this.props.header}
       {tooltip}
     </div>`

  renderBody: ->
    return if @state.isCollapsed
    `this.props.children`

  render: ->
    `<div className={this.classes()}>
      {this.renderHeader()}
      <div className="card-body">
        {this.renderBody()}
      </div>
     </div>`

export default Card
