import React from 'react';
import { AddNewButton } from '@makeably/creativex-design-system';
import { newPreflightPretestPath } from 'utilities/routes';

// TODO: Can be removed once views/guidelines/_rules.html.haml is updated to a React component
function NewPreflightButton() {
  return (
    <a href={newPreflightPretestPath()}>
      <AddNewButton label="New Pre-Flight" />
    </a>
  );
}

export default NewPreflightButton;
