import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TextInput } from '@makeably/creativex-design-system';
import { isNumeric } from 'utilities/string';
import styles from './TierRow.module.css';

export const tierProps = {
  label: PropTypes.string,
  labelError: PropTypes.string,
  maximum: PropTypes.number,
  minimum: PropTypes.number,
  minimumCutoff: PropTypes.number,
  rank: PropTypes.string,
  scoreError: PropTypes.string,
};

const propTypes = {
  editing: PropTypes.bool.isRequired,
  tier: PropTypes.shape(tierProps).isRequired,
  onTierChange: PropTypes.func.isRequired,
};

function TierRow({
  editing,
  onTierChange,
  tier: {
    label,
    labelError,
    maximum,
    minimum,
    minimumCutoff,
    rank,
    scoreError,
  },
}) {
  const isLowestRank = rank === 'lowest';
  const isHighestRank = rank === 'highest';

  const scoreLabel = editing ? 'Tier Score Range' : `${label} Threshold`;
  const comparisonClasses = classNames(
    'u-flexRow',
    'u-alignCenter',
    'u-gap-16',
    { [styles.padding]: !editing || (editing && isHighestRank) },
  );
  const assetScoreClasses = classNames(
    styles.block,
    't-overline',
    {
      [styles.green]: isHighestRank,
      [styles.blue]: rank === 'high',
      [styles.orange]: rank === 'low' || rank === 'medium',
      [styles.red]: isLowestRank,
    },
  );

  const onScoreChange = (score) => {
    if (isNumeric(score)) {
      const newScore = score === '' ? 0 : parseInt(score);
      onTierChange(rank, 'minimumCutoff', newScore);
    }
  };

  return (
    <div>
      <div className="u-flexRow u-gap-16">
        { editing && (
          <div className="u-flexColumn">
            <TextInput
              isError={Boolean(labelError)}
              label={isHighestRank && 'Tier Name'}
              size="small"
              value={label}
              onChange={(newLabel) => onTierChange(rank, 'label', newLabel)}
            />
            { labelError && <div className={styles.error}>{ labelError }</div> }
          </div>
        ) }
        <div className="u-flexColumn">
          <div className="u-flexRow u-gap-16">
            <TextInput
              disabled={!editing || isLowestRank}
              isError={Boolean(scoreError)}
              label={(!editing || isHighestRank) && scoreLabel}
              max={isHighestRank ? maximum : maximum - 1}
              min={minimum}
              size="small"
              type="number"
              value={minimumCutoff}
              onChange={onScoreChange}
            />
            <div className={comparisonClasses}>
              <div className={styles.comparison}>
                &le;
              </div>
              <div className={assetScoreClasses}>
                asset score
              </div>
              <div className={styles.comparison}>
                { isHighestRank ? '\u2264' : '\u003C' }
              </div>
              <TextInput
                size="small"
                value={maximum}
                disabled
              />
            </div>
          </div>
          { scoreError && <div className={styles.error}>{ scoreError }</div> }
        </div>
      </div>
    </div>
  );
}

TierRow.propTypes = propTypes;

export default TierRow;
