import ObjectHelper from 'components/utils/ObjectHelper';

class ArrayHelper {
  static withOrWithoutObject(values, valueObj) {
    let updatedValues = values;
    const isSelected = ArrayHelper.objectInArray(values, valueObj);

    if (isSelected) {
      // Remove if present
      updatedValues = ArrayHelper.removeObjectFromArray(values, valueObj);
    } else {
      // Add if missing
      updatedValues.push(valueObj);
    }

    return updatedValues;
  }

  static withOrWithoutValue(values, value) {
    let updatedValues = values;
    const isSelected = values.includes(value);

    if (isSelected) {
      // Remove if present
      updatedValues = ArrayHelper.removeFromArray(values, value);
    } else {
      // Add if missing
      updatedValues.push(value);
    }

    return updatedValues;
  }

  static removeFromArray(values, value) {
    return values.filter((elm) => elm !== value);
  }

  // Full object compare
  static exactObjectInArray(arr, obj) {
    const result = arr.find((elm) => ObjectHelper.sameObject(elm, obj));
    return result !== undefined;
  }

  static removeExactObjectFromArray(arr, obj) {
    return arr.filter((elm) => !ObjectHelper.sameObject(elm, obj));
  }

  static withOrWithoutExactObjectFromArray(arr, obj) {
    let updatedValues = arr;
    const isSelected = ArrayHelper.exactObjectInArray(arr, obj);

    if (isSelected) {
      // Remove if present
      updatedValues = ArrayHelper.removeExactObjectFromArray(arr, obj);
    } else {
      // Add if missing
      updatedValues.push(obj);
    }

    return updatedValues;
  }

  // By value
  static objectInArray(values, valueObj) {
    return values.find((elm) => elm.value === valueObj.value) !== undefined;
  }

  // By value
  static removeObjectFromArray(values, valueObj) {
    return values.filter((elm) => elm.value !== valueObj.value);
  }

  // Values from array of objects
  static values(arr) {
    return arr.map((elm) => elm.value);
  }

  // Sorts a table numerically by specified column
  static tableSort(arr, sortDir = 'asc', sortIdx = 0) {
    arr.sort((a, b) => {
      let comp;
      const isNumeric = a[sortIdx].format !== 'string';

      const aVal = a[sortIdx].value;
      const bVal = b[sortIdx].value;

      if (isNumeric) {
        comp = aVal - bVal;
      } else {
        comp = aVal.localeCompare(bVal);
      }

      return comp;
    });

    if (sortDir === 'desc') arr.reverse();

    return arr;
  }

  // Sorts an array numerically
  static numericSort(arr, sortDir = 'asc') {
    arr.sort((a, b) => a - b);
    if (sortDir === 'desc') arr.reverse();

    return arr;
  }
}

export default ArrayHelper;
