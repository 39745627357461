import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@duik/it';
import MixpanelTracker from 'components/utils/MixpanelTracker';

export const propTypes = {
  label: PropTypes.string.isRequired,
  primary: PropTypes.bool,
};

const defaultProps = {
  primary: false,
};

function FileSelectButton(props) {
  const {
    label,
    primary,
  } = props;

  const buttonContent = (
    <Button primary={primary} onClick={() => MixpanelTracker.trackButtonClick(label)}>
      { label }
      <input
        className="upload"
        id="file"
        multiple={false}
        name="file"
        type="file"
        required
      />
    </Button>
  );
  return buttonContent;
}

FileSelectButton.propTypes = propTypes;
FileSelectButton.defaultProps = defaultProps;

export default FileSelectButton;
