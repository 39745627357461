import StringHelper from 'components/utils/StringHelper';

class ObjectHelper {
  static stringToOption(str) {
    if (!str) {
      return undefined;
    }

    return ({
      label: str,
      value: str,
    });
  }

  static valueToObject(value, formatted = false) {
    const label = formatted ? StringHelper.titleize(value) : value;
    return {
      label,
      value,
    };
  }

  static valuesToObjects(values, formatted = false) {
    return values.map((v) => ObjectHelper.valueToObject(v, formatted));
  }

  static invalidObject(obj) {
    return !ObjectHelper.validObject(obj);
  }

  static validObject(obj) {
    return (typeof (obj) === 'object' && obj !== null);
  }

  static arrayToObject(arr) {
    if (!arr && arr.length !== 2) {
      return undefined;
    }

    return ({
      label: arr[0],
      value: arr[1],
    });
  }

  static arraysToObjects(arrs) {
    return arrs.map((arr) => ObjectHelper.arrayToObject(arr));
  }

  static sameObject(obj1, obj2) {
    return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
  }
}

export default ObjectHelper;
