import React from "react"
import MixpanelTracker from "components/utils/MixpanelTracker"
import PropTypes from "prop-types"
import SearchableSelect from 'components/reusable/SearchableSelect'
import { FormGroup, Select } from  "@duik/it"

# This is responsible for displaying the channel and metric selection for the
# Performance Measurement Table
class AuditPerformanceSelector extends React.Component
  @propTypes =
    channel: PropTypes.string
    channelOptions: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string
        value: PropTypes.string
      )
    )
    # Object of channel_identifier => [{label: "metric label", value: "metric_value"}, ...]
    # Example of channel_identifier is 'facebook_paid'
    # This is a mapping of channel options to metric options
    channelMetricOptions: PropTypes.object
    conversion: PropTypes.string
    conversionMetrics: PropTypes.arrayOf(PropTypes.string)
    conversionOptions: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string
        value: PropTypes.string
      )
    )
    filterQueryId: PropTypes.number
    # NB: this is the formId of the audit filter form, therefore when we
    # submit, we don't lose applied audit filters
    formId: PropTypes.string
    guideline: PropTypes.string
    guidelineOptions: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string
        value: PropTypes.string
      )
    )
    metric: PropTypes.string

  trackViewSelectorChange: (selectorName, value) ->
    MixpanelTracker.trackFilterSelection(selectorName, value)

  constructor: (props) ->
    super(props)

    @state =
      guideline: props.guideline
      channel: props.channel
      metric: props.metric
      conversion: props.conversion || undefined # NB: null || undefined => undefined

  submitFormCallback: (allowSubmit) =>
    =>
      $("##{@props.formId}").submit() if allowSubmit

  metricOptions: ->
    options = @props.channelMetricOptions[@state.channel]
    return [] if _.isUndefined(options) or _.isNull(options)
    return options

  renderHiddenInput: (name, value) ->
    return if _.isUndefined(value) or _.isNull(value)
    `<input form={this.props.formId} name={name} value={value} type='hidden' />`

  isConversionMetric: (metric) ->
    _.contains(@props.conversionMetrics, metric)

  onGuidelineSelect: (option) =>
    this.trackViewSelectorChange('Guideline', option.label)
    @setState(
      guideline: option.value
      channel: undefined
      metric: undefined
      conversion: undefined
    )

  onChannelSelect: (option) =>
    this.trackViewSelectorChange('Channel', option.label)
    @setState(
      channel: option.value,
      metric: undefined,
      conversion: undefined
    )

  onMetricSelect: (option) =>
    # Only submit on metric change if we are selecting a non-conversion metric
    # Conversion metrics require a conversion selection before submission
    metric = option.value
    this.trackViewSelectorChange('Metric', option.label)
    allowSubmit = !@isConversionMetric(metric)
    @setState(
      metric: metric,
      conversion: undefined,
      @submitFormCallback(allowSubmit)
    )

  onConversionSelect: (option) =>
    @setState(conversion: option.value, @submitFormCallback(true))

  renderSelect: (placeholder, name, options, callback) ->
    return unless options

    activeOption = _.findWhere(options, value: @state[name])
    buttonProps =
      disabled: options.length <= 1

    `<div className='u-marginRight'>
      <FormGroup>
        <Select
          activeOption={activeOption}
          buttonProps={buttonProps}
          name={name}
          onOptionClick={callback}
          options={options}
          placeholder={placeholder}
        />
      </FormGroup>
    </div>`

  renderConversionSelection: ->
    return unless @isConversionMetric(@state.metric)

    options = @props.conversionOptions
    activeOption = _.findWhere(options, value: @state.conversion)
    buttonProps =
      disabled: options.length <= 1

    `<div>
      <SearchableSelect
        activeOption={activeOption}
        buttonProps={buttonProps}
        name='conversion'
        onOptionClick={this.onConversionSelect}
        options={options}
      />
    </div>`

  render: ->
    guidelineSelect = @renderSelect(
      'Guideline',
      'guideline',
      @props.guidelineOptions,
      @onGuidelineSelect
    )

    channelSelect = @renderSelect(
      'Channel',
      'channel',
      @props.channelOptions,
      @onChannelSelect
    )

    metricSelect = @renderSelect(
      'Metric',
      'metric',
      @metricOptions(),
      @onMetricSelect
    )

    `<div>
      {this.renderHiddenInput('filter_query', this.props.filterQueryId)}
      {this.renderHiddenInput('performance[guideline]', this.state.guideline)}
      {this.renderHiddenInput('performance[channel]', this.state.channel)}
      {this.renderHiddenInput('performance[metric]', this.state.metric)}
      {this.renderHiddenInput('performance[conversion]', this.state.conversion)}
      <div className='performanceMeasurement-optionsSelection'>
        { guidelineSelect }
        { channelSelect }
        { metricSelect }
        { this.renderConversionSelection() }
      </div>
    </div>`

export default AuditPerformanceSelector
