import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Column from "components/reusable/column"
import Loader from "components/reusable/Loader"
import Row from "components/reusable/row"
import ViewSelector from "components/reusable/view_selector"
import { Tooltip } from '@makeably/creativex-design-system';

# This higher order component allows us to quickly build containers that display react components with
# props from endpoints.
# ex. PlotlyGraphContainer, CategoryAnalysisContainer
#
# To use AjaxContainer, compose a component with AjaxContainer(ContainerComponent, ContentComponent).
#
# To instantiate a component you should input contentOptions which includes labels and urls where to load the
# content props. If you want to pre-load some or all data, you can provide contentProps which should be a hash
# from values in contentOptions to content props.
#
# If you don't need multiple options, just provide the contentLocation prop.
#
#
# Advanced isControlledContent usage:
#
# If your ContentComponent loads/changes state that you then want to feed to the container you'll want to use this.
# First, set isControlledContent to true when constructing the AjaxContainer. This will pass through a
# onContentChange(changes) call to the content component which can give deltas to update contentProps with.
#
# Small example: If your content component has a percent vs. number view and you switch from percent to number then when
# you switch content components the changes from percent to number will be lost. In this case you should do something
# like:
#    changes = { defaultView: 'number' }
#    @props.onContentChange(changes)
#    # See componentDidUpdate below for where to put this logic.
#
# Large example: If you have a container that loads up other ajax containers, then if you don't use isControlledContent
# and onContentChange the loaded ajax content will be lost every time you change content.
# See componentDidUpdate below.
AjaxContainer = (ContainerComponent, ContentComponent, isControlledContent = false) -> class extends React.Component
  @propTypes =
    contentLocation: PropTypes.string   # This allows us to load something without contentOptions
    contentOptions: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string         # Label for pill
        value: PropTypes.string         # Url / contentProps hash key
      )
    )
    contentProps: PropTypes.object      # This allows us to have props pre-populated if AJAX doesn't make sense
    onContentChange: PropTypes.func     # This allows us to notify container of updated properties
    title: PropTypes.string             # Title for card, can be overridden with contentProps.cardTitle

  @defaultProps =
    contentProps: {}

  classes: ->
    classNames(
      @props.className
    )

  constructor: (props) ->
    super(props)
    @state = @stateFromProps(props)
    @state.isContentLoadingData = false

  # TODO: Need to update
  #  * Move data fetching code or side effects to componentDidUpdate.
  #  * If you're updating state whenever props change, refactor your code to
  #     use memoization techniques or move it to static getDerivedStateFromProps.
  #     Learn more at: https://fb.me/react-derived-state
  #
  # If this is a controlled component and the props update, we want to reset
  # the state. Constructor will not be called again.
  UNSAFE_componentWillReceiveProps: (nextProps) ->
    @setState(@stateFromProps(nextProps))

  stateFromProps: (props) ->
    {
      contentProps: props.contentProps
      contentLocation: props.contentLocation || props.contentOptions[0].value
      isLoadingData: false
    }

  # If ever we update the state, we want to notify the containing component if onContentChange is defined.
  # We update contentLocation/contentProps on the props if any changes are detected.
  componentDidUpdate: (_prevProps, prevState) ->
    if @props.onContentChange
      prevValues = {
        contentLocation: prevState.contentLocation
        contentProps: prevState.contentProps
      }
      changes = {
        contentLocation: @state.contentLocation
        contentProps: @state.contentProps
      }

      # Only call if we actually have changes
      @props.onContentChange(changes) if !_.isEqual(prevValues, changes)

  # This will trigger an AJAX call as soon as the component is up. Loader and all.
  componentDidMount: ->
    @handleContentLocationChange(@state.contentLocation)

  handleContentLocationChange: (newLocation) =>
    return if @state.isLoadingData

    # Always update the contentLocation to display new content or a loader/content retrieval
    updatedStateProps =
      contentLocation: newLocation

    # Fetch content and show loader if have nothing to display
    unless @state.contentProps[newLocation]
      updatedStateProps['isLoadingData'] = true

      $.get(
        newLocation
      ).done((response) =>
        newContentProps = $.extend(true, {}, @state.contentProps)
        newContentProps[newLocation] = response
        @setState(contentProps: newContentProps)
      ).always(=>
        @setState(isLoadingData: false)
      )

    @setState(updatedStateProps)

  handleControlledContentComponentChange: (contentLocation) =>
    (changes, isContentLoadingData = false) =>
      newContentProps = $.extend(true, {}, @state.contentProps)
      # Get the current contentComponent props and copy over the changes
      newContentProps[contentLocation] = $.extend(true, newContentProps[contentLocation], changes)
      @setState(contentProps: newContentProps, isContentLoadingData: isContentLoadingData)

  renderContentSelector: ->
    if @props.contentOptions
      `<ViewSelector onChange={this.handleContentLocationChange}
                     options={this.props.contentOptions}
                     disabled={this.state.isLoadingData || this.state.isContentLoadingData}
                     selected={this.state.contentLocation}
                     shrink
      />`

  render: =>
    contentComponentProps = @state.contentProps[@state.contentLocation]

    if @state.isLoadingData
      content = `<Loader/>`
    else if contentComponentProps
      if isControlledContent
        # Give the content component access to pass changes to this container
        contentComponentProps.onContentChange = @handleControlledContentComponentChange(@state.contentLocation)

      content =
        `<ContentComponent
          {...contentComponentProps}
        />`
      title = contentComponentProps.cardTitle
      tooltipText = contentComponentProps.cardTooltipText
    else
      # If there are no contentProps/not loading then we are in an error situation
      content = `<span>Oops! Something went wrong and we are taking a look.</span>`

    # title/tooltipText can either be provided by the contentComponentProps (ex. metric specific) or by the container
    # priority is given to the contentComponentProps
    title ||= @props.title
    tooltipText ||= @props.tooltipText
    tooltip = `<Tooltip label={tooltipText} />` if tooltipText

    `<ContainerComponent className={this.classes()}>
      <Row>
        <Column className='ajaxCard-headerRow no-padding'>
          <h5>
            {title}
            {tooltip}
          </h5>
          {this.renderContentSelector()}
        </Column>
      </Row>
      <Row>
        <Column className='no-padding'>
          {content}
        </Column>
      </Row>
    </ContainerComponent>`

export default AjaxContainer
