import React from "react"
import Button from "components/reusable/button"
import PropTypes from "prop-types"
import classNames from "classnames"
import Column from "components/reusable/column"
import Row from "components/reusable/row"
import { Tab, TabItem } from "@duik/it"

# Navigation Tabs
#
# NB: when tabs are used below pageHeaders, we ignore the top padding from the tabs
#     and use the margin bottom from the pageHeaders to space things. This happens to look fine
#     on most pages (now), but is brittle and makes this component difficult to use outside of
#     that context. As a temporary solution (until we fix the pageHeader issue), you
#     can pass in the className `tabs-container--useTopPadding` to get equal spacing
#     on tabs
class Tabs extends React.Component
  @propTypes =
    tabItems: PropTypes.arrayOf(
      PropTypes.shape(
        active: PropTypes.bool
        label: PropTypes.string
        link: PropTypes.string
      )
    ).isRequired
    # Height of the tabs
    # Note: md is the default for the Tab component in duik
    visualStyle: PropTypes.oneOf(['md', 'sm', 'xs'])

  @defaultProps =
    visualStyle: 'xs'

  renderTabItem: (tab) ->
    classes = classNames(
      "active": tab.active
    )

    tabProps =
      className: classes
      href: tab.link
      key: tab.label

    `<TabItem {...tabProps}>
      {tab.label}
    </TabItem>`

  render: ->
    if @props.visualStyle and @props.visualStyle isnt 'md'
      sizeProps =
        sm: @props.visualStyle is 'sm'
        xs: @props.visualStyle is 'xs'

    `<Tab {...sizeProps} className="resetTabs">
      {this.props.tabItems.map(this.renderTabItem)}
    </Tab>`

export default Tabs
