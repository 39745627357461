import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@makeably/creativex-design-system';
import GuidelineWeights from 'components/scores/modal/GuidelineWeights';
import { guidelineProps } from 'components/scores/modal/ScoreModal';

const propTypes = {
  guidelines: PropTypes.arrayOf(
    PropTypes.shape(guidelineProps),
  ).isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
};

const defaultProps = {
  description: undefined,
};

function ScoreConfirmation({
  description,
  guidelines,
  label,
}) {
  return (
    <>
      <h5 className="u-marginBottom-8">{ label }</h5>
      { description && <div className="t-body-1">{ description }</div> }
      <Divider margin />
      <GuidelineWeights guidelines={guidelines} />
    </>
  );
}

ScoreConfirmation.propTypes = propTypes;
ScoreConfirmation.defaultProps = defaultProps;

export default ScoreConfirmation;
