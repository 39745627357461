import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import { secondsToMinutes } from 'utilities/date';
import styles from './VideoInfo.module.css';

const propTypes = {
  length: PropTypes.number,
  showPlay: PropTypes.bool,
};

const defaultProps = {
  length: undefined,
  showPlay: undefined,
};

function VideoInfo({
  length,
  showPlay,
}) {
  if (!length) return null;

  return (
    <div className={styles.overlay}>
      { showPlay && <Icon color="white" name="play" /> }
      <span className={`t-button-bold ${styles.length}`}>
        { secondsToMinutes(length) }
      </span>
    </div>
  );
}

VideoInfo.propTypes = propTypes;
VideoInfo.defaultProps = defaultProps;

export default VideoInfo;
