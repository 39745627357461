import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Pagination,
} from '@makeably/creativex-design-system';
import QueueTable from 'components/admin/review_queue/QueueTable';
import Filters, { filterProps } from 'components/filters/AdminAuditFilters';
import NavDropdown, { propTypes as navProps } from 'components/navigation/NavDropdown';
import SearchableNavDropdown from 'components/navigation/SearchableNavDropdown';
import { HiddenInput } from 'components/reusable/HiddenInput';
import styles from './InflightQueue.module.css';

const inflightProps = {
  brand: PropTypes.string,
  channel: PropTypes.string,
  dateSubmitted: PropTypes.string,
  id: PropTypes.number,
  link: PropTypes.string,
  market: PropTypes.string,
  organization: PropTypes.string,
  reviewer: PropTypes.string,
};

const propTypes = {
  auditPosts: PropTypes.arrayOf(PropTypes.shape(inflightProps)).isRequired,
  count: PropTypes.number.isRequired,
  filters: filterProps.isRequired,
  formUrl: PropTypes.string.isRequired,
  organizationFilter: PropTypes.shape(navProps).isRequired,
  page: PropTypes.number.isRequired,
  pageSizeLimit: PropTypes.number.isRequired,
  viewState: PropTypes.oneOf(['pending', 'reviewed', 'unreviewable']).isRequired,
  contractScope: PropTypes.string,
  contractScopeFilter: PropTypes.shape(navProps),
};

const defaultProps = {
  contractScope: undefined,
  contractScopeFilter: undefined,
};

const headerOptions = [
  {
    label: 'ID',
    value: 'id',
  },
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Market',
    value: 'market',
  },
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'Reviewer',
    value: 'reviewer',
  },
  {
    label: 'Date Captured',
    value: 'dateCaptured',
    tooltip: 'This reflects the date that the post was captured by our system, regardless of when it was published.',
  },
];

class InflightQueue extends React.Component {
  constructor(props) {
    super(props);

    const {
      page,
      viewState,
    } = props;

    let headerFilter;

    if (viewState === 'reviewed') {
      headerFilter = [];
    } else {
      headerFilter = ['reviewer'];
    }

    this.headers = headerOptions.filter((elm) => !headerFilter.includes(elm.value));
    this.state = {
      formLoading: false,
      page,
      pageNavigation: false,
    };
  }

  navigateToPage(page, formId) {
    this.setState({
      formLoading: true,
      page,
      pageNavigation: true,
    }, () => document.getElementById(formId).submit());
  }

  render() {
    const { headers } = this;

    const {
      formLoading,
      page,
      pageNavigation,
    } = this.state;

    const {
      auditPosts,
      contractScope,
      contractScopeFilter,
      count,
      filters,
      formUrl,
      organizationFilter,
      pageSizeLimit,
      viewState,
    } = this.props;

    const formId = 'filteringForm';

    return (
      <div className={styles.container}>
        <div className={styles.filters}>
          <Filters
            contractScope={contractScope}
            filters={filters}
            formId={formId}
            formUrl={formUrl}
            loading={formLoading}
            viewState={viewState}
          />
        </div>
        <div className={styles.card}>
          <Card>
            <div className={styles.header}>
              <SearchableNavDropdown
                activeOption={organizationFilter.activeOption}
                label={organizationFilter.label}
                menuPosition="bottom-right"
                options={organizationFilter.options}
                block
              />
              { contractScopeFilter
                  && (
                    <NavDropdown
                      activeOption={contractScopeFilter.activeOption}
                      label={contractScopeFilter.label}
                      menuPosition="bottom-right"
                      options={contractScopeFilter.options}
                      block
                    />
                  ) }
            </div>
            <QueueTable
              headers={headers}
              items={auditPosts}
            />
            { pageNavigation && <HiddenInput form={formId} name="page" value={page} /> }
            <div className={styles.pagination}>
              <Pagination
                currentPage={page}
                perPage={pageSizeLimit}
                total={count}
                onPageChange={(newPage) => this.navigateToPage(newPage, formId)}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

InflightQueue.propTypes = propTypes;
InflightQueue.defaultProps = defaultProps;

export default InflightQueue;
