// Component is used to render the Onboarding Wizard Modal
//
// Disabling the forbidden prop type rule since it is a lookup,
// for details see the onboarding controller
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Modal,
} from '@duik/it';
import CarouselControls from 'components/reusable/CarouselControls';
import UserOnboarding from 'components/utils/content/UserOnboarding';

const propTypes = {
  profile: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};

function OnboardingContent({ profile, step }) {
  const content = UserOnboarding.content(step, profile);

  return content.map((text, idx) => {
    const key = `${profile}_${idx}`;
    return <p key={key}>{ text }</p>;
  });
}
OnboardingContent.propTypes = {
  profile: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
};

function ImageCarousel(props) {
  const {
    currentStep,
    profile,
  } = props;

  const steps = [1, 2, 3, 4];
  const images = steps.map((step) => {
    const imgSrc = UserOnboarding.imageUrl(step, profile);

    const classes = classNames(
      'onboarding-image',
      {
        'onboarding-image--active': step === currentStep,
        'onboarding-image--prev': step === (currentStep - 1),
        'onboarding-image--next': step === (currentStep + 1),
      },
    );

    return (
      <img
        key={step}
        alt=""
        className={classes}
        data-step={step}
        src={imgSrc}
      />
    );
  });

  return (
    <div className="onboarding-imagesWrapper u-flexEqualWidth">
      <div className="onboarding-images">
        { images }
      </div>
    </div>
  );
}
ImageCarousel.propTypes = {
  currentStep: PropTypes.number.isRequired,
  profile: PropTypes.string.isRequired,
};

class OnboardingWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { step: 1 };
  }

  onStepChange(stepChange) {
    this.setState((prevState) => {
      const newStep = prevState.step + stepChange;
      return { step: newStep };
    });
  }

  onDotClick(step) {
    this.setState({ step });
  }

  render() {
    const {
      profile,
      redirectUrl,
    } = this.props;

    const { step } = this.state;

    const getStartedButton = (
      <Button
        Component="a"
        className="onboarding-complete"
        href={redirectUrl}
        block
        primary
      >
        Get started
      </Button>
    );

    const title = UserOnboarding.headerTitle(step, profile);

    return (
      <Modal className="onboarding-modal" isOpen>
        <div className="onboarding-content u-flexRow">
          <Modal.Body className="onboarding-body u-flexColumn u-flexEqualWidth">
            <div className="u-flexColumn u-spaceBetween u-flexGrow">
              <div>
                <Modal.Title>{ title }</Modal.Title>
                <OnboardingContent
                  profile={profile}
                  step={step}
                />
              </div>
              <div>
                { (step === 4) && getStartedButton }
                <Divider />
              </div>
            </div>
            <CarouselControls
              currentStep={step}
              onClick={(stepChange) => this.onStepChange(stepChange)}
              onDotClick={(newStep) => this.onDotClick(newStep)}
            />
          </Modal.Body>
          <ImageCarousel
            currentStep={step}
            profile={profile}
          />
        </div>
      </Modal>
    );
  }
}

OnboardingWizard.propTypes = propTypes;

export default OnboardingWizard;
