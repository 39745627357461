import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  MessageModal,
} from '@makeably/creativex-design-system';

const propTypes = {
  dimension: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirmCode: PropTypes.func.isRequired,
  onRejectCode: PropTypes.func.isRequired,
  code: PropTypes.string,
  mappedValue: PropTypes.string,
};

const defaultProps = {
  code: undefined,
  mappedValue: undefined,
};

function ConfirmCodeModal({
  code,
  dimension,
  isOpen,
  mappedValue,
  onConfirmCode,
  onRejectCode,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    onConfirmCode();
    setIsLoading(false);
  };

  return (
    <MessageModal
      actionButtonDisabled={isLoading}
      actionButtonLabel="Confirm"
      closeButtonLabel="Reject"
      isOpen={isOpen}
      subtitle="This newly detected code can be added to our system to improve future parsings"
      title="New Taxonomy Code Detected"
      onActionButtonClick={handleConfirm}
      onClose={onRejectCode}
    >
      <div className="u-flexColumn u-gap-16">
        <div className="t-body-1">Please confirm the new code addition</div>
        <div className="u-flexColumn u-gap-8">
          <div className="t-subtitle">{ `${dimension} Code:` }</div>
          <div className="u-flexRow u-gap-8 t-body-1">
            { code }
            <Icon color="darkPurple" name="arrowRight" />
            { mappedValue }
          </div>
        </div>
      </div>
    </MessageModal>
  );
}

ConfirmCodeModal.propTypes = propTypes;
ConfirmCodeModal.defaultProps = defaultProps;

export default ConfirmCodeModal;
