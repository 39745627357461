import React from "react"
import PropTypes from "prop-types"
import NavDropdown from 'components/navigation/NavDropdown'

# Renders the selectors for the brand builder tool
class BrandFilters extends React.Component
  @propTypes =
    brandCueSelectorProps: PropTypes.object
    brandSelectorProps: PropTypes.object
    dateRangeProps: PropTypes.object
    marketSelectorProps: PropTypes.object

  renderSelector: (selectorProps, selectorName) ->
    options = NavDropdown.sanitize(selectorProps.options)
    activeOption = _.findWhere(options, link: selectorProps.selected)

    `<NavDropdown
      activeOption={activeOption}
      label={selectorProps.label}
      name={selectorName}
      options={options}
    />`

  renderBrandSelector: ->
    if @props.brandCueSelectorProps
      `<div className='js-brandCueSelect'>
        {this.renderSelector(this.props.brandCueSelectorProps, 'Brand Cue')}
      </div>`
    else
      `<div className='js-brandSelect'>
        {this.renderSelector(this.props.brandSelectorProps, 'Brand')}
      </div>`

  renderMetricSelector: ->
    return unless @props.metricSelectorProps
    `<div className='js-metricSelect'>
      {this.renderSelector(this.props.metricSelectorProps, 'Metric')}
    </div>`

  renderDateSelector: ->
    return unless @props.dateRangeProps
    `<div className='js-dateRangeSelect'>
      {this.renderSelector(this.props.dateRangeProps, 'Date')}
    </div>`

  render: ->
    `<div className='brandFilters'>
      <div className='u-flexRow u-spaceBetween'>
        <div className='brandFilters-leftSection u-flexRow u-spaceBetween'>
          {this.renderBrandSelector()}
          {this.renderMetricSelector()}
        </div>
        {this.renderDateSelector()}
      </div>
    </div>`

export default BrandFilters
