import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Card } from '@makeably/creativex-design-system';
import ItemsTable from 'components/molecules/ItemsTable';
import { getItemSortBy } from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import { editAdminGuidelineCheckValidationPath } from 'utilities/routes';
import {
  changePage,
  getPage,
  getParams,
} from 'utilities/url';
import styles from './ValidationQueue.module.css';

const propTypes = {
  checks: PropTypes.arrayOf(PropTypes.shape({
    assetId: PropTypes.number,
    brand: PropTypes.string,
    channel: PropTypes.string,
    dateCreated: PropTypes.string,
    dateSubmitted: PropTypes.string,
    id: PropTypes.number,
    market: PropTypes.string,
    organization: PropTypes.string,
    organizationId: PropTypes.number,
    postId: PropTypes.number,
    remainingHours: PropTypes.number,
  })).isRequired,
  preflightView: PropTypes.bool.isRequired,
};

function getHeaders(view) {
  const allHeaders = [
    {
      label: 'Check ID',
      key: 'id',
    },
    {
      label: 'Customer',
      key: 'organization',
    },
    {
      label: 'Brand',
      key: 'brand',
    },
    {
      label: 'Market',
      key: 'market',
    },
    {
      label: 'Channel',
      key: 'channel',
    },
    {
      label: 'Time Remaining',
      key: 'remainingHours',
      filter: 'preflight',
    },
    {
      label: 'Date Submitted',
      key: 'dateSubmitted',
      filter: 'preflight',
    },
    {
      label: 'Date Captured',
      key: 'dateCaptured',
      filter: 'inflight',
    },
    {
      label: 'CreativeX ID',
      key: 'assetId',
    },
    {
      label: 'Post ID',
      key: 'postId',
    },
  ];

  return allHeaders.filter((header) => (header.filter ? header.filter === view : true));
}

function generateQueueSpecificAttrs(check, preflightView) {
  if (preflightView) {
    return {
      dateSubmitted: {
        value: check.dateSubmitted,
        format: 'date',
      },
      remainingHours: {
        value: check.remainingHours,
        text: `${check.remainingHours} hrs`,
      },
    };
  }

  return {
    dateCaptured: {
      value: check.dateCaptured,
      format: 'date',
    },
  };
}

function generateIdLink(check, preflightView) {
  return {
    display: {
      url: editAdminGuidelineCheckValidationPath(
        check.id,
        { preflight_view: preflightView },
      ),
    },
    value: check.id,
  };
}

function ValidationQueue({ checks, preflightView }) {
  const defaultSort = preflightView ? 'remainingHours' : 'id';
  const params = getParams(window);
  const [page, setPage] = useState(getPage(params));
  const [sort, setSort] = useState({
    key: defaultSort,
    asc: true,
  });

  const view = preflightView ? 'preflight' : 'inflight';
  const headers = getHeaders(view);
  const [items, setItems] = useState([]);
  const [sortedItems, setSortedItems] = useState([]);

  useEffect(() => {
    setItems(checks.map((check) => setItemElement({
      ...generateQueueSpecificAttrs(check, preflightView),
      assetId: { value: check.assetId ?? 'N/A' },
      brand: { value: check.brand || 'N/A' },
      channel: { value: check.channel || 'N/A' },
      id: generateIdLink(check, preflightView),
      market: { value: check.market || 'N/A' },
      organization: { value: check.organization },
      postId: { value: check.postId ?? 'N/A' },
    })));
  }, [checks]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    setSortedItems(items.slice().sort(byKeyDir));
  }, [items, sort]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    changePage(newPage, params, window);
  };

  return (
    <div className={styles.card}>
      <Card>
        <div className={styles.table}>
          <ItemsTable
            headers={headers}
            items={sortedItems}
            page={page}
            sort={sort}
            onPageChange={handlePageChange}
            onSortChange={setSort}
          />
        </div>
      </Card>
    </div>
  );
}

ValidationQueue.propTypes = propTypes;

export default ValidationQueue;
