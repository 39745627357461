// Renders a Searchable Dropdown where the options are links
// PropTypes are the same as the SearchableSelect
// Props are the same as SearchableSelect
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '@duik/it';
import { propTypes as navDropdownProps } from 'components/navigation/NavDropdown';
import SearchableSelect, {
  propTypes as selectProps,
  defaultProps as selectDefaultProps,
} from 'components/reusable/SearchableSelect';

// This component uses the NavDropdown version of options
// and will modify them to map to the SearchableSelect
// This is done for consistency of the Navigation Elements
export const propTypes = selectProps;
propTypes.options = PropTypes.arrayOf(
  PropTypes.shape(navDropdownProps.options),
).isRequired;

function SearchableNavDropdown(props) {
  const {
    activeOption,
    block,
    buttonProps,
    label,
    labelTooltip,
    menuPosition,
    multiple,
    name,
    onOptionClick,
    options,
    placeholder,
  } = props;

  // TODO: Currently supporting both old and new here (value vs link). We should
  // clean this up and only use link as the "value" for a SearchableNavDropdown
  // and NavDropdown. Will help when eveywhere uses the presenters
  options.forEach((option, idx) => {
    options[idx].label = option.text;
    options[idx].props = {
      Component: 'a',
      href: option.value || option.link,
      key: option.text,
    };
  });

  const activeOptionForSelect = {
    label: activeOption.text,
    value: activeOption.link,
  };

  return (
    <FormGroup>
      <SearchableSelect
        activeOption={activeOptionForSelect}
        block={block}
        buttonProps={buttonProps}
        label={label}
        labelTooltip={labelTooltip}
        menuPosition={menuPosition}
        multiple={multiple}
        name={name}
        options={options}
        placeholder={placeholder}
        onOptionClick={onOptionClick}
      />
    </FormGroup>
  );
}

SearchableNavDropdown.propTypes = propTypes;
SearchableNavDropdown.defaultProps = selectDefaultProps;

export default SearchableNavDropdown;
