import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
} from '@duik/it';
import Instructions, { getStepHelper } from 'components/account_setup/Instructions';

const CX_NEW_ACCOUNT_PAGE_URL = '/settings/accounts/linked/new';

const propTypes = {
  channel: PropTypes.string,
  content: PropTypes.node,
  emptyMessage: PropTypes.string,
  view: PropTypes.string,
};

const defaultProps = {
  channel: undefined,
  content: undefined,
  emptyMessage: undefined,
  view: undefined,
};

function EmptyDataView({
  channel,
  content: instructionContent,
  emptyMessage,
  view: propView,
}) {
  const view = propView ? `${propView}_missing_data` : undefined;

  return (
    <>
      <Instructions channel={channel} content={instructionContent} view={view} />
      <Divider margin />
      <div className="missingDataText">
        { emptyMessage ?? getStepHelper(channel).getEmptyMessage() }
        <div>
          <Button Component="a" href={CX_NEW_ACCOUNT_PAGE_URL}>
            Back To Platform Selection
          </Button>
        </div>
      </div>
    </>
  );
}

EmptyDataView.propTypes = propTypes;
EmptyDataView.defaultProps = defaultProps;

export default EmptyDataView;
