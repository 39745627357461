import React from 'react';
import BulkUploadPage from 'components/taxonomies/BulkUploadPage';
import { bulkCreateApiCompanyMarketCodesPath } from 'utilities/routes';

const excelTemplate = 'https://picassolabs.s3.amazonaws.com/files/CreativeX%20Bulk%20Market%20Code%20Upload%20Template.xlsx';
const googleTemplate = 'https://docs.google.com/spreadsheets/d/1RbKdn31PmSFc2hVch8EB63mNEOvBmHBCu8y76MQoLdk/copy';

function MarketBulkUpload() {
  return (
    <BulkUploadPage
      excelTemplate={excelTemplate}
      googleTemplate={googleTemplate}
      postUrl={bulkCreateApiCompanyMarketCodesPath()}
      type="market"
    />
  );
}

export default MarketBulkUpload;
