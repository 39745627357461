// Renders a searchable select
// Search is:
//   * By label
//   * Case insensitive
//   * Finds keyword anywhere in value
import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Tooltip } from '@makeably/creativex-design-system';

export const activeOptionProps = {
  label: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      ...activeOptionProps,
      props: PropTypes.shape({
        Component: PropTypes.string,
        href: PropTypes.string,
      }),
    }),
  ).isRequired,
  activeOption: PropTypes.oneOfType([
    PropTypes.shape(activeOptionProps),
    PropTypes.arrayOf(
      PropTypes.shape(activeOptionProps),
    ),
  ]),
  disableClearable: PropTypes.bool,
  disablePortal: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string,
      tooltipText: PropTypes.string,
    }),
  ]),
  labelTooltip: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  onOptionClick: PropTypes.func,
};

const defaultProps = {
  activeOption: undefined,
  disableClearable: true,
  disablePortal: true,
  fullWidth: true,
  label: undefined,
  labelTooltip: undefined,
  multiple: false,
  name: null,
  onOptionClick: undefined,
  placeholder: 'Select...',
  size: 'small',
};

const renderLabel = (props) => {
  const {
    label,
    labelTooltip,
  } = props;

  let tooltip = labelTooltip;
  let labelText = label;

  if (typeof (label) === 'object') {
    tooltip = label.tooltipText;
    labelText = label.text;
  }

  return (
    <div className="u-flexRow u-label">
      <span className="u-marginRightSm">{ labelText }</span>
      { tooltip && <Tooltip label={tooltip} /> }
    </div>
  );
};

function SearchableDropdownSelect(props) {
  const {
    activeOption,
    disableClearable,
    disablePortal,
    fullWidth,
    multiple,
    name,
    onOptionClick,
    options,
    placeholder,
    size,
  } = props;

  const renderInput = (params) => {
    let placeholderText = placeholder;
    if (multiple) {
      placeholderText = undefined;
    }

    return (
      <TextField
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...params}
        placeholder={placeholderText}
        variant="outlined"
      />
    );
  };

  const onClick = (_, newValue) => {
    if (onOptionClick) {
      onOptionClick(newValue, name);
    }
  };

  return (
    <div>
      { renderLabel(props) }
      <Autocomplete
        className="searchableDropdown"
        disableClearable={disableClearable}
        disableCloseOnSelect={multiple}
        disablePortal={disablePortal}
        fullWidth={fullWidth}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value.value === option.value}
        limitTags={1}
        multiple={multiple}
        options={options}
        renderInput={renderInput}
        size={size}
        value={activeOption}
        clearOnEscape
        onChange={onClick}
      />
    </div>
  );
}

SearchableDropdownSelect.propTypes = propTypes;
SearchableDropdownSelect.defaultProps = defaultProps;

export default SearchableDropdownSelect;
