import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button } from  "@duik/it"
import CheckboxGroup from "components/reusable/checkbox_group"
import DataTable from "components/reusable/data_table"
import Loader from "components/reusable/Loader"
import { ModalLaunchButton } from "components/containers/miscellaneous_containers"

# Responsible for filtering logic on a modal data table. This is used by the audit filter on the Reports dashboard
# to filter by ad accounts and ad campaigns. User can select multiple objects at once using the bulkSelector
# (ex. campaign objective, campaign status).
class ObjectSelectorForm extends React.Component
  @propTypes =
    dataUrl: PropTypes.string
    headers: PropTypes.arrayOf(
      PropTypes.shape(
        value: PropTypes.string
      )
    )
    label: PropTypes.string
    modalId: PropTypes.string
    onChange: PropTypes.func
    selected: PropTypes.arrayOf(PropTypes.string)

  @defaultProps =
    selected: []

  constructor: (props) ->
    super(props)

    # Bulk selectors can be passed in via remote data call and allow selection
    # all objects of the selected field
    @state =
      bulkSelectors: []
      dataRows: undefined
      hideUnselectedRows: @props.selected.length > 0
      loadingData: false
      selectedBulkSelectors: {}

  fetchData: =>
    @setState(
      bulkSelectors: []
      dataRows: undefined
      hideUnselectedRows: @props.selected.length > 0
      loadingData: true
    )

    fetch(
      @props.dataUrl
    ).then((resp) ->
      resp.json()
    ).then((jsonData) =>
      selectedBulkSelectors = {}

      bulkSelectors = jsonData.bulkSelectors
      if bulkSelectors
        _.each(bulkSelectors, (bulkSelector) ->
          selectedBulkSelectors[bulkSelector.field] = []
        )

      @setState(
        bulkSelectors: bulkSelectors
        dataRows: jsonData.dataRows
        loadingData: false
        selectedBulkSelectors: selectedBulkSelectors
      )
    )

  handleRowSelect: (event) =>
    target = event.target
    value = target.value
    additionalFields =
      inputName: target.name
      isChecked: target.checked

    selectedObjectId =
      if additionalFields['isChecked']
        if @props.selected.length > 0
          @props.selected.concat([value])
        else
          [value]
      else
        _.without(@props.selected, value)

    # update filter input options and render hidden inputs
    @props.onChange(selectedObjectId)

  handleSelectAll: (event) =>
    target = event.target
    value = target.value
    additionalFields =
      inputName: target.name
      isChecked: target.checked

    selectedObjectIds =
      if additionalFields['isChecked']
        @pluckValues(@state.dataRows, 'api_id')
      else
        []

    @props.onChange(selectedObjectIds)

  # Add selected values in bulk (when user selects checkbox group option(s))
  handleBulkSelect: (event) =>
    return unless @state.dataRows.length > 0

    target = event.target
    value = target.value
    additionalFields =
      inputName: target.name
      isChecked: target.checked

    # Update state with selected bulkSelector
    updatedSelectedBulkSelectors = @state.selectedBulkSelectors
    bulkSelectorField = additionalFields['inputName']

    updatedSelectedBulkSelectors[bulkSelectorField] =
      if additionalFields['isChecked']
        updatedSelectedBulkSelectors[bulkSelectorField].concat(value)
      else
        _.without(updatedSelectedBulkSelectors[bulkSelectorField], value)

    @setState(selectedBulkSelectors: updatedSelectedBulkSelectors)

    selectedObjects = _.filter(@state.dataRows, (object) ->
      isIncluded = []

      _.each(updatedSelectedBulkSelectors, (bulkSelectorValues, bulkSelectorField) ->
        if bulkSelectorValues.length > 0
          field = _.find(object, (field) -> field.field is bulkSelectorField)
          isIncluded.push(_.contains(bulkSelectorValues, field.value))
      )

      _.every(isIncluded, (value) -> value is true) if isIncluded.length > 0
    )

    selectedObjectIds = @pluckValues(selectedObjects, 'api_id')
    @props.onChange(selectedObjectIds)

  toggleSelectedObjects: =>
    @setState(hideUnselectedRows: !@state.hideUnselectedRows)

  # Plucks the values at the given field/column (ex. 'objective', 'status')
  pluckValues: (objects, column) ->
    _.map(objects, (row) ->
      _.find(row, (field) -> field.field is column).value
    )

  renderBulkSelector: (bulkSelector) =>
    column_size = 12 / @state.bulkSelectors.length
    checkboxGroupOptions =
      _.find(@state.bulkSelectors, (selector) -> selector.field is bulkSelector.field).checkboxGroupOptions
    selectedValues = @state.selectedBulkSelectors[bulkSelector.field]
    colClasses = classNames(
      'col'
      'no-padding'
      "s#{column_size}"
    )

    `<div className={colClasses} key={bulkSelector.field}>
      <label>{bulkSelector.label}</label>
      <CheckboxGroup
        inputName={bulkSelector.field}
        onSelect={this.handleBulkSelect}
        options={checkboxGroupOptions}
        selectedValues={selectedValues}
        visualStyle={bulkSelector.visualStyle}
      />
    </div>`

  renderBulkSelectors: ->
    return unless @state.bulkSelectors
    bulkSelectors = _.map(@state.bulkSelectors, @renderBulkSelector)
    `<div className='row'>
      {bulkSelectors}
    </div>`

  renderToggleSelectedObjectsButton: ->
    return if _.isUndefined(@state.dataRows)
    label = if @state.hideUnselectedRows then "See All" else "See Selected"

    `<div className='row'>
      <div className='col s12 no-padding'>
        <Button sm onClick={this.toggleSelectedObjects}>
          {label}
        </Button>
      </div>
    </div>`

  renderDataTable: ->
    content =
      if @state.loadingData
        `<Loader/>`
      else if !_.isUndefined(@state.dataRows)
        `<DataTable
          className='dimensionFilter-objectsTable'
          headers={this.props.headers}
          hideUnselectedRows={this.state.hideUnselectedRows}
          onAllRowsSelect={this.handleSelectAll}
          onRowSelect={this.handleRowSelect}
          rows={this.state.dataRows}
          selectable={true}
          selectedValues={this.props.selected}
          sortDirection='asc'
          sortIndex={1}
          sortable={true}
        />`

    `<div className='row'>
      <div className="col s12 no-padding">
        {content}
      </div>
    </div>`

  renderCounts: ->
    modalHeader = "Select #{@props.label} to Include"
    if _.isUndefined(@state.dataRows)
      `<div className='row'>
        <div className='col s12 no-padding'>
          <h5 className="modalHeader--withBottomBorder">{modalHeader}</h5>
        </div>
      </div>`
    else
      formattedTotalCount = FormatUtil.metricFormat('integer', @state.dataRows.length)
      formattedSelectedCount = FormatUtil.metricFormat('integer', @props.selected.length)
      `<div className='row'>
         <div className='col s12 no-padding'>
          <h5 className="modalHeader--withBottomBorder">{modalHeader}</h5>
        </div>

        <div className='col s6 no-padding'>
          <h5>Total {this.props.label}: {formattedTotalCount}</h5>
        </div>

        <div className='col s6 right-align no-padding'>
          <h5>Selected {this.props.label}: {formattedSelectedCount}</h5>
        </div>
      </div>`

  renderModal: ->
    `<div>
      {this.renderCounts()}
      {this.renderBulkSelectors()}
      {this.renderToggleSelectedObjectsButton()}
      {this.renderDataTable()}
    </div>`

  render: ->
    modalId = @props.modalId
    label =
      if @props.selected.length
        "Selected #{this.props.label}: " + @props.selected.length
      else
        "Filter by #{this.props.label}"

    modalProps = {
      modal: {
        children: @renderModal()
        className: 'lg'
        id: modalId
        onOpen: @fetchData
      }
      contentProps: {
        'data-target': modalId
        children: label
        className: 'btn-sm'
      }
      launchType: "click"
    }

    `<div>
      <ModalLaunchButton {...modalProps} />
    </div>`

export default ObjectSelectorForm
