/* global MIXPANEL_DEBUG_ENABLED */
/* global MIXPANEL_ENABLED */
/* global MIXPANEL_TOKEN */

import mixpanel from 'mixpanel-browser';
import { retrieveFromLocalStorage } from 'utilities/storage';

class MixpanelHelper {
  static getPageProperties(pageName, productArea, pageProperties) {
    const properties = {
      ...this.getUserAttributesFromCookies(),
      pageName,
      productArea,
    };

    if (!pageProperties) return properties;

    pageProperties.forEach(
      (pageProperty) => { properties[pageProperty.name] = pageProperty.value; },
    );

    return properties;
  }

  static getPageProductAreaAttributesFromCookies() {
    const properties = {};
    properties.pageName = retrieveFromLocalStorage('pageName');
    properties.productArea = retrieveFromLocalStorage('productArea');
    return properties;
  }

  static getUserAttributesFromCookies() {
    const properties = {};
    try {
      const userAttributes = retrieveFromLocalStorage('userAttributes');

      if (userAttributes) {
        properties.userId = userAttributes.userId;
        properties.companyId = userAttributes.companyId;
        properties.companyName = userAttributes.companyName;
        properties.userEmail = userAttributes.userEmail;
        properties.userType = userAttributes.userType;
        properties.actingAsUserId = userAttributes.actingAsUserId;
      }
    } catch (SyntaxError) {
      return properties;
    }
    return properties;
  }

  static validProperties(properties) {
    if (!properties.userId) return false;
    if (!properties.productArea || !properties.pageName) return false;
    if (properties.productArea === 'undefined' || properties.pageName === 'undefined') return false;

    return true;
  }

  static trackEvent(eventName, eventProperties = {}) {
    const properties = {
      ...this.getUserAttributesFromCookies(),
      ...this.getPageProductAreaAttributesFromCookies(),
      ...eventProperties,
    };

    this.track(eventName, properties);
  }

  static trackPage(pageName, productArea, pageProperties) {
    if (!pageName || !productArea) return;

    this.track(`User Views ${pageName} Page`, this.getPageProperties(pageName, productArea, pageProperties));
  }

  static track(eventName, properties) {
    /* eslint-disable no-console */
    if (!this.validProperties(properties)) {
      if (MIXPANEL_DEBUG_ENABLED) {
        console.info('Invalid Mixpanel Properties event=%s, attributes=%o', eventName, properties);
      }
      return;
    }

    if (MIXPANEL_DEBUG_ENABLED) console.info('mixpanel.track event=%s, attributes=%o', eventName, properties);
    /* eslint-enable no-console */

    if (MIXPANEL_ENABLED) {
      mixpanel.init(MIXPANEL_TOKEN);
      mixpanel.identify(properties.userId);
      mixpanel.track(eventName, properties);
    }
  }
}

export default MixpanelHelper;
