import React from "react"
import DataTable from "components/reusable/data_table"
import Modal from "components/reusable/modal"
import PropTypes from "prop-types"
import { CustomFiltersForm } from "components/containers/miscellaneous_containers"

# This component renders a DataTable of either CustomFilters or CustomFilterOptions.
#
# For each row in the DataTable, there is an edit action column that when clicked, launches a modal
#   containing an edit form.
class CustomFiltersTable extends React.Component
  @propTypes =
    dataTable: PropTypes.object
    editFilterForms: PropTypes.arrayOf(
      PropTypes.shape(
        form: PropTypes.object
        modalId: PropTypes.string
      )
    )

  renderEditFilterForm: (formProps) ->
    `<CustomFiltersForm {...formProps}/>`

  renderEditFilterFormModal: (editFilterForm) =>
    modalProps = {
      id: editFilterForm.modalId
      children: @renderEditFilterForm(editFilterForm.form)
      className: 'customFiltersForm'
    }
    `<Modal key={editFilterForm.modalId} {...modalProps}/>`

  render: ->
    `<div>
       {this.props.editFilterForms.map(this.renderEditFilterFormModal)}
       <DataTable className='editCustomFiltersTable' {...this.props.dataTable}/>
     </div>`

export default CustomFiltersTable
