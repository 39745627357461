import React from 'react';
import PropTypes from 'prop-types';
import TierRow, { tierProps } from 'components/rules/tiers/TierRow';

const propTypes = {
  tiers: PropTypes.arrayOf(
    PropTypes.shape(tierProps),
  ).isRequired,
  editing: PropTypes.bool,
  onTierChange: PropTypes.func,
};

const defaultProps = {
  editing: false,
  onTierChange: () => {},
};

function TierForm({
  editing,
  onTierChange,
  tiers,
}) {
  return (
    <div className="u-flexColumn u-gap-16">
      { tiers.map((tier) => (
        <TierRow
          key={tier.rank}
          editing={editing}
          tier={tier}
          onTierChange={onTierChange}
        />
      )) }
    </div>
  );
}

TierForm.propTypes = propTypes;
TierForm.defaultProps = defaultProps;

export default TierForm;
