import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Spinner,
  TextInput,
  findObjectByValue,
  toggleObject,
} from '@makeably/creativex-design-system';
import AuditFilterCollection from 'components/audit/audit_filter_collection.coffee';
import { addToast } from 'components/organisms/Toasts';
import { track } from 'utilities/mixpanel';
import { post } from 'utilities/requests';
import {
  settingsCsvExportsPath,
} from 'utilities/routes';
import styles from './NewExport.module.css';

const PLACEMENT_TYPE = 'CsvExport::PlacementLevel';

const dataOptionShape = PropTypes.shape({
  description: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
});

const propTypes = {
  dataOptions: PropTypes.arrayOf(dataOptionShape).isRequired,
  filterOptions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  existingExport: PropTypes.shape({
    exportType: PropTypes.string,
    name: PropTypes.string,
    selectedDataOptions: PropTypes.arrayOf(PropTypes.string),
  }),
};

const defaultProps = {
  existingExport: {
    exportType: 'post',
    name: '',
    selectedDataOptions: [],
  },
};

const exportTypeOptions = [
  {
    label: 'Post Level',
    value: 'post',
  },
  {
    label: 'Placement Level',
    value: 'placement',
  },
];

function renderDataOption(selected, data, onChange) {
  const isChecked = selected ? findObjectByValue(selected, data) : false;

  const checkLabel = (
    <>
      <div>{ data.label }</div>
      <div className="t-caption-1">{ data.description }</div>
    </>
  );

  return (
    <div key={data.label} className="u-marginBelowSm">
      <Checkbox
        checked={isChecked}
        label={checkLabel}
        name={data.value}
        onChange={() => onChange(toggleObject(selected, data))}
      />
    </div>
  );
}

async function generateExport(filterFormId, name, reportType, selectedData) {
  const filterForm = document.getElementById(filterFormId);
  const filterFormData = new FormData(filterForm);
  filterFormData.append('name', name);
  filterFormData.append('export_type', reportType.value);

  selectedData.forEach(({ value }) => {
    filterFormData.append('data_options[]', value);
  });

  const response = await post(
    settingsCsvExportsPath(),
    filterFormData,
  );

  return response.data;
}

function NewExport({
  dataOptions,
  existingExport: {
    exportType: initExportType,
    name,
    selectedDataOptions,
  },
  filterOptions,
}) {
  const [exportName, setExportName] = useState(name);
  const [exportType, setExportType] = useState(exportTypeOptions.find(
    (option) => option.value === (initExportType === PLACEMENT_TYPE ? 'placement' : 'post'),
  ));

  const [isBusy, setIsBusy] = useState(false);
  const [selectedData, setSelectedData] = useState(
    dataOptions.filter((option) => selectedDataOptions.includes(option.value)),
  );

  const onGenerate = async () => {
    setIsBusy(true);

    const formId = filterOptions.formProps.id;
    const data = await generateExport(formId, exportName, exportType, selectedData);

    if (data.isError) {
      addToast('Something went wrong, please try again.', {
        size: 'large',
        type: 'error',
      });
    } else {
      track('generate_csv_export');
      addToast("Your report is being processed. You'll receive an email in less than 24 hours when your CSV is ready to download.", { size: 'large' });
      window.location.href = settingsCsvExportsPath();
    }

    setIsBusy(false);
  };

  const missingExportName = exportName === '';

  return (
    <div className={styles.page}>
      <Card className={styles.selectionCard}>
        <h5 className="u-marginBottom-16">Configure Export</h5>
        <div className="u-flexRow u-marginBottom-16">
          <div className="u-marginRight-8">
            <TextInput
              label="Export Name"
              name="export_name"
              size="medium"
              value={exportName}
              required
              onChange={(value) => setExportName(value)}
            />
          </div>
          <Dropdown
            label="Export Type"
            menuProps={{ size: 'medium' }}
            options={exportTypeOptions}
            selected={exportType}
            size="medium"
            onChange={(option) => setExportType(option)}
          />
        </div>
        <div>
          <div className="t-caption-1">Column Groups</div>
          <div>
            { dataOptions.map((data) => (
              renderDataOption(selectedData, data, setSelectedData)
            )) }
          </div>
        </div>
        <h5 className="u-marginTop-16 u-marginBottom-8">Filter by</h5>
        <div className={styles.filteringOverride}>
          <AuditFilterCollection
            filtersForExports
            startWithFilter
            vertical
            {...filterOptions}
          />
        </div>
        <div className="u-marginTop-8 u-flexRow u-justifyEnd">
          <Button
            disabled={isBusy || missingExportName}
            label="Generate"
            variant="primary"
            onClick={onGenerate}
          />
        </div>
        { isBusy && (
          <div className={styles.busy}>
            <Spinner />
          </div>
        ) }
      </Card>
    </div>
  );
}

NewExport.propTypes = propTypes;
NewExport.defaultProps = defaultProps;

export default NewExport;
