import React from 'react';
import PropTypes from 'prop-types';
import MixpanelTracker from 'components/utils/MixpanelTracker';

export const propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  inlineText: PropTypes.string,
  trackingName: PropTypes.string,
};

const defaultProps = {
  inlineText: '',
  trackingName: undefined,
};

function Link(props) {
  const {
    inlineText,
    label,
    path,
    trackingName,
  } = props;

  const linkContent = (
    <div>
      { inlineText }
      <a href={path} onClick={() => MixpanelTracker.trackButtonClick(trackingName || label)}>
        { label }
      </a>
    </div>
  );
  return linkContent;
}

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
