import React from 'react';
import { HiddenInput } from 'components/reusable/HiddenInput';
import { logError } from 'utilities/logging';
import { getAuthenticityToken as getToken } from 'utilities/requests';

// NOTE: Deprecated, use utilities/requests.js
function getAuthenticityToken() {
  if (process.env.NODE_ENV === 'development') {
    console.warn('getAuthenticityToken has moved. Import from requests utility instead'); // eslint-disable-line no-console
  }

  const tokenEl = document.querySelector('meta[name="csrf-token"]');
  if (tokenEl) return tokenEl.content;

  logError('Cannot find csrf-token');
  return undefined;
}
function AuthenticityTokenInput() {
  return (
    <HiddenInput name="authenticity_token" value={getToken()} />
  );
}
export {
  getAuthenticityToken,
};
export default AuthenticityTokenInput;
