// Helper functions for manipulating and transforming javascript objects: adding/removing keys, etc.
import {
  titleize,
  toSnakeCase,
} from 'utilities/string';

// Runs an update function on each value of an object
export function updateValues(obj, func) {
  return Object.entries(obj).reduce((all, [key, value]) => ({
    ...all,
    [key]: func(value),
  }), {});
}

export function filterValues(obj, func) {
  return Object.entries(obj).reduce((all, [key, value]) => {
    if (func(value)) {
      return {
        ...all,
        [key]: value,
      };
    }

    return all;
  }, {});
}

// If condition is true, returns an object with key/value pair
// If condition is false, returns an empty object
export function valueIf(condition, key, value) {
  if (!condition) return {};

  return { [key]: value };
}

export function valueToObject(value, formatted = false) {
  const label = formatted ? titleize(value) : value;
  return {
    label,
    value,
  };
}

export function removeProperty(object, key) {
  const { [key]: _toRemove, ...rest } = object;
  return { ...rest };
}

export function toggleProperty(object, key, value) {
  if (object[key]) {
    return removeProperty(object, key);
  }
  return {
    ...object,
    [key]: value,
  };
}

export function intersectByValue(arr, otherArr) {
  return arr.filter(({ value }) => otherArr.findIndex((obj) => obj.value === value) !== -1);
}

// Changes all object keys to snake case
export function toSnakeCaseKeys(obj) {
  return Object.entries(obj).reduce((updated, [key, value]) => ({
    ...updated,
    [toSnakeCase(key)]: value,
  }), {});
}
