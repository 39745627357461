# TODO: Replace me with the duik Select multiple
import React from "react"
import AntdSelect from "components/reusable/antd_select"
import PropTypes from "prop-types"

# NB: This component is a wrapper for the Ant Design Multi-Select. It's good for forms where you
# also need to render a hidden input with the values selected. If you need the generic version of
# the component, check out AntdSelect.
class MultiSelect extends React.Component
  @propTypes =
    allowClear: PropTypes.bool
    containerClassName: PropTypes.string
    disabled: PropTypes.bool
    inputName: PropTypes.string
    onChange: PropTypes.func
    options: PropTypes.arrayOf(
      PropTypes.shape(
        disabled: PropTypes.bool
        label: PropTypes.string
        value: PropTypes.string
      )
    )
    placeholder: PropTypes.string
    selected: PropTypes.array

  @defaultProps =
    allowClear: true
    selected: []

  renderHiddenInput: (value, idx) =>
    inputName = @props.inputName
    `<input key={idx} type="hidden" name={inputName} value={value} />`

  render: ->
    # copy props and delete keys that aren't relevant downstream
    antdProps = _.pick(
      @props,
      [
        'allowClear'
        'className',
        'disabled',
        'inputName',
        'placeholder',
        'onChange',
        'options',
        'selected'
      ]
    )
    antdProps['mode'] = 'multiple'

    `<div className={this.props.containerClassName}>
      {this.props.selected.map(this.renderHiddenInput)}
      <AntdSelect {...antdProps} size='small'/>
    </div>`

export default MultiSelect
