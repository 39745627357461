import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { WidgetContent } from '@duik/it';
import BarChart, { chartProps as BarChartProps } from 'components/charts/BarChart';
import CardHeader from 'components/reusable/CardHeader';
import ExpandButton from 'components/reusable/ExpandButton';
import Loader from 'components/reusable/Loader';

const propTypes = {
  cardHeader: PropTypes.shape({
    header: PropTypes.string,
    linkButton: PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    }),
    subHeader: PropTypes.string,
  }).isRequired,
  chart: PropTypes.shape(BarChartProps),
  className: PropTypes.string,
  dataUrl: PropTypes.string,
  remote: PropTypes.bool,
};

const defaultProps = {
  chart: undefined,
  className: undefined,
  dataUrl: undefined,
  remote: false,
};

class ExpandableBarChart extends React.Component {
  static defaultVisible() { return 10; }

  static slice(arr, expanded = false) {
    const endVal = expanded ? arr.length : ExpandableBarChart.defaultVisible();

    return arr.slice(0, endVal);
  }

  constructor(props) {
    super(props);

    this.chartRef = React.createRef();
    const { chart } = props;

    this.state = {
      chart,
      error: false,
      expanded: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { dataUrl, remote } = this.props;
    if (!remote) return;

    this.getData(dataUrl);
  }

  getData(url) {
    this.setState({ loading: true });

    fetch(url)
      .then((res) => res.json())
      .then((data) => this.setState({
        loading: false,
        chart: data,
        error: false,
      }))
      .catch((_error) => this.setState({
        loading: false,
        error: true,
      }));
  }

  expandTable() {
    const { chart } = this.chartRef.current;

    this.setState((prevState) => {
      const expanded = !prevState.expanded;

      const {
        chart: {
          data,
          labels,
        },
      } = prevState;

      chart.data.labels = ExpandableBarChart.slice(labels, expanded);
      chart.data.datasets[0].data = ExpandableBarChart.slice(data, expanded);

      const canvasContainer = chart.canvas.parentNode;
      const dataCount = chart.data.labels.length;
      const newHeight = BarChart.getHeight(dataCount);
      canvasContainer.style.height = `${newHeight}px`;

      return { expanded };
    }, () => {
      chart.update();
      chart.resize();
    });
  }

  render() {
    const {
      className,
      cardHeader: {
        header,
        linkButton,
        subHeader,
      },
    } = this.props;

    const {
      chart,
      error,
      expanded,
      loading,
    } = this.state;

    let content = null;
    let expandButton;

    if (error) {
      content = 'Encountered an error';
    } else if (loading) {
      content = <Loader />;
    } else if (chart) {
      chart.originalData = chart.data;
      chart.data = ExpandableBarChart.slice(chart.originalData, false);
      chart.originalLabels = chart.labels;
      chart.labels = ExpandableBarChart.slice(chart.originalLabels, false);

      // TODO: Consolidate this with the BarChart which supports expansion
      // Since this component does Expansion, we set Bar Chart to be static
      content = (
        <BarChart
          ref={this.chartRef}
          chart={chart}
          static
        />
      );

      // Only need expand button for > 10 elements
      if (chart.labels.length > ExpandableBarChart.defaultVisible()) {
        expandButton = (
          <ExpandButton
            callback={() => this.expandTable()}
            expanded={expanded}
          />
        );
      }
    }

    return (
      <div className={classNames('expandableBarChart', className)}>
        <CardHeader
          header={header}
          linkButton={linkButton}
          subHeader={subHeader}
        />
        <WidgetContent>
          { content }
        </WidgetContent>
        { expandButton }
      </div>
    );
  }
}

ExpandableBarChart.propTypes = propTypes;
ExpandableBarChart.defaultProps = defaultProps;

export default ExpandableBarChart;
