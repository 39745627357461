// TODO: Remove this temporary wrapper component for haml rendering once all
// pages are in React
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@makeably/creativex-design-system';

const propTypes = {
  label: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};
const defaultProps = {
  direction: 'top',
};

function DsTooltip({ label, direction }) {
  if (!label) return null;

  return <Tooltip direction={direction} label={label} />;
}

DsTooltip.propTypes = propTypes;
DsTooltip.defaultProps = defaultProps;

export default DsTooltip;
