import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';

export const propTypes = {
  currentStep: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  label: PropTypes.string,
};

const defaultProps = {
  label: undefined,
};

function Step(props) {
  const {
    currentStep,
    label,
    step,
  } = props;

  const active = step === currentStep;
  const complete = step < currentStep;

  const classes = classNames(
    'stepper-step',
    'u-flexColumn',
    'u-flexAlignCenter',
    {
      'stepper-step--active': active,
      'stepper-step--complete': complete,
      'stepper-step--withLabel': label,
    },
  );

  const stepContentClasses = classNames(
    'stepper-stepContent',
    'u-flexHorizontalVerticalCenter',
  );

  let stepContent = <div>{ step }</div>;

  if (complete) {
    stepContent = (
      <Icon color="white" name="check" noWrapper />
    );
  }

  return (
    <div className={classes}>
      <div className={stepContentClasses}>{ stepContent }</div>
      { label && <div className="stepper-stepLabel">{ label }</div> }
    </div>
  );
}

Step.propTypes = propTypes;
Step.defaultProps = defaultProps;

export default Step;
