// Helper functions for strings
import pluralizePlugin from 'pluralize';
import titleizePlugin from 'titleize';

// Split lowercase sequences followed by uppercase character.
// Uses a space as a separator
// `adAccounts` → `ad Accounts`
export function decamelize(string) {
  return string.replace(/([\p{Lowercase_Letter}\d])(\p{Uppercase_Letter})/gu, '$1 $2');
}

// Places underscore before every uppercase character
// and shifts all characters to lowercase
export function toSnakeCase(string) {
  return decamelize(string).toLowerCase().split(' ').join('_');
}

// Replaces windows-style line endinds with unix-style
export function toUnixFileEndings(string) {
  return string.replaceAll('\r\n', '\n');
}

// Replaces underscore with space
export function splitSnakeCase(string) {
  return string.split('_').join(' ');
}

export function hasContent(text) {
  return ![null, undefined].includes(text) && /\S/.test(text);
}

export function titleize(text) {
  if (!text) return '';

  return titleizePlugin(text);
}

export function pluralize(text) {
  return pluralizePlugin(text);
}

// Turns given Date into a string ex. '2020-01-01'
//
// We prefer this method to toISOString() since we were seeing issues with
// British timezone dates returning off by 1 dates around daylight savings.
//
// Ex: new Date(2021, 9, 1).toISOString() will return 2021-9-30 instead of 2021-10-1
export function dateToString(date) {
  if (date) {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

    return `${ye}-${mo}-${da}`;
  }

  return null;
}

export function toTimeAgoInWords(dateString) {
  const date = new Date(dateString);
  const seconds = Math.floor((new Date() - date) / 1000);

  const secondsInYear = 31536000;
  const secondsInMonth = 2592000;
  const secondsInDay = 86400;
  const secondsInHour = 3600;
  const secondsInMinute = 60;

  let interval = seconds / secondsInYear;
  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }

  interval = seconds / secondsInMonth;
  if (interval > 1) {
    return `${Math.floor(interval)} months ago`;
  }

  interval = seconds / secondsInDay;
  if (interval > 1) {
    return `${Math.floor(interval)} days ago`;
  }

  interval = seconds / secondsInHour;
  if (interval > 1) {
    return `${Math.floor(interval)} hours ago`;
  }

  interval = seconds / secondsInMinute;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes ago`;
  }

  return `${Math.floor(seconds)} seconds ago`;
}

// Validates that string is numeric only (0-9)
export function isNumeric(value) {
  return /^[0-9]*$/.test(value);
}

export function toCount(value, options = {}) {
  if (typeof value !== 'number') return null;

  const { concise } = options;
  const locale = 'en-US';
  const localeOptions = {
    maximumFractionDigits: concise ? 1 : 0,
    maximumSignificantDigits: concise ? 3 : undefined,
    notation: concise ? 'compact' : undefined,
  };

  return value.toLocaleString(locale, localeOptions);
}

export function toConciseCount(value) {
  return toCount(value, { concise: true });
}

export function toPercent(value, dp = 0) {
  if (typeof value !== 'number') return null;

  return `${(100 * value).toFixed(dp)}%`;
}

export function toMultiplier(value, dp = 0) {
  if (typeof value !== 'number') return null;

  return `${(value).toFixed(dp)}x`;
}

export function toSpend(value, options = {}) {
  if (typeof value !== 'number') return null;

  const { concise } = options;
  const locale = 'en-US';
  const localeOptions = {
    currency: 'USD',
    maximumFractionDigits: concise ? 1 : 0,
    maximumSignificantDigits: concise ? 3 : undefined,
    notation: concise ? 'compact' : undefined,
    style: 'currency',
  };

  return value.toLocaleString(locale, localeOptions);
}

export function toConciseSpend(value) {
  return toSpend(value, { concise: true });
}

export function toDate(date) {
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-US', options);
}

export function toShortMonth(dateString) {
  const options = {
    month: 'short',
    timeZone: 'UTC',
  };

  const date = new Date(dateString);
  return Intl.DateTimeFormat('en-US', options).format(date);
}

export function splitSearchToArray(string, separator = ' ') {
  return string?.trim().toLowerCase().split(separator).filter((term) => term !== '');
}

// Removes non alpha-numeric values from a string and replaces them with dashes
export function sanitizeString(string) {
  return string.replace(/[^0-9a-z]+/gi, '-');
}

// Clips a string to a max length and adds ellipses
export function clipString(string, length) {
  if (string.length > length) {
    return string.slice(0, length).concat('...');
  }
  return string;
}
