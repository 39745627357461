import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CopyButton,
  Divider,
  Dropdown,
  Icon,
  toggleObject,
} from '@makeably/creativex-design-system';
import Selection from 'components/admin/representation/Selection';
import Asset from 'components/admin/review_queue/post_info/Asset';
import AudioTranscription from 'components/admin/review_queue/post_info/AudioTranscription';
import GridSelector, { defaultGridOptions } from 'components/admin/review_queue/post_info/GridSelector';
import OcrTags from 'components/admin/review_queue/post_info/OcrTags';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import {
  adminRepresentationPath,
  editAdminRepresentationPath,
  evaluationPropsAdminRepresentationPath,
  updateReviewabilityAdminRepresentationPath,
} from 'utilities/routes';
import {
  splitSnakeCase,
  titleize,
} from 'utilities/string';
import styles from './EvaluationPage.module.css';

export const formName = 'representationDataForm';
const reviewableForm = 'reviewableForm';

const personTemplate = {
  id: undefined,
  age: [],
  sex: [],
  situation: [],
  skin_tone: [],
};

const propTypes = {
  id: PropTypes.number.isRequired,
  organization: PropTypes.string.isRequired,
  setFormIncomplete: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  unreviewableReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function formIncomplete(people, peopleStatus, isVideo) {
  if (!peopleStatus) return true;

  if (peopleStatus === 'individuals' && people.length === 0) return true;

  return people.some((person) => {
    if (!person.position) return true;
    if (isVideo && !person.timestamp) return true;
    if (person.age.length === 0) return true;
    if (person.sex.length === 0) return true;
    if (person.situation.length === 0) return true;
    if (person.skin_tone.length === 0) return true;
    return false;
  });
}

function renderUnreviewableComboButton(id, unreviewableReasons, setReason) {
  const reasons = unreviewableReasons.map((r) => ({
    label: titleize(splitSnakeCase(r)),
    value: r,
  }));

  return (
    <Dropdown
      menuProps={{ size: 'medium' }}
      options={reasons}
      placeholder="Mark Unreviewable"
      size="medium"
      onChange={(option) => setReason(option.value)}
    />
  );
}

function renderReviewableDropdown(isReviewable, id, unreviewableReasons, setReason) {
  if (isReviewable) {
    return renderUnreviewableComboButton(id, unreviewableReasons, setReason);
  }

  return (
    <Button
      label="Mark Reviewable"
      type="submit"
      variant="secondary"
    />
  );
}

function renderHiddenInputs(people, peopleStatus) {
  if (peopleStatus !== 'individuals') return null;

  return people.map((person, idx) => {
    const {
      id,
      position,
      timestamp,
      ...propertyTypesAndValues
    } = person;
    const propertyTypes = Object.keys(propertyTypesAndValues);
    const idForKey = id || `person_${idx}`;

    const hiddenInputs = propertyTypes.map((propertyType) => (
      propertyTypesAndValues[propertyType].map((propertyValue) => {
        const inputName = propertyValue.multiselect ? `people[][${propertyType}][]` : `people[][${propertyType}]`;

        return (
          <HiddenInput
            key={`${idForKey}_${propertyValue.value}`}
            name={inputName}
            value={propertyValue.value}
          />
        );
      })
    ));

    if (id) {
      hiddenInputs.push(<HiddenInput key={idForKey} name="people[][id]" value={id} />);
    }
    hiddenInputs.push(<HiddenInput key={`${idForKey}_position`} name="people[][position]" value={position} />);
    hiddenInputs.push(<HiddenInput key={`${idForKey}_timestamp`} name="people[][timestamp]" value={timestamp} />);

    return hiddenInputs;
  });
}

function EvaluationPage({
  id,
  organization,
  setFormIncomplete,
  setLoading,
  showError,
  unreviewableReasons,
}) {
  const url = editAdminRepresentationPath(id);

  const [assetProps, setAssetProps] = useState();
  const [isReviewable, setIsReviewable] = useState();
  const [diversityValueProps, setDiversityValueProps] = useState();
  const [ocrTags, setOcrTags] = useState([]);
  const [audioTranscription, setAudioTranscription] = useState(undefined);

  const [gridOptions, setGridOptions] = useState(defaultGridOptions);
  const [people, setPeople] = useState();
  const [peopleStatus, setPeopleStatus] = useState();
  const [reason, setReason] = useState();

  useEffect(() => {
    const fetchProps = async () => {
      try {
        const response = await fetch(evaluationPropsAdminRepresentationPath(id));
        const evaluationProps = await response.json();

        const {
          assetValues,
          audioTranscriptionDetails,
          diversityValues,
          ocrTagValues,
          reviewable,
        } = evaluationProps;

        setIsReviewable(reviewable);
        setDiversityValueProps(diversityValues);
        setAssetProps(assetValues);
        setOcrTags(ocrTagValues);
        setAudioTranscription(audioTranscriptionDetails);

        setLoading(false);
      } catch (e) {
        showError();
        setLoading(true);
      }
    };

    fetchProps();
    setGridOptions(defaultGridOptions);
  }, [id]);

  useEffect(() => {
    if (diversityValueProps) {
      const {
        people: initialPeople,
        peopleStatus: initialPeopleStatus,
      } = diversityValueProps;

      setPeople(initialPeople);
      setPeopleStatus(initialPeopleStatus);
    }
  }, [diversityValueProps]);

  useEffect(() => {
    if (reason) {
      document[reviewableForm].submit();
    }
  }, [reason]);

  let isVideo;
  if (assetProps) {
    isVideo = assetProps.isVideo;
  }

  useEffect(() => {
    setFormIncomplete(formIncomplete(people, peopleStatus, isVideo));
  }, [people, peopleStatus]);

  if (!diversityValueProps || !assetProps) return null;

  const onDiversityValueClick = (propertyValue, idx) => {
    const copiedPerson = { ...people[idx] };

    if (propertyValue.multiselect) {
      copiedPerson[propertyValue.type] = toggleObject(
        copiedPerson[propertyValue.type],
        propertyValue,
      );
    } else {
      copiedPerson[propertyValue.type] = [propertyValue];
    }

    const newPeople = [...people];
    newPeople[idx] = copiedPerson;

    setPeople(newPeople);
  };

  const onPeopleStatusChange = (value) => {
    setPeopleStatus(value);
    if (value === 'individuals') {
      const { people: initialPeople } = diversityValueProps;

      let individuals = initialPeople;
      if (individuals.length === 0) {
        individuals = [{ ...personTemplate }];
      }
      setPeople(individuals);
    } else {
      setPeople([]);
    }
  };

  const addPerson = () => {
    const updatedPeople = [...people, { ...personTemplate }];

    setPeople(updatedPeople);
  };

  const removePerson = (idx) => {
    const updatedPeople = [
      ...people.slice(0, idx),
      ...people.slice(idx + 1),
    ];

    setPeople(updatedPeople);
  };

  const onPersonPropertyChange = (propertyName, newProperty, idx) => {
    const copiedPerson = { ...people[idx] };
    copiedPerson[propertyName] = newProperty;
    const newPeople = [...people];
    newPeople[idx] = copiedPerson;

    setPeople(newPeople);
  };

  const { creativeUrl } = assetProps;
  const { diversityOptions } = diversityValueProps;

  return (
    <div className={styles.container}>
      <div className={styles.postInfoWrapper}>
        <div className={styles.postInfo}>
          <Asset
            auditAssetId={id}
            gridOptions={gridOptions}
            isVideo={isVideo}
            sourceUrl={creativeUrl}
          />
          <GridSelector
            callback={(newOptions) => setGridOptions(newOptions)}
            gridOptions={gridOptions}
          />
          <div className="u-marginBelow">
            <a href={creativeUrl} rel="noreferrer" target="_blank">
              <Icon color="grey" name="download" />
            </a>
          </div>
          <OcrTags ocrTags={ocrTags} />
          <AudioTranscription audioTranscription={audioTranscription} />
        </div>
      </div>
      <div className={styles.evaluation}>
        <div className="u-flexRow u-justifySpaceBetween">
          <div className="u-flexColumn">
            <h4>{ organization }</h4>
            <h4>{ `Audit Asset ID: ${id}` }</h4>
            <div className={styles.link}>
              <a href={url} rel="noreferrer" target="_blank">Link</a>
              <CopyButton text={url} />
            </div>
          </div>
          <div>
            <form action={updateReviewabilityAdminRepresentationPath(id)} method="post" name={reviewableForm}>
              <AuthenticityTokenInput />
              <HiddenInput name="unreviewable_reason" value={reason} />
              { renderReviewableDropdown(isReviewable, id, unreviewableReasons, setReason) }
            </form>
          </div>
        </div>
        <Divider />
        <Selection
          diversityOptions={diversityOptions}
          isVideo={isVideo}
          people={people}
          peopleStatus={peopleStatus}
          onDiversityValueClick={onDiversityValueClick}
          onPeopleStatusChange={onPeopleStatusChange}
          onPersonAdd={addPerson}
          onPersonDelete={removePerson}
          onPersonPositionChange={(position, idx) => onPersonPropertyChange('position', position, idx)}
          onPersonTimestampChange={(timestamp, idx) => onPersonPropertyChange('timestamp', timestamp, idx)}
        />
        <form action={adminRepresentationPath(id)} method="post" name={formName}>
          <HiddenInput name="_method" value="patch" />
          <AuthenticityTokenInput />
          <HiddenInput name="people_status" value={peopleStatus} />
          { renderHiddenInputs(people, peopleStatus) }
        </form>
      </div>
    </div>
  );
}

EvaluationPage.propTypes = propTypes;
export default EvaluationPage;
