import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Chart from 'components/molecules/Chart';
import { getCssVars } from 'utilities/css';
import {
  getItemConcise,
  getItemLabel,
  itemProps,
} from 'utilities/item';

const propTypes = {
  displayKey: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(itemProps).isRequired,
  labelKey: PropTypes.string.isRequired,
  tooltipKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  customChartOptions: PropTypes.shape({}),
};

const defaultProps = {
  customChartOptions: undefined,
};

function getData(items, displayKey, labelKey, tooltipKeys) {
  const barColors = getCssVars(['--purple-400']);

  return items.reduce((all, item, index) => {
    const {
      colors,
      contents,
      ids,
      labels,
      tooltips,
      values,
    } = all;

    const id = item.id?.value;
    const value = item[displayKey]?.value;
    const content = getItemConcise(item, displayKey);
    const label = getItemLabel(item, labelKey);
    const tooltip = tooltipKeys.map((key) => getItemLabel(item, key)).join(', ');
    const color = barColors[index % barColors.length];

    return {
      colors: [...colors, color],
      contents: [...contents, content],
      ids: [...ids, id],
      labels: [...labels, label],
      values: [...values, value],
      tooltips: [...tooltips, tooltip],
    };
  }, {
    colors: [],
    contents: [],
    ids: [],
    labels: [],
    tooltips: [],
    values: [],
  });
}

function getMaxValue(items, displayKey) {
  return items.reduce((max, item) => {
    const value = item[displayKey]?.value;

    return value > max ? value : max;
  }, -Infinity);
}

function getChartData(data) {
  return {
    labels: data.labels,
    datasets: [{
      barPercentage: 0.57,
      backgroundColor: data.colors,
      categoryPercentage: 1.0,
      data: data.values,
      contents: data.contents,
      tooltips: data.tooltips,
    }],
  };
}

function getChartOptions(maxValue, showTooltips) {
  return {
    indexAxis: 'y',
    layout: {
      padding: {
        right: 50,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        offset: 5,
        formatter: (value, context) => {
          const index = context.dataIndex;
          const contents = context.dataset.contents ?? [];
          const dataLabel = contents[index] ?? `${value.toFixed(0)}`;
          return dataLabel;
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: showTooltips,
        callbacks: {
          label: ({ dataset, dataIndex }) => {
            const tooltips = dataset.tooltips ?? [];
            const value = dataset.contents[dataIndex];
            const tooltip = tooltips[dataIndex] ?? `${value.toFixed(0)}`;
            return tooltip;
          },
          title: () => null,
        },
        displayColors: false,
      },
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
          max: maxValue,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };
}

function ItemsBarChart({
  displayKey,
  items,
  labelKey,
  tooltipKeys,
  customChartOptions,
}) {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    // @note: only recreate the chart when data changes so it doesn't re-run the animation
    const data = getData(items, displayKey, labelKey, tooltipKeys);
    const maxValue = getMaxValue(items, displayKey);
    const chartData = getChartData(data);
    const showTooltips = tooltipKeys.length > 0;
    const chartOptions = customChartOptions || getChartOptions(maxValue, showTooltips);

    setChart(
      <Chart
        data={chartData}
        options={chartOptions}
        type="bar"
      />,
    );
  }, [
    displayKey,
    items,
    labelKey,
    tooltipKeys,
  ]);

  return chart;
}

ItemsBarChart.propTypes = propTypes;

ItemsBarChart.defaultProps = defaultProps;

export default ItemsBarChart;
