import React from 'react';
import PropTypes from 'prop-types';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';

const propTypes = {
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  // Map of item keys to their corresponding form input name
  inputKeys: PropTypes.arrayOf(
    PropTypes.shape({
      input: PropTypes.string,
      key: PropTypes.string,
    }),
  ).isRequired,
  inputName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf( // eslint-disable-line react/forbid-prop-types
    PropTypes.object,
  ).isRequired,
};

function Form({
  formData: { id, url },
  items,
  inputName,
  inputKeys,
}) {
  return (
    <form action={url} id={id} method="post">
      <AuthenticityTokenInput />
      {
        items.map((item) => (
          <React.Fragment key={item.value}>
            {
              inputKeys.map(({ key, input }) => (
                <HiddenInput
                  key={key}
                  name={`${inputName}[][${input || key}]`}
                  value={typeof item[key] === 'object' ? item[key].value : item[key]}
                />
              ))
            }
          </React.Fragment>
        ))
      }
    </form>
  );
}

Form.propTypes = propTypes;

export default Form;
