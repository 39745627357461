# TODO: See if still used, if not remove
import React from "react"
import { Checkbox } from  "@duik/it"
import PropTypes from "prop-types"
import classNames from "classnames"

# This component is responsible for setting up a collection of checkbox inputs for a form
# Notes on props:
#   visualStyle:
#     `pill` results will be displayed `inline-block`
#     `default and channelIcon` results will be displayed `block`
#     `browserDefault` results will be displayed `block`, shows default html checkboxes
class CheckboxGroup extends React.Component
  @propTypes =
    formId: PropTypes.string
    inputName: PropTypes.string
    onSelect: PropTypes.func
    options: PropTypes.arrayOf(
      PropTypes.shape(
        iconProps: PropTypes.object
        label: PropTypes.string
        value: PropTypes.string
      )
    )
    selectedValues: PropTypes.arrayOf(
      PropTypes.string
    )

  isChecked: (value) ->
    _.include(@props.selectedValues,  value)

  renderCheckbox: (option) =>
    checkboxProps = {
      form: @props.formId
      id: option.label + '_' + option.value
      defaultChecked: @isChecked(option.value)
      key: option.value
      label: option.label
      name: @props.inputName
      onChange: @props.onSelect
      value: option.value
    }
    `<Checkbox {...checkboxProps} />`

  render: ->
    classes = classNames(
      'u-flexColumn'
      @props.className
    )

    `<div className={classes}>
      {this.props.options.map(this.renderCheckbox)}
    </div>`

export default CheckboxGroup
