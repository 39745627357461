import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Search,
} from '@makeably/creativex-design-system';
import ChannelLogo from 'components/atoms/ChannelLogo';
import EditCampaignModal, {
  campaignProps,
  optionProps,
} from 'components/connections/EditCampaignModal';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { findObjectByValue } from 'utilities/array';
import { saveFile } from 'utilities/file';
import {
  getItemSortBy,
  getItemsCsv,
} from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import { patch } from 'utilities/requests';
import { taxonomyManuallyMappedCampaignPath } from 'utilities/routes';
import {
  decamelize,
  titleize,
} from 'utilities/string';
import styles from './ManuallyMappedCampaigns.module.css';

const propTypes = {
  brandOptions: PropTypes.arrayOf(optionProps).isRequired,
  marketOptions: PropTypes.arrayOf(optionProps).isRequired,
  campaigns: PropTypes.arrayOf(campaignProps),
};

const defaultProps = {
  campaigns: undefined,
};

const headers = [
  {
    key: 'name',
    label: 'Campaign Name',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'accountId',
    label: 'Ad Account ID',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
];

const csvHeaders = [
  {
    key: 'name',
    label: 'Campaign Name',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'accountId',
    label: 'Ad Account ID',
  },
  {
    key: 'channelLabel',
    label: 'Channel',
  },
];

function getLabel(key, value, brandOptions) {
  if (key === 'brandId') {
    return findObjectByValue(brandOptions, value).label;
  }

  return titleize(decamelize(value));
}

function getOptions(key, campaigns, brandOptions = []) {
  const values = campaigns.reduce((vals, campaign) => vals.concat(campaign[key]), []);
  const sorted = [...new Set(values)].sort();
  return sorted.map((value) => ({
    label: getLabel(key, value, brandOptions),
    value,
  }));
}

function getName(campaign, setAndOpenCampaignModal) {
  return {
    element: (
      <Button
        label={campaign.name}
        variant="tertiary"
        onClick={() => setAndOpenCampaignModal(campaign)}
      />
    ),
    value: campaign.name,
  };
}

function getChannel(channel) {
  return {
    element: (
      <ChannelLogo channel={channel} size="size-24" />
    ),
    value: '',
  };
}

async function submitCampaign(brandId, market, { id, channel }) {
  const url = taxonomyManuallyMappedCampaignPath(id);

  const formData = new FormData();
  formData.append('channel', channel);
  formData.append('brand_id', brandId);
  formData.append('market', market);

  const response = await patch(url, formData);
  return response.data;
}

function getItems(campaigns, brandOptions, setAndOpenCampaignModal) {
  return campaigns.map((campaign) => setItemElement({
    id: { value: campaign.id },
    name: getName(campaign, setAndOpenCampaignModal),
    brand: { value: findObjectByValue(brandOptions, campaign.brandId).label },
    market: { value: campaign.market },
    accountId: { value: campaign.accountId },
    channel: getChannel(campaign.channel),
    channelLabel: { value: campaign.channelLabel },
  }));
}

function ManuallyMappedCampaigns({
  brandOptions,
  campaigns,
  marketOptions,
}) {
  const [brandFilter, setBrandFilter] = useState();
  const [marketFilter, setMarketFilter] = useState();
  const [channelFilter, setChannelFilter] = useState();
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState();
  const [sortedItems, setSortedItems] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(undefined);
  const [campaignModalShown, setCampaignModalShown] = useState(false);

  const brandFilterOptions = getOptions('brandId', campaigns, brandOptions);
  const marketFilterOptions = getOptions('market', campaigns);
  const channelOptions = getOptions('channelLabel', campaigns);
  const hasFiltersOrSearch = brandFilter || marketFilter || channelFilter || search;

  const clearFilterAndSearch = () => {
    setBrandFilter(null);
    setMarketFilter(null);
    setChannelFilter(null);
    setSearch('');
  };

  const setAndOpenCampaignModal = (campaign) => {
    setSelectedCampaign(campaign);
    setCampaignModalShown(true);
  };

  const onUpdateCampaign = async (brandId, market) => {
    const data = await submitCampaign(brandId, market, selectedCampaign);

    if (data.success) {
      addToast('The campaign has been successfully remapped!');
    } else {
      addToast('Something went wrong! Please try again', { type: 'error' });
    }

    window.location.reload();
  };

  const onExportCsv = () => {
    const fileName = 'manually_mapped_campaigns.csv';
    const csv = getItemsCsv(sortedItems, csvHeaders);
    saveFile(fileName, csv);
  };

  useEffect(() => {
    const filtered = campaigns.filter(({
      brandId,
      market,
      channelLabel,
      accountId,
      name,
    }) => {
      if (brandFilter && brandId !== brandFilter.value) return false;
      if (marketFilter && market !== marketFilter.value) return false;
      if (channelFilter && channelLabel !== channelFilter.value) return false;
      if (search) {
        const term = search.toLowerCase();

        return accountId.toLowerCase().includes(term) || name.toLowerCase().includes(term);
      }

      return true;
    });

    setFilteredCampaigns(filtered);
    setItems(getItems(filtered, brandOptions, setAndOpenCampaignModal));
  }, [brandFilter, marketFilter, channelFilter, search]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    } else {
      setSortedItems(items);
    }
  }, [items, sort]);

  return (
    <>
      <Card>
        <div className="u-flexRow u-justifyBetween u-marginBottom-16 u-alignEnd">
          <div className="u-flexRow u-gap-16 u-alignEnd">
            <Dropdown
              disabled={brandFilterOptions.length === 0}
              label="Brand"
              menuProps={{ size: 'medium' }}
              options={brandFilterOptions}
              selected={brandFilter}
              size="small"
              onChange={(value) => setBrandFilter(value)}
            />
            <Dropdown
              disabled={marketFilterOptions.length === 0}
              label="Market"
              menuProps={{ size: 'medium' }}
              options={marketFilterOptions}
              selected={marketFilter}
              size="small"
              onChange={(value) => setMarketFilter(value)}
            />
            <Dropdown
              disabled={channelOptions.length === 0}
              label="Channel"
              menuProps={{ size: 'medium' }}
              options={channelOptions}
              selected={channelFilter}
              size="small"
              onChange={(value) => setChannelFilter(value)}
            />
            <Search
              disabled={campaigns.length === 0}
              size="small"
              value={search}
              onChange={setSearch}
            />
            { hasFiltersOrSearch && (
              <Button
                label="Clear"
                variant="tertiary"
                onClick={() => clearFilterAndSearch()}
              />
            ) }
          </div>
          <Button
            label="Export CSV"
            onClick={() => onExportCsv()}
          />
        </div>
        <Divider />
        <div className={styles.campaignsTable}>
          <ItemsTable
            headers={headers}
            items={sortedItems}
            page={page}
            sort={sort}
            onPageChange={(p) => setPage(p)}
            onSortChange={(value) => setSort(value)}
          />
        </div>
        { filteredCampaigns.length === 0 && (
          <div className="u-flexRow u-justifyCenter u-marginTop-16 t-empty">
            There are no editable manually mapped campaigns at this time
          </div>
        ) }
      </Card>
      <EditCampaignModal
        brandOptions={brandOptions}
        campaign={selectedCampaign}
        isOpen={campaignModalShown}
        marketOptions={marketOptions}
        onClose={() => setCampaignModalShown(false)}
        onUpdateCampaign={onUpdateCampaign}
      />
    </>
  );
}

ManuallyMappedCampaigns.propTypes = propTypes;
ManuallyMappedCampaigns.defaultProps = defaultProps;

export default ManuallyMappedCampaigns;
