import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { HiddenInput } from 'components/reusable/HiddenInput';
import Select from 'components/reusable/select.coffee';

const propTypes = {
  adLevel: PropTypes.bool.isRequired,
  brandOptions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      linked: PropTypes.bool,
      name: PropTypes.string,
    }),
  ).isRequired,
  id: PropTypes.number.isRequired,
  marketOptions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
};

function Dv360AdvertiserSelect({
  adLevel,
  id,
  name,
  brandOptions,
  campaigns,
  marketOptions,
}) {
  const [selectedMarket, setSelectedMarket] = useState('');

  const itemNameClass = (campaign) => (classNames(
    'dv360AdsSetup-itemName',
    { 'dv360AdsSetup-itemName--linked': adLevel || (campaign && campaign.linked) },
  ));

  const renderCampaign = (campaign) => (
    <div key={campaign.id}>
      <div className="dv360AdsSetup-campaignMapping">
        <p className={itemNameClass(campaign)}>{ `${campaign.name} (${campaign.id})` }</p>
        { selectedMarket && <HiddenInput name="advertisers[][campaign][][id]" value={campaign.id} /> }
        <Select
          disabled={!selectedMarket || campaign.linked}
          inputName="advertisers[][campaigns][][brand]"
          label="Brand"
          options={brandOptions}
          wrapperClass="u-marginLeft"
          onChange={() => {
          }}
        />
      </div>
    </div>
  );

  return (
    <div>
      <div className="u-flexRow u-flexAlignCenter u-marginBelow">
        <p className={itemNameClass()}>{ `${name}(${id})` }</p>
        <Select
          className="browser-default"
          disabled={adLevel}
          inputName={selectedMarket && 'advertisers[][market]'}
          label="Market"
          options={marketOptions}
          required={false}
          selected={selectedMarket}
          wrapperClass="u-marginLeft"
          onChange={setSelectedMarket}
        />
        { selectedMarket && <HiddenInput name="advertisers[][id]" value={id} /> }
      </div>
      <div className="dv360AdsSetup-campaignsMapping">
        { campaigns.map(renderCampaign) }
      </div>
    </div>
  );
}

Dv360AdvertiserSelect.propTypes = propTypes;

export default Dv360AdvertiserSelect;
