import React from 'react';
import PropTypes from 'prop-types';
import styles from './Metric.module.css';

const propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function Metric({
  label,
  value,
}) {
  return (
    <div className={styles.metric}>
      <div className="t-caption-1">{ label }</div>
      <div>{ value }</div>
    </div>
  );
}

Metric.propTypes = propTypes;

export default Metric;
