import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const propTypes = {
  playbackRate: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  onReady: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

const defaultProps = {
  onError: undefined,
};

function Video(props) {
  const {
    onError,
    onReady,
    playbackRate,
    url,
    width,
  } = props;

  return (
    <ReactPlayer
      playbackRate={playbackRate}
      url={url}
      width={width}
      controls
      onError={onError}
      onReady={(reactPlayer) => onReady(reactPlayer)}
    />
  );
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;
