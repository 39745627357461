import React from "react"
import PlotlyChart from "components/reusable/plotly_chart"
import PropTypes from "prop-types"

# This class filters traces by traceIds and then creates a plotly chart. This relies on there to be trace
# ids on the traces.
# There are two usecases right now:
#     1) Traces have a single id - each id corresponds to a trace id that we can then filter (ex. trace per category)
#     2) Traces have multiple ids - bar charts! We are filtering bars and each bar has it's corresponding 'traceId' in
#                                   ids. This is not technically trace filtering... but whatever.
class TraceFilteredPlotyChart extends React.Component
  @propTypes =
    layout: PropTypes.object                            # See PlotlyChart.propsTypes.layout
    traceIds: PropTypes.arrayOf(PropTypes.string) # Trace ids we should include in the plotly chart
    traces: PropTypes.array.isRequired                  # See PlotlyChart.propTypes.traces + assumptions above

  traces: ->
    return [] if @props.traces.length is 0

    sampleTrace = @props.traces[0]
    # Check if we are dealing with a normal trace (has id) or a bar trace (see above for details)
    if sampleTrace.id
      # Run through traces and only add those whose id is in @props.traceIds
      traces = _.filter(@props.traces, (trace) => @props.traceIds.indexOf(trace.id) >= 0)

      # Sort by name so that traces appear alphabetically
      _.sortBy(traces, 'name')
    else
      trace = sampleTrace
      activeIndices = _.map(@props.traceIds, (index) -> _.indexOf(trace.ids, index))
      activeIndices = _.sortBy(activeIndices, (index) -> index)
      traceCopy = _.extend({}, trace)
      for key in _.keys(traceCopy)
        if _.isArray(traceCopy[key])
          traceCopy[key] = []
          # append values at active indices to array keys in trace
          activeIndices.forEach((index) -> traceCopy[key].push(trace[key][index]))
      [traceCopy]

  render: ->
    `<PlotlyChart
      layout={this.props.layout}
      traces={this.traces()}
    />`

export default TraceFilteredPlotyChart
