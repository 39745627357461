import { pluralize } from 'utilities/string';
// Helper functions for formatting rule labels
const RULE_LABELS = Object.freeze({
  guideline: 'Guideline',
  category: 'Brand Cue',
  regulatory: 'Regulation',
  hypothesis: 'Hypothesis',
});

export function getLabelForRuleType(ruleType, plural = true) {
  const label = RULE_LABELS[ruleType];
  return plural ? pluralize(label) : label;
}
