import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Widget,
} from '@duik/it';
import AuditFilterCollection from 'components/audit/audit_filter_collection.coffee';
import ExpandableBarChart from 'components/charts/ExpandableBarChart';
import LineChartWithTabs from 'components/charts/LineChartWithTabs';
import TopLevelStats from 'components/dashboard_components/TopLevelStats';
import CardHeader from 'components/reusable/CardHeader';
import ExpandableTable from 'components/tables/ExpandableTable';
import ObjectHelper from 'components/utils/ObjectHelper';

// Disabling rule for the filters, too complex a design to break out props
/* eslint-disable react/forbid-prop-types,  */
export const propTypes = {
  companyDataUrls: PropTypes.shape({
    companiesLink: PropTypes.string,
    companyBreakdown: PropTypes.string,
    companyChart: PropTypes.string,
    userBreakdown: PropTypes.string,
  }).isRequired,
  filters: PropTypes.object.isRequired,
  marketDataUrls: PropTypes.shape({
    accountsLink: PropTypes.string,
    marketChart: PropTypes.string,
    marketTable: PropTypes.string,
    marketTableRowUrl: PropTypes.string,
  }).isRequired,
  topLevelStats: PropTypes.shape({
    adAccounts: PropTypes.number,
    brands: PropTypes.number,
    channels: PropTypes.number,
    companies: PropTypes.number,
    markets: PropTypes.number,
    spend: PropTypes.string,
    users: PropTypes.number,
  }).isRequired,
  spendUpdateTime: PropTypes.string,
};
/* eslint-enable react/forbid-prop-types */

const defaultProps = {
  spendUpdateTime: undefined,
};

// Returns the top level stat props in display order
function statProps(topLevelStats, spendUpdateTime) {
  const {
    adAccounts,
    brands,
    channels,
    companies,
    markets,
    spend,
    users,
  } = topLevelStats;

  return [
    {
      format: 'number',
      label: 'Markets',
      value: markets,
    },
    {
      format: 'number',
      label: 'Brands',
      value: brands,
    },
    {
      format: 'number',
      label: 'Partners',
      value: companies,
    },
    {
      format: 'number',
      label: 'Users',
      value: users,
    },
    {
      format: 'string',
      label: 'Spend',
      tooltip: spendUpdateTime,
      value: spend,
    },
    {
      format: 'string',
      label: 'Channels',
      value: channels,
    },
    {
      format: 'string',
      label: 'Ad Accounts',
      value: adAccounts,
    },
  ];
}

function companyLineChartTabs() {
  const tabValues = ['partners', 'users'];
  return ObjectHelper.valuesToObjects(tabValues, true);
}

function marketLineChartTabs() {
  const tabValues = ['markets', 'brands', 'adAccounts', 'spend', 'posts'];
  return ObjectHelper.valuesToObjects(tabValues, true);
}

function marketTableHeaders() {
  return [
    'Market',
    'Brands',
    'Ad Accounts',
    'Total Spend (USD $)',
    'Spend YTD (USD $)',
    'Total Posts',
    'Posts YTD',
  ];
}

function MarketUsage(props) {
  const {
    dataUrls: {
      accountsLink,
      marketChart,
      marketTable,
      marketTableRowUrl,
    },
  } = props;

  return (
    <Widget>
      <CardHeader
        header="Markets"
        linkButton={{
          link: accountsLink,
          text: 'Go to Accounts',
        }}
        subHeader="Onboarding status by market."
      />
      <ExpandableTable
        className="onboardingDashboard-table"
        dataUrl={marketTable}
        emptyMessage="No markets have been linked yet."
        headers={marketTableHeaders()}
        linkParam="market"
        linkUrl={marketTableRowUrl}
        sortDir="desc"
        sortIndex={1}
        clickable
        remote
      />
      <Divider />
      <LineChartWithTabs
        dataUrl={marketChart}
        subHeader="Track month-over-month adoption and growth."
        tabs={marketLineChartTabs()}
        remote
      />
    </Widget>
  );
}
MarketUsage.propTypes = {
  dataUrls: PropTypes.shape({
    accountsLink: PropTypes.string,
    marketChart: PropTypes.string,
    marketTable: PropTypes.string,
    marketTableRowUrl: PropTypes.string,
  }).isRequired,
};

function CompanyUsage(props) {
  const {
    dataUrls: {
      companiesLink,
      companyBreakdown,
      companyChart,
      userBreakdown,
    },
  } = props;

  const barChartHeader = {
    header: 'Partners',
    linkButton: {
      link: companiesLink,
      text: 'Go to Partners',
    },
    subHeader: 'Activated user accounts by partner',
  };

  const companyTypeHeader = {
    header: 'Partner Type',
    subHeader: 'Activation status by different types of partners.',
  };

  return (
    <Widget>
      <ExpandableBarChart
        cardHeader={barChartHeader}
        className="onboardingDashboard-userBreakdown"
        dataUrl={userBreakdown}
        remote
      />
      <Divider />
      <ExpandableBarChart
        cardHeader={companyTypeHeader}
        className="onboardingDashboard-typeBreakdown"
        dataUrl={companyBreakdown}
        remote
      />
      <Divider />
      <LineChartWithTabs
        dataUrl={companyChart}
        subHeader="Track month-over-month growth in onboarded partners and users."
        tabs={companyLineChartTabs()}
        remote
      />
    </Widget>
  );
}
CompanyUsage.propTypes = {
  dataUrls: PropTypes.shape({
    companiesLink: PropTypes.string,
    companyBreakdown: PropTypes.string,
    companyChart: PropTypes.string,
    userBreakdown: PropTypes.string,
  }).isRequired,
};

function Dashboard(props) {
  const {
    companyDataUrls,
    filters: {
      authenticityToken,
      existingFilters,
      filterOptions,
      formProps,
      redirectOptions,
    },
    marketDataUrls,
    spendUpdateTime,
    topLevelStats,
  } = props;

  return (
    <div className="onboardingDashboard">
      <AuditFilterCollection
        authenticityToken={authenticityToken}
        existingFilters={existingFilters}
        filterOptions={filterOptions}
        formProps={formProps}
        redirectOptions={redirectOptions}
      />
      <TopLevelStats stats={statProps(topLevelStats, spendUpdateTime)} />
      <MarketUsage dataUrls={marketDataUrls} />
      <CompanyUsage dataUrls={companyDataUrls} />
    </div>
  );
}

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;

export default Dashboard;
