import React from 'react';
import PropTypes from 'prop-types';
import { guidelineProps } from 'components/scores/modal/ScoreModal';
import ChannelGuidelinesTable from 'components/scores/table/ChannelGuidelinesTable';
import { sortObjectArray } from 'utilities/array';
import styles from './GuidelineWeights.module.css';

const propTypes = {
  guidelines: PropTypes.arrayOf(
    PropTypes.shape(guidelineProps),
  ).isRequired,
  isEditable: PropTypes.bool,
  isWeightUpdate: PropTypes.bool,
  onWeightChange: PropTypes.func,
};

const defaultProps = {
  isEditable: false,
  isWeightUpdate: false,
  onWeightChange: undefined,
};

function GuidelineWeights({
  guidelines,
  isEditable,
  isWeightUpdate,
  onWeightChange,
}) {
  const guidelinesGroupedByChannel = Object.values(guidelines.reduce((grouped, guideline) => {
    const newGrouped = { ...grouped };
    const { channelKey } = guideline;
    if (newGrouped[channelKey]) {
      newGrouped[channelKey].guidelines.push(guideline);
    } else {
      newGrouped[channelKey] = {
        channelKey,
        channelLabel: guideline.channelLabel,
        guidelines: [guideline],
      };
    }

    return newGrouped;
  }, {}));

  const sortedGuidelines = sortObjectArray(guidelinesGroupedByChannel, 'channelLabel');

  return (
    <div className={styles.channelTableContainer}>
      { sortedGuidelines.map(({
        channelKey,
        channelLabel,
        guidelines: channelGuidelines,
      }) => (
        <ChannelGuidelinesTable
          key={channelKey}
          channelKey={channelKey}
          channelLabel={channelLabel}
          guidelines={channelGuidelines}
          isEditable={isEditable}
          isWeightUpdate={isWeightUpdate}
          onWeightChange={onWeightChange}
        />
      )) }
    </div>
  );
}

GuidelineWeights.propTypes = propTypes;
GuidelineWeights.defaultProps = defaultProps;

export default GuidelineWeights;
