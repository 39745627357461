import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleWidget from 'components/molecules/CollapsibleWidget';
import styles from './TextScreenshots.module.css';

const propTypes = {
  content: PropTypes.shape({
    instructions: PropTypes.string,
    screenshots: PropTypes.arrayOf(
      PropTypes.shape({
        screenshot: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }),
  header: PropTypes.string,
};

const defaultProps = {
  content: { screenshots: [] },
  header: '',
};

function TextScreenshots({
  header,
  content: {
    instructions,
    screenshots,
  },
}) {
  if (screenshots.length === 0) return null;

  return (
    <CollapsibleWidget headerText={header}>
      <div className={styles.screenshotInstructions}>
        { instructions }
      </div>
      {
        screenshots.map((screenshot) => (
          <div key={screenshot.screenshot} className="u-marginBelow">
            <img alt="Screenshot" src={screenshot.screenshot} />
            <span>{ ` ${screenshot.text}` }</span>
          </div>
        ))
      }
    </CollapsibleWidget>
  );
}

TextScreenshots.propTypes = propTypes;
TextScreenshots.defaultProps = defaultProps;

export default TextScreenshots;
