import React from 'react';
import PropTypes from 'prop-types';
import styles from './EmptyGuidelines.module.css';

const propTypes = {
  length: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
};

function EmptyGuidelines({
  length,
  search,
}) {
  if (length > 0) return null;

  const message = search ? 'No rules match your search' : 'No rules have checks to review';

  return <div className={`t-empty ${styles.empty}`}>{ message }</div>;
}

EmptyGuidelines.propTypes = propTypes;
export default EmptyGuidelines;
