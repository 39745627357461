import React from 'react';
import PropTypes from 'prop-types';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';

const propTypes = {
  adAccounts: PropTypes.arrayOf( // eslint-disable-line react/forbid-prop-types
    PropTypes.object,
  ).isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

function AdAccountForm(props) {
  const {
    adAccounts,
    formData: {
      id,
      url,
    },
  } = props;

  return (
    <form action={url} id={id} method="post">
      <AuthenticityTokenInput />
      {
        adAccounts.map((adAct) => (
          <React.Fragment key={`${adAct.businessCenterApiId}_${adAct.apiId}`}>
            <HiddenInput
              name="business_centers[][api_id]"
              value={adAct.businessCenterApiId}
            />
            <HiddenInput
              name="business_centers[]ad_accounts[][api_id]"
              value={adAct.apiId}
            />
            <HiddenInput
              name="business_centers[]ad_accounts[][name]"
              value={adAct.name}
            />
            <HiddenInput
              name="business_centers[]ad_accounts[][currency]"
              value={adAct.currency}
            />
            <HiddenInput
              name="business_centers[]ad_accounts[][brand_id]"
              value={adAct.brand.value}
            />
            <HiddenInput
              name="business_centers[]ad_accounts[][market]"
              value={adAct.market.value}
            />
            <HiddenInput
              name="business_centers[]ad_accounts[][organization_affiliate_id]"
              value={adAct.partner.value}
            />
          </React.Fragment>
        ))
      }
    </form>
  );
}

AdAccountForm.propTypes = propTypes;

export default AdAccountForm;
