import React from 'react';
import PropTypes from 'prop-types';
import { AddNewButton } from '@makeably/creativex-design-system';
import SearchableTable from 'components/organisms/SearchableTable';
import { setItemElement } from 'utilities/itemElement';
import {
  editSettingsUserPath,
  editSettingsUserPermissionPath,
  newSettingsUserPath,
  settingsUserPath,
} from 'utilities/routes';
import { titleize } from 'utilities/string';

const userProps = PropTypes.shape({
  canEditPermissions: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  hasModifiedRoles: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  function: PropTypes.string,
  lastLogin: PropTypes.string,
  market: PropTypes.string,
  partner: PropTypes.string,
  title: PropTypes.string,
});

export const propTypes = {
  canInviteUsers: PropTypes.bool.isRequired,
  showActions: PropTypes.bool.isRequired,
  usersByStatus: PropTypes.objectOf(PropTypes.arrayOf(userProps)).isRequired,
};

const filterKeys = ['partner', 'role', 'market'];
const filterUsesItemValue = ['role'];
const tabs = ['active', 'inactive', 'invited', 'all'];

function statusFormatter(value) {
  return titleize(value);
}

function getHeaders(key, showActions) {
  const allHeaders = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Role',
      key: 'role',
      tooltip: 'Users with admin access can submit new pre-flight creatives, see '
        + 'in-flight data and analytics, manage account connections, send '
        + 'invitations across all tiers, and regulate permissions settings. '
        + 'Users with standard access will be able to submit new '
        + 'pre-flight creatives, see in-flight data and analytics, manage account '
        + 'connections, and invite other users with Standard or Limited permission '
        + 'settings. '
        + 'Users with limited access will be able to submit new pre-flight '
        + 'creatives, manage their own account connections, and invite other users '
        + 'with Limited permission settings. They will have no access to spend '
        + 'data.',
    },
    {
      label: 'Title',
      key: 'title',
    },
    {
      label: 'Function',
      key: 'function',
    },
    {
      label: 'Market',
      key: 'market',
    },
    {
      label: 'Partner',
      key: 'partner',
    },
    {
      label: 'Status',
      key: 'status',
      filter: ['all'],
    },
    {
      label: 'Last Login',
      key: 'lastLogin',
      filter: ['active', 'inactive', 'all'],
    },
    {
      label: 'Actions',
      key: 'actions',
      filter: ['active', 'inactive', 'all'],
    },
  ];

  return allHeaders.filter((header) => {
    if (header.key === 'actions' && !showActions) return false;
    return header.filter ? header.filter.includes(key) : true;
  });
}

function confirmationMessage(name, action) {
  return `Are you sure you want to ${action} ${name}?`;
}

function actionDisplay(user, action) {
  const {
    id,
    name,
  } = user;

  const method = action === 'Activate' ? 'patch' : 'delete';
  const options = action === 'Activate' ? { user: { active: true } } : {};
  const warning = action === 'Deactivate';

  return {
    confirmationMessage: confirmationMessage(name, action),
    method,
    url: settingsUserPath(id, options),
    warning,
  };
}

function generateName(user) {
  const name = { value: user.name };

  if (user.editable) {
    name.display = { url: editSettingsUserPath(user.id) };
  }

  return name;
}

function generateRole(user) {
  let text = user.role;
  if (user.hasModifiedRoles) {
    text = `${text}*`;
  }

  const role = {
    text,
    value: user.role,
  };
  if (user.canEditPermissions) {
    role.display = { url: editSettingsUserPermissionPath(user.id) };
  }

  return role;
}

function generateLastLogin({ lastLogin }) {
  if (!lastLogin) return { value: '----' };

  return {
    value: lastLogin,
    format: 'date',
  };
}

function generateUserActions(user) {
  const { status } = user;
  const cell = { value: '' };
  if (status === 'invited') return cell;

  if (user.editable && !user.isCurrentUser) {
    const action = status === 'active' ? 'Deactivate' : 'Activate';
    cell.text = action;
    cell.display = actionDisplay(user, action);
  }

  return cell;
}

function renderAddUserButton(canInviteUsers) {
  if (!canInviteUsers) return null;

  return (
    <a href={newSettingsUserPath()}>
      <AddNewButton label="Invite User" />
    </a>
  );
}

function UserManagement({
  canInviteUsers, showActions, usersByStatus: rawUsersByStatus,
}) {
  const statuses = Object.keys(rawUsersByStatus);
  const usersByStatus = {};

  statuses.forEach((status) => {
    const current = rawUsersByStatus[status];
    usersByStatus[status] = current.map((user) => setItemElement({
      actions: generateUserActions(user),
      // @note: Some older users in the system might not have a partner
      partner: { value: user.partner ?? '----' },
      email: { value: user.email },
      function: { value: user.function ?? '----' },
      id: { value: user.id },
      lastLogin: generateLastLogin(user),
      market: { value: user.market ?? '----' },
      name: generateName(user),
      role: generateRole(user),
      status: {
        value: user.status,
        text: statusFormatter(user.status),
      },
      title: { value: user.title ?? '----' },
    }));
  });

  const tabDetails = tabs.map((key) => ({
    key,
    name: statusFormatter(key),
  }));

  return (
    <SearchableTable
      addButton={renderAddUserButton(canInviteUsers)}
      canDownloadCsv={showActions}
      csvFileName="users"
      filterKeys={filterKeys}
      filterUsesItemValue={filterUsesItemValue}
      getHeaders={(key) => getHeaders(key, showActions)}
      groupedItems={usersByStatus}
      tabDetails={tabDetails}
    />
  );
}

UserManagement.propTypes = propTypes;

export default UserManagement;
