import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Widget } from '@duik/it';
import TableHeader from 'components/molecules/TableHeader';
import TableRowItem from 'components/molecules/TableRowItem';
import ArrayHelper from 'components/utils/ArrayHelper';

export const propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.node),
      data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      disabled: PropTypes.bool,
      disabledSelected: PropTypes.bool,
    }),
  ).isRequired,
  onRowSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  emptyMessage: PropTypes.node,
  selectAll: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
  subtitle: PropTypes.string,
  title: PropTypes.string,
  onAllSelect: PropTypes.func,
};

const defaultProps = {
  className: undefined,
  emptyMessage: undefined,
  onAllSelect: undefined,
  selectAll: false,
  selected: [],
  subtitle: undefined,
  title: undefined,
};

function renderRowContent(rows, headers, emptyMessage, selected, onRowSelect) {
  if (rows.length === 0) {
    return (<tr><td colSpan={headers.length + 1}>{ emptyMessage }</td></tr>);
  }

  return rows.map(({
    columns,
    data,
    disabled,
    disabledSelected,
    key,
  }) => (
    <TableRowItem
      key={key || data.value}
      columns={columns}
      disabled={disabled || disabledSelected}
      selected={disabledSelected || ArrayHelper.exactObjectInArray(selected, data)}
      selectable
      onClick={() => onRowSelect(data)}
    />
  ));
}

function SelectableTable(props) {
  const {
    className,
    emptyMessage,
    headers,
    onAllSelect,
    onRowSelect,
    rows,
    selectAll,
    selected,
    subtitle,
    title,
  } = props;

  // 'every' returns is true on empty arrays
  const allRowsDisabled = rows.every(({
    disabled,
    disabledSelected,
  }) => disabled || disabledSelected);

  const selectableProps = {
    disabled: allRowsDisabled,
    hasSelect: true,
    selectable: selectAll,
    onClick: () => onAllSelect(),
  };

  if (allRowsDisabled) {
    // checking if all rows preselected and at least one exists
    const allRowsDisabledSelected = rows.every(({ disabledSelected }) => disabledSelected);
    selectableProps.selected = allRowsDisabledSelected && rows.length > 0;
  } else {
    // nonzero number of selectable rows, checking if all are selected
    const selectableRowCount = rows.filter((row) => !row.disabled && !row.disabledSelected).length;
    selectableProps.selected = selectableRowCount === selected.length;
  }

  return (
    <div className={classNames(className, 'selectableTable')}>
      { title && <h5 className="tableTitle">{ title }</h5> }
      { subtitle && <div className="tableSubtitle">{ subtitle }</div> }
      <Widget>
        <table className="card-table">
          <TableHeader
            columns={headers}
            {...selectableProps} // eslint-disable-line react/jsx-props-no-spreading
          />
          <tbody>
            { renderRowContent(rows, headers, emptyMessage, selected, onRowSelect) }
          </tbody>
        </table>
      </Widget>
    </div>
  );
}

SelectableTable.propTypes = propTypes;
SelectableTable.defaultProps = defaultProps;

export default SelectableTable;
