import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import HorizontalGauge from "components/graphs/horizontal_gauge"

# This is responsible for displaying a visual impact table by channel.
class VisualImpactTable extends React.Component
  @propTypes =
    channelSummaries: PropTypes.arrayOf(
      PropTypes.shape(
        channel: PropTypes.string,
        engagementRatio: PropTypes.number,
        postCountRatio: PropTypes.number,
        totalCount: PropTypes.number,
        totalEngagement: PropTypes.number,
        url: PropTypes.string,
        visualImpactFactor: PropTypes.number
      )
    ),
    includeChannel: PropTypes.bool # Whether to include the channel column
    mediaShareTooltipText: PropTypes.string
    performanceShareTooltipText: PropTypes.string
    visualImpactTooltipText: PropTypes.string

  @defaultProps =
    includeChannel: true

  renderVisualImpactFactor: (visualImpactFactor) ->
    # We round here so that we can use the rounded value to color format
    visualImpactFactor = if visualImpactFactor then visualImpactFactor.toFixed(2)
    classes = classNames(
      'visualImpactTable-visualImpactFactor',
      'positive': visualImpactFactor && visualImpactFactor > 1,
      'negative': visualImpactFactor && visualImpactFactor < 1
    )

    value = if visualImpactFactor then FormatUtil.metricFormat('visual_impact_factor', visualImpactFactor) else 'N/A'

    `<span className={classes}>
      {value}
    </span>`

  renderChannelColumn: (channelSummary) =>
    if @props.includeChannel
      `<td>
        <a href={channelSummary.url}>
          <Icon channel={channelSummary.channel} isColored size={3}/>
        </a>
      </td>`

  renderChannelSummary: (channelSummary) =>
    # visualImpactFactor is not inlined to show linter that we need the fat error
    visualImpactFactor = this.renderVisualImpactFactor(channelSummary.visualImpactFactor)
    postCountGauge = `<HorizontalGauge rate={channelSummary.postCountRatio}/>` if channelSummary.postCountRatio > 0
    engagementGauge = `<HorizontalGauge rate={channelSummary.engagementRatio}/>` if channelSummary.engagementRatio > 0

    `<tr key={channelSummary.channel}>
      {this.renderChannelColumn(channelSummary)}
      <td>
        <span className="visualImpactTable-metric">
          <span className='visualImpactTable-metricValue'>
            {FormatUtil.metricFormat('rate', channelSummary.postCountRatio)}
          </span>
          <span>
            ({FormatUtil.metricFormat('count', channelSummary.postCount)})
          </span>
          <span className='visualImpactTable-metricDescription'>
            of All Posts
          </span>
        </span>
        {postCountGauge}
      </td>
      <td>
        <span className="visualImpactTable-metric">
          <span className='visualImpactTable-metricValue'>
            {FormatUtil.metricFormat('rate', channelSummary.engagementRatio)}
          </span>
          <span>
            ({FormatUtil.metricFormat('count', channelSummary.totalEngagement)})
          </span>
          <span className='visualImpactTable-metricDescription'>
            of All Total Engagement
          </span>
        </span>
        {engagementGauge}
      </td>
      <td>
        {visualImpactFactor}
      </td>
    </tr>`

  render: ->
    channelHeader = if @props.includeChannel then `<th>Channel</th>`
    `<table className='visualImpactTable'>
      <thead>
        <tr>
          {channelHeader}
          <th>
            Media Share
          </th>
          <th>
            Performance Share
          </th>
          <th>
            Visual Impact Factor
          </th>
        </tr>
      </thead>
      <tbody>
        {this.props.channelSummaries.map(this.renderChannelSummary)}
      </tbody>
    </table>`

export default VisualImpactTable
