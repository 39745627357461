// TODO: Remove this temporary wrapper component for haml rendering once all pages are in React
import React from 'react';
import PropTypes from 'prop-types';
import { Header } from '@makeably/creativex-design-system';

const propTypes = {
  label: PropTypes.string.isRequired,
  section: PropTypes.string,
  url: PropTypes.string,
};
const defaultProps = {
  section: undefined,
  url: undefined,
};

function DsHeader({
  label, section, url,
}) {
  return (
    <Header
      label={label}
      section={section}
      url={url}
    />
  );
}

DsHeader.propTypes = propTypes;
DsHeader.defaultProps = defaultProps;

export default DsHeader;
