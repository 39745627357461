import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CollapsibleWidget from 'components/molecules/CollapsibleWidget';
import styles from './CreativeDetails.module.css';

export const propTypes = {
  creativeDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
};

function CreativeDetails({ creativeDetails }) {
  return (
    <CollapsibleWidget headerText="Creative Details">
      {
        creativeDetails.map(({ label, value }) => (
          <div key={`${label}-${value}`} className={styles.creativeDetail}>
            <div className={classNames('t-label-1', styles.label)}>{ label }</div>
            <div>{ value }</div>
          </div>
        ))
      }
    </CollapsibleWidget>
  );
}

CreativeDetails.propTypes = propTypes;

export default CreativeDetails;
