// This React component is used by Annotation Canvas
// It represents a single bounding box that surrounds a given object in an image
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'components/admin/annotation/BoundingBox.module.css';

export const positionProps = {
  height: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export const propTypes = {
  color: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  fillColor: PropTypes.string.isRequired,
  isDrawing: PropTypes.bool.isRequired,
  position: PropTypes.shape(positionProps).isRequired,
  id: PropTypes.number,
};

const defaultProps = {
  id: undefined,
};

function BoundingBox({
  completed,
  id,
  isDrawing,
  color,
  fillColor,
  position,
}) {
  const [timer, setTimer] = useState(null);
  const [mouseOver, setMouseOver] = useState(false);

  function mouseOverHandler() {
    if (mouseOver && isDrawing) {
      setTimer(
        setTimeout(() => {
          setMouseOver(true);
        }, 100),
      );
    }
  }

  function mouseLeaveHandler() {
    if (!timer) clearTimeout(timer);
    setMouseOver(false);
  }

  const mainClasses = classNames(styles.box, styles[`box--${color}`]);
  const bgClasses = classNames(styles.background, styles[`background--${fillColor}`]);

  return (
    <div
      className={mainClasses}
      style={position}
      onBlur={() => mouseOverHandler()}
      onFocus={() => mouseLeaveHandler()}
      onMouseLeave={() => mouseLeaveHandler()}
      onMouseOver={() => mouseOverHandler()}
    >
      <div className={bgClasses} />
      { completed && <div className={styles.label}>{ id }</div> }
    </div>
  );
}

BoundingBox.propTypes = propTypes;
BoundingBox.defaultProps = defaultProps;

export default BoundingBox;
