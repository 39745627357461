import ObjectHelper from 'components/utils/ObjectHelper';
import StringHelper from 'components/utils/StringHelper';

class UserFormValidator {
  static basicInfoIncomplete(fields) {
    const {
      company,
      email,
      firstName,
      jobFunction,
      jobTitle,
      lastName,
    } = fields;

    return (
      [email, firstName, lastName].some(StringHelper.invalidString)
        || [company, jobFunction, jobTitle].some(ObjectHelper.invalidObject)
    );
  }

  static permissionsIncomplete(fields) {
    const {
      companyRestrictionType,
      profile,
      roles,
      selectedBrands,
      selectedChannels,
      selectedCompanies,
      selectedMarkets,
    } = fields;

    if (profile === undefined) return true;

    if (['connecting', 'standard'].includes(profile)) {
      const restrictedBrands = roles.includes('restricted_brand_data');
      const restrictedMarkets = roles.includes('restricted_market_data');
      const restrictedChannels = roles.includes('restricted_audit_channel_data');
      const restrictedCompanies = roles.includes('restricted_org_affiliate_data')
        && companyRestrictionType === 'custom';

      if (restrictedBrands && selectedBrands.length === 0) return true;
      if (restrictedMarkets && selectedMarkets.length === 0) return true;
      if (restrictedChannels && selectedChannels.length === 0) return true;
      if (restrictedCompanies && selectedCompanies.length === 0) return true;
    }

    return false;
  }
}

export default UserFormValidator;
