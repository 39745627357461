import React from "react"
import AssetImage from "components/reusable/asset_image"
import PropTypes from "prop-types"
import classNames from "classnames"

# This component is responsible for displaying a post asset card
# The displayType property is used to determine metric color, default value of nothing
# returns metrics in grey
# FUTURE TODO: remove this card in favor of ImageCardWithMetrics
class PostAsset extends React.Component
  @propTypes =
    displayType: PropTypes.oneOf(['best', 'worst'])
    post: PropTypes.shape(
      assetId: PropTypes.number.isRequired,
      # This option is for setting a class on the image. Its a map to the method
      # of the same name on the asset model
      assetSmallerThanContainer: PropTypes.bool.isRequired,
      isVideo: PropTypes.bool.isRequired
      metrics: PropTypes.arrayOf(
        PropTypes.shape(
          value: PropTypes.number
        )
      ),
      url: PropTypes.string.isRequired,
    )

  classes: ->
    classNames(
      'postAsset',
      'search-asset-card', # Mooching off of search-asset-card styling. TODO: Remove this dependency.
      'js-asset-modal'
    )

  renderPostMetrics: ->
    if @props.post.metrics
      metricClasses = classNames(
        'postAsset-metrics',
        { "postAsset-metrics--#{@props.displayType}": @props.displayType }
      )

      `<div className={metricClasses}>
        {this.props.post.metrics.map(this.renderPostMetric)}
      </div>`

  renderPostMetric: (metricObject) ->
    # NB: Need a uniquely generated key for when
    # stats are the same
    key = _.uniqueId("post-asset-metric")
    value = metricObject.value || 0
    `<div key={key} className="postAsset-metric">
      <span className="postAsset-metricValue">{FormatUtil.largeNumberFormat(value)}</span>
    </div>`

  render: ->
    `<div className={this.classes()} data-id={this.props.post.assetId}>
      <AssetImage className='postAsset-image' {...this.props.post}/>
      {this.renderPostMetrics()}
    </div>`

export default PostAsset
