import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

# This component is a wrapper for a form date input
class DateInput extends React.Component
  @propTypes =
    id: PropTypes.string.isRequired
    inputName: PropTypes.string
    onChange: PropTypes.func
    value: PropTypes.string

  classes: ->
    classNames(
      'datepicker',
      'js-date-field'
    )

  handleChange: (event) =>
    @props.onChange(event.target.value)

  render: ->
    `<input
        className={this.classes()}
        id={this.props.id}
        name={this.props.inputName}
        onChange={this.handleChange}
        type="text"
        value={this.props.value}
    />`

export default DateInput
