import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormGroupContainer,
  Widget,
  WidgetContent,
  WidgetContainer,
  Divider,
} from '@duik/it';
import { Toggle } from '@makeably/creativex-design-system';
import CardHeader from 'components/reusable/CardHeader';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import DefaultQueryFields, {
  propTypes as defaultQueryFieldProps,
} from 'components/settings/default_query_fields.coffee';
import PermissionsForm, { inviterProps } from 'components/settings/users/PermissionsForm';
import UserFields from 'components/settings/users/UserFields';
import ArrayHelper from 'components/utils/ArrayHelper';
import UserFormValidator from 'components/utils/UserFormValidator';
import { valueToObject } from 'utilities/object';

const optionsProps = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const dataRestrictionOptions = {
  optionsArray: PropTypes.arrayOf(
    optionsProps,
  ),
  selectedOptions: PropTypes.arrayOf(
    optionsProps,
  ),
};

const propTypes = {
  brands: PropTypes.shape(dataRestrictionOptions).isRequired,
  canManageMfa: PropTypes.bool.isRequired,
  channels: PropTypes.shape(dataRestrictionOptions).isRequired,
  companies: PropTypes.shape({
    activeOption: optionsProps,
    options: PropTypes.arrayOf(optionsProps),
  }).isRequired,
  companyRestrictionType: PropTypes.oneOf(['own', 'custom']).isRequired,
  defaultQueryProps: PropTypes.shape(defaultQueryFieldProps).isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  inviter: inviterProps.isRequired,
  isLifecycleEnabled: PropTypes.bool.isRequired,
  isMfaEnabled: PropTypes.bool.isRequired,
  isRepresentationEnabled: PropTypes.bool.isRequired,
  lastName: PropTypes.string.isRequired,
  marketOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  markets: PropTypes.shape(dataRestrictionOptions).isRequired,
  permissionCompanyOptions: PropTypes.shape(dataRestrictionOptions).isRequired,
  profile: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCompany: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  url: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  jobFunction: PropTypes.string,
  jobTitle: PropTypes.string,
  market: PropTypes.string,
};

const defaultProps = {
  jobFunction: undefined,
  jobTitle: undefined,
  market: undefined,
};

class EditUser extends React.Component {
  constructor(props) {
    super(props);

    const {
      brands,
      channels,
      companies,
      companyRestrictionType,
      email,
      firstName,
      isMfaEnabled,
      jobFunction,
      jobTitle,
      lastName,
      market,
      markets,
      permissionCompanyOptions,
      profile,
      roles,
    } = props;

    let formattedJobFunction;
    if (jobFunction) {
      formattedJobFunction = valueToObject(jobFunction);
    }

    let formattedJobTitle;
    if (jobTitle) {
      formattedJobTitle = valueToObject(jobTitle);
    }

    let formattedMarket;
    if (market) {
      formattedMarket = valueToObject(market);
    }

    this.state = {
      company: companies.activeOption,
      companyRestrictionType,
      email,
      firstName,
      isMfaEnabled,
      jobFunction: formattedJobFunction,
      jobTitle: formattedJobTitle,
      lastName,
      market: formattedMarket,
      profile,
      roles: roles || [],
      selectedBrands: brands.selectedOptions || [],
      selectedChannels: channels.selectedOptions || [],
      selectedCompanies: permissionCompanyOptions.selectedOptions || [],
      selectedMarkets: markets.selectedOptions || [],
    };
  }

  onProfileChange(profile) {
    this.setState({
      companyRestrictionType: 'own',
      profile,
      roles: [],
      selectedBrands: [],
      selectedChannels: [],
      selectedCompanies: [],
      selectedMarkets: [],
    });
  }

  onRoleSelect(role) {
    this.setState((prevState) => {
      let { roles } = prevState;
      roles = ArrayHelper.withOrWithoutValue(roles, role);

      return ({ roles });
    });
  }

  onCompanyRestrictionChange(restrictionType) {
    this.setState({ companyRestrictionType: restrictionType });
  }

  onRestrictionOptionChange(valueObj, name) {
    this.setState((prevState) => {
      let values = prevState[name];
      values = ArrayHelper.withOrWithoutObject(values, valueObj);

      return ({ [name]: values });
    });
  }

  onUserFieldChange(value, name) {
    this.setState({ [name]: value });
  }

  render() {
    const {
      brands,
      canManageMfa,
      channels,
      companies,
      defaultQueryProps,
      inviter,
      isLifecycleEnabled,
      isRepresentationEnabled,
      marketOptions,
      markets: permissionMarkets,
      permissionCompanyOptions,
      selectedCompany,
      userName,
      url,
    } = this.props;

    const {
      company,
      companyRestrictionType,
      email,
      firstName,
      isMfaEnabled,
      jobFunction,
      jobTitle,
      lastName,
      market,
      profile,
      roles,
      selectedBrands,
      selectedChannels,
      selectedCompanies,
      selectedMarkets,
    } = this.state;

    const userFieldsCallback = (value, name) => this.onUserFieldChange(value, name);
    const brandOptions = brands.options;
    const channelOptions = channels.options;
    const companyOptions = permissionCompanyOptions.options;
    const permissionMarketOptions = permissionMarkets.options;

    const onMfaEnabledChange = () => {
      this.setState((prevState) => ({ isMfaEnabled: !prevState.isMfaEnabled }));
    };

    return (
      <WidgetContainer className="userFormContainer">
        <Widget>
          <CardHeader
            header={`Modify details for ${userName}`}
          />
          <WidgetContent>
            <form action={url} method="post">
              <HiddenInput name="_method" value="patch" />
              <AuthenticityTokenInput />
              <UserFields
                company={company}
                companyOptions={companies.options}
                editingSelf={false}
                email={email}
                firstName={firstName}
                jobFunction={jobFunction}
                jobTitle={jobTitle}
                lastName={lastName}
                market={market}
                marketOptions={marketOptions}
                canModifyCompany
                onFieldChange={userFieldsCallback}
              />
              { canManageMfa && (
                <>
                  <Divider margin />
                  <h5>Modify MFA Setting</h5>
                  <div className="u-marginAbove">
                    <Toggle
                      checked={isMfaEnabled}
                      label="Require MFA Login"
                      name="isMfaEnabled"
                      onChange={onMfaEnabledChange}
                    />
                    <HiddenInput
                      name="user[otp_required_for_login]"
                      value={isMfaEnabled}
                    />
                  </div>
                </>
              ) }
              <div>
                <Divider margin />
                <h5>Modify Default View</h5>
                <DefaultQueryFields
                  brandOptions={defaultQueryProps.brandOptions}
                  channelOptions={defaultQueryProps.channelOptions}
                  marketOptions={defaultQueryProps.marketOptions}
                  selectedValues={defaultQueryProps.selectedValues}
                />
              </div>
              <Divider margin />
              <h5>Modify Permissions</h5>
              <PermissionsForm
                brandOptions={brandOptions}
                brands={selectedBrands}
                channelOptions={channelOptions}
                channels={selectedChannels}
                companies={selectedCompanies}
                company={selectedCompany}
                companyOptions={companyOptions}
                companyRestrictionType={companyRestrictionType}
                inviter={inviter}
                isLifecycleEnabled={isLifecycleEnabled}
                isRepresentationEnabled={isRepresentationEnabled}
                marketOptions={permissionMarketOptions}
                markets={selectedMarkets}
                profile={profile}
                roles={roles}
                onCompanyRestrictionChange={(rt) => this.onCompanyRestrictionChange(rt)}
                onProfileChange={(selectedProfile) => this.onProfileChange(selectedProfile)}
                onRestrictionOptionChange={(obj, name) => this.onRestrictionOptionChange(obj, name)}
                onRoleSelect={(selectedRole) => this.onRoleSelect(selectedRole)}
              />
              <FormGroupContainer>
                <FormGroup className="u-textAlignCenter">
                  <input
                    className="formSubmit btn btn-primary"
                    disabled={UserFormValidator.permissionsIncomplete(this.state)}
                    name="commit"
                    type="submit"
                    value="Update User"
                  />
                </FormGroup>
              </FormGroupContainer>
            </form>
          </WidgetContent>
        </Widget>
      </WidgetContainer>
    );
  }
}

EditUser.propTypes = propTypes;
EditUser.defaultProps = defaultProps;

export default EditUser;
