import React from 'react';
import PropTypes from 'prop-types';
import {
  Widget,
} from '@duik/it';
import { chartProps as doughnutProps } from 'components/charts/DoughnutChart';
import TabbedChart from 'components/charts/TabbedChart';
import CardHeader from 'components/reusable/CardHeader';

const propTypes = {
  charts: PropTypes.arrayOf(
    PropTypes.shape({
      chart: PropTypes.shape(doughnutProps),
      dataLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  hasData: PropTypes.bool,
  subHeader: PropTypes.string,
  tooltipText: PropTypes.string,
};

const defaultProps = {
  hasData: true,
  subHeader: undefined,
  tooltipText: undefined,
};

function CreativeExcellence(props) {
  const {
    charts,
    hasData,
    subHeader,
    title,
    tooltipText,
  } = props;

  return (
    <Widget className="auditReport-chart">
      <CardHeader
        header={title}
        subHeader={subHeader}
        tooltip={tooltipText}
      />
      <TabbedChart
        charts={charts}
        hasData={hasData}
        type="doughnut"
        bodyContained
        fixedSize
        overview
        staticLayout
      />
    </Widget>
  );
}

CreativeExcellence.propTypes = propTypes;
CreativeExcellence.defaultProps = defaultProps;

export default CreativeExcellence;
