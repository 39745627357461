import React from "react"
import PropTypes from "prop-types"

# This is meant to display labels. These labels might be links if path is provided!
class Label extends React.Component
  @propTypes =
    path: PropTypes.string              # Optional path if we want the label to be a link
    value: PropTypes.oneOfType([        # Text value for the label
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.node,
    ]).isRequired

  render: ->
    if @props.path
      `<a href={this.props.path}>{this.props.value}</a>`
    else
      `<span>{this.props.value}</span>`

export default Label
