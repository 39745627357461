import React from 'react';
import { Icon } from '@makeably/creativex-design-system';
import styles from './PreflightEvaluationBanner.module.css';

const HELP_URL = 'https://www.notion.so/creativex/Marking-Unreviewable-Brand-Mismatches-591a91df040847d68020f2afef93c3bf';

function PreflightEvaluationBanner() {
  return (
    <div className={styles.container}>
      <Icon color="darkPurple" name="infoCircle" noWrapper />
      <div>
        Branded Check in Pre-Flight. If a brand mis-match is present, the check should FAIL.
        See
        { ' ' }
        <a href={HELP_URL}>
          here
        </a>
        { ' ' }
        for further details.
      </div>
    </div>
  );
}

export default PreflightEvaluationBanner;
