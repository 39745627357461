// Will likely be unused when ad account linking flows are redesigned
import React from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'antd';

const treeDataProps = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  key: PropTypes.string,
  title: PropTypes.string,
};

const propTypes = {
  defaultCheckedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultExpandAll: PropTypes.bool.isRequired,
  treeData: PropTypes.arrayOf(PropTypes.shape(treeDataProps)).isRequired,
  onCheck: PropTypes.func.isRequired,
  checkable: PropTypes.bool,
};

const defaultProps = {
  checkable: true,
};

function AntdTree({
  checkable,
  defaultCheckedKeys,
  defaultExpandAll,
  onCheck,
  treeData,
}) {
  const { TreeNode } = Tree;

  const renderChildNode = (node) => (
    <TreeNode {...node} />
  );

  const renderParentNode = ({
    key,
    title,
    children,
  }) => (
    <TreeNode key={key} title={title}>
      { children.map(renderChildNode) }
    </TreeNode>
  );

  return (
    <Tree
      checkable={checkable}
      defaultCheckedKeys={defaultCheckedKeys}
      defaultExpandAll={defaultExpandAll}
      treeData={treeData}
      onCheck={onCheck}
    >
      { treeData.map((node) => renderParentNode(node)) }
    </Tree>
  );
}

AntdTree.propTypes = propTypes;
AntdTree.defaultProps = defaultProps;

export default AntdTree;
