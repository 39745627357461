import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@makeably/creativex-design-system';
import ReviewGuideAsset, { assetProps } from 'components/internal/review_guides/ReviewGuideAsset';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { loadFiles } from 'utilities/file';
import { post } from 'utilities/requests';
import { uploadAssetInternalReviewGuidesPath } from 'utilities/routes';
import styles from './ReviewGuideAssets.module.css';

const propTypes = {
  assets: PropTypes.arrayOf(assetProps).isRequired,
  onChange: PropTypes.func.isRequired,
};

function determineCreativeType(mimeType) {
  if (mimeType === 'video/mp4') {
    return 'video';
  } else if (mimeType.startsWith('image/')) {
    return 'image';
  }

  return null;
}

function ReviewGuideAssets({ assets, onChange }) {
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async (file, creativeType) => {
    const formData = new FormData();
    formData.append('creative_type', creativeType);
    formData.append('file', file);

    const response = await post(uploadAssetInternalReviewGuidesPath(), formData);
    const { data, isError } = response;

    if (isError) throw new Error();

    return data;
  };

  const uploadFiles = async (infos) => {
    setIsUploading(true);

    const results = await Promise.allSettled(infos.map(async ({ file, creativeType }) => (
      uploadFile(file, creativeType)
    )));

    const added = results.reduce((arr, { value }) => (value ? [...arr, value] : arr), []);
    const errorCount = results.reduce((cnt, { status }) => (status === 'rejected' ? cnt + 1 : cnt), 0);

    if (errorCount > 0) {
      addErrorToast(`Could not upload ${errorCount} assets. Please try again later.`);
    }

    if (added.length > 0) {
      addToast(`Added ${added.length} asset(s)`);
      onChange([...assets, ...added]);
    }

    setIsUploading(false);
  };

  const handleFileUpload = async () => {
    const files = await loadFiles('video/mp4, image/*');
    const infos = files.map((file) => ({
      file,
      creativeType: determineCreativeType(file.type),
    }));

    await uploadFiles(infos);
  };

  const handleFileDrop = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const infos = [...e.dataTransfer.items].reduce((arr, { type }, index) => {
      const creativeType = determineCreativeType(type);
      const file = e.dataTransfer.files[index];

      if (creativeType) {
        return [
          ...arr,
          {
            creativeType,
            file,
          },
        ];
      }

      addErrorToast(`Could not upload unsupported file '${file.name}'`, { size: 'large' });
      return arr;
    }, []);

    await uploadFiles(infos);
  };

  const handleAssetDelete = (url) => {
    onChange(assets.filter((asset) => asset.url !== url));
  };

  const renderDrop = () => (
    <div className={styles.drop}>
      <button
        className={`t-body-1 ${styles.dropButton}`}
        disabled={isUploading}
        type="button"
        onClick={handleFileUpload}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleFileDrop}
      >
        { 'Click or\ndrop files to\nadd assets' }
      </button>
      { isUploading && (
        <div className={styles.active}>
          <Spinner />
        </div>
      ) }
    </div>
  );

  return (
    <div className={styles.assets}>
      { assets.map((asset) => (
        <ReviewGuideAsset
          key={asset.url}
          asset={asset}
          onRemove={() => handleAssetDelete(asset.url)}
        />
      )) }
      { renderDrop() }
    </div>
  );
}

ReviewGuideAssets.propTypes = propTypes;

export default ReviewGuideAssets;
