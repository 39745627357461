// Helper functions for searching the 'text' of items and returning matches
import { getItemLabel } from 'utilities/item';
import { splitSearchToArray } from 'utilities/string';

// Search an item's text data and return matches
// item: item to be search
// searchTerms: array of strings to search for
// searchKeys: array of item keys in which to search for the text
function searchItem(item, searchTerms, searchKeys) {
  return searchTerms.reduce((matches, term) => {
    let start = 0;

    const key = searchKeys.find((searchKey) => {
      const dimension = item[searchKey];
      const content = dimension?.label ?? dimension?.text ?? dimension?.value ?? '';
      start = content.toString().toLowerCase().indexOf(term);
      return start >= 0;
    });

    if (key) {
      const match = {
        key,
        start,
        end: start + term.length,
      };
      return [...matches, match];
    }
    return matches;
  }, []);
}

// If an item has successfully matched, move matches start/end into
// the data of each matched key so that the matches can be displayed by key
function buildMatchedItem(item, allMatches) {
  return Object.entries(item).reduce((matchedItem, [key, oldValue]) => {
    const keyMatches = allMatches.filter((match) => match.key === key);
    const matches = keyMatches.map((match) => ({
      start: match.start,
      end: match.end,
    }));
    let value = oldValue;

    if (matches.length > 0) {
      const oldDisplay = oldValue.display;

      value = {
        ...oldValue,
        display: {
          ...oldDisplay,
          matches,
        },
      };
    }

    return {
      ...matchedItem,
      [key]: value,
    };
  }, {});
}

// Search each items's text data and return matches
// items: array of items to be search
// searchString: 'space' delimited string to search for
// searchKeys: array of item keys in which to search for the text
export function searchItems(items, searchString, searchKeys) {
  const terms = splitSearchToArray(searchString);

  if (terms.length === 0) return items;

  return items.reduce((matchedItems, item) => {
    const matches = searchItem(item, terms, searchKeys);

    // If all search terms have matched for that item
    if (matches.length === terms.length) {
      const matchedItem = buildMatchedItem(item, matches);

      return [...matchedItems, matchedItem];
    }
    return matchedItems;
  }, []);
}

export function searchItemsByKey(items, key, searchTerm) {
  if (!searchTerm) return items;

  const term = searchTerm.toLowerCase();

  return items.filter((item) => {
    const label = getItemLabel(item, key).toLowerCase();

    return label.includes(term);
  });
}
