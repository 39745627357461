import React from 'react';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
} from '@duik/it';
import PostInfo, { propTypes as postInfoProps } from 'components/admin/audit_post/evaluation/PostInfo';
import EvaluationForm, { propTypes as evalPageFormProps } from 'components/admin/EvaluationPageForm';

const propTypes = {
  ...evalPageFormProps,
  postInfo: PropTypes.shape(postInfoProps).isRequired,
};

function EvaluationPage(props) {
  const {
    challengeState,
    formToken,
    formUrl,
    isReviewable,
    isVideo,
    skipUrl,
    guidelineChecks,
    organizationId,
    postId,
    postInfo,
    singleCheckReview,
    reviewableUrl,
    unreviewableOptions,
  } = props;

  const {
    altText,
    altTextEnabled,
    audioTranscription,
    campaignDetails,
    creativeDetails,
    creativeUrl,
    header,
    ocrTags,
    platformDetails,
    postCopy,
    postUrl,
    preflight,
    textScreenshotsSection,
    thumbnail,
    videoShots,
  } = postInfo;

  const source = {
    creativeUrl,
    isVideo,
  };

  return (
    <div className="row evalPage-row">
      <div className="evalPage-postInfo">
        <Widget className="u-textAlignCenter evaluationPage-postInfo">
          <WidgetContent>
            <PostInfo
              altText={altText}
              altTextEnabled={altTextEnabled}
              audioTranscription={audioTranscription}
              campaignDetails={campaignDetails}
              creativeDetails={creativeDetails}
              creativeUrl={creativeUrl}
              header={header}
              isVideo={isVideo}
              ocrTags={ocrTags}
              platformDetails={platformDetails}
              postCopy={postCopy}
              postUrl={postUrl}
              preflight={preflight}
              textScreenshotsSection={textScreenshotsSection}
              thumbnail={thumbnail}
              videoShots={videoShots}
            />
          </WidgetContent>
        </Widget>
      </div>
      <div className="evalPage-checkForm">
        <EvaluationForm
          challengeState={challengeState}
          formToken={formToken}
          formUrl={formUrl}
          guidelineChecks={guidelineChecks}
          isReviewable={isReviewable}
          organizationId={organizationId}
          postId={postId}
          reviewableUrl={reviewableUrl}
          singleCheckReview={singleCheckReview}
          skipUrl={skipUrl}
          source={source}
          unreviewableOptions={unreviewableOptions}
        />
      </div>
    </div>
  );
}

EvaluationPage.propTypes = propTypes;

export default EvaluationPage;
