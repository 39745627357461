import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Checkbox,
  Divider,
  Icon,
  MaxWidthText,
  MoreButton,
  Pagination,
  Table,
  Tag,
} from '@makeably/creativex-design-system';
import SearchBar from 'components/molecules/SearchBar';
import SubmitAsCoreAssetModal from 'components/pretests/SubmitAsCoreAssetModal';
import Modal from 'components/reusable/modal.coffee';
import { arrayIf } from 'utilities/array';
import {
  preflightPretestsPath,
  newPreflightPretestPath,
} from 'utilities/routes';
import styles from './SubmittedPreflights.module.css';

const propTypes = {
  displayBrand: PropTypes.bool.isRequired,
  displayQuality: PropTypes.bool.isRequired,
  displayRegulatory: PropTypes.bool.isRequired,
  isCreativeLifecycleEnabled: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      brandResult: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      channel: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      children: PropTypes.array,
      excellentCreatives: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      isCoreAsset: PropTypes.bool,
      key: PropTypes.string,
      name: PropTypes.shape({
        creativeName: PropTypes.string,
        creativeThumbnail: PropTypes.string,
        isVideo: PropTypes.bool,
        modal: PropTypes.shape({
          className: PropTypes.string,
          contentLocation: PropTypes.string,
          id: PropTypes.string,
          loadOnOpen: PropTypes.bool,
        }),
        pretestProcessingStatus: PropTypes.string,
      }),
      pdfDownload: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      }),
      regulatoryResult: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      rulesMet: PropTypes.string,
      score: PropTypes.string,
      submitted: PropTypes.string,
    }),
  ).isRequired,
  creativeLifecycleCampaigns: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  partners: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  searchValue: PropTypes.string,
};

const defaultProps = {
  creativeLifecycleCampaigns: [],
  partners: [],
  searchValue: undefined,
};

function navigateToPage(page, searchValue) {
  const params = { page };
  if (searchValue) params.search_term = searchValue;

  window.location.href = preflightPretestsPath(params);
}

function headers(displayRegulatory, displayBrand, displayQuality) {
  let conditionalHeaders = [];
  if (displayRegulatory) conditionalHeaders.push({ value: 'Compliant' });

  if (displayBrand) conditionalHeaders.push({ value: 'Branded' });

  if (displayQuality) {
    conditionalHeaders.unshift({
      className: styles.highQualityColumn,
      value: 'High Quality Creatives',
    });
    conditionalHeaders = conditionalHeaders.concat([
      { value: 'Score' },
      { value: 'Rules Met' },
    ]);
  }

  return [
    { value: '' },
    { value: 'Name' },
    { value: 'Submitted' },
    { value: 'Channel' },
    ...conditionalHeaders,
    { value: 'PDF' },
    { value: '' },
  ];
}

function creativeCell({
  children,
  isChild,
  name: {
    creativeName,
    creativeThumbnail,
    isVideo,
    modal,
    pretestProcessingStatus,
  },
}) {
  const hasChildren = children?.length > 0;

  const thumbnailClasses = classNames(
    styles.creativeContainer,
    { [styles.creativeContainerHover]: isChild || !hasChildren },
  );
  const thumbnail = (
    <div className={thumbnailClasses} data-target={!hasChildren && modal?.id}>
      { isVideo && (
        <div className={styles.videoContainer}>
          <Icon color="white" name="play" />
        </div>
      ) }
      <img alt="creative thumbnail" src={creativeThumbnail} />
    </div>
  );

  const thumbnailWithChildren = (
    <div className={styles.badgeContainer}>
      { thumbnail }
      <div className={styles.badge}>{ children?.length }</div>
    </div>
  );

  const containerClasses = classNames(
    styles.details,
    { [styles.margin]: isChild },
  );

  return (
    <div className={containerClasses}>
      { modal && <Modal {...modal} /> }
      { hasChildren ? thumbnailWithChildren : thumbnail }
      <div className={styles.status}>
        <MaxWidthText text={creativeName}>
          { creativeName }
        </MaxWidthText>
        <span>{ pretestProcessingStatus }</span>
      </div>
    </div>
  );
}

function resultCell(result) {
  if (typeof result === 'string') return result;

  const color = result ? 'green' : 'red';
  const icon = result ? 'checkCircle' : 'exclamationCircle';

  return <Icon color={color} name={icon} />;
}

function downloadIconCell(pdfData) {
  if (!pdfData) return '';

  const { name, url } = pdfData;

  return (
    <a download={name} href={url}>
      <Icon display="outline" name="download" />
    </a>
  );
}

function SubmittedPreflights({
  displayBrand,
  displayQuality,
  displayRegulatory,
  isCreativeLifecycleEnabled,
  pagination: {
    currentPage,
    perPage,
    total,
  },
  searchValue,
  tableData,
  creativeLifecycleCampaigns,
  partners,
}) {
  const [allData, setAllData] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [coreAssetSelectVisible, setCoreAssetSelectVisible] = useState(false);
  const [selectedAuditIds, setSelectedAuditIds] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const all = tableData.reduce((prev, data, index) => {
      const children = data?.children?.map((child) => ({
        ...child,
        index,
        isChild: true,
        isVisible: expandedIndex === index,
      })) || [];

      return [
        ...prev,
        {
          ...data,
          index,
        },
        ...children,
      ];
    }, []);

    setAllData(all);
  }, [expandedIndex]);

  const formatRow = (item) => {
    const {
      brandResult,
      channel,
      children,
      excellentCreatives,
      index,
      isChild,
      isCoreAsset,
      isVisible,
      name: { creativeName },
      pdfDownload,
      regulatoryResult,
      rulesMet,
      score,
      submitted,
    } = item;

    const id = item.key || index;

    // NOTE: Using css to hide non-expanded child rows so
    // that the materialize creative modal doesn't break.
    const visibilityClass = classNames({
      [styles.hiddenCell]: isChild && !isVisible,
    });

    let conditionalCells = [];
    if (displayRegulatory) conditionalCells.push({ value: resultCell(regulatoryResult) });
    if (displayBrand) conditionalCells.push({ value: resultCell(brandResult) });
    if (displayQuality) {
      conditionalCells.unshift({ value: resultCell(excellentCreatives) });
      conditionalCells = conditionalCells.concat([
        { value: score },
        { value: rulesMet },
      ]);
    }

    let expandButton = '';
    if (children?.length > 0) {
      const onClick = () => {
        if (index === expandedIndex) {
          setExpandedIndex(-1);
        } else {
          setExpandedIndex(index);
        }
      };

      expandButton = (
        <Button
          iconLeft={index === expandedIndex ? 'chevronDown' : 'chevronRight'}
          variant="round"
          onClick={onClick}
        />
      );
    }

    const row = {
      key: `${creativeName}_${index}`,
      cells: [
        ...arrayIf(coreAssetSelectVisible, {
          value: <Checkbox
            checked={selectedAuditIds.includes(id)}
            onChange={() => {
              if (children?.length > 0) {
                const ids = children.map((elem) => elem.key);
                if (selectedAuditIds.includes(id)) {
                  setSelectedAuditIds(
                    selectedAuditIds.filter((elem) => elem !== id && !ids.includes(elem)),
                  );
                } else {
                  setSelectedAuditIds(selectedAuditIds.concat(id).concat(...ids));
                  setExpandedIndex(index);
                }
              } else if (selectedAuditIds.includes(id)) {
                setSelectedAuditIds(selectedAuditIds.filter((elem) => elem !== id));
              } else {
                setSelectedAuditIds(selectedAuditIds.concat(id));
              }
            }}
          />,
        }),
        { value: expandButton },
        { value: creativeCell(item) },
        {
          value: new Date(submitted).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        },
        { value: channel },
        ...conditionalCells,
        { value: downloadIconCell(pdfDownload) },
        ...arrayIf(isCoreAsset && coreAssetSelectVisible, { value: <Tag label="Core Asset" /> }),
      ],
    };

    return {
      ...row,
      cells: row.cells.map((cell) => ({
        ...cell,
        className: visibilityClass,
      })),
    };
  };

  const menuOptions = [
    {
      label: 'Select Core Assets',
      onClick: () => setCoreAssetSelectVisible(true),
    },
  ];

  return (
    <>
      <Card>
        <div className={styles.header}>
          <div className={styles.headerRow}>
            <SearchBar
              formActionLink={preflightPretestsPath({
                page: currentPage,
                search: searchValue,
              })}
              placeholder="Search submission name"
              searchValue={searchValue ?? undefined}
            />
          </div>
          <div className={styles.headerRow}>
            { coreAssetSelectVisible
              && (
              <>
                <Button
                  label="Cancel"
                  variant="secondary"
                  onClick={() => setCoreAssetSelectVisible(false)}
                />
                <Button
                  disabled={selectedAuditIds.length === 0}
                  label="Add to Core Assets"
                  onClick={() => setModalOpen(true)}
                />
              </>
              ) }
            { isCreativeLifecycleEnabled && !coreAssetSelectVisible && allData.length > 0
              && <MoreButton options={menuOptions} /> }
            { !coreAssetSelectVisible
            && <AddNewButton label="Submit New" onClick={() => { window.location.href = newPreflightPretestPath(); }} /> }
          </div>
        </div>
        <Divider margin />
        <Table
          className={styles.table}
          headers={headers(displayRegulatory, displayBrand, displayQuality)}
          rows={allData.map(formatRow)}
        />
        { total > perPage && (
        <div className={styles.pagination}>
          <Pagination
            currentPage={currentPage}
            perPage={perPage}
            total={total}
            onPageChange={(page) => navigateToPage(page)}
          />
        </div>
        ) }
      </Card>
      <SubmitAsCoreAssetModal
        creativeLifecycleCampaigns={creativeLifecycleCampaigns}
        isOpen={modalOpen}
        partners={partners}
        selectedAuditIds={selectedAuditIds}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}

SubmittedPreflights.propTypes = propTypes;
SubmittedPreflights.defaultProps = defaultProps;

export default SubmittedPreflights;
