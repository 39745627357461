import { selectFilterOptions } from 'utilities/filtering';
import { filterValues } from 'utilities/object';
import { getAuthenticityToken } from 'utilities/requests';
import {
  adminReviewGuidelineCheckPath,
  reviewAdminReviewInflightGuidelineCheckUrl,
  updatePinsAdminReviewOrganizationsPath,
} from 'utilities/routes';
import { byObjectLabel } from 'utilities/sort';
import { selectParams } from 'utilities/url';

export const CONTRACT_SCOPE_KEY = 'contract_scope';
export const DATA_DATE_KEY = 'data_date';
export const DAY_AGE_KEY = 'day_age';
export const TIME_BUCKET_KEY = 'time_bucket';

export const PARAM_KEYS = Object.freeze([
  CONTRACT_SCOPE_KEY,
  DATA_DATE_KEY,
  DAY_AGE_KEY,
  TIME_BUCKET_KEY,
]);

const STATUS_NAMES = Object.freeze({
  needs_review: 'Needs Review',
  needs_second_review: 'Needs Second Review',
  needs_validation_review: 'Needs Validation Review',
  reviewed: 'Reviewed',
});

export function getFilterSelections(params, keys, options) {
  const filterValuesObj = selectParams(keys, params);
  const selected = selectFilterOptions(filterValuesObj, options);
  return filterValues(selected, (arr) => arr?.length > 0);
}

function getFilterValue(options) {
  if (!options) {
    return null;
  } else if (options.length === 1) {
    return options[0].value;
  }

  const values = options.map(({ value }) => value);
  return values.join(';');
}

export function getReviewabilityLabel(reason) {
  switch (reason) {
    case 'missing_or_broken_creative':
      return 'Unreviewable (Missing or Broken Creative)';
    case 'other':
      return 'Unreviewable (Other)';
    case 'wrong_brand':
      return 'Unreviewable (Wrong Brand)';
    case 'wrong_market':
      return 'Unreviewable (Wrong Market)';
    default:
      return 'Reviewable';
  }
}

export function isBool(value) {
  return typeof value === 'boolean';
}

export async function postEvaluated(guidelineId, id, passed, reviewStartAt) {
  try {
    const url = adminReviewGuidelineCheckPath(guidelineId, id);
    const formData = new FormData();
    formData.append('authenticity_token', getAuthenticityToken());
    formData.append('id', id);
    formData.append('passed', passed);
    formData.append('review_start_at', reviewStartAt);

    const response = await fetch(url, {
      method: 'PATCH',
      body: formData,
    });
    return response.json();
  } catch {
    return false;
  }
}

export async function postUnreviewable(guidelineId, id, unreviewableReason) {
  try {
    const url = reviewAdminReviewInflightGuidelineCheckUrl(guidelineId, id);
    const formData = new FormData();
    formData.append('authenticity_token', getAuthenticityToken());
    formData.append('id', id);
    if (unreviewableReason) {
      formData.append('unreviewable_reason', unreviewableReason);
    }

    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    return response.json();
  } catch {
    return false;
  }
}

function handleMultiple(arr) {
  if (arr.length > 1) {
    return 'Multiple';
  }
  return arr[0];
}

export function processCheckData(check) {
  return {
    ...check,
    brand: handleMultiple(check.brands),
    channel: handleMultiple(check.channels),
    market: handleMultiple(check.markets),
    reviewability: getReviewabilityLabel(check.unreviewableReason),
    status: STATUS_NAMES[check.status],
    type: check.pretests[0] ? 'Pre-Flight' : 'In-Flight',
  };
}

export function updateFilterParams(params, keys, selections) {
  keys.map((key) => {
    const value = getFilterValue(selections[key]);

    if (value) {
      return params.set(key, value);
    }
    return params.delete(key);
  });
}

export const typeName = {
  guideline: 'Guideline',
  category: 'DBA',
  regulatory: 'Compliance',
};

export const headers = [
  {
    key: 'name',
    label: 'Rule',
  },
  {
    key: 'ruleType',
    label: 'Type',
  },
  {
    key: 'count',
    label: 'Check Count',
  },
];

export const filterDimensions = [
  {
    value: 'brand',
    label: 'Brand',
    key: 'brands',
  },
  {
    value: 'market',
    label: 'Market',
    key: 'markets',
  },
  {
    value: 'channel',
    label: 'Channel',
    key: 'channels',
  },
];
export const filterKeys = filterDimensions.map((dim) => dim.value);

export function getFilterOptions(dimensions, values) {
  return dimensions.reduce((all, dimension) => {
    const entries = values[dimension.key] ?? [];
    const options = entries.map((entry) => {
      const parts = entry.split('::');
      const value = parts[0];
      const label = parts[1] ?? value;

      return {
        label,
        value,
      };
    });

    return {
      ...all,
      [dimension.value]: options.sort(byObjectLabel),
    };
  }, {});
}

export function searchItems(items, search) {
  if (!search) return items;

  const term = search.toLowerCase();

  return items.filter((item) => {
    const name = item.name.value.toLowerCase();

    return name.includes(term);
  });
}

export async function updatePinnedIds(ids, isPreflight) {
  const url = updatePinsAdminReviewOrganizationsPath();
  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('pinned_org_ids', JSON.stringify(ids));
  formData.append('preflight', isPreflight);

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });
  return response.json();
}
