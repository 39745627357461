import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@duik/it';
import { Checkbox } from '@makeably/creativex-design-system';
import Select from 'components/atoms/Select';
import { titleize } from 'utilities/string';
import styles from './ItemAssociation.module.css';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  itemName: PropTypes.string.isRequired,
  selectors: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
        }),
      ),
    }),
  ).isRequired,
  onCheck: PropTypes.func.isRequired,
  onPropertySelect: PropTypes.func.isRequired,
};

function ItemAssociation({
  item,
  itemName,
  onCheck,
  onPropertySelect,
  selectors,
}) {
  return (
    <Widget className={styles.card}>
      <div className="u-flexRow u-flexAlignCenter u-marginBelowMd">
        <div className="u-flexColumn">
          <div className="t-caption-1 u-marginBelowSm">{ `${titleize(itemName)} Name` }</div>
          <div className="u-flexRow u-flexAlignCenter">
            <div>
              <Checkbox
                autoComplete="off"
                checked={item.checked}
                onChange={onCheck}
              />
            </div>
            <div className="t-body-1 u-marginLeftSm">{ item.name }</div>
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div>
          <div className="t-caption-1 u-marginBelowSm">ID</div>
          <div className="t-body-1">{ item.value }</div>
        </div>
        { item.country
          && (
          <div>
            <div className="t-caption-1 u-marginBelowSm">Country</div>
            <div className="t-body-1">{ item.country }</div>
          </div>
          ) }
      </div>
      <div className={styles.selectorRow}>
        {
          selectors.map((selector, idx) => {
            const menuPosition = idx === (selectors.length - 1) ? 'bottom-left' : 'bottom-right';

            return (
              <div key={selector.label}>
                <Select
                  {...selector} // eslint-disable-line react/jsx-props-no-spreading
                  activeOption={item[selector.name]}
                  menuPosition={menuPosition}
                  block
                  searchable
                  onOptionClick={onPropertySelect}
                />
              </div>
            );
          })
        }
      </div>
    </Widget>
  );
}

ItemAssociation.propTypes = propTypes;

export default ItemAssociation;
