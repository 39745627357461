import React from "react"
import CheckboxGroup from "components/reusable/checkbox_group"
import Column from "components/reusable/column"
import DataTable from "components/reusable/data_table"
import Loader from "components/reusable/Loader"
import Row from "components/reusable/row"
import PropTypes from "prop-types"
import { ModalLaunchButton } from "components/containers/miscellaneous_containers"

# responsible for filtering logic for ad campaigns on the deep dive pages.
# renders inputs that are to be included in a parent form. Has some logic to make the
# data passed to the controller in an optimized way.
#
# The form can render a number of different types of inputs:
#
# `c_ids` (campaign_ids inclusive) Array of campaign ids
# `c_ids_x` (campaign_ids exclusive) Array of campaign ids
#   Imagine we have 100 campaigns:
#     - say a user selects 1 id, we send that 1 id  (inclusive)
#     - say a user selects 99 ids, instead of sending 99 ids, we send 1 id with an exclude param
#       - generalized logic: (selected > total/2) ? excludeParam : includeParam
#
# `campaign_objective` String
#
# `campaign_status` String
class CampaignSelectorForm extends React.Component
  @propTypes =
    additionalParams: PropTypes.object           # key value pairs to be submitted (start/end date)
    adCampaignDataLocation: PropTypes.string
    campaignObjectives: PropTypes.array
    campaignStatuses: PropTypes.array
    excludedFacebookAdCampaigns: PropTypes.array
    facebookAdAccountId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
    formId: PropTypes.string
    inputProps: PropTypes.object
    onChange: PropTypes.func                     # called when campaign is selected
    selectedFacebookAdCampaigns: PropTypes.arrayOf(PropTypes.string)

  @defaultProps =
    campaignObjectives: []
    campaignStatuses: []
    excludedFacebookAdCampaigns: []
    selectedFacebookAdCampaigns: []

  constructor: (props) ->
    super(props)

    @state = {
      campaignObjectiveOptions: [],
      campaignStatusOptions: [],
      facebookAdCampaigns: [],
      isLoadingData: false,
      totalCount: 0
    }

  componentDidMount: ->
    @getCampaigns()

  componentDidUpdate: (prevProps) ->
    @getCampaigns() unless @props.facebookAdAccountId == prevProps.facebookAdAccountId

  getCampaigns: ->
    return if @state.isLoadingData
    @setState(isLoadingData: true)
    $.get(
      @props.adCampaignDataLocation,
      { facebook_ad_account_id: @props.facebookAdAccountId }
    ).done((response) =>
      @setState(
        campaignObjectiveOptions: response.data.campaignObjectiveOptions
        campaignStatusOptions: response.data.campaignStatusOptions
        facebookAdCampaigns: response.data.facebookAdCampaigns
        totalCount: response.data.totalCount)
    ).always( =>
      @setState(isLoadingData: false)
    )

  allRowsSelected: ->
    @props.selectedFacebookAdCampaigns.length == 0 ||
    @props.selectedFacebookAdCampaigns.length == @state.totalCount

  onChange: (value, opts) =>
    @props.onChange(value, _.extend(opts,
      campaignObjectives: []
      campaignStatuses: []
    ))

  handleSelectAll: (value, opts) =>
    allValues = _.map(@state.facebookAdCampaigns, (campaign) -> campaign.id.value)
    @props.onChange(allValues, _.extend(
      opts,
      bulkUpdate: true
      campaignObjectives: []
      campaignStatuses: []
      excludedFacebookAdCampaigns: []
    ))

  handleCampaignFiltersChange: (value = '', opts = {}) =>
    return unless @state.facebookAdCampaigns.length > 0

    campaignObjectives = @props.campaignObjectives
    campaignStatuses = @props.campaignStatuses
    excludedFacebookAdCampaigns = @props.excludedFacebookAdCampaigns

    if opts.inputName == 'campaignObjectives'
      if opts.isChecked
        campaignObjectives.push(value)
      else
        campaignObjectives = _.without(campaignObjectives, value)

    else if opts.inputName == 'campaignStatuses'
      if opts.isChecked
        campaignStatuses.push(value)
      else
        campaignStatuses = _.without(campaignStatuses, value)

    filteredCampaigns = _.filter(@state.facebookAdCampaigns, (campaign) ->
      hasSelectedStatus = _.contains(campaignStatuses, campaign.status.value)
      hasSelectedObjective = _.contains(campaignObjectives, campaign.objective.value)
      isExcluded = _.contains(excludedFacebookAdCampaigns, campaign.id.value)

      if excludedFacebookAdCampaigns.length > 0
        !isExcluded

      else if campaignObjectives.length > 0 && campaignStatuses.length > 0
        hasSelectedStatus && hasSelectedObjective

      else if campaignObjectives.length == 0
        hasSelectedStatus

      else if campaignStatuses.length == 0
        hasSelectedObjective
    )

    filteredCampaignIds = _.map(filteredCampaigns, (campaign) -> campaign.id.value)

    @props.onChange(filteredCampaignIds,
      inputName: undefined
      isChecked: true
      bulkUpdate: true
      campaignObjectives: campaignObjectives
      campaignStatuses: campaignStatuses
      excludedFacebookAdCampaigns: []
    )

  renderCampaignFilters: ->
    `<Row>
       <Column className="no-padding" size={6}>
         <label>Campaign Objective</label>
         <CheckboxGroup
           inputName='campaignObjectives'
           onSelect={this.handleCampaignFiltersChange}
           options={this.state.campaignObjectiveOptions}
           selectedValues={this.props.campaignObjectives}
           visualStyle='browserDefault'
         />
       </Column>

       <Column className="no-padding" size={6}>
         <label>Campaign Status</label>
         <CheckboxGroup
           inputName={'campaignStatuses'}
           onSelect={this.handleCampaignFiltersChange}
           options={this.state.campaignStatusOptions}
           selectedValues={this.props.campaignStatuses}
           visualStyle='browserDefault'
         />
       </Column>
     </Row>`

  renderDataTable: ->
    dataTableProps = {
      headers: _.map(
        _.values(@state.facebookAdCampaigns[0]), (campaign) -> { format: 'string', value: campaign.label }
      )
      rows: _.map(
        @state.facebookAdCampaigns, (campaign) -> _.map(
          _.values(campaign), (column) -> { format: 'string', value: column.value }
        )
      )
      sortable: true
      selectable: true
      onRowSelect: @onChange
      onAllRowsSelect: @handleSelectAll
      selectedValues: @props.selectedFacebookAdCampaigns
    }
    `<Row>
       <Column className="no-padding">
         <DataTable {...dataTableProps} />
       </Column>
     </Row>`

  renderModal: ->
    return `<Loader />` if @state.isLoadingData

    formattedTotalCount = FormatUtil.metricFormat('integer', this.state.totalCount)
    formattedSelectedCount = FormatUtil.metricFormat('integer', this.props.selectedFacebookAdCampaigns.length)
    `<div>
      <Row>
        <Column className='no-padding'>
          <h4 className="modalHeader--withBottomBorder">Select Campaigns to Include</h4>
        </Column>

        <Column size={6} className='no-padding'>
          <h5>Total Campaigns: {formattedTotalCount}</h5>
        </Column>

        <Column size={6} className='right-align no-padding'>
          <h5>Selected Campaigns: {formattedSelectedCount}</h5>
        </Column>
      </Row>
      {this.renderCampaignFilters()}
      {this.renderDataTable()}
    </div>`

  renderHiddenInput: (inputName, inputValue) =>
    inputProps =
      form: @props.formId
      key: "#{inputName}_#{inputValue}"
      name: inputName
      type: 'hidden'
      value: inputValue

    `<input {...inputProps} />`

  # attempts to render minimum amount of information to complete request (avoid long urls!)
  renderFormInputs: ->
    # if everything is selected, render no inputs to the form
    if @allRowsSelected()
      return

    # render campaignObjectives or campaignStatuses if they are present
    if @props.campaignObjectives.length > 0 || @props.campaignStatuses.length > 0
      formInputs = _.union(
        _.map(@props.campaignObjectives, (objective) =>
          @renderHiddenInput('campaign_objectives[]', objective)
        ),
        _.map(@props.campaignStatuses, (status) =>
          @renderHiddenInput('campaign_statuses[]', status)
        )
      )

    else if @props.selectedFacebookAdCampaigns.length > (@state.totalCount / 2)
      # if more than half the values are selected, pass the smaller set with an exclude param
      allCampaignIds = _.map(@state.facebookAdCampaigns, (campaign) -> campaign.id.value)
      excludedCampaigns = _.difference(allCampaignIds, @props.selectedFacebookAdCampaigns)
      formInputs = _.map(excludedCampaigns, (campaignId) =>
        @renderHiddenInput('c_ids_x[]', campaignId)
      )

    else
      # if less than half are selected, pass the selected set.
      formInputs = _.map(@props.selectedFacebookAdCampaigns, (campaignId) =>
        @renderHiddenInput('c_ids[]', campaignId)
      )

    `<div>{formInputs}</div>`

  render: ->
    modalId = "CampaignFiltersForm"
    modalProps = {
      contentProps: {
        'data-target': modalId
        label: "Filter by Campaign"
        className: 'full-width'
      }
      launchType: "click"
      modal: {
        children: @renderModal()
        className: 'lg'
        id: modalId
      }
    }

    `<div>
      <ModalLaunchButton {...modalProps} />
      {this.renderFormInputs()}
    </div>`


export default CampaignSelectorForm
