import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Widget, WidgetHeader, WidgetContent } from  "@duik/it"
import { Icon } from "@makeably/creativex-design-system"

# Renders a collapsible card component.
class CollapsibleWidget extends React.Component
  @propTypes =
    children: PropTypes.node.isRequired          # what to display in the card body
    headerText: PropTypes.string.isRequired      # card header
    isCollapsed: PropTypes.bool                  # initial state of card (isCollapsed?)

  @defaultProps: ->
    isCollapsed: false

  constructor: (props) ->
    super(props)

    @state =
      isCollapsed: props.isCollapsed

  toggleBody: =>
    @setState(isCollapsed: !@state.isCollapsed)

  renderHeader: ->
    iconName = if @state.isCollapsed then 'chevronRight' else 'chevronDown'

    `<WidgetHeader className='collapsibleWidgetHeader' onClick={this.toggleBody}>
      <span>{this.props.headerText}</span>
      <Icon name={iconName} />
    </WidgetHeader>`

  renderBody: ->
    return if @state.isCollapsed

    `<WidgetContent className='collapsibleWidgetBody'>
      {this.props.children}
    </WidgetContent>`

  render: ->
    classes = classNames(
      'collapsibleWidget',
      @props.className
    )

    `<Widget className={classes}>
      {this.renderHeader()}
      {this.renderBody()}
    </Widget>`

export default CollapsibleWidget
