// Horizontal Bar chart
//
// @note: For the following props
//  - FixedSize: Use rarely. Will shrink the bars to fit the container divs size.
//      From a consistent design point of view, bars should be the same size everywhere
//  - StaticLayout: Use sparingly. Will render all of the bars vs having a 'Show All'
//      button to expand the table. It has the potential to overwhelm the page
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ExpandButton from 'components/reusable/ExpandButton';
import StringHelper from 'components/utils/StringHelper';
import {
  axisFormat,
  tickFormat,
} from 'utilities/chart';

export const chartProps = {
  barColor: PropTypes.string.isRequired,
  chartType: PropTypes.oneOf([
    'currency',
    'number',
    'percentage',
  ]).isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const layoutProps = {
  fixedSize: PropTypes.bool,
  staticLayout: PropTypes.bool,
};

export const propTypes = {
  chart: PropTypes.shape(chartProps).isRequired,
  className: PropTypes.string,
  dataLabel: PropTypes.string,
  fixedSize: PropTypes.bool,
  staticLayout: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  dataLabel: undefined,
  fixedSize: false,
  staticLayout: false,
};

class BarChart extends React.Component {
  static collapsedLength() { return 10; }

  static slice(arr, expanded) {
    const endIdx = expanded ? arr.length : BarChart.collapsedLength();

    return arr.slice(0, endIdx);
  }

  static updateScales(data, chartType) {
    return BarChart.scaleOptions(Math.max(...data), chartType);
  }

  static getHeight(items) {
    // Minimum size of 108px
    if (items < 3) return 108;

    return (36 * items);
  }

  // NB: The axes are in reverse for 'horizontal' bar charts
  // https://www.chartjs.org/docs/latest/charts/bar.html#config-options
  static scaleOptions(maxValue, chartType) {
    const isPercent = chartType === 'percentage';

    return {
      x: {
        ...axisFormat(maxValue, isPercent),
        border: {
          display: false,
        },
        ticks: {
          ...tickFormat(maxValue, isPercent),
          callback(val, _index) {
            return StringHelper.format(val, chartType);
          },
          display: chartType !== 'percentage',
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          crossAlign: 'far',
          callback(val, _index) {
            return StringHelper.truncateString(this.getLabelForValue(val));
          },
        },
      },
    };
  }

  static tooltipOptions(chartType) {
    return ({
      callbacks: {
        label({ raw }) {
          const opts = {};
          if (chartType === 'percentage') {
            opts.sigFigs = 2;
          }

          return StringHelper.format(raw, chartType, opts);
        },
      },
    });
  }

  static chartDataLabels(chartType) {
    return ({
      align: 'end',
      anchor: 'end',
      offset: 5,
      formatter(value, _context) {
        const opts = {};
        if (chartType === 'percentage') {
          opts.sigFigs = 2;
        }
        return StringHelper.format(value, chartType, opts);
      },
    });
  }

  static chartOptions(chartType) {
    return ({
      layout: {
        padding: {
          right: 60,
        },
      },
      maintainAspectRatio: false,
      plugins: {
        datalabels: BarChart.chartDataLabels(chartType),
        legend: {
          display: false,
        },
      },
    });
  }

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = undefined;

    const { staticLayout } = props;
    // Default for expandable (non-static) charts is Collapsed view
    // Default for static charts is show everything
    const expanded = !!staticLayout;

    this.state = { expanded };
  }

  componentDidMount() {
    const {
      fixedSize,
      chart: {
        barColor,
        chartType,
        data,
        labels,
      },
    } = this.props;

    const barChartRef = this.chartRef.current.getContext('2d');
    const { expanded } = this.state;

    // We want skinny bars in our charts
    const chartData = {
      datasets: [{
        backgroundColor: barColor,
        barPercentage: 0.4,
        categoryPercentage: 0.8,
        data: BarChart.slice(data, expanded),
      }],
      labels: BarChart.slice(labels, expanded),
    };

    const options = BarChart.chartOptions(chartType);
    options.scales = BarChart.updateScales(data, chartType);
    options.plugins.tooltip = BarChart.tooltipOptions(chartType);
    options.indexAxis = 'y';

    this.chart = new Chart(barChartRef, {
      data: chartData,
      options,
      plugins: [ChartDataLabels],
      type: 'bar',
    });

    if (fixedSize) return;

    this.resizeCanvas(chartData.labels.length);
    this.chart.resize();
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataLabel: prevLabel } = prevProps;
    const {
      dataLabel: currentLabel,
      fixedSize,
    } = this.props;

    const { expanded: prevExpanded } = prevState;
    const { expanded: currentExpanded } = this.state;

    if (prevLabel !== currentLabel || prevExpanded !== currentExpanded) {
      const { chart } = this;
      const { config } = chart;

      const {
        chart: {
          barColor,
          chartType,
          data,
          labels,
        },
      } = this.props;

      const { expanded } = this.state;

      config.data.labels = BarChart.slice(labels, expanded);
      config.data.datasets[0].data = BarChart.slice(data, expanded);
      config.data.datasets[0].backgroundColor = barColor;
      config.options.scales = BarChart.updateScales(data, chartType);
      config.options.plugins.tooltip = BarChart.tooltipOptions(chartType);
      config.options.plugins.datalabels = BarChart.chartDataLabels(chartType);
      chart.update();

      if (fixedSize) return;

      const dataCount = config.data.labels.length;
      this.resizeCanvas(dataCount);

      chart.resize();
    }
  }

  resizeCanvas(dataCount) {
    const { chart: { canvas } } = this;
    const canvasContainer = canvas.parentNode;
    const height = BarChart.getHeight(dataCount);

    canvasContainer.style.height = `${height}px`;
  }

  expandTable() {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  }

  render() {
    const {
      chart: { data },
      className,
      staticLayout,
    } = this.props;

    const { expanded } = this.state;

    const classes = classNames(
      className,
      'barChart',
    );

    let content;
    let canvasStyling;
    let expandButton;

    if (!data.length > 0) {
      content = 'No data to display';
      canvasStyling = {
        display: 'none',
      };
    }

    // Only need expand button for > 10 elements
    if (!staticLayout && data.length > BarChart.collapsedLength()) {
      expandButton = (
        <ExpandButton
          callback={() => this.expandTable()}
          expanded={expanded}
        />
      );
    }

    return (
      <>
        <div className={classes}>
          { content }
          <canvas ref={this.chartRef} style={canvasStyling} />
        </div>
        { expandButton }
      </>
    );
  }
}

BarChart.propTypes = propTypes;
BarChart.defaultProps = defaultProps;

export default BarChart;
