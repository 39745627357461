import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

# This component is responsible for displaying an asset image on a card.
# This requires that that container have a 'search-asset-card' class on it. Otherwise things will not style properly.
# TODO: Remove 'search-asset-card' dependency.
class AssetImage extends React.Component
  @propTypes =
    assetSmallerThanContainer: PropTypes.bool
    badge: PropTypes.string # badge in top right if true
    isVideo: PropTypes.bool
    url: PropTypes.string
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
    rating: PropTypes.string # background of the label

  classes: ->
    classNames(
      @props.className,
      'image', # Mooching off of search-asset-card styling. TODO: Remove this dependency.
      {
        'contain': !@props.assetSmallerThanContainer,
        'video': @props.isVideo
      }
    )

  labelClasses: ->
    classNames('image-card-label', @props.rating)

  renderLabel: ->
    unless _.isUndefined(@props.label)
      `<div className={this.labelClasses()}>{this.props.label}%</div>`

  renderBadge: ->
    return unless @props.badge
    `<div className='image-card-badge'>
      {this.props.badge}
    </div>`

  render: ->
    style = backgroundImage: "url('#{@props.url}')" if @props.url
    `<div className={this.classes()} style={style}>
       {this.renderLabel()}
       {this.renderBadge()}
     </div>`

export default AssetImage
