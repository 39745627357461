import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@duik/it';
import { track } from 'utilities/mixpanel';

export const propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  loading: PropTypes.bool,
  primary: PropTypes.bool,
  trackingLabel: PropTypes.string,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  className: undefined,
  disabled: false,
  form: undefined,
  loading: false,
  onSubmit: undefined,
  primary: false,
  trackingLabel: undefined,
};

class FormSubmit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  // @note: Allows us to update the loading state on the button from calling
  // components. React allows this functionality if we set a condition to
  // prevent infinite loops. Disabling linter as this is needed here.
  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps, prevState) {
    const { loading: prevLoading } = prevState;
    const { loading: propsLoading } = this.props;
    const { loading: stateLoading } = this.state;

    if (!stateLoading && prevLoading !== propsLoading) {
      this.setState({ loading: propsLoading });
    }
  }
  /* eslint-enable react/no-did-update-set-state */

  render() {
    const {
      className,
      disabled,
      form,
      label,
      primary,
      trackingLabel,
      onSubmit,
    } = this.props;

    let buttonName = label;
    if (trackingLabel) buttonName = trackingLabel;

    const { loading } = this.state;

    return (
      <Button
        className={className}
        disabled={disabled}
        form={form}
        isLoading={loading}
        primary={primary}
        type="submit"
        onClick={() => {
          track(`Click ${buttonName}`);
          this.setState({ loading: true });
          if (onSubmit) onSubmit();
        }}
      >
        { label }
      </Button>
    );
  }
}

FormSubmit.propTypes = propTypes;
FormSubmit.defaultProps = defaultProps;

export default FormSubmit;
