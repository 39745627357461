import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Spinner,
} from '@makeably/creativex-design-system';
import styles from './ActionButton.module.css';

const propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  active: false,
  disabled: undefined,
  form: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  label: undefined,
  onClick: undefined,
  type: undefined,
  url: undefined,
  variant: undefined,
};

function ActionButton({
  active,
  disabled,
  form,
  iconLeft,
  iconRight,
  label,
  onClick,
  type,
  url,
  variant,
}) {
  return (
    <div className={styles.wrap}>
      <Button
        disabled={active || disabled}
        form={form}
        iconLeft={iconLeft}
        iconRight={iconRight}
        label={label}
        type={type}
        url={url}
        variant={variant}
        onClick={onClick}
      />
      { active && (
        <div className={styles.overlay}>
          <div className={`${styles.spinner} ${styles[variant]}`}>
            <Spinner color="custom" size="custom" />
          </div>
        </div>
      ) }
    </div>
  );
}

ActionButton.propTypes = propTypes;
ActionButton.defaultProps = defaultProps;

export default ActionButton;
