import React from "react"
import PropTypes from "prop-types"
import NavDropdown from "components/navigation/NavDropdown"

# Used for filtering pending assets for admin
class PendingAssetsSelectors extends React.Component
  @propTypes =
    creativeTypeSelectorProps: PropTypes.object
    daysToLookBackSelectorProps: PropTypes.object
    pendingFilterSelectorProps: PropTypes.object

  renderSelector: (props) ->
    options = NavDropdown.sanitize(props.options)
    activeOption = _.findWhere(options, link: props.selected)

    `<div className='u-marginRight'>
      <NavDropdown
        activeOption={activeOption}
        label={props.label}
        options={options}
      />
    </div>`

  render: ->
    `<div className='u-flexRow'>
      {this.renderSelector(this.props.pendingFilterSelectorProps)}
      {this.renderSelector(this.props.creativeTypeSelectorProps)}
      {this.renderSelector(this.props.daysToLookBackSelectorProps)}
    </div>`

export default PendingAssetsSelectors
