// This component is responsible for generating a banner on the landing pages
import React from 'react';
import LandingBanner from 'components/landing/LandingBanner';
import isSupportedBrowser from 'components/utils/BrowserTypes';

function BrowserWarningBanner() {
  if (isSupportedBrowser()) return null;
  return (
    <LandingBanner
      bannerId="browser-warning-banner"
      bannerLink="https://www.google.com/chrome/"
      bannerTitle="Sorry, but we don't support Internet Explorer. To continue using CreativeX, please upgrade to Chrome, Firefox, or Edge."
      iconName="exclamationTriangle"
      isWarning
      permanent
    />
  );
}

export default BrowserWarningBanner;
