import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Widget } from '@duik/it';
import {
  Portal,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Button,
  Checkbox,
  Icon,
  Tooltip,
} from '@makeably/creativex-design-system';
import Select from 'components/atoms/Select';
import Tag from 'components/atoms/Tag';
import {
  pluralize,
  titleize,
} from 'utilities/string';
import styles from './BulkAssociation.module.css';

const propertyShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

const propTypes = {
  counts: PropTypes.shape({
    selectedCount: PropTypes.number,
    totalCount: PropTypes.number,
  }).isRequired,
  itemName: PropTypes.string.isRequired,
  properties: PropTypes.shape({
    brand: propertyShape,
    market: propertyShape,
    partner: propertyShape,
  }).isRequired,
  selectors: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      options: PropTypes.array,
    }),
  ).isRequired,
  onApply: PropTypes.func.isRequired,
  onPropertySelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
};

function onApplyMessage(itemName, selectedCount, {
  brand, market, partner,
}) {
  const assigned = [];

  if (brand.value) assigned.push(`Brand: ${brand.label}`);
  if (market.value) assigned.push(`Market: ${market.label}`);
  if (partner.value) assigned.push(`Partner: ${partner.label}`);

  return (`You have assigned ${assigned.join('; ')} to ${selectedCount} ${titleize(itemName)}(s)`);
}

function BulkAssociation({
  counts: {
    selectedCount,
    totalCount,
  },
  itemName,
  selectors,
  properties,
  onApply,
  onPropertySelect,
  onSelectAll,
}) {
  const [collapsed, setCollapsed] = useState(false);
  const [alert, updateAlert] = useState({
    message: undefined,
    open: false,
    severity: undefined,
  });

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') return;
    updateAlert({
      ...alert,
      open: false,
    });
  };

  const pluralItemName = pluralize(titleize(itemName));
  const tooltipText = 'Use the drop downs below to apply the same brand, '
    + `market and partner to the selected ${pluralItemName}.`;

  const isDisabled = Object
    .values(properties)
    .every(({ value }) => value === undefined);

  const isApplyDisabled = isDisabled || selectedCount === 0;

  const openAlert = (message, severity) => updateAlert({
    message,
    open: true,
    severity,
  });

  const selectAll = (e) => {
    const { checked } = e.target;
    if (checked) openAlert(`${totalCount} ${titleize(itemName)}(s) Selected`, 'info');
    onSelectAll(checked);
  };

  const onApplyClick = () => {
    onApply(properties);
    openAlert(onApplyMessage(itemName, selectedCount, properties), 'success');
  };

  return (
    <Widget className={styles.card}>
      <button
        className={classNames(styles.header, { [styles.headerCollapsed]: collapsed })}
        type="button"
        onClick={() => setCollapsed(!collapsed)}
      >
        <div className="u-flexRow u-flexAlignCenter">
          <h5 className="t-subtitle u-marginRightSm">
            Bulk associate brand, market, and partner
          </h5>
          <Tooltip label={tooltipText} />
        </div>
        <Icon name={collapsed ? 'chevronUp' : 'chevronDown'} />
      </button>
      <div className={classNames(styles.body, { [styles.bodyCollapsed]: collapsed })}>
        <div className="u-flexRow u-justifySpaceBetween u-flexAlignCenter u-marginBelowMd">
          <div>
            <Checkbox
              autoComplete="off"
              checked={selectedCount === totalCount}
              label={`Select All ${pluralItemName}`}
              onChange={selectAll}
            />
          </div>
          <Tag text={`${selectedCount}/${totalCount} Selected`} />
        </div>
        <div className={styles.selectorRow}>
          {
            selectors.map((selector, idx) => (
              <div key={selector.label}>
                <Select
                  {...selector} // eslint-disable-line react/jsx-props-no-spreading
                  activeOption={properties[selector.name]}
                  menuPosition={idx === (selectors.length - 1) ? 'bottom-left' : 'bottom-right'}
                  block
                  searchable
                  onOptionClick={onPropertySelect}
                />
              </div>
            ))
          }
        </div>
        <div className="u-flexRow u-justifyFlexEnd">
          <Button
            disabled={isApplyDisabled}
            label="Apply"
            variant="primary"
            onClick={onApplyClick}
          />
        </div>
      </div>
      <Portal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={6000}
          className={styles.alert}
          open={alert.open}
          onClose={handleAlertClose}
        >
          <Alert
            elevation={6}
            severity={alert.severity}
            variant="filled"
            onClose={handleAlertClose}
          >
            { alert.message }
          </Alert>
        </Snackbar>
      </Portal>
    </Widget>
  );
}

BulkAssociation.propTypes = propTypes;

export default BulkAssociation;
