import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Card } from '@makeably/creativex-design-system';
import Filters from 'components/filters/AdminAuditFilters';
import ItemsTable from 'components/molecules/ItemsTable';
import {
  getItemContent,
  getItemSortBy,
  itemProps,
} from 'utilities/item';
import { setItemsElement } from 'utilities/itemElement';
import {
  changePage,
  getPage,
  getParams,
} from 'utilities/url';
import styles from './TrialQueue.module.css';

const propTypes = {
  checks: PropTypes.arrayOf(itemProps).isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

const allOption = [{
  label: 'All',
  value: 'all',
}];

function generateFilterOptions(checks, key) {
  const sortedValues = checks
    .sort(getItemSortBy(key, true))
    .map((check) => getItemContent(check, key));
  const sortedUnique = [...new Set(sortedValues)];
  return allOption.concat(sortedUnique.map((val) => ({
    label: val,
    value: val,
  })));
}

function generateFilters(checks, currentFilters, filterOptions) {
  return filterOptions.map((filter) => {
    const options = generateFilterOptions(checks, filter.key);

    let activeOption = allOption;
    if (currentFilters[filter.key]) {
      activeOption = options.filter((option) => currentFilters[filter.key].includes(option.key));
    }

    return (
      {
        activeOption,
        label: filter.label,
        field: filter.key,
        options,
        type: 'select',
      }
    );
  });
}

function getFilterValues(formRef) {
  const filterValues = [...new FormData(formRef.current).entries()];
  const groupedFilters = {};
  filterValues.forEach((entry) => {
    const [type, value] = entry;
    // Brackets in filter name indicate a multi-select filter
    const filterName = type.replace('[]', '');
    groupedFilters[filterName] ||= [];
    groupedFilters[filterName].push(value);
  });
  return groupedFilters;
}

function getFilterTest(filterSelections) {
  const filterEntries = Object.entries(filterSelections);

  return (datum) => filterEntries
    .every(([filterKey, filterValues]) => {
      const value = getItemContent(datum, filterKey);
      return filterValues.length > 0 ? filterValues.includes(value) : true;
    });
}

function TrialQueue({
  checks, filterOptions, headers,
}) {
  const params = getParams(window);
  const formRef = useRef(null);
  const [page, setPage] = useState(getPage(params));
  const [filters, setFilters] = useState(generateFilters(checks, {}, filterOptions));
  const [filteredChecks, setFilteredChecks] = useState(checks);
  const [sortedChecks, setSortedChecks] = useState(filteredChecks);
  const [sort, setSort] = useState({
    key: 'id',
    asc: true,
  });

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    setSortedChecks(filteredChecks.slice().sort(byKeyDir));
  }, [filteredChecks, sort]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    changePage(newPage, params, window);
  };

  const onFormSubmit = () => {
    handlePageChange(1);
    const filterSelections = getFilterValues(formRef);
    const filterTest = getFilterTest(filterSelections);
    const filtered = checks.filter((check) => filterTest(check));
    setFilters(generateFilters(filtered, filterSelections, filterOptions));
    setFilteredChecks(filtered);
  };

  return (
    <div className="u-flexRow">
      <div className={styles.filterContainer}>
        <Filters
          ref={formRef}
          filters={filters}
          loading={false}
          onFormSubmit={() => onFormSubmit()}
        />
      </div>
      <div className={styles.itemsContainer}>
        <Card>
          <ItemsTable
            headers={headers}
            items={setItemsElement(sortedChecks)}
            page={page}
            sort={sort}
            onPageChange={handlePageChange}
            onSortChange={setSort}
          />
        </Card>
      </div>
    </div>
  );
}

TrialQueue.propTypes = propTypes;

export default TrialQueue;
