import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Dropdown,
  DropdownItem,
} from '@duik/it';
import { track } from 'utilities/mixpanel';

const optionPropTypes = PropTypes.shape({
  link: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
});

export const propTypes = {
  options: PropTypes.arrayOf(optionPropTypes).isRequired,
  activeOption: optionPropTypes,
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    clear: PropTypes.bool,
  }),
  buttonText: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  menuPosition: PropTypes.string,
  trackingName: PropTypes.string,
};

const defaultProps = {
  activeOption: undefined,
  buttonProps: {},
  buttonText: undefined,
  className: undefined,
  label: undefined,
  menuPosition: 'bottom-right',
  trackingName: undefined,
};

function renderDropdownItem(
  option,
  eventTrackingLabel,
) {
  const {
    link,
    target,
    text,
    onClick,
  } = option;

  const onOptionClick = (_e) => {
    if (eventTrackingLabel) track(`Click ${eventTrackingLabel}`, { selectedValue: text });
    if (onClick) onClick(option);
  };

  return (
    <DropdownItem key={text} Component="a" href={link} target={target} onClick={onOptionClick}>
      { text }
    </DropdownItem>
  );
}

// Renders a dropdown for navigation. The component options are links.
// Should only be used for link navigation
function NavDropdown(props) {
  const {
    activeOption,
    buttonProps,
    buttonText,
    className,
    label,
    menuPosition,
    options,
    trackingName,
  } = props;

  const classes = classNames(
    className,
    { select: label },
  );

  // If buttonText is sent it, that will be used as the display text. An
  // example of this would be for top level navs where the selected option
  // isn't displayed. Otherwise we display the text of the activeOption
  let displayedText;
  if (buttonText) {
    displayedText = buttonText;
  } else if (activeOption) {
    displayedText = activeOption.text;
  }

  const dropdown = (
    <Dropdown
      buttonProps={buttonProps}
      buttonText={displayedText}
      className={classes}
      menuPosition={menuPosition}
    >
      {
        options.map((option) => {
          const eventTrackingLabel = label || trackingName || option.text;
          return renderDropdownItem(option, eventTrackingLabel);
        })
      }
    </Dropdown>
  );

  if (label) {
    return (
      <FormGroup>
        <span className="content-title">
          <div className="u-flexRow">
            <span>{ label }</span>
          </div>
        </span>
        { dropdown }
      </FormGroup>
    );
  }

  return dropdown;
}

NavDropdown.propTypes = propTypes;
NavDropdown.defaultProps = defaultProps;

// TODO: Eventually remove this function once all the callers are cleaned up
// with the correct props
function sanitize(options) {
  const sanitizedOptions = [];
  options.forEach((option) => sanitizedOptions.push({
    text: option.label,
    link: option.value,
  }));
  return sanitizedOptions;
}
NavDropdown.sanitize = sanitize;

export default NavDropdown;
