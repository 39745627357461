import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  FormGroupContainer,
  Radio,
} from '@duik/it';
import {
  Button,
  Toggle,
} from '@makeably/creativex-design-system';
import { titleize } from 'utilities/string';
import styles from './GridSelector.module.css';

export const GRID_FILL_COLOR_KEY = 'evalPageGridFillColor';

const BLACK = 'black';
const BLUE = 'blue';
const GREEN = 'green';
const WHITE = 'white';
const FILL_COLORS = [BLUE, GREEN];
const GRID_COLORS = [BLACK, WHITE];

export const defaultGridOptions = {
  gridFillColor: localStorage.getItem(GRID_FILL_COLOR_KEY) ?? GREEN,
  gridlineColor: WHITE,
  showGridlines: false,
};

export const gridOptionProps = PropTypes.shape({
  gridFillColor: PropTypes.oneOf(FILL_COLORS),
  gridlineColor: PropTypes.oneOf(GRID_COLORS),
  showGridlines: PropTypes.bool,
});

const propTypes = {
  callback: PropTypes.func.isRequired,
  gridOptions: gridOptionProps.isRequired,
};

function GridSelector(props) {
  const {
    callback,
    gridOptions,
  } = props;

  const {
    gridFillColor,
    gridlineColor,
    showGridlines,
  } = gridOptions;

  const handleColorChange = (key, color) => {
    callback({
      ...gridOptions,
      [key]: color,
    });
  };

  const handleFillColorChange = (color) => {
    localStorage.setItem(GRID_FILL_COLOR_KEY, color);
    handleColorChange('gridFillColor', color);
  };

  const gridlineOptionButton = ({ handleToggle }) => (
    <Button iconLeft="moreDots" variant="tertiary" onClick={handleToggle} />
  );

  return (
    <div className={styles.assetGridSelector}>
      <div className={styles.margin}>
        <Toggle
          checked={showGridlines}
          label="Show Grid"
          right
          onChange={() => callback({
            ...gridOptions,
            showGridlines: !showGridlines,
          })}
        />
      </div>
      <Dropdown ButtonComponent={gridlineOptionButton} menuPosition="top-right">
        {
          (_handlers) => (
            <div className={styles.gridlineOptionMenu}>
              <FormGroupContainer horizontal>
                <FormGroupContainer>
                  <div className={styles.colorOption}>Grid Color:</div>
                  {
                    GRID_COLORS.map((color) => (
                      <Radio
                        key={color}
                        checked={gridlineColor === color}
                        label={titleize(color)}
                        name="gridColor"
                        onChange={() => handleColorChange('gridlineColor', color)}
                      />
                    ))
                  }
                </FormGroupContainer>
                <FormGroupContainer>
                  <div className={styles.colorOption}>Fill Color:</div>
                  {
                    FILL_COLORS.map((color) => (
                      <Radio
                        key={color}
                        checked={gridFillColor === color}
                        label={titleize(color)}
                        name="fillColor"
                        onChange={() => handleFillColorChange(color)}
                      />
                    ))
                  }
                </FormGroupContainer>
              </FormGroupContainer>
            </div>
          )
        }
      </Dropdown>
    </div>
  );
}

GridSelector.propTypes = propTypes;

export default GridSelector;
