import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  optionProps,
  segmentProps,
} from 'components/molecules/Filter';
import ReportFilter, { getOptions } from 'components/reporting/ReportFilter';
import { titleize } from 'utilities/string';

export const dataPointProps = PropTypes.shape({
  assetType: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  campaignStatus: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
  marketName: PropTypes.string.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  guidelineName: PropTypes.string,
});

const propTypes = {
  dataPoints: PropTypes.arrayOf(dataPointProps).isRequired,
  isOpen: PropTypes.bool.isRequired,
  segments: PropTypes.arrayOf(segmentProps).isRequired,
  selections: PropTypes.objectOf(
    PropTypes.arrayOf(optionProps),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSelectionsChange: PropTypes.func.isRequired,
};

function getCustomOption(dataPoint, key) {
  if (key === 'assetType') {
    const value = dataPoint[key];
    const label = titleize(value);

    return {
      value,
      label,
    };
  }
  return null;
}

function DataPointsFilter({
  dataPoints,
  segments,
  selections,
  isOpen,
  onClose,
  onOpen,
  onSelectionsChange,
}) {
  const [options, setOptions] = useState({});

  useEffect(() => {
    setOptions(getOptions(dataPoints, segments, getCustomOption));
  }, [segments, dataPoints]);

  return (
    <ReportFilter
      isOpen={isOpen}
      options={options}
      segments={segments}
      selections={selections}
      onClose={onClose}
      onOpen={onOpen}
      onSelectionsChange={onSelectionsChange}
    />
  );
}

DataPointsFilter.propTypes = propTypes;

export default DataPointsFilter;
