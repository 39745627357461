import React from 'react';
import PropTypes from 'prop-types';
import styles from './LinkButton.module.css';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

function LinkButton({ children, onClick }) {
  return (
    <button
      className={styles.linkButton}
      type="button"
      onClick={onClick}
    >
      { children }
    </button>
  );
}

LinkButton.propTypes = propTypes;
LinkButton.defaultProps = defaultProps;

export default LinkButton;
