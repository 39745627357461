import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Divider,
  Icon,
  RadioGroup,
} from '@makeably/creativex-design-system';
import RepresentationBmeModal from 'components/admin/RepresentationBmeModal';
import ItemsTable from 'components/molecules/ItemsTable';
import { getItemSortBy } from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import { getAuthenticityToken } from 'utilities/requests';
import {
  adminCompanyOrganizationFeaturePath,
  adminCompanyProductFeaturesPath,
} from 'utilities/routes';
import styles from './RepresentationFeature.module.css';

const configProps = PropTypes.shape({
  brandIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  marketIds: PropTypes.arrayOf(PropTypes.number).isRequired,
});

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const propTypes = {
  brandOptions: PropTypes.arrayOf(optionProps).isRequired,
  companyId: PropTypes.number.isRequired,
  featureId: PropTypes.number.isRequired,
  groupedConfigs: PropTypes.arrayOf(configProps).isRequired,
  marketOptions: PropTypes.arrayOf(optionProps).isRequired,
};

const ALL_OPTION = 'all';
const RESTRICTED_OPTION = 'restricted';

const headers = [
  {
    key: 'brands',
    label: 'Brand',
  },
  {
    key: 'markets',
    label: 'Market',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];

const radioOptions = [
  {
    label: 'Yes',
    value: ALL_OPTION,
  },
  {
    label: 'No',
    value: RESTRICTED_OPTION,
  },
];

function getLabels(options) {
  const labels = options.map((option) => option.label);

  return labels.join(', ');
}

async function submitConfigs(items, companyId, featureId) {
  const configs = items.map(({ brands, markets }) => ({
    brands: brands.map((brand) => brand.value),
    markets: markets.map((market) => market.value),
  }));

  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('configs', JSON.stringify(configs));

  await fetch(adminCompanyOrganizationFeaturePath(companyId, featureId), {
    method: 'PATCH',
    body: formData,
  });
}

function RepresentationFeature({
  brandOptions,
  companyId,
  featureId,
  groupedConfigs,
  marketOptions,
}) {
  const allBrandsOption = brandOptions.find((option) => option.value === ALL_OPTION);
  const allMarketsOption = marketOptions.find((option) => option.value === ALL_OPTION);
  const allConfig = {
    brands: [allBrandsOption],
    markets: [allMarketsOption],
  };

  function initialConfigs() {
    if (groupedConfigs.length === 0) {
      return [allConfig];
    }

    return groupedConfigs.map((config) => ({
      brands: config.brandIds.map((id) => brandOptions.find((option) => option.value === id)),
      markets: config.marketIds.map((id) => marketOptions.find((option) => option.value === id)),
    }));
  }

  const [configs, setConfigs] = useState(initialConfigs());
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState();
  const [sortedItems, setSortedItems] = useState([]);

  function setInitialSelection(options) {
    if (configs.length === 1 && options?.at(0)?.value === ALL_OPTION) return ALL_OPTION;

    return RESTRICTED_OPTION;
  }

  const [brandSelection, setBrandSelection] = useState(setInitialSelection(configs[0]?.brands));
  const [marketSelection, setMarketSelection] = useState(setInitialSelection(configs[0]?.markets));
  const [currentConfig, setCurrentConfig] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isAddBmeDisabled = brandSelection === ALL_OPTION || marketSelection === ALL_OPTION;
  const isSetAccessDisabled = isSubmitting || configs.some((config) => (
    config.brands.length === 0 || config.markets.length === 0
  ));

  const onAllBrandsChange = (value) => {
    setBrandSelection(value);

    if (value === ALL_OPTION) {
      setConfigs((last) => ([{
        brands: [allBrandsOption],
        markets: last.reduce((all, config) => all.concat(config.markets), []),
      }]));
    } else {
      setConfigs((last) => ([{
        brands: [],
        markets: last[0].markets,
      }]));
    }
  };

  const onAllMarketsChange = (value) => {
    setMarketSelection(value);

    if (value === ALL_OPTION) {
      setConfigs((last) => ([{
        brands: last.reduce((all, config) => all.concat(config.brands), []),
        markets: [allMarketsOption],
      }]));
    } else {
      setConfigs((last) => ([{
        brands: last[0].brands,
        markets: [],
      }]));
    }
  };

  const addNewBme = () => {
    setCurrentConfig(undefined);
    setShowModal(true);
  };

  const onEditConfig = (config) => {
    setCurrentConfig(config);
    setShowModal(true);
  };

  const onDeleteConfig = (index) => {
    setConfigs((last) => [...last.slice(0, index), ...last.slice(index + 1)]);
  };

  const onSave = (selectedBrands, selectedMarkets) => {
    const newConfig = {
      brands: selectedBrands,
      markets: selectedMarkets,
    };
    const newConfigs = configs.slice();

    if (currentConfig) {
      newConfigs[configs.findIndex((c) => c === currentConfig)] = newConfig;
    } else {
      newConfigs.push(newConfig);
    }

    setShowModal(false);
    setConfigs(newConfigs);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await submitConfigs(configs, companyId, featureId);
    window.location.href = adminCompanyProductFeaturesPath(companyId);
  };

  function generateDisplay(options) {
    if (options.length === 0) {
      return {
        element: (
          <div className="u-flexRow">
            <span className="u-marginRight-8">Not Set</span>
            <Icon color="red" name="exclamationCircle" />
          </div>
        ),
        value: '',
      };
    }

    return {
      value: options,
      text: getLabels(options),
    };
  }

  function generateActions(config, index) {
    if (brandSelection === ALL_OPTION && marketSelection === ALL_OPTION) {
      return { value: 'N/A' };
    }

    return {
      element: (
        <div className="u-flexRow u-gap-8">
          <Button
            label="Edit"
            variant="tertiary"
            onClick={() => onEditConfig(config)}
          />
          { configs.length > 1 && (
            <Button
              label="Delete"
              variant="tertiary"
              onClick={() => onDeleteConfig(index)}
            />
          ) }
        </div>
      ),
      value: '',
    };
  }

  function getItems() {
    return configs.map((config, index) => setItemElement({
      id: { value: index },
      brands: generateDisplay(config.brands),
      markets: generateDisplay(config.markets),
      actions: generateActions(config, index),
    }));
  }

  useEffect(() => {
    setItems(getItems());
  }, [configs]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    } else {
      setSortedItems(items);
    }
  }, [items, sort]);

  return (
    <>
      <Card>
        <div className="u-flexRow u-justifyBetween">
          <div className="u-flexColumn">
            <h5 className="u-marginBottom-8">Customize Representation Access</h5>
            <span className="t-subtitle">Apply to everything, or specific brands and/or markets</span>
          </div>
          <AddNewButton
            disabled={isAddBmeDisabled}
            label="Add New Brand/Market Entity"
            onClick={addNewBme}
          />
        </div>
        <Divider margin />
        <div className="u-flexRow u-gap-24">
          <RadioGroup
            label="Set For All Brands"
            name="brandSelectionRadioGroup"
            options={radioOptions}
            value={brandSelection}
            horizontal
            onChange={(e) => onAllBrandsChange(e.target.value)}
          />
          <RadioGroup
            label="Set For All Markets"
            name="marketSelectionRadioGroup"
            options={radioOptions}
            value={marketSelection}
            horizontal
            onChange={(e) => onAllMarketsChange(e.target.value)}
          />
        </div>
        <Divider margin />
        <div className={styles.table}>
          <ItemsTable
            headers={headers}
            items={sortedItems}
            page={1}
            sort={sort}
            onSortChange={(value) => setSort(value)}
          />
        </div>
        <div className="u-marginTop-16">
          <Button
            disabled={isSetAccessDisabled}
            label="Set Access"
            variant="secondary"
            onClick={handleSubmit}
          />
        </div>
      </Card>
      { showModal && (
        <RepresentationBmeModal
          brandDisabled={brandSelection === ALL_OPTION}
          brandOptions={brandOptions}
          config={currentConfig}
          isOpen={showModal}
          marketDisabled={marketSelection === ALL_OPTION}
          marketOptions={marketOptions}
          onClose={() => setShowModal(false)}
          onSave={onSave}
        />
      ) }
    </>
  );
}

RepresentationFeature.propTypes = propTypes;

export default RepresentationFeature;
