// This component is used for diversity evaluation and renders
// a collection of people property types and values.
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Divider,
  FormGroup,
  FormGroupContainer,
  Radio,
  Select,
  TextField,
} from '@duik/it';
import { Tooltip } from '@makeably/creativex-design-system';
import CollapsibleWidget from 'components/reusable/collapsible_widget.coffee';
import ArrayHelper from 'components/utils/ArrayHelper';

const propertyValueProps = PropTypes.arrayOf(
  PropTypes.shape({
    description: PropTypes.string,
    label: PropTypes.string,
    labelExtension: PropTypes.string,
    multiselect: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.number,
  }),
);

const propertyProps = PropTypes.shape({
  propertyType: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  propertyValues: propertyValueProps,
});

const personProps = PropTypes.shape({
  age: propertyValueProps,
  id: PropTypes.number,
  position: PropTypes.string,
  sex: propertyValueProps,
  situation: propertyValueProps,
  skin_tone: propertyValueProps,
  timestamp: PropTypes.number,
});

/* eslint-disable react/no-unused-prop-types */
const propTypes = {
  diversityOptions: PropTypes.arrayOf(propertyProps).isRequired,
  isVideo: PropTypes.bool.isRequired,
  people: PropTypes.arrayOf(personProps).isRequired,
  onDiversityValueClick: PropTypes.func.isRequired,
  onPeopleStatusChange: PropTypes.func.isRequired,
  onPersonAdd: PropTypes.func.isRequired,
  onPersonDelete: PropTypes.func.isRequired,
  onPersonPositionChange: PropTypes.func.isRequired,
  onPersonTimestampChange: PropTypes.func.isRequired,
  peopleStatus: PropTypes.string,
};
/* eslint-enable react/no-unused-prop-types */

const defaultProps = {
  peopleStatus: undefined,
};

function renderRadio(callback, idx, person, personIdx, propertyValue) {
  const {
    description,
    label,
    labelExtension,
    type,
    value,
  } = propertyValue;

  const isChecked = ArrayHelper.objectInArray(person[type], propertyValue);

  const propertyValueLabel = labelExtension ? `${label}: ${labelExtension}` : label;

  let helperBox;
  if (type === 'skin_tone') {
    helperBox = <div className={`skinTone skinTone--${idx} u-marginRightSm`} />;
  }

  let tooltip;
  if (type === 'sex' && label === 'Unknown') {
    const text = 'Select Unknown if you\'re unable to determine the sex of a person on screen, such as a young child or baby.';
    tooltip = <Tooltip label={text} />;
  }

  let descriptionToRender;
  if (description) {
    descriptionToRender = <div>{ description }</div>;
  }

  const radioLabel = (
    <div className="u-flexRow">
      { helperBox }
      <div className="u-marginRightSm">
        <div>{ propertyValueLabel }</div>
        { descriptionToRender }
      </div>
      { tooltip }
    </div>
  );

  return (
    <Radio
      key={value}
      checked={isChecked}
      label={radioLabel}
      name={`${personIdx}_${type}`}
      value={value}
      onChange={() => callback(propertyValue, personIdx)}
    />
  );
}

function renderCheckbox(callback, person, personIdx, propertyValue) {
  const {
    description,
    label,
    labelExtension,
    type,
    value,
  } = propertyValue;

  const isChecked = ArrayHelper.objectInArray(person[type], propertyValue);

  const propertyValueLabel = labelExtension ? `${label}: ${labelExtension}` : label;

  return (
    <Checkbox
      key={value}
      checked={isChecked}
      description={description}
      label={propertyValueLabel}
      value={value}
      onChange={() => callback(propertyValue, personIdx)}
    />
  );
}

function Property(props) {
  const {
    onDiversityValueClick,
    person,
    personIdx,
    property: { propertyType, propertyValues },
  } = props;

  const {
    label,
    value,
  } = propertyType;

  let tooltip;
  if (value === 'skin_tone') {
    const text = 'We use the Fitzpatrick Scale, which classifies skin tone into 6 categories based on its response to UV light.';
    tooltip = <Tooltip label={text} />;
  }

  const content = propertyValues.map((propertyValue, idx) => {
    if (propertyValue.multiselect) {
      return renderCheckbox(onDiversityValueClick, person, personIdx, propertyValue);
    }
    return renderRadio(onDiversityValueClick, idx, person, personIdx, propertyValue);
  });

  return (
    <FormGroupContainer className="diversityProperty">
      <div className="u-flexRow u-flexAlignCenter diversityPropertyLabel">
        <h5 className="u-marginRightSm">{ label }</h5>
        { tooltip }
      </div>
      { content }
    </FormGroupContainer>
  );
}

Property.propTypes = {
  person: personProps.isRequired,
  personIdx: PropTypes.number.isRequired,
  property: propertyProps.isRequired,
  onDiversityValueClick: PropTypes.func.isRequired,
};

function renderTimestampField(props) {
  const {
    isVideo,
    onPersonTimestampChange,
    person: {
      timestamp,
    },
    personIdx,
  } = props;

  if (!isVideo) return null;

  return (
    <TextField
      label="Time of appearance in video (in seconds)"
      min="0"
      step="0.1"
      type="number"
      value={timestamp !== undefined ? timestamp : ''}
      onChange={(e) => onPersonTimestampChange(e.target.value, personIdx)}
    />
  );
}

function renderPropertyRow(props, properties) {
  const {
    onDiversityValueClick,
    person,
    personIdx,
  } = props;

  return (
    <FormGroupContainer horizontal>
      {
        properties.map((property) => (
          <Property
            key={property.propertyType.value}
            person={person}
            personIdx={personIdx}
            property={property}
            onDiversityValueClick={onDiversityValueClick}
          />
        ))
      }
    </FormGroupContainer>
  );
}

function Person(props) {
  const {
    diversityOptions: [
      age,
      sex,
      situation,
      skinTone,
    ],
    onPersonDelete,
    onPersonPositionChange,
    person,
    personIdx,
  } = props;

  const representation = [
    age,
    sex,
    skinTone,
  ];

  const portrayal = [
    situation,
  ];

  const positionOptions = [
    {
      label: 'Left',
      value: 'left',
    },
    {
      label: 'Center',
      value: 'center',
    },
    {
      label: 'Right',
      value: 'right',
    },
  ];

  return (
    <CollapsibleWidget headerText={`Person ${personIdx + 1}`}>
      <FormGroupContainer horizontal>
        <Select
          activeOption={positionOptions.find((option) => option.value === person.position)}
          label="Position"
          options={positionOptions}
          onOptionClick={(option) => onPersonPositionChange(option.value, personIdx)}
        />
        { renderTimestampField(props) }
        <FormGroup className="u-textAlignRight evalPage-deletePersonBtn">
          <Button clear onClick={() => onPersonDelete(personIdx)}>
            Delete
          </Button>
        </FormGroup>
      </FormGroupContainer>
      <Divider margin />
      { renderPropertyRow(props, representation) }
      { renderPropertyRow(props, portrayal) }
    </CollapsibleWidget>
  );
}

Person.propTypes = {
  diversityOptions: PropTypes.arrayOf(propertyProps).isRequired,
  person: personProps.isRequired,
  personIdx: PropTypes.number.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  onDiversityValueClick: PropTypes.func.isRequired,
  onPersonDelete: PropTypes.func.isRequired,
  onPersonPositionChange: PropTypes.func.isRequired,
};

function renderIndividualTagging(props) {
  const { peopleStatus } = props;

  if (peopleStatus !== 'individuals') return null;

  const {
    diversityOptions,
    isVideo,
    onDiversityValueClick,
    onPersonAdd,
    onPersonDelete,
    onPersonPositionChange,
    onPersonTimestampChange,
    people,
  } = props;

  const individualNotice = (
    <div className="u-marginBelowSm u-textAlignCenter">
      <b>
        Only add unique individuals. If a person is featured
        throughout the video, they only need to be added once.
      </b>
    </div>
  );

  const peopleLimitReached = people.length >= 5;
  let notice = null;
  if (peopleLimitReached) {
    notice = (
      <div className="u-marginBelowSm u-textAlignCenter">
        <b>
          Only 5 people can be tagged per creative at this time. If there
          are more than 5 individuals featured, select any 5 to include.
        </b>
      </div>
    );
  }

  return (
    <div className="u-marginAboveLg">
      { individualNotice }
      {
        people.map((person, idx) => (
          <div key={person.id || `new_person_${idx}`} className="u-marginBelow">
            <Person
              diversityOptions={diversityOptions}
              isVideo={isVideo}
              person={person}
              personIdx={idx}
              onDiversityValueClick={onDiversityValueClick}
              onPersonDelete={onPersonDelete}
              onPersonPositionChange={onPersonPositionChange}
              onPersonTimestampChange={onPersonTimestampChange}
            />
          </div>
        ))
      }
      <FormGroup className="u-marginAboveXLg u-textAlignCenter">
        { notice }
        <Button disabled={peopleLimitReached} onClick={() => onPersonAdd()}>
          Add Another Person
        </Button>
      </FormGroup>
    </div>
  );
}

function Selection(props) {
  const {
    onPeopleStatusChange,
    peopleStatus,
  } = props;

  const tagOptions = [
    {
      label: 'No Individuals: No people present in the creative. Body parts, like hands or '
        + 'feet, without a visible face do not count as people.',
      value: 'none',
    },
    {
      label: 'Individuals: At least 1 person with a visible face. The following do not count: '
        + 'Illustrations/animations, people on packaging.',
      value: 'individuals',
    },
    {
      label: 'Group: At least one frame contains more than 5 unique people in it (i.e. a crowd, large group)',
      value: 'group',
    },
  ];

  return (
    <div className="u-marginAbove">
      <FormGroupContainer>
        <h5>Tag As:</h5>
        {
          tagOptions.map(({ label, value }) => (
            <Radio
              key={value}
              checked={peopleStatus === value}
              label={label}
              name="people_status"
              value={value}
              onChange={(e) => onPeopleStatusChange(e.target.value)}
            />
          ))
        }
      </FormGroupContainer>
      { renderIndividualTagging(props) }
    </div>
  );
}

Selection.propTypes = propTypes;
Selection.defaultProps = defaultProps;

export default Selection;
