import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MessageModal } from '@makeably/creativex-design-system';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirmCampaign: PropTypes.func.isRequired,
  brand: PropTypes.string,
  market: PropTypes.string,
  name: PropTypes.string,
};

const defaultProps = {
  brand: undefined,
  market: undefined,
  name: undefined,
};

function renderField(name, value) {
  return (
    <div className="u-flexColumn u-gap-8">
      <div className="t-subtitle">{ `${name}:` }</div>
      <div className="t-body-1">{ value }</div>
    </div>
  );
}

function ConfirmCampaignModal({
  brand,
  isOpen,
  market,
  name,
  onClose,
  onConfirmCampaign,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    onConfirmCampaign();
  };

  return (
    <MessageModal
      actionButtonDisabled={isLoading}
      actionButtonLabel="Confirm"
      isOpen={isOpen}
      title="Confirm the below mapping"
      onActionButtonClick={handleConfirm}
      onClose={onClose}
    >
      <div className="u-flexColumn u-gap-16">
        { renderField('Campaign Name', name) }
        { renderField('Brand', brand) }
        { renderField('Market', market) }
      </div>
    </MessageModal>
  );
}

ConfirmCampaignModal.propTypes = propTypes;
ConfirmCampaignModal.defaultProps = defaultProps;

export default ConfirmCampaignModal;
