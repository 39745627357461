import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Checkbox,
  Search,
} from '@makeably/creativex-design-system';
import PreflightBanner from 'components/admin/review_queue/preflight/PreflightBanner';
import ItemsTable from 'components/molecules/ItemsTable';
import { toggleValue } from 'utilities/array';
import { getItemSortBy } from 'utilities/item';
import { valueIf } from 'utilities/object';
import {
  adminReviewPreflightOrganizationGuidelinesUrl,
} from 'utilities/routes';
import {
  getParams,
  getParamsObj,
} from 'utilities/url';
import styles from './Organizations.module.css';
import {
  PARAM_KEYS,
  searchItems,
  TIME_BUCKET_KEY,
  updatePinnedIds,
} from '../shared';

const propTypes = {
  canPinOrgs: PropTypes.bool.isRequired,
  checkCounts: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      hourAge: PropTypes.string.isRequired,
      organizationId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  orgs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pinnedOrgIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

function getHeaders(timeBucketOptions) {
  return [
    {
      key: 'pinned',
      label: 'Pinned',
    },
    {
      key: 'name',
      label: 'Customer',
    },
    {
      key: 'all',
      label: 'All',
    },
    ...timeBucketOptions,
  ];
}

function getCountLink(orgId, timeBucket, value, params) {
  const paramsObj = {
    ...getParamsObj(params, PARAM_KEYS),
    [TIME_BUCKET_KEY]: timeBucket,
  };

  const url = adminReviewPreflightOrganizationGuidelinesUrl(orgId, paramsObj);
  return <a href={url}>{ value }</a>;
}

function getCount(orgId, timeBucket, value, params) {
  return {
    value,
    ...valueIf(value === undefined, 'text', '--'),
    ...valueIf(value !== undefined, 'element', getCountLink(orgId, timeBucket, value, params)),
  };
}

function Organizations({
  canPinOrgs,
  checkCounts,
  headers,
  orgs,
  pinnedOrgIds,
}) {
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState({
    key: 'pinned',
    asc: true,
  });
  const [sortedItems, setSortedItems] = useState(items);
  const [pinnedIds, setPinnedIds] = useState(pinnedOrgIds);
  const [search, setSearch] = useState('');
  const [searchedItems, setSearchedItems] = useState(items);
  const params = getParams(window);

  const handlePinChange = async (id) => {
    const updated = toggleValue(pinnedIds, id);
    setPinnedIds(await updatePinnedIds(updated, true));
  };

  useEffect(() => {
    const getItems = () => {
      const bucketedChecks = {};
      checkCounts.forEach(({
        organizationId, hourAge, count,
      }) => {
        if (bucketedChecks[organizationId]) {
          bucketedChecks[organizationId][hourAge] = count;
        } else {
          bucketedChecks[organizationId] = { [hourAge]: count };
        }
      });

      return orgs.map(({ id, name }) => {
        const pinIndex = pinnedIds.findIndex((pinId) => pinId === id);
        const isPinned = pinIndex !== -1;
        const pinnedName = isPinned ? `${pinIndex}_${name}` : name;

        return {
          pinned: {
            value: pinnedName,
            element: (
              <Checkbox
                checked={isPinned}
                disabled={!canPinOrgs}
                onChange={() => handlePinChange(id)}
              />
            ),
          },
          id: { value: id },
          name: { value: name },
          all: {
            value: Object.entries(bucketedChecks[id]).reduce((sum, [, value]) => sum + value, 0),
          },
          ...headers.reduce((all, header) => ({
            ...all,
            [header.key]: getCount(id, header.key, bucketedChecks[id][header.key], params),
          }), {}),
        };
      });
    };

    setItems(getItems());
  }, [orgs, pinnedIds, checkCounts]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    }
  }, [items, sort]);

  useEffect(() => {
    setSearchedItems(searchItems(sortedItems, search));
  }, [sortedItems, search]);

  return (
    <Card>
      <div className={styles.controls}>
        <Search
          placeholder="Search Customer"
          value={search}
          onChange={(value) => setSearch(value)}
        />
        <PreflightBanner />
      </div>
      { searchedItems.length === 0 && (
        <div className={`t-empty ${styles.empty}`}>
          No companies match your search
        </div>
      ) }
      <ItemsTable
        className={styles.table}
        headers={getHeaders(headers)}
        items={searchedItems}
        sort={sort}
        onSortChange={(value) => setSort(value)}
      />
    </Card>
  );
}

Organizations.propTypes = propTypes;

export default Organizations;
