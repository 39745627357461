import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Menu,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import Slider from 'components/atoms/Slider';
import { addToast } from 'components/organisms/Toasts';
import { secondsToMinutes } from 'utilities/date';
import { logError } from 'utilities/logging';
import styles from './VideoControls.module.css';

const MS_IN_S = 1000;
const PLAYBACK_DEFAULT_INDEX = 1;
const PLAYBACK_RATES = [
  {
    display: '.5x',
    rate: 0.5,
  },
  {
    display: '1x',
    rate: 1,
  },
  {
    display: '2x',
    rate: 2,
  },
  {
    display: '4x',
    rate: 4,
  },
];
// note: This corresponds to the media readyState property. See more here:
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
const VIDEO_HAS_METADATA = 1;
const VOLUME_BAR_WIDTH = 60;
const VOLUME_MAX = 1;
const VOLUME_MIN = 0;

const propTypes = {
  videoRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  containerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  hasOptions: PropTypes.bool,
  // note: value in milliseconds
  progressStep: PropTypes.number,
  volumeStep: PropTypes.number,
};

const defaultProps = {
  containerRef: undefined,
  hasOptions: false,
  progressStep: 100,
  volumeStep: 0.1,
};

function VideoControls({
  containerRef,
  hasOptions,
  progressStep,
  videoRef,
  volumeStep,
}) {
  const moreOptionsRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isProgressChanging, setIsProgressChanging] = useState(false);
  const [isVolumeChanging, setIsVolumeChanging] = useState(false);
  const [isVolumeHover, setIsVolumeHover] = useState(false);
  const [isVolumeTabFocused, setIsVolumeTabFocused] = useState(false);
  const [playbackRateIndex, setPlaybackRateIndex] = useState(PLAYBACK_DEFAULT_INDEX);
  const [prevIsPlaying, setPrevIsPlaying] = useState(false);
  const [prevVideoVolume, setPrevVideoVolume] = useState(VOLUME_MAX);
  const [showMenu, setShowMenu] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoTime, setVideoTime] = useState(0);
  const [videoVolume, setVideoVolume] = useLocalStorage('videoPlayerVolume', VOLUME_MAX);

  useEffect(() => {
    const player = videoRef.current;

    player.playbackRate = PLAYBACK_RATES[playbackRateIndex]?.rate;
  }, [playbackRateIndex]);

  useEffect(() => {
    const player = videoRef.current;

    if (isPlaying) {
      player.play();
    } else {
      player.pause();
    }
  }, [isPlaying]);

  // note: pause video while progress slider is being manipulated
  useEffect(() => {
    if (isProgressChanging) {
      setPrevIsPlaying(isPlaying);
      setIsPlaying(false);
    } else {
      setIsPlaying(prevIsPlaying);
    }
  }, [isProgressChanging]);

  useEffect(() => {
    const player = videoRef.current;

    player.volume = videoVolume;
  }, [videoVolume]);

  const onPlayClick = () => {
    setIsPlaying((prevPlaying) => !prevPlaying);
  };

  const onVolumeMute = () => {
    if (videoVolume === VOLUME_MIN) {
      setVideoVolume(prevVideoVolume);
    } else {
      setPrevVideoVolume(videoVolume);
      setVideoVolume(VOLUME_MIN);
    }
  };

  const onVideoEnd = () => {
    setIsPlaying(false);
  };

  const onVideoError = (e) => {
    addToast('Error loading video.', { type: 'error' });
    logError('Video load error', {
      src: e.target.src,
    });
  };

  const onCurrentTimeUpdate = () => {
    setVideoTime(videoRef.current.currentTime);
  };

  const onPlayerClick = () => {
    setIsPlaying((prevPlaying) => !prevPlaying);
  };

  const onDurationLoaded = () => {
    setVideoDuration(videoRef.current.duration);
  };

  const onFullscreenClick = () => {
    setIsFullscreen((prev) => !prev);
  };

  const onOptionsClick = () => {
    if (isFullscreen) {
      onFullscreenClick();
    } else {
      setShowMenu((prev) => !prev);
    }
  };

  const handleProgressNav = (e) => {
    const player = videoRef.current;

    if (e.key === 'ArrowLeft') {
      player.currentTime -= (progressStep / MS_IN_S);
      e.preventDefault();
    } else if (e.key === 'ArrowRight') {
      player.currentTime += (progressStep / MS_IN_S);
      e.preventDefault();
    }
  };

  useEffect(() => {
    const player = videoRef.current;

    player.addEventListener('ended', onVideoEnd);
    player.addEventListener('error', onVideoError);
    player.addEventListener('timeupdate', onCurrentTimeUpdate);
    player.addEventListener('click', onPlayerClick);
    player.addEventListener('keydown', handleProgressNav);
    if (hasOptions) player.addEventListener('dblclick', onFullscreenClick);

    return () => {
      player.removeEventListener('ended', onVideoEnd);
      player.removeEventListener('error', onVideoError);
      player.removeEventListener('timeupdate', onCurrentTimeUpdate);
      player.removeEventListener('click', onPlayerClick);
      player.removeEventListener('keydown', handleProgressNav);
      if (hasOptions) player.removeEventListener('dblclick', onFullscreenClick);
    };
  }, [videoRef]);

  useEffect(() => {
    const player = videoRef.current;

    // note: If metadata is already loaded, loadedmetadata
    // event won't fire, so we set duration here
    if (player.readyState < VIDEO_HAS_METADATA) {
      player.addEventListener('loadedmetadata', onDurationLoaded);
      return () => player.removeEventListener('loadedmetadata', onDurationLoaded);
    }

    setVideoDuration(player.duration);
    return undefined;
  }, []);

  const onFullscreenEscape = () => {
    if (!document.fullscreenElement) {
      setIsFullscreen(false);
    }
  };

  // NOTE: Pressing escape in fullscreen does not trigger a keyboard event. These listeners are
  // meant to keep the fullscreen state in sync with the browser state.
  useEffect(() => {
    if (hasOptions) {
      const container = containerRef.current;

      if (isFullscreen) {
        container.addEventListener('fullscreenchange', onFullscreenEscape);
        container.requestFullscreen();
      } else {
        if (document.fullscreenElement) document.exitFullscreen();

        container.removeEventListener('fullscreenchange', onFullscreenEscape);
      }
    }
  }, [isFullscreen]);

  const handleVolumeNav = (e) => {
    if (e.key === 'ArrowLeft') {
      setVideoVolume((prevVolume) => Math.max(prevVolume - volumeStep, VOLUME_MIN));
      e.stopPropagation();
      e.preventDefault();
    } else if (e.key === 'ArrowRight') {
      setVideoVolume((prevVolume) => Math.min(prevVolume + volumeStep, VOLUME_MAX));
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const handleProgressChange = (percent) => {
    const updatedTime = videoDuration * percent;
    const player = videoRef.current;

    player.currentTime = updatedTime;
    setVideoTime(updatedTime);
  };

  const handlePlaybackRateChange = () => {
    setPlaybackRateIndex((prev) => (
      (prev + 1) % PLAYBACK_RATES.length
    ));
  };

  const volumeBarStyle = {
    width: isVolumeHover || isVolumeChanging || isVolumeTabFocused ? VOLUME_BAR_WIDTH : 0,
  };

  const menuOptions = [
    {
      label: 'Download',
      newTab: true,
      url: videoRef.current ? videoRef.current.src : '',
    },
    {
      label: 'Fullscreen',
      onClick: onFullscreenClick,
    },
  ];

  return (
    <div
      className={styles.container}
      role="button"
      tabIndex={-1}
      onKeyDown={handleProgressNav}
    >
      <div className={styles.progressSlider}>
        <Slider
          percent={videoTime / videoDuration}
          onChange={handleProgressChange}
          onMouseClicked={setIsProgressChanging}
        />
      </div>
      <div className={styles.controls}>
        <button className={styles.play} type="button" onClick={onPlayClick}>
          <Icon color="current" name={isPlaying ? 'pause' : 'play'} />
        </button>
        <button
          className={styles.playback}
          type="button"
          onClick={handlePlaybackRateChange}
        >
          <div className={`t-button-bold ${styles.playbackText}`}>
            { PLAYBACK_RATES[playbackRateIndex].display }
          </div>
        </button>
        <div
          className={styles.volumeContainer}
          role="button"
          tabIndex={-1}
          onKeyDown={handleVolumeNav}
          onMouseEnter={() => setIsVolumeHover(true)}
          onMouseLeave={() => setIsVolumeHover(false)}
        >
          <button className={styles.volume} type="button" onClick={onVolumeMute}>
            <Icon color="current" name={videoVolume === VOLUME_MIN ? 'soundOff' : 'soundOn'} />
          </button>
          <div
            className={styles.volumeBar}
            style={volumeBarStyle}
            onBlur={() => setIsVolumeTabFocused(false)}
            onFocus={() => setIsVolumeTabFocused(true)}
          >
            <Slider
              backgroundColor="grey"
              color="white"
              percent={videoVolume}
              onChange={setVideoVolume}
              onMouseClicked={setIsVolumeChanging}
            />
          </div>
        </div>
        <div className={styles.text}>
          { `${secondsToMinutes(videoTime, 1)} / ${secondsToMinutes(videoDuration, 1)}` }
        </div>
        { hasOptions && (
          <>
            <button
              ref={moreOptionsRef}
              className={styles.options}
              type="button"
              onClick={onOptionsClick}
            >
              <Icon color="current" name={isFullscreen ? 'minimize' : 'moreDots'} />
            </button>
            <Menu
              options={menuOptions}
              parentRef={moreOptionsRef}
              position="top-right"
              show={showMenu}
              size="small"
              onClose={() => setShowMenu(false)}
            />
          </>
        ) }
      </div>
    </div>
  );
}

VideoControls.propTypes = propTypes;
VideoControls.defaultProps = defaultProps;

export default VideoControls;
