import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormGroupContainer,
  Widget,
  WidgetContent,
} from '@duik/it';
import CardHeader from 'components/reusable/CardHeader';
import { HiddenInput } from 'components/reusable/HiddenInput';
import PermissionsForm, { inviterProps } from 'components/settings/users/PermissionsForm';
import ArrayHelper from 'components/utils/ArrayHelper';
import UserFormValidator from 'components/utils/UserFormValidator';

const dataRestrictionOptions = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export const propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  brands: PropTypes.shape(dataRestrictionOptions).isRequired,
  channels: PropTypes.shape(dataRestrictionOptions).isRequired,
  companies: PropTypes.shape(dataRestrictionOptions).isRequired,
  companyRestrictionType: PropTypes.oneOf(['own', 'custom']).isRequired,
  inviter: inviterProps.isRequired,
  isLifecycleEnabled: PropTypes.bool.isRequired,
  isRepresentationEnabled: PropTypes.bool.isRequired,
  markets: PropTypes.shape(dataRestrictionOptions).isRequired,
  profile: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCompany: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  url: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

class EditPermissionsForm extends React.Component {
  constructor(props) {
    super(props);

    const {
      brands,
      channels,
      companies,
      companyRestrictionType,
      markets,
      profile,
      roles,
    } = props;

    this.state = {
      companyRestrictionType,
      profile,
      roles: roles || [],
      selectedBrands: brands.selectedOptions || [],
      selectedChannels: channels.selectedOptions || [],
      selectedCompanies: companies.selectedOptions || [],
      selectedMarkets: markets.selectedOptions || [],
    };
  }

  onProfileChange(profile) {
    this.setState({
      companyRestrictionType: 'own',
      profile,
      roles: [],
      selectedBrands: [],
      selectedChannels: [],
      selectedCompanies: [],
      selectedMarkets: [],
    });
  }

  onRoleSelect(role) {
    this.setState((prevState) => {
      let { roles } = prevState;
      roles = ArrayHelper.withOrWithoutValue(roles, role);

      return ({ roles });
    });
  }

  onCompanyRestrictionChange(restrictionType) {
    this.setState({ companyRestrictionType: restrictionType });
  }

  onRestrictionOptionChange(valueObj, name) {
    this.setState((prevState) => {
      let values = prevState[name];
      values = ArrayHelper.withOrWithoutObject(values, valueObj);

      return ({ [name]: values });
    });
  }

  render() {
    const {
      authenticityToken,
      brands,
      channels,
      companies,
      isLifecycleEnabled,
      isRepresentationEnabled,
      inviter,
      markets,
      selectedCompany,
      userName,
      url,
    } = this.props;

    const {
      companyRestrictionType,
      profile,
      roles,
      selectedBrands,
      selectedChannels,
      selectedCompanies,
      selectedMarkets,
    } = this.state;

    const brandOptions = brands.options;
    const channelOptions = channels.options;
    const companyOptions = companies.options;
    const marketOptions = markets.options;

    return (
      <Widget>
        <CardHeader
          header={`Permissions for ${userName}`}
          subHeader="Modify permissions for a team member"
        />
        <WidgetContent>
          <form action={url} method="post">
            <HiddenInput name="_method" value="patch" />
            <HiddenInput name="authenticity_token" value={authenticityToken} />
            <div className="u-marginBelowLg">
              <PermissionsForm
                brandOptions={brandOptions}
                brands={selectedBrands}
                channelOptions={channelOptions}
                channels={selectedChannels}
                companies={selectedCompanies}
                company={selectedCompany}
                companyOptions={companyOptions}
                companyRestrictionType={companyRestrictionType}
                inviter={inviter}
                isLifecycleEnabled={isLifecycleEnabled}
                isRepresentationEnabled={isRepresentationEnabled}
                marketOptions={marketOptions}
                markets={selectedMarkets}
                profile={profile}
                roles={roles}
                onCompanyRestrictionChange={(rt) => this.onCompanyRestrictionChange(rt)}
                onProfileChange={(selectedProfile) => this.onProfileChange(selectedProfile)}
                onRestrictionOptionChange={(obj, name) => this.onRestrictionOptionChange(obj, name)}
                onRoleSelect={(selectedRole) => this.onRoleSelect(selectedRole)}
              />
            </div>
            <FormGroupContainer>
              <FormGroup className="u-textAlignCenter">
                <input
                  className="formSubmit btn btn-primary"
                  disabled={UserFormValidator.permissionsIncomplete(this.state)}
                  name="commit"
                  type="submit"
                  value="Update Permissions"
                />
              </FormGroup>
            </FormGroupContainer>
          </form>
        </WidgetContent>
      </Widget>
    );
  }
}

EditPermissionsForm.propTypes = propTypes;

export default EditPermissionsForm;
