// LineChart with data toggle functionality based on tabs
//   When using, only render inside of a Widget
//   Card header is dynamic based on the selected tab
import React from 'react';
import PropTypes from 'prop-types';
import { WidgetContent } from '@duik/it';
import LineChart from 'components/charts/LineChart';
import CardHeader from 'components/reusable/CardHeader';
import Loader from 'components/reusable/Loader';
import Tabs from 'components/reusable/Tabs';
import StringHelper from 'components/utils/StringHelper';

export const propTypes = {
  subHeader: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  charts: PropTypes.arrayOf(
    PropTypes.shape({
      chartType: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number),
      labels: PropTypes.arrayOf(PropTypes.string),
      lineColor: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  dataUrl: PropTypes.string,
  remote: PropTypes.bool,
};

const defaultProps = {
  charts: undefined,
  className: undefined,
  dataUrl: undefined,
  remote: false,
};

class LineChartWithTabs extends React.Component {
  constructor(props) {
    super(props);

    this.chartRef = React.createRef();

    const {
      charts,
      tabs,
      remote,
    } = props;

    const activeTab = tabs[0].value;

    let activeChart;
    if (!remote) {
      activeChart = charts.find(({ label }) => label === activeTab).chart;
    }

    this.state = {
      activeChart,
      activeTab,
      charts,
      error: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { dataUrl, remote } = this.props;
    if (!remote) return;

    this.getData(dataUrl);
  }

  getData(url) {
    this.setState({ loading: true });

    fetch(url)
      .then((res) => res.json())
      .then((charts) => (
        this.setState((prevState) => {
          let activeChart;
          if (charts.length > 0) {
            activeChart = charts.find(({ label }) => label === prevState.activeTab).chart;
          }

          return {
            activeChart,
            charts,
            error: false,
            loading: false,
          };
        })
      ))
      .catch((_error) => this.setState({
        loading: false,
        error: true,
      }));
  }

  updateData(tabValue) {
    const { chart } = this.chartRef.current;

    this.setState((prevState) => {
      const { charts } = prevState;

      const {
        chartType,
        data,
        labels,
      } = charts.find(({ label }) => label === tabValue).chart;

      chart.data.labels = labels;
      chart.data.datasets[0].data = data;
      chart.options.scales = LineChart.updateScales(data, chartType);
      chart.options.tooltips = LineChart.tooltipOptions(chartType);

      return { activeTab: tabValue };
    }, () => {
      chart.update();
      chart.resize();
    });
  }

  render() {
    const {
      className,
      subHeader,
      tabs,
    } = this.props;

    const {
      activeChart,
      activeTab,
      error,
      loading,
    } = this.state;

    const formattedTabLabel = StringHelper.titleize(StringHelper.singularize(activeTab));

    let content = null;

    if (error) {
      content = 'Encountered an error';
    } else if (loading) {
      content = <Loader />;
    } else if (activeChart) {
      const {
        chartType,
        data,
        labels,
        lineColor,
      } = activeChart;

      content = (
        <LineChart
          ref={this.chartRef}
          chartType={chartType}
          data={data}
          labels={labels}
          lineColor={lineColor}
        />
      );
    }

    return (
      <div className={className}>
        <CardHeader
          header={`${formattedTabLabel} Trends`}
          subHeader={subHeader}
        />
        <Tabs
          activeOption={activeTab}
          callback={(tabValue) => this.updateData(tabValue)}
          tabs={tabs}
        />
        <WidgetContent>
          { content }
        </WidgetContent>
      </div>
    );
  }
}

LineChartWithTabs.propTypes = propTypes;
LineChartWithTabs.defaultProps = defaultProps;

export default LineChartWithTabs;
