import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  Divider,
  Search,
} from '@makeably/creativex-design-system';
import NewIndustryModal from 'components/admin/industries/NewIndustryModal';
import ItemsTable from 'components/molecules/ItemsTable';
import { getItemSortBy } from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import {
  adminCategoriesPath,
  adminIndustryPath,
  editAdminIndustryPath,
} from 'utilities/routes';
import styles from './IndustryManager.module.css';

const industryProps = PropTypes.shape({
  applicableBrands: PropTypes.number.isRequired,
  applicableCategories: PropTypes.number.isRequired,
  applicableCompanies: PropTypes.number.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

const propTypes = {
  canEditIndustries: PropTypes.bool.isRequired,
  industries: PropTypes.arrayOf(industryProps),
};

const defaultProps = {
  industries: [],
};

const headers = [
  {
    key: 'name',
    label: 'Industry',
  },
  {
    key: 'applicableCategories',
    label: 'Applicable Categories',
  },
  {
    key: 'applicableCompanies',
    label: 'Applicable Companies',
  },
  {
    key: 'applicableBrands',
    label: 'Applicable Brands',
  },
  {
    key: 'dateUpdated',
    label: 'Date Updated',
  },
];

function getItems(
  canEditIndustries,
  industries,
) {
  return industries.map((industry) => setItemElement({
    id: { value: industry.id },
    name: {
      ...canEditIndustries && {
        display: {
          url: editAdminIndustryPath(industry.id),
        },
      },
      value: industry.name,
    },
    applicableCategories: {
      display: {
        url: adminCategoriesPath({ industry_id: industry.id }),
      },
      value: industry.applicableCategories,
    },
    applicableCompanies: {
      display: {
        url: adminIndustryPath(industry.id),
      },
      value: industry.applicableCompanies,
    },
    applicableBrands: {
      display: {
        url: adminIndustryPath(industry.id),
      },
      value: industry.applicableBrands,
    },
    dateUpdated: { value: industry.dateUpdated },
  }));
}

function IndustryManager({
  canEditIndustries,
  industries,
}) {
  const [filteredIndustries, setFilteredIndustries] = useState(industries);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState();
  const [sortedItems, setSortedItems] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);

  useEffect(() => {
    const filtered = industries.filter(({ name }) => {
      if (search) {
        const term = search.toLowerCase();

        return name.toLowerCase().includes(term);
      }

      return true;
    });

    setFilteredIndustries(filtered);
  }, [search]);

  useEffect(() => {
    setItems(getItems(canEditIndustries, filteredIndustries));
  }, [filteredIndustries]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    } else {
      setSortedItems(items);
    }
  }, [items, sort]);

  return (
    <>
      <Card className={styles.card}>
        <div className="u-flexRow u-justifyBetween u-marginBottom-16">
          <Search
            value={search}
            onChange={setSearch}
          />
          <AddNewButton
            disabled={!canEditIndustries}
            label="Add New Industry"
            onClick={() => setShowNewModal(true)}
          />
        </div>
        <Divider />
        <div className={styles.table}>
          <ItemsTable
            headers={headers}
            items={sortedItems}
            page={page}
            sort={sort}
            onPageChange={(p) => setPage(p)}
            onSortChange={(value) => setSort(value)}
          />
        </div>
        { filteredIndustries.length === 0 && (
          <div className="u-flexRow u-justifyCenter u-marginTop-16 t-empty">
            Click &quot;+Add New Industry&quot; button to create a new Industry
          </div>
        ) }
      </Card>
      <NewIndustryModal
        isOpen={showNewModal}
        onClose={() => setShowNewModal(false)}
      />
    </>
  );
}

IndustryManager.propTypes = propTypes;
IndustryManager.defaultProps = defaultProps;

export default IndustryManager;
