import React from "react"
import RadioButtonGroup from "components/reusable/radio_button_group"
import Select from "components/reusable/select"
import PropTypes from "prop-types"

# responsible for rendering the audience filter for the search form
# consists of a dropdown and different types of filter selection options based on the value of
# the dropdown filter
class BreakdownFilter extends React.Component
  @propTypes =
    adAccountId: PropTypes.number
    onChange: PropTypes.func
    age: PropTypes.shape(
      props: PropTypes.object # Props for Radio
    )
    gender: PropTypes.shape(
      props: PropTypes.object # Props for Radio
    )
    selectProps: PropTypes.object
    selectedBreakdownType: PropTypes.string
    selectedBreakdownValue: PropTypes.oneOfType([
      PropTypes.array, # Typeahead
      PropTypes.string, # Radio
    ])

  onBreakdownTypeChange: (value, opts) =>
    defaultBreakdownValue =
      if value is 'age' or value is 'gender'
        @props[value].props.options[0].value
      else
        ''

    @props.onChange(value, _.extend(opts,
      defaultBreakdownValue: defaultBreakdownValue
    ))

  # NB: Secondary filter represents the breakdown value
  #     Ex: if the selectedBreakdownType is 'age', secondary filter is the list
  #     of like 18-24, 25-30, etc...
  renderSecondaryFilter: ->
    # NB: a blank breakdown type means there is no secondary filter
    return if @props.selectedBreakdownType is ''

    filter = @props[@props.selectedBreakdownType]

    `<RadioButtonGroup
      {...filter.props}
      onSelect={this.props.onChange}
      selectedValue={this.props.selectedBreakdownValue}
    />`

  render: ->
    `<div>
      <Select
        {...this.props.selectProps}
        onChange={this.onBreakdownTypeChange}
        selected={this.props.selectedBreakdownType}
      />
      {this.renderSecondaryFilter()}
    </div>`

export default BreakdownFilter
