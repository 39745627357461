import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import AltTextPanel from "components/preflight/AltTextPanel"
import FileDrop from "components/molecules/FileDrop"
import MixpanelTracker from "components/utils/MixpanelTracker"
import ObjectHelper from "components/utils/ObjectHelper"
import PreflightUrlUploader from "components/preflight/PreflightUrlUploader"
import SearchableSelect from "components/reusable/SearchableSelect"
import {
  Alert,
  Button,
  ContainerHorizontal,
  Divider,
  Dropdown,
  DropdownItem,
  FormGroupContainer,
  FormGroup,
  NavPanel,
  ScrollArea,
  Select,
  TextField,
  Widget,
  WidgetContainer,
  WidgetContent,
  WidgetHeader
} from "@duik/it"
import { Icon, RadioGroup, Tooltip } from "@makeably/creativex-design-system"
import { addToast } from 'components/organisms/Toasts'
import { getAuthenticityToken } from "utilities/requests"

propTypes =
  brandOptions: PropTypes.array
  # If this is not provided, campaignObjective dropdown does not appear
  campaignObjectiveOptions: PropTypes.arrayOf(
    PropTypes.shape(
      channel: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string
    )
  )
  channelOptions: PropTypes.arrayOf(
    PropTypes.shape(
      label: PropTypes.string,
      value: PropTypes.string
    )
  )
  exitSessionUrl: PropTypes.string.isRequired
  # If this is not provided, publisher dropdown does not appear
  publisherOptions: PropTypes.arrayOf(
    PropTypes.shape(
      channel: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string
    )
  )
  languages: PropTypes.arrayOf(PropTypes.string),
  marketOptions: PropTypes.array
  # If this is not provided, placements dropdown does not appear
  placementOptions: PropTypes.arrayOf(
    PropTypes.shape(
      channel: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string
    )
  )
  # Used to submit ajax post request to create preflights
  preflightSubmitUrl: PropTypes.string.isRequired
  # Used to send files to the server
  postUrl: PropTypes.string.isRequired
  rulesUrl: PropTypes.string
  altTextEnabled: PropTypes.bool.isRequired

# This component allows users to drag and drop assets into a "submission" area and create one or
#   many preflights with those assets. Note that because we want to a create "session" like experience,
#   we use ajax to submit post requests to the server to create the preflights so that users may submit
#   multiple preflights in one session.
#
# We utitilze Uppy's Dashboard component for asset uploads and some additional jQuery to make those
#   assets selectable and dynamically style them. Note that we rely on certain document ids and
#   classes that the Uppy component uses for proper functionality. If those ids or classes change
#   during a version update, we may have breaking changes here.
class PreflightForm extends React.Component
  DEFAULT_CAMPAIGN_OBJECTIVE = 'UNKNOWN'

  PREFLIGHT_COPY = "Check your creatives meet your organization's criteria for excellence.
    After uploading, categorize your creatives based on their brand, market, and channel, and submit."

  SELECT_CHAR_LIMIT = 24
  MULTIPLE_SELECT_CHAR_LIMIT = 16
  MAX_VIDEO_DURATION = 180
  DASHBOARD_NOTE_COPY = "Videos should be no longer than 3 minutes"
  FORM_ID = 'preflightSubmitForm'
  # Channels where Unknown is added as a campaign objective
  CHANNELS_WITHOUT_UNKNOWN_SUPPORT = ['facebook_paid', 'instagram_paid']
  CHANNELS_WITH_PUBLISHER = ['dv360', 'youtube_paid']

  @propTypes =
    brandOptions: PropTypes.array
    # If this is not provided, campaignObjective dropdown does not appear
    campaignObjectiveOptions: PropTypes.arrayOf(
      PropTypes.shape(
        channel: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string
      )
    )
    channelOptions: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string,
        value: PropTypes.string
      )
    )
    exitSessionUrl: PropTypes.string.isRequired
    # If this is not provided, publisher dropdown does not appear
    publisherOptions: PropTypes.arrayOf(
      PropTypes.shape(
        channel: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string
      )
    )
    languages: PropTypes.arrayOf(PropTypes.string),
    marketOptions: PropTypes.array
    # If this is not provided, placements dropdown does not appear
    placementOptions: PropTypes.arrayOf(
      PropTypes.shape(
        channel: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string
      )
    )
    # Used to submit ajax post request to create preflights
    preflightSubmitUrl: PropTypes.string.isRequired
    # Used to send files to the server
    postUrl: PropTypes.string.isRequired
    rulesUrl: PropTypes.string
    altTextEnabled: PropTypes.bool.isRequired

  constructor: (props) ->
    super(props)

    this.brandOptions = ObjectHelper.valuesToObjects(props.brandOptions)
    this.marketOptions = ObjectHelper.valuesToObjects(props.marketOptions)
    this.channelOptions = props.channelOptions
    this.languages = props.languages

    if this.channelOptions.length is 1
      channel = this.channelOptions[0].value
    else
      channel = undefined

    @state =
      brand: undefined
      campaignObjective: @getDefaultCampaignObjective(channel)
      channel: channel
      contentType: 'brand'
      language: undefined
      market: undefined
      placements: []
      preflights: []
      publisher: undefined
      selectedAssets: []
      showErrorNotification: false
      showSuccessNotification: false
      uploadByFile: true
      uploadedAssets: []

  # When the window is resized, we need to re-attach click handlers on the
  # assets in the uppy dashboard and re-attach the red borders around the
  # selected assets
  onResize: =>
    _.each(@state.uploadedAssets, (asset) =>
      @elementForAsset(asset).unbind('click')
      @elementForAsset(asset).click(=> @onAssetClick(asset.id))

      if _.include(@state.selectedAssets, asset)
        @selectAsset(asset)
      else
        @deselectAsset(asset)
    )

  componentDidMount: ->
    window.addEventListener('resize', @onResize)

  componentWillUnmount: ->
    window.removeEventListener('resize', @onResize)

  # The selects do not expand, so we need to truncate the text that is displayed
  # If the isMultiple flag is set, that means its a multi-select, so we have to
  # account for the extra space taken up by the count indicator
  truncateDisplayedValue: (activeOption, isMultiple = false) ->
    charLimit = if isMultiple then MULTIPLE_SELECT_CHAR_LIMIT else SELECT_CHAR_LIMIT
    # Copy object so that the dropdown options remain un-truncated
    option = Object.assign({}, activeOption)
    return activeOption unless option.label.length >= charLimit
    option.label = option.label.substring(0, charLimit - 3) + "..."
    option

  getDefaultCampaignObjective: (channel) ->
    switch channel
      when 'facebook_paid'
        campaignObj = DEFAULT_CAMPAIGN_OBJECTIVE
      when 'instagram_paid'
        campaignObj = DEFAULT_CAMPAIGN_OBJECTIVE
      when 'youtube_paid'
        campaignObj = DEFAULT_CAMPAIGN_OBJECTIVE
      else
        campaignObj = undefined

    return campaignObj

  onNameChange: (event) =>
    @setState(name: event.target.value)

  onChannelChange: (channel) =>
    # NB: We want to reset placements and campaign objective when you change channel
    # Default campaign objective option for supported channels is Unknown
    @setState(
      campaignObjective: @getDefaultCampaignObjective(channel)
      channel: channel
      placements: []
      publisher: undefined
    )

  onSelectChange: (option, name) =>
    value = option.value
    @onChannelChange(value) if name is 'channel'
    @setState("#{name}": value, "#{name}Keyword": '')

  onPlacementChange: (option) =>
    placement = option.value
    placements =
      if @state.placements.includes(placement)
        # value alredy selected, now we want to remove it
        _.without(@state.placements, placement)
      else
        @state.placements.concat([placement])

    @setState(placements: placements)

  onCampaignObjectiveChange: (option) =>
    @setState(campaignObjective: option.value)

  onPublisherChange: (option) =>
    @setState(publisher: option.value)

  onContentTypeChange: (event) =>
    @setState(contentType: event.target.value)

  onAltTextChange: (asset, text) =>
    @setState((prevState) ->
      updatedAsset = asset
      updatedAsset.altText = text

      updatedUploadedAssets = prevState.uploadedAssets

      for otherAsset, index in updatedUploadedAssets
        if otherAsset.id is asset.id
          updatedUploadedAssets[index] = updatedAsset

      updatedSelectedAssets = prevState.selectedAssets

      for otherAsset, index in updatedSelectedAssets
        if otherAsset.id is asset.id
          updatedSelectedAssets[index] = updatedAsset

      return {
        selectedAssets: updatedSelectedAssets,
        uploadedAssets: updatedUploadedAssets,
      }
    )

  optionsForCurrentChannel: (options) ->
    return [] unless @state.channel
    _.filter(options, (option) => option['channel'] is @state.channel)

  labelForChannel: (channelValue) ->
    _.find(this.channelOptions, (option) -> option.value is channelValue).label

  nameCount: (name) ->
    _.filter(@state.preflights, (preflight) -> preflight.name is name).length

  elementForAsset: (asset) ->
    # We use getElementById since the fileId contains some special characters
    $(document.getElementById("uppy_#{asset.id}"))

  # Used to attach a red border around selected assets
  selectAsset: (asset) =>
    @elementForAsset(asset).
      find('.uppy-Dashboard-Item-previewInnerWrap').
      addClass('preflightFormAsset--selected')

  # Used to remove the red border around selected assets
  deselectAsset: (asset) =>
    @elementForAsset(asset).
      find('.uppy-Dashboard-Item-previewInnerWrap').
      removeClass('preflightFormAsset--selected')

  selectAllAssets: =>
    _.each(@state.uploadedAssets, (asset) => @selectAsset(asset))
    @setState(selectedAssets: @state.uploadedAssets)

  deselectAllAssets: =>
    _.each(@state.uploadedAssets, (asset) => @deselectAsset(asset))
    @setState(selectedAssets: [])

  deselectSubmittedAssets: =>
    _.each(@state.selectedAssets, (asset) => @deselectAsset(asset))

  # Once an asset is submitted as part of a preflight, it is still selectable, but will have
  #   half opacity to indicate that it has already been submitted
  blurSubmittedAssets: =>
    _.each(@state.selectedAssets, (asset) =>
      @elementForAsset(asset).addClass('preflightFormAsset--submitted')
    )

  onAssetClick: (clickedAssetId) =>
    clickedAsset = _.find(@state.uploadedAssets, (asset) -> asset.id is clickedAssetId)

    updatedAssets =
      if _.any(@state.selectedAssets, (asset) -> asset.id is clickedAssetId)
        @deselectAsset(clickedAsset)
        _.reject(@state.selectedAssets, (asset) -> asset.id is clickedAssetId)
      else
        @selectAsset(clickedAsset)
        _.union(@state.selectedAssets, [clickedAsset])

    @setState(selectedAssets: updatedAssets)

  submittedAssets: =>
    return _.filter(@state.uploadedAssets, (asset) -> asset.submitted)

  unsubmittedAssets: =>
    return _.filter(@state.uploadedAssets, (asset) -> !asset.submitted)

  uploadSuccess: (file, url) =>
    asset =
      altText: ''
      content_type: file.type
      filename: file.name
      id: file.id
      preview: file.preview
      s3_filename: url.substring(url.lastIndexOf('/') + 1)
      submitted: false
      url: url

    # Since the Uppy Dashboard controls the rendering of the assets within the
    # drag-drop area, we use jQuery to make the uploaded assets
    # clickable/selectable
    @elementForAsset(asset).click(=> this.onAssetClick(asset.id))
    unsubmitted = _.union(this.unsubmittedAssets(), [asset])
    @setState(uploadedAssets: _.union(unsubmitted, this.submittedAssets()))

  urlUploadSuccess: (asset) =>
    unsubmitted = _.union(this.unsubmittedAssets(), [asset])
    @setState(uploadedAssets: _.union(unsubmitted, this.submittedAssets()))

  onNetworkError: =>
    @setState(
      showErrorNotification: true,
      errorMessage: "Error: This looks like a network error, the endpoint " +
        "might be blocked by an internet provider or a firewall."
    )

  onValidationError: (message) ->
    addToast(message, { size: 'large', type: 'error', duration: 10000 })

  setErrorState: (message) =>
    @deselectSubmittedAssets()
    @setState(
      selectedAssets: [],
      showErrorNotification: true,
      errorMessage: message
    )

  reorderSubmittedAssets: =>
    submittedAssets = @state.selectedAssets
    reorderedAssets = _.difference(@state.uploadedAssets, submittedAssets)
    reorderedAssets = _.union(reorderedAssets, submittedAssets)
    for asset in @state.selectedAssets
      asset.submitted = true
    for asset in reorderedAssets
      asset.submitted = true
    return reorderedAssets

  createPreflight: (event) =>
    event.preventDefault()

    preflight =
      assets: @state.selectedAssets.map((asset) ->
        # format to ruby
        asset['alt_text'] = asset.altText
        asset['creative_source'] = asset.creativeSource
        asset['creative_source_type'] = asset.creativeSourceType

        asset
      )
      brand: @state.brand
      campaign_objective: @state.campaignObjective
      channel: @state.channel
      content_type: @state.contentType
      language: @state.language
      market: @state.market
      name: @state.name
      placements: @state.placements || []
      publisher: @state.publisher

    fetch(
      @props.preflightSubmitUrl,
      method: 'POST',
      body: JSON.stringify(preflight),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getAuthenticityToken()
      }
    ).then((response) =>
      if response.ok
        # For adding numerical notation to preflights with the same name
        preflight.nameIdx = @nameCount(preflight.name) + 1

        @deselectSubmittedAssets()
        @blurSubmittedAssets()
        @setState(
          uploadedAssets: this.reorderSubmittedAssets()
          selectedAssets: [],
          preflights: [preflight].concat(@state.preflights),
          showSuccessNotification: true
        )
      else
        response.json()
          .then((response) =>
            @setErrorState(response.error)
          )
          .catch(=>
            @setErrorState('Pre-flight failed to create. Please refresh the page and try again.')
          )

      setTimeout(@removeNotification, 10000)
    )

  removeNotification: =>
    @setState(showSuccessNotification: false, showErrorNotification: false)

  preflightsCreated: ->
    !_.isEmpty(@state.preflights)

  isElementValid: (el) ->
    if _.isArray(el)
      !_.isUndefined(el) and el.length > 0
    else
      !_.isUndefined(el)

  validPlacements: ->
    # This part of the form is valid if there are no placement specific guidelines
    return true unless @props.placementOptions

    # If there are placement specific guidelines, user must select at least 1
    #   placement if placement options are available for the selected channel
    if @optionsForCurrentChannel(@props.placementOptions).length > 0
      @isElementValid(@state.placements)
    else
      true

  validPublishers: ->
    # This part of the form is valid if there are no publisher specific
    # guidelines
    return true unless @props.publisherOptions

    # If there are publisher specific guidelines, user must select a publisher
    # if publisher options are available for the selected channel
    if @optionsForCurrentChannel(@props.publisherOptions).length > 0
      @isElementValid(@state.publisher)
    else
      true

  isFormValid: ->
    _.every([
      @state.selectedAssets,
      @state.brand,
      @state.market,
      @state.name,
      @state.channel
    ], @isElementValid) && @validPlacements() && @validPublishers()

  trackSubmitButtonClick: ->
    MixpanelTracker.trackButtonClick("Submit")

  renderAsset: (asset, idx) ->
    `<div key={idx} className='preflightFormAsset'>
      <div className='preflightFormAsset-filerow'>
        <Icon color='green' name='checkCircle'/>
        <div className='preflightFormAsset-filename'>{asset.filename || asset.title}</div>
      </div>
      <div className='preflightFormAsset-image'>
        <img alt='asset_thumbnail' src={asset.preview || asset.url}/>
      </div>
      <div className='preflightFormAsset-altText'>
        <div>{asset.altText}</div>
      </div>
    </div>`

  # TODO: Consider making this a component
  renderPreflight: (preflight, idx) =>
    if @nameCount(preflight.name) > 1
      preflight.name = "#{preflight.name} (#{preflight.nameIdx})"

    `<div key={idx} className='preflightSubmission'>
       <div className='u-flexRow u-flexAlignCenter'>
         <div className='preflightFormPreflight-name'>{preflight.name}</div>
         <div className='u-marginLeft'>{preflight.market}</div>
         <div className='u-marginLeft'>{preflight.brand}</div>
         <div className='u-marginLeft'>{this.labelForChannel(preflight.channel)}</div>
       </div>
       <div className='u-flexRow u-flexAlignCenter u-flexWrap'>
         {preflight.assets.map(this.renderAsset)}
       </div>
     </div>`

  renderFileDrop: ->
    `<FileDrop
       isPresignedUrl={true}
       height={600}
       onSuccess={this.uploadSuccess}
       onNetworkError={this.onNetworkError}
       onValidationError={this.onValidationError}
       postUrl={this.props.postUrl}
       maxVideoDuration={MAX_VIDEO_DURATION}
       note={DASHBOARD_NOTE_COPY}
     />`

  # TODO: Duplicate code.. Just set the props in the if/else
  renderNotification: =>
    rightEl=
      `<span onClick={this.removeNotification}>
         <Icon color='white' name='x'/>
       </span>`

    if @state.showSuccessNotification
      leftEl = `<Icon color='white' name='checkCircle'/>`
      `<Alert className='preflightFormNotification' success leftEl={leftEl} rightEl={rightEl}>
         Pre-flight submitted - Results will be ready in 24 hours or less.
       </Alert>`
    else
      `<Alert className='preflightFormNotification' danger rightEl={rightEl}>
         {this.state.errorMessage}
       </Alert>`

  renderMenu: =>
    if !_.isEmpty(@state.uploadedAssets)
      buttonProps =
        hideArrows: true

      `<Dropdown
        buttonProps={buttonProps}
        buttonText='...'
        className='preflightFormDropdown'
        menuPosition='bottom-left'
      >
         <DropdownItem onClick={this.selectAllAssets}>Select All</DropdownItem>
         <DropdownItem onClick={this.deselectAllAssets}>Deselect All</DropdownItem>
       </Dropdown>`

  renderContentTypes: =>
    options = [
      {
        label: "Brand (Non-Influencer Content)"
        value: "brand"
      },
      {
        label: "Creator (Influencer Content)"
        value: "creator"
      }
    ]
    
    activeOption = _.findWhere(options, { value: @state.contentType })

    `<div className='u-marginAboveMd'>
      <label>Content Type</label> <Tooltip label={'Creator Content is defined as content intended to be run through ' +
                                                    'a creator\'s profile or account. For In-Flight ' +
                                                    'content, this includes Spark Ads on TikTok' +
                                                    'and Partnership Ads on Meta.'} />
      <div className='u-marginAboveSm'>
        <RadioGroup
          name='dimension'
          options={options}
          value={activeOption.value}
          onChange={this.onContentTypeChange}
        />
      </div>
     </div>
    `

  updateView: =>
    @setState((prevState) -> {
      selectedAssets: [],
      uploadByFile: !prevState.uploadByFile,
      uploadedAssets: [],
    })

  renderHeader: =>
    if @props.uploadByUrl.allowed
      content = if @state.uploadByFile then "Upload by Youtube URL" else "Upload by file"
      viewToggle =
        `<a className='preflightViewToggle' onClick={this.updateView}>
          { content }
         </a>`

    if @state.showSuccessNotification || @state.showErrorNotification
      `<WidgetHeader>
        {this.renderNotification()}
       </WidgetHeader>`
    else if @state.uploadByFile
      `<WidgetHeader>
         <h5>Your Creatives</h5>
         <div>
           { viewToggle }
           { this.renderMenu() }
         </div>
       </WidgetHeader>`
    else
      `<WidgetHeader>
        <h5>Upload YouTube URLs</h5>
        { viewToggle }
      </WidgetHeader>`

  renderCampaignObjectiveSelect: =>
    return unless @props.campaignObjectiveOptions

    options = @optionsForCurrentChannel(@props.campaignObjectiveOptions)

    # Default option for supported channels is Unknown. Adding it here instead of in the
    #   CampaignObjectivePresenter b/c that is used elsewhere where we don't want/need
    #   this Unknown option
    if options.length > 0 && CHANNELS_WITHOUT_UNKNOWN_SUPPORT.includes(@state.channel)
      options = options.concat({ channel: '', label: 'Unknown', value: 'UNKNOWN' })

    activeOption = _.findWhere(options, { value: @state.campaignObjective })
    activeOption = @truncateDisplayedValue(activeOption) if activeOption

    # disable dropdown for unsupported channels
    buttonProps =
      disabled: options.length <= 1

    `<FormGroup>
       <label>Campaign Objective</label>
       <Select
         activeOption={activeOption}
         block
         buttonProps={buttonProps}
         onOptionClick={this.onCampaignObjectiveChange}
         options={options}
         placeholder='Campaign Objective'
       />
     </FormGroup>`

  renderPublisherSelect: =>
    return unless @props.publisherOptions && CHANNELS_WITH_PUBLISHER.includes(@state.channel)

    options = @optionsForCurrentChannel(@props.publisherOptions)

    activeOption = _.findWhere(options, { value: @state.publisher })
    activeOption = @truncateDisplayedValue(activeOption) if activeOption

    # disable dropdown for unsupported channels
    buttonProps =
      disabled: options.length < 1

    `<FormGroup>
      <label>Publisher</label>
      <Select
        activeOption={activeOption || {}}
        block
        buttonProps={buttonProps}
        onOptionClick={this.onPublisherChange}
        options={options}
        placeholder='Publisher'
      />
    </FormGroup>`

  renderPlacementSelect: =>
    return unless @props.placementOptions

    options = @optionsForCurrentChannel(@props.placementOptions)
    activeOptions = _.filter(options, (option) => @state.placements.includes(option.value))

    if activeOptions.length > 0
      activeOption = @truncateDisplayedValue(activeOptions[0], activeOptions.length > 1)
      # Re-assign copied object to be the first element so it displays correctly
      activeOptions[0] = activeOption

    # disable dropdown for unsupported channels without placement options
    buttonProps =
      disabled: options.length <= 1

    `<FormGroup>
       <Select
         activeOption={activeOptions}
         block
         buttonProps={buttonProps}
         multiple
         onOptionClick={this.onPlacementChange}
         options={options}
         placeholder='Placement(s)'
       />
     </FormGroup>`

  renderLanguageSelect: (languages) =>
    return unless languages

    labels = new Intl.DisplayNames(['en'], { type: 'language' })
    options = languages.map((value) -> ({ label: labels.of(value), value }))
    options.sort((a, b) -> a.label.localeCompare(b.label))

    activeLanguage = @state.language || 'en'
    activeOption = options.find((option) -> option.value == activeLanguage)

    `<FormGroup>
       <label>Scorecard Language</label>
       <Select
         activeOption={activeOption}
         block
         name='language'
         onOptionClick={this.onSelectChange}
         options={options}
       />
     </FormGroup>`

  renderSelect: (label, options, name) =>
    if options.length <= 1
      buttonProps =
        disabled: name is 'channel'


    activeOption = _.findWhere(options, { value: @state[name] })
    activeOption = @truncateDisplayedValue(activeOption) if activeOption

    `<FormGroup>
       <SearchableSelect
         activeOption={activeOption}
         buttonProps={buttonProps}
         block
         name={name}
         onOptionClick={this.onSelectChange}
         options={options}
         placeholder={label}
       />
     </FormGroup>`

  renderButtons: ->
    `<FormGroup className='u-marginAboveLg'>
       <Button
         Component='input'
         block
         className='u-marginBelow'
         disabled={!this.isFormValid()}
         primary
         type='submit'
         value='Submit'
         onClick={this.trackSubmitButtonClick}
       />
       <Button block Component='a' href={this.props.exitSessionUrl}>
         Exit Session
       </Button>
     </FormGroup>`

  renderInputs: ->
    rulesLink = `<a href={this.props.rulesUrl}>rules</a>`

    `<form id={FORM_ID} onSubmit={this.createPreflight}>
      <FormGroupContainer>
        <p className='u-textUppercase'>New Preflight</p>
        <p>
          {"Ensure that your creatives meet your organization's "}
          {rulesLink}
          {" for excellence."}
        </p>
        <p>
          After uploading, click on the creatives you'd like to submit,
          and categorize them in their appropriate brand, market,
          and channel.
        </p>
        <FormGroup className='u-marginAboveLg u-marginBelowLg'>
          <TextField
            placeholder='Submission Name'
            name='name'
            onChange={this.onNameChange}
          />
        </FormGroup>

        <label>Criteria</label>
        {this.renderSelect('Brand', this.brandOptions, 'brand')}
        {this.renderSelect('Market', this.marketOptions, 'market')}
        {this.renderSelect('Channel', this.channelOptions, 'channel')}
        {this.renderPlacementSelect()}
        {this.renderCampaignObjectiveSelect()}
        {this.renderPublisherSelect()}
        {this.renderLanguageSelect(this.languages)}
        {this.renderContentTypes()}
        {this.renderButtons()}
      </FormGroupContainer>
    </form>`

  render: ->
    assets = @state.uploadedAssets
    altTextEnabled = @props.altTextEnabled
    uploadByFile = @state.uploadByFile

    `<div className="preflightForm">
       <NavPanel className='preflightFormInputs'>
         <WidgetContent>
           {this.renderInputs()}
         </WidgetContent>
       </NavPanel>
       <div className='preflightFileDrop'>
         <Widget>
           { this.renderHeader() }
           { uploadByFile && this.renderFileDrop() }
           { !uploadByFile &&
             <PreflightUrlUploader
               selectedAssets={this.state.selectedAssets}
               postUrl={this.props.uploadByUrl.postUrl}
               uploadSuccess={this.urlUploadSuccess}
               onAssetClick={this.onAssetClick}
               maxVideoDuration={MAX_VIDEO_DURATION}
               note={DASHBOARD_NOTE_COPY}
             />
           }
         </Widget>
         { this.preflightsCreated() &&
            <Widget>
              <WidgetContent className='u-flexColumn preflightSubmissions'>
                {this.state.preflights.map(this.renderPreflight)}
              </WidgetContent>
            </Widget>
         }
       </div>
      { altTextEnabled && (
              <AltTextPanel
                assets={assets}
                onTextChange={this.onAltTextChange}
              />
        )}
     </div>`

export { propTypes }
export default PreflightForm
