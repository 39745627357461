import React from 'react';
import PropTypes from 'prop-types';
import SelectableTable, { propTypes as tableProps } from 'components/organisms/SelectableTable';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import ArrayHelper from 'components/utils/ArrayHelper';

const propTypes = {
  addMixpanelData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  tables: PropTypes.arrayOf(
    PropTypes.shape({
      businessCenter: PropTypes.shape({
        apiId: PropTypes.string,
        name: PropTypes.string,
      }),
      rows: tableProps.rows,
    }),
  ).isRequired,
  onFormAction: PropTypes.func.isRequired,
};

class AdAccountSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allSelected: [],
      selected: [],
    };
  }

  onDataChange() {
    const { addMixpanelData, onFormAction } = this.props;
    const { selected } = this.state;
    const numAdAccounts = selected.length;
    onFormAction(numAdAccounts > 0);
    addMixpanelData({ totalAdAccounts: numAdAccounts });
  }

  onAllSelect(businessCenterApiId) {
    this.setState((prevState) => {
      const allSelected = ArrayHelper.withOrWithoutValue(
        prevState.allSelected,
        businessCenterApiId,
      );

      const tableValues = this.tableDataForBizCenter(businessCenterApiId);

      let updatedValues = prevState.selected;

      if (allSelected.includes(businessCenterApiId)) {
        // Add table rows to list of selected
        tableValues.forEach((row) => {
          const isSelected = ArrayHelper.exactObjectInArray(updatedValues, row);
          // Add if not there already
          if (!isSelected) {
            updatedValues.push(row);
          }
        });
      } else {
        // Remove table rows from list of selected
        tableValues.forEach((row) => {
          updatedValues = updatedValues.filter((elm) => (
            // Filter out ones that match
            !Object.keys(elm).every((key) => elm[key] === row[key])
          ));
        });
      }

      return {
        allSelected,
        selected: updatedValues,
      };
    }, () => this.onDataChange());
  }

  onRowSelect(businessCenterApiId, data) {
    this.setState((prevState) => {
      const updatedValues = ArrayHelper.withOrWithoutExactObjectFromArray(prevState.selected, data);

      // Check if all business center api ids are now selected
      const tableValues = this.tableDataForBizCenter(businessCenterApiId);
      const valuesForBizCenter = updatedValues.filter((elm) => (
        elm.businessCenterApiId === businessCenterApiId
      ));

      let { allSelected } = prevState;

      if (tableValues.length === valuesForBizCenter.length) {
        allSelected.push(businessCenterApiId);
      } else if (allSelected.includes(businessCenterApiId)) {
        allSelected = ArrayHelper.removeFromArray(allSelected, businessCenterApiId);
      }

      return {
        allSelected,
        selected: updatedValues,
      };
    }, () => this.onDataChange());
  }

  tableDataForBizCenter(businessCenterApiId) {
    const { tables } = this.props;
    const { rows } = tables.find(({ businessCenter }) => (
      businessCenter.apiId === businessCenterApiId
    ));
    return rows.filter(({ disabledSelected }) => !disabledSelected).map(({ data }) => data);
  }

  rowSelected(apiId) {
    const { selected } = this.state;
    return selected.filter((data) => data.businessCenterApiId === apiId);
  }

  render() {
    const {
      formData: {
        id,
        url,
      },
      headers,
      tables,
    } = this.props;

    const { selected: stateSelected } = this.state;

    const tableSubtitle = (rows) => {
      if (rows.some(({ disabledSelected }) => disabledSelected)) {
        return ('Pre-selected Ad Accounts have already been connected.');
      }

      return undefined;
    };

    return (
      <>
        {
          tables.map(({ businessCenter, rows }) => (
            <SelectableTable
              key={businessCenter.apiId}
              className="businessCenterTable"
              headers={headers}
              rows={rows}
              selected={this.rowSelected(businessCenter.apiId)}
              subtitle={tableSubtitle(rows)}
              title={businessCenter.name}
              selectAll
              onAllSelect={() => this.onAllSelect(businessCenter.apiId)}
              onRowSelect={(rowData) => this.onRowSelect(businessCenter.apiId, rowData)}
            />
          ))
        }
        <form action={url} id={id} method="post">
          <AuthenticityTokenInput />
          {
            stateSelected.map(({
              businessCenterApiId, name, value,
            }) => (
              <React.Fragment key={`${businessCenterApiId}_${value}`}>
                <HiddenInput
                  name="business_centers[][api_id]"
                  value={businessCenterApiId}
                />
                <HiddenInput
                  name="business_centers[]ad_accounts[][api_id]"
                  value={value}
                />
                <HiddenInput
                  name="business_centers[]ad_accounts[][name]"
                  value={name}
                />
              </React.Fragment>
            ))
          }
        </form>
      </>
    );
  }
}

AdAccountSelection.propTypes = propTypes;

export default AdAccountSelection;
