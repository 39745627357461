import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

# This component is a wrapper for a form text input
class TextInput extends React.Component
  @propTypes =
    disabled: PropTypes.bool
    inputName: PropTypes.string.isRequired
    label: PropTypes.string
    onChange: PropTypes.func
    placeholder: PropTypes.string
    required: PropTypes.bool
    type: PropTypes.oneOf(['number', 'text', 'textarea'])
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string
    ])

  @defaultProps =
    disabled: false
    required: false
    type: 'text'

  classNames: ->
    classNames(
      @props.className,
      'input-field'
    )

  handleChange: (event) =>
    @props.onChange(event.target.value)

  isDisabled: ->
    if @props.disabled then 'disabled' else ''

  renderInput: ->
    inputProps =
      disabled: @isDisabled()
      id: @props.id
      name: @props.inputName
      onChange: @handleChange
      placeholder: @props.placeholder
      required: @props.required
      value: @props.value

    if @props.type is 'text'
      `<input type='text' {...inputProps} />`
    else if @props.type is 'number'
      `<input type="number" min="0" {...inputProps} />`
    else
      `<textarea className="materialize-textarea" {...inputProps} />`

  render: ->
    `<div className={this.classNames()}>
      {this.renderInput()}
      <label htmlFor={this.props.id} className="active">{this.props.label}</label>
    </div>`

export default TextInput
