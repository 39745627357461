import React from "react"
import Column from "components/reusable/column"
import PropTypes from "prop-types"
import CustomComparisonCard from "components/comparison/custom_comparison_card"
import DefaultComparisonCard from "components/comparison/default_comparison_card"
import Row from "components/reusable/row"

# Displays cards for custom comparisons
class ComparisonCardCollection extends React.Component
  @propTypes =
    # Label for header
    label: PropTypes.string
    # Array of post group comparison objects
    postGroupCollection: PropTypes.array.isRequired
    # Type of comparison collection
    type: PropTypes.oneOf(['defaultComparisonCollection', 'customComparisonCollection']).isRequired

  renderComparisonCard: (comparisonCardProps) =>
    key = "comparisonCard_#{comparisonCardProps.id}"
    if @props.type is 'defaultComparisonCollection'
      `<DefaultComparisonCard key={key} type='custom' {...comparisonCardProps} />`
    else
      `<CustomComparisonCard key={key} type='custom' {...comparisonCardProps} />`

  render: ->
    content =
      if @props.postGroupCollection.length > 0
        `<div className='comparisonCardCollection'>
          {this.props.postGroupCollection.map(this.renderComparisonCard)}
        </div>`
      else
        `<p>No custom comparisons created yet, choose from one of the defaults above to get started!</p>`

    `<Row>
      <Column>
        <h5>{this.props.label}</h5>
      </Column>
      <Column>
        {content}
      </Column>
    </Row>`

export default ComparisonCardCollection
