import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Icon,
  RadioGroup,
  Table,
  TextArea,
  TextInput,
  Tooltip,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import styles from './Form.module.css';

const customIdpKey = 'custom';

const defaultCustomAttributes = [
  {
    cxKey: 'email',
    idpKey: '',
  },
  {
    cxKey: 'first_name',
    idpKey: '',
  },
  {
    cxKey: 'last_name',
    idpKey: '',
  },
];
const tableHeaders = [
  { value: 'Identity Provider Key' },
  { value: '' },
  { value: 'CreativeX Key' },
];
const rightArrowIcon = <Icon name="arrowRight" />;
const formName = 'sso_configuration_form';

const propTypes = {
  defaultAttributes: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        cxKey: PropTypes.string,
        idpKey: PropTypes.string,
      }),
    ),
  ).isRequired,
  formUrl: PropTypes.string.isRequired,
  idpOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  method: PropTypes.oneOf(['patch', 'post']),
  ssoConfig: PropTypes.shape({
    certificate: PropTypes.string,
    customAttributes: PropTypes.arrayOf(
      PropTypes.shape({
        cxKey: PropTypes.string,
        idpKey: PropTypes.string,
      }),
    ),
    entityId: PropTypes.string,
    idp: PropTypes.string,
    targetUrl: PropTypes.string,
    urlDisplayName: PropTypes.string,
  }),
};

const defaultProps = {
  method: 'post',
  ssoConfig: {
    certificate: '',
    customAttributes: [],
    entityId: '',
    idp: 'azure',
    targetUrl: '',
    urlDisplayName: '',
  },
};

function renderDefaultAttributes(attributes, idp) {
  if (idp === customIdpKey) return null;

  const rows = attributes[idp].map(({ cxKey, idpKey }) => ({
    cells: [
      { value: idpKey },
      { value: rightArrowIcon },
      { value: cxKey },
    ],
    key: idpKey,
  }));

  return (
    <div className={styles.defaultAttributes}>
      <div className="t-subtitle">Attributes</div>
      <Divider />
      <Table
        className={styles.table}
        headers={tableHeaders}
        rows={rows}
      />
    </div>
  );
}

function renderAttributes(attributes, setAttributes, idp, isError, setIsError) {
  if (idp !== customIdpKey) return null;
  const idpKeys = attributes.map(({ idpKey }) => idpKey);

  const rows = attributes.map(({ cxKey, idpKey }, idx) => {
    const onChange = (newAttribute) => {
      setIsError(false);
      setAttributes([
        ...attributes.slice(0, idx),
        {
          ...attributes[idx],
          idpKey: newAttribute,
        },
        ...attributes.slice(idx + 1),
      ]);
    };

    let isDuplicate = false;
    if (isError) {
      isDuplicate = idpKeys.filter((key) => key !== '' && key === idpKey).length > 1;
    }
    const idpInput = (
      <TextInput
        isError={isDuplicate}
        size="large"
        value={idpKey}
        onChange={onChange}
      />
    );

    return {
      cells: [
        { value: idpInput },
        { value: rightArrowIcon },
        { value: cxKey },
      ],
      key: cxKey,
    };
  });

  return (
    <div className={styles.customAttributes}>
      <div className="t-subtitle">Attributes</div>
      <Divider />
      <Table
        className={styles.table}
        headers={tableHeaders}
        rows={rows}
      />
    </div>
  );
}

function renderCustomAttributeHiddenInputs(attributes, idp) {
  let customAttributes;

  if (idp !== customIdpKey) {
    customAttributes = null;
  } else {
    const customAttributesObject = attributes.reduce((attrs, { cxKey, idpKey }) => ({
      ...attrs,
      [idpKey]: cxKey,
    }), {});
    customAttributes = JSON.stringify(customAttributesObject);
  }

  return <HiddenInput name="custom_idp_attributes" value={customAttributes} />;
}

function Form({
  defaultAttributes,
  formUrl,
  idpOptions,
  method,
  ssoConfig: {
    certificate: initialCertificate,
    customAttributes: initialAttributes,
    entityId: initialEntityId,
    idp: initialIdp,
    targetUrl: initialTargetUrl,
    urlDisplayName: initialUrlDisplayName,
  },
}) {
  const attributes = initialAttributes.length > 0 ? initialAttributes : defaultCustomAttributes;
  const [certificate, setCertificate] = useState(initialCertificate);
  const [customAttributes, setCustomAttributes] = useState(attributes);
  const [entityId, setEntityId] = useState(initialEntityId);
  const [targetUrl, setTargetUrl] = useState(initialTargetUrl);
  const [urlDisplayName, setUrlDisplayName] = useState(initialUrlDisplayName);
  const [idp, setIdp] = useState(initialIdp);
  const [isError, setIsError] = useState(false);

  const urlDisplayLabel = (
    <div className={styles.label}>
      URL Display Name
      <Tooltip
        direction="right"
        label="This is the name we pass into the url to specify the company (usually company name in all lowercase). Example: A url ending with 'login_org=creativex' uses the url display name 'creativex'"
      />
    </div>
  );

  const onRadioChange = (event) => {
    setIsError(false);
    setIdp(event.target.value);
  };

  const isDisabled = () => {
    const isInputEmpty = [certificate, entityId, targetUrl, urlDisplayName].some((option) => option === '');

    if (idp !== customIdpKey) return isInputEmpty || isError;

    const isCustomAttributeEmpty = customAttributes.some(({ idpKey }) => idpKey === '');

    return isInputEmpty || isError || isCustomAttributeEmpty;
  };

  const onSave = () => {
    if (idp === customIdpKey) {
      const idpKeys = customAttributes.map(({ idpKey }) => idpKey);
      const uniqueKeys = [...new Set(idpKeys)];

      if (idpKeys.length !== uniqueKeys.length) {
        addToast('Identity Provider keys must be unique!', {
          size: 'large',
          type: 'error',
        });
        setIsError(true);

        return;
      }
    }

    document[formName].submit();
  };

  return (
    <Card>
      <div className="u-flexRow">
        <div className={styles.inputs}>
          <TextInput
            label={urlDisplayLabel}
            size="large"
            value={urlDisplayName}
            onChange={setUrlDisplayName}
          />
          <TextInput
            label="Entity ID"
            size="large"
            value={entityId}
            onChange={setEntityId}
          />
          <TextInput
            label="Target Url"
            size="large"
            value={targetUrl}
            onChange={setTargetUrl}
          />
          <TextArea
            label="Certificate"
            size="large"
            value={certificate}
            onChange={setCertificate}
          />
        </div>
        <div className={styles.divider}>
          <Divider vertical />
        </div>
        <div className={styles.attributes}>
          <div className={styles.radio}>
            <RadioGroup
              label="Identity Provider"
              name="idp_selection"
              options={idpOptions}
              value={idp}
              onChange={onRadioChange}
            />
          </div>
          { renderDefaultAttributes(defaultAttributes, idp) }
          { renderAttributes(customAttributes, setCustomAttributes, idp, isError, setIsError) }
        </div>
      </div>
      <div className={styles.save}>
        <form action={formUrl} method="post" name={formName}>
          <HiddenInput name="_method" value={method} />
          <AuthenticityTokenInput />
          <HiddenInput name="org_url_display_name" value={urlDisplayName} />
          <HiddenInput name="entity_id" value={entityId} />
          <HiddenInput name="target_url" value={targetUrl} />
          <HiddenInput name="certificate" value={certificate} />
          <HiddenInput name="idp_name" value={idp} />
          { renderCustomAttributeHiddenInputs(customAttributes, idp) }
          <Button
            disabled={isDisabled()}
            label="Save"
            onClick={onSave}
          />
        </form>
      </div>
    </Card>
  );
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
