import React from "react"
import PropTypes from "prop-types"
import Card from "components/reusable/card"
import Pills from "components/reusable/Pills"
import { ModalLaunchButton } from "components/containers/miscellaneous_containers"
import { Checkbox, Select } from  "@duik/it"

# This component sets up options we can use with the interactice plotly chart.
class InteractivePlotlyChartOptions extends React.Component

  @propTypes =
    chartType: PropTypes.shape(
      onChange: PropTypes.func
      selected: PropTypes.string
      types: PropTypes.arrayOf(PropTypes.string)
    )
    isLoading: PropTypes.bool
    plotId: PropTypes.string
    primaryMetric: PropTypes.shape(
      label: PropTypes.string
      onChange: PropTypes.func
      options: PropTypes.array
      selected: PropTypes.string
    )
    secondaryMetric: PropTypes.shape(
      label: PropTypes.string
      onChange: PropTypes.func
      options: PropTypes.array
      selected: PropTypes.string
    )
    traceOptions: PropTypes.shape(
      label: PropTypes.string
      onChange: PropTypes.func
      options: PropTypes.array
      selected: PropTypes.array
    )

  chartIconOption: (chartType) ->
    # Future TODO: Use Dale's SVG icon for boxplot + scatterplot
    [icon, title] =
      switch chartType
        when 'bar' then ['bar-chart', 'Bar Chart']
        when 'line', 'spline' then ['line-chart', 'Line Chart']
        when 'boxplot' then ['square', 'Boxplot Chart']
    {
      icon: { icon: icon },
      title: title,
      value: chartType
    }

  renderChartTypeOptions: =>
    chartTypes = @props.chartType.types
    return `<div/>` unless chartTypes && chartTypes.length > 1

    `<div className='interactivePlotlyChart-chartOptions'>
      <label>Chart Type:</label>
      <Pills selected={this.props.chartType.selected}
             onChange={this.props.chartType.onChange}
             options={chartTypes.map(this.chartIconOption)}
      />
    </div>`

  renderMetricSelection: (label, metricGroup) =>
    return null unless @props[metricGroup]

    selectorProps = @props[metricGroup]
    options = selectorProps.options
    activeOption = _.findWhere(options, value: selectorProps.selected)

    selectorCallback = (option) ->
      selectorProps.onChange(option.value)

    selectProps =
      activeOption: activeOption
      label: label
      onOptionClick: selectorCallback
      options: options

    `<Select {...selectProps}/>`


  renderTraceOption: (traceOption) =>
    key = "filter_" + @props.plotId + '_' + traceOption.value
    callback = => @props.traceOptions.onChange(traceOption.value)

    `<Checkbox
      checked={traceOption.selected}
      disabled={this.props.isLoading}
      id={key}
      key={key}
      label={traceOption.label}
      onChange={callback}
      value={traceOption.value}
    />`

  # TODO: This is functionality duplicated a few places (see also
  # AuditSegmentationDimensionSelect)
  renderTraceOptionModal: =>
    selected = []
    available = []
    options = _.sortBy(@props.traceOptions.options, (option) -> option.label)
    for option in options
      if @props.traceOptions.selected.indexOf(option.value) >= 0
        selected.push(_.extend({ selected: true }, option))
      else
        available.push(option)

    `<div className='row'>
      <div className='col s6'>
        <Card header={'Selected ' + this.props.traceOptions.label}>
          <div className='u-flexColumn'>
            {selected.map(this.renderTraceOption)}
          </div>
        </Card>
      </div>
      <div className='col s6'>
        <Card header={'Add ' + this.props.traceOptions.label}>
          <div className='u-flexColumn'>
            {available.map(this.renderTraceOption)}
          </div>
        </Card>
      </div>
    </div>`

  renderTraceOptions: =>
    return null unless @props.traceOptions

    modalId = "trace_selection_modal" + @props.plotId
    modalProps = {
      contentProps: {
        'data-target': modalId
        children: @props.traceOptions.selected.length + " Selected (Edit)"
        className: 'btn btn-primary'
      }
      launchType: "click"
      modal: {
        children: @renderTraceOptionModal()
        id: modalId
      }
    }

    `<div className='interactivePlotlyChart-dataOption'>
      <label>{this.props.traceOptions.label}:</label>
      <ModalLaunchButton {...modalProps} />
    </div>`

  render: ->
    primaryMetric = this.props.primaryMetric.label if this.props.primaryMetric
    secondaryMetric = this.props.secondaryMetric.label if this.props.secondaryMetric

    `<div className='interactivePlotlyChart-options'>
      <div className='u-marginAbove interactivePlotlyChart-dataOptions'>
        {this.renderMetricSelection(primaryMetric, 'primaryMetric')}
        {this.renderMetricSelection(secondaryMetric, 'secondaryMetric')}
        {this.renderTraceOptions()}
      </div>
      {this.renderChartTypeOptions()}
    </div>`

export default InteractivePlotlyChartOptions
