import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import Tiers from 'components/rules/tiers/Tiers';
import { tierCollectionDataTierPath } from 'utilities/routes';
import styles from './StandardTiers.module.css';

const propTypes = {
  scoreId: PropTypes.number.isRequired,
};

function StandardTiers({ scoreId }) {
  const [tierData, setTierData] = useState();

  useEffect(() => {
    async function fetchProps() {
      const response = await fetch(tierCollectionDataTierPath(scoreId));

      if (response.ok) {
        const data = await response.json();

        setTierData(data);
      } else {
        addToast('Something went wrong, please reload to try again.', {
          size: 'large',
          type: 'error',
        });
      }
    }

    fetchProps();
  }, [scoreId]);

  if (!tierData) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  const {
    tierCollection,
    tiers,
  } = tierData;

  return (
    <Tiers
      editable={false}
      tierCollection={tierCollection}
      tiers={tiers}
    />
  );
}

StandardTiers.propTypes = propTypes;

export default StandardTiers;
