import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

# Uses http://materializecss.com/preloader.html linear determinate progress bar.
class HorizontalGauge extends React.Component
  @propTypes =
    color: PropTypes.string   # Fill color. Default is blue.
    percent: PropTypes.number # Percent (0-100) the gauge should be filled
    rate: PropTypes.number    # Decimel percent (0-1) the gauge should be filled
    useDefaultStyles: PropTypes.bool # set to false if you have custom css skillz

  @defaultProps =
    useDefaultStyles: true

  classes: ->
    classNames(
      'horizontalGauge': @props.useDefaultStyles,
      'progress'
    )

  render: ->
    percentFilled =
      if @props.rate
        @props.rate * 100
      else
        @props.percent

    gaugeStyle =
      width: percentFilled + "%"

    if @props.useDefaultStyles
      gaugeStyle['backgroundColor'] = @props.color || ColorUtil.color('blue')

    `<div className={this.classes()}>
      <div className="determinate horizontalGauge-bar" style={gaugeStyle}/>
    </div>`

export default HorizontalGauge
