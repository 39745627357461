// Helper functions for arrays
import { getItemSortBy } from 'utilities/item';

// Return the next index in an array
// If there is no next item, return 0
export function getNextIndex(index, length) {
  const next = index + 1;

  if (next < length) {
    return next;
  }
  return (length > 1) ? 0 : -1;
}

// Returns the values of an array of objects
export function objectValues(objs) {
  return objs.map(({ value }) => value);
}

export function findObjectByValue(objects, value) {
  return (objects ?? []).find((obj) => obj.value === value);
}

export function findObjectsByValue(objects, values) {
  return values.reduce((arr, value) => {
    const found = findObjectByValue(objects, value);
    if (found) {
      return [...arr, found];
    }
    return arr;
  }, []);
}

// If condition is true, returns the rest of the values in an array
// If condition is false, returns an empty array
export function arrayIf(condition, ...rest) {
  if (!condition) return [];

  return [
    ...rest,
  ];
}

export function isSameByValue(arr0, arr1) {
  if (arr0.length !== arr1.length) return false;

  return arr0.every((obj, index) => obj.value === arr1[index].value);
}

// note: This is for non-object values. Use toggleObject from design system otherwise
export function toggleValue(array, value) {
  if (array.includes(value)) return array.filter((existingValue) => existingValue !== value);

  return [...array, value];
}

// Return a new array of sorted items
export function sortItemsArray(items, sortKey, sortAsc) {
  const byKeyDir = getItemSortBy(sortKey, sortAsc);

  return [...items].sort(byKeyDir);
}

// Return a new array of sorted objects
export function sortObjectArray(objects, sortKey, sortAsc = true) {
  const byKeyDir = ({ [sortKey]: a }, { [sortKey]: b }) => {
    let result = 0;

    if (typeof a === 'string' && typeof b === 'string') {
      result = a.localeCompare(b);
    } else if (a < b) {
      result = -1;
    } else if (a > b) {
      result = 1;
    }

    return sortAsc ? result : result * -1;
  };

  return [...objects].sort(byKeyDir);
}

export function appendIfUnique(arr, value) {
  if (arr.includes(value)) {
    return arr;
  }
  return [...arr, value];
}

// gets the unique values of arr1 that are not in arr2
export function getUniqueValues(arr1, arr2) {
  const set2 = new Set(arr2);
  return arr1.filter((value) => !set2.has(value));
}

export function removeByIndex(arr, index) {
  return [
    ...arr.slice(0, index),
    ...arr.slice(index + 1),
  ];
}

export function replaceAtIndex(arr, index, value) {
  return [
    ...arr.slice(0, index),
    value,
    ...arr.slice(index + 1),
  ];
}
