import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import CopyToClipboard from 'components/reusable/CopyToClipboard';
import CreativeDetail, { creativeDetailPropTypes } from 'components/reusable/CreativeDetail';
import MixpanelTracker from 'components/utils/MixpanelTracker';

export const auditCreativePropTypes = {
  creativeDetails: PropTypes.arrayOf(PropTypes.shape(creativeDetailPropTypes)).isRequired,
  isVideo: PropTypes.bool.isRequired,
  sourceUrl: PropTypes.string.isRequired,
  challengeUrl: PropTypes.string,
  externalUrl: PropTypes.string,
  overviewUrl: PropTypes.string,
  thumbnailUrl: PropTypes.string,
};

const defaultProps = {
  challengeUrl: undefined,
  externalUrl: undefined,
  overviewUrl: undefined,
  thumbnailUrl: undefined,
};

function challengeIcon(challengeUrl) {
  const disabled = !challengeUrl;
  const classes = classNames({ 'challengeIcon--disabled': disabled });

  return (
    <a
      className={classes}
      disabled={disabled}
      href={challengeUrl}
      rel="noreferrer"
      onClick={() => MixpanelTracker.trackButtonClick('Challenge')}
    >
      <Icon color="current" name="flag" />
    </a>
  );
}

function AuditCreative(props) {
  const {
    challengeUrl,
    creativeDetails,
    externalUrl,
    isVideo,
    overviewUrl,
    sourceUrl,
    thumbnailUrl,
  } = props;
  let asset;
  if (isVideo) {
    asset = <video className="asset" controls="controls" poster={thumbnailUrl} src={sourceUrl} />;
  } else {
    asset = <img alt="audited creative" className="asset" src={sourceUrl} />;
  }

  return (
    <div className="scorecard-imageContainer">
      { asset }
      <div className="scorecard-creativeDetails">
        <div className="scorecard-creativeDetails--header">
          <h4>Creative Details</h4>
          <div className="scorecard-creativeDetails--icons">
            { challengeIcon(challengeUrl) }
            <CopyToClipboard
              className="scorecard-uniqueLink"
              value={overviewUrl}
              onClick={() => MixpanelTracker.trackButtonClick('Share Scorecard')}
            />
            { externalUrl && (
              <a href={externalUrl} rel="noreferrer" target="_blank" onClick={() => MixpanelTracker.trackButtonClick('Post Link')}>
                <Icon color="current" name="externalLink" />
              </a>
            ) }
            <a
              href={sourceUrl}
              rel="noreferrer"
              target="_blank"
              onClick={() => MixpanelTracker.trackButtonClick('Download')}
            >
              <Icon color="current" name="download" />
            </a>
          </div>
        </div>
        { creativeDetails.map(({ label, value }) => {
          if (!value) return undefined;

          return <CreativeDetail key={label} className="scorecard-creativeDetail" label={label} value={value} />;
        }) }
      </div>
    </div>
  );
}

AuditCreative.propTypes = auditCreativePropTypes;
AuditCreative.defaultProps = defaultProps;

export default AuditCreative;
