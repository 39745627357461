import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@duik/it';
import { checkProps } from 'components/admin/check_evaluation/GuidelineCheckDisplay';
import CheckQueue from 'components/admin/review_queue/CheckQueue';
import { getDaysRemaining } from 'utilities/date';
import { getItemArrayFilterTest } from 'utilities/filtering';
import { setItemElement } from 'utilities/itemElement';
import {
  adminProcessControlCheckPath,
  adminProcessControlChecksPath,
  editAdminProcessControlCheckUrl,
} from 'utilities/routes';
import {
  getParams,
  setParam,
} from 'utilities/url';

const propTypes = {
  checks: PropTypes.arrayOf(
    PropTypes.shape({
      ...checkProps,
      flaggedAt: PropTypes.string.isRequired,
      brand: PropTypes.string,
      channel: PropTypes.string,
      market: PropTypes.string,
      reviewerId: PropTypes.number,
    }),
  ).isRequired,
  view: PropTypes.oneOf(['pending', 'reviewed']).isRequired,
  orgId: PropTypes.number,
  orgs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};

const defaultProps = {
  orgId: undefined,
  orgs: [],
};

const filterOptions = [
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
  {
    key: 'guideline',
    label: 'Guideline',
  },
  {
    key: 'reviewerId',
    label: 'User ID',
  },
];

function getHeaders(view) {
  const allOptions = [
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'guidelineCheckId',
      label: 'Guideline Check ID',
    },
    {
      key: 'guideline',
      label: 'Guideline',
    },
    {
      key: 'brand',
      label: 'Brand',
    },
    {
      key: 'market',
      label: 'Market',
    },
    {
      key: 'channel',
      label: 'Channel',
    },
    {
      key: 'timeRemaining',
      label: 'Time Remaining',
      view: 'pending',
    },
    {
      key: 'reviewerId',
      label: 'Latest Review By',
    },
  ];

  return allOptions.filter((header) => !header.view || header.view === view);
}

function filterItems(items, filterSelections) {
  const filterTest = getItemArrayFilterTest(filterSelections);
  return items.filter(filterTest);
}

function generateLink(item, action, view) {
  const displayContent = {};
  if (view === 'pending') {
    displayContent.button = () => action(item);
  } else {
    displayContent.url = editAdminProcessControlCheckUrl(item.id);
  }
  return {
    display: displayContent,
    value: item.id,
  };
}

function formatForModal(check) {
  return {
    ...check,
    detailsDisplay: [
      {
        label: 'Brand',
        value: check.brand ?? 'N/A',
      },
      {
        label: 'Channel',
        value: check.channel ?? 'N/A',
      },
      {
        label: 'Market',
        value: check.market ?? 'N/A',
      },
      {
        label: 'Type',
        value: check.type ?? 'N/A',
      },
      {
        label: 'Status',
        value: check.status,
      },
    ],
    headersDisplay: [
      { value: check.organization },
      {
        label: 'Audit Asset ID',
        value: check.auditAssetId,
      },
      {
        label: 'Guideline Check ID',
        value: check.checkId,
      },
    ],
  };
}

function renderOrganizationDropdown(orgs, selectedId, view) {
  const options = orgs.map((org) => ({
    label: org.name,
    value: org.id,
  }));
  const activeOption = options.find((option) => option.value === selectedId);

  const handleCompanyClick = (option) => {
    const url = adminProcessControlChecksPath({
      view,
      org_id: option.value,
    });

    window.location.href = url;
  };

  return (
    <Select
      activeOption={activeOption}
      label="Company"
      menuPosition="bottom-left"
      options={options}
      block
      onOptionClick={handleCompanyClick}
    />
  );
}

function ProcessControlQueue({
  checks,
  orgId,
  orgs,
  view,
}) {
  const params = getParams(window);

  const getItemElements = (currentChecks, onClick) => {
    const now = Date.now();

    return currentChecks.map((check) => {
      const daysRemaining = getDaysRemaining(new Date(check.flaggedAt), now);

      return setItemElement({
        id: generateLink(check, onClick, view),
        guidelineCheckId: { value: check.checkId },
        guideline: { value: check.name },
        brand: { value: check.brand ?? 'N/A' },
        market: { value: check.market ?? 'N/A' },
        channel: { value: check.channel ?? 'N/A' },
        reviewerId: { value: check.reviewerId?.toString() ?? 'N/A' },
        timeRemaining: {
          value: daysRemaining,
          text: `${daysRemaining} days`,
        },
      });
    });
  };

  useEffect(() => {
    setParam('org_id', orgId, params, window);
  }, [orgId]);

  return (
    <CheckQueue
      checkType="process control"
      checks={checks}
      editUrl={editAdminProcessControlCheckUrl}
      filterItems={filterItems}
      filterOptions={filterOptions}
      formatForModal={formatForModal}
      getItemElements={getItemElements}
      headerRight={renderOrganizationDropdown(orgs, orgId, view)}
      headers={getHeaders(view)}
      queue="processControlCheck"
      updateUrl={adminProcessControlCheckPath}
    />
  );
}

ProcessControlQueue.propTypes = propTypes;
ProcessControlQueue.defaultProps = defaultProps;

export default ProcessControlQueue;
