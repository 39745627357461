// Wrapper Component for DUIK Select
import React from 'react';
import PropTypes from 'prop-types';
import {
  Select as DuikSelect,
} from '@duik/it';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activeOption: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  block: PropTypes.bool,
  label: PropTypes.node,
  // See https://developer.dashboard-ui.com/docs/react/dropdown#menu-position
  menuPosition: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  noWrap: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  searchable: PropTypes.bool,
  onOptionClick: PropTypes.func,
};

const defaultProps = {
  activeOption: undefined,
  block: false,
  label: undefined,
  menuPosition: 'bottom-right',
  multiple: false,
  name: undefined,
  noWrap: false,
  onOptionClick: undefined,
  options: [],
  searchable: false,
};

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = { keyword: '' };
  }

  onSearch(value) {
    this.setState({ keyword: value });
  }

  onOptionClick(value, name) {
    this.setState({ keyword: '' });

    const { onOptionClick } = this.props;

    if (onOptionClick) {
      onOptionClick(value, name);
    }
  }

  render() {
    const {
      options,
      searchable,
    } = this.props;

    const { keyword } = this.state;

    let inputSearchProps;
    let filteredOptions = options;

    if (searchable) {
      inputSearchProps = {
        onChange: (event) => this.onSearch(event.target.value),
        value: keyword,
      };

      filteredOptions = options.filter((option) => (
        option.label.toLowerCase().includes(keyword.toLowerCase())
      ));
    }

    return (
      <DuikSelect
        {...this.props} // eslint-disable-line react/jsx-props-no-spreading
        inputSearchProps={inputSearchProps}
        options={filteredOptions}
        onOptionClick={
          (option, selectName) => this.onOptionClick(option, selectName)
        }
      />
    );
  }
}

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
