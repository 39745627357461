import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  BasicModal,
  Button,
  CloseButton,
  Icon,
  MultipleDropdown,
  toggleObject,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import { sortObjectArray } from 'utilities/array';
import { getAuthenticityToken } from 'utilities/requests';
import { duplicateAdminGuidelineDetailPath } from 'utilities/routes';
import styles from './DuplicateDetail.module.css';

const propTypes = {
  detail: PropTypes.shape({
    guidelineId: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number,
    isTemplate: PropTypes.bool,
  }).isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      guidelines: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

async function postDuplicateDetail(id, guidelines) {
  const token = getAuthenticityToken();
  const formData = new FormData();
  formData.append('authenticity_token', token);
  guidelines.forEach(({ value }) => formData.append('guideline_ids[]', value));

  const response = await fetch(duplicateAdminGuidelineDetailPath(id), {
    method: 'POST',
    body: formData,
  });
  return response.ok ? response.json() : { success: false };
}

function DuplicateGuidelineDetail({
  detail,
  organization: {
    id: currentOrgId,
    isTemplate,
  },
  organizations,
}) {
  const companyOptions = organizations.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  const currentCompany = companyOptions.filter(({ value }) => value === currentOrgId);
  const initialCompanies = isTemplate ? [] : currentCompany;
  const [selectedCompanies, setSelectedCompanies] = useState(initialCompanies);
  const [selectedGuidelines, setSelectedGuidelines] = useState([]);
  const [guidelineOptions, setGuidelineOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const options = organizations.reduce((list, org) => {
      if (selectedCompanies.map(({ value }) => value).includes(org.id)) {
        const guidelines = sortObjectArray(
          org.guidelines.map(({ id, name }) => ({
            label: `${name} (${id})`,
            value: id,
          })),
          'label',
          true,
        );

        if (isTemplate) {
          // Template duplication groups the guidelines
          return [
            ...list,
            {
              label: org.name,
              group: true,
            },
            ...guidelines,
          ];
        }

        return [
          ...list,
          ...guidelines,
        ];
      }

      return list;
    }, []);

    setGuidelineOptions(options);

    if (!isTemplate) {
      setSelectedGuidelines(options.filter(({ value }) => value === detail.guidelineId));
    } else {
      setSelectedGuidelines([]);
    }
  }, [selectedCompanies]);

  const handleClose = () => setIsModalOpen(false);

  const detailLabel = detail.name ? `${detail.name} (${detail.id})` : `${detail.id}`;

  const onDuplicate = async () => {
    const toastOpts = { size: 'large' };
    handleClose();

    const data = await postDuplicateDetail(detail.id, selectedGuidelines);

    if (data.success) {
      selectedGuidelines.forEach(({ label: guidelineName }) => (
        addToast(`Successfully duplicated definition ${detailLabel} to ${guidelineName}`, toastOpts)
      ));
      const currentUrl = window.location.href;
      window.location.href = currentUrl;
    } else {
      toastOpts.type = 'error';
      addToast('Issues duplicating for some/all selections', toastOpts);

      data.failures.forEach((guidelineName) => {
        addToast(`Unable to duplicate definition ${detailLabel} to ${guidelineName}`, toastOpts);
      });
    }
  };

  const footer = (
    <div className="buttonGroup">
      <Button
        label="Cancel"
        variant="tertiary"
        onClick={handleClose}
      />
      <Button
        disabled={[selectedCompanies, selectedGuidelines].some((l) => l.length === 0)}
        label="Confirm"
        onClick={onDuplicate}
      />
    </div>
  );

  // Ensure every selected company has at least one guideline selected
  const allCompaniesWithGuidelineSelection = selectedCompanies.every((c) => {
    const orgGuidelines = organizations
      .find(({ id: orgId }) => orgId === c.value)
      .guidelines;
    const selectedGuidelineIds = selectedGuidelines.map(({ value }) => value);
    return orgGuidelines.some(({ id }) => selectedGuidelineIds.includes(id));
  });
  const needsWarning = selectedGuidelines.length > 0 && !allCompaniesWithGuidelineSelection;

  return (
    <>
      <button
        className={styles.button}
        type="button"
        onClick={() => setIsModalOpen(true)}
      >
        Duplicate
      </button>
      <BasicModal
        footer={footer}
        header={<CloseButton onClick={handleClose} />}
        isOpen={isModalOpen}
        size="medium"
        onClose={handleClose}
      >
        <h5 className={styles.header}>
          { `Duplicating definition ${detailLabel}.\nSelect companies and guideline(s) to duplicate to below:` }
        </h5>
        <div className={styles.modalContent}>
          <div className={styles.selectors}>
            <MultipleDropdown
              disabled={companyOptions.length === 1}
              label="Companies"
              menuProps={{ size: 'large' }}
              options={companyOptions}
              selected={selectedCompanies}
              size="medium"
              onChange={(c) => setSelectedCompanies((last) => toggleObject(last, c))}
            />
            { selectedCompanies.length > 1 && (
              <div>
                <div className="t-caption-1">Duplicating to:</div>
                <div className="t-body-1">
                  { selectedCompanies.map(({ label }) => label).sort().join(', ') }
                </div>
              </div>
            ) }
            <MultipleDropdown
              label="Copy to Guidelines"
              menuProps={{ size: 'large' }}
              options={guidelineOptions}
              selected={selectedGuidelines}
              size="medium"
              onChange={(g) => setSelectedGuidelines((last) => toggleObject(last, g))}
            />
            { selectedGuidelines.length > 1 && (
              <div>
                <div className="t-caption-1">Duplicating to:</div>
                <div className="t-body-1">
                  { selectedGuidelines.map(({ label }) => label).sort().join(', ') }
                </div>
              </div>
            ) }
          </div>
          { needsWarning && (
            <div className={styles.warning}>
              <Icon color="orange" name="exclamationTriangle" />
              <div className="t-label-1">Warning: You haven&apos;t selected a guideline for every selected company</div>
            </div>
          ) }
        </div>
      </BasicModal>
    </>
  );
}

DuplicateGuidelineDetail.propTypes = propTypes;

export default DuplicateGuidelineDetail;
