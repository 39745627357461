import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownItem,
} from '@duik/it';

// Renders a dropdown where the component options are links that do put requests.
// Do not use this for link navigation (use NavDropdown instead).
export const propTypes = {
  buttonText: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  buttonProps: PropTypes.shape({
    lg: PropTypes.bool,
  }),
  menuPosition: PropTypes.string,
};

const defaultProps = {
  buttonProps: {},
  menuPosition: 'bottom-right',
};

function renderDropdownItem({ text, link }) {
  return (
    <DropdownItem
      key={text}
      Component="a"
      data-method="put"
      href={link}
    >
      { text }
    </DropdownItem>
  );
}

function PostRequestNavDropdown(props) {
  const {
    buttonProps,
    buttonText,
    menuPosition,
    options,
  } = props;

  return (
    <Dropdown
      buttonProps={buttonProps}
      buttonText={buttonText}
      menuPosition={menuPosition}
    >
      { options.map(renderDropdownItem) }
    </Dropdown>
  );
}

PostRequestNavDropdown.propTypes = propTypes;
PostRequestNavDropdown.defaultProps = defaultProps;

export default PostRequestNavDropdown;
