// note: Move to Design System
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  TextInput,
} from '@makeably/creativex-design-system';
import styles from './HelperTextInput.module.css';

const propTypes = {
  helperText: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['default', 'error', 'success']),
      value: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  isError: PropTypes.bool,
  label: PropTypes.node,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderIcon: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.string,
  onChange: PropTypes.func,
};

const defaultProps = {
  disabled: false,
  form: undefined,
  isError: false,
  label: undefined,
  max: undefined,
  maxLength: undefined,
  min: undefined,
  minLength: undefined,
  name: undefined,
  onChange: undefined,
  placeholder: undefined,
  placeholderIcon: undefined,
  required: false,
  size: 'small',
  type: 'text',
};

function getIcon(type) {
  if (type === 'default' || type === 'success') {
    return 'checkCircle';
  }

  return 'exclamationCircle';
}

function renderHelperText({ type, value }) {
  const classes = classNames(
    't-label-1',
    styles.row,
    {
      [styles.default]: type === 'default',
      [styles.error]: type === 'error' || !type,
      [styles.success]: type === 'success',
    },
  );

  return (
    <div key={value} className={classes}>
      <div className={styles.icon}>
        <Icon color="current" name={getIcon(type)} />
      </div>
      { value }
    </div>
  );
}

function HelperTextInput({
  disabled,
  form,
  helperText,
  isError,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  onChange,
  placeholder,
  placeholderIcon,
  required,
  size,
  type,
  value,
}) {
  return (
    <div className={styles.container}>
      <TextInput
        disabled={disabled}
        form={form}
        isError={isError}
        label={label}
        max={max}
        maxLength={maxLength}
        min={min}
        minLength={minLength}
        name={name}
        placeholder={placeholder}
        placeholderIcon={placeholderIcon}
        required={required}
        size={size}
        type={type}
        value={value}
        onChange={onChange}
      />
      { helperText.map(renderHelperText) }
    </div>
  );
}

HelperTextInput.propTypes = propTypes;
HelperTextInput.defaultProps = defaultProps;

export default HelperTextInput;
