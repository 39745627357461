/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  FormGroupContainer,
  TextField,
} from '@duik/it';
import { HiddenInput } from 'components/reusable/HiddenInput';
import EditPasswordForm from 'components/settings/users/EditPasswordForm';

const propTypes = {
  activationCode: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  rules: PropTypes.shape({
    letter: PropTypes.boolean,
    minLength: PropTypes.number,
    number: PropTypes.boolean,
    symbol: PropTypes.boolean,
  }).isRequired,
  url: PropTypes.string.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    password: PropTypes.string,
    passwordConfirmation: PropTypes.string,
  }).isRequired,
};

class Activation extends React.Component {
  constructor(props) {
    super(props);

    const { user } = props;
    user.password = '';
    user.passwordConfirmation = '';

    this.state = {
      user,
    };
  }

  onChange(prop, value) {
    this.setState((prevState) => {
      const { user } = prevState;
      user[prop] = value;
      return { user };
    });
  }

  render() {
    const {
      user,
      url,
      activationCode,
      authenticityToken,
      rules,
    } = this.props;

    return (
      <form action={url} method="post">
        <HiddenInput name="_method" value="patch" />
        <HiddenInput name="activation_code" value={activationCode} />
        <HiddenInput name="authenticity_token" value={authenticityToken} />
        <FormGroupContainer>
          <h5>Welcome to CreativeX!</h5>
          <FormGroup>
            <TextField
              defaultValue={user.email}
              label="Email"
              name="user[email]"
              type="email"
              disabled
            />
          </FormGroup>
          <FormGroupContainer horizontal>
            <FormGroup>
              <TextField
                defaultValue={user.firstName}
                label="First Name"
                name="user[first_name]"
              />
            </FormGroup>
            <FormGroup>
              <TextField
                defaultValue={user.lastName}
                label="Last Name"
                name="user[last_name]"
              />
            </FormGroup>
          </FormGroupContainer>
          <EditPasswordForm
            confirmationFormName="user[password_confirmation]"
            passwordFormName="user[password]"
            rules={rules}
          />
          <FormGroup>
            <Button
              Component="input"
              name="commit"
              type="submit"
              value="Continue"
              primary
            />
          </FormGroup>
        </FormGroupContainer>
      </form>
    );
  }
}

Activation.propTypes = propTypes;

export default Activation;
