import React from "react"

# This component is used to display a link to the previous url on static error pages
class PreviousUrl extends React.Component

  render: ->
    previousUrl = document.referrer
    currentUrl = window.location.href

    return false if !previousUrl or previousUrl == currentUrl

    `<a href={previousUrl}> Go Back </a>`

export default PreviousUrl
