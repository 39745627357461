import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Column from "components/reusable/column"
import PostAsset from "components/reusable/post_asset"
import Row from "components/reusable/row"

# This component is responsible for displaying a collection of post assets
class PostAssets extends React.Component
  @propTypes =
    allowModal: PropTypes.bool
    centered: PropTypes.bool
    horizontal: PropTypes.bool
    posts: PropTypes.array.isRequired

  @defaultProps =
    allowModal: true
    centered: true
    horizontal: false

  classes: ->
    classNames(
      'postAssets--horizontal': @props.horizontal,
      'image-grid',
      @props.className
    )

  renderPostAsset: (post) ->
    # NB: Need a uniquely generated key for when multiple posts point to same asset
    `<PostAsset
        key={_.uniqueId("post-asset")}
        post={post}
    />`

  render: ->
    centered = @props.centered && !@props.horizontal # Centered styling does not apply to horizontal view
    containerStyle = classNames('no-padding', { 'center': centered })
    `<Row styleName='no-margin'>
      <Column styleName={containerStyle}>
        <div className={this.classes()} data-allow-modal={this.props.allowModal}>
          {this.props.posts.map(this.renderPostAsset)}
        </div>
      </Column>
    </Row>`

export default PostAssets
