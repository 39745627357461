import React from 'react';
import PropTypes from 'prop-types';
import {
  ContainerVertical,
  Checkbox,
  FormGroup,
  FormGroupContainer,
  Radio,
} from '@duik/it';
import {
  Toggle,
} from '@makeably/creativex-design-system';
import { HiddenInput } from 'components/reusable/HiddenInput';
import SearchableSelect from 'components/reusable/SearchableSelect';
import InternalAdminPermissions from 'components/settings/users/AdminPermissions';
import ArrayHelper from 'components/utils/ArrayHelper';
import UserInfo from 'components/utils/content/UserInfo';
import StringHelper from 'components/utils/StringHelper';
import SelectableCardGroup from 'components/widgets/SelectableCardGroup';

const optionProps = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
);

export const inviterProps = PropTypes.shape({
  canManageLifecycle: PropTypes.bool,
  canManageTaxonomies: PropTypes.bool,
  canModifyAdmin: PropTypes.bool,
  canModifyInternalAdmin: PropTypes.bool,
  canModifyLimited: PropTypes.bool,
  canModifyStandard: PropTypes.bool,
  canViewUsageDashboard: PropTypes.bool,
  includeRestricted: PropTypes.bool,
  profile: PropTypes.string,
});

// Function vs Component use causes these props to be listed as "unused"
// They are in fact used, so will disable the linter
/* eslint-disable react/no-unused-prop-types */
export const propTypes = {
  brandOptions: optionProps.isRequired,
  brands: optionProps.isRequired,
  channelOptions: optionProps.isRequired,
  channels: optionProps.isRequired,
  companies: optionProps.isRequired,
  companyOptions: optionProps.isRequired,
  companyRestrictionType: PropTypes.oneOf(['own', 'custom']).isRequired,
  inviter: inviterProps.isRequired,
  marketOptions: optionProps.isRequired,
  markets: optionProps.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCompanyRestrictionChange: PropTypes.func.isRequired,
  onProfileChange: PropTypes.func.isRequired,
  onRestrictionOptionChange: PropTypes.func.isRequired,
  onRoleSelect: PropTypes.func.isRequired,
  company: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  isLifecycleEnabled: PropTypes.bool,
  isRepresentationEnabled: PropTypes.bool,
  profile: PropTypes.string,
};
/* eslint-enable react/no-unused-prop-types */

const defaultProps = {
  company: undefined,
  isLifecycleEnabled: false,
  isRepresentationEnabled: false,
  profile: undefined,
};

function hiddenInputs(activeOptions, name) {
  return (
    activeOptions.map(({ _label, value }) => (
      <HiddenInput
        key={value}
        name={name}
        value={value}
      />
    ))
  );
}

function renderLifecyclePermission(roles, callback) {
  const role = 'lifecycle_manage_and_report';

  return (
    <Toggle
      checked={roles.includes(role)}
      label="Can upload core assets, view/edit campaigns, and report on Creative Lifecycle"
      name={role}
      right
      onChange={callback}
    />
  );
}

function renderUsagePermission(roles, callback) {
  const role = 'view_usage_dashboard';

  return (
    <Toggle
      checked={roles.includes(role)}
      label="Can view usage dashboard"
      name={role}
      right
      onChange={callback}
    />
  );
}

function renderTaxonomyPermission(inviter, roles, callback) {
  const role = 'manage_taxonomies';

  return (inviter.canManageTaxonomies || inviter.profile === 'picasso_admin')
    && (
      <Toggle
        checked={roles.includes(role)}
        label="Can manage taxonomies"
        name={role}
        right
        onChange={callback}
      />
    );
}

const restrictionPropTypes = {
  activeOption: optionProps.isRequired,
  callback: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  options: optionProps.isRequired,
  onOptionClick: PropTypes.func.isRequired,
};

function BrandRestrictions(props) {
  const {
    activeOption,
    checked,
    callback,
    onOptionClick,
    options,
  } = props;

  const restrictionSelect = (
    <FormGroup className="u-marginLeft">
      <SearchableSelect
        activeOption={activeOption}
        name="selectedBrands"
        options={options}
        testId="brandRestriction-select"
        multiple
        onOptionClick={onOptionClick}
      />
    </FormGroup>
  );

  return (
    <ContainerVertical>
      <Toggle
        checked={checked}
        label="Restrict user's data access by brand"
        name="restricted_brand_data"
        right
        onChange={callback}
      />
      { checked && hiddenInputs(activeOption, 'user[whitelisted_brands][]') }
      { checked && restrictionSelect }
    </ContainerVertical>
  );
}

BrandRestrictions.propTypes = restrictionPropTypes;

function MarketRestrictions(props) {
  const {
    activeOption,
    checked,
    callback,
    onOptionClick,
    options,
  } = props;

  const restrictionSelect = (
    <FormGroup className="u-marginLeft">
      <SearchableSelect
        activeOption={activeOption}
        name="selectedMarkets"
        options={options}
        testId="marketRestriction-select"
        multiple
        onOptionClick={onOptionClick}
      />
    </FormGroup>
  );

  return (
    <ContainerVertical>
      <Toggle
        checked={checked}
        label="Restrict user's data access by market"
        name="restricted_market_data"
        right
        onChange={callback}
      />
      { checked && hiddenInputs(activeOption, 'user[whitelisted_markets][]') }
      { checked && restrictionSelect }
    </ContainerVertical>
  );
}

MarketRestrictions.propTypes = restrictionPropTypes;

function ChannelRestrictions(props) {
  const {
    activeOption,
    callback,
    checked,
    onOptionClick,
    options,
  } = props;

  const restrictionCheckboxes = options.map((option) => {
    const isChecked = ArrayHelper.objectInArray(activeOption, option);
    const channelCallback = (event) => {
      const { target } = event;
      const valueObj = {
        label: target.dataset.label,
        value: target.value,
      };

      return onOptionClick(valueObj, 'selectedChannels');
    };

    return (
      <FormGroup key={option.value} className="u-marginLeft u-marginAbove">
        <Checkbox
          checked={isChecked}
          data-label={option.label}
          label={option.label}
          name="user[whitelisted_audit_channels][]"
          value={option.value}
          onChange={channelCallback}
        />
      </FormGroup>
    );
  });

  return (
    <ContainerVertical>
      <Toggle
        checked={checked}
        label="Restrict user's data access by channel"
        name="restricted_audit_channel_data"
        right
        onChange={callback}
      />
      { checked && restrictionCheckboxes }
    </ContainerVertical>
  );
}

ChannelRestrictions.propTypes = restrictionPropTypes;

const companyRestrictionPropTypes = {
  company: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onCompanyRestrictionChange: PropTypes.func.isRequired,
};

function CompanyRestrictions(props) {
  const {
    activeOption,
    callback,
    checked,
    company,
    companyRestriction,
    onCompanyRestrictionChange,
    onOptionClick,
    options,
  } = props;

  const ownChecked = companyRestriction === 'own';
  const customChecked = companyRestriction !== 'own';
  const inputName = 'user[whitelisted_organization_affiliates][]';

  const companyRestrictionSelector = (
    <FormGroup className="u-marginLeft">
      <SearchableSelect
        activeOption={activeOption}
        name="selectedCompanies"
        options={options}
        testId="companyRestriction-select"
        multiple
        onOptionClick={onOptionClick}
      />
    </FormGroup>
  );

  const radioDataName = 'companyRestrictionType';
  const radioOnChange = (event) => onCompanyRestrictionChange(event.target.value);

  let orgAffiliateId;
  let ownLabel = "User's partner only";

  if (company) {
    orgAffiliateId = company.value;
    ownLabel += ` (${company.label})`;
  }

  const restrictionRadios = (
    <FormGroupContainer className="u-marginLeft u-marginAbove">
      <Radio
        aria-label={ownLabel}
        checked={ownChecked}
        data-name={radioDataName}
        label={ownLabel}
        value="own"
        onChange={radioOnChange}
      />
      <Radio
        aria-label="Custom partners"
        checked={customChecked}
        data-name={radioDataName}
        label="Custom partners"
        value="custom"
        onChange={radioOnChange}
      />
      { ownChecked && <HiddenInput name={inputName} value={orgAffiliateId} /> }
      { customChecked && hiddenInputs(activeOption, inputName) }
      { customChecked && companyRestrictionSelector }
    </FormGroupContainer>
  );

  return (
    <ContainerVertical>
      <Toggle
        checked={checked}
        label="Restrict user's data access by partner"
        name="restricted_org_affiliate_data"
        right
        onChange={callback}
      />
      { checked && restrictionRadios }
    </ContainerVertical>
  );
}

CompanyRestrictions.propTypes = {
  ...restrictionPropTypes,
  ...companyRestrictionPropTypes,
};
CompanyRestrictions.defaultProps = {
  company: undefined,
};

const limitedPermissionPropTypes = {
  brandOptions: optionProps.isRequired,
  brands: optionProps.isRequired,
  callback: PropTypes.func.isRequired,
  canManageLifecycle: PropTypes.bool.isRequired,
  companies: optionProps.isRequired,
  company: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  companyOptions: optionProps.isRequired,
  isLifecycleEnabled: PropTypes.bool.isRequired,
  marketOptions: optionProps.isRequired,
  markets: optionProps.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onOptionClick: PropTypes.func.isRequired,
};

function LimitedPermissions({
  brandOptions,
  brands,
  callback,
  canManageLifecycle,
  companies,
  company,
  companyOptions,
  isLifecycleEnabled,
  marketOptions,
  markets,
  onOptionClick,
  roles,
}) {
  const hiddenLimitedInputs = roles.map((role) => (
    <HiddenInput
      key={role}
      data-testid={role}
      name="user[roles][]"
      value={role}
    />
  ));

  const additionalCreativesRole = roles.includes('view_additional_creatives');
  let selections;

  if (additionalCreativesRole) {
    selections = (
      <FormGroupContainer className="u-marginLeft">
        <SearchableSelect
          activeOption={brands}
          label="Restrict data access by brand"
          name="selectedBrands"
          options={brandOptions}
          placeholder="All"
          testId="brandRestriction-select"
          multiple
          onOptionClick={onOptionClick}
        />
        <SearchableSelect
          activeOption={markets}
          label="Restrict data access by market"
          name="selectedMarkets"
          options={marketOptions}
          placeholder="All"
          testId="marketRestriction-select"
          multiple
          onOptionClick={onOptionClick}
        />
        <SearchableSelect
          activeOption={companies}
          label="Restrict data access by partner"
          name="selectedCompanies"
          options={companyOptions}
          placeholder="All"
          testId="companyRestriction-select"
          multiple
          onOptionClick={onOptionClick}
        />
        { hiddenInputs(brands, 'user[whitelisted_brands][]') }
        { hiddenInputs(markets, 'user[whitelisted_markets][]') }
        { hiddenInputs(companies, 'user[whitelisted_organization_affiliates][]') }
      </FormGroupContainer>
    );
  }

  return (
    <ContainerVertical className="u-marginAboveXLg">
      <div className="u-label">Permissions</div>
      { hiddenLimitedInputs }
      <FormGroupContainer>
        <Toggle
          checked={roles.includes('view_affiliate_creatives')}
          label={`Can view all pre-flight submissions from users of ${company.label}`}
          name="view_affiliate_creatives"
          right
          onChange={callback}
        />
        <Toggle
          checked={additionalCreativesRole}
          label="Allow user to see additional creatives"
          name="view_additional_creatives"
          right
          onChange={callback}
        />
        { additionalCreativesRole && selections }
        { isLifecycleEnabled && canManageLifecycle && renderLifecyclePermission(roles, callback) }
      </FormGroupContainer>
    </ContainerVertical>
  );
}

LimitedPermissions.propTypes = limitedPermissionPropTypes;

function renderStandardPermissions(props, callback) {
  const {
    brandOptions,
    brands,
    channelOptions,
    channels,
    companies,
    company,
    companyOptions,
    companyRestrictionType,
    isLifecycleEnabled,
    inviter,
    isRepresentationEnabled,
    marketOptions,
    markets,
    onCompanyRestrictionChange,
    onRestrictionOptionChange,
    orgAffiliateId,
    roles,
  } = props;

  return (
    <ContainerVertical className="u-marginAboveXLg">
      <div className="u-label">Permissions</div>
      {
        roles.map((role) => (
          <HiddenInput
            key={role}
            data-testid={role}
            name="user[roles][]"
            value={role}
          />
        ))
      }
      <FormGroupContainer>
        <BrandRestrictions
          activeOption={brands}
          callback={callback}
          checked={roles.includes('restricted_brand_data')}
          display={inviter}
          options={brandOptions}
          onOptionClick={onRestrictionOptionChange}
        />
        <ChannelRestrictions
          activeOption={channels}
          callback={callback}
          checked={roles.includes('restricted_audit_channel_data')}
          options={channelOptions}
          onOptionClick={onRestrictionOptionChange}
        />
        <CompanyRestrictions
          activeOption={companies}
          callback={callback}
          checked={roles.includes('restricted_org_affiliate_data')}
          company={company}
          companyRestriction={companyRestrictionType}
          options={companyOptions}
          orgAffiliateId={orgAffiliateId}
          onCompanyRestrictionChange={onCompanyRestrictionChange}
          onOptionClick={onRestrictionOptionChange}
        />
        <MarketRestrictions
          activeOption={markets}
          callback={callback}
          checked={roles.includes('restricted_market_data')}
          options={marketOptions}
          onOptionClick={onRestrictionOptionChange}
        />
        { isRepresentationEnabled && (
          <ContainerVertical>
            <Toggle
              checked={roles.includes('view_representation')}
              label="View Representation Dashboard"
              name="view_representation"
              right
              onChange={callback}
            />
          </ContainerVertical>
        ) }
        { isLifecycleEnabled
            && inviter.canManageLifecycle
            && renderLifecyclePermission(roles, callback) }
        { renderTaxonomyPermission(inviter, roles, callback) }
      </FormGroupContainer>
    </ContainerVertical>
  );
}

function renderAdminPermissions(props, callback) {
  const {
    inviter,
    roles,
  } = props;

  return (
    <>
      {
        roles.map((role) => (
          <HiddenInput
            key={role}
            data-testid={role}
            name="user[roles][]"
            value={role}
          />
        ))
      }
      { (inviter.canViewUsageDashboard || inviter.canManageTaxonomies || inviter.profile === 'picasso_admin')
        && (
          <ContainerVertical className="u-marginAboveXLg">
            <div className="u-label">Permissions</div>
            <FormGroupContainer>
              { (inviter.canViewUsageDashboard || inviter.profile === 'picasso_admin') && renderUsagePermission(roles, callback) }
              { renderTaxonomyPermission(inviter, roles, callback) }
            </FormGroupContainer>
          </ContainerVertical>
        ) }
    </>
  );
}

function generateCards(inviter, activeValue) {
  const cards = [];
  const bucket = 'http://picassolabs.s3.amazonaws.com/assets/user-form';
  const profiles = UserInfo.profileList(inviter);

  profiles.forEach((profile) => {
    const {
      value,
      display,
      disabled,
      icon,
    } = profile;

    const headline = display || StringHelper.titleize(value);
    const iconValue = icon || value;

    const card = {
      active: value === activeValue,
      cta: 'Set access',
      description: UserInfo.profileDescription(value),
      disabled,
      headline,
      image: {
        alt: `${iconValue} badge`,
        src: `${bucket}/account-${iconValue}.png`,
      },
      value,
    };

    cards.push(card);
  });

  return cards;
}

function PermissionsForm(props) {
  const {
    brandOptions,
    brands,
    companies,
    company,
    companyOptions,
    isLifecycleEnabled,
    inviter,
    marketOptions,
    markets,
    onProfileChange,
    onRestrictionOptionChange,
    onRoleSelect,
    profile,
    roles,
  } = props;

  const {
    canManageLifecycle,
    canModifyAdmin,
    canModifyInternalAdmin,
    canModifyLimited,
    canModifyStandard,
  } = inviter;

  const callback = (event) => onRoleSelect(event.target.name);

  const displayAdminPermissions = profile === 'admin' && canModifyAdmin;
  const displayStandardPermissions = profile === 'standard' && canModifyStandard;
  const displayLimitedPermissions = (['limited', 'connecting'].includes(profile)) && canModifyLimited;
  const isInternalAdminProfile = ['picasso_admin', 'picasso_restricted_admin'].includes(profile);
  const displayInternalAdminPermissions = isInternalAdminProfile && canModifyInternalAdmin;

  return (
    <ContainerVertical className="permissionsForm">
      <div className="u-label">Account Type</div>
      <SelectableCardGroup
        cards={generateCards(inviter, profile)}
        inputName="user[profile]"
        onClick={onProfileChange}
      />
      { displayLimitedPermissions && company && (
        <LimitedPermissions
          brandOptions={brandOptions}
          brands={brands}
          callback={callback}
          canManageLifecycle={canManageLifecycle}
          companies={companies}
          company={company}
          companyOptions={companyOptions}
          isLifecycleEnabled={isLifecycleEnabled}
          marketOptions={marketOptions}
          markets={markets}
          roles={roles}
          onOptionClick={onRestrictionOptionChange}
        />
      ) }
      { displayStandardPermissions && renderStandardPermissions(props, callback) }
      { displayAdminPermissions && renderAdminPermissions(props, callback) }
      { displayInternalAdminPermissions
          && (
          <InternalAdminPermissions
            callback={callback}
            profile={profile}
            roles={roles}
          />
          ) }
    </ContainerVertical>
  );
}

PermissionsForm.propTypes = propTypes;
PermissionsForm.defaultProps = defaultProps;

export default PermissionsForm;
