// Renders a Selectable Card Group
// Selectable Cards behave like a radio button group
// Only one is selectable at a time
import React from 'react';
import PropTypes from 'prop-types';
import SelectableCard, {
  propTypes as selectableCardProps,
} from 'components/widgets/SelectableCard';

export const propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape(selectableCardProps),
  ).isRequired,
  inputName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function SelectableCardGroup(props) {
  const {
    cards,
    inputName,
    onClick,
  } = props;

  return (
    <div className="u-flexRow u-justifyCenter" role="radiogroup">
      {
        cards.map((card) => (
          <SelectableCard
            key={card.value}
            active={card.active}
            cta={card.cta}
            description={card.description}
            disabled={card.disabled}
            headline={card.headline}
            image={card.image}
            inputName={inputName}
            value={card.value}
            onCardClick={onClick}
          />
        ))
      }
    </div>
  );
}

SelectableCardGroup.propTypes = propTypes;

export default SelectableCardGroup;
