import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from '@makeably/creativex-design-system';
import styles from './ChannelLogo.module.css';

const propTypes = {
  channel: PropTypes.string.isRequired,
  showPaid: PropTypes.bool,
  size: PropTypes.oneOf(['size-16', 'size-24', 'size-32']),
};

const defaultProps = {
  showPaid: true,
  size: undefined,
};

const logoNames = {
  adform: 'adform',
  amazon_paid: 'amazon',
  display_ads: 'tv',
  dv360: 'dv360',
  display_ads_access: 'dv360',
  facebook_brand_pages: 'facebook',
  facebook_organic: 'facebook',
  facebook_paid: 'meta',
  fashiontv_paid: 'fashionTv',
  google_adwords: 'adwords',
  instagram_organic: 'instagram',
  instagram_paid: 'instagram',
  instagram_stories: 'instagram',
  iqiyi_paid: 'iqiyi',
  mango_paid: 'mangoTv',
  my_target_paid: 'mytarget',
  pinterest: 'pinterest',
  pptv_paid: 'pptv',
  snapchat_paid: 'snapchat',
  sohu_paid: 'sohu',
  tencent_paid: 'tencent',
  tiktok_paid: 'tiktok',
  toutiao_paid: 'toutiao',
  tv_paid: 'tv',
  twitter_organic: 'twitter',
  twitter_paid: 'twitter',
  wechat_paid: 'wechat',
  weibo_paid: 'weibo',
  xiaomi_paid: 'xiaomi',
  youku_paid: 'youku',
  youtube_organic: 'youtube',
  youtube_paid: 'google',
};

function ChannelLogo({
  channel, showPaid, size,
}) {
  const logoName = logoNames[channel];
  const wrapStyle = size ? {
    height: `var(--${size})`,
    width: `var(--${size})`,
  } : {};
  const isPaid = (logoName && showPaid) ? channel.includes('paid') : false;

  return (
    <span className={styles.wrap} style={wrapStyle}>
      <Logo classname={styles.logo} name={logoName} />
      { isPaid && <span className={styles.dot} /> }
    </span>
  );
}

ChannelLogo.propTypes = propTypes;
ChannelLogo.defaultProps = defaultProps;

export default ChannelLogo;
