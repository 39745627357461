import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  ClickableTag,
  Dropdown,
} from '@makeably/creativex-design-system';
import ActivatedMedia, {
  spendAndImpressionsProps,
  totalsProps,
} from 'components/creative_lifecycle/ActivatedMedia';
import Usage, {
  coreAssetProps,
  activatedYearProps,
} from 'components/creative_lifecycle/Usage';
import {
  getFilterOptions,
  getDateOptions,
} from 'components/creative_lifecycle/utilities';
import DsTabs from 'components/molecules/DsTabs';
import ReportFilter from 'components/reporting/ReportFilter';
import { arrayIf } from 'utilities/array';
import { track } from 'utilities/mixpanel';
import { removeProperty } from 'utilities/object';
import styles from './Dashboard.module.css';

const propTypes = {
  activatedYears: PropTypes.arrayOf(activatedYearProps).isRequired,
  canViewSpend: PropTypes.bool.isRequired,
  coreAssets: PropTypes.arrayOf(coreAssetProps).isRequired,
  summaryMetrics: PropTypes.arrayOf(spendAndImpressionsProps).isRequired,
  totals: PropTypes.arrayOf(totalsProps).isRequired,
};

const segments = [
  {
    label: 'Asset Type',
    value: 'assetType',
  },
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Campaign Name',
    value: 'campaign',
  },
  {
    label: 'Core\nAsset ID',
    value: 'uuid',
  },
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'File Name',
    value: 'fileName',
  },
  {
    label: 'Market',
    value: 'market',
  },
  {
    label: 'Production Partner',
    value: 'partner',
  },
];

function getFilteredCoreAssets(assets, filters) {
  let filteredAssets = assets;
  Object.keys(filters).forEach((key) => {
    if (key !== 'channel' && key !== 'market') {
      const keyValues = new Set(filters[key].map(({ value }) => value));
      filteredAssets = filteredAssets.filter((asset) => keyValues.has(asset[key]));
    }
  });
  return filteredAssets;
}

function getFilteredPostIds(posts, year, filters) {
  let filteredPosts = posts;
  Object.keys(filters).forEach((key) => {
    if (key === 'channel' || key === 'market') {
      const keyValues = new Set(filters[key].map(({ value }) => value));
      filteredPosts = filteredPosts.filter((asset) => keyValues.has(asset[key]));
    }
  });
  if (year !== 'All Time') {
    filteredPosts = filteredPosts.filter((asset) => asset.year === year);
  }

  return new Set(filteredPosts.flatMap((asset) => asset.postIds));
}

function getFilteredData(data, filters, dateFilter) {
  let filteredData = data;
  if (dateFilter.value !== 'All Time') {
    filteredData = filteredData.filter((asset) => asset.year === dateFilter.value);
  }
  Object.keys(filters).forEach((key) => {
    const keyValues = new Set(filters[key].map(({ value }) => value));
    filteredData = filteredData.filter((asset) => !(key in asset) || keyValues.has(asset[key]));
  });
  return filteredData;
}

function Dashboard({
  coreAssets,
  activatedYears,
  summaryMetrics,
  totals,
  canViewSpend,
}) {
  const [filteredCoreAssets, setFilteredCoreAssets] = useState(coreAssets);
  const [filteredPostIds, setFilteredPostIds] = useState(new Set([]));
  const [filteredData, setFilteredData] = useState(summaryMetrics);
  const [filteredTotals, setFilteredTotals] = useState(totals);
  const [filterOpen, setFilterOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('Usage');
  const [filters, setFilters] = useState({});
  const [dateFilter, setDateFilter] = useState({
    label: 'All Time',
    value: 'All Time',
  });
  const filterOptions = useMemo(
    () => getFilterOptions(coreAssets, segments),
    [coreAssets],
  );
  const dropdownOptions = useMemo(
    () => [{
      label: 'All Time',
      value: 'All Time',
    }, ...getDateOptions(activatedYears)],
    [activatedYears],
  );

  useEffect(() => {
    setFilteredCoreAssets(getFilteredCoreAssets(coreAssets, filters));
    setFilteredPostIds(getFilteredPostIds(activatedYears, dateFilter.value, filters));
    setFilteredData(getFilteredData(summaryMetrics, filters, dateFilter));
    setFilteredTotals(getFilteredData(totals, filters, dateFilter));
  }, [filters, dateFilter]);

  const handleFilterChange = (selections, keyToRemove = '') => {
    if (keyToRemove) {
      setFilters((last) => removeProperty(last, keyToRemove));
      track('remove_filter_tag', { keyToRemove });
    } else {
      setFilters(selections);
      track('submit_dashboard_filter', { selections });
    }
  };

  const handleDateChange = (date) => {
    setDateFilter(date);

    track('change_date_period', { date });
  };

  const tabs = [
    {
      label: 'Usage',
      onClick: setCurrentTab,
    },
    ...arrayIf(canViewSpend, {
      label: 'Activated Media',
      onClick: setCurrentTab,
    }),
  ];

  const renderFilterTag = (key) => {
    const segmentLabel = segments.find(({ value }) => value === key).label;
    return (
      <div className={styles.tag}>
        <ClickableTag
          key={key}
          color="purple"
          label={`${segmentLabel} (${filters[key].length})`}
          onClick={() => setFilterOpen(true)}
          onRemove={() => handleFilterChange({}, key)}
        />
      </div>
    );
  };

  return (
    <>
      <DsTabs
        currentTab={currentTab}
        tabs={tabs}
        variant="button"
      />
      <div className="u-flexColumn u-gap-16">
        <div className="u-flexRow u-justifyBetween">
          <div className="u-flexRow u-gap-8">
            <ReportFilter
              isOpen={filterOpen}
              options={filterOptions}
              segments={segments}
              selections={filters}
              onClose={() => setFilterOpen(false)}
              onOpen={() => setFilterOpen(true)}
              onSelectionsChange={handleFilterChange}
            />
            <div className="u-flexRow u-alignCenter u-gap-8">
              { Object.keys(filters).map((key) => renderFilterTag(key)) }
            </div>
          </div>
          <Dropdown
            options={dropdownOptions}
            search={false}
            selected={dateFilter}
            onChange={handleDateChange}
          />
        </div>
        { currentTab === 'Usage' && (
        <Usage
          filteredCoreAssets={filteredCoreAssets}
          filteredPostIds={filteredPostIds}
        />
        ) }
        { currentTab === 'Activated Media' && (
        <ActivatedMedia
          filteredData={filteredData}
          filteredTotals={filteredTotals}
        />
        ) }
      </div>
    </>
  );
}

Dashboard.propTypes = propTypes;

export default Dashboard;
