import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Divider,
  TextField,
  WidgetContent,
} from '@duik/it';
import { v4 as uuidv4 } from 'uuid';
import { Icon } from '@makeably/creativex-design-system';
import { getAuthenticityToken } from 'utilities/requests';
import styles from 'components/preflight/PreflightUrlUploader.module.css';

const propTypes = {
  maxVideoDuration: PropTypes.number.isRequired,
  note: PropTypes.string.isRequired,
  postUrl: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
  uploadSuccess: PropTypes.func.isRequired,
  onAssetClick: PropTypes.func.isRequired,
};

const defaultUrlInput = {
  message: undefined,
  url: '',
  valid: true,
};

// Note: Currently only support youtube urls
function isValidUrl(url) {
  return /(http|https|www){0,1}.*youtube.com.*\//.test(url);
}

function renderEmptyState(note) {
  return (
    <div className={styles.emptyState}>
      <div className={styles.emptyGraphic} />
      <div className={`t-subtitle ${styles.emptyStatus}`}>No URLs uploaded</div>
      <div className={`t-caption-1 ${styles.emptyNote}`}>{ note }</div>
    </div>
  );
}

function renderAssetPreview({ id, preview }) {
  if (!id) return null;

  return <img alt="asset_thumbnail" src={preview} />;
}

function renderAssetLabel({
  id, title, creativeSource,
}) {
  if (id) {
    return (
      <>
        <div className={styles.assetLabel}>{ title }</div>
        <div className={styles.assetUrl}>{ creativeSource }</div>
      </>
    );
  }
  return (
    <div className={classNames('u-flexRow', styles.pendingAssetLabel)}>
      <Icon name="processing" />
      <div>Processing...</div>
    </div>
  );
}

function renderUploadedVideo(video, selected, onClick) {
  const assetClasses = classNames(
    'preflightFormAsset-image',
    styles.assetImage,
    { [styles.assetImageSelected]: selected },
  );

  return (
    <button
      key={video.uid}
      className={classNames('preflightFormAsset', styles.assetContainer)}
      type="button"
      onClick={() => onClick(video.id)}
    >
      <div className={assetClasses}>
        { renderAssetPreview(video) }
      </div>
      <div className={styles.assetLabelContainer}>
        { renderAssetLabel(video) }
      </div>
    </button>
  );
}

function renderUploadedVideos(selectedAssets, uploadedVideos, onClick) {
  const isSelected = (id) => selectedAssets.findIndex((elm) => elm.id === id) >= 0;

  return (
    <>
      <Divider margin />
      <div className="u-flexRow">
        { uploadedVideos.map((video) => renderUploadedVideo(video, isSelected(video.id), onClick)) }
      </div>
    </>
  );
}

function verifyVideoExceedsMaxDuration(asset, maxVideoDuration) {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = asset.url;

    video.onloadedmetadata = () => {
      const { duration } = video;
      if (duration > maxVideoDuration) {
        resolve(true);
      } else {
        resolve(false);
      }
    };

    video.onerror = () => {
      resolve(false);
    };
  });
}

function PreflightUrlUploader({
  onAssetClick,
  postUrl,
  selectedAssets,
  uploadSuccess,
  maxVideoDuration,
  note,
}) {
  const [urlInput, setUrlInput] = useState({ ...defaultUrlInput });
  const [uploadedVideos, setVideos] = useState([]);

  const textUpdate = (e) => {
    const url = e.target.value;
    const valid = isValidUrl(url);
    let message;

    if (!valid && url.length > 0) {
      message = 'URL format is invalid';
    }

    setUrlInput({
      valid,
      url,
      message,
    });
  };

  const submitUrl = async () => {
    let message;
    const { url } = urlInput;
    const index = uploadedVideos.findIndex((elm) => elm.creativeSource === url);

    if (index >= 0) message = 'Video already uploaded';

    setUrlInput({
      ...defaultUrlInput,
      message,
    });

    // Short circuit if there is an error messaage
    if (message) return;

    const uid = uuidv4();
    setVideos((prevVideos) => [...prevVideos, {
      uid,
      url,
    }]);

    const body = {
      preflight: {
        creative_source: url,
        uid,
      },
    };
    const fetchObj = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getAuthenticityToken(),
      },
    };

    const response = await fetch(postUrl, fetchObj);
    const data = await response.json();
    const asset = {
      ...data,
      altText: '',
    };

    const exceedsMaxDuration = await verifyVideoExceedsMaxDuration(asset, maxVideoDuration);

    setVideos((prevVideos) => {
      const newVideos = [...prevVideos];
      const newVideoIndex = newVideos.findIndex((elm) => elm.uid === asset.uid);

      if (asset.error || exceedsMaxDuration) {
        message = exceedsMaxDuration ? 'The video exceeds the 3 minute limit.' : `Unable to process video: ${asset.creativeSource}`;
        newVideos.splice(newVideoIndex, 1);
      } else {
        uploadSuccess(asset);
        newVideos.splice(newVideoIndex, 1, asset);
      }

      setUrlInput((prevInput) => ({
        ...prevInput,
        message,
      }));

      return newVideos;
    });
  };

  const textFieldClass = classNames(styles.uploadInput, styles.uploadInput);

  return (
    <WidgetContent className={styles.uploadContent}>
      <div>
        <ButtonGroup>
          <div className="u-flexColumn">
            <TextField
              errorMessage={urlInput.message}
              value={urlInput.url}
              wrapperProps={{ className: textFieldClass }}
              noWrap
              onChange={textUpdate}
            />
          </div>
          <Button
            disabled={urlInput.url.length === 0 || !urlInput.valid}
            onClick={() => submitUrl()}
          >
            Upload
          </Button>
        </ButtonGroup>
      </div>
      { uploadedVideos.length <= 0 && renderEmptyState(note) }
      { uploadedVideos.length > 0
        && renderUploadedVideos(selectedAssets, uploadedVideos, onAssetClick) }
    </WidgetContent>
  );
}

PreflightUrlUploader.propTypes = propTypes;

export default PreflightUrlUploader;
