import React from "react"
import PropTypes from "prop-types"
import { Dashboard } from "@uppy/react"
import AwsS3 from "@uppy/aws-s3"
import Uppy from "@uppy/core"
import XHRUpload from "@uppy/xhr-upload"
import { logError } from "utilities/logging"
import { getAuthenticityToken } from "utilities/requests"

# on file drop, posts file to an endpoint
class FileDrop extends React.Component
  @propTypes =
    acceptedFileTypes: PropTypes.array
    authenticityToken: PropTypes.string
    height: PropTypes.number
    isPresignedUrl: PropTypes.bool
    maxFileSize: PropTypes.number
    maxFileUploads: PropTypes.number
    minFileSize: PropTypes.number
    onNetworkError: PropTypes.func
    onSuccess: PropTypes.func
    # Endpoint to post to
    postUrl: PropTypes.string.isRequired

  @defaultProps =
    acceptedFileTypes: ['.mp4', '.mov', '.jpg', '.jpeg', '.gif', '.png', '.avi', '.wmv', '.qt']
    authenticityToken: getAuthenticityToken()
    height: 600
    isPresignedUrl: false
    minFileSize: 1

  constructor: (props) ->
    super(props)
    @uppy = Uppy(
      autoProceed: true,
      restrictions:
        allowedFileTypes: @props.acceptedFileTypes
        maxNumberOfFiles: @props.maxFileUploads
        maxFileSize: @props.maxFileSize
        minFileSize: @props.minFileSize
    )

    @uppy.setOptions({
      onBeforeFileAdded: (currentFile, _files) =>
        if currentFile.name.charAt(0) == '.'
          @uppy.info('Your file name cannot begin with \".\"', 'error')
          return false
    })

    if @props.isPresignedUrl
      @uppy.use(AwsS3,
       getUploadParameters: (file) =>
         # Send a request to the pretest upload signing endpoint
         fetch(@props.postUrl, {
           method: 'POST',
           headers: {
             accept: 'application/json',
             'content-type': 'application/json',
             'X-CSRF-Token': @props.authenticityToken
           },
           body: JSON.stringify({
             filename: file.name
           })
         }).then((response) ->
           # Parse pretest upload JSON response
           return response.json()
         ).then((data) ->
           # Return an object in the right shape for S3
           return {
             method: data.method,
             url: data.url,
             fields: data.fields,
             # Provide content type header required by S3
             headers: { 'Content-Type': file.type }
           }
         )
      )
    else
      @uppy.use(XHRUpload,
        endpoint: @props.postUrl,
        fieldName: 'file',
        headers: { 'X-CSRF-Token': @props.authenticityToken },
        timeout: 120 * 1000
      )

    @uppy.on('upload-success', (file, response) =>
      if @props.onSuccess
        # Ensure that we pull the file from the state.
        # The file passed into this method is not guaranteed to be the same as
        # the version in the Uppy state.
        # We do this to ensure the preview is on the file object returned
        @props.onSuccess(@uppy.getFile(file.id), response.body.location)
    )

    @uppy.on('file-added', (file) =>
      # uppy does not provide support for video thumbnails
      # generate and set video thumbnails
      return unless file.type.includes('video')

      this.generateVideoThumbnail(file, (thumbnail) =>
        @uppy.setFileState(file.id, { preview: thumbnail })
      )
    )

    @uppy.on('file-removed', (file) =>
      if @props.onRemove
        @props.onRemove(file)
    )

    @uppy.on('upload-error', (file, error) =>
      if error.isNetworkError
        # Let your users know that file upload could have failed due to
        # firewall or ISP issues
        if @props.onNetworkError
          @props.onNetworkError()

      logError("Uppy Upload #{error}", {
        isNetworkError: error.isNetworkError
        filename: file.name
        filesize: file.size
      })
    )

  # Generates and sets the thumbnail for videos
  # from the first frame of the video
  generateVideoThumbnail: (file, setThumbnail) ->
    url = window.URL || window.webkitURL
    fileURL = url.createObjectURL(file.data)

    video = document.createElement('video')

    video.onseeked = (event) =>
      thumbnail = this.captureVideoScreenshot(video)
      setThumbnail(thumbnail)

    # in order to take the screenshot the video
    # needs to be playing or set to a specific time
    video.onloadedmetadata = (event) ->
      video.currentTime = 0 # triggers the seek event

    video.preload = 'metadata'
    video.src = fileURL

  captureVideoScreenshot: (video) ->
    canvas = document.createElement('canvas')
    # set the canvas dimensions to be the same as the video
    # dimensions so that we can fit a full snapshot of the video
    # onto the canvas
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    context = canvas.getContext('2d')
    context.drawImage(video, 0, 0)
    # generate a URL for the screenshot generated
    canvas.toDataURL("image/jpeg")

  componentWillUnmount: ->
    @uppy.close()

  render: ->
    locale =
      strings:
        dropPaste: 'Drag and drop files here, or %{browse}'
        browse: 'upload manually'

    `<Dashboard
       uppy={this.uppy}
       height={this.props.height}
       hideProgressAfterFinish
       id='dashboard'
       inline
       locale={locale}
       proudlyDisplayPoweredByUppy={false}
       showLinkToFileUploadResult={false}
       showRemoveButtonAfterComplete={false}
       width='100%' //max width
    />`

export default FileDrop
