class FormHelper {
  static resolveRailsPatchPostMethod(formMethod) {
    let formObj;

    if (formMethod === 'patch') {
      formObj = {
        formMethod: 'post',
        hiddenMethod: 'patch',
      };
    } else {
      formObj = {
        formMethod,
        hiddenMethod: undefined,
      };
    }

    return formObj;
  }
}

export default FormHelper;
