import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Divider,
} from '@makeably/creativex-design-system';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import {
  settingsBulkInviteIndexPath,
  settingsPartnersPath,
  settingsUsersPath,
} from 'utilities/routes';
import styles from './BulkInvitePage.module.css';

const excelTemplate = 'https://picassolabs.s3.amazonaws.com/files/CreativeX%20Bulk%20User%20Invite%20Template.xlsx';
const googleTemplate = 'https://docs.google.com/spreadsheets/d/1WEUw7qGEp4-p6N-dV2oxSe91MzM4-ThsKFc7dN9SqCA/copy';

const propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

function renderNotices(isAdmin) {
  let variableNotice;
  if (isAdmin) {
    variableNotice = (
      <li>
        You can view your list of available Partners&nbsp;
        <a href={settingsPartnersPath()} rel="noreferrer" target="_blank">here</a>
        &nbsp;within your dashboard.
      </li>
    );
  } else {
    variableNotice = (
      <>
        <li>
          You can only send invites that match, or are below, your own permission level.
        </li>
        <li>
          To review your details and to see a list of Partners in your dashboard, visit the Users
          page&nbsp;
          <a href={settingsUsersPath()} rel="noreferrer" target="_blank">here</a>
          .
        </li>
      </>
    );
  }

  return (
    <div className="t-body-1">
      <ol className={styles.notices}>
        <li>You will receive an email if the upload has been successful.</li>
        <li>Do not edit any of the column headers or the file will not be accepted.</li>
        <li>
          Columns A - E&nbsp;
          <span className={styles.bolded}>must</span>
          &nbsp;be completed for every user
        </li>
        { variableNotice }
        <li>
          Please reach out to&nbsp;
          <a href="mailto: support@creativex.com">support@creativex.com</a>
          &nbsp;if you have any issues with your upload.
        </li>
      </ol>
    </div>
  );
}

function BulkInvitePage({ isAdmin }) {
  const hiddenFileInput = useRef(null);
  const csvForm = useRef(null);

  const clickFileInput = () => {
    hiddenFileInput.current.click();
  };

  const submitForm = () => {
    csvForm.current.submit();
  };

  return (
    <Card className={styles.textColor}>
      <div className="u-flexRow u-justifySpaceBetween u-marginBottom-16">
        <div>
          <div>
            <h5>Invite teammates in bulk</h5>
          </div>
          <div className="t-subtitle u-marginTop-8">
            Add multiple team members to CreativeX at once.
          </div>
        </div>
        <form ref={csvForm} action={settingsBulkInviteIndexPath()} encType="multipart/form-data" method="post">
          <AuthenticityTokenInput />
          <AddNewButton
            label="Upload CSV"
            onClick={clickFileInput}
          />
          <input
            ref={hiddenFileInput}
            className="u-displayNone"
            name="file"
            type="file"
            onChange={submitForm}
          />
        </form>
      </div>
      <Divider />
      <div className="t-subtitle u-marginTop-16">
        For full instructions, follow the guide in our&nbsp;
        <a
          href="https://support.creativex.com/hc/en-us/articles/6135829902235-How-can-I-bulk-invite-multiple-people-to-my-account-"
          rel="noreferrer"
          target="_blank"
        >
          Help Center
        </a>
        .
      </div>
      <div className="t-body-1 u-marginTop-16">
        <ol className={styles.instructions}>
          <li>Download a template to edit in Excel or open in Google Sheets.</li>
          <li>Input user information into each of the rows.</li>
          <li>Export it as a .CSV before uploading</li>
        </ol>
      </div>
      <div className={styles.buttons}>
        <a href={excelTemplate} rel="noreferrer" target="_blank">
          <Button
            iconLeft="download"
            label="Download Template"
            variant="secondary"
          />
        </a>
        <a href={googleTemplate} rel="noreferrer" target="_blank">
          <Button
            iconLeft="externalLink"
            label="Open in Google Sheets"
            variant="secondary"
          />
        </a>
      </div>
      { renderNotices(isAdmin) }
    </Card>
  );
}

BulkInvitePage.propTypes = propTypes;

export default BulkInvitePage;
