import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormGroupContainer,
  Widget,
  WidgetContent,
} from '@duik/it';
import CardHeader from 'components/reusable/CardHeader';
import { HiddenInput } from 'components/reusable/HiddenInput';
import PermissionsForm, { inviterProps } from 'components/settings/users/PermissionsForm';
import UserFields from 'components/settings/users/UserFields';
import ArrayHelper from 'components/utils/ArrayHelper';
import MixpanelTracker from 'components/utils/MixpanelTracker';
import UserFormValidator from 'components/utils/UserFormValidator';

const optionProps = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

const dataRestrictionOptions = {
  options: PropTypes.arrayOf(optionProps),
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

function trackSendInvite() {
  MixpanelTracker.trackButtonClick('Send Invite');
}

export const propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  brands: PropTypes.shape(dataRestrictionOptions).isRequired,
  canModifyCompany: PropTypes.bool.isRequired,
  channels: PropTypes.shape(dataRestrictionOptions).isRequired,
  companies: PropTypes.shape(dataRestrictionOptions).isRequired,
  companyRestrictionType: PropTypes.oneOf(['own', 'custom']).isRequired,
  inviter: inviterProps.isRequired,
  isLifecycleEnabled: PropTypes.bool.isRequired,
  marketOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  markets: PropTypes.shape(dataRestrictionOptions).isRequired,
  url: PropTypes.string.isRequired,
  selectedCompany: optionProps,
};

const defaultProps = {
  selectedCompany: undefined,
};

class InviteUser extends React.Component {
  static formIncomplete(state) {
    return (
      UserFormValidator.basicInfoIncomplete(state)
        || UserFormValidator.permissionsIncomplete(state)
    );
  }

  constructor(props) {
    super(props);

    const {
      companyRestrictionType,
      selectedCompany,
    } = props;

    this.state = {
      company: selectedCompany,
      email: '',
      firstName: '',
      jobFunction: undefined,
      jobTitle: undefined,
      lastName: '',
      market: undefined,
      roles: [],
      profile: undefined,
      companyRestrictionType,
      selectedBrands: [],
      selectedChannels: [],
      selectedCompanies: [],
      selectedMarkets: [],
    };
  }

  onProfileChange(profile) {
    this.setState({
      companyRestrictionType: 'own',
      profile,
      roles: [],
      selectedBrands: [],
      selectedChannels: [],
      selectedCompanies: [],
      selectedMarkets: [],
    });
  }

  onUserFieldChange(value, name) {
    this.setState({ [name]: value });
  }

  onRoleSelect(role) {
    this.setState((prevState) => {
      let { roles } = prevState;
      roles = ArrayHelper.withOrWithoutValue(roles, role);

      return ({ roles });
    });
  }

  onCompanyRestrictionChange(restrictionType) {
    this.setState({ companyRestrictionType: restrictionType });
  }

  onRestrictionOptChange(valueObj, name) {
    this.setState((prevState) => {
      let values = prevState[name];
      values = ArrayHelper.withOrWithoutObject(values, valueObj);

      return ({ [name]: values });
    });
  }

  render() {
    const {
      authenticityToken,
      brands,
      canModifyCompany,
      channels,
      companies,
      inviter,
      isLifecycleEnabled,
      marketOptions,
      markets: permissionMarkets,
      url,
    } = this.props;

    const {
      company,
      companyRestrictionType,
      email,
      firstName,
      jobFunction,
      jobTitle,
      lastName,
      market,
      profile,
      role,
      roles,
      selectedBrands,
      selectedChannels,
      selectedCompanies,
      selectedMarkets,
    } = this.state;

    const brandOptions = brands.options;
    const channelOptions = channels.options;
    const companyOptions = companies.options;
    const permissionMarketOptions = permissionMarkets.options;

    const userFieldsCallback = (value, name) => this.onUserFieldChange(value, name);

    return (
      <Widget>
        <CardHeader
          header="Invite teammates"
          subHeader="Add additional team members and set their access permissions"
        />
        <WidgetContent>
          <form action={url} method="post">
            <HiddenInput name="authenticity_token" value={authenticityToken} />
            <UserFields
              canModifyCompany={canModifyCompany}
              company={company}
              companyOptions={companyOptions}
              email={email}
              firstName={firstName}
              jobFunction={jobFunction}
              jobTitle={jobTitle}
              lastName={lastName}
              market={market}
              marketOptions={marketOptions}
              role={role}
              onFieldChange={userFieldsCallback}
            />
            <div className="u-marginAboveLg">
              <PermissionsForm
                brandOptions={brandOptions}
                brands={selectedBrands}
                channelOptions={channelOptions}
                channels={selectedChannels}
                companies={selectedCompanies}
                company={company}
                companyOptions={companyOptions}
                companyRestrictionType={companyRestrictionType}
                inviter={inviter}
                isLifecycleEnabled={isLifecycleEnabled}
                marketOptions={permissionMarketOptions}
                markets={selectedMarkets}
                profile={profile}
                roles={roles}
                onCompanyRestrictionChange={(rt) => this.onCompanyRestrictionChange(rt)}
                onProfileChange={(selectedProfile) => this.onProfileChange(selectedProfile)}
                onRestrictionOptionChange={(obj, n) => this.onRestrictionOptChange(obj, n)}
                onRoleSelect={(selectedRole) => this.onRoleSelect(selectedRole)}
              />
            </div>
            <FormGroupContainer>
              <FormGroup className="u-textAlignCenter">
                <input
                  className="formSubmit btn btn-primary u-marginAboveLg"
                  disabled={InviteUser.formIncomplete(this.state)}
                  name="commit"
                  type="submit"
                  value="Send Invite"
                  onClick={trackSendInvite}
                />
              </FormGroup>
            </FormGroupContainer>
          </form>
        </WidgetContent>
      </Widget>
    );
  }
}

InviteUser.propTypes = propTypes;
InviteUser.defaultProps = defaultProps;

export default InviteUser;
