import React from "react"
import PropTypes from "prop-types"
import AntdSelect from "components/reusable/antd_select"
import MixpanelTracker from "components/utils/MixpanelTracker"
import { Button, Widget, WidgetContent, WidgetHeader } from  "@duik/it"
import { HiddenInput } from "components/reusable/HiddenInput"
import { ModalLaunchButton } from "components/containers/miscellaneous_containers"
import { Tooltip } from '@makeably/creativex-design-system'

# Responsible for displaying available brands, markets and org affiliates for an ad account to link to.
class AccountSetupForm extends React.Component
  @propTypes =
    brand: PropTypes.string
    brandOptions: PropTypes.arrayOf(PropTypes.string)
    entityDetails: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string,
        value: PropTypes.integer
      )
    )
    form: PropTypes.shape(
      path: PropTypes.string,
      authenticityToken: PropTypes.string
    )
    hiddenInputs: PropTypes.arrayOf(
      PropTypes.shape(
        name: PropTypes.string,
        value: PropTypes.string
      )
    )
    hideBrandMarket: PropTypes.bool
    market: PropTypes.string
    marketOptions: PropTypes.arrayOf(PropTypes.string)
    orgAffiliateId: PropTypes.string
    orgAffiliateOptions: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string,
        value: PropTypes.integer
      )
    )

  COMPANY_OWNER_TOOLTIP_TEXT =
    "This is the company that manages this account. " +
    "If you don't see your company on the list, please contact support@creativex.com."

  constructor: (props) ->
    super(props)

    orgAffiliateId = @props.orgAffiliateId
    selectedOrgAffiliate = _.find(@props.orgAffiliateOptions, (option) ->
      option.value is orgAffiliateId
    )

    # We can sometimes have a situation where the passed in orgAffiliateId
    # isn't in the orgAffiliateOptions list and this breaks the form. If we
    # have that case, set the orgAffiliate to undefined
    if _.isUndefined(selectedOrgAffiliate)
      orgAffiliateId = undefined

    @state =
      brand: @props.brand
      market: @props.market
      orgAffiliateId: orgAffiliateId
      submitted: false

  onFormSubmit: =>
    @setState(submitted: true)

  brandOptions: ->
    @props.brandOptions.map((brand) -> { label: brand, value: brand })

  marketOptions: ->
    @props.marketOptions.map((market) -> { label: market, value: market })

  onBrandChange: (value) =>
    @setState(brand: value)

  onMarketChange: (value) =>
    @setState(market: value)

  onOrgAffiliateChange: (value) =>
    @setState(orgAffiliateId: value)

  trackFormSubmission: ->
    MixpanelTracker.trackEvent("User Links Account")

  trackModalLaunch: ->
    MixpanelTracker.trackButtonClick("Confirm")

  renderBrandMarketSelections: ->
    return if @props.hideBrandMarket

    `<div>
      <div className='input-field accountSetupCard-inputField'>
        <span>Brand</span>
        <AntdSelect
          allowClear={false}
          className='accountSetupCard-select accountSetupCard-select--default'
          onChange={this.onBrandChange}
          options={this.brandOptions()}
          mode='default'
          placeholder='Select...'
          selected={this.state.brand}
          showSearch={true}
        />
      </div>
      <div className='input-field accountSetupCard-inputField'>
        <span>Market</span>
        <AntdSelect
          allowClear={false}
          className='accountSetupCard-select accountSetupCard-select--default'
          onChange={this.onMarketChange}
          options={this.marketOptions()}
          mode='default'
          placeholder='Select...'
          selected={this.state.market}
          showSearch={true}
        />
      </div>
    </div>`

  renderSelections: ->
    `<div className='accountSetup-orgDetails-container'>
      {this.renderBrandMarketSelections()}
      <div className='input-field accountSetupCard-inputField'>
        <span className='u-flexRow'>
          <span className='u-marginRightSm'>Company Owner</span>
          <Tooltip label={COMPANY_OWNER_TOOLTIP_TEXT} />
        </span>
        <AntdSelect
          allowClear={false}
          className='accountSetupCard-select accountSetupCard-select--default'
          onChange={this.onOrgAffiliateChange}
          options={this.props.orgAffiliateOptions}
          mode='default'
          placeholder='Select...'
          searchByLabel={true}
          selected={this.state.orgAffiliateId}
          showSearch={true}
        />
      </div>
    </div>`

  renderDetailRow: (detail) =>
    return if @props.hideBrandMarket && _.contains(['Brand', 'Market'], detail.label)

    `<div className='u-flexRow u-marginAboveSm' key={detail.label}>
      <b className='accountSetup-detailLabel'>{detail.label}</b>
      <span>{detail.value}</span>
    </div>`

  renderHiddenInput: (hiddenInput, idx) ->
    `<HiddenInput key={idx} {...hiddenInput}/>`

  renderModal: ->
    selectedOrgAffiliate = _.find(@props.orgAffiliateOptions, (option) => option.value is @state.orgAffiliateId)
    orgAffiliateName = selectedOrgAffiliate.label

    `<form className='accountSetupCard' action={this.props.form.path} onSubmit={this.onFormSubmit} method='post'>
      {this.props.hiddenInputs.map(this.renderHiddenInput)}
      <HiddenInput name='authenticity_token' value={this.props.form.authenticityToken}/>
      <HiddenInput name='brand' value={this.state.brand}/>
      <HiddenInput name='market' value={this.state.market}/>
      <HiddenInput name='org_affiliate_id' value={this.state.orgAffiliateId}/>
      <h5>Please confirm the following is correct</h5>
      <div className='u-flexColumn'>
        {this.props.entityDetails.map(this.renderDetailRow)}
        {this.renderDetailRow({label: 'Brand', value: this.state.brand})}
        {this.renderDetailRow({label: 'Market', value: this.state.market})}
        {this.renderDetailRow({label: 'Organization Affiliate', value: orgAffiliateName})}
      </div>
      <div className='u-marginAbove' onClick={this.trackFormSubmission}>
        <input
          className="btn btn-primary"
          type="submit"
          value="Looks good!"
          disabled={this.state.submitted}
        />
      </div>
    </form>`

  renderModalButton: ->
    selections = [@state.orgAffiliateId]
    selections = _.union(selections, [@state.brand, @state.market]) unless @props.hideBrandMarket

    incomplete = _.some(selections, (selection) ->
      _.isNull(selection) || _.isUndefined(selection) || _.isEmpty(selection))

    if incomplete
      `<Button disabled>Confirm</Button>`
    else
      modalId = 'ConfirmationModal'

      modalProps = {
        modal: {
          children: @renderModal()
          className: 'wide'
          id: modalId
          onOpen: this.trackModalLaunch
        }
        contentProps: {
          'data-target': modalId
          children: 'Confirm'
        }
        launchType: "click"
      }

      `<ModalLaunchButton {...modalProps} />`

  render: ->
    `<Widget className='accountSetupCard accountSetupCard--medium'>
      <WidgetHeader>
        <div>
          <h5>Organization Details</h5>
          <p>Select the organization attributes associated with this account</p>
        </div>
      </WidgetHeader>
      <WidgetContent>
        {this.renderSelections()}
        <div className='u-marginAbove'>
          <div className='right'>
            {this.renderModalButton()}
          </div>
        </div>
      </WidgetContent>
    </Widget>`

export default AccountSetupForm
