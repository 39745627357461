import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@makeably/creativex-design-system';
import AntdTree from 'components/reusable/AntdTree';
import { HiddenInput } from 'components/reusable/HiddenInput';
import {
  track,
  useViewPage,
} from 'utilities/mixpanel';

const organizationsWithAdAccountsProps = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  key: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const propTypes = {
  authId: PropTypes.string.isRequired,
  organizationsWithAdAccounts: PropTypes.arrayOf(
    PropTypes.shape(organizationsWithAdAccountsProps),
  ).isRequired,
};

function SnapchatAdAccountSelect({
  authId,
  organizationsWithAdAccounts,
}) {
  const [checkedAdAccounts, setCheckedAdAccounts] = useState([]);
  const [disabledAdAccounts, setDisabledAdAccounts] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);

  useViewPage();

  useEffect(() => {
    const formattedOrgData = organizationsWithAdAccounts.map((organization) => {
      const adAccounts = organization.children.map((account) => {
        if (account.linked) {
          setDisabledAdAccounts((prev) => [...prev, account.key]);
        }
        return {
          key: account.key,
          title: account.title,
          disabled: account.linked,
        };
      });
      return {
        key: organization.key,
        title: organization.title,
        children: adAccounts,
      };
    });
    setOrganizationData(formattedOrgData);
  }, []);

  const onCheck = (checkedKeys, event) => {
    const orgKeys = organizationsWithAdAccounts.map((org) => org.key);
    const checkedOptions = event.checkedNodes.filter((node) => (
      !orgKeys.includes(node.key) && !disabledAdAccounts.includes(node.key)
    ));
    setCheckedAdAccounts(checkedOptions);
  };

  const renderAdAccountHiddenInputs = (adAccount, index) => (
    <div key={index}>
      <HiddenInput name="ad_accounts[][id]" value={adAccount.key} />
      <HiddenInput name="ad_accounts[][name]" value={adAccount.props.title} />
    </div>
  );

  return (
    <div>
      <div className="snapchatAdsSetupCard snapchatAdsSetupCard--large">
        <AntdTree
          defaultCheckedKeys={disabledAdAccounts}
          defaultExpandAll={organizationsWithAdAccounts.length === 1}
          treeData={organizationData}
          onCheck={onCheck}
        />
      </div>

      { checkedAdAccounts.map(renderAdAccountHiddenInputs) }
      <HiddenInput name="auth_id" value={authId} />
      <div className="u-flexRow u-justifyEnd u-marginBottom-8 u-marginRight-8">
        <Button
          disabled={checkedAdAccounts.length === 0}
          label="Next"
          type="submit"
          variant="secondary"
          onClick={() => track('selected_ad_accounts')}
        />
      </div>
    </div>
  );
}

SnapchatAdAccountSelect.propTypes = propTypes;

export default SnapchatAdAccountSelect;
