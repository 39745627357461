import React from "react"
import PropTypes from "prop-types"
import CategoryFilterField from "components/category/category_filter_field"
import Column from "components/reusable/column"
import Label from "components/reusable/label"
import Row from "components/reusable/row"

# Used to pretty display a category's filter
class CategoryFilter extends React.Component
  @propTypes =
    label: PropTypes.string.isRequired
    filter: PropTypes.shape(
      have: PropTypes.shape(
        operator: PropTypes.oneOf(['and', 'or'])
        fields: PropTypes.arrayOf(PropTypes.object)
      )
      not_have: PropTypes.shape(
        operator: PropTypes.oneOf(['and', 'or'])
        fields: PropTypes.arrayOf(PropTypes.object)
      )
    ).isRequired

  renderField: (fieldObj) ->
    `<CategoryFilterField key={fieldObj.field} {...fieldObj} />`

  renderSection: (sectionObj) =>
    section = sectionObj.section
    fields = @props.filter[section].fields
    operator = @props.filter[section].operator

    if _.some(fields, (fieldObj) -> fieldObj.value.length > 0)
      `<Row key={section} >
        <Column styleName='no-padding'>
          <Row>
            <Column styleName='no-padding'>
              <h5 className='categoryFilterShow-sectionLabel'>
                {sectionObj.filterShowLabel[operator]}
              </h5>
            </Column>
          </Row>
          {fields.map(this.renderField)}
        </Column>
      </Row>`

  render: ->
    `<Row styleName='no-margin'>
      <Column styleName='no-padding'>
        <Row>
          <Column styleName='no-padding'>
            <h5 className='categoryFilterShow-label'>
              <span>{"Category Name: "}</span>
              <Label value={this.props.label} />
            </h5>
          </Column>
        </Row>
        <hr/>
        {CategoryUtil.sections().map(this.renderSection)}
      </Column>
    </Row>`

export default CategoryFilter
