import React from "react"
import CsvButton from "components/reusable/csv_button"
import PropTypes from "prop-types"

# This component is responsible for generating a csv file that the user can download. This focuses on client side
# csv generation.
class AuditSegmentationCsvDownload extends React.Component
  @propTypes =
    # a function that takes in primary/secondary dimension values
    getMetricValue: PropTypes.func
    metricLabel: PropTypes.string
    onClick: PropTypes.func
    primaryDimensionValues: PropTypes.array
    primaryDimensionLabel: PropTypes.string
    secondaryDimensionValues: PropTypes.array
    secondaryDimensionLabel: PropTypes.string

  formatCsvValue: (value) =>
    return value if _.isUndefined(value)

    switch @props.metric
      when 'spend', 'excellent_spend', 'non_excellent_spend'
        FormatUtil.csvFormat(value, 2)
      when 'excellence_rate', 'excellence_spend_rate'
        FormatUtil.csvFormat(value, 4)
      else
        value

  render: ->
    rows = []

    header_row = ['']
    _.each(
      @props.secondaryDimensionValues,
      (secondaryDimensionValue) -> header_row.push(secondaryDimensionValue)
    )
    rows.push(header_row)

    _.each(
      @props.primaryDimensionValues,
      (primaryDimensionValue) =>
        row = [primaryDimensionValue]
        _.each(
          @props.secondaryDimensionValues,
          (secondaryDimensionValue) =>
            formattedValue = @formatCsvValue(@props.getMetricValue(primaryDimensionValue, secondaryDimensionValue))
            row.push(formattedValue)
        )
        rows.push(row)
    )

    filename = "Comparison Report - #{@props.metricLabel} by #{@props.primaryDimensionLabel}
      and #{@props.secondaryDimensionLabel}"

    `<CsvButton rows={rows} filename={filename} label='Download CSV' onClick={this.props.onClick}/>`

export default AuditSegmentationCsvDownload
