import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
} from '@makeably/creativex-design-system';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import UserFields from 'components/settings/users/UserFields';
import UserFormValidator from 'components/utils/UserFormValidator';
import { valueToObject } from 'utilities/object';

const optionProps = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.number,
});

export const propTypes = {
  canModifyCompany: PropTypes.bool.isRequired,
  companies: PropTypes.shape({
    activeOption: optionProps,
    options: PropTypes.arrayOf(optionProps),
  }).isRequired,
  editingSelf: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  marketOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  url: PropTypes.string.isRequired,
  jobFunction: PropTypes.string,
  jobTitle: PropTypes.string,
  market: PropTypes.string,
};

const defaultProps = {
  jobFunction: undefined,
  jobTitle: undefined,
  market: undefined,
};

class EditUser extends React.Component {
  constructor(props) {
    super(props);

    const {
      companies,
      email,
      firstName,
      jobFunction,
      jobTitle,
      lastName,
      market,
    } = props;

    let formattedJobFunction;
    if (jobFunction) {
      formattedJobFunction = valueToObject(jobFunction);
    }

    let formattedJobTitle;
    if (jobTitle) {
      formattedJobTitle = valueToObject(jobTitle);
    }

    let formattedMarket;
    if (market) {
      formattedMarket = valueToObject(market);
    }

    this.state = {
      company: companies.activeOption,
      email,
      firstName,
      jobFunction: formattedJobFunction,
      jobTitle: formattedJobTitle,
      lastName,
      market: formattedMarket,
    };
  }

  onUserFieldChange(value, name) {
    this.setState({ [name]: value });
  }

  render() {
    const {
      canModifyCompany,
      companies,
      editingSelf,
      marketOptions,
      url,
    } = this.props;

    const {
      company,
      email,
      firstName,
      jobFunction,
      jobTitle,
      lastName,
      market,
    } = this.state;

    const companyModifiable = canModifyCompany && !editingSelf;
    const userFieldsCallback = (value, name) => this.onUserFieldChange(value, name);

    return (
      <Card>
        { !editingSelf
          && (
          <>
            <h5>{ `${firstName} ${lastName}` }</h5>
            <div className="t-subtitle">Update information about a team member</div>
            <Divider margin />
          </>
          ) }
        <div>
          <form action={url} method="post">
            <HiddenInput name="_method" value="patch" />
            <AuthenticityTokenInput />
            <UserFields
              canModifyCompany={companyModifiable}
              company={company}
              companyOptions={companies.options}
              editingSelf={editingSelf}
              email={email}
              firstName={firstName}
              jobFunction={jobFunction}
              jobTitle={jobTitle}
              lastName={lastName}
              market={market}
              marketOptions={marketOptions}
              onFieldChange={userFieldsCallback}
            />
            <div className="u-flexRow u-justifyFlexEnd u-marginAbove">
              <Button
                disabled={UserFormValidator.basicInfoIncomplete(this.state)}
                label={editingSelf ? 'Save' : 'Update User'}
                type="submit"
              />
            </div>
          </form>
        </div>
      </Card>
    );
  }
}

EditUser.propTypes = propTypes;
EditUser.defaultProps = defaultProps;

export default EditUser;
