import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MessageModal,
  TextInput,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import { post } from 'utilities/requests';
import { adminIndustriesPath } from 'utilities/routes';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

async function submitIndustry(name) {
  const url = adminIndustriesPath();

  const formData = new FormData();
  formData.append('name', name);

  const response = await post(url, formData);
  return response.data;
}

function NewIndustryModal({
  isOpen,
  onClose,
}) {
  const [name, setName] = useState('');

  const onSave = async () => {
    const data = await submitIndustry(name);

    if (data.success) {
      addToast('New industry successfully added');
    } else {
      addToast('Industry name already exists', { type: 'error' });
    }

    window.location.reload();
  };

  return (
    <MessageModal
      actionButtonDisabled={!name}
      actionButtonLabel="Save"
      isOpen={isOpen}
      size="medium"
      title="Add New Industry"
      onActionButtonClick={onSave}
      onClose={onClose}
    >
      <TextInput
        label="Industry Name"
        placeholder="Industry Name"
        size="large"
        value={name}
        onChange={(value) => setName(value)}
      />
    </MessageModal>
  );
}

NewIndustryModal.propTypes = propTypes;

export default NewIndustryModal;
