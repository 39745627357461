/* global CX_NAMESPACE_UUID */

// Helper functions for filters & filtering
import { v5 as uuidv5 } from 'uuid';
import { getItemText } from 'utilities/item';
import { post } from 'utilities/requests';
import { filterQueriesPath } from 'utilities/routes';
import { byObjectValue } from 'utilities/sort';

export function calculateFilterUuid(filterSelections) {
  const json = JSON.stringify(filterSelections);

  // uuidv5 creates repeatable hash-based uuids
  return uuidv5(json, CX_NAMESPACE_UUID);
}

// Returns an object with default values
export function getDefaultSelections(keys, defaultFilters) {
  return keys.reduce((obj, key) => {
    const defaultFilterVal = defaultFilters?.[key];
    const value = defaultFilterVal ? [defaultFilterVal] : [];
    return {
      ...obj,
      [key]: value,
    };
  }, {});
}

function intersectsFilterValues(filterValues, value) {
  if (Array.isArray(value)) {
    return filterValues.some((item) => value.includes(item));
  }

  return filterValues.includes(value);
}

function intersectsObjFilterValues(filterValues, value) {
  return filterValues.findIndex((option) => {
    if (Array.isArray(value)) {
      return value.includes(option?.value);
    }

    return option?.value === value;
  }) !== -1;
}

// Returns a function that checks if a datum passes all selected filters
// Handles both Items (useDataumValue = true) and normal objects
// Handles both object selections (useSelectionValue = true) and simple arrays of values
function getFilterTest(filterSelections, useDatumValue, useSelectionValue) {
  const filterEntries = Object.entries(filterSelections);

  return (datum) => filterEntries.every(([filterKey, filterValues]) => {
    if (filterValues.length === 0) {
      return true;
    }

    const value = useDatumValue ? datum[filterKey]?.value : datum[filterKey];

    if (useSelectionValue) {
      return intersectsObjFilterValues(filterValues, value);
    }
    return intersectsFilterValues(filterValues, value);
  });
}

export function getArrayFilterTest(filterSelections) {
  return getFilterTest(filterSelections, false, false);
}

export function getObjFilterTest(filterSelections) {
  return getFilterTest(filterSelections, false, true);
}

export function getItemArrayFilterTest(filterSelections) {
  return getFilterTest(filterSelections, true, false);
}

export function getItemObjFilterTest(filterSelections) {
  return getFilterTest(filterSelections, true, true);
}

function intersectsObj(objArr, arr) {
  return arr.some((value) => {
    let compareVal = value;

    if (typeof value === 'object') {
      if (value.filterIgnore) return true;
      compareVal = value.value;
    }

    return objArr.findIndex((obj) => obj?.value === compareVal) !== -1;
  });
}

// Returns filter test for items and dropdown selections
export function getItemMultipleFilterTest(filterSelections) {
  const filterEntries = Object.entries(filterSelections);

  return (datum) => filterEntries.every(([filterKey, initialFilterObjs]) => {
    const filterObjs = Array.isArray(initialFilterObjs) ? initialFilterObjs : [initialFilterObjs];

    if (filterObjs.length === 0) {
      return true;
    }

    const optionValues = datum[filterKey].options?.map(({ value }) => value);
    const filterValues = datum[filterKey].filter;
    const { value } = datum[filterKey];

    const values = optionValues ?? filterValues ?? [value];

    if (filterValues) {
      // eslint-disable-next-line no-console
      console.warn(`The Item 'filter' key is deprecated, use 'options' instead
        (while filtering '${filterKey}' by ${JSON.stringify(filterSelections)})`);
    }

    // If we set to ignore filtering then it should always show up
    if (datum[filterKey].ignoreFilter) return true;

    return intersectsObj(filterObjs, values);
  });
}

// Returns an object of filters specified in params
export function getFilterSelections(filterKeys, params) {
  return filterKeys.reduce((selections, key) => {
    const value = params.get(key)?.split(',') ?? [];

    return {
      ...selections,
      [key]: value,
    };
  }, {});
}

function selectOptions(values, options) {
  return values.reduce((all, value) => {
    const option = options.find((opt) => opt.value === value);

    if (option) {
      return [...all, option];
    }
    return all;
  }, []);
}

export function selectFilterOptions(filterValuesObj, filterOptions) {
  return Object.entries(filterValuesObj).reduce((all, [key, values]) => {
    const keyOptions = filterOptions[key] ?? [];

    return {
      ...all,
      [key]: selectOptions(values, keyOptions),
    };
  }, {});
}

// Generate props for filtering based on unique item properties.
// filterOptions: [{ key: 'filterKey', label: 'Filter Name' }, { ... } ]
// filterDisplays is for setting options to create non-dropdown filters.
export function getFilters(allItems, filterOptions, filterDisplays = {}) {
  if (allItems.length === 0) return [];

  return filterOptions.map(({ key, label }) => {
    const uniques = new Map();
    allItems.map((item) => {
      const value = item[key]?.value;
      const text = getItemText(item, key);

      return uniques.set(value, text);
    });

    const unsorted = [...uniques].map(([value, text]) => ({
      value,
      label: text,
    }));
    const options = unsorted.sort(byObjectValue);

    return {
      key,
      options,
      label,
      display: filterDisplays[key],
    };
  });
}

export async function saveFilterQuery(selections) {
  const uuid = calculateFilterUuid(selections);
  const url = filterQueriesPath();

  const formData = new FormData();
  formData.append('uuid', uuid);
  formData.append('filter_options', JSON.stringify(selections));

  await post(url, formData);

  return uuid;
}
