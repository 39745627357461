import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Plotly from "plotly.js"

# Responsible for generating a Plotly heatmap graph.
# See Plotly documentation for layout and other customization options:
# https://plotly.com/javascript/reference/#heatmap
class PlotlyHeatmap extends React.Component
  @propTypes =
    data: PropTypes.array,
    id: PropTypes.string,
    layout: PropTypes.object

  classes: ->
    classNames(
      @props.className
    )

  constructor: (props) ->
    super(props)
    @state =
      id: @props.id

  drawGraph: ->
    Plotly.newPlot(@props.id, @props.data, @props.layout, PlotlyUtil.modebarOptions())

  # TODO: Need to update
  #  * Move code with side effects to componentDidMount, and set initial state
  #     in the constructor.
  #  * See https://fb.me/react-unsafe-component-lifecycles for details.
  UNSAFE_componentWillMount: ->
    $(window).on('resize', _.debounce(@resize, 150))

  componentDidMount: ->
    @drawGraph()

  componentDidUpdate: ->
    @drawGraph()

  resize: =>
    el = document.getElementById(@state.id)
    Plotly.Plots.resize(el) if el

  render: ->
    `<div className={this.classes()} id={this.props.id} />`

export default PlotlyHeatmap
