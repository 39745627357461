import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  MaxWidthText,
  MoreButton,
  Search,
} from '@makeably/creativex-design-system';
import ItemsTable from 'components/molecules/ItemsTable';
import { arrayIf } from 'utilities/array';
import { getItemSortBy } from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import {
  track,
} from 'utilities/mixpanel';
import {
  newSettingsCsvExportPath,
} from 'utilities/routes';
import { dateToString } from 'utilities/string';
import styles from './NewExport.module.css';

const PER_PAGE_LIMIT = 20;

const exportProps = PropTypes.shape({
  dateGenerated: PropTypes.string,
  dateRange: PropTypes.string,
  expired: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
  readyForDownload: PropTypes.bool,
  rowCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
});

const propTypes = {
  csvExports: PropTypes.arrayOf(exportProps).isRequired,
};

const emptyState = (
  <div className={classNames('u-flexColumn', 'u-alignCenter', 't-empty', styles.empty)}>
    No exports found
  </div>
);

const headers = [
  {
    label: 'Name (ID)',
    key: 'name',
  },
  {
    label: 'Date Generated',
    key: 'dateGenerated',
  },
  {
    label: 'Date Range',
    key: 'dateRange',
  },
  {
    label: 'Type',
    key: 'type',
  },
  {
    label: 'No. of Rows',
    key: 'rowCount',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: '',
    key: 'menu',
  },
];

function rowCountCell(value) {
  const hsh = { value };
  if (typeof value !== 'number') return hsh;

  return {
    ...hsh,
    format: 'number',
  };
}

function onExportEdit(exportId) {
  track('copy_report');
  window.location.href = newSettingsCsvExportPath({
    export_id: exportId,
  });
}

const getOptions = (expired, url, index, id) => [
  ...arrayIf(!expired && url, {
    label: 'Download',
    url,
  }), {
    label: 'Edit & Re-Run',
    onClick: () => onExportEdit(id),
    testId: `exports:reports:more_button:edit:${index}`,
  }];

const optionsButton = (expired, url, index, id) => (
  <MoreButton
    options={getOptions(expired, url, index, id)}
    testId={`exports:reports:more_button:${index}`}
  />
);

function menuCell({
  url,
  id,
  expired,
  name,
}, index) {
  return {
    element: optionsButton(expired, url, index, id),
    value: name,
  };
}

function titleCell(name) {
  return {
    element: <MaxWidthText
      size="medium"
      text={name}
    />,
    value: name,
  };
}

function formattedExport(csvExport, index) {
  return setItemElement({
    dateGenerated: { value: dateToString(csvExport.dateGenerated) },
    dateRange: { value: csvExport.dateRange },
    status: { value: csvExport.status },
    id: { value: csvExport.id },
    name: titleCell(csvExport.name),
    rowCount: rowCountCell(csvExport.rowCount),
    type: { value: csvExport.type },
    menu: menuCell(csvExport, index),
  });
}

function Exports({
  csvExports,
}) {
  const [filteredItems, setFilteredItems] = useState([]);
  const [sortedItems, setSortedItems] = useState([]);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    key: 'dateGenerated',
    asc: false,
  });

  useEffect(() => {
    const filtered = csvExports.filter(({
      name,
    }) => name.toLowerCase().includes(search.toLowerCase()));

    setFilteredItems(filtered);
  }, [search]);

  useEffect(() => {
    setItems(filteredItems.map(formattedExport));
  }, [filteredItems]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    } else {
      setSortedItems(items);
    }
  }, [items, sort]);

  return (
    <Card className={styles.content}>
      <div className="u-flexRow u-spaceBetween u-gap-24 u-marginBottom-16">
        <Search
          placeholder="Search Export Name"
          size="large"
          value={search}
          onChange={setSearch}
        />
        <AddNewButton
          label="Add New"
          url={newSettingsCsvExportPath()}
          onClick={() => track('add_report')}
        />
      </div>
      <div data-testid="exports:reports">
        <ItemsTable
          emptyTableContent={emptyState}
          headers={headers}
          items={sortedItems}
          maxWidthSize="custom"
          page={page}
          perPage={PER_PAGE_LIMIT}
          sort={sort}
          onPageChange={setPage}
          onSortChange={setSort}
        />
      </div>
    </Card>
  );
}

Exports.propTypes = propTypes;

export default Exports;
