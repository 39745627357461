# TODO: Remove me. Convert to two components:
#       1. NavDropdown (Dropdown with a tags)
#       2. Searchable Select
import React from "react"
import AntdSelect from "components/reusable/antd_select"
import classNames from "classnames"
import PropTypes from "prop-types"
import Select from "components/reusable/select"
import { Dropdown, DropdownItem } from  "@duik/it"
import Pills from "components/reusable/Pills"
import { Tooltip } from '@makeably/creativex-design-system';

# A container for selecting things. You can explicitly choose pills or a browser select,
# or it will choose for you depending on the # of options being passed in.
# Options are assumed to have a specific structure:
#   options: [ {label: 'One', value: '1' }, ...]
#
# Default behavior will reload the page with the value` of selected
#   - (so it better be a url!)
class ViewSelector extends React.Component
  # greater than this will present dropdown instead of pills
  PILL_THRESHOLD = 5

  @propTypes =
    disabled: PropTypes.bool
    force: PropTypes.oneOf(['pills', 'select', 'dropdown'])
    isPerformanceSelector: PropTypes.bool   # Changes style of the view selector for performance pages
    label: PropTypes.string
    onChange: PropTypes.func
    options: PropTypes.array.isRequired
    selected: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string
      })
    ])
    size: PropTypes.oneOf(['small', 'default'])
    shrink: PropTypes.bool                # Whether to shrink selector to content size
    remote: PropTypes.bool                # mimic rails `remote: true` behavior
    tootlipText: PropTypes.string

  @defaultProps =
    disabled: false
    isPerformanceSelector: false
    remote: false
    size: 'default'

  handleChange: (selected_value) =>
    # priority 1 is to use the callback passed in
    if @props.onChange
      @props.onChange(selected_value)

    # priority 2 is to use the `remote` flag if passed in. This mimics rails remote: true behavior
    else if @props.remote
      $.get( selected_value ).fail( ->
        Materialize.toast("Oops! We've encountered an error.", 4000, 'rounded')
      )

    # default behavior is to consider the change a request to visit a new url.
    else
      window.location.href = selected_value

  renderLabel: ->
    if @props.label
      label = `<label>{this.props.label}</label>`

  renderTooltip: ->
    if @props.tooltipText
      `<Tooltip label={this.props.tooltipText} />`

  renderSelect: (props) ->
    `<Select {...props}/>`

  renderPills: (props) ->
    `<Pills {...props}/>`

  # Renders options as links for a dropdown menu
  renderDropdownOption: (option, index) ->
    props =
      key: index
      target: option.target
      href: option.value

    `<DropdownItem Component={"a"} {...props}>
      {option.label}
    </DropdownItem>`

  renderDropdown: (props) ->
    `<Dropdown buttonText={props.selected}>
      {props.options.map(this.renderDropdownOption)}
    </Dropdown>`

  renderContent: (props) ->
    if @props.force

      switch @props.force
        when 'select' then @renderSelect(props)
        when 'pills' then @renderPills(props)
        when 'dropdown' then @renderDropdown(props)

    else if @props.options.length >= PILL_THRESHOLD
      @renderSelect(props)

    else
      @renderPills(props)

  renderPerformanceSelection: (props) ->
    `<div className='input-field'>
        <span> {this.props.label} </span>
        <AntdSelect
            allowClear={false}
            className='performanceMeasurement-selection'
            disabled={props['disabled']}
            mode='default'
            onChange={props['onChange']}
            options={props['options']}
            selected={props['selected']}
            showSearch={true}
        />
    </div>`

  render: ->
    # Handle case where selected is an object
    selected_value = if typeof(@props.selected) is 'string' then @props.selected else @props.selected.value

    props =
      onChange: @handleChange
      options: @props.options
      selected: selected_value
      size: @props.size

    props.disabled = @props.disabled if @props.disabled
    props.shrink = @props.shrink if @props.shrink

    if (@props.isPerformanceSelector)
      `<div>
        {this.renderPerformanceSelection(props)}
      </div>`
    else
      `<div className={this.props.className}>
        {this.renderLabel()}
        {this.renderTooltip()}
        {this.renderContent(props)}
      </div>`

export default ViewSelector
