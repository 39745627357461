import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MessageModal } from '@makeably/creativex-design-system';
import { getAuthenticityToken } from 'utilities/requests';
import {
  bulkDeactivateCreativeLifecycleCoreAssetsPath,
  editCreativeLifecycleCampaignPath,
} from 'utilities/routes';
import styles from './AssetDeactivateModal.module.css';

const propTypes = {
  campaignId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

function AssetDeactivateModal({
  campaignId,
  isOpen,
  onClose,
  selectedUuids,
}) {
  const [errorText, setErrorText] = useState();

  const onActionButtonClick = async () => {
    const response = await fetch(bulkDeactivateCreativeLifecycleCoreAssetsPath(), {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-CSRF-Token': getAuthenticityToken(),
      },
      body: JSON.stringify({
        core_asset_uuids: selectedUuids,
        campaign_id: campaignId,
      }),
    });

    if (response.ok) {
      window.location.href = editCreativeLifecycleCampaignPath(campaignId);
    } else {
      setErrorText('Something went wrong, please try again');
    }
  };

  return (
    <MessageModal
      actionButtonLabel="Delete"
      actionButtonVariant="destructive"
      isOpen={isOpen}
      subtitle="Are you sure you want to delete the selected core assets?"
      title={`Delete Selected Core Assets (${selectedUuids.length})`}
      onActionButtonClick={onActionButtonClick}
      onClose={onClose}
    >
      <div className={styles.errorText}>{ errorText }</div>
    </MessageModal>
  );
}

AssetDeactivateModal.propTypes = propTypes;

export default AssetDeactivateModal;
