import AjaxCard from "components/higher_order_components/ajax_card"
import AjaxContainer from "components/higher_order_components/ajax_container"
import PlotlyChart from "components/reusable/plotly_chart"
import PlotlyChartWithControls from "components/reusable/plotly_chart_with_controls"

# This file is for all flavors of plotly graph containers... this allows us to define and name things in a way where
# we control dependency loading (MultiViewPlotlyGraphContainer after PlotlyGraphContainer).

# Just a cool PlotlyChart card. See AjaxCard for propTypes.
export PlotlyGraphContainer = AjaxCard(PlotlyChart)
# Just a cool div that allows you to switch between PlotlyGraphContainers
export MultiViewPlotlyGraphContainer = AjaxContainer('div', PlotlyGraphContainer, true)
# Just a cool PlotlyChartWithControls card. See AjaxCard for propTypes.
export PlotlyChartContainerWithControls = AjaxCard(PlotlyChartWithControls)
