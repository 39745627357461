import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Stepper,
  Tag,
} from '@makeably/creativex-design-system';
import AssociateEntity from 'components/account_setup/amazon_ads/AssociateEntity';
import { useViewPage } from 'utilities/mixpanel';
import { getAuthenticityToken } from 'utilities/requests';
import { newSettingsLinkedPlatformAccountPath } from 'utilities/routes';
import styles from './AssociateAdvertisers.module.css';

const stepProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
});
const advertiserProps = PropTypes.shape({
  apiId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});
const entityProps = PropTypes.shape({
  countryCode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
});
const optionsProps = PropTypes.arrayOf(PropTypes.node);
const propTypes = {
  advertisers: PropTypes.arrayOf(advertiserProps).isRequired,
  brandOptions: PropTypes.arrayOf(optionsProps).isRequired,
  currentStep: PropTypes.number.isRequired,
  entity: entityProps.isRequired,
  formUrl: PropTypes.string.isRequired,
  nextUrl: PropTypes.string.isRequired,
  partnerOptions: PropTypes.arrayOf(optionsProps).isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
};

const dropdownKeys = ['brand', 'partner'];
const entityHeaders = [
  {
    key: 'checked',
    label: '',
  },
  {
    key: 'name',
    label: 'Advertiser Name',
  },
  {
    key: 'id',
    label: 'Advertiser ID',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'partner',
    label: 'Partner',
  },
];

function getDropdowns(brandOptions, partnerOptions) {
  return {
    brand: {
      label: 'Brand',
      options: brandOptions.map(([value, label]) => ({
        label,
        value,
      })),
    },
    partner: {
      label: 'Partner',
      options: partnerOptions.map(([value, label]) => ({
        label,
        value,
      })),
    },
  };
}

function getItems(advertisers) {
  return advertisers.map((advertiser) => ({
    id: { value: advertiser.apiId },
    brand: { value: null },
    checked: { value: false },
    currency: { value: advertiser.currency },
    market: { value: advertiser.market },
    name: { value: advertiser.name },
    partner: { value: null },
  }));
}

function getAssociatedCount(items) {
  return items.reduce((sum, item) => {
    if (item.brand.value && item.partner.value) {
      return sum + 1;
    }
    return sum;
  }, 0);
}

function getSelectedCount(items) {
  return items.reduce((sum, item) => {
    if (item.checked.value) {
      return sum + 1;
    }
    return sum;
  }, 0);
}

function updateItem(item, key, value) {
  return {
    ...item,
    [key]: { value },
  };
}

function updateItemInArray(item, items) {
  const index = items.findIndex((i) => i.id.value === item.id.value);
  const before = items.slice(0, index);
  const after = items.slice(index + 1);

  return [...before, item, ...after];
}

async function submitAdvertisers(entity, items, url) {
  if (items.length === 0) return;

  const advertisers = items.map((item) => ({
    api_id: item.id.value,
    brand_id: item.brand.value,
    currency: item.currency.value,
    market: item.market.value,
    name: item.name.value,
    partner_id: item.partner.value,
  }));
  const entityData = {
    country_code: entity.countryCode,
    name: entity.name,
    profile_id: entity.profileId,
  };

  const formData = new FormData();
  formData.append('advertisers', JSON.stringify(advertisers));
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('entity', JSON.stringify(entityData));

  await fetch(url, {
    method: 'POST',
    body: formData,
  });
}

function AssociateAdvertisers({
  advertisers,
  brandOptions,
  currentStep,
  entity,
  formUrl,
  nextUrl,
  partnerOptions,
  steps,
}) {
  const [allSelected, setAllSelected] = useState(false);
  const [dropdowns, setDropdowns] = useState({});
  const [dropdownSelections, setDropdownSelections] = useState({});
  const [entitySelected, setEntitySelected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [items, setItems] = useState([]);

  const associatedCount = getAssociatedCount(items);
  const selectedCount = getSelectedCount(items);
  const isApplyDisabled = Object.keys(dropdownSelections).length === 0 || selectedCount === 0;
  const isContinueDisabled = associatedCount !== advertisers.length || isSubmitting;

  useViewPage('associate');

  useEffect(() => {
    setItems(getItems(advertisers));
  }, [advertisers]);

  useEffect(() => {
    setDropdowns(getDropdowns(brandOptions, partnerOptions));
  }, [brandOptions, partnerOptions]);

  const handleAllSelected = (value) => {
    setAllSelected(value);
    setItems((all) => all.map((item) => updateItem(item, 'checked', value)));
  };

  const handleEntitySelected = (value) => {
    setEntitySelected(value);
    setItems((all) => all.map((item) => updateItem(item, 'checked', value)));
  };

  const handleItemSelected = (item, value) => {
    const updated = updateItem(item, 'checked', value);
    setItems((all) => updateItemInArray(updated, all));
  };

  const handleItemChanged = (item, key, option) => {
    const updated = updateItem(item, key, option.value);
    setItems((all) => updateItemInArray(updated, all));
  };

  const handleDropdownChange = (key, option) => {
    setDropdownSelections((values) => ({
      ...values,
      [key]: option,
    }));
  };

  const handleBulkApply = () => {
    const entries = Object.entries(dropdownSelections);
    const updated = entries.reduce((obj, [key, option]) => ({
      ...obj,
      [key]: { value: option.value },
    }), {});

    setDropdownSelections({});
    setItems((all) => all.map((item) => {
      if (item.checked.value) {
        return {
          ...item,
          ...updated,
        };
      }
      return item;
    }));
  };

  const handleContinue = async () => {
    setIsSubmitting(true);
    await submitAdvertisers(entity, items, formUrl);
    window.location.href = nextUrl;
  };

  const renderDropdown = (key) => {
    const dropdown = dropdowns[key];

    if (!dropdown) return null;

    return (
      <Fragment key={key}>
        <Dropdown
          {...dropdown}
          menuProps={{ size: 'medium' }}
          selected={dropdownSelections[key]}
          size="medium"
          onChange={(option) => handleDropdownChange(key, option)}
        />
      </Fragment>
    );
  };

  return (
    <>
      <div className={styles.stepper}>
        <Stepper currentStep={currentStep} steps={steps} />
      </div>
      <Card>
        <div className={styles.bulkTitle}>
          <div>
            <div className="t-subtitle">Bulk Associate</div>
            <div className={`t-body-2 ${styles.bulkSubtitle}`}>
              Apply Brand and Partner to selected advertisers
            </div>
          </div>
          <Tag label={`${associatedCount}/${items.length} Associated`} />
        </div>
        <div className={styles.bulkControls}>
          { dropdownKeys.map((key) => renderDropdown(key)) }
          <Button
            disabled={isApplyDisabled}
            label="Apply"
            onClick={handleBulkApply}
          />
        </div>
      </Card>
      <div className={styles.all}>
        <Checkbox
          checked={allSelected}
          label="All Advertisers"
          onChange={(e) => handleAllSelected(e.target.checked)}
        />
      </div>
      <AssociateEntity
        dropdowns={dropdowns}
        headers={entityHeaders}
        items={items}
        name={entity.name}
        selected={entitySelected}
        onItemChanged={handleItemChanged}
        onItemSelected={handleItemSelected}
        onSelected={handleEntitySelected}
      />
      <div className={styles.buttons}>
        <div className="buttonGroup">
          <Button
            label="Cancel"
            url={newSettingsLinkedPlatformAccountPath()}
            variant="secondary"
          />
          <Button
            disabled={isContinueDisabled}
            label="Continue"
            onClick={handleContinue}
          />
        </div>
      </div>
    </>
  );
}

AssociateAdvertisers.propTypes = propTypes;

export default AssociateAdvertisers;
