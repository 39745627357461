import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ChannelLogo from 'components/atoms/ChannelLogo'
import Label from "components/reusable/label"
import { Checkbox, WidgetContent } from  "@duik/it"
import { Icon, Tooltip } from '@makeably/creativex-design-system'

propTypes =
  headers: PropTypes.arrayOf(
    PropTypes.shape(
      format: PropTypes.string
      tooltip: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string
      ])
      path: PropTypes.string
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    )
  ).isRequired
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape(
        confirmationMessage: PropTypes.string # confirmation if url is present
        disabled: PropTypes.bool # for disabled cell styling
        download: PropTypes.string # filename to use if downloading
        format: PropTypes.string #for icons or disabled link style
        method: PropTypes.string #HTTP method if url is present
        url: PropTypes.string # for a single url in a cell
        urls: PropTypes.arrayOf( #for multiple urls in one cell
          PropTypes.shape(
            confirmationMessage: PropTypes.string #confirmation if url is present
            dataTarget: PropTypes.string # for launching modals
            label: PropTypes.string #label value
            method: PropTypes.string #http method if url is present
            url: PropTypes.string #will be empty if this is a disabled link
            warning: PropTypes.bool # use warning link vs normal link
          )
        )
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.array,
          PropTypes.node,
        ])
        warning: PropTypes.bool # use warning link if url presenet
      )
    )
  ).isRequired
  emptyMessage: PropTypes.string      # message to display if no rows
  format: PropTypes.string            # default display if all the data has the same format
  hideUnselectedRows: PropTypes.bool
  includeRowNumber: PropTypes.bool    # display row numbers
  selectable: PropTypes.bool          # controlled component, rows in table are selectable
  selectedValues: PropTypes.arrayOf(  # corresponds to the first element of each row
    PropTypes.string
  )
  sortDirection: PropTypes.oneOf(['asc', 'desc'])
  sortIndex: PropTypes.number         # Which column index to sort by
  sortable: PropTypes.bool            # allow columns to be sorted
  onRowSelect: PropTypes.func
  onAllRowsSelect: PropTypes.func

# This is responsible for displaying and updating data in a table format
class DataTable extends React.Component
  @propTypes = propTypes

  @defaultProps =
    format: 'string'
    hideUnselectedRows: false
    includeRowNumber: false
    selectable: false
    sortDirection: 'desc'
    sortIndex: 0
    sortable: false

  constructor: (props) ->
    super(props)

    @state =
      sortIndex: props.sortIndex,
      sortAsc: props.sortDirection == 'asc'

  updateSort: (index) =>
    # If we update sort on the current column, reverse the direction
    if @state.sortIndex == index
      @setState(
        sortAsc: !@state.sortAsc # reverse it
      )
    # Otherwise update the column and sort by default sort direction
    else
      @setState(
        sortIndex: index,
        sortAsc: @props.sortDirection == 'asc'
      )

  allRowsSelected: ->
    @props.selectedValues.length is @props.rows.length

  formatDataValue: (data) =>
    if data.value is null
      return 'N/A'

    format = data.format || @props.format
    FormatUtil.format(format, data.value)

  sortFunction: (row) =>
    tableCell = row[@state.sortIndex]
    if tableCell.format is 'date'
      new Date(tableCell.value)
    else
      tableCell.value

  sortedRows: =>
    if @props.sortable
      sortedRows = _.sortBy(@props.rows, @sortFunction)
      if @state.sortAsc then sortedRows else sortedRows.reverse()
    else
      @props.rows

  renderUrlItem: (urlProps, idx) ->
    linkClasses = classNames('warning': urlProps.warning)

    if urlProps.dataTarget
      urlContent = `<a className={linkClasses} data-target={urlProps.dataTarget} href=''>{urlProps.label}</a>`
    else
      disabledUrl = _.isEmpty(urlProps.url)
      urlContent =
        if disabledUrl
          urlProps.label
        else
          `<a
            className={linkClasses}
            data-confirm={urlProps.confirmationMessage}
            data-method={urlProps.method}
            href={urlProps.url}
            >
              {urlProps.label}
          </a>`

    urlItemClassNames = classNames(
      'u-marginRightSm'
      'dataTableComponent-urlItem--disabled': disabledUrl
    )

    `<div key={idx} className={urlItemClassNames}>
      {urlContent}
    </div>`

  renderTableCell: (cellData, index, id) =>
    formattedValue = @formatDataValue(cellData)
    content =
      # index 0 is the first element, which we use as the id when this table is selectable
      if @props.selectable && index == 0
        isSelected = _.contains(@props.selectedValues, cellData.value)

        `<div className='u-flexRow u-flexAlignCenter'>
          <Checkbox
            checked={isSelected}
            id={cellData.value}
            onChange={this.props.onRowSelect}
            value={cellData.value}
          />
          <div>
            { formattedValue }
          </div>
        </div>`
      else if cellData.urls
        urlItems = _.map(cellData.urls, @renderUrlItem)

        `<div className='u-flexRow'>
          {urlItems}
        </div>`
      else if cellData.format is 'icon'
        if cellData.field is 'channel'
          if typeof cellData.value is 'object'
            `<div className='u-flexRow'>
              { cellData.value.map((c) => (
                  <div key={c} className='u-marginRightSm'>
                    <ChannelLogo channel={c} size='size-32' showPaid={false}/>
                  </div>
                ))
              }
            </div>`
          else
            `<ChannelLogo channel={cellData.value} size='size-32' showPaid={false}/>`
        else
          iconName =
            if cellData.value == 'Yes'
              'checkCircle'
            else
              'xCircle'

          `<Icon name={iconName}/>`
      else
        labelProps = { value: formattedValue }
        labelProps.path = cellData.path if cellData.path
        `<Label {...labelProps} />`

    if cellData.url
      linkClasses = classNames('warning': cellData.warning)
      content =
          `<a
            data-confirm={cellData.confirmationMessage}
            data-method={cellData.method}
            href={cellData.url}
            download={cellData.download}
            className={linkClasses}
            >
              {content}
          </a>`

    cellClassName = classNames('dataTableComponent-tableCell--disabled': cellData.disabled)
    cell_key = "table-cell--#{id}--#{index}"

    `<td className={cellClassName} key={cell_key}>
      {content}
    </td>`

  renderTableRow: (row, idx) =>
    # The first element in the row is the id so we use it as the key for iterating
    idForRow = row[0]['value']
    cellsForRow = for cell, index in row
      @renderTableCell(cell, index, idForRow)
    row_key = "table-row--#{idForRow}--#{idx}"

    if @props.includeRowNumber
      rowNumber = `<td><Label value={idx + 1} /></td>`

    # rows that are not selected will be hidden
    if @props.hideUnselectedRows and !_.contains(@props.selectedValues, row[0]['value'])
      style = { display: 'none' }

    `<tr key={row_key} style={style}>
      {rowNumber}
      {cellsForRow}
    </tr>`

  renderTooltip: (tooltip) ->
    if tooltip
      `<Tooltip label={tooltip} />`

  renderTableHeader: (headerVal, index) =>
    headerProps = { key: headerVal.value }
    sortIcon = null

    if @props.sortable
      # Have a sort icon for current sort column
      if index is @state.sortIndex
        icon = if @state.sortAsc then 'arrowUp' else 'arrowDown'
        sortIcon = `<Icon name={icon} />`

      headerProps['className'] = 'dataTableComponent-sortHeader'
      headerProps['onClick'] = => @updateSort(index)

    # Need a different layout if we have an all select box (click will
    # interfere with sorting by this column)
    if index == 0 && @props.selectable && @props.onAllRowsSelect
      `<th
        key={headerProps['key']}
        className={headerProps['className']}
       >
        <div className='u-flexRow u-flexAlignCenter'>
          <Checkbox
            checked={this.allRowsSelected()}
            id='select_all'
            onChange={this.props.onAllRowsSelect}
          />
          <div className='dataTableComponent-selectableHeader' onClick={headerProps['onClick']}>
            {headerVal.value}{sortIcon}
          </div>
        </div>
      </th>`
    else
      `<th {...headerProps}>
        <div className='dataTableComponent-withSortIcon'>
          {headerVal.value}
          {this.renderTooltip(headerVal.tooltip)}
          {sortIcon}
        </div>
      </th>`

  renderRowNumberHeader: ->
    return unless @props.includeRowNumber
    `<th className='dataTableComponent-sortHeader dataTableComponent-rowNumberHeader'>#</th>`

  renderBodyContent: ->
    if @props.rows.length >= 1
      this.sortedRows().map(this.renderTableRow)
    else if @props.emptyMessage
      `<tr>
        <td colSpan='2'>{this.props.emptyMessage}</td>
      </tr>`

  render: ->
    classes = classNames(
      'dataTableComponent'
      @props.className
    )

    `<div className={classes}>
      <table className="card-table">
        <thead>
          <tr>
            {this.renderRowNumberHeader()}
            {this.props.headers.map(this.renderTableHeader)}
          </tr>
        </thead>
        <tbody>
          { this.renderBodyContent() }
        </tbody>
      </table>
    </div>`

export default DataTable
export dataTablePropTypes = propTypes
