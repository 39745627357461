import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Widget } from '@duik/it';
import { Icon } from '@makeably/creativex-design-system';
import CsvButton from 'components/atoms/CsvButton';
import FilterDropdowns from 'components/filters/FilterDropdowns';
import ItemsTable from 'components/molecules/ItemsTable';
import Tabs from 'components/molecules/Tabs';
import {
  getDefaultSelections,
  getItemArrayFilterTest,
} from 'utilities/filtering';
import {
  getItemSortBy,
  getItemText,
  itemValueProps,
} from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import { searchItems as searchItemsFunc } from 'utilities/itemSearch';
import { setItemsText } from 'utilities/itemText';
import { updateValues } from 'utilities/object';
import { byObjectValue } from 'utilities/sort';
import { retrieveFromLocalStorageAndRemove } from 'utilities/storage';
import {
  changePage,
  getPage,
  getParams,
} from 'utilities/url';
import styles from './SearchableTable.module.css';

const itemProps = PropTypes.objectOf(itemValueProps);
const tabProps = PropTypes.shape({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.element,
});
const propTypes = {
  getHeaders: PropTypes.func.isRequired,
  groupedItems: PropTypes.objectOf(PropTypes.arrayOf(itemProps)).isRequired,
  tabDetails: PropTypes.arrayOf(tabProps).isRequired,
  addButton: PropTypes.node,
  canDownloadCsv: PropTypes.bool,
  // value of csvFileName should not include csv extension
  csvFileName: PropTypes.string,
  filterKeys: PropTypes.arrayOf(PropTypes.string),
  filterUsesItemValue: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.node,
  pageStorageKey: PropTypes.string,
};
const defaultProps = {
  addButton: undefined,
  canDownloadCsv: true,
  csvFileName: 'download',
  filterKeys: [],
  filterUsesItemValue: [],
  header: undefined,
  pageStorageKey: undefined,
};

function getTabs(groupedItems, tabDetails) {
  return tabDetails.reduce((all, tab) => {
    const count = groupedItems[tab.key]?.length ?? 0;

    if (count > 0) {
      return [...all, tab];
    }

    return all;
  }, []);
}

function getFilters(all, keys, usesItemValue) {
  if (!all) {
    return [];
  }

  return keys.map((key) => {
    const uniques = new Map();

    all.map((item) => {
      const value = item[key]?.value;
      const text = usesItemValue.includes(key) ? value : getItemText(item, key);

      return uniques.set(value, text);
    });

    const unsorted = [...uniques].reduce((arr, [value, label]) => {
      // Filter out empty values
      if (value) {
        return [...arr, {
          value,
          label,
        }];
      }

      return arr;
    }, []);

    const options = unsorted.sort(byObjectValue);

    return {
      key,
      options,
      label: key,
    };
  });
}

function filterItems(groupedItems, filterSelections) {
  const noFiltering = Object.values(filterSelections).every((value) => value.length === 0);

  if (noFiltering) {
    // If all filter selections are empty, just return the original items
    return groupedItems;
  }

  const itemFilterTest = getItemArrayFilterTest(filterSelections);
  const filterItemArray = (items) => items.filter(itemFilterTest);

  // Filter the array of items in each of the values of the groupedItems object
  return updateValues(groupedItems, filterItemArray);
}

function searchItems(itemsByStatus, searchString, searchKeys) {
  const searchFunc = (items) => searchItemsFunc(items, searchString, searchKeys);
  return updateValues(itemsByStatus, searchFunc);
}

// Formats the ItemElement for the item if not already done
// Adds timestamp to id to help render search change
function getCurrentItems(itemsByStatus, selectedTab) {
  const current = itemsByStatus[selectedTab] ?? [];
  const now = Date.now();

  return current.map((item) => {
    // Below is needed to allow search to render highlights
    const updated = setItemElement(item);
    // The timestamp is appended to the id so that react will rerender on search change
    const id = { value: `${item.id.value}:${now}` };

    return {
      ...updated,
      id,
    };
  });
}

function renderTabLabel(name, count, icon) {
  const text = `${name} (${count})`;

  if (icon) {
    return (
      <span className={styles.tabLabel}>
        { icon }
        { text }
      </span>
    );
  }
  return text;
}

function renderTabs(tabItems, itemsByStatus, selected, onSelectedChange) {
  const tabs = tabItems.map((item) => {
    const count = itemsByStatus[item.key]?.length ?? 0;
    return {
      key: item.key,
      label: renderTabLabel(item.name, count, item.icon),
    };
  });

  return (
    <Tabs
      selected={selected}
      tabs={tabs}
      onSelectedChange={onSelectedChange}
    />
  );
}

function renderSearch(value, onInput) {
  return (
    <div className={styles.search}>
      <input
        className={styles.searchInput}
        placeholder="Search"
        type="text"
        value={value}
        onChange={(e) => onInput(e.target.value)}
        onInput={(e) => onInput(e.target.value)}
      />
      <span className={styles.searchIcons}>
        { value && (
          <button className={styles.searchClear} type="button" onClick={() => onInput('')}>
            <Icon name="closeX" noWrapper />
          </button>
        ) }
        <Icon name="search" noWrapper />
      </span>
    </div>
  );
}

function SearchableTable({
  addButton,
  canDownloadCsv,
  csvFileName,
  filterKeys,
  filterUsesItemValue,
  getHeaders,
  groupedItems,
  header,
  pageStorageKey,
  tabDetails,
}) {
  let defaultsFromStorage;
  if (pageStorageKey) defaultsFromStorage = retrieveFromLocalStorageAndRemove(pageStorageKey);
  const tabFromStorage = defaultsFromStorage?.tab;
  const defaultFilters = defaultsFromStorage;

  const tabs = getTabs(groupedItems, tabDetails);
  const defaultTab = tabFromStorage ?? tabs[0]?.key ?? '';
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const params = getParams(window);
  const [page, setPage] = useState(getPage(params));

  const defaultSelections = getDefaultSelections(filterKeys, defaultFilters);
  const [filterSelections, setFilterSelections] = useState(defaultSelections);

  const [formattedByStatus, setFormattedByStatus] = useState(groupedItems);
  const [filteredByStatus, setFilteredByStatus] = useState(formattedByStatus);
  const [search, setSearch] = useState('');
  const [searchedByStatus, setSearchedByStatus] = useState(filteredByStatus);

  const [filters, setFilters] = useState(getFilters([], filterKeys, filterUsesItemValue));

  const tableHeaders = getHeaders(selectedTab);

  const sortFromStorage = defaultsFromStorage?.sort;
  const defaultSort = sortFromStorage ?? {};
  const [tableSort, setTableSort] = useState(defaultSort);
  const [sortedItems, setSortedItems] = useState([]);

  useEffect(() => {
    const resultByStatus = updateValues(groupedItems, setItemsText);
    setFormattedByStatus(resultByStatus);
    setFilters(getFilters(resultByStatus?.all, filterKeys, filterUsesItemValue));
    setFilterSelections(getDefaultSelections(filterKeys, defaultFilters));
    setSearch('');
  }, [groupedItems, filterKeys]);

  useEffect(() => {
    const foundByStatus = filterItems(formattedByStatus, filterSelections);
    setFilteredByStatus(foundByStatus);
  }, [filterSelections, formattedByStatus]);

  useEffect(() => {
    const searchKeys = tableHeaders.map((tableHeader) => tableHeader.key);
    const foundByStatus = searchItems(filteredByStatus, search, searchKeys);
    setSearchedByStatus(foundByStatus);
  }, [search, filteredByStatus]);

  useEffect(() => {
    const items = getCurrentItems(searchedByStatus, selectedTab);

    if (tableSort) {
      const byKeyDir = getItemSortBy(tableSort.key, tableSort.asc);
      const sorted = items.slice().sort(byKeyDir);

      setSortedItems(sorted);
    } else {
      setSortedItems(items);
    }
  }, [searchedByStatus, selectedTab, tableSort]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    changePage(newPage, params, window);
  };

  const handleTabChange = (key) => {
    handlePageChange(1);
    setSelectedTab(key);
  };

  const handleFilterChange = (key, value) => {
    handlePageChange(1);
    setFilterSelections((selections) => ({
      ...selections,
      [key]: value,
    }));
  };

  const navigationStyle = classNames(
    styles.navigation,
    { [styles.topBorder]: header },
  );

  const renderFilterRow = () => {
    const hasFilters = filters.length > 0;

    if (!hasFilters && !canDownloadCsv) return null;

    return (
      <div className={styles.filterRow}>
        { hasFilters && (
          <FilterDropdowns
            filters={filters}
            selections={filterSelections}
            onChange={handleFilterChange}
          />
        ) }
        { !hasFilters && <div /> }
        { canDownloadCsv && (
          <CsvButton
            filename={`${csvFileName}.csv`}
            headers={tableHeaders.filter((tableHeader) => tableHeader.key !== 'actions')}
            items={sortedItems}
          />
        ) }
      </div>
    );
  };

  return (
    <Widget>
      <div>
        { header }
        <div className={navigationStyle}>
          { renderTabs(tabs, searchedByStatus, selectedTab, handleTabChange) }
          <div className={styles.controls}>
            { addButton }
            { renderSearch(search, setSearch) }
          </div>
        </div>
        { renderFilterRow() }
        <div className={styles.table}>
          <ItemsTable
            headers={tableHeaders}
            items={sortedItems}
            page={page}
            sort={tableSort}
            onPageChange={handlePageChange}
            onSortChange={setTableSort}
          />
        </div>
      </div>
    </Widget>
  );
}

SearchableTable.propTypes = propTypes;
SearchableTable.defaultProps = defaultProps;

export default SearchableTable;
