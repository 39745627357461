import React from 'react';
import PropTypes from 'prop-types';
import { MessageModal } from '@makeably/creativex-design-system';

const VARIANTS = Object.freeze({
  HIDDEN: Symbol('hidden'),
  CREATE: Symbol('create'),
  DELETE: Symbol('delete'),
  UPDATE: Symbol('update'),
});

const propTypes = {
  dataType: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(VARIANTS)).isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteConfirm: PropTypes.func.isRequired,
  onUpdateConfirm: PropTypes.func.isRequired,
  affectedCount: PropTypes.number,
  code: PropTypes.string,
  defaultCode: PropTypes.string,
  defaultName: PropTypes.string,
  name: PropTypes.string,
  onCreateConfirm: PropTypes.func,
};

const defaultProps = {
  affectedCount: undefined,
  code: undefined,
  defaultCode: undefined,
  defaultName: undefined,
  name: undefined,
  onCreateConfirm: undefined,
};

function MappingWarningModal({
  variant,
  dataType,
  code,
  name,
  affectedCount,
  defaultCode,
  defaultName,
  onClose,
  onDeleteConfirm,
  onCreateConfirm,
  onUpdateConfirm,
}) {
  if (variant === VARIANTS.HIDDEN) {
    return null;
  }

  let title;
  let actionButtonLabel;
  let action;
  let explanation;
  let summary;

  if (variant === VARIANTS.DELETE) {
    title = 'Delete Code?';
    actionButtonLabel = 'Delete';
    action = onDeleteConfirm;

    if (defaultCode) {
      explanation = `Current and future creatives will no longer be mapped to the ${dataType} assigned to this code and will be automatically remapped to its ISO default. This will affect your company's data.`;
      summary = (
        <>
          <div className="u-marginBottom-8">
            { `Removed from ${code} → ${name}:` }
          </div>
          <div className="u-marginBottom-8">
            { `Number of creatives to be automatically re-mapped to ${defaultCode} → ${defaultName}:` }
            <p>{ affectedCount !== undefined ? affectedCount.toLocaleString() : 'LOADING...' }</p>
          </div>
        </>
      );
    } else {
      explanation = `Current and future creatives will no longer be mapped to the ${dataType} assigned to this code. This will affect your company's data.`;
      summary = (
        <div className="u-marginBottom-8">
          { `Number of creatives to be removed from ${code} → ${name}:` }
          <p>{ affectedCount !== undefined ? affectedCount.toLocaleString() : 'LOADING...' }</p>
        </div>
      );
    }
  } else {
    // Both create and update look the same; create here is technically an update to an ISO default.
    title = 'Change Code?';
    actionButtonLabel = 'Change';
    action = variant === VARIANTS.UPDATE ? onUpdateConfirm : onCreateConfirm;
    explanation = `Current creatives will be remapped to the ${dataType} assigned to this code. This will affect your company's data.`;
    summary = (
      <div className="u-marginBottom-8">
        { `Number of creatives to be mapped to ${code} → ${name}:` }
        <p>{ affectedCount !== undefined ? affectedCount.toLocaleString() : 'LOADING...' }</p>
      </div>
    );
  }

  return (
    <MessageModal
      actionButtonLabel={actionButtonLabel}
      actionButtonVariant="destructive"
      isOpen={variant !== VARIANTS.HIDDEN}
      title={title}
      onActionButtonClick={action}
      onClose={onClose}
    >
      <div className="u-marginBottom-16">
        { explanation }
      </div>
      <div className="t-body-2 u-marginBottom-8">
        Summary
      </div>
      { summary }
    </MessageModal>
  );
}

MappingWarningModal.propTypes = propTypes;
MappingWarningModal.defaultProps = defaultProps;

export { VARIANTS };

export default MappingWarningModal;
