import React from "react"
import Column from "components/reusable/column"
import Row from "components/reusable/row"
import Select from "components/reusable/select"
import PropTypes from "prop-types"

# This component is used to generate a form that lets users compare 2 topics
class CompareTopics extends React.Component
  @propTypes =
    # Form param to know which type of comparison to make
    comparisonType: PropTypes.oneOf(['EarnedPostGroupComparison', 'McrPostGroupComparison']).isRequired
    # array of topics used to populate the dropdowns in the form
    topics: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired

  constructor: (props) ->
    super(props)

    @state =
      topic_a_list: $.extend(true, [], @props.topics)
      topic_b_list: $.extend(true, [], @props.topics)
      topic_a: undefined
      topic_b: undefined

  topicOptionList: (topic) ->
    @state["#{topic}_list"]

  setSelectedOption: (topic) =>
    (value) =>
      # Disable the 'Select a topic' option on the list that is being changed
      updatedTopicList = $.extend(true, [], @topicOptionList(topic))
      updatedTopicList[0].disabled = true

      otherTopic = if topic is 'topic_a' then 'topic_b' else 'topic_a'
      otherTopicList = $.extend(true, [], @topicOptionList(otherTopic))
      for option in otherTopicList[1..]
        # Disable selected option in the other list
        option.disabled = (option.value is parseInt(value))

      @setState(
        "#{topic}": value
        "#{topic}_list": updatedTopicList
        "#{otherTopic}_list": otherTopicList
      )

  renderTopicSelect: (label, topic) ->
    inputName = "comparison[#{topic}]"

    `<div>
      <label>{label}</label>
      <Select
        inputName={inputName}
        onChange={this.setSelectedOption(topic)}
        options={this.topicOptionList(topic)}
        selected={this.state[topic]}
      />
    </div>`

  render: ->
    `<div>
      <input type='hidden' name='comparison[type]' value={this.props.comparisonType}/>
      <Row>
        <Column styleName='no-padding'>
          <h6>Compare Topics</h6>
          <hr/>
        </Column>
      </Row>
      <Row>
        <Column styleName='no-padding'>
          <p>Which two topics would you like to compare?</p>
        </Column>
      </Row>
      <Row>
        <Column styleName='no-padding' className="compareTopics-selection">
          {this.renderTopicSelect('Topic A', 'topic_a')}
          <h5 className="compareTopics-vsSpacer">vs.</h5>
          {this.renderTopicSelect('Topic B', 'topic_b')}
        </Column>
      </Row>
      <Row styleName='no-margin'>
        <Column styleName='no-padding'>
          <input
            className="btn"
            disabled={!this.state.topic_a || !this.state.topic_b}
            type="submit"
            value="Compare Topics"
          />
        </Column>
      </Row>
    </div>`

export default CompareTopics
