import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@duik/it';
import AuditFilterCollection from 'components/audit/audit_filter_collection.coffee';
import { chartProps as BarChartProps } from 'components/charts/BarChart';
import { chartProps as DoughnutChartProps } from 'components/charts/DoughnutChart';
import TabbedChart from 'components/charts/TabbedChart';
import TopLevelStats from 'components/dashboard_components/TopLevelStats';
import CardHeader from 'components/reusable/CardHeader';

// Disabling rule for the filters, too complex a design to break out props
/* eslint-disable react/forbid-prop-types,  */
export const propTypes = {
  brandBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      chart: PropTypes.shape(BarChartProps),
      dataLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  filters: PropTypes.object.isRequired,
  globalBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      chart: PropTypes.shape(DoughnutChartProps),
      dataLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  hasBrands: PropTypes.bool.isRequired,
  topLevelStats: PropTypes.shape({
    brandConsistency: PropTypes.number,
    brandInvestment: PropTypes.number,
    posts: PropTypes.number,
    spend: PropTypes.string,
  }).isRequired,
};
/* eslint-enable react/forbid-prop-types */

function renderEmptyDashboard() {
  return (
    <div className="brandsContainer dashboardLayout">
      <p className="center-align">
        <span>We couldn’t find any brand data.</span>
      </p>
    </div>
  );
}

// Returns the top level stat props in display order
function statProps({
  brandConsistency, brandInvestment, posts, spend,
}) {
  return [
    {
      format: 'number',
      label: 'Total Posts',
      tooltip: 'The total number of posts that are tracked for brand cues.',
      value: posts,
    },
    {
      format: 'percent',
      label: 'Brand Consistency',
      sigFigs: 1,
      tooltip: "The number of posts that meet your organization's threshold for consistency divided by the total number of posts that are tracked for brand cues.",
      value: brandConsistency,
    },
    {
      format: 'string',
      label: 'Total Spend',
      tooltip: 'The total spend associated with posts that are tracked for brand cues.',
      value: spend,
    },
    {
      format: 'percent',
      label: 'Branded Investment',
      sigFigs: 1,
      tooltip: "The total spend behind posts that meet your organization's threshold for consistency, divided by the total spend of all posts tracked for brand cues.",
      value: brandInvestment,
    },
  ];
}

function Overview(props) {
  const {
    brandBreakdown,
    filters: {
      authenticityToken,
      dateRange,
      dateRangeProps,
      existingFilters,
      filterOptions,
      formProps,
      redirectOptions,
    },
    globalBreakdown,
    hasBrands,
    topLevelStats,
  } = props;

  if (!hasBrands) return renderEmptyDashboard();

  return (
    <div className="brandConsistencyOverview">
      <AuditFilterCollection
        authenticityToken={authenticityToken}
        dateRange={dateRange}
        dateRangeProps={dateRangeProps}
        existingFilters={existingFilters}
        filterOptions={filterOptions}
        formProps={formProps}
        redirectOptions={redirectOptions}
      />
      <TopLevelStats stats={statProps(topLevelStats)} />
      <Widget className="globalBreakdown">
        <CardHeader
          header="Branded Content Breakdown"
          subHeader="Breakdown of posts and investment by those that meet your organization's minimum threshold for consistency (branded) versus those that don't (unbranded)."
        />
        <TabbedChart
          charts={globalBreakdown}
          tabColor="darkblue"
          type="doughnut"
          bodyContained
          overview
        />
      </Widget>
      <Widget className="brandBreakdown">
        <CardHeader
          header="Brand Breakdown"
          subHeader="Brand consistency score breakdown by brand."
        />
        <TabbedChart
          charts={brandBreakdown}
          layoutPadding={150}
          tabColor="darkblue"
          tickPadding={150}
          type="bar"
          bodyContained
        />
      </Widget>
      <div className="push" />
    </div>
  );
}

Overview.propTypes = propTypes;

export default Overview;
