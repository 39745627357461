import React, { useState } from 'react';
import {
  Button,
  Card,
  Divider,
  TextInput,
} from '@makeably/creativex-design-system';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { adminDv3bIndexPath } from 'utilities/routes';
import styles from './Dv3bForm.module.css';

function Dv3bForm() {
  const [sourceUrl, setSourceUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  return (
    <Card className={styles.container}>
      <div className="u-marginBottom-16">
        <h5>DV3B</h5>
        <div className="t-subtitle u-marginTop-8">
          Associate an Advertiser&apos;s Source URL with a Preview URL containing a DV3B
        </div>
      </div>
      <Divider />
      <form action={adminDv3bIndexPath()} method="post">
        <AuthenticityTokenInput />
        <div className="u-flexRow u-marginTop-16">
          <div className="u-flexGrow">
            <TextInput
              label="Source Url"
              name="source_url"
              placeholder="Add the Source URL"
              size="large"
              value={sourceUrl}
              onChange={(url) => setSourceUrl(url)}
            />
          </div>
          <div className="u-flexGrow">
            <TextInput
              label="Preview Url"
              name="preview_url"
              placeholder="Add the Preview URL"
              size="large"
              value={previewUrl}
              onChange={(url) => setPreviewUrl(url)}
            />
          </div>
        </div>
        <div className="u-flexRow u-justifyFlexEnd u-marginTop-16">
          <Button
            disabled={!(sourceUrl && previewUrl)}
            label="Submit"
            type="submit"
          />
        </div>
      </form>
    </Card>
  );
}

export default Dv3bForm;
