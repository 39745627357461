import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  MessageModal,
  TextInput,
} from '@makeably/creativex-design-system';
import { track } from 'utilities/mixpanel';
import { getAuthenticityToken } from 'utilities/requests';
import { creativeLifecycleCampaignsPath } from 'utilities/routes';
import styles from './CreateCampaignModal.module.css';

const propTypes = {
  brandOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

async function createCampaign(campaignName, brandId) {
  const response = await fetch(creativeLifecycleCampaignsPath(), {
    method: 'POST',
    body: JSON.stringify({
      name: campaignName,
      organization_brand_id: brandId,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getAuthenticityToken(),
    },
  });

  return response.json();
}

function CreateCampaignModal({
  brandOptions,
  isOpen,
  onClose,
  onSuccess,
}) {
  const [name, setName] = useState('');
  const [brand, setBrand] = useState();
  const [errorText, setErrorText] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const onClear = () => {
    setName('');
    setBrand(undefined);
  };

  const onSave = async () => {
    track('create_campaign');
    setIsSaving(true);

    const campaignResponse = await createCampaign(name, brand.value);

    setIsSaving(false);
    if (campaignResponse?.id) {
      onSuccess({
        brand: campaignResponse.brand,
        id: campaignResponse.id,
        name: campaignResponse.name,
      });
      onClear();
      onClose();
    } else {
      setErrorText(campaignResponse?.error || 'Something went wrong, please try again');
    }
  };

  const onCancel = () => {
    onClear();
    onClose();
  };

  return (
    <MessageModal
      actionButtonDisabled={!name || !brand || isSaving}
      actionButtonLabel="Save"
      isOpen={isOpen}
      subtitle="Add new Campaign name and select the brand associated with it."
      title="Add New Campaign"
      onActionButtonClick={onSave}
      onClose={onCancel}
    >
      <div className={styles.modalContent}>
        <TextInput
          label="Campaign Name"
          size="medium"
          value={name}
          onChange={setName}
        />
        <Dropdown
          label="Brand"
          menuProps={{ size: 'medium' }}
          options={brandOptions}
          selected={brand}
          size="medium"
          onChange={setBrand}
        />
        { errorText && <div className={styles.errorText}>{ errorText }</div> }
      </div>
    </MessageModal>
  );
}

CreateCampaignModal.propTypes = propTypes;

export default CreateCampaignModal;
