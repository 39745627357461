// Component to add a view page event on every page load
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { track } from 'utilities/mixpanel';
import { saveToLocalStorage } from 'utilities/storage';

const propTypes = {
  controller: PropTypes.shape({
    action: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  productArea: PropTypes.string.isRequired,
  userAttributes: PropTypes.shape({
    userEmail: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    userType: PropTypes.string.isRequired,
  }).isRequired,
};

function Mixpanel({
  controller,
  productArea,
  userAttributes,
}) {
  useEffect(() => {
    saveToLocalStorage(controller, 'controller');
    saveToLocalStorage(productArea, 'productArea');
    saveToLocalStorage(userAttributes, 'userAttributes');

    track('view_page');
  }, []);

  return null;
}

Mixpanel.propTypes = propTypes;

export default Mixpanel;
