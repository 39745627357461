import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  FormGroupContainer,
  Radio,
} from '@duik/it';
import {
  Icon,
  Toggle,
} from '@makeably/creativex-design-system';
import StringHelper from 'components/utils/StringHelper';

export const GRID_FILL_COLOR_KEY = 'evalPageGridFillColor';

const BLACK = 'black';
const BLUE = 'blue';
const GREEN = 'green';
const WHITE = 'white';
const FILL_COLORS = [BLUE, GREEN];
const GRID_COLORS = [BLACK, WHITE];

export const defaultGridOptions = {
  gridFillColor: localStorage.getItem(GRID_FILL_COLOR_KEY) ?? GREEN,
  gridlineColor: WHITE,
  showGridlines: false,
};

export const gridOptionProps = PropTypes.shape({
  gridFillColor: PropTypes.oneOf(FILL_COLORS),
  gridlineColor: PropTypes.oneOf(GRID_COLORS),
  showGridlines: PropTypes.bool,
});

const propTypes = {
  callback: PropTypes.func.isRequired,
  gridOptions: gridOptionProps.isRequired,
};

function EvaluationGridSelector(props) {
  const {
    callback,
    gridOptions,
  } = props;

  const {
    gridFillColor,
    gridlineColor,
    showGridlines,
  } = gridOptions;

  const handleColorChange = (key, color) => {
    callback({
      ...gridOptions,
      [key]: color,
    });
  };

  const handleFillColorChange = (color) => {
    localStorage.setItem(GRID_FILL_COLOR_KEY, color);
    handleColorChange('gridFillColor', color);
  };

  const gridlineOptionButton = ({ handleToggle }) => (
    <Button clear sm square onClick={handleToggle}>
      <Icon name="moreDots" />
    </Button>
  );

  return (
    <div className="evaluationPage-assetGridSelector">
      <div className="u-marginRight">
        <Toggle
          checked={showGridlines}
          label="Show Grid"
          right
          onChange={() => callback({
            ...gridOptions,
            showGridlines: !showGridlines,
          })}
        />
      </div>
      <Dropdown ButtonComponent={gridlineOptionButton} menuPosition="top-left">
        {
          (_handlers) => (
            <div className="gridlineOptionMenu">
              <FormGroupContainer horizontal>
                <FormGroupContainer>
                  <div className="asLabel u-textAlignLeft">Grid Color:</div>
                  {
                    GRID_COLORS.map((color) => (
                      <Radio
                        key={color}
                        checked={gridlineColor === color}
                        label={StringHelper.titleize(color)}
                        name="gridColor"
                        onChange={() => handleColorChange('gridlineColor', color)}
                      />
                    ))
                  }
                </FormGroupContainer>
                <FormGroupContainer>
                  <div className="asLabel u-textAlignLeft">Fill Color:</div>
                  {
                    FILL_COLORS.map((color) => (
                      <Radio
                        key={color}
                        checked={gridFillColor === color}
                        label={StringHelper.titleize(color)}
                        name="fillColor"
                        onChange={() => handleFillColorChange(color)}
                      />
                    ))
                  }
                </FormGroupContainer>
              </FormGroupContainer>
            </div>
          )
        }
      </Dropdown>
    </div>
  );
}

EvaluationGridSelector.propTypes = propTypes;

export default EvaluationGridSelector;
