import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Icon,
  MessageModal,
  TextInput,
  Tooltip,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import { post } from 'utilities/requests';
import {
  adminCategoriesPath,
  adminIndustriesPath,
} from 'utilities/routes';

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  industryOptions: PropTypes.arrayOf(optionProps),
};

const defaultProps = {
  industryOptions: [],
};

async function submitCategory(name, industryId) {
  const url = adminCategoriesPath();

  const formData = new FormData();
  formData.append('name', name);
  formData.append('industry_id', industryId);

  const response = await post(url, formData);
  return response.data;
}

function NewCategoryModal({
  industryOptions,
  isOpen,
  onClose,
}) {
  const [name, setName] = useState('');
  const [industry, setIndustry] = useState();

  const onSave = async () => {
    const data = await submitCategory(name, industry?.value);

    if (data.success) {
      addToast('New category successfully added');
    } else {
      addToast('Category already exists in the selected industry', { type: 'error' });
    }

    window.location.reload();
  };

  return (
    <MessageModal
      actionButtonDisabled={!name || !industry}
      actionButtonLabel="Save"
      isOpen={isOpen}
      size="medium"
      title="Add New Category"
      onActionButtonClick={onSave}
      onClose={onClose}
    >
      <div className="u-flexColumn u-gap-16">
        <div className="u-flexColumn u-gap-8">
          <Dropdown
            label="Industry"
            menuProps={{ size: 'medium' }}
            options={industryOptions}
            selected={industry}
            size="medium"
            onChange={(opt) => setIndustry(opt)}
          />
          <div className="u-flexRow u-alignCenter u-gap-8">
            <Tooltip
              direction="bottom"
              label="If your desired industry is missing, click this link to open the Industry Management page in a new tab and add it there."
            />
            <a className="u-flexRow u-alignCenter u-gap-8" href={adminIndustriesPath()}>
              <span>Industry Manager</span>
              <Icon color="grey" name="externalLink" noWrapper />
            </a>
          </div>
        </div>
        <TextInput
          label="Category Name"
          placeholder="Category Name"
          size="large"
          value={name}
          onChange={(value) => setName(value)}
        />
      </div>
    </MessageModal>
  );
}

NewCategoryModal.propTypes = propTypes;
NewCategoryModal.defaultProps = defaultProps;

export default NewCategoryModal;
