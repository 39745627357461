import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  FormGroup,
  FormGroupContainer,
  Select,
  TextField,
} from '@duik/it';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import DefaultQueryFields, {
  propTypes as defaultQueryFieldProps,
} from 'components/settings/default_query_fields.coffee';
import UserInfo from 'components/utils/content/UserInfo';
import ObjectHelper from 'components/utils/ObjectHelper';

const propTypes = {
  defaultQueryProps: PropTypes.shape(defaultQueryFieldProps).isRequired,
  url: PropTypes.string.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

function renderInput(testId, name, value) {
  return (
    <HiddenInput
      data-testid={testId}
      name={name}
      value={value}
    />
  );
}

function renderJobFunctionInput(jobFunction) {
  if (!jobFunction) return null;

  return renderInput('jobFunctionInput', 'user[job_function]', jobFunction.value);
}

function renderJobTitleInput(jobTitle) {
  if (!jobTitle) return null;

  return renderInput('jobTitleInput', 'user[job_title]', jobTitle.value);
}

function formIncomplete(jobFunction, jobTitle) {
  return !(jobFunction && jobTitle);
}

class ActivateSSO extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobFunction: undefined,
      jobTitle: undefined,
    };
  }

  onFieldChange(value, name) {
    this.setState({ [name]: value });
  }

  render() {
    const {
      defaultQueryProps: {
        brandOptions,
        channelOptions,
        marketOptions,
        selectedValues,
      },
      url,
      user: {
        email,
        firstName,
        lastName,
      },
    } = this.props;

    const {
      jobFunction,
      jobTitle,
    } = this.state;

    const onOptionClick = (value, name) => this.onFieldChange(value, name);

    return (
      <form action={url} method="post">
        <AuthenticityTokenInput />
        { renderJobFunctionInput(jobFunction) }
        { renderJobTitleInput(jobTitle) }
        <FormGroupContainer>
          <FormGroup>
            <TextField
              defaultValue={email}
              type="email"
              disabled
            />
          </FormGroup>
          <FormGroup>
            <TextField
              defaultValue={`${firstName} ${lastName}`}
              disabled
            />
          </FormGroup>
          <Divider />
          <FormGroupContainer horizontal>
            <FormGroup>
              <Select
                activeOption={jobFunction}
                buttonProps={{
                  'data-testid': 'jobFunctionSelect',
                  transparent: true,
                }}
                label="Function*"
                name="jobFunction"
                options={ObjectHelper.valuesToObjects(UserInfo.jobFunctionOptions())}
                block
                onOptionClick={onOptionClick}
              />
            </FormGroup>
            <FormGroup>
              <Select
                activeOption={jobTitle}
                buttonProps={{
                  'data-testid': 'jobTitleSelect',
                  transparent: true,
                }}
                label="Role*"
                name="jobTitle"
                options={ObjectHelper.valuesToObjects(UserInfo.jobTitleOptions())}
                block
                onOptionClick={onOptionClick}
              />
            </FormGroup>
          </FormGroupContainer>
          <Divider />
        </FormGroupContainer>
        <DefaultQueryFields
          brandOptions={brandOptions}
          channelOptions={channelOptions}
          marketOptions={marketOptions}
          selectedValues={selectedValues}
        />
        <FormGroupContainer>
          <FormGroup>
            <Button
              Component="input"
              disabled={formIncomplete(jobFunction, jobTitle)}
              name="commit"
              type="submit"
              value="Save"
              primary
            />
          </FormGroup>
        </FormGroupContainer>
      </form>
    );
  }
}

ActivateSSO.propTypes = propTypes;

export default ActivateSSO;
