import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Spinner,
} from '@makeably/creativex-design-system';
import ChangeLog from 'components/molecules/ChangeLog';
import { addToast } from 'components/organisms/Toasts';
import { scoreDataScorePath } from 'utilities/routes';
import styles from './History.module.css';

const propTypes = {
  scoreId: PropTypes.number.isRequired,
};

function History({ scoreId }) {
  const [scoreData, setScoreData] = useState();

  useEffect(() => {
    async function fetchProps() {
      const response = await fetch(scoreDataScorePath(scoreId, { type: 'history' }));

      if (response.ok) {
        const data = await response.json();

        setScoreData(data);
      } else {
        addToast('Something went wrong, please reload to try again.', {
          size: 'large',
          type: 'error',
        });
      }
    }

    fetchProps();
  }, [scoreId]);

  if (!scoreData) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  const {
    logs,
  } = scoreData;

  return (
    <Card>
      <ChangeLog logs={logs} />
    </Card>
  );
}

History.propTypes = propTypes;

export default History;
