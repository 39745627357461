import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { clampToRange } from 'utilities/number';
import styles from './Slider.module.css';

const propTypes = {
  percent: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  onMouseClicked: PropTypes.func,
};

const defaultProps = {
  backgroundColor: 'white',
  color: 'purple',
  onMouseClicked: undefined,
};

const sliderColors = {
  purple: 'var(--accent-color)',
  grey: 'var(--grey-500)',
  white: 'var(--white)',
};

function Slider({
  backgroundColor,
  color,
  onChange,
  onMouseClicked,
  percent,
}) {
  const sliderRef = useRef(null);

  const [isMouseClicked, setIsMouseClicked] = useState(false);
  const [isMouseHover, setIsMouseHover] = useState(false);
  const [isTabFocused, setIsTabFocused] = useState(false);
  const [left, setLeft] = useState();
  const [width, setWidth] = useState();

  useEffect(() => {
    onMouseClicked?.(isMouseClicked);
  }, [isMouseClicked]);

  const updateProgressDimensions = () => {
    const rectangle = sliderRef.current.getBoundingClientRect();
    setLeft(rectangle.left);
    setWidth(rectangle.width);
  };

  useLayoutEffect(() => {
    updateProgressDimensions();

    const observer = new ResizeObserver(updateProgressDimensions);
    observer.observe(sliderRef.current);

    return () => observer.unobserve(sliderRef.current);
  }, []);

  const onMouseDrag = (e) => {
    const offset = e.pageX - left;
    const adjustedOffset = clampToRange(0, offset, width);
    onChange(adjustedOffset / width);
  };

  const handleMouseMove = (e) => {
    if (isMouseClicked) onMouseDrag(e);
  };

  const handleExternalMouseMove = (e) => {
    e.preventDefault();
    onMouseDrag(e);
  };

  const handleMouseUp = (e) => {
    setIsMouseClicked(false);

    onMouseDrag(e);

    // note: allow dragging outside of slider container
    document.removeEventListener('mousemove', handleExternalMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = () => {
    setIsMouseClicked(true);

    // note: allow dragging outside of slider container
    document.addEventListener('mousemove', handleExternalMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const backgroundColorVar = sliderColors[backgroundColor] ?? sliderColors.white;

  const sliderBarStyle = {
    backgroundColor: backgroundColorVar,
  };

  const colorVar = sliderColors[color] ?? sliderColors.purple;

  const sliderFillStyle = {
    backgroundColor: colorVar,
    width: `${percent * 100}%`,
  };

  const sliderDotStyle = {
    backgroundColor: colorVar,
    left: `${percent * 100}%`,
    opacity: isMouseHover || isMouseClicked || isTabFocused ? 1 : 0,
  };

  return (
    <div
      ref={sliderRef}
      className={styles.sliderContainer}
      role="progressbar"
      tabIndex={0}
      onBlur={() => setIsTabFocused(false)}
      onFocus={() => setIsTabFocused(true)}
      onMouseDown={handleMouseDown}
      onMouseEnter={() => setIsMouseHover(true)}
      onMouseLeave={() => setIsMouseHover(false)}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div className={styles.sliderBar} style={sliderBarStyle}>
        <div className={styles.sliderFill} style={sliderFillStyle} />
        <div className={styles.sliderDot} style={sliderDotStyle} />
      </div>
    </div>
  );
}

Slider.propTypes = propTypes;
Slider.defaultProps = defaultProps;

export default Slider;
