import React from "react"
import PropTypes from "prop-types"
import StringHelper from 'components/utils/StringHelper'
import { HiddenInput } from "components/reusable/HiddenInput"
import MomentUtils from '@date-io/moment'
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';


# Used for selecting a date for the processing date range form.
class ProcessingDateSelect extends React.Component
  @propTypes =
    inputName: PropTypes.string # form input name ex. company[data_start_date]
    value: PropTypes.string # format: YYYY-MM-DD, null is acceptable

  constructor: (props) ->
    super(props)

    @state =
      value: props.value

  handleChange: (value) =>
    newVal = if value then value.format('YYYY-MM-DD') else value
    @setState(value: newVal)

  render: ->
    # Need '' vs undefined so we can remove its value
    inputVal = if typeof(this.state.value) is 'string' then this.state.value else ''

    `<div>
      <HiddenInput name={this.props.inputName} value={inputVal}/>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          autoOk
          clearable
          format="YYYY-MM-DD"
          inputVariant="outlined"
          size='small'
          value={this.state.value}
          variant="dialog"
          views={['year', 'month', 'date']}
          onChange={this.handleChange}
        />
      </MuiPickersUtilsProvider>
    </div>`

export default ProcessingDateSelect
