import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@makeably/creativex-design-system';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  header: PropTypes.bool,
};

const defaultProps = {
  checked: false,
  disabled: false,
  header: false,
};

function SelectableTableCell(props) {
  const {
    checked,
    disabled,
    header,
    onChange,
  } = props;

  const cellType = header ? 'h' : 'd';
  const CellTag = `t${cellType}`;

  return (
    <CellTag>
      <Checkbox
        autoComplete="off"
        checked={checked}
        disabled={disabled}
        onChange={(_e) => onChange()}
      />
    </CellTag>
  );
}

SelectableTableCell.propTypes = propTypes;
SelectableTableCell.defaultProps = defaultProps;

export default SelectableTableCell;
