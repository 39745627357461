import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { HiddenInput } from "components/reusable/HiddenInput"
import MixpanelTracker from "components/utils/MixpanelTracker"
import { FormGroupContainer, FormGroup, Select } from  "@duik/it"
import SearchableSelect from "components/reusable/SearchableSelect"

# Responsible for displaying the default query (Brand, Market & Channel) fields
# for a user
class DefaultQueryFields extends React.Component
  @propTypes =
    brandOptions: PropTypes.arrayOf(PropTypes.object).isRequired
    channelOptions: PropTypes.arrayOf(PropTypes.object).isRequired
    marketOptions: PropTypes.arrayOf(PropTypes.object).isRequired
    selectedValues: PropTypes.shape(
      brand: PropTypes.arrayOf(PropTypes.string)
      channel: PropTypes.arrayOf(PropTypes.string)
      market: PropTypes.arrayOf(PropTypes.string)
    )

  constructor: (props) ->
    super(props)
    selectedValues = props.selectedValues || {}

    @state =
      brand: selectedValues.brand || []
      channel: selectedValues.channel || []
      market: selectedValues.market || []

  trackFilterValueAdded: (label, value) ->
    MixpanelTracker.trackFilterSelection(label, value)

  trackFilterValueRemoved: (label, value) ->
    MixpanelTracker.trackFilterSelection("Deselects #{label}", value)

  handleChange: (inputType, label) =>
    (option) =>
      value = option.value
      if @state[inputType].includes(value)
        # value already selected, now we want to remove it
        values = _.without(@state[inputType], value)
        this.trackFilterValueRemoved(label, value)
      else
        values = @state[inputType].concat([value])
        this.trackFilterValueAdded(label, value)

      @setState("#{inputType}": values)

  renderInput: (options, label, inputType) ->
    placeholder = "Choose one or more #{inputType}..."
    selected = _.filter(options, (option) => _.contains(@state[inputType], option.value))

    `<FormGroup className='userDefaultQuery-field'>
      <SearchableSelect
        activeOption={selected}
        label={label}
        multiple
        onOptionClick={this.handleChange(inputType, label)}
        options={options}
        placeholder={placeholder}
      />
    </FormGroup>`

  renderHiddenInput: (inputName) ->
    (value, idx) ->
      key = "#{inputName}-#{idx}"
      `<HiddenInput key={key} name={inputName} value={value} />`

  renderHiddenInputs: (input) =>
    inputName = "default_query[#{input}][]"
    values = @state[input]
    return unless values.length > 0

    `<span>
      {values.map(this.renderHiddenInput(inputName))}
    </span>`

  render: ->
    `<div className='u-marginAbove'>
      <div className='userDefaultQuery-header'>Set Your Default View</div>
      <div className='userDefaultQuery-note u-marginBelowSm'>
        Select your priority brands, markets, and channels so that your
        dashboard is automatically filtered based on what’s most important to
        you. You can adjust this setting anytime. If you're on a global team,
        leave these selections blank to see data across all brands, markets,
        and channels as your default.
      </div>
      <FormGroupContainer>
        {this.renderInput(this.props.brandOptions, 'Brand', 'brand')}
        {this.renderInput(this.props.channelOptions, 'Channel', 'channel')}
        {this.renderInput(this.props.marketOptions, 'Market', 'market')}
      </FormGroupContainer>
      {this.renderHiddenInputs('brand')}
      {this.renderHiddenInputs('channel')}
      {this.renderHiddenInputs('market')}
    </div>`

export propTypes = DefaultQueryFields.propTypes
export default DefaultQueryFields
