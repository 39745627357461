import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ClickableTag,
  Divider,
  MessageModal,
  MultipleDropdown,
  removeObjectByValue,
  toggleObject,
} from '@makeably/creativex-design-system';

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const propTypes = {
  brandDisabled: PropTypes.bool.isRequired,
  brandOptions: PropTypes.arrayOf(optionProps).isRequired,
  isOpen: PropTypes.bool.isRequired,
  marketDisabled: PropTypes.bool.isRequired,
  marketOptions: PropTypes.arrayOf(optionProps).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  config: PropTypes.shape({
    brands: PropTypes.arrayOf(optionProps),
    markets: PropTypes.arrayOf(optionProps),
  }),
};

const defaultProps = {
  config: undefined,
};

function RepresentationBmeModal({
  brandDisabled,
  brandOptions,
  config,
  isOpen,
  marketDisabled,
  marketOptions,
  onClose,
  onSave,
}) {
  const [brandSelections, setBrandSelections] = useState(config?.brands || []);
  const [marketSelections, setMarketSelections] = useState(config?.markets || []);

  const removeOption = (setSelection, option) => {
    setSelection((current) => removeObjectByValue(current, option));
  };

  const handleSave = () => {
    onSave(brandSelections, marketSelections);
  };

  return (
    <MessageModal
      actionButtonDisabled={brandSelections?.length === 0 || marketSelections?.length === 0}
      actionButtonLabel="Save"
      isOpen={isOpen}
      size="medium"
      title="Create/Edit Brand/Market Entity"
      onActionButtonClick={() => handleSave()}
      onClose={onClose}
    >
      <MultipleDropdown
        disabled={brandDisabled}
        label="Brand"
        options={brandOptions}
        selected={brandSelections}
        onChange={(brands) => setBrandSelections(toggleObject(brandSelections, brands))}
      />
      { brandSelections && !brandDisabled && (
        <div className="u-flexRow u-flexWrap u-marginTop-16 u-gap-8">
          { brandSelections.map((brand) => (
            <ClickableTag
              key={brand.value}
              label={brand.label}
              onRemove={() => removeOption(setBrandSelections, brand)}
            />
          )) }
        </div>
      ) }
      <Divider margin />
      <MultipleDropdown
        disabled={marketDisabled}
        label="Market"
        options={marketOptions}
        selected={marketSelections}
        onChange={(markets) => setMarketSelections(toggleObject(marketSelections, markets))}
      />
      { marketSelections && !marketDisabled && (
        <div className="u-flexRow u-flexWrap u-marginTop-16 u-gap-8">
          { marketSelections.map((market) => (
            <ClickableTag
              key={market.value}
              label={market.label}
              onRemove={() => removeOption(setMarketSelections, market)}
            />
          )) }
        </div>
      ) }
    </MessageModal>
  );
}

RepresentationBmeModal.propTypes = propTypes;
RepresentationBmeModal.defaultProps = defaultProps;

export default RepresentationBmeModal;
