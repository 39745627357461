# TODO: Remove, use the duik button
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

# A button!
# Allows for an onClick callback and/or href link.
class Button extends React.Component
  @propTypes =
    confirm: PropTypes.string
    disabled: PropTypes.bool
    download: PropTypes.string
    label: PropTypes.string
    link: PropTypes.string
    method: PropTypes.string  # NB: Used for when you need a button to not be a GET request
    onClick: PropTypes.func

  @defaultProps =
    disabled: false

  classes: ->
    classNames(
      'btn',
      @props.className,
      @props.styleName,
      'disabled': @props.disabled
    )

  clickCallback: (onClick) ->
    (e) ->
      # since we have href, prevent default and bubble event up
      e.preventDefault()
      onClick(e)

  render: ->
    anchorProps =
      className: @classes()

    anchorProps['download'] = @props.download

    # from the html spec, `a` tags must have an href attribute
    anchorProps['href'] = if @props.link then @props.link else ''

    # If we are disabled we don't want any interaction
    if @props.disabled
      # Provide an empty onClick which will prevent any hrefs from taking place
      anchorProps['onClick'] = @clickCallback(->)
    else
      anchorProps['onClick'] = @clickCallback(@props.onClick) if @props.onClick

      # If you want to use a button for something other than a GET
      # request, this will allow you to use a button for POST request
      if @props.method
        anchorProps['data-method'] = @props.method
        anchorProps['rel'] = "nofollow"

      if @props.confirm
        anchorProps['data-confirm'] = @props.confirm

      # Set list of allowable data-*'s here
      # Don't set if disabled to prevent action from taking place
      $.extend(anchorProps, _.pick(@props, ['data-target']))

    `<a {...anchorProps}>
      {this.props.children || this.props.label}
     </a>`

export default Button
