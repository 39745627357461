import React from 'react';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
} from '@duik/it';
import DoughnutChart from 'components/charts/DoughnutChart';
import LineChart from 'components/charts/LineChart';
import CardHeader from 'components/reusable/CardHeader';

const propTypes = {
  brandCue: PropTypes.string.isRequired,
  lineChart: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  pieChart: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

function BrandCueCharts({
  brandCue,
  lineChart,
  pieChart,
}) {
  return (
    <>
      <Widget>
        <CardHeader header="Creative Type" />
        <WidgetContent>
          <DoughnutChart {...pieChart} />
        </WidgetContent>
      </Widget>
      <Widget className="brandCue-timeseries">
        <CardHeader header={`${brandCue} Usage Over Time`} />
        <WidgetContent>
          <LineChart {...lineChart} />
        </WidgetContent>
      </Widget>
    </>
  );
}

BrandCueCharts.propTypes = propTypes;

export default BrandCueCharts;
