import React from "react"
import MultiSelect from "components/reusable/multi_select"
import PropTypes from "prop-types"

# Used to handle channel selection for the new companies form.
class LinkableChannelsSelect extends React.Component
  @propTypes =
    inputName: PropTypes.string
    options: PropTypes.arrayOf(
      PropTypes.shape(
        disabled: PropTypes.bool
        label: PropTypes.string
        value: PropTypes.string
      )
    )
    selected: PropTypes.arrayOf(
      PropTypes.string
    )

  constructor: (props) ->
    super(props)

    @state =
      selected: props.selected || []

  handleChange: (value) =>
    @setState(selected: value)

  render: ->
    `<MultiSelect
      {..._.omit(this.props, 'selected')}
      onChange={this.handleChange}
      selected={this.state.selected}
    />`

export default LinkableChannelsSelect
