import React from 'react';
import {
  Button,
  Card,
  Icon,
} from '@makeably/creativex-design-system';
import { newSettingsLinkedPlatformAccountPath } from 'utilities/routes';

function Confirm() {
  return (
    <Card>
      <div className="u-flexRow">
        <Icon color="green" name="checkCircle" />
        <div className="u-marginLeftSm">
          <h5>Thank you for enabling Display Ads</h5>
        </div>
      </div>
      <div className="t-body-1 u-marginTop-8">
        Our system is now confirming which Partners and Advertisers have Display Ads enabled. Once
        we are done confirming, we will send you an email summary and possible next steps.
      </div>
      <div className="u-flexRow u-justifyFlexEnd u-marginAboveLg">
        <a href={newSettingsLinkedPlatformAccountPath()}>
          <Button label="Done" />
        </a>
      </div>
    </Card>
  );
}

export default Confirm;
