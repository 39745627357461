import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormGroupContainer,
  TextField,
  Widget,
  WidgetHeader,
  WidgetContent,
} from '@duik/it';
import {
  Toggle,
} from '@makeably/creativex-design-system';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import FormSubmit from 'components/reusable/FormSubmit';
import { HiddenInput } from 'components/reusable/HiddenInput';
import { AllowedSymbols as PasswordAllowedSymbols } from 'components/settings/users/EditPasswordForm';

const propTypes = {
  passwordRules: PropTypes.shape({
    letter: PropTypes.boolean,
    minLength: PropTypes.number,
    number: PropTypes.boolean,
    symbol: PropTypes.boolean,
  }).isRequired,
  patchUrl: PropTypes.string.isRequired,
  sessionTimeoutMinutes: PropTypes.number,
};

const defaultProps = {
  sessionTimeoutMinutes: 0,
};

class SecurityConfigurationForm extends React.Component {
  constructor(props) {
    super(props);
    const {
      passwordRules,
      sessionTimeoutMinutes,
    } = props;
    this.state = {
      passwordRules,
      sessionTimeoutMinutes,
    };
  }

  handleBooleanRule(id) {
    this.setState((prevState) => {
      const { passwordRules } = prevState;
      return {
        passwordRules: {
          ...passwordRules,
          [id]: !passwordRules[id],
        },
      };
    });
  }

  handleNumberRule(event) {
    const {
      id,
      value,
    } = event.target;
    this.setState((prevState) => {
      const { passwordRules } = prevState;
      return {
        passwordRules: {
          ...passwordRules,
          [id]: value,
        },
      };
    });
  }

  handleSessionTimeout(event) {
    this.setState({ sessionTimeoutMinutes: event.target.value });
  }

  render() {
    const {
      patchUrl,
    } = this.props;

    const {
      passwordRules,
      sessionTimeoutMinutes,
    } = this.state;

    return (
      <Widget>
        <WidgetHeader>
          <h5>Security Settings</h5>
        </WidgetHeader>
        <WidgetContent>
          <form action={patchUrl} method="post">
            <HiddenInput name="_method" value="patch" />
            <AuthenticityTokenInput />
            <FormGroupContainer>
              <div>Password Rules</div>
              <Toggle
                checked={passwordRules.letter}
                label="Require at least 1 letter"
                name="security_configuration[password_require_letter]"
                right
                onChange={() => this.handleBooleanRule('letter')}
              />
              <Toggle
                checked={passwordRules.number}
                label="Require at least 1 number"
                name="security_configuration[password_require_number]"
                right
                onChange={() => this.handleBooleanRule('number')}
              />
              <Toggle
                checked={passwordRules.symbol}
                label={`Require at least 1 symbol (${PasswordAllowedSymbols})`}
                name="security_configuration[password_require_symbol]"
                right
                onChange={() => this.handleBooleanRule('symbol')}
              />
              <TextField
                className="adminSecurityConfiguration-numberInput"
                id="minLength"
                label="Minimum Length"
                max="72"
                min="8"
                name="security_configuration[password_min_length]"
                type="number"
                value={passwordRules.minLength}
                wrapperProps={{ className: 'adminSecurityConfiguration-numberInput' }}
                onChange={(e) => this.handleNumberRule(e)}
              />
              <FormGroupContainer>
                <TextField
                  className="adminSecurityConfiguration-numberInput"
                  label="User Session Timeout (Minutes)"
                  min="0"
                  name="security_configuration[user_session_timeout_minutes]"
                  type="number"
                  value={sessionTimeoutMinutes || 0}
                  wrapperProps={{ className: 'adminSecurityConfiguration-numberInput' }}
                  onChange={(e) => this.handleSessionTimeout(e)}
                />
              </FormGroupContainer>
              <FormGroup>
                <FormSubmit
                  label="Save Settings"
                  primary
                />
              </FormGroup>
            </FormGroupContainer>
          </form>
        </WidgetContent>
      </Widget>
    );
  }
}

SecurityConfigurationForm.propTypes = propTypes;
SecurityConfigurationForm.defaultProps = defaultProps;
export default SecurityConfigurationForm;
