/* Renders a month dropdown selector */
import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Tooltip } from '@makeably/creativex-design-system';
import StringHelper from 'components/utils/StringHelper';

function activeOptionFormat(props, propName, componentName) {
  if (!(propName in props)) {
    return new Error(
      `Failed prop type: The prop \`${propName}\` is marked as required in 
      \`${componentName}\`, but its value is \`undefined\`.`,
    );
  }
  if (!/\d{4}-\d{2}-\d{2}_\d{4}-\d{2}-\d{2}/.test(props[propName])) {
    return new Error(
      `Invalid prop ${propName} supplied to ${componentName}. Must be in the format 'YYYY-MM-DD_YYYY-MM-DD'.`,
    );
  }
  return null;
}

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.shape({
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  // Must be in the format "YYYY-MM-DD_YYYY-MM-DD"
  // eslint-disable-next-line react/require-default-props
  activeOption: activeOptionFormat,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelTooltip: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
};

const defaultProps = {
  disabled: false,
  label: undefined,
  labelTooltip: undefined,
  size: 'small',
};

const renderLabel = (label, labelTooltip) => (
  <div className="u-flexRow u-label">
    <span className="u-marginRightSm">{ label }</span>
    { labelTooltip && <Tooltip label={labelTooltip} /> }
  </div>
);

function MonthSelector(props) {
  const {
    activeOption,
    disabled,
    label,
    labelTooltip,
    name,
    onChange: callback,
    options: {
      maxDate,
      minDate,
    },
    size,
  } = props;

  const date = activeOption.split('_')[0];

  const dateObj = StringHelper.stringToDate(date);
  const maxDateObj = StringHelper.stringToDate(maxDate);
  const minDateObj = StringHelper.stringToDate(minDate);

  const onChange = (value) => {
    const monthStart = value.startOf('month');
    if (monthStart > maxDateObj || monthStart < minDateObj) return;

    const startDate = monthStart.format('YYYY-MM-DD');
    const endDate = value.endOf('month').format('YYYY-MM-DD');
    const range = `${startDate}_${endDate}`;

    if (activeOption !== range) {
      callback(range, name);
    }
  };

  const popoverProps = {
    className: 'monthSelector',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
  };

  return (
    <div>
      { renderLabel(label, labelTooltip) }
      <div className="monthSelector">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            PopoverProps={popoverProps}
            disabled={disabled}
            inputVariant="outlined"
            maxDate={maxDateObj}
            minDate={minDateObj}
            openTo="year"
            size={size}
            value={dateObj}
            variant="inline"
            views={['year', 'month']}
            autoOk
            onChange={onChange}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
}

MonthSelector.propTypes = propTypes;
MonthSelector.defaultProps = defaultProps;

export default MonthSelector;
