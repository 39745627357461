// TODO: Move to design system once it has been tested in the app
import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  BasicModal,
  Button,
  ClickableTag,
  Icon,
  MaxWidthText,
  Search,
  findObjectByValue,
  removeObjectByValue,
  toggleObject,
} from '@makeably/creativex-design-system';
import { removeProperty } from 'utilities/object';
import { autocompleteLabels } from 'utilities/search';
import './Filter.css';

export const segmentProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  variant: PropTypes.string,
});
export const optionProps = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  label: PropTypes.string,
});
const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.objectOf(
    PropTypes.arrayOf(optionProps),
  ).isRequired,
  segments: PropTypes.arrayOf(segmentProps).isRequired,
  selections: PropTypes.objectOf(
    PropTypes.arrayOf(optionProps),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectionsChange: PropTypes.func.isRequired,
};

function Filter({
  segments,
  isOpen,
  onClose,
  onSelectionsChange,
  options,
  selections,
}) {
  const [optionSearch, setOptionSearch] = useState('');
  const [segmentSearch, setSegmentSearch] = useState('');
  const [selectionSearch, setSelectionSearch] = useState('');
  const [displayedSegments, setDisplayedSegments] = useState([]);
  const [displayedSelections, setDisplayedSelections] = useState({});
  const [currentSegment, setCurrentSegment] = useState(segments[0]);
  const [currentSelections, setCurrentSelections] = useState(selections);
  const [closedSelections, setClosedSelections] = useState([]);
  const [currentOptions, setCurrentOptions] = useState([]);
  const selectedTotal = Object
    .values(currentSelections)
    .reduce((total, arr) => (total + arr.length), 0);

  useEffect(() => {
    setDisplayedSegments(segments);
    setCurrentSegment(segments[0]);
  }, [segments]);

  useEffect(() => {
    setOptionSearch('');
    setCurrentOptions(options[currentSegment?.value] ?? []);
  }, [options, currentSegment]);

  useEffect(() => {
    setCurrentSelections(selections);
  }, [selections]);

  useEffect(() => {
    setSelectionSearch('');
    setDisplayedSelections(currentSelections);
  }, [currentSelections]);

  const handleSegmentSearch = (value) => {
    setSegmentSearch(value);
    setDisplayedSegments(autocompleteLabels(segments, value));
  };

  const handleOptionSearch = (value) => {
    setOptionSearch(value);
    const allOptions = options[currentSegment?.value] ?? [];
    setCurrentOptions(autocompleteLabels(allOptions, value));
  };

  const handleSelectionSearch = (value) => {
    setSelectionSearch(value);
    const filteredSelections = Object.keys(currentSelections).reduce((acc, segment) => {
      const segmentSelections = autocompleteLabels(currentSelections[segment], value);
      if (segmentSelections.length > 0) {
        acc[segment] = segmentSelections;
      }
      return acc;
    }, {});

    setDisplayedSelections(filteredSelections);
  };

  const toggleSelectionClosed = (segment) => {
    setClosedSelections((closed) => toggleObject(closed, segment));
  };

  const addSelection = (value, opts) => {
    setCurrentSelections((current) => ({
      ...current,
      [value]: opts,
    }));
  };

  const removeSelection = (value) => {
    setCurrentSelections((current) => removeProperty(current, value));
  };

  const addOption = (value, option) => {
    setCurrentSelections((current) => {
      const arr = current[value] ?? [];

      if (findObjectByValue(arr, option)) {
        return current;
      }

      return {
        ...current,
        [value]: [...arr, option],
      };
    });
  };

  const removeOption = (value, option) => {
    setCurrentSelections((current) => {
      const newOptions = removeObjectByValue(current[value], option);

      if (newOptions.length === 0) return removeProperty(current, value);

      return {
        ...current,
        [value]: newOptions,
      };
    });
  };

  const handleCancel = () => {
    onClose();
    setCurrentSegment(segments[0]);
    setCurrentSelections(selections);
    setClosedSelections([]);
  };

  const handleApply = () => {
    onSelectionsChange(currentSelections);
    onClose();
    setCurrentSegment(segments[0]);
    setClosedSelections([]);
  };

  const renderEmpty = () => (
    <div className="t-empty filter-empty">
      No Results
    </div>
  );

  const renderSegment = (segment) => {
    if (segment.hidden) return null;

    const classes = classNames(
      'filter-segment',
      't-body-1',
      {
        'filter-segment--selected': segment.value === currentSegment?.value,
      },
    );

    return (
      <button
        key={segment.label}
        className={classes}
        type="button"
        onClick={() => setCurrentSegment(segment)}
      >
        { segment.label }
      </button>
    );
  };

  const renderOption = (option) => (
    <button
      key={option.value}
      className="filter-option t-body-1"
      type="button"
      onClick={() => addOption(currentSegment?.value, option)}
    >
      <Icon color="current" name="plus" />
      <MaxWidthText
        className="filter-optionLabel"
        text={option.label ?? option.value}
      />
    </button>
  );

  // TODO: Move to separate FilterSelection component when Filter is moved to design system
  const renderSelection = (segment) => {
    const { value } = segment;
    const selection = displayedSelections[value] ?? [];

    if (selection.length === 0) return null;

    const selectionOpen = !findObjectByValue(closedSelections, segment);
    const icon = selectionOpen ? 'chevronUp' : 'chevronDown';

    return (
      <div key={value} className="filter-selection">
        <div className="filter-selectionHeader t-caption-1">
          <div className="filter-selectionTitle">
            { `${segment.label} (${selection.length})` }
            <button type="button" onClick={() => toggleSelectionClosed(segment)}>
              <Icon name={icon} />
            </button>
          </div>
          <button type="button" onClick={() => removeSelection(value)}>
            <Icon name="closeX" />
          </button>
        </div>
        { selectionOpen && (
          <div className="filter-selectionOptions">
            { selection.map((option) => (
              <ClickableTag
                key={option.value}
                label={option.label ?? option.value}
                onRemove={() => removeOption(value, option)}
              />
            )) }
          </div>
        ) }
      </div>
    );
  };

  const renderItems = (itemType) => {
    switch (itemType) {
      case 'segments':
        if (displayedSegments.length === 0) return renderEmpty();
        return displayedSegments.map(renderSegment);
      case 'options':
        if (currentOptions.length === 0) return renderEmpty();
        return currentOptions.map(renderOption);
      case 'selections':
        if (Object.keys(displayedSelections).length === 0 && selectionSearch) return renderEmpty();
        return segments.map(renderSelection);
      default:
        return null;
    }
  };

  const footer = (
    <div className="filter-footer">
      <Button
        label="Cancel"
        variant="tertiary"
        onClick={handleCancel}
      />
      <Button
        label="Apply"
        onClick={handleApply}
      />
    </div>
  );

  return (
    <div className="filter">
      <BasicModal
        footer={footer}
        isOpen={isOpen}
        padding={false}
        onClose={handleCancel}
      >
        <div className="filter-content">
          <div className="filter-column">
            <div className="filter-header">
              <div className="filter-title">
                <h5>Filter By</h5>
              </div>
            </div>
            <div className="filter-search">
              <Search
                size="large"
                value={segmentSearch}
                onChange={handleSegmentSearch}
              />
            </div>
            <div className="filter-segments">
              <div>
                { renderItems('segments') }
              </div>
            </div>
          </div>
          <div className="filter-column">
            <div className="filter-header">
              <div className="filter-title">
                <h5>{ currentSegment?.label }</h5>
              </div>
              <Button
                label="Add All"
                variant="tertiary"
                onClick={() => addSelection(currentSegment.value, currentOptions)}
              />
            </div>
            <div className="filter-search">
              <Search
                size="large"
                value={optionSearch}
                onChange={handleOptionSearch}
              />
            </div>
            <div className="filter-options">
              { renderItems('options') }
            </div>
          </div>
          <div className="filter-column">
            <div className="filter-header">
              <div className="filter-title">
                <h5>{ `Selected (${selectedTotal})` }</h5>
              </div>
              <Button
                label="Clear All"
                variant="tertiary"
                onClick={() => setCurrentSelections({})}
              />
            </div>
            <div className="filter-search">
              <Search
                size="large"
                value={selectionSearch}
                onChange={handleSelectionSearch}
              />
            </div>
            <div className="filter-selections">
              { renderItems('selections') }
            </div>
          </div>
        </div>
      </BasicModal>
    </div>
  );
}

Filter.propTypes = propTypes;

export default Filter;
