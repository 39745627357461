import React from "react"
import Column from "components/reusable/column"
import PropTypes from "prop-types"
import ComparisonDataContainer from "components/containers/comparison_data_container"
import DataTable from "components/reusable/data_table"
import PlotlyChart from "components/reusable/plotly_chart"
import Row from "components/reusable/row"

# This is responsible for displaying and updating the timeseries chart and table for timeseries analysis
class TimeseriesComparisonContainer extends React.Component
  @propTypes =
    # chartData has the format of object { dataView (:value, :rate, etc...) => plotlyObject }
    chartData: PropTypes.object
    # Options for looking at metrics. Values should correspond to methods on rows ex. row.value, row.test_view.
    # Ex. [{label: 'Engagement', value: 'value'}, {label: 'Test', value: 'test_view'}...]
    dataViewOptions: PropTypes.array
    # Selected metric ex. :engagement, :count
    metric: PropTypes.string
    # tableData has the format of object { dataView (:value, :rate, etc...) => dataTable object }
    tableData: PropTypes.object
    # Overall title for the comparison
    title: PropTypes.string
    metricSelect: PropTypes.shape(
      options: PropTypes.array,
      selected: PropTypes.string
    )

  constructor: (props) ->
    super(props)

    @state =
      dataView: 'value'

    if props.dataViewOptions
      @state['dataView'] = props.dataViewOptions[0].value

  handleDataViewChange: (dataView) =>
    @setState(dataView: dataView)

  renderGraph: ->
    if @props.chartData
      `<Row>
        <Column>
          <PlotlyChart {...this.props.chartData[this.state.dataView]} />
        </Column>
      </Row>`

  renderDataTable: ->
    if @props.tableData
      format = if @state.dataView is 'value' then @props.metric else @state.dataView

      `<Row>
        <Column>
          <DataTable
            {...this.props.tableData[this.state.dataView]}
            format = {format}
          />
        </Column>
      </Row>`

  render: ->
    dataViewSelectParams =
      if @props.dataViewOptions
        onChange: @handleDataViewChange
        options: @props.dataViewOptions
        selected: @state.dataView

    `<ComparisonDataContainer
      dataViewSelectParams = {dataViewSelectParams}
      metricSelectParams = {this.props.metricSelect}
      title = {this.props.title}
    >
      {this.renderGraph()}
      {this.renderDataTable()}
    </ComparisonDataContainer>`

export default TimeseriesComparisonContainer
