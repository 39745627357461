import { detect } from 'detect-browser';

const currentBrowser = detect();

function isSupportedBrowser() {
  // Checks current browser type. We only currently don't support IE
  switch (currentBrowser && currentBrowser.name) {
    case 'ie':
      return false;
    default:
      return true;
  }
}

export default isSupportedBrowser;
