import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Checkbox,
  Dropdown,
  Icon,
  MaxWidthText,
  Table,
} from '@makeably/creativex-design-system';
import { getItemContent } from 'utilities/item';
import styles from './AssociateEntity.module.css';

const headerProps = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
});
const dropdownProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
    }),
  ),
});
const itemProps = PropTypes.shape({
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
const propTypes = {
  dropdowns: PropTypes.objectOf(dropdownProps).isRequired,
  headers: PropTypes.arrayOf(headerProps).isRequired,
  items: PropTypes.arrayOf(itemProps).isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onItemChanged: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
};

function renderCheckboxCell(item, onItemSelected) {
  return {
    className: styles.checkbox,
    value: (
      <Checkbox
        checked={item.checked.value}
        onChange={(e) => onItemSelected(item, e.target.checked)}
      />
    ),
  };
}

function renderDropdownCell(item, key, dropdown, onItemChanged) {
  const value = item[key]?.value;
  const selected = dropdown.options.find((option) => option.value === value);

  return {
    className: styles.dropdown,
    value: (
      <Dropdown
        options={dropdown.options}
        placeholder={dropdown.label}
        selected={selected}
        onChange={(option) => onItemChanged(item, key, option)}
      />
    ),
  };
}

function renderTextCell(item, key) {
  const content = getItemContent(item, key);

  switch (typeof content) {
    case 'string':
    case 'number':
      return {
        value: (
          <MaxWidthText
            className={styles.cell}
            text={content}
          />
        ),
      };
    default:
      return { value: content };
  }
}

function AssociateEntity({
  dropdowns,
  headers,
  items,
  name,
  onItemChanged,
  onItemSelected,
  onSelected,
  selected,
}) {
  const [shown, setShown] = useState(true);

  const renderCell = (item, key) => {
    const dropdown = dropdowns[key];

    if (dropdown) {
      return renderDropdownCell(item, key, dropdown, onItemChanged);
    } else if (key === 'checked') {
      return renderCheckboxCell(item, onItemSelected);
    }
    return renderTextCell(item, key);
  };

  const getRow = (item, keys) => ({
    key: `${item.id.value}`,
    cells: keys.map((key) => renderCell(item, key)),
  });

  const tableHeaders = headers.map((header) => ({ value: header.label }));
  const keys = headers.map((header) => header.key);
  const rows = items.map((item) => getRow(item, keys));

  return (
    <Card>
      <div className={styles.title}>
        <Checkbox
          checked={selected}
          label={name}
          onChange={(e) => onSelected(e.target.checked)}
        />
        <button
          type="button"
          onClick={() => setShown((value) => !value)}
        >
          <Icon name={shown ? 'chevronUp' : 'chevronDown'} />
        </button>
      </div>
      { shown && (
        <Table
          headers={tableHeaders}
          rows={rows}
        />
      ) }
    </Card>
  );
}

AssociateEntity.propTypes = propTypes;

export default AssociateEntity;
