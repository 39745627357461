import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  TopBar,
  TopBarLink,
  TopBarSection,
} from '@duik/it';
import NavDropdown from 'components/navigation/NavDropdown';

const propTypes = {
  brandMarkPath: PropTypes.string.isRequired,
  homePath: PropTypes.string.isRequired,
  topLinks: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      link: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  userDropdown: PropTypes.shape({
    buttonText: PropTypes.string,
    logoUrl: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        target: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }).isRequired,
};

function renderTopBarLink({
  active, link, text,
}) {
  const classes = classNames({ active });

  return (
    <TopBarLink key={text} className={classes} href={link}>
      { text }
    </TopBarLink>
  );
}

// Renders the product navigation
function ProductNavigation(props) {
  const {
    brandMarkPath,
    homePath,
    topLinks,
    userDropdown: {
      buttonText,
      logoUrl,
      options,
    },
  } = props;

  let logo;
  if (logoUrl) logo = <img alt="company_logo" className="navDropdown-logo" src={logoUrl} />;

  const logoButtonText = (
    <div className="u-flexAlignCenter u-flexRow u-justifySpaceBetween">
      { logo }
      <div>{ buttonText }</div>
    </div>
  );

  return (
    <TopBar>
      <TopBarSection>
        <a className="logo--mark" href={homePath}>
          <img alt="CreativeX" src={brandMarkPath} />
        </a>
      </TopBarSection>
      <TopBarSection>
        { topLinks.map(renderTopBarLink) }
      </TopBarSection>
      <TopBarSection>
        <NavDropdown
          buttonProps={{ transparent: true }}
          buttonText={logoButtonText}
          menuPosition="bottom-left"
          options={options}
        />
      </TopBarSection>
    </TopBar>
  );
}

ProductNavigation.propTypes = propTypes;

export default ProductNavigation;
