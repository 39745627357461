import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Label from "components/reusable/label"

# Explanation of PropTypes:
#   - formId: if this element interacts with a form on a different part of
#             the page, formId is used to indicate the id of the form (HTML5 magic)
#   - visualStyle: (default|pill|channelIcon) checkboxes can be displayed many ways,
#            use this selector if you have a specific use case in mind.
#            (default) - browser default css from framework
#            (broswerDefault) - don't style the checkbox
#            (pill) - labels become pills with colored backgrounds when active
#            (channelIcon) - add channel icon before text
class Checkbox extends React.Component
  @propTypes =
    disabled: PropTypes.bool
    formId: PropTypes.string
    iconProps: PropTypes.object
    id: PropTypes.string.isRequired
    isChecked: PropTypes.bool
    label: PropTypes.string
    name: PropTypes.string
    onSelect: PropTypes.func
    visualStyle: PropTypes.oneOf(['default', 'browserDefault', 'pill', 'channelIcon'])
    value: PropTypes.string

  @defaultProps =
    visualStyle: 'default'

  labelClasses: ->
    classNames(
      'checkbox-PillLabel': @props.visualStyle == 'pill'
    )

  checkboxClasses: ->
    checkboxStyle =
      if @props.visualStyle == 'pill'
        'checkbox-Pill'
      else if @props.visualStyle == 'browserDefault'
        'browser-default'
      else
        'filled-in'

    classNames(
      checkboxStyle,
      @props.styleName,
      @props.className
    )

  handleClick: (event) =>
    @props.onSelect(event.target.value, { inputName: event.target.name, isChecked: event.target.checked })

  renderLabelContent: ->
    return unless (@props.label || @props.iconProps)
    `<Label iconProps={this.props.iconProps} value={this.props.label} />`

  render: ->
    `<div className="checkboxGroup-checkbox">
      <input
        checked={this.props.isChecked}
        className={this.checkboxClasses()}
        disabled={this.props.disabled}
        form={this.props.formId}
        id={this.props.id}
        name={this.props.name}
        onChange={this.handleClick}
        type='checkbox'
        value={this.props.value}
      />
      <label
        htmlFor={this.props.id}
        className={this.labelClasses()}
      >
        {this.renderLabelContent()}
      </label>
    </div>`

export default Checkbox
