// Helper functions for decoding and manipulating urls and their parameters
import { toSnakeCase } from 'utilities/string';

export const PARAM_DELIMITER = ';';

export function getParams(win) {
  return new URLSearchParams(win.location.search);
}

export function getParamsObj(params, keys) {
  return keys.reduce((obj, key) => ({
    ...obj,
    [key]: params.get(key),
  }), {});
}

export function removeEmptyParams(params) {
  const emptyEntries = [...params.entries()].filter(([_key, value]) => value === '');
  emptyEntries.forEach(([key, _value]) => params.delete(key));
}

export function updateParams(params, win) {
  const paramsStr = `${params.size > 0 ? '?' : ''}${params.toString()}`;
  win.history.pushState({}, '', `${win.location.pathname}${paramsStr}`);
}

export function setParamsFromObj(obj, params, win) {
  Object.entries(obj).map(([key, values]) => {
    const value = Array.isArray(values) ? values.join(PARAM_DELIMITER) : values;
    return params.set(key, value);
  });
  removeEmptyParams(params);
  updateParams(params, win);
}

export function setParam(key, values, params, win) {
  const value = Array.isArray(values) ? values.join(PARAM_DELIMITER) : values;
  params.set(key, value);
  removeEmptyParams(params);
  updateParams(params, win);
}

export function redirectWithParamsObj(obj, params, win) {
  setParamsFromObj(obj, params, win);
  win.history.go();
}

export function redirectWithParam(key, value, params, win) {
  setParam(key, value, params, win);
  win.history.go();
}

export function redirectToParams(params, win) {
  updateParams(params, win);
  win.history.go();
}

export function selectParams(keys, params) {
  return keys.reduce((all, key) => {
    const values = params.get(key)?.split(PARAM_DELIMITER) ?? [];

    return {
      ...all,
      [key]: values,
    };
  }, {});
}

export function getNumberParam(key, params, defaultValue) {
  const valueStr = params.get(key);
  const value = parseInt(valueStr);

  if (Number.isNaN(value)) {
    return defaultValue;
  }
  return value;
}

export function getPage(params) {
  return getNumberParam('page', params, 1);
}

export function changePage(page, params, win) {
  setParam('page', page, params, win);
}

export function paramsToSnakeCase(linkParams) {
  const params = {};
  Object.keys(linkParams).forEach((param) => {
    params[toSnakeCase(param)] = linkParams[param];
  });
  return params;
}
