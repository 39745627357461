import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Stepper,
  Tooltip,
} from '@makeably/creativex-design-system';
import SelectableItemsTable from 'components/molecules/SelectableItemsTable';
import { useViewPage } from 'utilities/mixpanel';
import { getAuthenticityToken } from 'utilities/requests';
import { newSettingsLinkedPlatformAccountPath } from 'utilities/routes';
import styles from './Entities.module.css';

const stepProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
});
const entityProps = PropTypes.shape({
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  countryCode: PropTypes.string.isRequired,
  profileId: PropTypes.number.isRequired,
});
const propTypes = {
  currentStep: PropTypes.number.isRequired,
  entities: PropTypes.arrayOf(entityProps).isRequired,
  formUrl: PropTypes.string.isRequired,
  nextUrl: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
};

const headers = [
  {
    key: 'name',
    label: 'Entity Name',
  },
  {
    key: 'id',
    label: 'Entity ID',
  },
];

function getItems(entities) {
  return entities.map((entity) => ({
    name: { value: entity.accountInfo.name },
    id: { value: entity.accountInfo.id },
    profileId: { value: entity.profileId },
    countryCode: { value: entity.countryCode },
  }));
}

async function submitEntity(item, url) {
  if (!item) return;

  const entity = {
    name: item.name?.value,
    profile_id: `${item.profileId.value}`,
    country_code: item.countryCode.value,
  };

  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('entity', JSON.stringify(entity));

  await fetch(url, {
    method: 'POST',
    body: formData,
  });
}

function Entities({
  currentStep,
  entities,
  formUrl,
  nextUrl,
  steps,
}) {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDisabled = selectedItems.length === 0 || isSubmitting;

  useViewPage('entities');

  useEffect(() => {
    setItems(getItems(entities));
  }, [entities]);

  const handleContinue = async () => {
    setIsSubmitting(true);
    await submitEntity(selectedItems.at(0), formUrl);
    window.location.href = nextUrl;
  };

  const renderTable = () => {
    if (items.length === 0) {
      return (
        <div className={classNames('t-empty', styles.empty)}>
          No DSP-type entities were found
        </div>
      );
    }

    return (
      <SelectableItemsTable
        headers={headers}
        items={items}
        selectedItems={selectedItems}
        singleSelect
        onItemsSelect={setSelectedItems}
      />
    );
  };

  return (
    <>
      <div className={styles.stepper}>
        <Stepper currentStep={currentStep} steps={steps} />
      </div>
      <div className={styles.instructions}>
        <h5>Select the Entity that you would like to connect to CreativeX</h5>
        <Tooltip
          label="You can only select one entity at a time to connect to Creativex.
          Repeat this flow to connect additional entities"
        />
      </div>
      <Card>
        { renderTable() }
      </Card>
      <div className={styles.buttons}>
        <div className="buttonGroup">
          <Button
            label="Cancel"
            url={newSettingsLinkedPlatformAccountPath()}
            variant="secondary"
          />
          <Button
            disabled={isDisabled}
            label="Continue"
            onClick={handleContinue}
          />
        </div>
      </div>
    </>
  );
}

Entities.propTypes = propTypes;

export default Entities;
