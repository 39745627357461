// Content for the user onboarding modal
class UserOnboarding {
  static imageUrl(step, profile) {
    const bucket = 'https://picassolabs.s3.amazonaws.com/assets/onboarding-wizard';

    const isLimited = profile === 'limited';
    const isConnecting = profile === 'connecting';

    let image;

    switch (step) {
      case 1:
        image = 'intro';
        break;
      case 2:
        image = (isConnecting) ? 'account-connections' : 'creative-score';
        break;
      case 3:
        if (isConnecting) {
          image = 'new-account';
        } else if (isLimited) {
          image = 'rules';
        } else {
          image = 'global-tracking';
        }
        break;
      case 4:
        if (isConnecting) {
          image = 'help';
        } else if (isLimited) {
          image = 'preflight';
        } else {
          image = 'invite';
        }
        break;

      // no default
    }

    return `${bucket}/${image}.jpg`;
  }

  static headerTitle(step, profile) {
    const isLimited = profile === 'limited';
    const isConnecting = profile === 'connecting';

    let title;

    switch (step) {
      case 1:
        title = 'Welcome to CreativeX';
        break;
      case 2:
        if (isConnecting) {
          title = 'Connect your accounts';
        } else {
          title = 'Creative Excellence';
        }
        break;
      case 3:
        if (isConnecting) {
          title = 'Connect a new account';
        } else if (isLimited) {
          title = "Your team's rules";
        } else {
          title = 'Track and compare';
        }
        break;
      case 4:
        if (isConnecting) {
          title = 'Getting help';
        } else if (isLimited) {
          title = 'Preflight';
        } else {
          title = 'Invite teammates';
        }
        break;

      // no default
    }

    return title;
  }

  static content(step, profile) {
    const isLimited = profile === 'limited';
    const isConnecting = profile === 'connecting';

    let content;

    switch (step) {
      case 1:
        content = [
          'CreativeX is a digital tool that helps you and your organization measure the efficiency, consistency, and impact of all your creatives worldwide.',
          'Keep on-brand, up-to-date, and in-the-know with our dashboards, testing, and reporting tools.',
        ];
        break;
      case 2:
        if (isConnecting) {
          content = [
            "Your organization's media accounts need to be connected to CreativeX to ensure that creative content can be automatically retrieved and analyzed by our system.",
          ];
        } else {
          content = [
            "This score is an overall measurement that maps your creatives to your organization's best practices to gauge their adoption and help keep all advertising on-brand.",
            'By maintaining a high level of creative excellence, you can create assets quickly and effectively, while avoiding rework.',
          ];
        }
        break;
      case 3:
        if (isConnecting) {
          content = [
            'You can add a new account, or reconnect a lapsed account, by visiting the accounts page and following our step-by-step process.',
          ];
        } else if (isLimited) {
          content = [
            'Your organization has set up a series of rules that represent their definition of creative excellence. Each asset you create will be measured against these rules to determine its excellence score.',
          ];
        } else {
          content = [
            "We provide visibility not only into your organization's global creative performance, but also into how that performance breaks down by individual brands, agencies, markets, and channels.",
          ];
        }
        break;
      case 4:
        if (isConnecting) {
          content = [
            'Are you stuck or having problems? No worries, just visit our help center to troubleshoot.',
            'Welcome to CreativeX!',
          ];
        } else if (isLimited) {
          content = [
            'Avoid rework and costly back and forth by submitting a preflight test. We’ll check your asset’s creative excellence score before it is released to be sure it is on brand and up to specification.',
          ];
        } else {
          content = [
            'Bring the rest of your team onboard by inviting them to create an account and start tracking or testing their creative excellence rate.',
          ];
        }
        break;

      // no default
    }

    return content;
  }
}

export default UserOnboarding;
