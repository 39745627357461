import React from "react"
import Card from "components/reusable/card"
import PropTypes from "prop-types"
import classNames from "classnames"
import GapGauge from "components/gap_analysis/gap_gauge"

# responsible for rendering a label and multiple gauges.
class GapAnalysisCard extends React.Component
  @propTypes =
    # TODO: supply shape params
    gapType: PropTypes.object
    group: PropTypes.object

  renderGauge: (group) =>
    return if !(group and group[@props.gapType.gaugeType])

    classes = classNames(
      "gapGauge--#{group.group_class}",
    )

    `<GapGauge
      {...group[this.props.gapType.gaugeType].gaugeParams}
      className={classes}
      />`

  render: ->
    `<Card>
      <p className="gapGauge--cardTitle">{this.props.gapType.title}</p>
      {this.renderGauge(this.props.group.group_a)}
      {this.renderGauge(this.props.group.group_b)}
    </Card>`

export default GapAnalysisCard
