import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import FilterDropdowns from 'components/filters/FilterDropdowns';
import ItemsTable from 'components/molecules/ItemsTable';
import { getDefaultSelections } from 'utilities/filtering';
import { setItemElement } from 'utilities/itemElement';
import styles from 'components/organisms/SearchableTable.module.css';

const optionProps = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

const filterProps = PropTypes.shape({
  disabled: PropTypes.bool,
  /**
   * Optional override for the default filter logic.
   * (selectedFilterValue, item) => bool
   *
   * @example
   * // The default filter
   * filterFn = (selectedFilterValue, item) => selectedFilterValue === valueFn(item)
   *
   * @example
   * // An item-level filter is not bound to a default key, so a filterFn must be provided
   * filterFn = (selectedFilterValue, item) => someCoolLogic(selectedFilterValue, item)
   */
  filterFn: PropTypes.func,
  /**
   * Converts an item into an option value. Mandatory for item-level filters.
   * (item) => filter option value
   *
   * @example
   * // The default item to option converter is a no-op on a given property
   * itemToOptionFn = (item) => item[key]
   *
   * @example
   * // An item-level filter
   * itemToOptionFn = (item) => item.something.somethingDeeper.capitalize(),
   */
  itemToOptionFn: PropTypes.func,
  label: PropTypes.node,
  options: PropTypes.arrayOf(optionProps),
});

const searchProps = PropTypes.shape({
  disabled: PropTypes.bool,
  /**
   * Optionally, provide your own search fn.
   * (item, searchStr) => [{start: #, end:#}] (or any truthy value)
   *
   * NOTE: If returning matches instead of a truthy value, the length of the matches array
   *       is compared to the number of search terms (whitespace-separated).
   *       It will only consider the item matching if all the terms are found.
   *       If a match array is returned, it will be used for highlighting search results.
   *
   * @example
   * // A basic truthy search
   * searchFn = (item, searchStr) => searchStr.split(/\s+/).find(s => item.something.includes(s))
   *
   * @example
   * // A more complex search (the default search) that will be used to highlight results
   * searchFn = (item, searchStr) => searchStr.split(/\s+/).reduce((termMatches, term) => {
   *   const start = valueFn(item)?.toString()?.toLowerCase()?.indexOf(term) ?? -1;
   *
   *   if (start !== -1) {
   *     return termMatches.concat({ start, end: start + term.length });
   *   }
   *
   *   return termMatches;
   * }, []);
   */
  searchFn: PropTypes.func,
});

const columnConfigProps = PropTypes.shape({
  filter: filterProps,
  header: PropTypes.node,
  /**
   * Custom render function for a value. Required for non-simple values.
   * The second parameter can be optionally used to render search result matches.
   * (itemValue, searchMatches(optional)) => render
   *
   * @example
   * // Render a sub-property on the item, in red if a search result.
   * (item, matches) => (
   *   <span style={{ color: matches?.length ? 'red' : 'black' }}>
   *     { item.someInnerValue }
   *   </span>
   * )
   */
  renderFn: PropTypes.func,
  search: searchProps,
  /**
   * Override for the sort fn.
   * (valueA, valueB) => bool
   */
  sortFn: PropTypes.func,
  /**
   * Override for converting from an item's property to a value.
   * This value is used for display and is passed to most other functions (search, sort, etc).
   * itemValue => transformedValue
   */
  valueFn: PropTypes.func,
});

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  columnConfigs: PropTypes.objectOf(columnConfigProps),
  itemLevelFilters: PropTypes.arrayOf(filterProps),
};

const defaultProps = {
  columnConfigs: undefined,
  itemLevelFilters: undefined,
};

const getHeaders = (columnConfigs) => Object.entries(columnConfigs).map(([key, column]) => ({
  key,
  label: column.header ?? key,
}));

const getValue = (columnConfigs, key, item) => {
  if (columnConfigs[key]?.valueFn) {
    return columnConfigs[key].valueFn(item);
  }

  return item[key] ?? '';
};

const getItem = (columnConfigs, item, keyMatch, uniqueKey) => {
  const mappedItem = Object.fromEntries(
    Object.keys(columnConfigs).map((key) => {
      const matches = key === keyMatch?.key ? keyMatch?.matches : undefined;
      const itemElement = {
        value: getValue(columnConfigs, key, item),
        element: columnConfigs[key]?.renderFn?.(item, matches),
      };

      // Let itemElement render search results unless there's a custom renderer provided
      if (matches && !itemElement.element) {
        itemElement.display = { matches };
      }

      return [
        key,
        itemElement,
      ];
    }),
  );

  if (!mappedItem.id) {
    // Ideally, ids should be passed in on elements for key. Fall back on array index.
    mappedItem.id = { value: `idx-${uniqueKey}` };
  }

  return setItemElement(mappedItem);
};

const hasMatches = (matches, numTerms) => matches
    && (matches?.length === undefined
        || matches.length === numTerms);

const defaultSearch = (searchTerms, value) => searchTerms.reduce((termMatches, term) => {
  const start = value?.toString()?.toLowerCase()?.indexOf(term) ?? -1;

  if (start !== -1) {
    return termMatches.concat({
      start,
      end: start + term.length,
    });
  }

  return termMatches;
}, []);

function searchItemsAndMapToDisplay(columnConfigs, items, searchString) {
  const searchTerms = searchString?.split(/\s+/)?.map((term) => term.toLowerCase());

  return items.reduce((matched, item) => {
    const keyMatch = {
      key: '',
      matches: [],
    };
    if (searchString) {
      Object.entries(columnConfigs)
        .filter(([_, columnConfig]) => !columnConfig.search?.disabled)
        .find(([key, columnConfig]) => {
          keyMatch.key = key;

          if (columnConfig.search?.searchFn) {
            keyMatch.matches = columnConfig.search.searchFn(item, searchString);
          } else {
            // Default search
            keyMatch.matches = defaultSearch(searchTerms, getValue(columnConfigs, key, item));
          }

          return hasMatches(keyMatch.matches, searchTerms.length);
        });
    }

    if (!searchString || hasMatches(keyMatch.matches, searchTerms.length)) {
      const mappedItem = getItem(columnConfigs, item, keyMatch, matched.length);

      return [...matched, mappedItem];
    }
    return matched;
  }, []);
}

const addIfDefined = (collection, value) => {
  if (value === undefined || value === '') {
    return collection;
  }

  return collection.add(value);
};

const mapValuesToOptions = (options) => Array.from(options).map((option) => ({
  value: option,
  label: option?.toString(),
}));

function getColumnLevelFilters(columnConfigs, items) {
  return Object.entries(columnConfigs).reduce((filters, [key, columnConfig]) => {
    if (columnConfig.filter?.disabled) {
      return filters;
    }

    const itemToOptionFn = columnConfig.filter?.itemToOptionFn
        ?? ((item) => getValue(columnConfigs, key, item));
    let options;
    if (columnConfig.filter?.options) {
      options = columnConfig.filter.options;
    } else {
      options = items.reduce((uniq, item) => addIfDefined(uniq, itemToOptionFn(item)), new Set());
      options = mapValuesToOptions(options);
    }

    return filters.concat({
      key,
      options,
      label: columnConfig.filter?.label ?? key,
      filterFn: columnConfig.filter?.filterFn,
      itemToOptionFn,
    });
  }, []);
}

const getItemLevelFilters = (itemLevelFilters, items) => {
  if (itemLevelFilters) {
    return itemLevelFilters.map((filter) => {
      let options;
      if (filter.options) {
        options = filter.options;
      } else {
        options = items.reduce((values, item) => addIfDefined(
          values,
          filter.itemToOptionFn(item),
        ),
        new Set());
        options = mapValuesToOptions(options);
      }

      return {
        key: crypto.randomUUID(),
        options,
        label: filter.label,
        filterFn: filter.filterFn,
        itemToOptionFn: filter.itemToOptionFn,
      };
    });
  }

  return [];
};

const getFilters = (columnConfigs, items, itemLevelFilters) => {
  const columnFilters = getColumnLevelFilters(columnConfigs, items);
  const itemFilters = getItemLevelFilters(itemLevelFilters, items);

  return [...columnFilters, ...itemFilters];
};

function filterItems(filters, items, filterSelections) {
  return items.filter((item) => filters.every((filter) => {
    const filterValues = filterSelections[filter.key];
    if (!filterValues || filterValues.length === 0) {
      return true;
    }

    const value = filter.itemToOptionFn ? filter.itemToOptionFn(item) : item;

    if (filter.filterFn) {
      return (filterValues.findIndex((filterValue) => filter.filterFn(filterValue, value)) !== -1);
    }

    return filterValues.includes(value);
  }));
}

function getSortFn(columnConfigs, key, asc) {
  return (itemA, itemB) => {
    const aValue = getValue(columnConfigs, key, itemA);
    const bValue = getValue(columnConfigs, key, itemB);

    if (columnConfigs[key]?.sortFn) {
      const customSortFn = columnConfigs[key].sortFn;
      return asc ? customSortFn(aValue, bValue) : customSortFn(bValue, aValue);
    }

    if (aValue === bValue) {
      return 0;
    }

    // Sort null last
    if (aValue === null) {
      return 1;
    }
    if (bValue === null) {
      return -1;
    }

    if (aValue > bValue) {
      return asc ? 1 : -1;
    }

    return asc ? -1 : 1;
  };
}

const sortItems = (columnConfigs, items, key, asc) => {
  const itemsClone = items.slice();

  const sortFn = getSortFn(columnConfigs, key, asc);

  return itemsClone.sort(sortFn);
};

/**
 * Configurable searchable, sortable, paginated table with filter drop downs.
 *
 * @param columnConfigs Optional configuration details about how to handle items.
 *                      If not provided, will attempt to infer sane defaults.
 * @param items Array of objects in the table.
 * @param itemLevelFilters Filters that operate on whole items instead of just one property/column.
 *                         Example: Custom categories that look for a combination of traits.
 * @returns {JSX.Element}
 * @constructor
 *
 * @example
 * // Inferred configuration is based on the first item
 * <ConfigurableTable
 *   items={[{ id: 'an_id', cool: 'value', and: 'stuff' }, { id: 'an_id2', noncoforming: 'thing' }]}
 * />
 *
 * @example
 * // Complex example with non-homogenous items, custom filters, value transforms, etc.
 * <ConfigurableTable
 *   columnConfigs={{
 *     id: { header: 'Identifier' },
 *     cool: { filter: { disabled: true } },
 *     and: { valueFn: (item) => item.and ? `${item.and}+and` : '' },
 *     obj: {
 *       filter: {
 *         label: 'Object values',
 *         itemToOptionFn: (item) => item.obj?.some,
 *       },
 *       valueFn: (item) => item.obj?.some || '',
 *       renderFn: (item, matches) => (
 *         <marquee style={{ color: matches?.length ? 'red' : 'black' }}>
 *           { item.obj?.some }
 *         </marquee>
 *       ),
 *     },
 *     nonconforming: {},
 *     reverse: { valueFn: (item) => item.reverse?.split('')?.reverse()?.join('') || '' },
 *     customSort: { sortFn: (a, b) => (a?.length ?? 0) - (b?.length ?? 0) },
 *   }}
 *   itemLevelFilters={[{
 *     label: 'Category',
 *     options: [{ label: 'Category A', value: 'CAT_A' }, { label: 'Category B', value: 'CAT_B' }],
 *     filterFn: (v, item) => (v === 'CAT_A' && item.obj?.some === 'property') ||
 *                            (v === 'CAT_B' && item.cool === 'value'),
 *   }]}
 *   items={[{
 *     id: 'an_id',
 *     cool: 'value',
 *     and: 'stuff',
 *     extra: "won't render",
 *     obj: { some: 'property' },
 *     reverse: 'atoz',
 *     customSort: 'aaa',
 *   },
 *   {
 *     id: 'an_id2',
 *     cool: 'value',
 *     and: 'things',
 *     extra: "won't render",
 *     obj: { some: 'other property' },
 *     reverse: 'hello',
 *     customSort: 'bbbbbb',
 *   },
 *   { id: 'an_id3', nonconforming: 'thing', customSort: 'cc' }]}
 * />
 */
function ConfigurableTable({
  columnConfigs,
  items,
  itemLevelFilters,
}) {
  const [resolvedColumnConfigs, setResolvedColumnConfigs] = useState({});
  const [filters, setFilters] = useState([]);
  const [filterSelections, setFilterSelections] = useState({});
  const [filteredItems, setFilteredItems] = useState(items);

  const [tableSort, setTableSort] = useState({});
  const [sortedItems, setSortedItems] = useState([]);

  const [search, setSearch] = useState('');
  const [searchedItems, setSearchedItems] = useState([]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!columnConfigs && items.length) {
      // Generate default configs based on first item's properties
      setResolvedColumnConfigs(Object.fromEntries(Object.keys(items[0]).map((key) => [key, {}])));
    } else {
      setResolvedColumnConfigs(columnConfigs);
    }
    setPage(1);
  }, [items, columnConfigs]);

  useEffect(() => {
    setFilters(getFilters(resolvedColumnConfigs, items, itemLevelFilters));
    setSearch('');
  }, [items, resolvedColumnConfigs]);

  useEffect(() => {
    setFilterSelections(getDefaultSelections(filters.map((filter) => filter.key), {}));
  }, [filters]);

  useEffect(() => {
    const filtered = filterItems(filters, items, filterSelections);
    setFilteredItems(filtered);
  }, [items, filterSelections, filters]);

  useEffect(() => {
    if (tableSort) {
      const sorted = sortItems(resolvedColumnConfigs, filteredItems, tableSort.key, tableSort.asc);
      setSortedItems(sorted);
    } else {
      setSortedItems(filteredItems);
    }
  }, [filteredItems, tableSort]);

  useEffect(() => {
    const searched = searchItemsAndMapToDisplay(resolvedColumnConfigs, sortedItems, search);
    setSearchedItems(searched);
  }, [sortedItems, search]);

  const handleFilterChange = (key, value) => {
    // handlePageChange(1);
    setFilterSelections((selections) => ({
      ...selections,
      [key]: value,
    }));
  };

  return (
    <>
      <div className={styles.filterRow}>
        <FilterDropdowns
          filters={filters}
          selections={filterSelections}
          onChange={handleFilterChange}
        />
        <div className={styles.search}>
          <input
            className={styles.searchInput}
            placeholder="Search"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onInput={(e) => setSearch(e.target.value)}
          />
          <span className={styles.searchIcons}>
            { search && (
            <button className={styles.searchClear} type="button" onClick={() => setSearch('')}>
              <Icon name="closeX" noWrapper />
            </button>
            ) }
            <Icon name="search" noWrapper />
          </span>
        </div>
      </div>
      <div className={styles.table}>
        <ItemsTable
          headers={getHeaders(resolvedColumnConfigs)}
          items={searchedItems}
          page={page}
          sort={tableSort}
          onPageChange={setPage}
          onSortChange={setTableSort}
        />
      </div>
    </>
  );
}

ConfigurableTable.propTypes = propTypes;
ConfigurableTable.defaultProps = defaultProps;

export default ConfigurableTable;
