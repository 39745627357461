import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  NavFooter,
} from '@makeably/creativex-design-system';
import FileDrop from 'components/reusable/file_drop.coffee';
import { HiddenInput } from 'components/reusable/HiddenInput';
import FormHelper from 'components/utils/FormHelper';
import { getAuthenticityToken } from 'utilities/requests';

export const propTypes = {
  companyName: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
};

const defaultProps = {
  logoUrl: undefined,
};

class CompanyLogoUpload extends React.Component {
  constructor(props) {
    super(props);

    const {
      logoUrl,
    } = props;

    this.state = {
      logoUrl,
      logoUploaded: false,
    };
  }

  handleLogoUrlChange(logoUrl) {
    this.setState({
      logoUrl,
      logoUploaded: true,
    });
  }

  renderPreview() {
    const {
      companyName,
      fullName,
      logoUrl,
    } = this.props;

    const user = {
      companyName,
      fullName,
      logoUrl,
    };

    return (
      <div className="companyLogoSideNav u-flexRow u-marginBelowSm">
        <NavFooter
          options={[]}
          user={user}
        />
      </div>
    );
  }

  render() {
    const {
      formUrl,
      url,
    } = this.props;

    const {
      logoUrl,
      logoUploaded,
    } = this.state;

    const { formMethod, hiddenMethod } = FormHelper.resolveRailsPatchPostMethod('patch');

    const fileFormats = ['.jpg', '.jpeg', '.png'];

    const acceptedFormatCopy = `Accepted formats are ${fileFormats.join(', ')}`;

    return (
      <Card>
        <div className="u-marginBelowSm">
          <h5>Brand Logo Preview</h5>
        </div>
        { this.renderPreview() }
        <div className="u-marginBelowSm">
          <a
            data-confirm="Are you sure you want to remove the logo?"
            data-method="delete"
            href={url}
            rel="nofollow"
          >
            Remove Logo
          </a>
        </div>
        <Divider margin />
        <h5>Upload Brand Logo</h5>
        <form action={url} className="companyLogoFileDrop" method={formMethod}>
          <HiddenInput name="authenticity_token" value={getAuthenticityToken()} />
          <HiddenInput name="_method" value={hiddenMethod} />
          <HiddenInput name="organization[logo_url]" value={logoUrl} />
          <FileDrop
            acceptedFileTypes={fileFormats}
            height={350}
            maxFileSize={30720}
            maxFileUploads={1}
            postUrl={formUrl}
            onSuccess={(file, updatedUrl) => this.handleLogoUrlChange(updatedUrl)}
          />
          <p>
            { acceptedFormatCopy }
          </p>
          <p> Max file size: 30 KB </p>
          <div className="u-flexRow u-justifyFlexEnd">
            <Button
              disabled={!logoUploaded}
              label="Set Logo"
              type="submit"
              variant="primary"
            />
          </div>
        </form>
      </Card>
    );
  }
}

CompanyLogoUpload.defaultProps = defaultProps;
CompanyLogoUpload.propTypes = propTypes;

export default CompanyLogoUpload;
