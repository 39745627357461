import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Label from "components/reusable/label"

# This component creates a radio button with a label
class RadioButton extends React.Component
  @propTypes =
    formId: PropTypes.string
    iconProps: PropTypes.object
    isChecked: PropTypes.bool
    label: PropTypes.string.isRequired
    labelType: PropTypes.oneOf(['channel', 'icon', 'string'])
    name: PropTypes.string
    onSelect: PropTypes.func
    value: PropTypes.string.isRequired
    visualStyle: PropTypes.oneOf(['default', 'browserDefault'])

  @defaultProps =
    labelType: 'string'
    visualStyle: 'default'

  constructor: (props) ->
    super(props)

    # We need an id on this component so that the label and input are properly linked
    # and clicking either option will select the radio button
    # Use a combo of the value and input_name, this makes it easier to find in feature
    # tests...
    @state =
      id: "#{props.formId}--#{props.name}--#{props.value}"

  radioClasses: ->
    classNames(
      'browser-default': @props.visualStyle == 'browserDefault'
    )

  handleClick: (event) =>
    @props.onSelect(event.target.value)

  renderLabelContent: ->
    `<Label iconProps={this.props.iconProps} value={this.props.label} />`

  render: ->
    `<p className="radioButtonGroup-radioButton">
      <input
        className={this.radioClasses()}
        checked={this.props.isChecked}
        form={this.props.formId}
        id={this.state.id}
        name={this.props.name}
        onChange={this.handleClick}
        type='radio'
        value={this.props.value}
      />
      <label htmlFor={this.state.id}>{this.renderLabelContent()}</label>
    </p>`

export default RadioButton
