import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Checkbox,
  Search,
} from '@makeably/creativex-design-system';
import ItemsTable from 'components/molecules/ItemsTable';
import { toggleValue } from 'utilities/array';
import { getItemSortBy } from 'utilities/item';
import { valueIf } from 'utilities/object';
import {
  adminReviewInflightOrganizationGuidelinesPath,
} from 'utilities/routes';
import {
  getParams,
  getParamsObj,
} from 'utilities/url';
import styles from './Organizations.module.css';
import {
  DAY_AGE_KEY,
  PARAM_KEYS,
  searchItems,
  updatePinnedIds,
} from '../shared';

const propTypes = {
  canPinOrgs: PropTypes.bool.isRequired,
  counts: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      dayAge: PropTypes.number.isRequired,
      orgId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  dayAgeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  orgs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pinnedOrgIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

function getHeaders(dayAgeOptions) {
  return [
    {
      key: 'pinned',
      label: 'Pinned',
    },
    {
      key: 'name',
      label: 'Customer',
    },
    {
      key: 'all',
      label: 'All',
    },
    ...dayAgeOptions.map(({ label }) => ({
      key: label,
      label,
    })),
  ];
}

function getCountKey(orgId, dayAge) {
  return `${orgId}::${dayAge}`;
}

function filterEmptyOrgs(orgs, countsObj) {
  const countKeys = Object.keys(countsObj);

  return orgs.filter((org) => {
    const orgKeyStart = getCountKey(org.id, '');
    return countKeys.some((key) => key.startsWith(orgKeyStart));
  });
}

function getTotalCount(orgId, countsObj) {
  const orgKeyStart = getCountKey(orgId, '');

  return Object.entries(countsObj).reduce((sum, [key, value]) => {
    if (key.startsWith(orgKeyStart)) {
      return sum + value;
    }
    return sum;
  }, 0);
}

function getCountsByOrgAge(counts) {
  return counts.reduce((obj, {
    orgId, dayAge, count,
  }) => {
    const key = getCountKey(orgId, dayAge);

    return {
      ...obj,
      [key]: count,
    };
  }, {});
}

function getCountLink(orgId, dayAge, value, params) {
  const paramsObj = {
    ...getParamsObj(params, PARAM_KEYS),
    [DAY_AGE_KEY]: dayAge,
  };

  const url = adminReviewInflightOrganizationGuidelinesPath(orgId, paramsObj);

  return <a href={url}>{ value }</a>;
}

function getCount(orgId, dayAge, countsObj, params) {
  const value = countsObj[getCountKey(orgId, dayAge)] ?? 0;

  return {
    value,
    ...valueIf(value === 0, 'text', '--'),
    ...valueIf(value !== 0, 'element', getCountLink(orgId, dayAge, value, params)),
  };
}

function Organizations({
  canPinOrgs,
  counts,
  dayAgeOptions,
  orgs,
  pinnedOrgIds,
}) {
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState({
    key: 'pinned',
    asc: true,
  });
  const [sortedItems, setSortedItems] = useState(items);
  const [pinnedIds, setPinnedIds] = useState(pinnedOrgIds);
  const [search, setSearch] = useState('');
  const [searchedItems, setSearchedItems] = useState(items);
  const params = getParams(window);

  const handlePinChange = async (id) => {
    const updated = toggleValue(pinnedIds, id);
    setPinnedIds(await updatePinnedIds(updated, false));
  };

  useEffect(() => {
    const getItems = () => {
      const countsObj = getCountsByOrgAge(counts);
      const filteredOrgs = filterEmptyOrgs(orgs, countsObj);

      return filteredOrgs.map(({ id, name }) => {
        const pinIndex = pinnedIds.findIndex((pinId) => pinId === id);
        const isPinned = pinIndex !== -1;
        const pinnedName = isPinned ? `${pinIndex}_${name}` : name;

        return {
          pinned: {
            value: pinnedName,
            element: (
              <Checkbox
                checked={isPinned}
                disabled={!canPinOrgs}
                onChange={() => handlePinChange(id)}
              />
            ),
          },
          id: { value: id },
          name: { value: name },
          all: { value: getTotalCount(id, countsObj) },
          ...dayAgeOptions.reduce((all, { index, label }) => ({
            ...all,
            [label]: getCount(id, index, countsObj, params),
          }), {}),
        };
      });
    };

    setItems(getItems());
  }, [orgs, pinnedIds, counts]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    }
  }, [items, sort]);

  useEffect(() => {
    setSearchedItems(searchItems(sortedItems, search));
  }, [sortedItems, search]);

  return (
    <Card>
      <div className={styles.controls}>
        <Search
          placeholder="Search Customer"
          value={search}
          onChange={(value) => setSearch(value)}
        />
      </div>
      { searchedItems.length === 0 && (
        <div className={`t-empty ${styles.empty}`}>
          No companies match your search
        </div>
      ) }
      <ItemsTable
        className={styles.table}
        headers={getHeaders(dayAgeOptions)}
        items={searchedItems}
        sort={sort}
        onSortChange={(value) => setSort(value)}
      />
    </Card>
  );
}

Organizations.propTypes = propTypes;

export default Organizations;
