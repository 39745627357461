import React from "react"
import Button from "components/reusable/button"
import Column from "components/reusable/column"
import PropTypes from "prop-types"
import ImageCardWithMetrics from "components/category/image_card_with_metrics"
import ImageCardWithMetricsCollection from "components/image_cards/image_card_with_metrics_collection"
import Label from "components/reusable/label"
import Row from "components/reusable/row"

class PostAssetsByGroup extends React.Component

  NUM_ROWS_AT_A_TIME = 4

  @propTypes =
    emptyMessage: PropTypes.string
    numGroups: PropTypes.number
    groups: PropTypes.arrayOf(
      PropTypes.shape(
        key: PropTypes.string
        label: PropTypes.shape(Label.propTypes)
        posts: PropTypes.arrayOf(
          PropTypes.shape(ImageCardWithMetrics.propTypes)
        )
      )
    )

  constructor: (props) ->
    super(props)

    @state =
      numGroups: props.numGroups

  renderPostAssets: (posts) ->
    if _.isEmpty(posts)
      `<span>{this.props.emptyMessage}</span>`
    else
      `<ImageCardWithMetricsCollection
         collection={posts}
         orientation='horizontal'
         message={this.props.emptyMessage}
      />`

  renderPostAssetGroup: (group) =>
    # fat arrow check lint checking prevention
    renderedPostAssets = @renderPostAssets(group.posts)

    `<div key={group.key} className="postAssetsGroup">
      <Row className="no-margin">
        <Column className="no-padding">
          <h5>
            <Label {...group.label} />
          </h5>
        </Column>
      </Row>
      <Row className="no-margin">
        <Column className="no-padding">
          {renderedPostAssets}
        </Column>
      </Row>
    </div>`

  handleSeeMoreChange: =>
    @setState(numGroups: @state.numGroups + NUM_ROWS_AT_A_TIME)

  renderSeeMoreRow: ->
    if @props.groups.length > @state.numGroups
      `<Row className="postAssetsByGroup-seeMoreRow">
        <Column styleName='center-align'>
          <Button label='See More' onClick={this.handleSeeMoreChange}/>
        </Column>
      </Row>`

  render: ->
    groups = @props.groups.slice(0, @state.numGroups)

    `<div className="postAssetsByGroup">
      {groups.map(this.renderPostAssetGroup)}
      {this.renderSeeMoreRow()}
    </div>`

export default PostAssetsByGroup
