// Expand Button
// Only used inside Expandable components
//  see ExpandableBarChart and ExpandableTable
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@duik/it';
import { Icon } from '@makeably/creativex-design-system';

function ExpandButton({
  callback, display, expanded,
}) {
  if (!display) return null;

  const label = expanded ? 'Show less' : 'Show all';
  const icon = expanded ? 'chevronUp' : 'chevronDown';

  return (
    <div className="expandButton">
      <Button clear onClick={() => callback()}>
        <Icon name={icon} />
        <span>{ label }</span>
      </Button>
    </div>
  );
}

ExpandButton.propTypes = {
  callback: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  display: PropTypes.bool,
};
ExpandButton.defaultProps = {
  display: true,
};

export default ExpandButton;
