import React from "react"
import Card from "components/reusable/card"
import Column from "components/reusable/column"
import PropTypes from "prop-types"
import DualYAxisPlotlyChart from "components/graphs/dual_y_axis_plotly_chart"
import InteractivePlotlyChartOptions from "components/graphs/interactive_plotly_chart_options"
import Loader from "components/reusable/Loader"
import MixpanelTracker from "components/utils/MixpanelTracker"
import PlotlyChart from "components/reusable/plotly_chart"
import Row from "components/reusable/row"
import TraceFilteredPlotyChart from "components/graphs/trace_filtered_plotly_chart"

# This is an interactive + trace centered plotly component.
# We can switch metric/chart types, select traces, and load data at the chart, metric, or trace level.
# This component gives the user options and loads/changes charts appropriately.
class InteractivePlotlyChart extends React.Component

  TRACES_TO_SHOW = 10
  AVAILABLE_CHART_TYPES = ['bar', 'boxplot', 'line', 'spline']

  # Future TODO: Explain propTypes more.
  @propTypes =
    # selectable chart types
    chartTypes: PropTypes.arrayOf(PropTypes.oneOf(AVAILABLE_CHART_TYPES))
    # whether or not to display in a card
    displayInCard: PropTypes.bool
    # hash from chart types to layout options by metric
    # ex. {bar: {impressions: plotly_layout_options}}
    layout: PropTypes.object
    # hash from chart types to {primaryMetric: options, secondaryMetric: options}
    # where secondaryMetric is optional and format of options is:
    #   {group: 'Deliver', label: 'Impressions', value: 'impressions'}
    metricOptions: PropTypes.object
    # Selected options. All are optional.
    selected: PropTypes.shape(
      chartType: PropTypes.oneOf(AVAILABLE_CHART_TYPES) # selected chart type, ex. 'bar'
      primaryMetric: PropTypes.object                   # per chart, ex. {bar: 'impressions', line: 'cpm'}
      secondaryMetric: PropTypes.object                 # per chart, ex. {bar: 'impressions', line: 'cpm'}
      traceIds: PropTypes.array                         # selected trace ids
    )
    # Initial trace data to load. ex.
    # {
    #   line: {
    #     impressions: {
    #       ids: [1],
    #       traces: [traceForId1]
    #     }
    #   }
    # }
    traceData: PropTypes.object
    # Where to load data for each chart type
    # ex. { line: "/instagram/paid/categories_graph?chart_type=line" }
    traceDataLocation: PropTypes.object
    # Selectable trace options for all graphs. Optional. If nothing is provide we will not filter.
    traceOptions: PropTypes.shape(
      label: PropTypes.string,        # What to call the trace options ex. Categories, Locations
      options: PropTypes.arrayOf(
        PropTypes.shape(
          label: PropTypes.string,    # What to call the trace. Ex. 'Indoors'
          value: PropTypes.string     # Identifier for the trace. Ex. '25'
        )
      )
    )
    # Callback for when the Primary or Secondary Metric changes with
    # params metricName and metricGroup
    onMetricChange: PropTypes.func

  @defaultProps =
    displayInCard: true
    onMetricChange: undefined

  constructor: (props) ->
    super(props)

    @state =
      id: _.uniqueId("interactive-plotly-chart")

    _.extend(@state, props.traceData)
    _.extend(@state, props.selected)

  componentDidMount: ->
    # In case we need to do a remote initial load
    @updateChartType(@state.chartType)

  updateChartType: (chartType) =>
    return if @state.isLoadingChart

    updatedStateProps =
      chartType: chartType

    if @state[chartType]
      # If we already have data, make sure we have data for all the selected trace ids
      primaryMetric = @state.primaryMetric[chartType]
      traceData = @state[chartType][primaryMetric]
      missingIds = _.difference(@traceIds(), traceData.ids)
      if missingIds.length > 0
        @fetchTraceData(chartType, primaryMetric, missingIds)

      # NB: For when switching between ex: bar & line where traces are selectable
      #     and line graph supports 2 metric selectors
      if @state.secondaryMetric[chartType]
        secondaryMetric = @state.secondaryMetric[chartType]
        traceData = @state[chartType][secondaryMetric]
        missingIds = _.difference(@traceIds(), traceData.ids)
        if missingIds.length > 0
          @fetchTraceData(chartType, secondaryMetric, missingIds)

    else
      # When switching between charts, take the last set of trace ids if we are loading a brand new chart.
      # In practice this means that if we have a bar chart with top 10 things by metric x, those are the 10 things
      # we will see when the chart changes.
      updatedStateProps.traceIds = @traceIds()
      updatedStateProps.isLoadingChart = true

      dataParams =
        trace_ids: updatedStateProps.traceIds
        metrics: _.compact(
          [
            @state.primaryMetric[chartType],
            @state.secondaryMetric[chartType]
          ]
        )

      # The callee can choose to send over more trace data if they are feeling bold.
      $.get(
        @props.traceDataLocation[chartType],
        dataParams
      ).done((response) =>
        stateUpdate = {}
        stateUpdate[chartType] = response
        @setState(stateUpdate)
      ).always(=>
        @setState(isLoadingChart: false)
      )

    @setState(updatedStateProps)

  updateMetricSelection: (metric, metricGroup) =>
    return if @state.isLoadingChart

    chartType = @state.chartType

    selectedMetrics = $.extend(true, {}, @state[metricGroup])
    selectedMetrics[chartType] = metric

    traceData = @state[chartType][metric]
    if traceData
      missingIds = _.difference(@traceIds(), traceData.ids)
      if missingIds.length > 0
        @fetchTraceData(chartType, metric, missingIds)
    else
      @fetchTraceData(chartType, metric, @state.traceIds)

    metricType =
      if metricGroup == 'primaryMetric'
        "Primary Metric"
      else
        "Secondary Metric"
    MixpanelTracker.trackFilterSelection(metricType, selectedMetrics)
    @props.onMetricChange(metric, metricGroup) if @props.onMetricChange

    stateUpdate = {}
    stateUpdate[metricGroup] = selectedMetrics
    @setState(stateUpdate)

  updateTraceIdSelection: (traceId) =>
    chartType = @state.chartType
    traceData = @state[chartType]
    selectedTraceIds = @traceIds()

    # If traceId is currently selected. Remove it and update traceIds.
    if _.includes(selectedTraceIds, traceId)
      @setState(traceIds: _.without(selectedTraceIds, traceId))
      return

    # Otherwise we need to add trace id as a selected trace Id.
    # We need to additionally check if we have data for this trace id, if not, we need to fetch it.
    primaryMetric = @state.primaryMetric[chartType]
    dataTraceIds = traceData[primaryMetric].ids
    unless _.includes(dataTraceIds, traceId)
      @fetchTraceData(chartType, primaryMetric, [traceId])

    selectedTraceIds.push(traceId)
    @setState(traceIds: selectedTraceIds)

  fetchTraceData: (chartType, metric, traceIds) =>
    # When selecting the 'None' option, you don't have a metric
    return unless metric
    @setState(isLoadingChart: true)

    dataParams =
      trace_ids: traceIds
      metrics: [metric]

    $.get(
      @props.traceDataLocation[chartType],
      dataParams
    ).done((response) =>
      chartTypeTraceData = $.extend(true, {}, @state[chartType])
      chartTypeTraceData[metric] ||= { ids: [], traces: [] }
      metricTraceData = chartTypeTraceData[metric]
      data = response[metric]
      metricTraceData.ids = _.union(metricTraceData.ids, data.ids)
      metricTraceData.traces = _.union(metricTraceData.traces, data.traces)
      stateUpdate = {}
      stateUpdate[chartType] = chartTypeTraceData
      @setState(stateUpdate)
    ).always(=>
      @setState(isLoadingChart: false)
    )

  traceIds: =>
    if @state.traceIds
      @state.traceIds
    else
      # If we do not have explicit traceIds... use the first TRACES_TO_SHOW traceIds as an implicit selection
      chartType = @state.chartType
      primaryOption = @state.primaryMetric[chartType]
      if @state[chartType]
        _.first(@state[chartType][primaryOption].ids, TRACES_TO_SHOW)
      else
        []

  metricSelectOptions: (chartType, metricGroup) ->
    options = @props.metricOptions[chartType][metricGroup]
    return null unless options

    options

  renderOptions: ->
    chartType = @state.chartType

    primaryMetricProps =
      label: @state.primaryMetric['label'] || 'Primary Metric'
      onChange: (metric) => @updateMetricSelection(metric, 'primaryMetric')
      options: @metricSelectOptions(chartType, 'primaryMetric')
      selected: @state.primaryMetric[chartType]

    # Future TODO: Remove @state.primaryMetric[chartType] as an available secondary metric
    secondaryMetricOptions = @metricSelectOptions(chartType, 'secondaryMetric')
    secondaryMetricProps =
      if secondaryMetricOptions
        label: @state.secondaryMetric['label'] || 'Secondary Metric'
        onChange: (metric) => @updateMetricSelection(metric, 'secondaryMetric')
        options: secondaryMetricOptions
        selected: @state.secondaryMetric[chartType] || ''

    chartTypeProps =
      onChange: @updateChartType
      selected: @state.chartType
      types: @props.chartTypes

    traceOptionProps =
      if @props.traceOptions
        label: @props.traceOptions.label
        onChange: @updateTraceIdSelection
        options: @props.traceOptions.options
        selected: @traceIds()

    `<InteractivePlotlyChartOptions
      chartType={chartTypeProps}
      isLoading={this.state.isChartLoading}
      plotId={this.state.id}
      primaryMetric={primaryMetricProps}
      secondaryMetric={secondaryMetricProps}
      traceOptions={traceOptionProps}
    />`

  renderPlotlyChart: ->
    chartType = @state.chartType

    return `<Loader/>` if @state.isLoadingChart || _.isUndefined(@state[chartType])

    primaryOption = @state.primaryMetric[chartType]
    secondaryOption = @state.secondaryMetric[chartType]

    plotlyProps =
      traces: if @state[chartType][primaryOption] then @state[chartType][primaryOption].traces else []
      layout: @props.layout[chartType][primaryOption]

    if secondaryOption
      props =
        primary: plotlyProps
        secondary:
          traces: @state[chartType][secondaryOption].traces
          layout: @props.layout[chartType][secondaryOption]

      `<DualYAxisPlotlyChart {...props} />`
    else if @props.traceOptions
      `<TraceFilteredPlotyChart {...plotlyProps} traceIds={this.traceIds()}/>`
    else
      `<PlotlyChart {...plotlyProps} />`

  render: ->
    content =
      `<div>
        {this.renderOptions()}
        <Row className="no-margin">
          <Column>
            {this.renderPlotlyChart()}
          </Column>
        </Row>
      </div>`

    if @props.displayInCard
      `<Card>
        {content}
      </Card>`
    else
      content

export default InteractivePlotlyChart
