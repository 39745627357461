import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  FormGroup,
  TextField,
} from '@duik/it';
import { Icon } from '@makeably/creativex-design-system';

export const propTypes = {
  formActionLink: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  searchValue: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  placeholder: '',
  searchValue: '',
};

function SearchBar({
  className,
  formActionLink,
  placeholder,
  searchValue: initialSearchValue,
}) {
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const onChange = (e) => setSearchValue(e.target.value);
  const searchResultView = initialSearchValue !== '';

  const classes = classNames('searchBar', className);
  const formId = 'searchBar';

  return (
    <ButtonGroup className={classes}>
      <form action={formActionLink} id={formId} method="get">
        <FormGroup>
          <TextField
            name="search_term"
            placeholder={placeholder}
            value={searchValue}
            onChange={onChange}
          />
        </FormGroup>
      </form>
      <ButtonGroup>
        { (searchValue !== '' || searchResultView)
          && (
            <Button
              Component="a"
              href={formActionLink}
              square
              onClick={(e) => {
                if (!searchResultView) {
                  setSearchValue('');
                  e.preventDefault();
                }
              }}
            >
              <Icon name="closeX" />
            </Button>
          ) }
        <Button form={formId} type="submit" square>
          <Icon name="search" />
        </Button>
      </ButtonGroup>
    </ButtonGroup>
  );
}

SearchBar.propTypes = propTypes;
SearchBar.defaultProps = defaultProps;

export default SearchBar;
