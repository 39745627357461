import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

# TODO: This is an extraction of HorizontalBarChart logic. We should gut it and replace with the plotly graph a la Matt.
# note: Component is placed on the end of the class name for namespacing.
class HorizontalBarChartComponent extends React.Component
  ID = 0

  @propTypes =
    direction: PropTypes.string
    dataLabel: PropTypes.string
    labels: PropTypes.array
    metric: PropTypes.string
    stats: PropTypes.array

  constructor: (props) ->
    super(props)
    this.state = @stateFromProps(props)
    this.state.id = "category-analysis-graph-#{this.props.direction}-#{ID++}"

  classes: ->
    classNames(
      'horizontal-barchart-component'
    )

  # TODO: Need to update
  #  * Move data fetching code or side effects to componentDidUpdate.
  #  * If you're updating state whenever props change, refactor your code to
  #     use memoization techniques or move it to static getDerivedStateFromProps.
  #     Learn more at: https://fb.me/react-derived-state
  #
  # This is called if the component receives new props.
  UNSAFE_componentWillReceiveProps: (nextProps) ->
    @setState(@stateFromProps(nextProps))

  # This is called after component is mounted in DOM
  componentDidMount: ->
    @generateBarChart()

  # This is called after the component is updated.
  componentDidUpdate: ->
    @generateBarChart()

  # LIFTED FROM BAR CHARTs
  MIN_CHART_Y_AXIS = 7
  INDEX_OF_STATS = 1

  stateFromProps: (props) ->
    statData = props.stats.map(Number)
    # flip it and reverse
    statData = statData.map((datum) -> datum * -1) if props.direction is 'left'
    statData = [props.dataLabel].concat(statData)
    labelData = ['labels'].concat(_.pluck(props.labels, 'label'))
    data = [labelData, statData]

    $.merge({ data: data }, @getAxisConfig(data))

  generateBarChart: ->
    c3.generate
      bindto: '#' + @state.id

      size:
        height: 225

      padding:
        left: @calculatePadding('left')
        right: @calculatePadding('right')

      data:
        x: 'labels'
        selection:
          enabled: false
        columns: @state.data
        type: 'bar'
        color: (color, d) =>
          @determineChartColor()

      bar:
        width: 20 # Sets width of bar in px

      axis:
        rotated: true
        x:
          show: false
          type: 'category'
        y:
          max: @max_y_axis
          min: @min_y_axis
          padding:
            top: 0
            bottom: 0
          tick:
            format: (d) =>
              if @props.metric
                # if metric is defined, use formatting for that metric
                FormatUtil.metricFormat(@props.metric, Math.abs(d))
              else if Math.floor(d) == d
                FormatUtil.largeNumberFormat(Math.abs(d))
              else
                ''

  # C3 Chart Creation Helpers
  getAxisConfig: (data) ->
    max_data = _.max(data[INDEX_OF_STATS], (datum) -> Math.abs(datum))
    below_minimum = Math.abs(max_data) < MIN_CHART_Y_AXIS

    min_y_axis = 0
    max_y_axis = 0

    if @props.direction is 'left'
      max_y_axis = 0
      min_y_axis =
        if below_minimum and _.isUndefined(@metric)
          -MIN_CHART_Y_AXIS
        else
          _.min(data[1])
    else if @props.direction is 'right'
      min_y_axis = 0
      max_y_axis =
        if below_minimum
          MIN_CHART_Y_AXIS
        else
          _.max(data[1])

    {
      min_y_axis: min_y_axis
      max_y_axis: max_y_axis
    }

  calculatePadding: (paddingSide) ->
    # Two graphs are mirrored so padding is used to make the sure the axis labels are
    # not cut off. The category metric analysis graph is on the left side so the padding
    # is 2px for the left side of the graph and 5px for the right side of the graph.
    # The other graph will be a mirror image of the padding
    outside_padding = 2
    inside_padding = 5

    if @props.direction is 'left'
      if paddingSide is 'left' then outside_padding else inside_padding
    else if @props.direction is 'right'
      if paddingSide is 'left' then inside_padding else outside_padding

  determineChartColor: ->
    if @props.direction is 'left' then C3GraphUtil.chartBlue() else C3GraphUtil.chartRed()

  render: ->
    `<div className={this.classes()} id={this.state.id}/>`

export default HorizontalBarChartComponent
