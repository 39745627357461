import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Column from "components/reusable/column"
import HorizontalGauge from "components/graphs/horizontal_gauge"
import Label from "components/reusable/label"
import Row from "components/reusable/row"

# This is a collection of horizontal gauges. One for each passed in row.
class HorizontalGaugeComparisonGroup extends React.Component
  @propTypes =
    color: PropTypes.string  # What color the gauges should have
    metric: PropTypes.string # Metric we are currently looking at. Used for formatting.
    # Rows to display horizontal gauges for! Should have row[props.view], percent, label, id.
    # Ex. [{id: 1, label: 'Row 1', percent: 100,  value: 1000},...] when props.view == 'value'
    rows: PropTypes.array
    title: PropTypes.string
    view: PropTypes.string   # What metric view should be displayed on the rows ex. :value, :percent, :rate

  classes: ->
    classNames(
      'category-comparison-group'
    )

  renderRow: (row) =>
    value = row[@props.view]
    # if we are looking at the value then we should format based on the metric, otherwise view ex. :rate, :percent
    format = if @props.view is 'value' then @props.metric else @props.view
    formattedValue = FormatUtil.metricFormat(format, value)
    key = _.uniqueId("horizontal-gauge-row")
    color = row.color or @props.color

    `<Row key={key}>
      <Column>
        <Row styleName="no-margin">
          <Column size={8} styleName="truncate">
            <Label value={row.label} path={row.path} />
          </Column>
          <Column size={4} styleName="right-align">{formattedValue}</Column>
        </Row>
        <Row>
          <Column>
            <HorizontalGauge percent={row.percent} color={color}/>
          </Column>
        </Row>
      </Column>
    </Row>`

  header: ->
    if @props.title
      `<Row styleName="no-margin">
        <Column styleName="center-align truncate">
          <span>{this.props.title}</span>
        </Column>
      </Row>`

  render: ->
    `<div>
      {this.header()}
      {this.props.rows.map(this.renderRow)}
    </div>`

export default HorizontalGaugeComparisonGroup
