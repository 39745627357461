import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  Button,
  TextArea,
} from '@makeably/creativex-design-system';
import SafeZoneModal from 'components/organisms/SafeZoneModal';
import { editAdminAuditPostPath } from 'utilities/routes';

const SPACE_KEY = ' ';

// This component renders a collection of guideline checks.
// A guideline check may have an initial state of null (blank checkbox).
export const propTypes = {
  callback: PropTypes.func.isRequired,
  guidelineChecks: PropTypes.arrayOf(
    PropTypes.shape({
      automated: PropTypes.bool,
      challengeMatchingAuditAssetId: PropTypes.number,
      challengeMatchingAuditPostId: PropTypes.number,
      challengeState: PropTypes.string,
      challengeUser: PropTypes.string,
      challengeUserDisplayReason: PropTypes.string,
      challengeUserExemption: PropTypes.string,
      challengeUserReason: PropTypes.string,
      changeCopy: PropTypes.string,
      checkEditable: PropTypes.bool,
      cv: PropTypes.bool,
      description: PropTypes.string,
      id: PropTypes.number,
      initialPassed: PropTypes.bool,
      label: PropTypes.string,
      lastReviewer: PropTypes.string,
      level: PropTypes.string,
      noChangeCopy: PropTypes.string,
      passed: PropTypes.bool,
      reviewerJustification: PropTypes.string,
      templateUrl: PropTypes.string,
      versions: PropTypes.arrayOf(PropTypes.number),
    }),
  ).isRequired,
  organizationId: PropTypes.number.isRequired,
  source: PropTypes.shape({
    creativeUrl: PropTypes.string,
    isVideo: PropTypes.bool,
  }).isRequired,
  view: PropTypes.string.isRequired,
  onGuidelineJustificationChange: PropTypes.func.isRequired,
};

function onClick(check, callback) {
  // automated guideline checks are not able to change
  // if a challenge is pending on the post/asset
  // and this guideline check is not challenged it is not changeable
  if (check.checkEditable) {
    return callback(check.id);
  }
  return undefined;
}

function onKeyDown(e, check, callback) {
  // The key event we should listen for depends on the role;
  // for checkbox it's the space key
  if (e.key === SPACE_KEY) {
    onClick(check, callback);
  }
}

function iconProps(passed, checkEditable) {
  if (passed) {
    return {
      color: checkEditable ? 'green' : 'grey',
      name: 'checkCircle',
    };
  } else if (passed === null) {
    return {
      color: 'orange',
      name: 'questionCircle',
    };
  }

  return {
    color: checkEditable ? 'red' : 'grey',
    name: 'xCircle',
  };
}

function renderLastReviewer({ lastReviewer }) {
  return (
    <div className="u-marginTop-8 u-marginBottom-16 u-flexRow">
      <div className="t-subtitle">Guideline Reviewer:&nbsp;</div>
      { lastReviewer }
    </div>
  );
}

function renderMatchingId({ challengeMatchingAuditAssetId, challengeMatchingAuditPostId }, orgId) {
  const url = editAdminAuditPostPath(orgId, challengeMatchingAuditPostId);

  return (
    <div className="u-marginBottom-16 u-flexRow">
      <div className="t-subtitle">Identical Creative ID:&nbsp;</div>
      <a href={url} rel="noreferrer" target="_blank">{ challengeMatchingAuditAssetId }</a>
    </div>
  );
}

function renderTextResponse(
  {
    challengeUserExemption,
    id,
    reviewerJustification,
  },
  onJustificationChange,
) {
  return (
    <>
      { challengeUserExemption && (
        <div className="u-marginBottom-8 u-flexRow">
          <div className="t-subtitle">Exemption Explanation:&nbsp;</div>
          { `"${challengeUserExemption}"` }
        </div>
      ) }
      <TextArea
        placeholder="Please explain why this rule was changed or unchanged. This will be visible to the user, so use full sentences and refer back to the definition when possible. If an error has been made, thank them for reporting it."
        size="large"
        value={reviewerJustification}
        onChange={(value) => onJustificationChange(id, value)}
      />
    </>
  );
}

function renderVersions(versions) {
  return <div className="u-marginAboveSm">{ `Versions: ${versions.join(', ')}` }</div>;
}

function renderGuidelineCheck(check, callback, onChange, orgId, openModal) {
  const classes = classNames({ 'guidelineCheckInput--editable': (check.checkEditable) });

  return (
    <div key={check.id} className="guidelineCheck t-body-1">
      <div className="u-flexRow u-marginBottom-16">
        <div
          aria-checked={check.passed}
          className={classes}
          role="checkbox"
          tabIndex="0"
          onClick={() => onClick(check, callback)}
          onKeyDown={(e) => onKeyDown(e, check, callback)}
        >
          <Icon {...iconProps(check.passed, check.checkEditable)} />
        </div>
        <div className="u-marginLeft">
          <div className="u-flexRow u-alignCenter">
            <div className="t-subtitle">{ check.label }</div>
            { check.templateUrl && (
              <button
                className="safeZone-button"
                type="button"
                onClick={() => openModal(check.templateUrl)}
              >
                View
              </button>
            ) }
          </div>
          { check.versions && renderVersions(check.versions) }
          <div className="u-flexRow u-flexAlignCenter">
            <div className="u-marginRightSm t-body-1">{ check.description }</div>
            <Button
              iconLeft="copy"
              variant="tertiary"
              onClick={() => navigator.clipboard.writeText(check.description)}
            />
          </div>
          { check.lastReviewer && renderLastReviewer(check) }
          { check.challengeUser && (
            <div className="u-marginAboveSm u-marginBottom-16 u-flexRow">
              <div className="t-subtitle">Challenger:&nbsp;</div>
              { check.challengeUser }
            </div>
          ) }
          { check.challengeUserReason && (
            <div className="u-marginAboveSm u-flexRow">
              <div className="t-subtitle">Reason:&nbsp;</div>
              { check.challengeUserDisplayReason }
            </div>
          ) }
        </div>
      </div>
      <div className="u-marginLeftLg u-marginBottom-8">
        { check.challengeMatchingAuditAssetId && renderMatchingId(check, orgId) }
        { check.challengeState === 'escalated' && renderTextResponse(check, onChange) }
      </div>
    </div>
  );
}

function renderCheckGroup(checks, typeHeader, callback, onChange, orgId, openModal) {
  if (checks.length > 0) {
    return (
      <div>
        <h4 className="u-marginBottom-16">{ typeHeader }</h4>
        { checks.map((check) => renderGuidelineCheck(check, callback, onChange, orgId, openModal)) }
      </div>
    );
  }

  return null;
}

function renderChecks(checks, callback, onChange, orgId, openModal) {
  const postChecks = checks.filter((check) => check.level === 'post');
  const assetChecks = checks.filter((check) => check.level === 'asset');

  if (checks.length > 0) {
    return (
      <div>
        { renderCheckGroup(postChecks, 'Post Checks', callback, onChange, orgId, openModal) }
        { renderCheckGroup(assetChecks, 'Asset Checks', callback, onChange, orgId, openModal) }
      </div>
    );
  }

  return 'No guidelines.';
}

function EvaluationPageGuidelineChecks({
  callback,
  guidelineChecks,
  onGuidelineJustificationChange,
  organizationId,
  source,
  view,
}) {
  const [isSafeZoneModalOpen, setIsSafeZoneModalOpen] = useState(false);
  const [templateUrl, setTemplateUrl] = useState('');

  const openSafeZoneModal = (template) => {
    setTemplateUrl(template);
    setIsSafeZoneModalOpen(true);
  };

  const checksToRender = guidelineChecks.filter((check) => {
    if (view === 'Automated Rules') {
      return check.automated;
    }
    return !check.automated;
  });

  return (
    <>
      <div className="u-marginAbove evalPageChecks">
        { renderChecks(
          checksToRender,
          callback,
          onGuidelineJustificationChange,
          organizationId,
          openSafeZoneModal,
        ) }
      </div>
      <SafeZoneModal
        isOpen={isSafeZoneModalOpen}
        source={source}
        templateUrl={templateUrl}
        onClose={() => setIsSafeZoneModalOpen(false)}
      />
    </>
  );
}

EvaluationPageGuidelineChecks.propTypes = propTypes;
export default EvaluationPageGuidelineChecks;
