import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  Tooltip,
} from '@makeably/creativex-design-system';
import ChannelLogo from 'components/atoms/ChannelLogo';
import { track } from 'utilities/mixpanel';
import styles from 'components/account_setup/AdPlatformSelection.module.css';

const channelProps = {
  key: PropTypes.string.isRequired,
  managed: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  remote: PropTypes.bool.isRequired,
  configConnection: PropTypes.string,
  connection: PropTypes.string,
  description: PropTypes.string,
};

const channelsProps = PropTypes.arrayOf(PropTypes.shape(channelProps));

export const propTypes = {
  channelsByType: PropTypes.objectOf(channelsProps).isRequired,
};

const sections = [
  {
    type: 'paid',
    title: 'Ad Platforms',
    description: 'Connect your paid media accounts by channel',
  },
  {
    type: 'supporting',
    title: 'Supporting Connections',
    description: 'Connect your missing brand pages and keep your DV360 Advertiser accounts up-to-date with new '
                 + 'Campaign IDs',
  },
  {
    type: 'organic',
    title: 'Organic Platforms',
    description: 'Connect your organic social media accounts by channel',
  },
];

const channelHasTooltip = {
  display_ads_access: true,
  dv360: true,
  facebook_paid: true,
  my_target_paid: true,
};

function getConnectionTooltip(connection, configConnection) {
  if (configConnection === 'both') {
    if (connection === 'partner') {
      return 'Connect using a Partner-based connection flow if you are not permitted to use an OAuth-based flow';
    }
  } else if (connection === 'oauth') {
    return 'Connect using an OAuth-based connection flow';
  } else if (connection === 'partner') {
    return 'Connect using a Partner-based connection flow';
  }
  return null;
}

function renderChannelCard({
  configConnection,
  connection,
  description,
  key,
  managed,
  name,
  path,
  remote,
}) {
  const href = managed ? null : path;
  const target = remote ? '_blank' : null;
  const cardClasses = classNames(styles.card, { [styles.disabled]: managed });
  const hasTooltip = managed || channelHasTooltip[key];

  let cardKey = key;
  let cardName = name;
  let tooltip = managed ? 'Managed by CreativeX' : description;

  if (connection) {
    if (connection && configConnection) {
      tooltip = getConnectionTooltip(connection, configConnection);
    }
    cardKey += `_${connection}`;
    cardName += connection === 'oauth' ? ' (OAuth)' : ' (Partner)';
  }

  return (
    <a
      key={cardKey}
      className={cardClasses}
      href={href}
      target={target}
      onClick={() => track('select_platform', { platform: key })}
    >
      <div className={styles.content}>
        <div className={styles.logo}>
          <ChannelLogo channel={key} showPaid={false} />
        </div>
        <div className={styles.text}>
          <h5 className="u-marginRightSm">{ cardName }</h5>
          { hasTooltip && <Tooltip label={tooltip} /> }
        </div>
      </div>
      <Icon color="current" name="chevronRight" />
    </a>
  );
}

function renderSection(section, channelsByType) {
  const channels = channelsByType[section.type] ?? [];

  if (channels.length === 0) {
    return null;
  }

  return (
    <div key={section.title} className={styles.section}>
      <div className={styles.header}>
        <h5>{ section.title }</h5>
        <div className="t-body-1">{ section.description }</div>
      </div>
      <div className={styles.cards}>
        { channels.map((channel) => renderChannelCard(channel)) }
      </div>
    </div>
  );
}

function AdPlatformSelection({ channelsByType }) {
  return (
    <div className={styles.sections}>
      { sections.map((section) => renderSection(section, channelsByType)) }
    </div>
  );
}

AdPlatformSelection.propTypes = propTypes;

export default AdPlatformSelection;
