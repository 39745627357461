import React from 'react';
import PropTypes from 'prop-types';
import { track } from 'utilities/mixpanel';

const propTypes = {
  event: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

function TrackedLink({
  event,
  href,
  label,
}) {
  return (
    <a
      href={href}
      onClick={() => track(event, { href })}
    >
      { label }
    </a>
  );
}

TrackedLink.propTypes = propTypes;

export default TrackedLink;
