import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Logo,
  Toggle,
} from '@makeably/creativex-design-system';
import BmeUtilityModal from 'components/client/settings/modal/BmeUtilityModal';
import DataTable, { dataTablePropTypes } from 'components/reusable/data_table.coffee';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import {
  channelName,
  logoName,
} from 'utilities/accounts';
import { settingsLinkedPlatformAccountPath } from 'utilities/routes';
import styles from './AccountDetails.module.css';

const accountFormName = 'account_form';

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  disabled: PropTypes.bool,
});

const accountProps = {
  accountClass: PropTypes.string.isRequired,
  dateLinked: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  organizationAffiliateId: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  usage: PropTypes.string.isRequired,
  brand: PropTypes.string,
  businessCenterId: PropTypes.number,
  connectionType: PropTypes.string,
  currency: PropTypes.string,
  market: PropTypes.string,
  name: PropTypes.string,
  userId: PropTypes.number,
};

const propTypes = {
  account: PropTypes.shape(accountProps).isRequired,
  brandSelectOptions: PropTypes.arrayOf(optionProps).isRequired,
  canModifyDetails: PropTypes.bool.isRequired,
  canPreserveBme: PropTypes.bool.isRequired,
  canRemap: PropTypes.bool.isRequired,
  marketSelectOptions: PropTypes.arrayOf(optionProps).isRequired,
  partnerSelectOptions: PropTypes.arrayOf(optionProps).isRequired,
  userSelectOptions: PropTypes.arrayOf(optionProps).isRequired,
  facebookAdPagesDetails: PropTypes.shape(dataTablePropTypes),
};

const defaultProps = {
  facebookAdPagesDetails: undefined,
};

function renderDetail({ label, value }) {
  return (
    <div key={label} className="u-marginBottom-16">
      <div className="t-caption-1 u-marginBottom-8">
        { label }
      </div>
      <div className="t-body-1">
        { value }
      </div>
    </div>
  );
}

function renderAccountDetails(account) {
  const detailsList = [
    {
      label: 'Platform Account Name',
      value: account.name ?? 'N/A',
    },
    {
      label: 'Platform Account ID',
      value: account.number,
    },
  ];

  if (account.accountClass === 'TiktokAccount') {
    detailsList.push({
      label: 'Business Center Id',
      value: account.businessCenterId ?? 'N/A',
    });
  }

  detailsList.push(
    {
      label: 'Currency',
      value: account.currency ?? 'N/A',
    },
    {
      label: 'Channel',
      value: channelName(account.usage),
    },
    {
      label: 'Date Connected',
      value: account.dateLinked,
    },
  );

  if (account.connectionType) {
    detailsList.push({
      label: 'Connection Type',
      value: account.connectionType,
    });
  }

  return (
    <div className="u-flexColumn u-flexGrow">
      { detailsList.map(renderDetail) }
    </div>
  );
}

function renderFacebookBrandPages(facebookAdPagesDetails) {
  return (
    <Card>
      <div className="u-marginBottom-16">
        <h5>Associated Facebook Brand Pages</h5>
      </div>
      <Divider />
      { facebookAdPagesDetails && (
        <>
          <p className="u-marginTop-16">
            To track video content, Facebook requires your Brand Page to be connected to
            CreativeX. Please activate the pages below.
          </p>
          <DataTable
            {...facebookAdPagesDetails}
          />
        </>
      ) }
      { !facebookAdPagesDetails && (
        <p className="u-marginTop-16">
          There are no Facebook Brand Pages associated with this ad account
        </p>
      ) }
    </Card>
  );
}

function AccountDetails({
  account,
  brandSelectOptions,
  canModifyDetails,
  canPreserveBme,
  canRemap,
  facebookAdPagesDetails,
  marketSelectOptions,
  partnerSelectOptions,
  userSelectOptions,
}) {
  const [orgAffiliateId, setOrgAffiliateId] = useState(account.organizationAffiliateId);
  const [accountState, setAccountState] = useState(account.state);
  const [userId, setUserId] = useState(account.userId);
  const [brand, setBrand] = useState(account.brand);
  const [market, setMarket] = useState(account.market);
  const [preserveBme, setPreserveBme] = useState(false);
  const [showRemappingModal, setShowRemappingModal] = useState(false);

  const handleUpdate = () => {
    if (account.brand === brand && account.market === market) {
      document[accountFormName].submit();
    } else {
      setShowRemappingModal(true);
    }
  };

  const subtitle = canModifyDetails ? 'View and modify ad account details' : 'View ad account details';

  return (
    <div>
      <div className="u-marginBottom-16">
        <Card>
          <div className="u-flexRow u-marginBottom-16">
            <span className={styles.logo}>
              <Logo name={logoName(account.usage)} />
            </span>
            <div>
              <div className="u-marginTop-8">
                <h5>Account Details</h5>
              </div>
              <div className="t-caption-1 u-marginTop-8">
                { subtitle }
              </div>
            </div>
          </div>
          <Divider />
          <div className="u-flexRow u-marginTop-16 u-marginBottom-16">
            <div className="u-flexGrow">
              <div className="t-subtitle">
                Ad Account Details
              </div>
            </div>
            <div className="u-flexGrow u-marginLeft">
              <div className="t-subtitle">
                CreativeX Details
              </div>
            </div>
          </div>
          <Divider />
          <form action={settingsLinkedPlatformAccountPath(account.id)} method="post" name={accountFormName}>
            <input name="_method" type="hidden" value="patch" />
            <AuthenticityTokenInput />
            <div className="u-flexRow u-marginTop-16 u-marginBottom-16">
              <div className="u-flexRow u-flexGrow u-marginRight">
                { renderAccountDetails(account) }
                <Divider vertical />
              </div>
              <div className="u-flexColumn u-flexGrow">
                <HiddenInput name="account_class" value={account.accountClass} />
                <HiddenInput name="account[organization_affiliate_id]" value={orgAffiliateId} />
                <HiddenInput name="account[state]" value={accountState} />
                <HiddenInput name="account[user_id]" value={userId} />
                <HiddenInput name="account[brand]" value={brand} />
                <HiddenInput name="account[market]" value={market} />
                <HiddenInput name="preserve_bme" value={preserveBme} />
                <div className="u-marginBottom-16">
                  <Dropdown
                    disabled={!canModifyDetails}
                    label="Owner"
                    menuProps={{ size: 'medium' }}
                    options={userSelectOptions}
                    selected={userSelectOptions.find((opt) => opt.value === userId)}
                    size="medium"
                    onChange={(e) => setUserId(e.value)}
                  />
                </div>
                <div className="u-marginBottom-16">
                  <Dropdown
                    disabled={!canModifyDetails}
                    label="Partner"
                    menuProps={{ size: 'medium' }}
                    options={partnerSelectOptions}
                    selected={partnerSelectOptions.find((opt) => opt.value === orgAffiliateId)}
                    size="medium"
                    onChange={(e) => setOrgAffiliateId(e.value)}
                  />
                </div>
                <div className="u-marginBottom-16">
                  <Dropdown
                    disabled={!canRemap}
                    label="Market"
                    menuProps={{ size: 'medium' }}
                    options={marketSelectOptions}
                    selected={marketSelectOptions.find((opt) => opt.value === market)}
                    size="medium"
                    onChange={(e) => setMarket(e.value)}
                  />
                </div>
                <div className="u-marginBottom-16">
                  <Dropdown
                    disabled={!canRemap}
                    label="Brand"
                    menuProps={{ size: 'medium' }}
                    options={brandSelectOptions}
                    selected={brandSelectOptions.find((opt) => opt.value === brand)}
                    size="medium"
                    onChange={(e) => setBrand(e.value)}
                  />
                </div>
                { canPreserveBme && (
                  <div className="u-marginBottom-16">
                    <Toggle
                      checked={preserveBme}
                      disabled={!canRemap}
                      label="Preserve Historical BME"
                      onChange={(e) => setPreserveBme(e.target.checked)}
                    />
                  </div>
                ) }
                <div className="t-caption-1 u-marginBottom-8">
                  Status
                </div>
                <div className="u-flexRow u-justifySpaceBetween">
                  <div className="t-body-1">
                    This account is&nbsp;
                    <span className="t-subtitle">{ account.state }</span>
                    .
                  </div>
                  <Toggle
                    checked={accountState === 'active'}
                    disabled={!canModifyDetails}
                    onChange={(e) => setAccountState(e.target.checked ? 'active' : 'inactive')}
                  />
                </div>
              </div>
            </div>
            { (canModifyDetails || canRemap)
              && (
              <>
                <Divider />
                <div className="u-flexRow u-justifyFlexEnd u-marginTop-16">
                  <Button
                    label="Update"
                    variant="primary"
                    onClick={handleUpdate}
                  />
                </div>
              </>
              ) }
          </form>
        </Card>
      </div>
      { account.accountClass === 'FacebookAdAccount' && renderFacebookBrandPages(facebookAdPagesDetails) }
      <BmeUtilityModal
        accountClass={account.accountClass}
        accountId={account.id}
        canPreserveBme={canPreserveBme}
        currentBrand={account.brand}
        currentMarket={account.market}
        newBrand={brand}
        newMarket={market}
        platformAccountId={account.number}
        preserveBme={preserveBme}
        visible={showRemappingModal}
        onModalClose={() => setShowRemappingModal(false)}
        onSubmitForm={() => document[accountFormName].submit()}
      />
    </div>
  );
}

AccountDetails.propTypes = propTypes;
AccountDetails.defaultProps = defaultProps;

export default AccountDetails;
