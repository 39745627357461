import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { addToast } from 'components/organisms/Toasts';

const propTypes = {
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

function getType(name) {
  switch (name) {
    case 'alert':
    case 'error':
      return 'error';
    default:
      return 'success';
  }
}

function FlashToast({
  message,
  name,
}) {
  useEffect(() => {
    addToast(message, {
      duration: 10000,
      size: 'large',
      type: getType(name),
    });
  }, []);

  return null;
}

FlashToast.propTypes = propTypes;

export default FlashToast;
