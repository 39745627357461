import React from 'react';
import PropTypes from 'prop-types';
import { SelectDate } from '@duik/it';
import styles from 'components/molecules/DuikSelectDate.module.css';

const propTypes = {
  label: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  block: PropTypes.bool,
  initialVisibleDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  value: PropTypes.instanceOf(Date),
};

const defaultProps = {
  block: false,
  initialVisibleDate: undefined,
  maxDate: undefined,
  minDate: undefined,
  value: undefined,
};

function DuikSelectDate({
  block,
  initialVisibleDate,
  label,
  maxDate,
  minDate,
  onDateChange,
  value,
}) {
  return (
    <div className={styles.duikDatepicker}>
      <SelectDate
        block={block}
        initialVisibleDate={initialVisibleDate}
        label={label}
        maxDate={maxDate}
        minDate={minDate}
        value={value}
        onDateChange={onDateChange}
      />
    </div>
  );
}

DuikSelectDate.propTypes = propTypes;
DuikSelectDate.defaultProps = defaultProps;

export default DuikSelectDate;
