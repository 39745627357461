import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ChannelLogo from 'components/atoms/ChannelLogo';
import ChannelLogos from 'components/molecules/ChannelLogos';
import styles from './GuidelineDetail.module.css';

const channelType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

const propTypes = {
  channels: PropTypes.arrayOf(channelType).isRequired,
  openDrawer: PropTypes.func.isRequired,
  shownMax: PropTypes.number,
};

const defaultProps = {
  shownMax: undefined,
};

function ChannelsWithDrawer({
  channels,
  openDrawer,
  shownMax,
}) {
  const drawerContent = channels.map(({ label, value }) => (
    <div key={value} className="u-marginBottom-8 u-flexRow u-flexAlignCenter">
      <ChannelLogo channel={value} showPaid={false} size="size-24" />
      { label }
    </div>
  ));

  const displayChannelDrawer = () => openDrawer(`Channels (${channels.length})`, drawerContent);

  return (
    <div
      className={classnames(styles.definitionItem, styles.clickable)}
      role="button"
      tabIndex="0"
      onClick={displayChannelDrawer}
      onKeyDown={(e) => {
        if (e.key === 'Enter') displayChannelDrawer();
      }}
    >
      <div className="t-caption-1">Channels:</div>
      <div className={styles.channels}>
        <ChannelLogos channels={channels} showTooltipForHidden={false} shownMax={shownMax} />
      </div>
    </div>
  );
}

ChannelsWithDrawer.propTypes = propTypes;
ChannelsWithDrawer.defaultProps = defaultProps;

export default ChannelsWithDrawer;
