import React from 'react';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
  WidgetHeader,
} from '@duik/it';
import DoughnutChart, { propTypes as doughnutProps } from 'components/charts/DoughnutChart';

const propTypes = {
  chart: PropTypes.shape(doughnutProps).isRequired,
};

function ComplianceRateBreakdown(props) {
  const { chart } = props;

  return (
    <Widget className="auditReport-chart">
      <WidgetHeader>
        <div className="u-flexRow u-flexAlignCenter">
          <div>
            <h5>Compliant vs Non-Compliant Content</h5>
          </div>
        </div>
      </WidgetHeader>
      <WidgetContent className="chartContainer chartContainer--doughnut">
        <DoughnutChart chart={chart} overview />
      </WidgetContent>
    </Widget>
  );
}

ComplianceRateBreakdown.propTypes = propTypes;

export default ComplianceRateBreakdown;
