import React from "react"
import PropTypes from "prop-types"
import BreakdownFilter from "components/search/breakdown_filter"
import Button from "components/reusable/button"
import CampaignSelectorForm from "components/search/campaign_selector_form"
import Card from "components/reusable/card"
import CheckboxGroup from "components/reusable/checkbox_group"
import classNames from "classnames"
import DateRange from "components/reusable/date_range"
import Label from "components/reusable/label"
import RadioButtonGroup from "components/reusable/radio_button_group"
import Select from "components/reusable/select"
import Typeahead from "components/reusable/typeahead"

# responsible for search form interactions on the repository search pages and deep dive pages
# NB: there is no `form` element in this component, the form inputs here are tied to an external
# form via the form attribute on input elements (HTML5).
#
# Notes on props:
#   filters: array of things that can filter results on the page (organizations, accounts, dates)
#            displayed as cards with headers
#   formId:  id of form to submit (see note on layout above)
#   selectedFilters: represents initial filters that have been selected (passed from server)
class SearchForm extends React.Component
  @propTypes =
    formId: PropTypes.string
    filters: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(
          cardProps: PropTypes.object
          radioProps: PropTypes.object
          param: PropTypes.string.isRequired
          type: PropTypes.string.isRequired
        ),
        PropTypes.shape(
          cardProps: PropTypes.object
          checkboxProps: PropTypes.object
          param: PropTypes.string.isRequired
          type: PropTypes.string.isRequired
        ),
        PropTypes.shape(
          cardProps: PropTypes.object
          selectProps: PropTypes.object
          param: PropTypes.string.isRequired
          type: PropTypes.string.isRequired
        ),
        PropTypes.shape(
          cardProps: PropTypes.object
          dateRangeProps: PropTypes.object
          param: PropTypes.string.isRequired
          type: PropTypes.string.isRequired
        ),
        PropTypes.shape(
          cardProps: PropTypes.object
          componentProps: PropTypes.object
          param: PropTypes.string.isRequired
          type: PropTypes.string.isRequired
        ),
        PropTypes.shape(
          cardProps: PropTypes.object
          dateRangeProps: PropTypes.object
          param: PropTypes.string.isRequired
        ),
        PropTypes.shape(
          hiddenProps: PropTypes.object
          type: PropTypes.string.isRequired
        ),
        PropTypes.shape(
          cardProps: PropTypes.object
          componentProps: PropTypes.object
          param: PropTypes.string.isRequired
          type: PropTypes.string.isRequired
        )
      ])
    )
    # See search_form.rb for details on the selected filters
    selectedFilters: PropTypes.object

  classes: ->
    classNames(
      @props.className
    )

  constructor: (props) ->
    super(props)
    @state = props.selectedFilters

  # disallow scrolling of main content when scrolling filters div
  pauseMainDivScroll: ->
    document.body.style.overflow = 'hidden'

  # set scrolling back to normal
  resumeScrollBehavior: ->
    document.body.style.overflow = 'auto'

  handleClick: (filter) =>
    (value, additionalFields = {}) =>
      inputName =
        if additionalFields['inputName']
          if filter.type is 'checkbox'
            # NB: For the checkbox, we need to remove the [] in the input name
            indexOfBrackets = additionalFields['inputName'].indexOf("[]")
            additionalFields['inputName'].slice(0, indexOfBrackets)
          else
            additionalFields['inputName']
        else
          filter.param

      updatedState = {}
      updatedState[inputName] = @state[inputName]

      if filter.type is 'checkbox' && additionalFields.bulkUpdate
        # bulkUpdate sets the value to all values passed through (for selectAll or select all
        # matching conditions behavior)
        updatedState[inputName] = if additionalFields.isChecked then value else []
      else if filter.type is 'checkbox'
        if additionalFields['isChecked']
          # add value to state
          updatedState[inputName].push(value)
        else
          # remove value from state
          updatedState[inputName] = _.without(updatedState[inputName], value)

      else # handle a non-array param (dates included here)
        updatedState[inputName] = value

      if additionalFields['campaignObjectives']
        updatedState['campaign_objectives'] = additionalFields['campaignObjectives']
      if additionalFields['campaignStatuses']
        updatedState['campaign_statuses'] = additionalFields['campaignStatuses']
      if additionalFields['excludedFacebookAdCampaigns']
        updatedState['c_ids_x'] = additionalFields['excludedFacebookAdCampaigns']

      # Changing FacebookAdAccount should clear out the FacebookAdCampaign selection
      if filter.param == 'ad_account_id'
        updatedState['c_ids'] = []
        updatedState['c_ids_x'] = []
        updatedState['campaign_objectives'] = []
        updatedState['campaign_statuses'] = []

      # NB: The default breakdown_value is '' when the breakdown_type is not age or gender.
      #     This is due to the fact that age and gender are static lists but something like country
      #     is a dynamic list
      #
      #     breakdown_value => this is the secondary filter (things such as 18-25, male, etc...
      if !_.isUndefined(additionalFields['defaultBreakdownValue'])
        updatedState['breakdown_value'] = additionalFields['defaultBreakdownValue']

      @setState(updatedState)

  renderOptions: (filter) ->
    if filter.param is 'c_ids'
      # NB: need the call to window because the string representation,
      # say "TextInput", is not equivalent to the object TextInput.
      # Also, react only recognizes components that are capitalized
      `<CampaignSelectorForm
         {...filter.componentProps}
         campaignObjectives={this.state.campaign_objectives}
         campaignStatuses={this.state.campaign_statuses}
         excludedFacebookAdCampaigns={this.state.c_ids_x}
         facebookAdAccountId={this.state.ad_account_id}
         onChange={this.handleClick(filter)}
         selectedFacebookAdCampaigns={this.state.c_ids}
      />`

    else if filter.param is 'breakdown_value'
      `<BreakdownFilter
        {...filter.componentProps}
        adAccountId={parseInt(this.state.ad_account_id)}
        onChange={this.handleClick(filter)}
        selectedBreakdownType={this.state.breakdown_type}
        selectedBreakdownValue={this.state.breakdown_value}
      />`

    else if filter.type is 'radio'
      `<RadioButtonGroup
         {...filter.radioProps}
         selectedValue={this.state[filter.param]}
         onSelect={this.handleClick(filter)}
      />`

    else if filter.type is 'checkbox'
      `<CheckboxGroup
         {...filter.checkboxProps}
         selectedValues={this.state[filter.param]}
         onSelect={this.handleClick(filter)}
      />`

    else if filter.type is 'typeahead'
      `<Typeahead
         {...filter.typeaheadProps}
         formValue={_.pluck(this.state[filter.param], 'value')}
         onChange={this.handleClick(filter)}
         selected={this.state[filter.param]}
      />`

    else if filter.type is 'select'
      `<Select
         {...filter.selectProps}
         onChange={this.handleClick(filter)}
         selected={this.state[filter.param]}
      />`

    else if filter.type is 'date'
      `<DateRange
         {...filter.dateRangeProps}
         onSelect={this.handleClick(filter)}
         endDate={this.state.end_date}
         startDate={this.state.start_date}
      />`

    else if filter.type is 'hidden'
      `<input
         {...filter.hiddenProps}
         key={filter.hiddenProps.name}
         type="hidden"
      />`

  renderFilter: (filter) =>
    options = @renderOptions(filter)
    return options unless filter.cardProps

    `<Card
       {...filter.cardProps}
       key={filter.cardProps.header}
       headerTooltipProps={filter.tooltip}
     >
       {options}
     </Card>`

  renderSubmit: ->
    `<Card className="center">
       <button className='btn' role='submit' form={this.props.formId}>
         Search
       </button>
     </Card>`

  render: ->
    labelProps = { value: 'FILTERS', iconProps: { 'icon': 'filter' } }

    `<div className={this.classes()}
      onMouseOver={this.pauseMainDivScroll}
      onMouseOut={this.resumeScrollBehavior}
    >
      <div className='filter-header'>
        <Label {...labelProps} />
      </div>
      { this.props.filters.map(this.renderFilter) }
      { this.renderSubmit() }

      <Card>
        <Button
          link={window.location.pathname}
          className={'btn-outline'}
          label={'Clear Filters'}
        />
      </Card>
     </div>`

export default SearchForm
