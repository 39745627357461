// Helper functions for sorting data
export function byObjectLabel(a, b) {
  if (a.label > b.label) {
    return 1;
  }
  if (a.label < b.label) {
    return -1;
  }
  return 0;
}

export function byObjectValue(a, b) {
  if (a.value > b.value) {
    return 1;
  }
  if (a.value < b.value) {
    return -1;
  }
  return 0;
}

const rankValues = {
  lowest: 0,
  low: 1,
  medium: 2,
  high: 3,
  highest: 4,
};

export function byRank(a, b) {
  const aValue = rankValues[a];
  const bValue = rankValues[b];

  if (aValue > bValue) {
    return 1;
  }
  if (aValue < bValue) {
    return -1;
  }
  return 0;
}
