import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ContainerHorizontal,
  ContainerVertical,
  WidgetHeader,
} from '@duik/it';
import {
  Icon,
  Tooltip,
} from '@makeably/creativex-design-system';

const propTypes = {
  header: PropTypes.string.isRequired,
  icon: PropTypes.string,
  linkButton: PropTypes.shape({
    link: PropTypes.string,
    remote: PropTypes.bool,
    text: PropTypes.string,
  }),
  subHeader: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  tooltip: PropTypes.string,
};
const defaultProps = {
  icon: undefined,
  linkButton: undefined,
  subHeader: undefined,
  tooltip: undefined,
};

function renderIcon(icon) {
  if (icon) {
    return (
      <div className="squareIcon u-marginRight">
        <Icon color="white" name={icon} />
      </div>
    );
  }

  return null;
}

function renderButtonLink(buttonProps) {
  if (buttonProps) {
    const {
      link,
      remote,
      text,
    } = buttonProps;

    // Remote opens the link in a new tab
    const target = remote ? '_blank' : undefined;

    return (
      <Button Component="a" href={link} target={target} transparent>
        <div>
          <Icon name="externalLink" />
          { text }
        </div>
      </Button>
    );
  }

  return null;
}

function CardHeader(props) {
  const {
    header,
    icon,
    linkButton,
    subHeader,
    tooltip: tooltipText,
  } = props;

  let subHeaderContent = subHeader;
  if (subHeader && typeof subHeader === 'string') {
    subHeaderContent = <p>{ subHeader }</p>;
  }

  return (
    <WidgetHeader className="cardHeader">
      <ContainerHorizontal className="u-flexAlignCenter">
        { renderIcon(icon) }
        <ContainerVertical>
          <div className="u-flexRow u-flexAlignCenter cardHeader-heading">
            <h5 className="u-marginRightSm">{ header }</h5>
            { tooltipText && <Tooltip label={tooltipText} /> }
          </div>
          <div className="cardHeader-subheading">
            { subHeaderContent }
          </div>
        </ContainerVertical>
        { renderButtonLink(linkButton) }
      </ContainerHorizontal>
    </WidgetHeader>
  );
}

CardHeader.propTypes = propTypes;
CardHeader.defaultProps = defaultProps;

export default CardHeader;
