import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  MessageModal,
} from '@makeably/creativex-design-system';
import { getAuthenticityToken } from 'utilities/requests';
import {
  bulkUpdatePartnerCreativeLifecycleCoreAssetsPath,
  editCreativeLifecycleCampaignPath,
} from 'utilities/routes';
import styles from './AssetUpdatePartnerModal.module.css';

const propTypes = {
  campaignId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  selectedUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

function AssetUpdatePartnerModal({
  campaignId,
  isOpen,
  onClose,
  partners,
  selectedUuids,
}) {
  const [errorText, setErrorText] = useState();
  const [newPartner, setNewPartner] = useState();

  const onActionButtonClick = async () => {
    const response = await fetch(bulkUpdatePartnerCreativeLifecycleCoreAssetsPath(), {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-CSRF-Token': getAuthenticityToken(),
      },
      body: JSON.stringify({
        core_asset_uuids: selectedUuids,
        partner_id: newPartner.value,
      }),
    });

    if (response.ok) {
      window.location.href = editCreativeLifecycleCampaignPath(campaignId);
    } else {
      setErrorText('Something went wrong, please try again');
    }
  };

  return (
    <MessageModal
      actionButtonDisabled={!newPartner}
      actionButtonLabel="Update"
      isOpen={isOpen}
      subtitle="Choose a new production partner from the dropdown below."
      title={`Update Selected Core Assets (${selectedUuids.length})`}
      onActionButtonClick={onActionButtonClick}
      onClose={onClose}
    >
      <>
        <div className={styles.dropdown}>
          <Dropdown
            disabled={partners.length === 0}
            label="Production Partner"
            menuProps={{ size: 'medium' }}
            options={partners}
            placeholder={partners.length > 0 ? 'Select' : 'None'}
            selected={newPartner}
            size="medium"
            onChange={(p) => setNewPartner(p)}
          />
        </div>
        <div className={styles.errorText}>{ errorText }</div>
      </>
    </MessageModal>
  );
}

AssetUpdatePartnerModal.propTypes = propTypes;

export default AssetUpdatePartnerModal;
