import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DataVizSkeleton,
} from '@makeably/creativex-design-system';
import ItemsGroupedBarChart from 'components/molecules/ItemsGroupedBarChart';
import { itemProps } from 'utilities/item';
import styles from './TimePeriodVisualization.module.css';

const metricProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
});

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(itemProps).isRequired,
  onAddSegment: PropTypes.func.isRequired,
  metric: metricProps,
  segment: metricProps,
  timePeriod: metricProps,
};

const defaultProps = {
  metric: undefined,
  segment: undefined,
  timePeriod: undefined,
};

function TimePeriodVisualization({
  isLoading,
  items,
  metric,
  onAddSegment,
  segment,
  timePeriod,
}) {
  if (
    items.length === 0
      || !metric
      || !timePeriod
      || isLoading
  ) {
    return (
      <div className={styles.main}>
        <div className={styles.empty}>
          <DataVizSkeleton animate={isLoading} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.controls}>
        <div className={styles.chart}>
          <ItemsGroupedBarChart
            barKey={timePeriod.value}
            dataType={metric.type}
            displayKey={metric.value}
            displayLabel={metric.label}
            groupKey={segment?.value}
            items={items}
          />
          { !segment && (
            <Button
              label="Add Segment"
              variant="tertiary"
              onClick={onAddSegment}
            />
          ) }
        </div>
      </div>
    </div>
  );
}

TimePeriodVisualization.propTypes = propTypes;
TimePeriodVisualization.defaultProps = defaultProps;

export default TimePeriodVisualization;
