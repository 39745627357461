// This component is responsible for generating a banner on the landing pages
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Alert } from '@duik/it';
import { Icon } from '@makeably/creativex-design-system';

const sessionId = 'landingBanner_v3';

const propTypes = {
  bannerLink: PropTypes.string.isRequired,
  bannerTitle: PropTypes.string.isRequired,
  bannerId: PropTypes.string,
  className: PropTypes.string,
  iconName: PropTypes.string,
  isWarning: PropTypes.bool,
  permanent: PropTypes.bool,
};

const defaultProps = {
  bannerId: sessionId,
  className: undefined,
  iconName: 'direction-right',
  isWarning: false,
  permanent: false,
};

class LandingBanner extends React.Component {
  constructor(props) {
    super(props);

    // Check session on load to see if it has already been closed by the user
    const sessionClosed = localStorage.getItem(props.bannerId);
    const bannerClosed = sessionClosed === 'closed';

    this.state = {
      closed: bannerClosed,
    };
  }

  closeBanner() {
    // Save to session so it doesn't contiually pop up on new page loads if the
    // user has closed it
    const {
      bannerId,
    } = this.props;
    localStorage.setItem(bannerId, 'closed');
    this.setState({ closed: true });
  }

  renderCloseButton() {
    return (
      <button
        aria-label="Close"
        className="close"
        data-dismiss="alert"
        type="button"
        onClick={() => this.closeBanner()}
      >
        <Icon className="icon--white" display="outline" name="close" />
      </button>
    );
  }

  render() {
    const { closed } = this.state;
    if (closed) return null;

    const {
      bannerLink,
      bannerTitle,
      className,
      iconName,
      isWarning,
      permanent,
    } = this.props;

    const bannerClass = classNames(
      'u-flexRow',
      'u-justifyCenter',
      'u-flexAlignCenter',
      'landingBanner',
      className,
    );

    return (
      <div className={bannerClass}>
        <Alert
          leftEl={<Icon color="white" name={iconName} />}
          rightEl={permanent ? null : this.renderCloseButton()}
          warning={isWarning}
        >
          <a href={bannerLink} rel="noreferrer" target="_blank">
            <p className="p--small">{ bannerTitle }</p>
          </a>
        </Alert>
      </div>
    );
  }
}

LandingBanner.propTypes = propTypes;
LandingBanner.defaultProps = defaultProps;

export default LandingBanner;
