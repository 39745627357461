import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { adminPropTypes } from 'components/navigation/AdminNavigation';
import { navPropTypes } from 'components/organisms/Navigation';
import Page, {
  headerPropTypes,
  pageSizes,
  tabPropTypes,
} from 'components/templates/Page';

export const pagePropTypes = {
  content: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  header: PropTypes.shape(headerPropTypes).isRequired,
  path: PropTypes.string.isRequired,
  admin: PropTypes.shape(adminPropTypes),
  nav: PropTypes.shape(navPropTypes),
  size: PropTypes.oneOf(pageSizes),
  tabs: PropTypes.arrayOf(
    PropTypes.shape(tabPropTypes),
  ),
};
const defaultProps = {
  admin: undefined,
  nav: undefined,
  size: undefined,
  tabs: undefined,
};

function renderLoading() {
  // @note: webpack has all components on our system preloaded, so this should never be seen
  return <div>Loading...</div>;
}

function ComponentPage({
  admin,
  content,
  header,
  nav,
  path,
  size,
  tabs,
}) {
  const Content = React.lazy(() => import(`components/${path}`));

  return (
    <Page
      admin={admin}
      header={header}
      nav={nav}
      size={size}
      tabs={tabs}
    >
      <Suspense fallback={renderLoading()}>
        <Content {...content} />
      </Suspense>
    </Page>
  );
}

ComponentPage.propTypes = pagePropTypes;
ComponentPage.defaultProps = defaultProps;

export default ComponentPage;
