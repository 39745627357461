import React from "react"
import PropTypes from "prop-types"

# Displays a card representing a default comparison of Best & Worst
# performing images for an account with a year-to-date time range
class DefaultComparisonCard extends React.Component
  @propTypes =
    id: PropTypes.string.isRequired # unique id for the post group
    linkUrl: PropTypes.string.isRequired # link for card's click action
    dateRange: PropTypes.string # String date range for post group
    label: PropTypes.string # post group label

  render: ->
    linkId = "comparisonCard_#{@props.id}"

    `<a id={linkId} href={this.props.linkUrl}>
      <div className='comparisonCard comparisonCard--default'>
        <div className='defaultComparisonCard-label'>
          <span>{this.props.label}</span>
          <span className='defaultComparisonCard-dateRange'>({this.props.dateRange})</span>
        </div>
      </div>
    </a>`

export default DefaultComparisonCard
