import React, {
  useState,
  useEffect,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  CopyButton,
  Icon,
  Spinner,
} from '@makeably/creativex-design-system';
import PostInfo from 'components/admin/review_queue/post_info/PostInfo';
import { postInfoAdminReviewGuidelineCheckPath } from 'utilities/routes';
import styles from './GuidelineCheckDisplay.module.css';

const displayProps = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string,
  }),
);

export const checkProps = {
  checkId: PropTypes.number.isRequired,
  cv: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  auditAssetId: PropTypes.number,
  createdAt: PropTypes.string,
};

export const propTypes = {
  check: PropTypes.shape({
    ...checkProps,
    detailsDisplay: displayProps.isRequired,
    headersDisplay: displayProps.isRequired,
  }).isRequired,
  editUrl: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  setPassed: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  evaluating: PropTypes.bool,
  isGuess: PropTypes.bool,
  passed: PropTypes.bool,
  setIsGuess: PropTypes.func,
};

const defaultProps = {
  errorMessage: undefined,
  evaluating: null,
  isGuess: null,
  passed: null,
  setIsGuess: undefined,
};

function renderPostInfo(postInfoProps, displayError, onAssetReady) {
  if (displayError) {
    const loadErrorMessage = 'Something went wrong, please reload the page.';

    return (
      <div className={styles.error}>
        { loadErrorMessage }
      </div>
    );
  }

  if (!postInfoProps) return null;

  return (
    <div className={styles.postInfo}>
      <PostInfo {...postInfoProps} onAssetReady={onAssetReady} />
    </div>
  );
}

function renderHeaderItem(label, value) {
  const content = label ? `${label}: ${value}` : value;
  return (<h4 key={value}>{ content }</h4>);
}

function renderHeaderInfo(check, url) {
  return (
    <div>
      { check.headersDisplay.map(({ label, value }) => renderHeaderItem(label, value)) }
      <div className={styles.checkLink}>
        <a className={styles.link} href={url} rel="noreferrer" target="_blank">Link</a>
        <CopyButton text={url} />
      </div>
    </div>
  );
}

function renderDetails(check) {
  return (
    check.detailsDisplay.map(({ label, value }) => (
      <div key={label}>
        <span className="t-overline">{ label }</span>
        <h4>{ value }</h4>
      </div>
    ))
  );
}

function renderIcon(passed) {
  switch (passed) {
    case true:
      return <Icon color="green" name="checkCircle" />;
    case false:
      return <Icon color="red" name="xCircle" />;
    default:
      return <Icon color="orange" name="questionCircle" />;
  }
}

function GuidelineCheckDisplay({
  check,
  editUrl,
  errorMessage,
  evaluating,
  isGuess,
  passed,
  setIsGuess,
  setLoading,
  setPassed,
}) {
  const [currentCheck, setCurrentCheck] = useState(check);
  const [assetId, setAssetId] = useState(null);
  const [postInfoProps, setPostInfoProps] = useState(null);
  const [displayError, setDisplayError] = useState(false);
  const [isBusy, setIsBusy] = useState(true);

  const {
    checkId,
    description,
    id,
    label,
  } = currentCheck;

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (check.id !== id) setCurrentCheck(check);
    // eslint-disable-next-line react/prop-types
    if (check.auditAssetId !== assetId) {
      // eslint-disable-next-line react/prop-types
      setAssetId(check.auditAssetId);
      setIsBusy(true);
    }
  }, [check]);

  useEffect(() => {
    const fetchProps = async () => {
      try {
        const response = await fetch(postInfoAdminReviewGuidelineCheckPath(checkId));
        const postInfo = await response.json();

        postInfo.checkId = checkId;
        setPostInfoProps(postInfo);
        setLoading(false);
      } catch (e) {
        setDisplayError(true);
        setLoading(true);
      }
    };

    fetchProps();
  }, [currentCheck]);

  const iconClasses = classNames(styles.iconButton, {
    [styles.guessIcon]: isGuess,
  });

  const handleClick = () => {
    if (isGuess) {
      setIsGuess(false);
    } else {
      setPassed(!passed);
    }
  };

  const onAssetReady = () => {
    setIsBusy(false);
  };

  return (
    <>
      { errorMessage && <h4 className={styles.error}>{ errorMessage }</h4> }
      <div>
        <div className={styles.content}>
          { renderPostInfo(postInfoProps, displayError, onAssetReady) }
          <div className={styles.data}>
            { renderHeaderInfo(currentCheck, editUrl) }
            <div className={styles.details}>
              { renderDetails(currentCheck) }
            </div>
            <div className={styles.passed}>
              <button
                className={iconClasses}
                disabled={evaluating || isBusy}
                type="button"
                onClick={handleClick}
              >
                { renderIcon(passed) }
              </button>
              <div>
                <div className={styles.label}>{ label }</div>
                <div className={styles.description}>
                  { description }
                  <div>
                    <CopyButton text={description} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          { isBusy && (
            <div className={styles.busy}>
              <div className={styles.spinner}>
                <Spinner />
              </div>
            </div>
          ) }
        </div>
      </div>
    </>
  );
}

GuidelineCheckDisplay.propTypes = propTypes;
GuidelineCheckDisplay.defaultProps = defaultProps;

export default GuidelineCheckDisplay;
