import React from "react"
import PropTypes from "prop-types"
import NavDropdown from 'components/navigation/NavDropdown'
import { ModalLaunchButton } from "components/containers/miscellaneous_containers"
import {
  Checkbox,
  Widget,
  WidgetContent,
  WidgetHeader
} from  "@duik/it"

# This component is responsible for dealing with primary/secondary dimensions on the segmentation report and has
# two features: allowing user to select a different type of dimension (ex. change from brand to channel) and
# filtering dimension values that are currently selected (ex. deselect Nike from the report).
class AuditSegmentationDimensionSelect extends React.Component
  @propTypes =
    dimensionLocation: PropTypes.string           # usd in handleDimensionValueChange call
    dimensionSelectProps: PropTypes.object        # NavDropdown props
    handleDimensionValueChange: PropTypes.func    # see handleDimensionValueChange in AuditSegmentationCard
    selected: PropTypes.array
    values: PropTypes.array

  constructor: (props) ->
    super(props)

    @state =
      id: _.uniqueId("segmentation-dimension-select")

  renderDimensionSelect: ->
    props = @props.dimensionSelectProps
    options = NavDropdown.sanitize(props.options)
    activeOption = _.findWhere(options, link: props.selected)

    dropdownProps =
      activeOption: activeOption
      options: options
      label: props.label
      buttonProps:
        className: "segmentationOptionSelector"

    `<NavDropdown {...dropdownProps} />`

  renderDimensionValueOption: (dimensionValue, selected) =>
    key = @state.id + "_filter_primary_dimension_" + dimensionValue
    callback = => @props.handleDimensionValueChange(@props.dimensionLocation, dimensionValue, !selected)

    `<Checkbox
      checked={selected}
      id={key}
      key={key}
      label={dimensionValue}
      onChange={callback}
      value={dimensionValue}
    />`

  renderDimensionValueSelect: =>
    selectedValues = @props.selected

    selected = []
    deselected = []

    for dimensionValue in @props.values
      if selectedValues.indexOf(dimensionValue) >= 0
        selected.push(@renderDimensionValueOption(dimensionValue, true))
      else
        deselected.push(@renderDimensionValueOption(dimensionValue, false))

    modalContent =
      `<div className='row'>
        <div className='col s6'>
          <Widget>
            <WidgetHeader>Selected</WidgetHeader>
            <WidgetContent>
              <div className='u-flexColumn'>
                {selected}
              </div>
            </WidgetContent>
          </Widget>
        </div>
        <div className='col s6'>
          <Widget>
            <WidgetHeader>Deselected</WidgetHeader>
            <WidgetContent>
              <div className='u-flexColumn'>
                {deselected}
              </div>
            </WidgetContent>
          </Widget>
        </div>
      </div>`

    modalProps = {
      contentProps: {
        'data-target': @state.id
        children: selectedValues.length + " Selected (Edit)"
        className: 'btn-sm btn-primary u-marginAboveSm js-modalBtn'
      }
      launchType: "click"
      modal: {
        children: modalContent
        id: @state.id
      }
    }

    `<ModalLaunchButton {...modalProps} />`

  render: ->
    `<div className={this.props.className}>
      {this.renderDimensionSelect()}
      {this.renderDimensionValueSelect()}
    </div> `

export default AuditSegmentationDimensionSelect
