import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@makeably/creativex-design-system';
import EvaluationPage, { formName } from 'components/admin/representation/EvaluationPage';
import { adminRepresentationPath } from 'utilities/routes';
import styles from './EvaluationModal.module.css';

const propTypes = {
  id: PropTypes.number.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  organization: PropTypes.string.isRequired,
  unreviewableReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAuditAssetSaved: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function EvaluationModal({
  id,
  modalOpen,
  organization,
  unreviewableReasons,
  onAuditAssetSaved,
  onClose,
}) {
  const [formIncomplete, setFormIncomplete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleSubmit = async () => {
    const formData = new FormData(document[formName]);

    try {
      setIsLoading(true);
      setShowError(false);
      const response = await fetch(adminRepresentationPath(id), {
        method: 'PATCH',
        body: formData,
      });

      if (response.ok) {
        const success = await response.json();

        if (success) {
          onAuditAssetSaved(id);
        } else {
          setShowError(true);
        }
      } else {
        setShowError(true);
      }
    } catch (error) {
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      actionButtonDisabled={isLoading || formIncomplete}
      actionButtonLabel="Evaluate"
      closeButtonLabel="Close"
      isOpen={modalOpen}
      size="full"
      onActionButtonClick={handleSubmit}
      onClose={onClose}
    >
      <div className={styles.content}>
        { showError && <h4 className={styles.error}>Evaluation Error: Please Try Again</h4> }
        <EvaluationPage
          id={id}
          organization={organization}
          setFormIncomplete={setFormIncomplete}
          setLoading={setIsLoading}
          showError={() => setShowError(true)}
          unreviewableReasons={unreviewableReasons}
        />
      </div>
    </Modal>
  );
}

EvaluationModal.propTypes = propTypes;
export default EvaluationModal;
