import React, { Component } from 'react';
import { Icon } from '@makeably/creativex-design-system';

const Deprecated = (InnerComponent, msg = '', preventRender = false) => class extends Component {
  componentDidMount() {
    if (process.env.NODE_ENV === 'development') {
      /* eslint-disable-next-line no-console */
      console.warn(`${InnerComponent.name} is deprecated.`, msg);
    }
  }

  render() {
    if (process.env.NODE_ENV === 'development' && preventRender) {
      return (
        <div className="deprecatedResource">
          <Icon color="white" name="exclamationTriangle" />
          <div className="u-marginLeft">
            <div>{ `${InnerComponent.name} has been deprecated.` }</div>
            <div>{ msg }</div>
          </div>
        </div>
      );
    }

    /* eslint-disable-next-line react/jsx-props-no-spreading */
    return <InnerComponent {...this.props} />;
  }
};

export default Deprecated;
