class UserInfo {
  static jobTitleOptions() {
    const jobTitleOptions = [
      'Manager',
      'Senior Manager',
      'Director/Head Of',
      'VP/SVP/GM',
      'C-Suite',
      'Other',
    ];

    return jobTitleOptions;
  }

  static jobFunctionOptions() {
    const jobFunctions = [
      'Brand',
      'Creative/Content',
      'Ecommerce',
      'Innovation/IT/Tech',
      'Insights/Data/Analytics',
      'Media/Paid/Performance',
      'Social',
      'General/Digital',
      'Other/Procurement',
    ];

    return jobFunctions;
  }

  static profileDescription(profile) {
    const descriptions = {
      limited: 'Test creatives before they go live and invite other users with limited access',
      connecting: 'Manage account connections and invite other users with connector permissions',
      standard: 'Access both in-flight and pre-flight data and analytics, manage account connections, and invite other standard users',
      admin: 'Full access to in-flight and pre-flight data and analytics, plus rights over account connections, invitations, permission settings, and rule weights, and more',
      picasso_restricted_admin: 'CreativeX internal restricted admin, typically used for MARs',
    };

    return descriptions[profile];
  }

  static profileList({ includeRestricted, profile }) {
    let profiles = [];
    const isAdmin = ['picasso_admin', 'admin'].includes(profile);

    // CX Users are the only ones with this set
    if (profile === 'picasso_admin' && includeRestricted) {
      profiles = [
        {
          value: 'picasso_admin',
          display: 'CreativeX Admin',
          icon: 'admin',
        },
        {
          value: 'picasso_restricted_admin',
          display: 'Restricted Admin',
        },
      ];
    } else {
      profiles = [{ value: 'limited' }];

      if (profile !== 'limited') {
        profiles.push({ value: 'connecting' });
      }

      if (profile === 'standard' || isAdmin) {
        profiles.push({ value: 'standard' });
      }

      if (isAdmin) {
        profiles.push({ value: 'admin' });
      }
    }

    return profiles;
  }
}

export default UserInfo;
