import React from 'react';
import PropTypes from 'prop-types';
import Step, { propTypes as stepProps } from 'components/atoms/Step';
import StepSeparator from 'components/atoms/StepSeparator';

export const propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: stepProps.label,
      step: stepProps.step,
    }),
  ).isRequired,
};

function Stepper({ currentStep, steps }) {
  return (
    <div className="stepper u-flexRow u-flexAlignCenter">
      {
        steps.map(({ step, label }) => (
          <React.Fragment key={step}>
            <StepSeparator active={step <= currentStep} />
            <Step
              currentStep={currentStep}
              label={label}
              step={step}
            />
          </React.Fragment>
        ))
      }
    </div>
  );
}

Stepper.propTypes = propTypes;

export default Stepper;
