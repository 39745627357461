import React from 'react';
import PropTypes from 'prop-types';
import {
  ContainerVertical,
  TextArea,
  Widget,
  WidgetContent,
  WidgetContainer,
  WidgetHeader,
} from '@duik/it';
import { Icon } from '@makeably/creativex-design-system';
import { hasContent } from 'utilities/string';

const assetPropType = {
  altText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  filename: PropTypes.string,
  title: PropTypes.string,
};

const propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape(
      assetPropType,
    ),
  ).isRequired,
  onTextChange: PropTypes.func.isRequired,
};

function renderMissingAltText() {
  return (
    <div className="u-flexRow u-textAlignRight u-flexAlignCenter u-justifyFlexEnd altText-status--missing">
      <Icon color="red" name="exclamationCircle" />
      Please add alt text
    </div>
  );
}

function renderPresentAltText() {
  return (
    <div className="u-flexRow u-textAlignRight u-flexAlignCenter altText-status--present">
      <Icon color="green" name="checkCircle" />
      <div>Alt text added</div>
    </div>
  );
}

function renderAssetAltText(asset, onTextChange) {
  // Alt text consisted of only whitespace will be reduced later on to an empty
  // string, so don't count it now
  const altTextPresent = hasContent(asset.altText);
  return (
    <div key={asset.id} className="u-flexColumn">
      <div className="u-flexRow u-justifySpaceBetween">
        <div className="altText-assetFilename">{ asset.filename || asset.title }</div>
        { altTextPresent && renderPresentAltText() }
        { !altTextPresent && renderMissingAltText() }
      </div>
      <div className="u-flexRow">
        <div className="altText-assetPreview preflightFormAsset-image">
          <img alt="Preflight Upload Preview" src={asset.preview} />
        </div>
        <TextArea
          placeholder="Write the description of the creative here"
          rows={6}
          onChange={(event) => {
            onTextChange(asset, event.target.value);
          }}
        />
      </div>
      <div className="divider-horizontal u-marginBelow u-marginAbove" />
    </div>
  );
}

function AltTextPanel(props) {
  const {
    assets,
    onTextChange,
  } = props;

  const assetCount = assets.length;
  const assetsPresent = assetCount >= 1;
  const assetsWithText = assets.filter(({ altText }) => hasContent(altText)).length;
  const textAddedCount = `${assetsWithText}/${assetCount}`;

  return (
    <WidgetContainer className="altText-panel">
      <Widget className="altText-card">
        <WidgetHeader className="cardHeader">
          <ContainerVertical>
            <div className="u-flexRow u-justifySpaceBetween cardHeader-heading">
              <h5>Add Alt text to each creative</h5>
              <h5>{ assetsPresent && textAddedCount }</h5>
            </div>
            <div className="cardHeader-subheading">
              Please add a description to each creative to ensure that it is
              accessible for everyone.
            </div>
          </ContainerVertical>
        </WidgetHeader>
        <WidgetContent className="altText-content">
          { !assetsPresent && (
            <div className="altText-noAssetMessage">
              <div>Drop your creatives into the workspace to add the alt text</div>
            </div>
          ) }
          { assets.map((asset) => (renderAssetAltText(asset, onTextChange, asset.altText))) }
        </WidgetContent>
      </Widget>
    </WidgetContainer>
  );
}

AltTextPanel.propTypes = propTypes;

export default AltTextPanel;
