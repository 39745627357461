import React from "react"
import Modal from "components/reusable/modal"
import PropTypes from "prop-types"

# Wraps a ContentComponent in a div that also contains a modal for use with the content
# See CategoryCardsWithSecondaryModal for example
# or look into the react modal examples
ModalContainer = (ContentComponent) -> class extends React.Component
  @propTypes =
    contentProps: PropTypes.object
    idx: PropTypes.number
    # Click means the ContentComponent is clickable, callback means launched via callback
    launchType: PropTypes.oneOf(['click', 'callback', 'addNew'])
    # Either content, or contentLocation is required for the modal
    modal: PropTypes.shape(
      # This is an object that is converted by the modal into content, see Modal for details
      content: PropTypes.object
      contentLocation: PropTypes.string
      enableModalNavigation: PropTypes.bool
      id: PropTypes.string.isRequired
      loadOnOpen: PropTypes.bool # If true, the request for data won't be sent until the modal is launched
      onOpen: PropTypes.func
    )
    modalNavProps: PropTypes.shape( # These props are used by the Modal for navigation purposes, see Modal
      getModalContentLocationAtIdx: PropTypes.func
      lastIdx: PropTypes.number
      newContentLocation: PropTypes.string
      newIdx: PropTypes.number
    )

  @defaultProps =
    launchType: 'click'

  constructor: (props) ->
    super(props)

    @state =
      modalContentLocation: @props.modal.contentLocation
      modalContentParams: {}

  # NB: params is an object of params for unique content based on object clicked to launch modal
  openModal: (e, params) =>
    e.preventDefault()
    contentLocation = @props.modal.contentLocation
    @setState(modalContentLocation: contentLocation, modalContentParams: params)
    # Open modal
    $("##{@props.modal.id}").openModal()

  render: ->
    if @props.launchType is 'callback'
      callbackModalProps = {
        modalCallback: @openModal
      }
    if @props.launchType is 'addNew'
      addNewProps = {
        onClick: (e) => @openModal(e, this.state.modalContentParams)
      }

    `<span>
      <Modal
        {..._.omit(this.props.modal, 'contentLocation')}
        {...this.props.modalNavProps}
        contentLocation={this.state.modalContentLocation}
        contentParams={this.state.modalContentParams}
        idx={this.props.idx}
      />
      <ContentComponent
        {...this.props.className}
        {...this.props.contentProps}
        {...callbackModalProps}
        {...addNewProps}
      />
    </span>`

export default ModalContainer
