import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Dropdown,
  Icon,
} from '@makeably/creativex-design-system';
import DuplicateGuideline from 'components/admin/guidelines/DuplicateGuideline';
import StandardScore from 'components/admin/guidelines/StandardScore';
import ItemsTable from 'components/molecules/ItemsTable';
import { getItemSortBy } from 'utilities/item';
import { generateItemElement } from 'utilities/itemElement';
import { filterItems } from 'utilities/itemFilter';
import {
  adminCompanyGuidelinesPath,
  adminGuidelineGuidelineDetailsPath,
  adminGuidelinePath,
  editAdminGuidelinePath,
  editImageAdminGuidelinePath,
  newAdminCompanyGuidelinePath,
} from 'utilities/routes';
import {
  toTimeAgoInWords,
  toCount,
} from 'utilities/string';
import {
  getParams,
  getParamsObj,
  updateParams,
} from 'utilities/url';
import styles from './Index.module.css';

const propTypes = {
  companyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  filterOptions: PropTypes.shape({
    brands: PropTypes.arrayOf(PropTypes.string),
    channels: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    markets: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  // Disabling as this lookup is an id to count lookup, so is dynamic
  // eslint-disable-next-line react/forbid-prop-types
  guidelineCheckCounts: PropTypes.object.isRequired,
  guidelines: PropTypes.arrayOf(
    PropTypes.shape({
      activable: PropTypes.bool,
      applicability: PropTypes.string,
      branded: PropTypes.bool,
      definitionDetails: PropTypes.shape({
        brandValues: PropTypes.arrayOf(PropTypes.string),
        channelValues: PropTypes.arrayOf(PropTypes.string),
        details: PropTypes.arrayOf(PropTypes.string),
        guidelineId: PropTypes.number,
        marketValues: PropTypes.arrayOf(PropTypes.string),
      }),
      displayUrl: PropTypes.string,
      evaluationOrder: PropTypes.number,
      global: PropTypes.bool,
      id: PropTypes.number,
      launchDate: PropTypes.string,
      name: PropTypes.string,
      organizationId: PropTypes.number,
      organizationOrder: PropTypes.number,
      paid: PropTypes.bool,
      ruleType: PropTypes.string,
      samplingRate: PropTypes.number,
      standard: PropTypes.bool,
      state: PropTypes.string,
      trialable: PropTypes.bool,
      type: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  ).isRequired,
  newGuidelineOptions: PropTypes.shape({
    assetLevel: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    postLevel: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number,
    isTemplate: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  standardScore: PropTypes.shape({
    canMange: PropTypes.bool,
    details: PropTypes.shape({
      id: PropTypes.number,
      isProcessing: PropTypes.bool,
    }),
    supported: PropTypes.bool,
  }).isRequired,
  status: PropTypes.string.isRequired,
};

const IGNORE_VALUE = 'All';

const headers = [
  {
    key: 'position',
    label: 'Position\n(Eval Pos)',
  },
  {
    key: 'id',
    label: 'Name (ID)',
  },
  {
    key: 'standard',
    label: 'Standard CQ',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'applicability',
    label: 'Applicability',
  },
  {
    key: 'global',
    label: 'Global/Local',
  },
  {
    key: 'branded',
    label: 'Branded',
  },
  {
    key: 'paid',
    label: 'Included in Usage Reporting',
  },
  {
    key: 'samplingRate',
    label: 'Sampling Rate',
  },
  {
    key: 'checkCount',
    label: 'Checks',
  },
  {
    key: 'launchDate',
    label: 'Date to Launch',
  },
  {
    key: 'lastUpdate',
    label: 'Last Update',
  },
  {
    key: 'lastDefinitionUpdate',
    label: 'Last Definition Update\n(Definition ID)',
  },
  {
    key: 'definitionCounts',
    label: 'Definition Counts\nActive/Inactive',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];

const applicabilityFilter = [
  {
    label: 'In-Flight',
    value: 'audit',
  },
  {
    label: 'Pre-Flight',
    value: 'pretest',
  },
];
const defaultRuleType = {
  label: 'Guideline',
  value: 'guideline',
  default: true,
};
const ruleTypeFilter = [
  defaultRuleType,
  {
    label: 'Category',
    value: 'category',
  },
  {
    label: 'Regulatory',
    value: 'regulatory',
  },
];
const statusFilter = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Retired',
    value: 'retired',
  },
  {
    label: 'Editing',
    value: 'editing',
  },
  {
    label: 'Trial',
    value: 'trial',
  },
];

const filterDimensions = [
  {
    key: 'applicability',
    label: 'Applicability',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
];
const filterKeys = filterDimensions.map(({ key }) => key);

function updateFilterParams(params, keys, selections) {
  keys.map((key) => {
    const value = selections[key]?.value;

    if (value) return params.set(key, value);

    return params.delete(key);
  });
}

function getHeaders(showStandardGuideline, status) {
  return headers.filter(({ key }) => {
    if (key === 'standard') {
      return showStandardGuideline;
    }

    if (key === 'checkCount') return status !== 'editing';
    if (key === 'launchDate') return status === 'editing';

    return true;
  });
}

function getId({ id, name }) {
  const text = `${name} (${id})`;
  const display = {
    url: editAdminGuidelinePath(id),
  };

  return {
    value: text,
    element: generateItemElement(text, display),
  };
}

function getIcon(conditional) {
  const [color, icon] = conditional ? ['purple', 'checkCircle'] : ['grey', 'xCircle'];

  return {
    element: <Icon color={color} name={icon} />,
    value: conditional ? 'yes' : 'no',
  };
}

function getActions({
  displayUrl,
  definitionDetails: { definitionCount },
  id,
  name,
  organizationId,
  ruleType,
  state,
}, countLookup, isTemplate, companyOptions) {
  const actions = [];
  const checkCount = countLookup[id] || 0;

  const isEditing = state === 'editing';
  const activable = isEditing && definitionCount > 0;
  if (activable) {
    actions.push({
      confirmationMessage: `Are you sure you want to activate ${name}? This action can't be undone.`,
      label: 'Activate',
      method: 'patch',
      url: adminGuidelinePath(id, { activate: true }),
    });
  }

  const trialable = isEditing && definitionCount > 0;
  if (trialable) {
    actions.push({
      confirmationMessage: `Are you sure you want to trial ${name}? This action can't be undone.`,
      label: 'Trial',
      method: 'patch',
      url: adminGuidelinePath(id, { trial: true }),
    });
  }

  actions.push({
    label: 'Duplicate',
  });

  if (ruleType === 'category') {
    actions.push({
      label: displayUrl ? 'Modify Display Image' : 'Add Display Image',
      url: editImageAdminGuidelinePath(id),
    });
  }

  if (checkCount === 0) {
    actions.push({
      confirmationMessage: `Are you sure you want to delete ${name}?`,
      label: 'Delete',
      method: 'delete',
      url: adminGuidelinePath(id, { company_id: organizationId }),
    });
  }

  const element = (
    <span>
      { actions.map(({
        confirmationMessage, label, onClick, method, url,
      }) => (
        <span key={label} className="u-marginRightSm">
          { label === 'Duplicate' && (
            <DuplicateGuideline
              companies={companyOptions}
              id={id}
              isTemplate={isTemplate}
              name={name}
            />
          ) }
          { label !== 'Duplicate' && (
          <a
            data-confirm={confirmationMessage}
            data-method={method}
            href={url}
            onClick={onClick}
          >
            { label }
          </a>
          ) }
        </span>
      )) }
    </span>
  );

  return {
    element,
    value: actions[0]?.label || 'N/A',
  };
}

function getDefinitionCounts({ id, definitionDetails: { details } }) {
  const statusCounts = (details || []).reduce((res, detail) => {
    const [_id, status, _updatedAt] = detail.split('::');
    res[status] += 1;
    return res;
  }, {
    active: 0,
    inactive: 0,
  });

  const { active, inactive } = statusCounts;
  const text = `${active}/${inactive}`;

  const display = {
    url: adminGuidelineGuidelineDetailsPath(id),
  };

  return {
    value: text,
    element: generateItemElement(text, display),
  };
}

function getDefinitionUpdate(details) {
  if (details) {
    const parsedDetails = details.map((detail) => {
      const [id, _status, updatedAt] = detail.split('::');

      return {
        id,
        updatedAt,
        time: Date.parse(updatedAt),
      };
    });

    const newestUpdate = parsedDetails.reduce((res, d) => (d.time > res.time ? d : res));

    return {
      text: `${toTimeAgoInWords(newestUpdate.updatedAt)} (${newestUpdate.id})`,
      value: newestUpdate.time,
    };
  }

  return { value: 'N/A' };
}

function getApplicability(applicability) {
  const opt = applicabilityFilter.find(({ value }) => value === applicability) || {
    label: 'All',
    value: 'all',
  };

  return {
    ...opt,
    ignoreFilter: applicability === 'all',
  };
}

function getOptionalFilterItem(key, values) {
  if (values) {
    const filteredValues = values.filter((v) => v !== IGNORE_VALUE);

    return {
      ignoreFilter: filteredValues.length === 0,
      options: filteredValues.map((v) => ({
        value: v,
        label: v,
      })),
      value: key,
    };
  }

  return {
    options: [],
    value: key,
  };
}

function getItem(
  guideline,
  countLookup,
  isTemplate,
  companyOptions,
) {
  return {
    actions: getActions(guideline, countLookup, isTemplate, companyOptions),
    applicability: getApplicability(guideline.applicability),
    branded: getIcon(guideline.branded),
    brand: getOptionalFilterItem('brand', guideline.definitionDetails.brandValues),
    channel: getOptionalFilterItem('channel', guideline.definitionDetails.channelValues),
    checkCount: {
      text: toCount(countLookup[guideline.id] || 0),
      value: countLookup[guideline.id] || 0,
    },
    definitionCounts: getDefinitionCounts(guideline),
    global: { value: guideline.global ? 'Global' : 'Local' },
    lastDefinitionUpdate: getDefinitionUpdate(guideline.definitionDetails.details),
    lastUpdate: {
      text: toTimeAgoInWords(guideline.updatedAt),
      value: guideline.updatedAt,
    },
    launchDate: { value: guideline.launchDate || '' },
    market: getOptionalFilterItem('market', guideline.definitionDetails.marketValues),
    id: getId(guideline),
    paid: getIcon(guideline.paid),
    position: {
      text: `${guideline.organizationOrder || 1} (${guideline.evaluationOrder || 1})`,
      value: guideline.organizationOrder || 1,
    },
    ruleType: { value: guideline.ruleType },
    samplingRate: {
      text: `${guideline.samplingRate || 0}%`,
      value: guideline.samplingRate || 0,
    },
    standard: getIcon(guideline.standard),
    type: { value: guideline.type },
  };
}

function getItems(guidelines, countLookup, isTemplate, companyOptions) {
  return guidelines.map((guideline) => (
    getItem(guideline, countLookup, isTemplate, companyOptions)
  ));
}

function Index({
  companyOptions,
  filterOptions: {
    brands,
    channels,
    markets,
  },
  guidelineCheckCounts,
  guidelines,
  newGuidelineOptions,
  organization,
  standardScore,
  status,
}) {
  const params = getParams(window);
  const [sort, setSort] = useState({
    key: 'organizationOrder',
    asc: true,
  });

  const filterOptions = {
    applicability: applicabilityFilter,
    brand: brands.map((b) => ({
      value: b,
      label: b,
    })),
    channel: channels.map(({ label, key: value }) => ({
      label,
      value,
    })),
    market: markets.map((m) => ({
      value: m,
      label: m,
    })),
  };

  const filterValuesFromParams = getParamsObj(params, filterKeys);
  const filtersFromParams = filterKeys
    .reduce((res, k) => {
      if (!filterValuesFromParams[k]) return res;

      return {
        ...res,
        [k]: filterOptions[k].find(({ value }) => value === filterValuesFromParams[k]),
      };
    }, {});

  const initialFilterSelections = {
    ...filtersFromParams,
    ruleType: defaultRuleType,
  };
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(items);
  const [sortedItems, setSortedItems] = useState(items);

  const updateFilterSelections = (selections) => {
    setFilterSelections(selections);
    updateFilterParams(params, filterKeys, selections);
    updateParams(params, window);
  };

  useEffect(() => {
    const allItems = getItems(
      guidelines,
      guidelineCheckCounts,
      organization.isTemplate,
      companyOptions,
    );
    setItems(allItems);
  }, [guidelines]);

  useEffect(() => {
    setFilteredItems(filterItems(items, filterSelections));
  }, [items, filterSelections]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(filteredItems.slice().sort(byKeyDir));
    }
  }, [filteredItems, sort]);

  const handleFilterSelect = (key, selected) => {
    updateFilterSelections({
      ...filterSelections,
      [key]: selected,
    });
  };

  const onStatusChange = (opt) => {
    window.location = adminCompanyGuidelinesPath(organization.id, { status: opt.value });
  };

  const isGuidelineView = filterSelections.ruleType.value === 'guideline';
  const standardScoreSupportedForView = isGuidelineView && standardScore.supported;

  const emptyTableContent = (
    <div className={`t-empty ${styles.empty}`}>
      There are no rules that match all filters
    </div>
  );

  const newRuleOptions = [
    {
      label: 'Asset Guidelines',
      group: true,
    },
    ...newGuidelineOptions.assetLevel,
    {
      label: 'Post Guidelines',
      group: true,
    },
    ...newGuidelineOptions.postLevel,
  ];

  const onAddNewRule = (rule) => {
    window.location = newAdminCompanyGuidelinePath(
      organization.id,
      { guideline: { type: rule.value } },
    );
  };

  return (
    <>
      <Card>
        <div className={styles.header}>
          <div className="u-flexColumn">
            <div className={styles.filters}>
              <Dropdown
                label="Status"
                options={statusFilter}
                selected={statusFilter.find(({ value }) => value === status)}
                onChange={onStatusChange}
              />
              <Dropdown
                label="Rule Type"
                options={ruleTypeFilter}
                selected={filterSelections.ruleType}
                onChange={(opt) => handleFilterSelect('ruleType', opt)}
              />
            </div>
            <div className={styles.filters}>
              { filterDimensions.map(({ key, label }) => (
                <Dropdown
                  key={key}
                  label={label}
                  menuProps={{ size: ['brand', 'market', 'channel'].includes(key) ? 'large' : 'small' }}
                  options={filterOptions[key] ?? []}
                  selected={filterSelections[key]}
                  size="small"
                  onChange={(opt) => handleFilterSelect(key, opt)}
                />
              )) }
              <Button
                label="Clear Filters"
                variant="tertiary"
                onClick={() => updateFilterSelections({ ruleType: filterSelections.ruleType })}
              />
            </div>
          </div>
          <div className={styles.actions}>
            <StandardScore
              {...standardScore}
              company={organization}
              supportedForView={standardScoreSupportedForView}
            />
            <Dropdown
              label="Add New Rule"
              menuProps={{ size: 'large' }}
              options={newRuleOptions}
              size="medium"
              onChange={onAddNewRule}
            />
          </div>
        </div>
        <ItemsTable
          className={styles.table}
          emptyTableContent={emptyTableContent}
          headers={getHeaders(standardScoreSupportedForView, status)}
          items={sortedItems}
          perPage={25}
          sort={sort}
          onSortChange={(value) => setSort(value)}
        />
      </Card>
    </>
  );
}

Index.propTypes = propTypes;

export default Index;
