import React, { Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'components/molecules/Tabs.module.css';

const tabProps = {
  key: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
};
const propTypes = {
  selected: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape(tabProps)).isRequired,
  onSelectedChange: PropTypes.func.isRequired,
};

function renderTab(tab, index, selected, onSelectedChange) {
  const tabClasses = classnames(styles['tabs-tab'], {
    [styles['tabs-tab--selected']]: tab.key === selected,
  });

  return (
    <Fragment key={tab.key}>
      { index !== 0 && (
        <div className={styles['tabs-line']} />
      ) }
      <button
        key={tab.key}
        className={tabClasses}
        type="button"
        onClick={() => onSelectedChange(tab.key)}
      >
        <span>{ tab.label }</span>
      </button>
    </Fragment>
  );
}

function Tabs({
  tabs, selected, onSelectedChange,
}) {
  return (
    <div className={styles.tabs}>
      { tabs.map((tab, index) => renderTab(tab, index, selected, onSelectedChange)) }
    </div>
  );
}

Tabs.propTypes = propTypes;

export default Tabs;
