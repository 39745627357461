import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  FormGroup,
  Select,
  TextField,
  TopBar,
  TopBarSection,
} from '@duik/it';
import { Icon } from '@makeably/creativex-design-system';
import NavDropdown from 'components/navigation/NavDropdown';

export const adminPropTypes = {
  navOptions: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string,
          text: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  searchPath: PropTypes.string.isRequired,
  showSearch: PropTypes.bool.isRequired,
  internalPath: PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

const defaultProps = {
  internalPath: undefined,
};

const searchOptions = [
  {
    label: 'Audit Asset',
    value: 'audit_asset_id',
  },
  {
    label: 'Platform Ad',
    value: 'platform_ad_id',
  },
];

function SearchTextField({ activeOption: { label, value } }) {
  let textField;
  if (value === 'audit_asset_id') {
    textField = (
      <TextField
        min="1"
        name={value}
        placeholder={`${label} ID`}
        step="1"
        type="number"
      />
    );
  } else {
    textField = (
      <TextField
        name={value}
        placeholder={`${label} ID`}
      />
    );
  }
  return textField;
}
SearchTextField.propTypes = {
  activeOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

function AuditPostSearch({
  activeOption, onOptionClick, searchPath,
}) {
  return (
    <TopBarSection>
      <form action={searchPath} method="get">
        <ButtonGroup className="adminSearch">
          <FormGroup>
            <SearchTextField activeOption={activeOption} />
          </FormGroup>
          <Select
            activeOption={activeOption}
            options={searchOptions}
            onOptionClick={(option) => onOptionClick(option)}
          />
          <Button type="submit" square>
            <Icon name="search" />
          </Button>
        </ButtonGroup>
      </form>
    </TopBarSection>
  );
}
AuditPostSearch.propTypes = {
  ...SearchTextField.propTypes,
  searchPath: PropTypes.string.isRequired,
  onOptionClick: PropTypes.func.isRequired,
};

class AdminNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOption: searchOptions[0],
    };
  }

  handleSearchOption(option) {
    this.setState({ activeOption: option });
  }

  render() {
    const {
      activeOption,
    } = this.state;
    const {
      internalPath,
      navOptions,
      searchPath,
      showSearch,
    } = this.props;

    let auditPostSearch;
    if (showSearch) {
      auditPostSearch = (
        <AuditPostSearch
          activeOption={activeOption}
          searchPath={searchPath}
          onOptionClick={(option) => this.handleSearchOption(option)}
        />
      );
    }

    return (
      <TopBar>
        <TopBarSection>
          {
            navOptions.map(({ buttonText, options }) => {
              const buttonProps = { clear: true };
              return (
                <NavDropdown
                  key={buttonText}
                  buttonProps={buttonProps}
                  buttonText={buttonText}
                  options={options}
                />
              );
            })
          }
          { internalPath && (
            <a href={internalPath.link}>{ internalPath.text }</a>
          ) }
        </TopBarSection>
        { auditPostSearch }
      </TopBar>
    );
  }
}

AdminNavigation.propTypes = adminPropTypes;
AdminNavigation.defaultProps = defaultProps;

export default AdminNavigation;
