import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@duik/it';
import StringHelper from 'components/utils/StringHelper';

const displayValue = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  format: PropTypes.string,
  formatOpts: PropTypes.object,
};

const propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape(displayValue)).isRequired,
  chartType: PropTypes.string,
};

const defaultProps = {
  chartType: 'percentage',
};

function renderDisplayValue(chartValue, chartType) {
  const {
    label,
    value,
  } = chartValue;

  const content = StringHelper.format(value, chartType);

  return (
    <div className="u-flexColumn u-alignEnd">
      <h4>{ content }</h4>
      <div className="u-label">{ label }</div>
    </div>
  );
}

function DoughnutOverview({ chartType, values }) {
  const topValue = values[0];
  const otherValues = values.slice(1);

  return (
    <div className="u-flexColumn">
      { renderDisplayValue(topValue, chartType) }
      <Divider margin />
      <div className="u-flexColumn u-gap-24">
        {
          otherValues.map((value) => (
            <Fragment key={value.label}>
              { renderDisplayValue(value, chartType) }
            </Fragment>
          ))
        }
      </div>
    </div>
  );
}

DoughnutOverview.propTypes = propTypes;
DoughnutOverview.defaultProps = defaultProps;

export default DoughnutOverview;
