import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const creativeDetailPropTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  className: PropTypes.string,
  delimiter: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  delimiter: ':',
};

function CreativeDetail(props) {
  const {
    label,
    value,
    className,
    delimiter,
  } = props;

  const detailClassNames = classNames(
    'u-flexRow',
    'u-spaceBetween',
    'u-flexGrow',
    className,
  );
  return (
    <div className={detailClassNames}>
      <span className="u-flexRow">
        { label }
        { delimiter }
      </span>
      <span className="u-flexRow">
        { value }
      </span>
    </div>
  );
}

CreativeDetail.propTypes = creativeDetailPropTypes;
CreativeDetail.defaultProps = defaultProps;

export default CreativeDetail;
