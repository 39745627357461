// semi-transparent vertical and horizontal crosshair lines
// the intersect position follows the cursor (used on AnnotationCanvas)
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'components/admin/annotation/Crosshair.module.css';

export const propTypes = {
  fillColor: PropTypes.string.isRequired,
  imageProps: PropTypes.shape({
    height: PropTypes.number,
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
    width: PropTypes.number,
  }).isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

function Crosshair({
  imageProps,
  fillColor,
  x,
  y,
}) {
  const floorX = Math.max(0, x);
  const floorY = Math.max(0, y);

  const verticalStyle = {
    left: Math.min(imageProps.width + imageProps.offsetX, floorX),
    height: imageProps.height,
  };

  const horizontalStyle = {
    top: Math.min(imageProps.height + imageProps.offsetY, floorY),
    width: imageProps.width,
  };

  const verticalClasses = classNames(styles.verticalLine, styles[fillColor]);
  const horizontalClasses = classNames(styles.horizontalLine, styles[fillColor]);

  return (
    <div>
      <div className={verticalClasses} style={verticalStyle} />
      <div className={horizontalClasses} style={horizontalStyle} />
    </div>
  );
}

Crosshair.propTypes = propTypes;

export default Crosshair;
