import { byObjectValue } from 'utilities/sort';
import { titleize } from 'utilities/string';

export function getAssetCount(assets) {
  const uniqueCoreAssets = new Set(
    assets.map(({ uuid }) => uuid).filter((uuid) => !!uuid),
  );

  return uniqueCoreAssets.size;
}

function getSegmentOptions(assets, segment) {
  const keyOptionsMap = assets.reduce((map, asset) => {
    const value = asset[segment];
    if (value) {
      map.set(value, {
        label: ['uuid', 'fileName'].includes(segment) ? value : titleize(value),
        value,
      });
    }
    return map;
  }, new Map());

  return [...keyOptionsMap.values()].sort(byObjectValue);
}

export function getFilterOptions(assets, segments) {
  const filterOptions = segments.reduce((options, { value: segment }) => {
    const segmentOptions = getSegmentOptions(assets, segment);
    return {
      ...options,
      [segment]: segmentOptions,
    };
  }, {});

  return filterOptions;
}

export function getDateOptions(elements) {
  const uniqueYears = new Set(elements.map(({ year }) => year));
  const dateOptions = [...uniqueYears].sort().reverse().map((year) => ({
    label: year.toString(),
    value: year,
  }));
  return dateOptions;
}
