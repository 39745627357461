import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@makeably/creativex-design-system';
import StringHelper from 'components/utils/StringHelper';
import { getDaysDifference } from 'utilities/date';
import { itemProps } from 'utilities/item';
import {
  toCount,
  toPercent,
} from 'utilities/string';
import styles from './DataPointVisualization.module.css';
import {
  formatDate,
  renderMonthlyBreakdown,
  renderStat,
} from './utilities';

const propTypes = {
  dataPointsCost: PropTypes.number.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(itemProps).isRequired,
  startDate: PropTypes.string.isRequired,
  dataPointsCap: PropTypes.number,
  endDate: PropTypes.string,
};

const defaultProps = {
  dataPointsCap: undefined,
  endDate: undefined,
};

const usageTooltip = 'Data Points associated with ads that have been reviewed by CreativeX, within the contract start/end date';
const annualUsageTooltip = 'Average Data Point daily usage within the contract start/end date, multiplied by 365';

function calculateAnnualUsage(count, startDate, contractEndDate) {
  let endDate;

  if (contractEndDate && StringHelper.stringToDate(contractEndDate) < new Date()) {
    endDate = StringHelper.stringToDate(contractEndDate);
  } else {
    endDate = new Date();
  }

  const daysElapsed = getDaysDifference(StringHelper.stringToDate(startDate), endDate);

  return toCount(Math.round((count / daysElapsed) * 365));
}

function DataPointVisualization({
  dataPointsCap,
  dataPointsCost,
  endDate,
  hasFilters,
  items,
  startDate,
}) {
  const capText = toCount(dataPointsCap);
  const costText = toCount(dataPointsCost);
  const usageText = (!hasFilters && dataPointsCap) ? `${costText} / ${capText}` : `${costText}`;
  const percentUsed = toPercent(dataPointsCost / dataPointsCap);
  const annualUsage = calculateAnnualUsage(dataPointsCost, startDate, endDate);

  return (
    <>
      <div className={styles.usageStats}>
        { renderStat('Data Point Usage', usageText, usageTooltip) }
        { !hasFilters && Boolean(dataPointsCap) && renderStat('Percent Used', percentUsed) }
        { renderStat('Annualized Data Point Usage', annualUsage, annualUsageTooltip) }
        { renderStat('Contract Start Date', formatDate(startDate)) }
        { renderStat('Contract End Date', formatDate(endDate)) }
      </div>
      <Divider />
      { renderMonthlyBreakdown(items, 'totalCost') }
    </>
  );
}

DataPointVisualization.propTypes = propTypes;
DataPointVisualization.defaultProps = defaultProps;

export default DataPointVisualization;
