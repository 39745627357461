import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  MessageModal,
} from '@makeably/creativex-design-system';
import { findObjectByValue } from 'utilities/array';
import styles from './EditCampaignModal.module.css';

export const campaignProps = PropTypes.shape({
  accountId: PropTypes.string,
  brandCode: PropTypes.string,
  brandDisabled: PropTypes.bool,
  brandId: PropTypes.number,
  channel: PropTypes.string,
  channelLabel: PropTypes.string,
  id: PropTypes.number,
  market: PropTypes.string,
  marketCode: PropTypes.string,
  marketDisabled: PropTypes.bool,
  name: PropTypes.string,
});

export const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const propTypes = {
  brandOptions: PropTypes.arrayOf(optionProps).isRequired,
  campaign: campaignProps.isRequired,
  isOpen: PropTypes.bool.isRequired,
  marketOptions: PropTypes.arrayOf(optionProps).isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateCampaign: PropTypes.func.isRequired,
};

function renderField(name, value) {
  return (
    <div className="u-flexColumn u-gap-8">
      <div className="t-subtitle">{ `${name}:` }</div>
      <div className="t-body-1">{ value || 'N/A' }</div>
    </div>
  );
}

function EditCampaignModal({
  brandOptions,
  campaign,
  isOpen,
  marketOptions,
  onClose,
  onUpdateCampaign,
}) {
  const [brand, setBrand] = useState(undefined);
  const [market, setMarket] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBrand(findObjectByValue(brandOptions, campaign?.brandId));
    setMarket(findObjectByValue(marketOptions, campaign?.market));
  }, [campaign, brandOptions, marketOptions]);

  const handleUpdate = async () => {
    setIsLoading(true);
    onUpdateCampaign(brand.value, market.value);
  };

  return (
    <MessageModal
      actionButtonDisabled={isLoading}
      actionButtonLabel="Update"
      isOpen={isOpen}
      size="medium"
      title="Re-map Campaign"
      onActionButtonClick={handleUpdate}
      onClose={onClose}
    >
      <div className="u-flexColumn u-gap-16 u-marginBottom-8">
        <div className={styles.name}>
          <div className="t-subtitle">Campaign Name:</div>
          <div className="t-body-1">{ campaign?.name || 'N/A' }</div>
        </div>
        <div className="u-flexRow u-gap-24">
          { renderField('Brand Code', campaign?.brandCode) }
          { renderField('Market Code', campaign?.marketCode) }
          { renderField('Ad Account ID', campaign?.accountId) }
          { renderField('Channel', campaign?.channelLabel) }
        </div>
        <Dropdown
          disabled={campaign?.brandDisabled}
          label="Brand"
          menuProps={{ size: 'medium' }}
          options={brandOptions}
          selected={brand}
          size="medium"
          onChange={(opt) => setBrand(opt)}
        />
        <Dropdown
          disabled={campaign?.marketDisabled}
          label="Market"
          menuProps={{ size: 'medium' }}
          options={marketOptions}
          selected={market}
          size="medium"
          onChange={(opt) => setMarket(opt)}
        />
      </div>
    </MessageModal>
  );
}

EditCampaignModal.propTypes = propTypes;

export default EditCampaignModal;
