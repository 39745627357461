import React from 'react';
import PropTypes from 'prop-types';
import {
  ClickableTag,
  Tag,
} from '@makeably/creativex-design-system';
import styles from './ReportTags.module.css';

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const optionPropsArray = PropTypes.arrayOf(optionProps);

const propTypes = {
  filters: PropTypes.objectOf(optionPropsArray),
  removeFilter: PropTypes.func,
  removeSelectedMetric: PropTypes.func,
  removeSelectedSegment: PropTypes.func,
  segments: optionPropsArray,
  selectedDateOption: optionProps,
  selectedMetrics: optionPropsArray,
  selectedSegments: optionPropsArray,
  selectedTimePeriod: optionProps,
  onFilterClick: PropTypes.func,
  onMetricClick: PropTypes.func,
  onSegmentClick: PropTypes.func,
};

const defaultProps = {
  filters: {},
  onFilterClick: undefined,
  onMetricClick: undefined,
  onSegmentClick: undefined,
  removeFilter: undefined,
  removeSelectedMetric: undefined,
  removeSelectedSegment: undefined,
  segments: [],
  selectedDateOption: undefined,
  selectedMetrics: [],
  selectedSegments: [],
  selectedTimePeriod: undefined,
};

function ReportTags({
  filters,
  onFilterClick,
  onMetricClick,
  onSegmentClick,
  removeFilter,
  removeSelectedMetric,
  removeSelectedSegment,
  segments,
  selectedDateOption,
  selectedMetrics,
  selectedSegments,
  selectedTimePeriod,
}) {
  const filterEntries = Object.entries(filters);
  const hasSegments = selectedSegments.length > 0;
  const hasMetrics = selectedMetrics.length > 0;
  const hasFilters = filterEntries.length > 0;

  const renderSegment = (option) => (
    <ClickableTag
      key={option.value}
      label={option.label}
      onClick={onSegmentClick}
      onRemove={option.disabled ? undefined : () => removeSelectedSegment?.(option)}
    />
  );

  const renderMetric = (option) => (
    <ClickableTag
      key={option.value}
      label={option.label}
      onClick={onMetricClick}
      onRemove={() => removeSelectedMetric?.(option)}
    />
  );

  const renderTimePeriod = () => {
    if (!selectedTimePeriod) return null;

    return (
      <>
        <div className="t-caption-1">Time Period:</div>
        <ClickableTag
          label={selectedTimePeriod.label}
          onClick={onSegmentClick}
        />
      </>
    );
  };

  const renderDate = () => {
    if (!selectedDateOption) return null;

    return (
      <>
        <div className="t-caption-1">Date:</div>
        <Tag label={selectedDateOption.label} />
      </>
    );
  };

  const renderFilter = ([key, values]) => {
    const segmentLabel = segments.find((seg) => (seg.value === key))?.label;

    return (
      <ClickableTag
        key={key}
        color="purple"
        label={`${segmentLabel} (${values.length})`}
        onClick={onFilterClick}
        onRemove={() => removeFilter?.(key)}
      />
    );
  };

  if (!hasSegments && !hasMetrics && !hasFilters) return null;

  return (
    <div className={styles.tags}>
      { hasSegments && <div className="t-caption-1">Segments:</div> }
      { selectedSegments.map((seg) => renderSegment(seg)) }
      { hasMetrics && <div className="t-caption-1">Metrics:</div> }
      { selectedMetrics.map((met) => renderMetric(met)) }
      { renderTimePeriod() }
      { renderDate() }
      { hasFilters && <div className="t-caption-1">Filters:</div> }
      { filterEntries.map((entry) => renderFilter(entry)) }
    </div>
  );
}

ReportTags.propTypes = propTypes;
ReportTags.defaultProps = defaultProps;

export default ReportTags;
