import React from "react"
import AssetImage from "components/reusable/asset_image"
import Column from "components/reusable/column"
import PropTypes from "prop-types"
import GapAnalysisCard from "components/gap_analysis/gap_analysis_card"
import Row from "components/reusable/row"
import { Tooltip } from '@makeably/creativex-design-system';

# responsible for rendering gap analysis rows (winner card, frequency gap, performance gap
class GapAnalysisContainer extends React.Component

  @propTypes =
    # TODO: supply shape params
    frequency: PropTypes.object
    groups: PropTypes.array
    performance: PropTypes.object

  renderCategoryImage: (group) ->
    winner = group[group.winnerGroup]
    asset = winner.asset

    tooltipText = "#{winner.label} in #{winner.group_label} had greater performance uplift in this timeframe."

    `<div className={'search-asset-card'}>
      <a href={winner.link} >
        <AssetImage {...asset} />
        <Tooltip label={tooltipText}>
          <h5 className={group.winnerGroup}>{group.label}</h5>
        </Tooltip>
      </a>
    </div>`

  renderGauge: (group, gapType) ->
    `<GapAnalysisCard
      gapType={gapType}
      group={group}
    />`

  renderGapAnalysis: (group) =>
    image = @renderCategoryImage(group)
    frequencyGauge = @renderGauge(group, @props.frequency)
    performanceGauge = @renderGauge(group, @props.performance)

    `<Row key={_.uniqueId("gaugeGap--")} >
      <Column size={2} className="no-padding">
        {image}
      </Column>
      <Column size={5}>
        {frequencyGauge}
      </Column>
      <Column size={5} className="no-padding">
        {performanceGauge}
      </Column>
    </Row>`

  render: ->
    `<div className={"gapAnalysisContainer"}>
      {this.props.groups.map(this.renderGapAnalysis)}
    </div>`

export default GapAnalysisContainer
