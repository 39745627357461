// Chart with Tabs
// Renders passed in chart type with tabs to select the data
//  - BodyContainedTabs -> Determines position of tabs:
//    - In CardBody
//    - Outside CardBody
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  WidgetContent,
} from '@duik/it';
import BarChart, {
  chartProps as BarChartProps,
  layoutProps as BarChartLayout,
} from 'components/charts/BarChart';
import DoughnutChart, {
  chartProps as DoughnutChartProps,
  layoutProps as DoughnutChartLayout,
} from 'components/charts/DoughnutChart';
import Tabs from 'components/reusable/Tabs';
import MixpanelTracker from 'components/utils/MixpanelTracker';
import StringHelper from 'components/utils/StringHelper';

const propTypes = {
  charts: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(BarChartProps),
      PropTypes.shape(DoughnutChartProps),
    ]),
  ).isRequired,
  type: PropTypes.oneOf(['bar', 'doughnut']).isRequired,
  ...BarChartLayout,
  ...DoughnutChartLayout,
  bodyContained: PropTypes.bool,
  bodyContainedTabs: PropTypes.bool,
  className: PropTypes.string,
  hasData: PropTypes.bool,
  tabColor: PropTypes.string,
  onTabChange: PropTypes.func,
};

const defaultProps = {
  bodyContained: false,
  bodyContainedTabs: false,
  className: undefined,
  hasData: true,
  onTabChange: () => undefined,
  tabColor: undefined,
};

class TabbedChart extends React.Component {
  constructor(props) {
    super(props);

    const { charts } = props;
    const tabs = charts.map((
      {
        label,
        labelExtension,
        dataLabel,
        tooltipText,
      },
    ) => (
      {
        label: label || StringHelper.titleize(dataLabel),
        labelExtension,
        tooltipText,
        value: dataLabel,
      }
    ));

    const tab = tabs[0].value;
    const { chart } = charts.find(({ dataLabel }) => dataLabel === tab);

    this.state = {
      chart,
      tab,
      tabs,
    };
  }

  updateData(tab) {
    const {
      charts,
      onTabChange,
    } = this.props;

    const selectedChart = charts.find(({ dataLabel }) => dataLabel === tab);
    const { chart, label } = selectedChart;

    this.setState(
      {
        tab,
        chart,
      },
      () => onTabChange(tab),
    );

    MixpanelTracker.trackTabClick(label, tab);
  }

  render() {
    const {
      bodyContained,
      bodyContainedTabs,
      className,
      fixedSize,
      layoutPadding,
      hasData,
      overview,
      staticLayout,
      tabColor,
      tickPadding,
      type,
    } = this.props;

    const {
      tab,
      tabs,
      chart,
    } = this.state;

    const tabContent = (
      <Tabs
        activeOption={tab}
        borderless={bodyContainedTabs}
        callback={(newTab) => this.updateData(newTab)}
        tabColor={tabColor}
        tabs={tabs}
      />
    );

    const classes = classNames(
      className,
      'chartContainer',
      {
        'chartContainer--bar': type === 'bar',
        'chartContainer--doughnut': type === 'doughnut',
      },
    );

    let ChartComponent;
    switch (type) {
      case 'bar':
        ChartComponent = BarChart;
        break;
      case 'doughnut':
        ChartComponent = DoughnutChart;
        break;
      default:
        throw new Error('TabbedChart requires a type to be set');
    }

    const chartContent = (
      <ChartComponent
        chart={chart}
        dataLabel={tab}
        fixedSize={fixedSize}
        layoutPadding={layoutPadding}
        overview={overview}
        staticLayout={staticLayout}
        tickPadding={tickPadding}
      />
    );

    let content;
    const Container = bodyContained ? WidgetContent : 'div';

    if (!hasData) {
      content = (
        <Container className={classes}>
          No data to display
        </Container>
      );
    } else if (bodyContainedTabs) {
      content = (
        <Container className={classes}>
          { tabContent }
          { chartContent }
        </Container>
      );
    } else {
      content = (
        <div>
          { tabContent }
          <Container className={classes}>
            { chartContent }
          </Container>
        </div>
      );
    }

    return content;
  }
}

TabbedChart.propTypes = propTypes;
TabbedChart.defaultProps = defaultProps;

export default TabbedChart;
