import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { Icon } from '@makeably/creativex-design-system';

const propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  className: undefined,
  icon: 'copy',
  onClick: () => {},
};

class CopyToClipboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copySuccess: false,
    };
  }

  removeCopyNotification() {
    setTimeout(() => this.setState({ copySuccess: false }), 2000);
  }

  copyToClipboard() {
    const { value, onClick } = this.props;
    onClick();
    copy(value);
    this.setState({ copySuccess: true }, this.removeCopyNotification);
  }

  render() {
    const {
      className,
      icon: iconName,
    } = this.props;

    const { copySuccess } = this.state;

    const classes = classNames(
      className,
      'copyToClipboard',
      {
        'copyToClipboard--success': copySuccess,
      },
    );

    return (
      <button
        className={classes}
        tabIndex={0}
        type="button"
        onClick={() => this.copyToClipboard()}
        onKeyDown={() => this.copyToClipboard()}
      >
        <Icon color="current" name={iconName} />
      </button>
    );
  }
}

CopyToClipboard.propTypes = propTypes;
CopyToClipboard.defaultProps = defaultProps;

export default CopyToClipboard;
