// Renders an expandable and sortable table
// Can have clickable rows (as links) is passing in clickable
// and linkParam and linkUrl
// @note should be the only table used moving forward
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  WidgetContent,
  WidgetTable,
} from '@duik/it';
import { Icon } from '@makeably/creativex-design-system';
import ExpandButton from 'components/reusable/ExpandButton';
import Loader from 'components/reusable/Loader';
import ArrayHelper from 'components/utils/ArrayHelper';
import StringHelper from 'components/utils/StringHelper';

const tableCellProps = {
  format: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export const propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortDir: PropTypes.oneOf(['asc', 'desc']).isRequired,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  dataUrl: PropTypes.string,
  emptyMessage: PropTypes.string,
  linkParam: PropTypes.string,
  linkUrl: PropTypes.string,
  remote: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape(tableCellProps),
    ),
  ),
  sortIndex: PropTypes.number,
};

const defaultProps = {
  className: undefined,
  clickable: false,
  dataUrl: undefined,
  emptyMessage: undefined,
  linkParam: undefined,
  linkUrl: undefined,
  remote: false,
  rows: undefined,
  sortIndex: 0,
};

function goToLink(linkUrl, linkParam, linkValue) {
  const url = new URL(linkUrl);
  const params = url.searchParams;
  params.append(linkParam, linkValue);

  window.location.href = url.href;
}

function EmptyMessage({ display, message }) {
  if (display && message) {
    return <WidgetContent className="u-textAlignCenter">{ message }</WidgetContent>;
  }

  return null;
}
EmptyMessage.propTypes = {
  display: PropTypes.bool.isRequired,
  message: PropTypes.string,
};
EmptyMessage.defaultProps = {
  message: undefined,
};

function TableHeader(props) {
  const {
    children,
    idx,
    onClick,
    sortDir,
    sortIndex,
  } = props;

  const selected = idx === sortIndex;

  const iconName = sortDir === 'desc' ? 'chevronDown' : 'chevronUp';

  let header;
  if (selected) {
    header = (
      <div>
        <Icon name={iconName} />
        { children }
      </div>
    );
  } else {
    header = children;
  }

  return (
    <th onClick={() => onClick()}>
      { header }
    </th>
  );
}
TableHeader.propTypes = {
  children: PropTypes.node.isRequired,
  idx: PropTypes.number.isRequired,
  sortDir: PropTypes.oneOf(['asc', 'desc']).isRequired,
  sortIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

/* Allowed to override this rule if breaking down a static set  */
/* eslint-disable react/no-array-index-key */
function TableRow(props) {
  const {
    cells,
    clickable,
    linkParam,
    linkUrl,
    rank,
  } = props;

  let onClick;
  if (clickable) {
    const linkValue = cells[0].value;
    onClick = () => goToLink(linkUrl, linkParam, linkValue);
  }

  return (
    <tr onClick={onClick}>
      <td>{ rank }</td>
      {
        cells.map(({ format, value }, cellIdx) => {
          const options = {};
          // Don't set sigFigs on currency for now.
          // In the future, might want to expand currency format to be be
          // currency, currency-rounded, currency-full.
          // Or similar to indicate differences and abstract
          // nitty-gritty formatting
          if (format === 'currency') options.sigFigs = null;

          return (
            <td key={cellIdx}>
              { StringHelper.format(value, format, options) }
            </td>
          );
        })
      }
    </tr>
  );
}
/* eslint-enable react/no-array-index-key */
TableRow.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape(tableCellProps),
  ).isRequired,
  clickable: PropTypes.bool.isRequired,
  rank: PropTypes.number.isRequired,
  linkParam: PropTypes.string,
  linkUrl: PropTypes.string,
};
TableRow.defaultProps = {
  linkParam: undefined,
  linkUrl: undefined,
};

class ExpandableTable extends React.Component {
  static defaultVisibleRows() { return 5; }

  static sliceRows(arr, expanded = false) {
    const defaultVisible = ExpandableTable.defaultVisibleRows();
    const endVal = expanded ? arr.length : defaultVisible;

    return arr.slice(0, endVal);
  }

  constructor(props) {
    super(props);

    const {
      rows,
      sortDir,
      sortIndex,
    } = props;

    this.state = {
      error: false,
      expanded: false,
      loading: false,
      rows,
      sortDir,
      sortIndex,
    };
  }

  componentDidMount() {
    const { dataUrl, remote } = this.props;
    if (!remote) return;

    this.getData(dataUrl);
  }

  getData(url) {
    this.setState({ loading: true });

    fetch(url)
      .then((res) => res.json())
      .then((data) => this.setState({
        loading: false,
        rows: data,
        error: false,
      }))
      .catch((_error) => this.setState({
        loading: false,
        error: true,
      }));
  }

  expandTable() {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  }

  sortData(idx) {
    this.setState((prevState) => {
      // Default sort is desc by newly clicked column
      let sortDir = 'desc';

      if (prevState.sortIndex === idx) {
        if (prevState.sortDir === 'asc') {
          sortDir = 'desc';
        } else {
          sortDir = 'asc';
        }
      }

      return {
        sortDir,
        sortIndex: idx,
      };
    });
  }

  render() {
    const {
      className,
      clickable,
      emptyMessage,
      headers,
      linkParam,
      linkUrl,
      remote,
    } = this.props;

    const {
      expanded,
      rows,
      sortDir,
      sortIndex,
      loading,
      error,
    } = this.state;

    // Short Circuit on Remote case
    if (remote) {
      if (error) {
        return (
          <WidgetContent>
            Encountered an error
          </WidgetContent>
        );
      }

      if (loading) {
        return (
          <WidgetContent>
            <Loader />
          </WidgetContent>
        );
      }

      if (!rows) return null;
    }

    const classes = classNames(
      className,
      'expandableTable',
      { 'expandableTable--clickable': clickable },
    );

    let sortedRows = ArrayHelper.tableSort(rows, sortDir, sortIndex);
    sortedRows = ExpandableTable.sliceRows(sortedRows, expanded);

    const displayExpandButton = rows.length > ExpandableTable.defaultVisibleRows();

    return (
      <div className={classes}>
        <WidgetTable>
          <thead>
            <tr>
              <th>Rank</th>
              {
                headers.map((header, idx) => (
                  <TableHeader
                    key={header}
                    idx={idx}
                    sortDir={sortDir}
                    sortIndex={sortIndex}
                    onClick={() => this.sortData(idx)}
                  >
                    { header }
                  </TableHeader>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              sortedRows.map((rowCells, idx) => (
                <TableRow
                  key={rowCells[0].value}
                  cells={rowCells}
                  clickable={clickable}
                  linkParam={linkParam}
                  linkUrl={linkUrl}
                  rank={idx + 1}
                />
              ))
            }
          </tbody>
        </WidgetTable>
        <ExpandButton
          callback={() => this.expandTable()}
          display={displayExpandButton}
          expanded={expanded}
        />
        <EmptyMessage display={(rows.length < 1)} message={emptyMessage} />
      </div>
    );
  }
}

ExpandableTable.propTypes = propTypes;
ExpandableTable.defaultProps = defaultProps;

export default ExpandableTable;
