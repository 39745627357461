import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Spinner } from '@makeably/creativex-design-system';
import VideoInfo from 'components/atoms/VideoInfo';
import styles from './AssetImage.module.css';

const propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  videoLength: PropTypes.number,
};

const defaultProps = {
  videoLength: undefined,
};

function AssetImage({
  alt,
  src,
  videoLength,
}) {
  const [loaded, setLoaded] = useState(false);

  const spinnerClasses = classNames(
    styles.spinner,
    { [styles.hidden]: loaded },
  );
  const imageClasses = classNames({
    [styles.hidden]: !loaded,
  });

  return (
    <div className={styles.main}>
      <div className={spinnerClasses}>
        <Spinner color="custom" size="custom" />
      </div>
      <img
        alt={alt}
        className={imageClasses}
        src={src}
        onLoad={() => setLoaded(true)}
      />
      <VideoInfo length={videoLength} />
    </div>
  );
}

AssetImage.propTypes = propTypes;
AssetImage.defaultProps = defaultProps;

export default AssetImage;
