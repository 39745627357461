const labels = {
  activeAccounts: 'Active Accounts',
  missingBrandPages: 'Missing Brand Pages',
  needsReconnecting: 'Needs Reconnecting',
  totalSpend: 'Total Spend',
  activeBrandPages: 'Active Brand Pages',
  totalAccounts: 'Total Accounts',
  inactiveAccounts: 'Inactive Accounts',
  adAccountReportingTooltips: {
    activeAccounts: 'These ad accounts are fully connected and are likely providing data.',
    missingBrandPages: 'Facebook stores some videos in Brand Pages, which need to be connected in order to pull in this content.',
    needsReconnecting: 'CreativeX has lost access to these ad accounts and data pulls have ceased. To resume, please reconnect.',
    totalSpend: 'Up to date spend of all reviewed creatives.',
  },
};

// eslint-disable-next-line import/prefer-default-export
export { labels as enLabels };
