import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import RadioButton from "components/reusable/radio_button"
import { Tooltip } from '@makeably/creativex-design-system';

# This component is responsible for setting up a collection of radio buttons for a form
class RadioButtonGroup extends React.Component
  @propTypes =
    formId: PropTypes.string
    inputName: PropTypes.string
    labelType: PropTypes.string
    layout: PropTypes.oneOf(['horizontal', 'vertical'])
    onSelect: PropTypes.func
    options: PropTypes.arrayOf(
      PropTypes.shape(
        iconProps: PropTypes.object
        label: PropTypes.string
        value: PropTypes.string
      )
    ).isRequired
    selectedValue: PropTypes.string.isRequired
    # Props to render a tooltip for a given radio button option
    tooltips: PropTypes.arrayOf(
      PropTypes.shape(
        option: PropTypes.string # Same value as the radio button option
        tooltipProps: PropTypes.object # Props for a Tooltip component
      )
    )
    visualStyle: PropTypes.oneOf(['default', 'browserDefault'])

  @defaultProps =
    layout: 'vertical'
    visualStyle: 'default'

  classes: ->
    classNames(
      'radioButtonGroup',
      "radioButtonGroup--#{@props.layout}",
      @props.className
    )

  renderTooltip: (optionValue) =>
    tooltip = _.find(@props.tooltips, { option: optionValue })
    if (tooltip.tooltipProps.tooltipText)
      `<Tooltip label={tooltip.tooltipProps.tooltipText} />`

  renderRadioButton: (option) =>
    # extracted out from the jsx to appease linter of need for fat arrow
    isChecked = @props.selectedValue is option.value
    `<div key={option.value} >
      <RadioButton
         {...option}
         formId={this.props.formId}
         isChecked={isChecked}
         labelType={this.props.labelType}
         name={this.props.inputName}
         onSelect={this.props.onSelect}
         visualStyle={this.props.visualStyle}
      />
      {this.renderTooltip(option.value)}
    </div>`

  render: ->
    `<div className={this.classes()}>
      {this.props.options.map(this.renderRadioButton)}
    </div>`

export default RadioButtonGroup
