import React from 'react';
import PropTypes from 'prop-types';
import { headerProps } from 'components/molecules/ItemsTable';
import { saveFile } from 'utilities/file';
import {
  getItemsCsv,
  getItemSortBy,
  itemProps,
} from 'utilities/item';
import styles from './CsvButton.module.css';

const propTypes = {
  filename: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(headerProps).isRequired,
  items: PropTypes.arrayOf(itemProps).isRequired,
  children: PropTypes.node,
  tableSort: PropTypes.shape({
    asc: PropTypes.bool,
    key: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

const defaultProps = {
  children: 'Export CSV',
  onClick: undefined,
  tableSort: undefined,
};

function CsvButton({
  children,
  headers,
  items,
  filename,
  tableSort,
  onClick,
}) {
  const generateCsv = () => {
    let sortedItems = items.slice();

    if (tableSort) {
      const sortByKeyDir = getItemSortBy(tableSort.key, tableSort.asc);
      sortedItems = sortedItems.sort(sortByKeyDir);
    }

    const csv = getItemsCsv(sortedItems, headers);
    saveFile(filename, csv);
  };

  const onExportClick = () => {
    generateCsv();
    if (onClick) onClick();
  };

  return (
    <button className={styles.button} type="button" onClick={onExportClick}>
      { children }
    </button>
  );
}

CsvButton.propTypes = propTypes;
CsvButton.defaultProps = defaultProps;

export default CsvButton;
