// File is used for business logic / display logic for the TikTok Ad Account
// linking
import BusinessCenterSelection from 'components/account_setup/shared_steps/BusinessCenterSelection';
import Confirmation from 'components/account_setup/shared_steps/Confirmation';
import AdAccountAssignment from 'components/account_setup/tiktok_ads/steps/AdAccountAssignment';
import AdAccountSelection from 'components/account_setup/tiktok_ads/steps/AdAccountSelection';

const HELP_LINK_BASE_URL = 'https://ads.tiktok.com/help/article';
const CX_INVITE_USER_URL = 'https://app.creativex.com/settings/users/new';

class StepHelper {
  static getContentTag(view) {
    switch (view) {
      case 'business_centers':
        return BusinessCenterSelection;
      case 'ad_accounts':
        return AdAccountSelection;
      case 'associate_ad_accounts':
        return AdAccountAssignment;
      case 'confirmation':
        return Confirmation;
      default:
        return null;
    }
  }

  static getInstructionContent(view) {
    switch (view) {
      case 'business_centers':
        return {
          preLink: 'Select Business Centers to assign CreativeX',
          linkObject: {
            href: `${HELP_LINK_BASE_URL}?aid=13989`,
            label: 'Partner Access',
          },
          postLink: '. This will enable the sharing of the account\'s assets.',
        };
      case 'business_centers_missing_data':
        return {
          preLink: 'This account does not have admin access to any Business Centers. '
            + 'Please try again using a different TikTok account, or',
          linkObject: {
            href: CX_INVITE_USER_URL,
            label: 'invite',
          },
          postLink: ' a user to CreativeX with the required access to complete your connections.',
        };
      case 'ad_accounts':
        return {
          preLink: 'Select Ad Accounts to assign CreativeX the',
          linkObject: {
            href: `${HELP_LINK_BASE_URL}?aid=238947046829056266`,
            label: 'Analyst Role',
          },
          postLink: '. This will enable ads, reports, transactions, and audience viewing.',
        };
      case 'associate_ad_accounts':
        return 'Associate each Ad Account with its correct brand, market, and partner.';
      case 'confirmation':
        return 'Confirm each Ad Account is associated with the correct brand, market, and partner.';
      default:
        return null;
    }
  }

  static getEmptyMessage() {
    return 'No available Business Centers';
  }
}

export default StepHelper;
