import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import InteractivePlotlyChart from "components/graphs/interactive_plotly_chart"
import NavDropdown from "components/navigation/NavDropdown"
import PlotlyChart from "components/reusable/plotly_chart"
import { Tooltip } from '@makeably/creativex-design-system'
import { Widget, WidgetHeader, WidgetContent } from  "@duik/it"

# Audit historical trends page
class HistoricalTrends extends React.Component
  @propTypes =
    performanceChartProps: PropTypes.object
    pretestOptions: PropTypes.object
    segmentType: PropTypes.string
    segmentationOptions: PropTypes.object
    tooltipText: PropTypes.string

  renderNavDropdown: (props) ->
    options = NavDropdown.sanitize(props.options)
    activeOption = _.findWhere(options, link: props.selected)

    dropdownProps =
      activeOption: activeOption
      label: props.label
      options: options

    `<NavDropdown {...dropdownProps} />`

  renderSegmentationOptions: ->
    additionalSelector =
      if @props.segmentType is 'aggregated'
        @renderNavDropdown(@props.pretestOptions)

    `<div className='u-marginAbove u-flexRow'>
      <div className='u-marginRight'>
        {this.renderNavDropdown(this.props.segmentationOptions)}
      </div>
      {additionalSelector}
    </div>`

  renderPerformanceGraph: (isStatic) ->
    if isStatic
      `<PlotlyChart {...this.props.performanceChartProps}/>`
    else
      `<InteractivePlotlyChart {...this.props.performanceChartProps}/>`

  render: ->
    isStatic = @props.segmentType is 'aggregated'

    `<div className="trends">
      <Widget>
        <WidgetHeader>
          <div className='u-flexRow u-flexAlignCenter'>
            <div className='u-marginRightSm'>
              <h5>Past Performance</h5>
            </div>
            { this.props.tooltipText && <Tooltip label={this.props.tooltipText} direction='right' /> }
          </div>
        </WidgetHeader>
        <WidgetContent>
          {this.renderSegmentationOptions()}
          {this.renderPerformanceGraph(isStatic)}
        </WidgetContent>
      </Widget>
    </div>`

export default HistoricalTrends
