import {
  useEffect,
  useRef,
} from 'react';

export function useTimeout(callback, delay) {
  const timeoutRef = useRef(null);
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => { // eslint-disable-line consistent-return
    const currentCallback = () => callbackRef.current();

    if (typeof delay === 'number') {
      timeoutRef.current = setTimeout(currentCallback, delay);
      return () => clearTimeout(timeoutRef.current);
    }
  }, [delay]);

  return timeoutRef;
}
