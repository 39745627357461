import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Pagination,
} from '@makeably/creativex-design-system';
import QueueTable from 'components/admin/review_queue/QueueTable';
import Filters, { filterProps } from 'components/filters/AdminAuditFilters';
import { propTypes as navProps } from 'components/navigation/NavDropdown';
import SearchableNavDropdown from 'components/navigation/SearchableNavDropdown';
import { HiddenInput } from 'components/reusable/HiddenInput';
import styles from './PreflightQueue.module.css';

const preflightProps = {
  brand: PropTypes.string,
  channel: PropTypes.string,
  creativeRatio: PropTypes.string,
  dateSubmitted: PropTypes.string,
  id: PropTypes.number,
  link: PropTypes.string,
  market: PropTypes.string,
  name: PropTypes.string,
  organization: PropTypes.string,
  remainingHours: PropTypes.number,
  status: PropTypes.string,
  submitter: PropTypes.string,
};

const propTypes = {
  audits: PropTypes.arrayOf(PropTypes.shape(preflightProps)).isRequired,
  filters: filterProps.isRequired,
  formUrl: PropTypes.string.isRequired,
  organizationFilter: PropTypes.shape(navProps).isRequired,
  page: PropTypes.number.isRequired,
  showSubmitter: PropTypes.bool.isRequired,
  viewState: PropTypes.string.isRequired,
  count: PropTypes.number,
  pageSizeLimit: PropTypes.number,
};

const defaultProps = {
  count: null,
  pageSizeLimit: undefined,
};

function getHeaders(showSubmitter, view) {
  const allHeaders = [
    {
      label: 'ID',
      value: 'id',
    },
    {
      label: 'Status',
      value: 'status',
      filter: 'pending',
    },
    {
      label: 'Brand',
      value: 'brand',
    },
    {
      label: 'Market',
      value: 'market',
    },
    {
      label: 'Channel',
      value: 'channel',
    },
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Time Remaining',
      value: 'remainingHours',
      filter: 'pending',
    },
    {
      label: 'Submitter',
      value: 'submitter',
    },
    {
      label: 'Date Submitted',
      value: 'dateSubmitted',
    },
    {
      label: 'Outstanding / Total Creatives',
      value: 'creativeRatio',
      filter: 'pending',
    },
    {
      label: 'Total Creatives',
      value: 'totalCreatives',
      filter: 'reviewed',
    },
    {
      label: 'Completed In',
      value: 'timeToEvaluate',
      filter: 'reviewed',
    },
  ];

  return allHeaders.filter((header) => {
    if (header.value === 'submitter') return showSubmitter;

    return header.filter ? header.filter === view : true;
  });
}

class PreflightQueue extends React.Component {
  constructor(props) {
    super(props);

    const {
      page,
    } = props;

    this.state = {
      formLoading: false,
      page,
      pageNavigation: false,
    };
  }

  navigateToPage(page, formId) {
    this.setState({
      formLoading: true,
      page,
      pageNavigation: true,
    }, () => document.getElementById(formId).submit());
  }

  render() {
    const {
      formLoading,
      page,
      pageNavigation,
    } = this.state;

    const {
      audits,
      count,
      filters,
      formUrl,
      organizationFilter,
      pageSizeLimit,
      showSubmitter,
      viewState,
    } = this.props;

    const formId = 'filteringForm';

    return (
      <div className={styles.container}>
        <div className={styles.filters}>
          <Filters
            filters={filters}
            formId={formId}
            formUrl={formUrl}
            loading={formLoading}
            viewState={viewState}
          />
        </div>
        <div className={styles.card}>
          <Card>
            <div className={styles.header}>
              <SearchableNavDropdown
                activeOption={organizationFilter.activeOption}
                label={organizationFilter.label}
                menuPosition="bottom-right"
                options={organizationFilter.options}
                block
              />
            </div>
            <QueueTable
              headers={getHeaders(showSubmitter, viewState)}
              items={audits}
            />
            { pageNavigation && <HiddenInput form={formId} name="page" value={page} /> }
            <div className={styles.pagination}>
              <Pagination
                currentPage={page}
                perPage={pageSizeLimit}
                total={count}
                onPageChange={(newPage) => this.navigateToPage(newPage, formId)}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

PreflightQueue.propTypes = propTypes;
PreflightQueue.defaultProps = defaultProps;

export default PreflightQueue;
