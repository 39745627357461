import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@makeably/creativex-design-system';

const propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SCALE = 100;

function toPercent(value) {
  return (SCALE * value).toFixed(0);
}

function fromPercent(text) {
  const value = parseFloat(text);

  if (Number.isNaN(value)) {
    return 0;
  }

  const limited = Math.max(0, Math.min(100, value));

  return Math.round(limited) / SCALE;
}

function PercentInput({
  label,
  onChange,
  value,
}) {
  return (
    <TextInput
      label={label}
      type="number"
      value={toPercent(value)}
      onChange={(text) => onChange(fromPercent(text))}
    />
  );
}

PercentInput.propTypes = propTypes;

export default PercentInput;
