import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

class DarkHeaderCard extends React.Component
  @propTypes =
    headerClass: PropTypes.string
    headerContent: PropTypes.node

  constructor: (props) ->
    super(props)

  classes: ->
    classNames(
      'darkHeaderCard'
      'card'
      @props.className
    )

  headerClasses: ->
    classNames('darkHeader', @props.headerClass)

  render: ->
    `<div className={this.classes()}>
       <div className={this.headerClasses()}>
         {this.props.headerContent}
       </div>
       {this.props.children}
     </div>`

export default DarkHeaderCard
