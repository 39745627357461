import React from "react"
import Column from "components/reusable/column"
import DateRange from "components/reusable/date_range"
import RadioButtonGroup from "components/reusable/radio_button_group"
import Row from "components/reusable/row"
import Select from "components/reusable/select"
import TextInput from "components/reusable/text_input"
import Typeahead from "components/reusable/typeahead"
import PropTypes from "prop-types"

# This component is responsible for containing logic around the form creation of post groups
# This class generates a form for updating PostFormGroup rails objects
# It has various control functionality and interaction between inputs
# For example, changing the media type will update the the channel select options to those supported by the media type
# This behavior extends to other elements of the form, so the options you can select dynamically change based
# on other inputs.
# Also changing some values will cause other options to reset, for example changing the media type will reset
# the selected media filter (tag, hashtag, category)
#
# NB: IMPORTANT: state *just so happens* to be flat (not nested) at the moment. If we change this, some things
#     in the react state changes need to be updated (since _.extend() only shallow copies,
#     nested changes will not be updated
class PostGroupForm extends React.Component
  FILTERABLE_OPTIONS_URL = '/comparisons/form_options'
  FORM_PREFIX = 'post_group_form'

  @propTypes =
    # In order to support forms in rails we need this token for the form
    authenticity_token: PropTypes.string.isRequired
    currentView: PropTypes.string
    formId: PropTypes.string
    # This is the PostFormGroup model object used to fill out the form
    # TODO: Give more insight into this object
    # TODO: change the attributes to be camelCase to follow JS convention
    formObject: PropTypes.object.isRequired
    # Object of various simply generated options that don't vary based on selections.
    # Comes from a function in PostFormGroup
    # TODO: change all these props to be camelCase to follow JS convention ex: start_date, end_date, date_range_type
    formOptions: PropTypes.shape(
      age: PropTypes.arrayOf(PropTypes.object)
      audience_filter_types: PropTypes.arrayOf(PropTypes.object)
      channels: PropTypes.object
      competitors: PropTypes.object
      country: PropTypes.array
      creative_types: PropTypes.arrayOf(PropTypes.object)
      # Props for the DateRange
      date_range_options: PropTypes.object
      editable_fields: PropTypes.arrayOf(PropTypes.object)
      filterableOptions: PropTypes.object
      filterable_types: PropTypes.arrayOf(PropTypes.object)
      formElementLabels: PropTypes.object
      gender: PropTypes.arrayOf(PropTypes.object)
      hashtag_support: PropTypes.object
      media_types: PropTypes.arrayOf(PropTypes.object)
      performance_filters: PropTypes.arrayOf(PropTypes.object)
      performance_metric_options: PropTypes.object
      visible: PropTypes.arrayOf(PropTypes.object)
    ).isRequired
    # Where we want to sumbit the form
    formUrl: PropTypes.string.isRequired
    # General label for the post group
    groupLabel: PropTypes.string.isRequired

  constructor: (props) ->
    super(props)
    @state = $.extend(true, {}, @props.formObject)
    @state.isLoadingData = false

    @state.audience_filter_type =
      if @state.country
        'country'
      else if @state.age && @state.gender
        'age_gender'
      else if @state.age
        'age'
      else if @state.gender
        'gender'
      else
        'none'

    # Make sure we have start and end dates to prevent input errors
    @state.start_date ||= ''
    @state.end_date ||= ''

    # If we have a preset date range selected, populate the inputs with the values for that preset range
    presetOptionsToRange = @props.formOptions.date_range_options.presetDateRangeProps.optionToDateRange
    presetDateRange = presetOptionsToRange[@props.formObject.date_range_type]
    if presetDateRange
      @state.start_date = presetDateRange.startDate
      @state.end_date = presetDateRange.endDate


    @getFilterableOptions('country') if @state.country
    @getFilterableOptions(@state.filterable_type) if @state.filterable_type isnt 'none'

  resetForm: =>
    @setState(@props.formObject)

  # We setup various keys for data that is retrieved from the server for the form
  # That way we make as few requests as possible and can reload from the cache
  # of data if the user is toggling various elements of the form
  #
  # cacheDataKey is of form:
  # (Category, Tag, Hashtag)_(owned, earned, competitor, paid)_(instagram, facebook....)_(last_30_days)
  # ex: Category_owned_instagram is the key to the categories for their instagram owned content
  cacheDataKey: (filterableType) ->
    cacheElementArr = [
      filterableType,
      @state.media_type,
      @state.channel,
      @state.competitor_id,
      @state.start_date,
      @state.end_date
    ]
    _.compact(cacheElementArr).join("_")

  # If we change the filterable type we need to get the data to populate the select
  retrieveFilterableData: (attributeName, value) ->
    return if @state.isLoadingData

    if (attributeName is 'filterable_type' and value isnt 'none') or
       (attributeName is 'audience_filter_type' and value is 'country')
      # Only get data if we don't have it already
      # NB: If we are here, the value is a filterable_type or 'country'
      unless @state[@cacheDataKey(value)]
        @getFilterableOptions(value)

  # -- Reset Various Properties -- #
  propertiesToReset: (updatedAttributeName) =>
    switch updatedAttributeName
      # Reset filterable_type/audience_filter_type to none if we change channel, competitor, media type, date range
      when 'channel', 'competitor_id', 'media_type', 'date_range_type'
        {
          age: ''
          audience_filter_type: 'none'
          country: ''
          filterable_id: ''
          filterable_type: if @props.currentView == 'topics' then 'Hashtag' else 'none'
          filterables: []
          gender: ''
        }

      # Reset filterable_id if we change the filterable type
      when 'filterable_type'
        {
          filterable_id: ''
          filterables: []
        }

      # Reset date range type if you select a start or end date
      when 'start_date', 'end_date'
        {
          age: ''
          audience_filter_type: 'none'
          country: ''
          date_range_type: ''
          filterable_id: ''
          filterable_type: if @props.currentView == 'topics' then 'Hashtag' else 'none'
          filterables: []
          gender: ''
        }

  handleValueChange: (attributeName) =>
    (value) =>
      # Update the Property Value
      updatedState = {}
      updatedState[attributeName] = value

      # If we change the filterable type we need to get the data to populate the select
      @retrieveFilterableData(attributeName, value)

      # Verify currently selected channel is valid for selected media type
      # If we change the media type we want to reset competitor_id
      if attributeName is 'media_type'
        updatedState.competitor_id = ''
        channelOptions = @props.formOptions.channels[value]
        selectedOption = _.find(channelOptions, (obj) => obj.value is @state.channel)
        if _.isUndefined(selectedOption)
          # Purpose of this is so that we always have channel selected
          # Some media types dont support all channels
          updatedState.channel = channelOptions[0].value

      # If we change the channel and competitor is the media type or
      # if we select competitors as a media type, we want to set the selected
      # competitor to the first one in the list
      competitorChannelChanged = attributeName is 'channel' and @state.media_type is 'competitor'
      competitorSelected = attributeName is 'media_type' and value is 'competitor'
      if competitorChannelChanged or competitorSelected
        channel = if attributeName is 'channel' then value else @state.channel
        updatedState.competitor_id = @props.formOptions.competitors[channel][0].value

      # -- Reset Various Properties -- #
      $.extend(true, updatedState, @propertiesToReset(attributeName))

      @setState(updatedState)

  # -- Form Helpers -- #

  inputName: (name) ->
    FORM_PREFIX + "[#{name}]"

  getFilterableOptions: (filterableType) ->
    @state.isLoadingData = true

    $(document).trigger('loader.show')
    $.get(
      FILTERABLE_OPTIONS_URL,
      filterable_options:
        channel: @state.channel
        competitor_id: @state.competitor_id
        end_date: @state.end_date
        filterable_type: filterableType
        media_type: @state.media_type
        start_date: @state.start_date
    ).done((response) =>
      updatedData = {}
      updatedData[@cacheDataKey(filterableType)] = response

      @setState(updatedData)
    ).always(=>
      @state.isLoadingData = false
      $(document).trigger('loader.hide')
    )

  # This function is a mapping of attributes to labels for consolidation of view specific code
  formElementLabel: (attributeName) ->
    if attributeName == 'filterable_id'
      @props.formOptions.formElementLabels[attributeName][@state.filterable_type]
    else
      @props.formOptions.formElementLabels[attributeName]

  renderRadioButtonGroup: (attributeName, optionsName, layout = 'vertical') ->
    options =
      switch optionsName
        when 'channels'
          @props.formOptions.channels[@state.media_type]
        when 'filterable_types'
          if @props.formOptions.hashtag_support[@state.media_type][@state.channel]
            @props.formOptions.filterable_types
          else
            # If the current media type and channel don't support hashtags, we remove them from the filterable_types
            _.reject(@props.formOptions.filterable_types, (obj) -> obj.value is 'Hashtag')
        else
          @props.formOptions[optionsName]

    labelType = if attributeName is 'channel' then 'channel' else undefined

    `<RadioButtonGroup
       formId={this.props.formId}
       inputName={this.inputName(attributeName)}
       labelType={labelType}
       layout={layout}
       onSelect={this.handleValueChange(attributeName)}
       options={options}
       selectedValue={this.state[attributeName]}
     />`

  renderTypeahead: (field) ->
    typeaheadOptions = @props.formOptions[field]
    `<Typeahead
       multiple
       {...typeaheadOptions}
       formValue={_.pluck(this.state[field], 'value')}
       inputName={this.inputName(field)}
       onChange={this.handleValueChange(field)}
       selected={this.state[field]}
     />`

  renderSelect: (attributeName, allowDisplay) ->
    return unless allowDisplay

    selectOptions =
      switch attributeName
        when 'competitor_id'
          @props.formOptions.competitors[@state.channel]
        when 'country'
          @state[@cacheDataKey('country')]
        when 'filterable_id'
          @state[@cacheDataKey(@state.filterable_type)]
        when 'facebook_ad_account_id'
          @props.formOptions.facebook_ad_accounts
        when 'sort_column'
          @props.formOptions.performance_metric_options[@state.media_type][@state.channel]
        else
          @props.formOptions[attributeName]

    if attributeName == 'filterable_id' and
      @props.formOptions.filterableOptions[@state.filterable_type] and
      @props.formOptions.filterableOptions[@state.filterable_type]['has_many']
        `<Typeahead
           multiple
           required={true}
           formValue={_.pluck(this.state['filterables'], 'value')}
           inputName={this.inputName('filterable_ids')}
           labelKey="label"
           onChange={this.handleValueChange('filterables')}
           options={selectOptions || []}
           selected={this.state.filterables}
         />`
    else if selectOptions and selectOptions.length > 0
      selected = @state[attributeName] || selectOptions[0].value
      `<Select
         className="btn-spacing"
         inputName={this.inputName(attributeName)}
         onChange={this.handleValueChange(attributeName)}
         options={selectOptions}
         selected={selected}
      />`

  handleDateRangeChange: (targetValue, additionalFields = {}) =>
    targetName = additionalFields['inputName']
    # NB: attributeName is different than what we send to the DateRangePicker for the targetName(input name)
    attributeName =
      switch targetName
        when @inputName('start_date') then 'start_date'
        when @inputName('end_date') then 'end_date'
        when @inputName('date_range_type') then 'date_range_type'
    return unless attributeName
    updatedState = { "#{attributeName}": targetValue }

    # -- Reset Various Properties -- #
    $.extend(true, updatedState, @propertiesToReset(attributeName))
    @setState(updatedState)

  renderDateRange: ->
    `<div className='postGroupForm-dateRange'>
      <DateRange
        {...this.props.formOptions.date_range_options}
        endDate={this.state.end_date}
        endDateInputName={this.inputName('end_date')}
        onSelect={this.handleDateRangeChange}
        startDate={this.state.start_date}
        startDateInputName={this.inputName('start_date')}
        presetInputName={this.inputName('date_range_type')}
      />
     </div>`

  renderAudienceFilters: ->
    return unless @state.media_type is 'paid'

    type = @state.audience_filter_type

    `<div>
      {this.renderRadioButtonGroup('audience_filter_type', 'audience_filter_types')}
      {this.renderSelect('age', type  === 'age' || type === 'age_gender' )}
      {this.renderSelect('gender', type === 'gender' || type === 'age_gender' )}
      {this.renderSelect('country', type === 'country')}
    </div>`

  renderTextInput: (attributeName) ->
    `<TextInput
       inputName={this.inputName(attributeName)}
       label={this.formElementLabel(attributeName)}
       onChange={this.handleValueChange(attributeName)}
       required={true}
       value={this.state[attributeName]}
     />`

  renderEditableFields: (field) =>
    editableField =
      switch field.type
        when 'text'
          @renderTextInput(field.attribute)
        when 'radio'
          @renderRadioButtonGroup(field.attribute, field.options)
        when 'select'
          switch field.attribute
            when 'competitor_id'
              @renderSelect(field.attribute, @state.media_type == 'competitor')
            when 'filterable_id'
              @renderSelect('filterable_id', @state.filterable_type != 'none')
            when 'sort_column'
              @renderSelect('sort_column', @state.performance_filter != 'none')
            when 'facebook_ad_account_id'
              @renderSelect(field.attribute, @state.media_type is 'paid')
        when 'typeahead'
          @renderTypeahead(field.attribute)
        else
          switch field.attribute
            when 'date_range'
              @renderDateRange()
            when 'audience_filter_type'
              @renderAudienceFilters()

    return unless editableField

    key = "attributeName-#{field.attribute}"
    `<Row key={key}>
       <Column>
         <h6>{this.formElementLabel(field.attribute)}</h6>
         <div className="inputGroup">
           {editableField}
         </div>
       </Column>
     </Row>`

  renderDelete: ->
    if _.contains(_.pluck(@props.formOptions.editable_fields, 'attribute'), 'visible') and @props.formObject.persisted
      `<Column size={6} className='right-align'>
         <input type="submit" value="delete" name={this.inputName('visible')} className="btn-secondary"/>
       </Column>`

  render: ->
    formMethod = if @props.formObject.persisted then 'patch' else 'post'

    `<form
      className="postGroupForm"
      id={this.props.formId}
      action={this.props.formUrl}
      method="post"
    >
      <Row className='postGroupForm-header'>
        <Column>
          <h4>
            {this.props.groupLabel}
          </h4>
        </Column>
      </Row>
      <input type="hidden" name="_method" value={formMethod}/>
      <input type="hidden" name="authenticity_token" value={this.props.authenticity_token}/>
      <input type="hidden" name="current_view" value={this.props.currentView}/>
      <input type="hidden" name="type" value={this.state.type}/>
      {this.props.formOptions.editable_fields.map(this.renderEditableFields)}
      <Row>
        <Column size={6}>
          <input type="submit" value="Save Group" className="btn"/>
        </Column>
        {this.renderDelete()}
      </Row>
    </form>`

export default PostGroupForm
