import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './StickyTop.module.css';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function StickyTop({ children }) {
  const [shadowShown, setShadowShown] = useState(false);

  useEffect(() => {
    const handleScroll = (e) => {
      const shadowSizePx = 24;
      setShadowShown(e.target.scrollTop > shadowSizePx);
    };

    const content = document.getElementById('page-content');
    content.addEventListener('scroll', handleScroll); // eslint-disable-line
    return () => content.removeEventListener('scroll', handleScroll); // eslint-disable-line
  }, []);

  const classes = classNames(styles.top, { [styles.shadow]: shadowShown });

  return (
    <div className={classes}>
      { children }
    </div>
  );
}

StickyTop.propTypes = propTypes;

export default StickyTop;
