import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Card,
  Spinner,
  Tooltip,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import { internalUserActAsPath } from 'utilities/routes';
import styles from './ActAs.module.css';

const RECENTS_MAX = 10;
const SPINNER_TIMEOUT_MS = 10000;

const userProps = PropTypes.shape({
  companyId: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
});

const propTypes = {
  companyAdmins: PropTypes.arrayOf(userProps).isRequired,
  actingUser: userProps,
};

const defaultProps = {
  actingUser: undefined,
};

function findAdmins(ids, admins) {
  return ids.reduce((arr, id) => {
    const found = admins.find(({ companyId }) => companyId === id);

    if (found) {
      return [...arr, found];
    }
    return arr;
  }, []);
}

function ActAs({ actingUser, companyAdmins }) {
  const [savedCompanyIds, setSavedCompanyIds] = useLocalStorage('cxIntActAs', []);
  const [recentCompanyIds, setRecentCompanyIds] = useState([]);
  const [loadingId, setLoadingId] = useState(null);

  useEffect(() => setRecentCompanyIds(savedCompanyIds), []);

  const recentAdmins = useMemo(() => (
    findAdmins(recentCompanyIds, companyAdmins)
  ), [recentCompanyIds, companyAdmins]);

  const handleClick = (companyId, id) => {
    setLoadingId(id);
    setTimeout(() => setLoadingId(null), SPINNER_TIMEOUT_MS);

    setSavedCompanyIds((last) => {
      const limited = last.slice(0, RECENTS_MAX - 1);
      const filtered = limited.filter((value) => value !== companyId);

      return [companyId, ...filtered];
    });
  };

  const renderActingUser = (user) => {
    if (!user) return null;

    return (
      <div className={`t-body-2 ${styles.actingAs}`}>
        { `Currently acting as ${user.fullName} from ${user.companyName}` }
      </div>
    );
  };

  const renderSpinner = (id) => {
    if (id !== loadingId) return null;

    return (
      <div className={styles.loading}>
        <div className={styles.spinner}>
          <Spinner color="custom" size="custom" />
        </div>
      </div>
    );
  };

  const renderAdmin = ({
    companyId,
    companyName,
    email,
    fullName,
    userId,
  },
  section) => {
    const id = `${companyId}:${section}`;
    const isDisabled = loadingId && id !== loadingId;
    const classes = classNames(
      't-button',
      styles.admin,
      { [styles.disabled]: isDisabled },
    );

    return (
      <a
        key={id}
        className={classes}
        disabled={isDisabled}
        href={internalUserActAsPath(userId)}
        onClick={() => handleClick(companyId, id)}
      >
        <div className={styles.name}>
          { companyName }
        </div>
        <Tooltip label={`${fullName} (${email})`} />
        { renderSpinner(id) }
      </a>
    );
  };

  return (
    <Card>
      { recentAdmins.length > 0 && (
        <>
          <div className={styles.section}>
            <div className={styles.recent}>
              <div className={`t-overline ${styles.title}`}>
                Recent Company Admin Users
              </div>
              { renderActingUser(actingUser) }
            </div>
            <div className={styles.admins}>
              { recentAdmins.map((admin) => renderAdmin(admin, 'recent')) }
            </div>
          </div>
        </>
      ) }
      <div className={styles.section}>
        <div className={`t-overline ${styles.title}`}>
          All Company Admin Users
          <Tooltip label="Click a company to act as the default user for that company" />
        </div>
        <div className={styles.admins}>
          { companyAdmins.map((admin) => renderAdmin(admin, 'all')) }
        </div>
      </div>
    </Card>
  );
}

ActAs.propTypes = propTypes;
ActAs.defaultProps = defaultProps;

export default ActAs;
