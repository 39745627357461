import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormGroupContainer,
  TextField,
} from '@duik/it';
import InputValidation from 'components/reusable/forms/InputValidation';

const propTypes = {
  rules: PropTypes.shape({
    letter: PropTypes.boolean,
    minLength: PropTypes.number,
    number: PropTypes.boolean,
    symbol: PropTypes.boolean,
  }).isRequired,
  passwordExpired: PropTypes.boolean,
};

const defaultProps = {
  passwordExpired: false,
};

const NumberRegex = /\d/;
const LetterRegex = /[A-Za-z]/;
const AllowedSymbols = "! @ # $ % ^ & * ( ) _ + { } [ ] : ; ' \" / \\ ? > > . ,";
const SymbolRegex = new RegExp(`[${AllowedSymbols.split(' ').join('\\')}]`);

function checkLetter(password) {
  return LetterRegex.test(password);
}

function checkNumber(password) {
  return NumberRegex.test(password);
}

function checkSymbol(password) {
  return SymbolRegex.test(password);
}

function checkMinLength(password, minLength) {
  return password.length >= minLength;
}

function LetterValidation(password) {
  return (
    <InputValidation
      key="letter"
      label="Must include at least 1 letter"
      success={checkLetter(password)}
      value={password}
    />
  );
}

function NumberValidation(password) {
  return (
    <InputValidation
      key="number"
      label="Must include at least 1 number"
      success={checkNumber(password)}
      value={password}
    />
  );
}

function SymbolValidation(password) {
  return (
    <InputValidation
      key="symbol"
      label="Must include at least 1 symbol"
      success={checkSymbol(password)}
      tooltip={`Symbols include: ${AllowedSymbols}`}
      value={password}
    />
  );
}

function MinLengthValidation(password, minLength) {
  return (
    <InputValidation
      key="minLength"
      label={`Must have at least ${minLength} characters`}
      success={checkMinLength(password, minLength)}
      value={password}
    />
  );
}

function RenderValidations(password, rules) {
  const ruleValidators = [];
  if (rules.letter) ruleValidators.push(LetterValidation(password));
  if (rules.number) ruleValidators.push(NumberValidation(password));
  if (rules.symbol) ruleValidators.push(SymbolValidation(password));
  if (rules.minLength) ruleValidators.push(MinLengthValidation(password, rules.minLength));

  return ruleValidators;
}

class EditPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmationPassword: '',
    };
  }

  onPasswordChange(password) {
    this.setState({ password });
  }

  onPasswordConfirmationChange(confirmationPassword) {
    this.setState({ confirmationPassword });
  }

  render() {
    const {
      rules,
      passwordExpired,
    } = this.props;

    const {
      password,
      confirmationPassword,
    } = this.state;

    return (
      <FormGroupContainer>
        <FormGroup>
          <TextField
            autocompleted="off"
            label={passwordExpired ? 'New Password' : 'Password'}
            name="user[password]"
            type="password"
            noWrap
            onChange={(e) => this.onPasswordChange(e.target.value)}
          />
          { RenderValidations(password, rules) }
        </FormGroup>
        <FormGroup>
          <TextField
            autocompleted="off"
            label={passwordExpired ? 'Confirm New Password' : 'Password Confirmation'}
            name="user[password_confirmation]"
            type="password"
            noWrap
            onChange={(e) => this.onPasswordConfirmationChange(e.target.value)}
          />
          <InputValidation
            label="Passwords must match"
            success={confirmationPassword === password}
            value={confirmationPassword}
          />
        </FormGroup>
      </FormGroupContainer>
    );
  }
}
EditPasswordForm.propTypes = propTypes;
EditPasswordForm.defaultProps = defaultProps;

export {
  AllowedSymbols,
  checkLetter,
  checkNumber,
  checkSymbol,
  checkMinLength,
};

export default EditPasswordForm;
