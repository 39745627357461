class ColorHelper {
  static lookup(string) {
    let color;

    switch (string) {
      case 'Markets':
        color = 'blue';
        break;
      case 'Brands':
        color = 'rose';
        break;
      case 'Companies':
        color = 'indigo';
        break;
      case 'Users':
      case 'Spend YTD':
        color = 'yellow';
        break;
      case 'Spend':
      case 'Total Spend':
        color = 'green';
        break;

      // no default
    }

    return color;
  }
}

export default ColorHelper;
