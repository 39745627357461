# TODO: Replace this with the duik Select or Searchable Select
import React from "react"
import PropTypes from "prop-types"
import { Select } from "antd"

# NB: This component is a simple wrapper for the Ant Design Select component. Full list of available
# props can be found here: https://ant.design/components/select/
#
# Explanation of PropTypes:
#   - searchByLabel: this is relevant when showSearch is true and allows the search to work on the label instead of
#                    the value. This is useful when we search by name, but select an id. Ex. Bob - 1
#                    @note: this only works if labels are unique, otherwise you will get a random value back
class AntdSelect extends React.Component
  SELECT_ALL_OPTION = 'select_all'
  SELECT_ALL_OPTION_LABEL = 'Select All'

  @propTypes =
    allowClear: PropTypes.bool
    allowSelectAll: PropTypes.bool
    disabled: PropTypes.bool
    dropdownClassName: PropTypes.string
    placeholder: PropTypes.string
    onChange: PropTypes.func
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape(
          disabled: PropTypes.bool
          label: PropTypes.string
          value: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.number,
            PropTypes.string
          ])
        )
      ),
      PropTypes.arrayOf(
        PropTypes.shape(
          filters: PropTypes.arrayOf(
            PropTypes.shape(
              inputName: PropTypes.string
              label: PropTypes.string
              options: PropTypes.arrayOf(
                PropTypes.shape(
                  label: PropTypes.string
                  value: PropTypes.string
                )
              )
              type: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
              ])
              value: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
              ])
            )
          )
          optgroup: PropTypes.string
        )
      )
    ])
    mode: PropTypes.oneOf(['multiple', 'default'])
    searchByLabel: PropTypes.bool
    selected: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.number,
      PropTypes.string
    ])
    showSearch: PropTypes.bool
    size: PropTypes.string

  @defaultProps =
    allowClear: true  # NB allowSearch and allowClear should not be true at same time
    allowSelectAll: false # NB allows select all option
    mode: 'multiple'
    placeholder: 'choose from...'
    searchByLabel: false
    selected: []
    showSearch: false # NB allowSearch and allowClear should not be true at same time
    size: 'default'

  allSelected: (selectedValue) ->
    # NB: Select all is only valid for a multi-select, which returns an array for option
    return unless _.isArray(selectedValue)
    selectedValue.includes(SELECT_ALL_OPTION) || selectedValue.includes(SELECT_ALL_OPTION_LABEL)

  handleChange: (selectedValue) =>
    selectedValues = selectedValue

    # If multiple mode and the user selects all we just grab all the values
    # from options
    if @props.mode is 'multiple' and @allSelected(selectedValues)
      selectedValues = _.pluck(@props.options, 'value')

    # If we're in searchByLabel mode, depending on the mode, we need to either
    # grab an array of option values (multiple) or a single option value (default)
    else if @props.searchByLabel
      if @props.mode is 'multiple'
        selectedValues = _.pluck(_.filter(@props.options, (option) -> option.label in selectedValue), 'value')
      else
        selectedValues = _.find(@props.options, (option) -> option.label is selectedValue).value

    @props.onChange(selectedValues)

  renderOption: (option) =>
    key = "optionId-#{option.value}"

    # antdSelect uses the value to do the search, so if we want to searchByLabel we need to pretend the label is the
    # value and then lookup the actual value in the handleChange call
    value = if @props.searchByLabel then option.label else option.value
    `<Select.Option value={value} key={key} disabled={option.disabled}>
      {option.label}
    </Select.Option>`

  renderOptGroup: (optGroupLabel, options) ->
    key = "optGroup-#{optGroupLabel}"
    groupOptions = options.map(@renderOption)

    `<Select.OptGroup label={optGroupLabel} key={key}>
      {groupOptions}
    </Select.OptGroup>`

  renderOptions: =>
    for option in @props.options
      if option.optgroup
        @renderOptGroup(option.optgroup, option.filters)
      else
        @renderOption(option)

  renderSelectAll: ->
    return unless @props.options and @props.allowSelectAll
    @renderOption(
      {
        value: SELECT_ALL_OPTION,
        label: 'Select All'
      },
      'select_all_key'
    )

  render: ->
    selectProps = _.pick(
      @props,
      [
        'allowClear',
        'className',
        'disabled',
        'dropdownClassName',
        'mode',
        'placeholder',
        'showSearch',
        'size',
        'selected'
      ]
    )

    # In searchByLabel mode, we want to make sure we're always displaying the labels
    #   of the options, so depending on whether we're in multiple or default mode,
    #   we return an array of labels or a single label found in @props.options
    selected =
      if @props.searchByLabel
        if @props.mode is 'multiple'
          selectedValues = @props.selected
          _.pluck(_.filter(@props.options, (option) -> option.value in selectedValues), 'label')
        else
          selectedOption = _.find(@props.options, (option) => option.value is @props.selected)
          if selectedOption then selectedOption.label else undefined
      else
        # Select prefers undefined to null as an unselected value, otherwise the placeholder text will not show
        if _.isNull(@props.selected) then undefined else @props.selected

    `<Select {...selectProps} onChange={this.handleChange} value={selected}>
      {this.renderSelectAll()}
      {this.renderOptions()}
    </Select>`

export default AntdSelect
