import React from 'react';
import PropTypes from 'prop-types';
import { DataVizSkeleton } from '@makeably/creativex-design-system';
import ItemsBarChart from 'components/molecules/ItemsBarChart';
import { itemProps } from 'utilities/item';
import styles from './VerticalBarChart.module.css';

const verticalBarChartProps = {
  displayKey: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(itemProps).isRequired,
  labelKey: PropTypes.string.isRequired,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
};

const verticalBarChartDefaultProps = {
  xLabel: '',
  yLabel: '',
};

const getChartOptions = (yLabel, xLabel) => ({
  indexAxis: 'x',
  layout: {
    padding: {
      top: 50,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: () => null,
      },
      displayColors: false,
      xAlign: 'center',
      yAlign: 'bottom',
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: xLabel,
      },
    },
    y: {
      beginAtZero: true,
      border: {
        display: true,
      },
      grid: {
        display: true,
        drawTicks: true,
      },
      ticks: {
        beginAtZero: true,
        display: true,
        callback: (value) => (value % 1 === 0 ? value : undefined),
      },
      title: {
        display: true,
        text: yLabel,
      },
    },
  },
});

function VerticalBarChart({
  displayKey,
  items,
  labelKey,
  xLabel,
  yLabel,
}) {
  const customChartOptions = getChartOptions(yLabel, xLabel);

  if (items.length === 0) {
    return (
      <div className={styles.empty}>
        <DataVizSkeleton />
      </div>
    );
  }

  return (
    <ItemsBarChart
      customChartOptions={customChartOptions}
      displayKey={displayKey}
      items={items}
      labelKey={labelKey}
      tooltipKeys={[]}
    />
  );
}

VerticalBarChart.propTypes = verticalBarChartProps;
VerticalBarChart.defaultProps = verticalBarChartDefaultProps;

export default VerticalBarChart;
