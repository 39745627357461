// File is used for business logic / display logic for the Facebook Ad Account
// linking
import BrandPageSelection from 'components/account_setup/facebook_brands/steps/BrandPageSelection';
import Confirmation from 'components/account_setup/facebook_brands/steps/Confirmation';
import BusinessCenterSelection from 'components/account_setup/shared_steps/BusinessCenterSelection';

const HELP_LINK_BASE_URL = 'https://support.creativex.com/hc/en-us/articles';
const FACEBOOK_BASE_URL = 'https://www.facebook.com/business/';
const CX_INVITE_USER_URL = '/settings/users/new';
const CX_META_ADS_START = '/auth/meta_ads';

class StepHelper {
  static getContentTag(view) {
    switch (view) {
      case 'business_managers':
        return BusinessCenterSelection;
      case 'brand_pages':
        return BrandPageSelection;
      case 'confirmation':
        return Confirmation;
      default:
        return null;
    }
  }

  static getInstructionContent(view) {
    switch (view) {
      case 'business_managers':
        return {
          preLink: 'Select Business Centers to assign CreativeX',
          linkObject: {
            href: `${HELP_LINK_BASE_URL}/6135779336091-How-do-I-connect-my-Facebook-and-Instagram-Ads-account-`,
            label: 'Partner Access',
          },
          postLink: '. This will enable the sharing of the account\'s assets.',
        };
      case 'business_managers_missing_data':
        return [
          {
            preLink: 'This account does not have admin access to any Business Managers. Please',
            linkObject: {
              href: CX_META_ADS_START,
              label: 'log in',
            },
            postLink: ' using a different Facebook account, or ',
          },
          {
            linkObject: {
              href: CX_INVITE_USER_URL,
              label: 'invite',
            },
            postLink: ' a user to CreativeX with the required access to complete your connections.',
          },
        ];
      case 'brand_pages':
        return [
          {
            preLink: 'Select the',
            linkObject: {
              href: `${FACEBOOK_BASE_URL}pages`,
              label: 'Brand Pages',
            },
            postLink: ' to give CreativeX access, which will enable the sharing of ads ',
          },
          {
            linkObject: {
              href: `${FACEBOOK_BASE_URL}help/397103717129942?id=1913105122334058`,
              label: 'stored at that level',
            },
            postLink: '.',
          },
        ];
      case 'confirmation':
        return 'Confirm each Ad Account is associated with the correct brand, market, and partner.';
      default:
        return null;
    }
  }

  static getEmptyMessage() {
    return 'No available Business Managers';
  }
}

export default StepHelper;
