import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  BasicModal,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import { getAuthenticityToken } from 'utilities/requests';
import {
  adminStandardScorePath,
  adminStandardScoresPath,
} from 'utilities/routes';
import styles from './StandardScore.module.css';

const propTypes = {
  canManage: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  details: PropTypes.shape({
    id: PropTypes.number,
    isProcessing: PropTypes.bool,
  }).isRequired,
  supportedForView: PropTypes.bool.isRequired,
};

async function updateScoreWeights(companyId, scoreId, isUpdate) {
  const params = { company_id: companyId };
  let method; let
    url;

  if (isUpdate) {
    method = 'PATCH';
    url = adminStandardScorePath(scoreId, params);
  } else {
    method = 'POST';
    url = adminStandardScoresPath(params);
  }

  const response = await fetch(
    url,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-CSRF-Token': getAuthenticityToken(),
      },
      method,
    },
  );

  return response.json();
}

function StandardScore({
  canManage,
  company: {
    id: companyId,
    name: companyName,
  },
  details: {
    id: scoreId,
    isProcessing,
  },
  supportedForView,
}) {
  if (!canManage || !supportedForView) return null;

  const [actionDisabled, setActionDisabled] = useState(isProcessing);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isUpdate = scoreId !== undefined;
  const handleClose = () => setIsModalOpen(false);

  const onUpdateScore = async () => {
    handleClose();
    setActionDisabled(true);

    const data = await updateScoreWeights(companyId, scoreId, isUpdate);
    const toastOpts = { size: 'large' };
    let toastMsg;

    if (data.success) {
      toastMsg = isUpdate ? 'Processing updates to Standard Score' : 'Creating Standard Score';
    } else {
      toastMsg = 'Unable to process Standard Score, please try again';
      toastOpts.type = 'error';
      setActionDisabled(false);
    }

    addToast(toastMsg, toastOpts);
  };

  const footer = (
    <div className="buttonGroup">
      <Button
        label="Cancel"
        variant="tertiary"
        onClick={handleClose}
      />
      <Button
        label="Confirm"
        onClick={onUpdateScore}
      />
    </div>
  );

  return (
    <>
      <AddNewButton
        disabled={actionDisabled}
        label={isUpdate ? 'Update Standard Score' : 'Create Standard Score'}
        onClick={() => setIsModalOpen(true)}
      />
      <BasicModal
        footer={footer}
        isOpen={isModalOpen}
        size="medium"
        onClose={handleClose}
      >
        <>
          <h5>
            Are you sure you want to
            { ' ' }
            { isUpdate ? 'update the' : 'create a' }
            { ' ' }
            Standard Score for
            { ` ${companyName}?` }
          </h5>
          <div className={classNames(styles.notice, 't-body-1')}>
            No changes will be made to any pre-existing scores, but doing so will
            { ' ' }
            { isUpdate ? 'update the' : 'add a' }
            { ' ' }
            Standard Score
            { ' ' }
            { isUpdate ? 'on' : 'to' }
            { ' ' }
            their dashboard and trigger a recalcuation of their content.
          </div>
        </>
      </BasicModal>
    </>
  );
}

StandardScore.propTypes = propTypes;

export default StandardScore;
