import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Checkbox,
  MaxWidthText,
  Table,
} from '@makeably/creativex-design-system';
import {
  getItemContent,
  itemProps,
} from 'utilities/item';
import styles from 'components/molecules/SelectableItemsTable.module.css';

export const headerProps = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
});
const propTypes = {
  headers: PropTypes.arrayOf(headerProps).isRequired,
  items: PropTypes.arrayOf(itemProps).isRequired,
  selectedItems: PropTypes.arrayOf(itemProps).isRequired,
  onItemsSelect: PropTypes.func.isRequired,
  checkDisabledItems: PropTypes.bool,
  className: PropTypes.string,
  singleSelect: PropTypes.bool,
};
const defaultProps = {
  checkDisabledItems: false,
  className: undefined,
  singleSelect: false,
};

function renderCell(item, key, disabled) {
  const content = getItemContent(item, key);
  const cellClasses = classNames(
    styles.cell,
    {
      [styles.disabled]: disabled,
    },
  );

  switch (typeof content) {
    case 'string':
    case 'number':
      return {
        value: (
          <MaxWidthText
            className={cellClasses}
            text={content}
          />
        ),
      };
    default:
      return {
        value: content,
      };
  }
}

function SelectableItemsTable({
  checkDisabledItems,
  className,
  headers,
  items,
  onItemsSelect,
  selectedItems,
  singleSelect,
}) {
  const [allSelected, setAllSelected] = useState(false);

  const handleSelectAll = (checked) => {
    setAllSelected(checked);
    if (checked) {
      const enabledItems = items.filter((item) => !item.disabled?.value);
      onItemsSelect(enabledItems);
    } else {
      onItemsSelect([]);
    }
  };

  const handleItemChecked = (item, checked) => {
    if (checked) {
      if (singleSelect) {
        onItemsSelect([item]);
      } else {
        onItemsSelect([...selectedItems, item]);
      }
    } else {
      onItemsSelect(selectedItems.filter((i) => i.id.value !== item.id.value));
    }
  };

  const renderCheckbox = (item, disabled) => {
    const isSelected = selectedItems.findIndex((i) => i.id.value === item.id.value) !== -1;
    const checked = isSelected || (checkDisabledItems && disabled);

    return (
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={(e) => handleItemChecked(item, e.target.checked)}
      />
    );
  };

  const getFirstTableHeader = () => {
    if (singleSelect) return { value: '' };

    return {
      value: (
        <Checkbox
          checked={allSelected}
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
      ),
    };
  };

  const getTableHeaders = () => {
    const firstHeader = getFirstTableHeader();

    return [
      firstHeader,
      ...headers.map((header) => ({ value: header.label })),
    ];
  };

  const getRow = (item, keys) => {
    const disabled = Boolean(item.disabled?.value);
    const checkboxCell = {
      value: renderCheckbox(item, disabled),
    };

    return {
      key: `${item.id.value}`,
      cells: [checkboxCell, ...keys.map((key) => renderCell(item, key, disabled))],
    };
  };

  const tableHeaders = getTableHeaders();
  const keys = headers.map((header) => header.key);
  const rows = items.map((item) => getRow(item, keys));

  return (
    <Table
      className={`${styles.table} ${className}`}
      headers={tableHeaders}
      rows={rows}
    />
  );
}

SelectableItemsTable.propTypes = propTypes;
SelectableItemsTable.defaultProps = defaultProps;

export default SelectableItemsTable;
