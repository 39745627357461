import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  FormGroupContainer,
  FormGroup,
  Widget,
  WidgetContent,
} from '@duik/it';
import CardHeader from 'components/reusable/CardHeader';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import DefaultQueryFields, {
  propTypes as defaultQueryFieldProps,
} from 'components/settings/default_query_fields.coffee';
import LapsedNotifications from 'components/settings/users/LapsedNotifications';

const optionProps = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
);

const optionPair = PropTypes.shape({
  options: optionProps,
  selectedOptions: optionProps,
});

export const propTypes = {
  brands: optionPair.isRequired,
  channels: optionPair.isRequired,
  companies: optionPair.isRequired,
  defaultQueryProps: PropTypes.shape(defaultQueryFieldProps).isRequired,
  firstName: PropTypes.string.isRequired,
  lapsedType: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  markets: optionPair.isRequired,
  url: PropTypes.string.isRequired,
};

function renderDefaultQueryFields(props) {
  const {
    brandOptions,
    channelOptions,
    marketOptions,
    selectedValues,
  } = props;

  return (
    <div>
      <Divider margin />
      <DefaultQueryFields
        brandOptions={brandOptions}
        channelOptions={channelOptions}
        marketOptions={marketOptions}
        selectedValues={selectedValues}
      />
    </div>
  );
}

function Preferences(props) {
  const {
    brands,
    channels,
    companies,
    defaultQueryProps,
    firstName,
    lapsedType,
    lastName,
    markets,
    url,
  } = props;

  return (
    <div className="page--clipHiddenOverflow">
      <Widget>
        <CardHeader
          header={`${firstName} ${lastName}`}
          icon="userPreferences"
          subHeader="Update your notifications and dashboard settings"
        />
        <WidgetContent>
          <form action={url} method="post">
            <AuthenticityTokenInput />
            <HiddenInput name="_method" value="patch" />
            <div className="userPreferences-header">Notifications</div>
            <LapsedNotifications
              brands={brands}
              channels={channels}
              companies={companies}
              lapsedType={lapsedType}
              markets={markets}
            />
            { renderDefaultQueryFields(defaultQueryProps) }
            <FormGroupContainer>
              <FormGroup className="u-textAlignCenter">
                <input
                  className="formSubmit btn btn-primary"
                  name="commit"
                  type="submit"
                  value="Update User"
                />
              </FormGroup>
            </FormGroupContainer>
          </form>
        </WidgetContent>
      </Widget>
    </div>
  );
}

Preferences.propTypes = propTypes;

export default Preferences;
