import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@duik/it';
import {
  Icon,
  Tooltip,
} from '@makeably/creativex-design-system';
import SearchableTable from 'components/organisms/SearchableTable';
import { pageStorageKey } from 'components/settings/AdAccounts';
import { setItemElement } from 'utilities/itemElement';
import {
  updateStateSettingsLinkedPlatformAccountPath,
} from 'utilities/routes';
import {
  getParams,
  setParam,
} from 'utilities/url';
import styles from './FacebookAdPages.module.css';

export const UNIQUE = 'unique';
export const BRAND_MARKET = 'brand_market';

const adPageProps = PropTypes.shape({
  associatedId: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  brandPageId: PropTypes.string.isRequired,
  brandPageName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  connectionUrl: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  recordId: PropTypes.number.isRequired,
  rowId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  dateLinked: PropTypes.string,
});
export const propTypes = {
  adPagesByStatus: PropTypes.objectOf(PropTypes.arrayOf(adPageProps)).isRequired,
};

const filterKeys = ['brand', 'market'];
const tabs = ['lapsed', 'active', 'inactive', 'all'];

function statusFormatter(value) {
  const statusLabels = {
    active: 'Active',
    all: 'All',
    inactive: 'Inactive',
    lapsed: 'Missing',
  };

  return statusLabels[value];
}

function getHeaders(tab, pageView) {
  const allHeaders = [
    {
      key: 'brandPageId',
      label: 'Brand Page ID',
    },
    {
      key: 'brandPageName',
      label: 'Brand Page Name',
    },
    {
      key: 'brand',
      label: 'Brand',
      view: BRAND_MARKET,
    },
    {
      key: 'market',
      label: 'Market',
      view: BRAND_MARKET,
    },
    {
      key: 'associatedId',
      label: 'Associated Account ID',
      view: BRAND_MARKET,
    },
    {
      key: 'associatedAccounts',
      label: 'Associated Ad Accounts',
      tooltip: 'The number of ad accounts dependent on the corresponding Brand Page.',
      view: UNIQUE,
    },
    {
      key: 'dateLinked',
      label: 'Date Linked',
    },
    {
      key: 'status',
      label: 'Status',
      filter: ['all'],
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ];

  return allHeaders.filter(({ filter, view }) => {
    const forFilter = filter ? filter.includes(tab) : true;
    const forView = view ? view === pageView : true;

    return forFilter && forView;
  });
}

function getHeaderContent(pageView, setPageView) {
  const headerClasses = 'u-flexRow u-flexAlignCenter u-marginLeftMd u-marginAboveMd';

  const uniqueLabel = <span className="t-label-1">By Unique Brand Page</span>;
  const brandMarketLabel = <span className="t-label-1">By Brand & Market</span>;

  return (
    <>
      <div className={headerClasses}>
        <div className="u-marginRightSm">
          <h5>Facebook Brand Pages</h5>
        </div>
        <Tooltip label="Facebook stores some videos in Brand Pages, which need to be connected in order to capture this content." />
      </div>
      <div className={styles.radioButtonContainer}>
        <span className="t-overline">Brand Page Table View</span>
        <div className={styles.radioButtonRow}>
          <div>
            <Radio
              checked={UNIQUE === pageView}
              label={uniqueLabel}
              onChange={() => setPageView(UNIQUE)}
            />
          </div>
          <div className="u-marginLeftMd">
            <Radio
              checked={BRAND_MARKET === pageView}
              label={brandMarketLabel}
              onChange={() => setPageView(BRAND_MARKET)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function activateUrl({ connectionUrl }, label) {
  return {
    label,
    url: connectionUrl,
  };
}

function deactivateUrl({
  brandPageId,
  brandPageName,
  companyName,
  recordId,
}) {
  const confirmationMessage = 'Are you sure you want to deactivate '
    + `${brandPageName} (${brandPageId}) for ${companyName}?`;

  const url = updateStateSettingsLinkedPlatformAccountPath(
    recordId,
    {
      account_class: 'FacebookAdPage',
      state: 'inactive',
    },
  );

  return {
    confirmationMessage,
    label: 'Deactivate',
    method: 'post',
    url,
    warning: true,
  };
}

function generateItemActions(item) {
  let display;
  const { status } = item;

  if (status === 'lapsed') {
    display = {
      urls: [
        activateUrl(item, 'Connect'),
        deactivateUrl(item),
      ],
    };
  } else if (status === 'active') {
    display = {
      urls: [
        deactivateUrl(item),
      ],
    };
  } else if (status === 'inactive') {
    display = {
      urls: [
        activateUrl(item, 'Activate'),
      ],
    };
  }

  return {
    display,
    value: '',
  };
}

function generateDateLinked({ dateLinked }) {
  if (dateLinked) {
    return {
      value: dateLinked,
      format: 'date',
    };
  }

  return { value: 'N/A' };
}

function formatAllAdPages(adPages) {
  return adPages.map((item) => setItemElement({
    actions: generateItemActions(item),
    associatedId: { value: item.associatedId },
    brand: { value: item.brand },
    brandPageId: { value: item.brandPageId },
    brandPageName: { value: item.brandPageName },
    dateLinked: generateDateLinked(item),
    id: { value: `facebook_ad_page:${item.rowId}:${item.brandPageId}` },
    market: { value: item.market },
    status: {
      text: statusFormatter(item.status),
      value: item.status,
    },
  }));
}

function formatUniqueAdPages(adPages) {
  const uniqueBrandIds = [...new Set(adPages.map(({ brandPageId }) => brandPageId))];

  return uniqueBrandIds.map((id) => {
    const adAccounts = adPages.filter(({ brandPageId }) => brandPageId === id);
    const item = adAccounts[0];

    return setItemElement({
      actions: generateItemActions(item),
      associatedAccounts: { value: adAccounts.length.toString() },
      brandPageId: { value: id },
      brandPageName: { value: item.brandPageName },
      dateLinked: generateDateLinked(item),
      id: { value: `facebook_ad_page:${id}` },
      status: {
        text: statusFormatter(item.status),
        value: item.status,
      },
    });
  });
}

function formatAdPagesByStatus(rawAdPagesByStatus, view) {
  const statuses = Object.keys(rawAdPagesByStatus);
  const adPagesByStatus = {};

  statuses.forEach((status) => {
    const current = rawAdPagesByStatus[status];

    if (view === UNIQUE) {
      adPagesByStatus[status] = formatUniqueAdPages(current);
    } else {
      adPagesByStatus[status] = formatAllAdPages(current);
    }
  });

  return adPagesByStatus;
}

function FacebookAdPages({ adPagesByStatus }) {
  const params = getParams(window);
  const view = params.get('view') ?? 'unique';

  const [pageView, setPageView] = useState(view);

  useEffect(() => {
    setParam('view', pageView, params, window);
  }, [pageView]);

  const formattedAdPagesByStatus = formatAdPagesByStatus(adPagesByStatus, pageView);

  const tabDetails = tabs.map((tab) => {
    let icon;
    if (tab === 'lapsed') icon = <Icon color="red" name="exclamationCircle" />;

    return {
      key: tab,
      name: statusFormatter(tab),
      icon,
    };
  });

  return (
    <SearchableTable
      csvFileName="brand_pages"
      filterKeys={pageView === BRAND_MARKET ? filterKeys : []}
      getHeaders={(tab) => getHeaders(tab, pageView)}
      groupedItems={formattedAdPagesByStatus}
      header={getHeaderContent(pageView, setPageView)}
      pageStorageKey={pageStorageKey}
      tabDetails={tabDetails}
    />
  );
}

FacebookAdPages.propTypes = propTypes;

export default FacebookAdPages;
