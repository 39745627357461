import React from 'react';

function ScoreInfoNotice() {
  return (
    <div className="t-body-2">
      Our system is now calculating your new score using the applicable guidelines
      and their weights. The calculation time depends on your company&apos;s quantity of data.
      You&apos;ll receive an email once the procedure is complete and your results are available.
    </div>
  );
}

export default ScoreInfoNotice;
