import React from 'react';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
} from '@duik/it';
import BarChart, { chartProps as BarChartProps } from 'components/charts/BarChart';
import TabbedChart from 'components/charts/TabbedChart';
import CardHeader from 'components/reusable/CardHeader';

// One of type 'chart' or 'charts' is required, but not both.
const propTypes = {
  title: PropTypes.string.isRequired,
  chart: PropTypes.shape(BarChartProps),
  charts: PropTypes.arrayOf(
    PropTypes.shape({
      chart: PropTypes.shape(BarChartProps),
      dataLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  subHeader: PropTypes.string,
  tooltipText: PropTypes.string,
};

const defaultProps = {
  chart: undefined,
  charts: undefined,
  subHeader: undefined,
  tooltipText: undefined,
};

function GuidelineAdoption(props) {
  const {
    chart,
    charts,
    subHeader,
    title,
    tooltipText,
  } = props;

  let chartContent;
  if (charts) {
    chartContent = (
      <TabbedChart
        charts={charts}
        type="bar"
        bodyContained
        fixedSize
        staticLayout
      />
    );
  } else {
    chartContent = (
      <WidgetContent>
        <BarChart
          chart={chart}
          fixedSize
          staticLayout
        />
      </WidgetContent>
    );
  }

  return (
    <Widget className="auditReport-chart">
      <CardHeader
        header={title}
        subHeader={subHeader}
        tooltip={tooltipText}
      />
      { chartContent }
    </Widget>
  );
}

GuidelineAdoption.propTypes = propTypes;
GuidelineAdoption.defaultProps = defaultProps;

export default GuidelineAdoption;
