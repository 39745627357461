import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Progress,
} from '@duik/it';
import {
  Divider,
  Radio,
} from '@makeably/creativex-design-system';
import Dv360AdvertiserSelect from 'components/account_setup/Dv360AdvertiserSelect';
import { HiddenInput } from 'components/reusable/HiddenInput';
import Select from 'components/reusable/select.coffee';

const propTypes = {
  advertiserLevelOptions: PropTypes.shape({
    brandOptions: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    marketOptions: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
  advertisers: PropTypes.arrayOf(
    PropTypes.shape({
      campaign_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      linked: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      adLevel: PropTypes.bool,
    }),
  ).isRequired,
  campaignLevelOptions: PropTypes.shape({
    brandOptions: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    marketOptions: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
};

function Dv360SetupForm({
  advertisers,
  advertiserLevelOptions,
  campaignLevelOptions,
}) {
  const [accountSetupLevel, setAccountSetupLevel] = React.useState('advertiser');
  const [advertisersLoadingCampaigns, setAdvertisersLoadingCampaigns] = React.useState([]);
  const [advertisersWithCampaigns, setAdvertisersWithCampaigns] = React.useState([]);
  const [advertiserCampaignsLoaded, setAdvertiserCampaignsLoaded] = React.useState(false);
  const [advertiserCampaignFetchStarted, setAdvertiserCampaignFetchStarted] = React.useState(false);
  const [campaignFetchError, setCampaignFetchError] = React.useState(false);

  const removeFromList = (id) => {
    const updatedList = advertisersLoadingCampaigns.filter((advId) => advId !== id);
    setAdvertisersLoadingCampaigns(updatedList);
    if (updatedList.length === 0) {
      setAdvertiserCampaignsLoaded(true);
    }
  };

  const fetchCampaigns = async (advertiser) => {
    try {
      const response = await fetch(advertiser.campaign_url);
      const jsonData = await response.json();
      removeFromList(advertiser.id);

      const advertiserWithCampaigns = {
        adLevel: advertiser.linked && advertiser.adLevel,
        campaigns: jsonData.campaigns,
        id: advertiser.id,
        name: advertiser.name,
      };

      setAdvertisersWithCampaigns((prevState) => ([...prevState, advertiserWithCampaigns]));
    } catch (error) {
      setCampaignFetchError(true);
      removeFromList(advertiser.id);
    }
  };

  const handleRadioSelect = (e) => {
    const setupLevelSelection = e.target.value;
    setAccountSetupLevel(setupLevelSelection);
    setAdvertisersLoadingCampaigns(advertisers.map((adv) => adv.id));
    setAdvertiserCampaignFetchStarted(true);

    if (setupLevelSelection !== 'advertiser' && !advertiserCampaignsLoaded) {
      setAdvertiserCampaignsLoaded(true);
      advertisers.forEach((adv) => {
        fetchCampaigns(adv);
      });
    }
  };

  const generateAccountLevelLabel = () => {
    const label = 'My account is set up at the Advertiser level';
    const campaignsNotLoaded = advertiserCampaignFetchStarted && !advertiserCampaignsLoaded;
    const notice = campaignsNotLoaded ? ' (Please wait for campaigns to load before switching back to Advertiser level)' : '';
    return (
      <div>
        { label }
        <b>{ notice }</b>
      </div>
    );
  };

  const renderALAdvertiser = (advertiser) => {
    const advertiserClass = classNames(
      'dv360AdsSetup-itemName',
      { 'dv360AdsSetup-itemName--linked': advertiser.linked },
    );

    return (
      <div key={advertiser.id} className="dv360AdsSetup-advertisers u-flexRow">
        <p className={advertiserClass}>{ `${advertiser.name}(${advertiser.id})` }</p>
        <HiddenInput name="advertisers[][id]" value={advertiser.id} />
        <Select
          disabled={advertiser.linked}
          inputName="advertisers[][market]"
          label="Market"
          options={advertiserLevelOptions.marketOptions}
          wrapperClass="u-marginLeft"
          onChange={() => {
          }}
        />
        <Select
          disabled={advertiser.linked}
          inputName="advertisers[][brand]"
          label="Brand"
          options={advertiserLevelOptions.brandOptions}
          wrapperClass="u-marginLeft"
          onChange={() => {
          }}
        />
      </div>
    );
  };

  const renderCLAdvertiser = (advertiser) => {
    if (advertisersWithCampaigns && advertiser.campaigns.length === 0) return null;

    return (
      <div key={advertiser.id} className="dv360AdsSetup-advertiser u-flexColumn">
        <Dv360AdvertiserSelect
          adLevel={advertiser.adLevel}
          brandOptions={campaignLevelOptions.brandOptions}
          campaigns={advertiser.campaigns}
          id={advertiser.id}
          marketOptions={campaignLevelOptions.marketOptions}
          name={advertiser.name}
        />
      </div>
    );
  };

  const renderAdvertiserForm = () => {
    if (accountSetupLevel === 'advertiser') {
      return (
        <div>
          <h5>Advertisers</h5>
          { advertisers.map(renderALAdvertiser) }
        </div>
      );
    }
    if (advertiserCampaignsLoaded) {
      return (
        <div>
          <h5>Advertisers</h5>
          { campaignFetchError && 'Encountered errors fetching Campaign level info. Please contact support for assistance.' }
          <div className="dv360AdsSetup-advertisers u-flexRow u-flexWrap">
            { advertisersWithCampaigns.map(renderCLAdvertiser) }
          </div>
        </div>
      );
    }
    return (
      <div>
        <h5> Loading your campaigns </h5>
        <Progress fill={1 - (advertisersLoadingCampaigns.length / advertisers.length)} />
      </div>
    );
  };

  return (
    <div>
      <HiddenInput
        name="campaign_level"
        value={accountSetupLevel === 'campaign'}
      />
      <div className="u-flexColumn">
        <Radio
          checked={accountSetupLevel === 'advertiser'}
          disabled={advertiserCampaignFetchStarted && !advertiserCampaignsLoaded}
          label={generateAccountLevelLabel()}
          value="advertiser"
          onChange={handleRadioSelect}
        />
        <Radio
          checked={accountSetupLevel === 'campaign'}
          label="My account is set up at the Campaign level"
          value="campaign"
          onChange={handleRadioSelect}
        />
      </div>
      <Divider margin />
      { renderAdvertiserForm() }
    </div>
  );
}

Dv360SetupForm.propTypes = propTypes;

export default Dv360SetupForm;
