import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
} from '@makeably/creativex-design-system';
import StickyTop from 'components/atoms/StickyTop';
import ActivationCard, { activationProps } from 'components/creative_lifecycle/ActivationCard';
import CoreAssetDetails, { coreAssetProps } from 'components/creative_lifecycle/CoreAssetDetails';
import ActionButton from 'components/molecules/ActionButton';
import { addToast } from 'components/organisms/Toasts';
import { removeProperty } from 'utilities/object';
import { getAuthenticityToken } from 'utilities/requests';
import { unmatchCreativeLifecycleCoreAssetsPath } from 'utilities/routes';
import styles from './RemoveActivations.module.css';

const propTypes = {
  activationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.node.isRequired,
    }),
  ).isRequired,
  coreAsset: PropTypes.shape(coreAssetProps).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape(activationProps),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

// @note: returns the count of failed assets, 0 = success
async function postUnmatch(coreAssetUuid, assetIds) {
  try {
    const url = unmatchCreativeLifecycleCoreAssetsPath();

    const formData = new FormData();
    formData.append('core_asset_uuid', coreAssetUuid);
    formData.append('asset_ids', JSON.stringify(assetIds));
    formData.append('authenticity_token', getAuthenticityToken());

    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    const result = await response.json();

    if (!response.ok) {
      return assetIds.length;
    }
    return result.error?.length ?? 0;
  } catch (e) {
    return assetIds.length;
  }
}

function RemoveActivations({
  items,
  activationDetails,
  coreAsset,
  onClose,
}) {
  const [selectedIds, setSelectedIds] = useState({});
  const [active, setActive] = useState(false);
  const selectedCount = Object.values(selectedIds).length;

  const handleSelect = (id) => {
    setSelectedIds((obj) => {
      if (obj[id]) {
        return removeProperty(obj, id);
      }
      return {
        ...obj,
        [id]: id,
      };
    });
  };

  const handleRemove = async () => {
    const assetIds = Object.values(selectedIds);
    setActive(true);

    const failedCount = await postUnmatch(coreAsset.uuid, assetIds);
    const okCount = assetIds.length - failedCount;

    if (okCount) {
      addToast(`(${okCount}) Activations sucessfully removed!`);
    }
    if (failedCount) {
      addToast(`(${failedCount}) Activations failed to remove!`, { type: 'error' });
    }
    setSelectedIds({});
    setActive(false);
    onClose();
    window.location.reload();
  };

  const renderActivations = () => {
    if (items.length === 0) {
      return (
        <>
          <Divider />
          <div className={`t-empty ${styles.empty}`}>
            This core asset has no activations to remove.
          </div>
        </>
      );
    }

    return (
      <div className={styles.cards}>
        { items.map((item) => {
          const id = item.id.value;

          return (
            <button
              key={id}
              type="button"
              onClick={() => handleSelect(id)}
            >
              <ActivationCard
                item={item}
                selected={Boolean(selectedIds[id])}
              />
            </button>
          );
        }) }
      </div>
    );
  };

  return (
    <>
      <StickyTop>
        <CoreAssetDetails
          activationDetails={activationDetails}
          coreAsset={coreAsset}
          isEditing
        />
        <div className={styles.controls}>
          <h5>Select the assets below that you would like to remove from activations.</h5>
          <div className={styles.buttons}>
            <ActionButton
              active={active}
              disabled={selectedCount === 0}
              label={`Remove Selected (${selectedCount})`}
              variant="secondary"
              onClick={handleRemove}
            />
            <Button
              label="Cancel"
              onClick={onClose}
            />
          </div>
        </div>
      </StickyTop>
      { renderActivations() }
    </>
  );
}

RemoveActivations.propTypes = propTypes;

export default RemoveActivations;
