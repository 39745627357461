import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import HorizontalGauge from "components/graphs/horizontal_gauge"

# responsible for rendering a gauge with a label (number).
# renders a positive and negative gauge for performance comparison, just positive for frequency
class GapGauge extends React.Component
  @propTypes =
    gaugeNumber: PropTypes.number
    gaugePercent: PropTypes.number
    gaugeType: PropTypes.oneOf(['frequency', 'performance'])
    title: PropTypes.string

  # Visual indicator on performance gauge. Placed at the center of the guage.
  renderPerformanceTick: ->
    if @props.gaugeType == 'performance'
      `<div className="gapGauge--middlePerformanceTick"/>`

  classes: (extraClasses) ->
    classNames(
      "gapGauge",
      "gapGauge--#{@props.gaugeType}",
      @props.className,
      extraClasses
    )

  render: ->
    if @props.gaugeType == 'performance'
      color_class = if @props.gaugeNumber > 0 then 'gapGauge--positive' else 'gapGauge--negative'
      # performance formatting has +/- for number e.g. +13% or -20%
      number = FormatUtil.forPercent(@props.gaugeNumber, true)
    else
      # frequency formatting is just a percent e.g. 13%
      number = FormatUtil.forPercent(@props.gaugeNumber)

    `<div className={this.classes(color_class)}>
      <div className='gapGauge--graph'>
        <p className='gapGauge--groupLabel'> {this.props.title} </p>
        {this.renderPerformanceTick()}
        <HorizontalGauge
          percent={this.props.gaugePercent}
          useDefaultStyles={false}
          />
      </div>
      <div className='gapGauge--number'>
        <p> {number} </p>
      </div>
    </div>`

export default GapGauge
