import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  TextInput,
} from '@makeably/creativex-design-system';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { createJobsAdminLogoElementPath } from 'utilities/routes';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
  label: PropTypes.string,
};

const defaultProps = {
  id: undefined,
  label: undefined,
};

const formName = 'create_jobs_form';

function CreateJobsModal({
  id,
  isOpen,
  label,
  onClose,
}) {
  const [jobNumber, setJobNumber] = useState(100);

  return (
    <Modal
      actionButtonDisabled={jobNumber <= 0}
      actionButtonLabel="Create Jobs"
      isOpen={isOpen}
      size="medium"
      onActionButtonClick={() => document[formName].submit()}
      onClose={onClose}
    >
      <h4>{ `Create Asset Jobs for ${label}` }</h4>
      <div className="u-marginTop-16">
        <form action={createJobsAdminLogoElementPath(id)} method="post" name={formName}>
          <AuthenticityTokenInput />
          <TextInput
            label="Job Number"
            name="asset_count"
            size="medium"
            type="number"
            value={jobNumber}
            onChange={(value) => setJobNumber(value)}
          />
        </form>
      </div>
    </Modal>
  );
}

CreateJobsModal.propTypes = propTypes;
CreateJobsModal.defaultProps = defaultProps;

export default CreateJobsModal;
