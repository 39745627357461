import React from "react"
import Button from "components/reusable/button"
import Card from "components/reusable/card"
import PropTypes from "prop-types"
import classNames from "classnames"
import Column from "components/reusable/column"
import ImageCardWithMetrics from "components/category/image_card_with_metrics"
import Row from "components/reusable/row"

# This is responsible for displaying a card with a collection of
# best performing categories by a grouping (ex. classification)
class TopCategoriesPerGroup extends React.Component
  @propTypes =
    categories: PropTypes.arrayOf(
      PropTypes.shape(
        categoryCardProps: PropTypes.object,
        groupLabel: PropTypes.string
      )
    ).isRequired
    linkUrl: PropTypes.string
    title: PropTypes.string

  renderCategory: (category) ->
    `<div key={_.uniqueId("top-category")}>
      <h5 className="topPerformingCategories-groupLabel truncate">{category.groupLabel}</h5>
      <ImageCardWithMetrics {...category.categoryCardProps}/>
    </div>`

  renderTitle: ->
    if @props.title
      `<Row>
        <Column>
          <h5>{this.props.title}</h5>
        </Column>
      </Row>`

  renderSeeMoreButton: ->
    if @props.linkUrl
      `<Row>
        <Column styleName='center-align'>
          <Button label='See More' link={this.props.linkUrl}/>
        </Column>
      </Row>`

  render: ->
    # NB: Formatting the layout of the flexbox items with spaceAround and spaceBetween
    #     if you have more than 3 categories, the container is scrollable so we need the
    #     items to line up with the left edge of the container. SpaceBetween will do this.
    #     SpaceAround will center the itmes with left and right padding and is ideal for
    #     a case with a small amount of categories
    classes = classNames(
      'topPerformingCategories-list',
      'topPerformingCategories-list--spaceAround': @props.categories.length <= 3,
      'topPerformingCategories-list--spaceBetween': @props.categories.length > 3
    )

    `<Card>
      {this.renderTitle()}
      <Row>
        <Column className={classes}>
          {this.props.categories.map(this.renderCategory)}
        </Column>
      </Row>
      {this.renderSeeMoreButton()}
    </Card>`

export default TopCategoriesPerGroup
