import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@duik/it';
import BulkAssociation from 'components/account_setup/shared_steps/BulkAssociation';
import Form from 'components/account_setup/shared_steps/Form';
import ItemAssociation from 'components/account_setup/shared_steps/ItemAssociation';
import ObjectHelper from 'components/utils/ObjectHelper';
import {
  pluralize,
  titleize,
} from 'utilities/string';

/* eslint-disable react/forbid-prop-types */
const propTypes = {
  brandOptions: PropTypes.array.isRequired,
  formData: PropTypes.object.isRequired,
  inputName: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  marketOptions: PropTypes.array.isRequired,
  partnerOptions: PropTypes.array.isRequired,
  onFormAction: PropTypes.func.isRequired,
};

function setDefaultProperties(selectors) {
  const properties = {};
  selectors.forEach(({ name }) => {
    properties[name] = { value: undefined };
  });
  return properties;
}

function generateDefaultItems(defaultItems) {
  return defaultItems.map((item) => ({
    ...item,
    brand: undefined,
    checked: false,
    complete: false,
    market: undefined,
    partner: undefined,
  }));
}

function Association({
  brandOptions,
  formData,
  inputName,
  items: defaultItems,
  marketOptions,
  onFormAction,
  partnerOptions,
  itemName,
}) {
  const selectors = [
    {
      label: 'Brand',
      name: 'brand',
      options: ObjectHelper.arraysToObjects(brandOptions),
    },
    {
      label: 'Market',
      name: 'market',
      options: ObjectHelper.valuesToObjects(marketOptions),
    },
    {
      label: 'Partner',
      name: 'partner',
      options: ObjectHelper.arraysToObjects(partnerOptions),
    },
  ];

  const [items, setItems] = useState(generateDefaultItems(defaultItems));
  const [bulkProperties, setBulkProperties] = useState(setDefaultProperties(selectors));

  useEffect(() => {
    onFormAction(items.every(({ complete }) => complete));
  }, [items]);

  const onItemCheck = (event, id) => {
    setItems(items.map((item) => {
      if (id === item.value) {
        return {
          ...item,
          checked: event.target.checked,
        };
      }

      return item;
    }));
  };

  const isItemComplete = (item) => [
    item.brand,
    item.market,
    item.partner,
  ].every((attr) => attr);

  const onBulkPropertySelect = (option, name) => (
    setBulkProperties({
      ...bulkProperties,
      [name]: option,
    })
  );

  const onPropertySelect = (option, name, id) => {
    setItems(items.map((item) => {
      if (item.value === id) {
        const updatedItem = {
          ...item,
          [name]: option,
        };

        return {
          ...updatedItem,
          complete: isItemComplete(updatedItem),
        };
      }

      return item;
    }));
  };

  const completedItems = items.filter(({ complete }) => complete);

  const counts = {
    selectedCount: items.filter(({ checked }) => checked).length,
    totalCount: items.length,
  };

  const onSelectAll = (checked) => setItems(items.map((item) => ({
    ...item,
    checked,
  })));

  const onBulkApply = ({
    brand, market, partner,
  }) => {
    setItems(items.map((item) => {
      if (!item.checked) return { ...item };

      const updatedItem = {
        ...item,
        checked: false,
        brand: brand.value ? brand : item.brand,
        market: market.value ? market : item.market,
        partner: partner.value ? partner : item.partner,
      };

      return {
        ...updatedItem,
        complete: isItemComplete(updatedItem),
      };
    }), setBulkProperties(setDefaultProperties(selectors)));
  };

  const inputKeys = [
    {
      input: 'organization_brand_id',
      key: 'brand',
    },
    { key: 'market' },
    { key: 'name' },
    {
      input: 'organization_affiliate_id',
      key: 'partner',
    },
    { key: 'value' },
  ];

  return (
    <>
      <BulkAssociation
        counts={counts}
        itemName={itemName}
        properties={bulkProperties}
        selectAllChecked={items.every(({ checked }) => checked)}
        selectors={selectors}
        onApply={onBulkApply}
        onPropertySelect={onBulkPropertySelect}
        onSelectAll={onSelectAll}
      />
      <Divider margin />
      <div>
        <div className="t-caption-1 u-marginBelow u-flexRow u-justifyFlexEnd">
          <div>
            { `${pluralize(titleize(itemName))} Assigned: ${completedItems.length} of ${items.length}` }
          </div>
        </div>
        {
          items.map((item) => (
            <ItemAssociation
              key={item.value}
              item={item}
              itemName={itemName}
              selectors={selectors}
              onCheck={(e) => onItemCheck(e, item.value)}
              onPropertySelect={(option, name) => onPropertySelect(option, name, item.value)}
            />
          ))
        }
      </div>
      <Form
        formData={formData}
        inputKeys={inputKeys}
        inputName={inputName}
        items={completedItems}
      />
    </>
  );
}

Association.propTypes = propTypes;

export default Association;
