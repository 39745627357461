import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  Table,
  Tag,
  TextInput,
} from '@makeably/creativex-design-system';
import ChannelLogo from 'components/atoms/ChannelLogo';
import { guidelineProps } from 'components/scores/modal/ScoreModal';
import { sortObjectArray } from 'utilities/array';
import { toStringWithDecimals } from 'utilities/number';
import styles from './ChannelGuidelinesTable.module.css';

const propTypes = {
  channelKey: PropTypes.string.isRequired,
  channelLabel: PropTypes.string.isRequired,
  guidelines: PropTypes.arrayOf(
    PropTypes.shape(guidelineProps),
  ).isRequired,
  isEditable: PropTypes.bool,
  isWeightUpdate: PropTypes.bool,
  onWeightChange: PropTypes.func,
};

const defaultProps = {
  isEditable: false,
  isWeightUpdate: false,
  onWeightChange: undefined,
};

const headers = [
  { value: 'Name' },
  { value: 'Description' },
  { value: 'Weight' },
];

function getWeightValue(current, initial, isWeightUpdate) {
  if (isWeightUpdate && current !== initial) {
    return (
      <div className="u-flexRow u-gap-8">
        { `${toStringWithDecimals(initial)}%` }
        <Icon name="arrowRight" />
        { `${toStringWithDecimals(current)}%` }
      </div>
    );
  }

  return `${toStringWithDecimals(current)}%`;
}

function renderGuidelineRow(
  guideline,
  onChange,
  channel,
  initialWeightSum,
  currentWeightSum,
  isEditable,
  isWeightUpdate,
) {
  const {
    description,
    id,
    initialSelected,
    initialWeight: initial,
    isActive,
    name,
    weight,
  } = guideline;

  const inactiveName = (
    <div className="u-flexRow u-alignCenter u-justifySpaceBetween u-gap-8">
      { name }
      <Tag label="Inactive" />
    </div>
  );

  const initialPercent = initialWeightSum > 0 ? (initial / initialWeightSum) * 100 : 0;
  const currentPercent = currentWeightSum > 0 ? (weight / currentWeightSum) * 100 : 0;

  const cells = [
    {
      className: styles.name,
      value: isActive ? name : inactiveName,
    },
    {
      className: styles.description,
      value: description || 'N/A',
    },
    { value: getWeightValue(currentPercent, initialPercent, isWeightUpdate) },
  ];

  if (isEditable) {
    cells.push({
      className: styles.input,
      value: (
        <TextInput
          min={0}
          type="number"
          value={weight}
          onChange={(newWeight) => onChange(channel, id, newWeight)}
        />
      ),
    });
  }

  return {
    cells,
    hasWeightChange: initialPercent !== currentPercent || !initialSelected,
    key: id.toString(),
  };
}

function ChannelGuidelinesTable({
  channelKey,
  channelLabel,
  guidelines,
  isEditable,
  isWeightUpdate,
  onWeightChange,
}) {
  const onReset = (channel, channelGuidelines) => {
    channelGuidelines.forEach(({ id }) => onWeightChange(channel, id));
  };

  const sectionHeaders = [...headers];
  if (isEditable) {
    sectionHeaders.push({
      value: (
        <div className="u-flexRow u-alignCenter u-gap-8">
          <div>Points</div>
          <button
            className={classNames(styles.resetButton, 't-overline')}
            type="button"
            onClick={() => onReset(channelKey, guidelines)}
          >
            Reset
          </button>
        </div>
      ),
    });
  }

  const initialSelectedGuidelines = guidelines.filter(({ initialSelected }) => initialSelected);
  const currentSelectedGuidelines = guidelines.filter(({ isSelected }) => isSelected);

  const initialWeightSum = initialSelectedGuidelines
    .reduce((sum, { initialWeight }) => sum + initialWeight, 0);
  const currentWeightSum = currentSelectedGuidelines
    .reduce((sum, { weight }) => sum + weight, 0);

  const rows = sortObjectArray(currentSelectedGuidelines, 'name')
    .map((guideline) => renderGuidelineRow(
      guideline,
      onWeightChange,
      channelKey,
      initialWeightSum,
      currentWeightSum,
      isEditable,
      isWeightUpdate,
    ))
    .filter(({ hasWeightChange }) => (isWeightUpdate ? hasWeightChange : true));

  if (rows.length === 0) return null;

  return (
    <>
      <div className="u-flexRow u-alignCenter u-gap-8">
        <ChannelLogo
          channel={channelKey}
          showPaid={false}
          size="size-32"
        />
        <h5>{ channelLabel }</h5>
      </div>
      <Table
        className={styles.table}
        headers={sectionHeaders}
        rows={rows}
      />
    </>
  );
}

ChannelGuidelinesTable.propTypes = propTypes;
ChannelGuidelinesTable.defaultProps = defaultProps;

export default ChannelGuidelinesTable;
