import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Chart from 'components/molecules/Chart';
import { formatTickLabel } from 'utilities/chart';
import { getCssVars } from 'utilities/css';
import {
  getItemConcise,
  getItemLabel,
  itemProps,
} from 'utilities/item';

const propTypes = {
  barKey: PropTypes.string.isRequired,
  displayKey: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(itemProps).isRequired,
  dataType: PropTypes.string,
  displayLabel: PropTypes.string,
  groupKey: PropTypes.string,
};

const defaultProps = {
  dataType: 'count',
  displayLabel: undefined,
  groupKey: undefined,
};

const AXIS_WIDTH = 48;
const BAR_WIDTH = 48;
const BAR_GAP = 4;
const BAR_LABEL_PADDING = 24;
const BAR_LABEL_SIZE = 12;
const BAR_PERCENTAGE = BAR_WIDTH / (BAR_WIDTH + BAR_GAP);
const GROUP_PADDING = 24;
const LABEL_WIDTH = 24;

function getBarColor(barColors, index, total) {
  switch (total) {
    case 4:
    case 3:
      return barColors[index];
    case 2:
      return barColors[2 * index];
    default:
      return barColors[2];
  }
}

function getBarsWidth(barCount) {
  return barCount * BAR_WIDTH + (barCount - 1) * BAR_GAP;
}

function getGroupWidth(barCount) {
  return 2 * GROUP_PADDING + getBarsWidth(barCount);
}

function getChartWidth({ bars, groups }, displayLabel) {
  const axisSize = AXIS_WIDTH + (displayLabel ? LABEL_WIDTH : 0);
  const dataSize = groups.length * getGroupWidth(bars.length);

  return axisSize + dataSize;
}

function getGroups(allItems, groupKey) {
  const groupsMap = Map.groupBy(allItems, (item) => getItemLabel(item, groupKey));

  return [...groupsMap.entries()].map(([label, items]) => ({
    label,
    items,
  }));
}

function getBarLabels(items, barKey) {
  const fullSet = items.reduce((set, item) => (
    set.add(getItemLabel(item, barKey))
  ), new Set());

  return [...fullSet.values()];
}

function getBarItems(groups, barKey, barLabel) {
  return groups.map(({ items }) => (
    items.find((item) => getItemLabel(item, barKey) === barLabel) ?? {}
  ));
}

function getData(items, barKey, displayKey, groupKey) {
  const barColors = getCssVars([
    '--purple-200',
    '--purple-300',
    '--purple-400',
    '--purple-500',
  ]);
  const groups = getGroups(items, groupKey);
  const barLabels = getBarLabels(items, barKey);
  const bars = barLabels.map((barLabel, index) => {
    const barItems = getBarItems(groups, barKey, barLabel);

    return {
      barLabel,
      color: getBarColor(barColors, index, barLabels.length),
      displayValues: barItems.map((item) => item[displayKey]?.value),
      displayLabels: barItems.map((item) => getItemConcise(item, displayKey)),
    };
  });

  return {
    groups,
    bars,
  };
}

function getChartData({ groups, bars }) {
  return {
    labels: groups.map(({ label }) => label),
    datasets: bars.map(({
      barLabel,
      color,
      displayLabels,
      displayValues,
    }) => ({
      barPercentage: BAR_PERCENTAGE,
      backgroundColor: color,
      borderRadius: 3,
      categoryPercentage: getBarsWidth(bars.length) / getGroupWidth(bars.length),
      data: displayValues,
      label: barLabel,
      tooltips: displayLabels,
    })),
  };
}

function getChartOptions(dataType, displayLabel) {
  return {
    layout: {
      padding: {
        top: BAR_LABEL_PADDING,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        font: {
          size: BAR_LABEL_SIZE,
        },
        formatter: (value, context) => {
          if (typeof value === 'undefined') {
            return `No Data\n${context.dataset.label}`;
          }
          return context.dataset.label;
        },
        textAlign: 'center',
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => context.dataset.tooltips[context.dataIndex],
          title: () => null,
        },
        displayColors: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: dataType === 'percent' ? 1 : null,
        ticks: {
          callback: (value) => formatTickLabel(value, dataType),
          count: 5,
        },
        title: {
          display: Boolean(displayLabel),
          text: displayLabel,
        },
      },
    },
  };
}

function ItemsGroupedBarChart({
  barKey,
  dataType,
  displayKey,
  displayLabel,
  items,
  groupKey,
}) {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    // @note: only recreate the chart when data changes so it doesn't re-run the animation
    const data = getData(items, barKey, displayKey, groupKey);
    const chartData = getChartData(data);
    const chartOptions = getChartOptions(dataType, displayLabel);
    const height = '100%';
    const width = getChartWidth(data, displayLabel);

    setChart(
      <div style={{
        height,
        width,
      }}
      >
        <Chart
          data={chartData}
          options={chartOptions}
          type="bar"
        />
      </div>,
    );
  }, [
    barKey,
    displayKey,
    items,
    groupKey,
  ]);

  return chart;
}

ItemsGroupedBarChart.propTypes = propTypes;
ItemsGroupedBarChart.defaultProps = defaultProps;

export default ItemsGroupedBarChart;
