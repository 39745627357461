import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import SelectableTableCell from 'components/molecules/SelectableTableCell';
import styles from './TableRowItem.module.css';

export const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.node).isRequired,
  disabled: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  disabled: false,
  onClick: undefined,
  selectable: false,
  selected: false,
};

class TableRowItem extends React.Component {
  constructor(props) {
    super(props);

    // @note: This is to ensure that the columns have unique keys. There is no
    // guarantee that index will work especially if we ever sort the table
    const { columns } = props;
    this.columns = columns.map((column) => ({
      uid: uuidv4(),
      value: column,
    }));
  }

  render() {
    const {
      disabled,
      selectable,
      selected,
      onClick,
    } = this.props;

    let selectableCell;
    if (selectable) {
      selectableCell = (
        <SelectableTableCell
          checked={selected}
          disabled={disabled}
          onChange={() => onClick()}
        />
      );
    }

    return (
      <tr className={classNames({ [styles['tableRowItem--disabled']]: disabled })}>
        { selectableCell }
        { this.columns.map(({ uid, value }) => <td key={uid}>{ value }</td>) }
      </tr>
    );
  }
}

TableRowItem.propTypes = propTypes;
TableRowItem.defaultProps = defaultProps;

export default TableRowItem;
