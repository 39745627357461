import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@makeably/creativex-design-system';
import VideoInfo from 'components/atoms/VideoInfo';
import UuidDisplay from 'components/molecules/UuidDisplay';
import { itemValueProps } from 'utilities/item';
import { adminCompanyCoreAssetPath } from 'utilities/routes';
import styles from './Asset.module.css';

const propTypes = {
  companyId: PropTypes.number.isRequired,
  item: PropTypes.shape({
    assetType: itemValueProps.isRequired,
    brand: itemValueProps.isRequired,
    count: itemValueProps.isRequired,
    id: itemValueProps.isRequired,
    url: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
    videoLength: PropTypes.shape({
      value: PropTypes.number,
    }).isRequired,
  }).isRequired,
  urlParams: PropTypes.objectOf(PropTypes.string).isRequired,
};

function Asset({
  companyId,
  item,
  urlParams,
}) {
  const id = item.id?.value;
  const url = adminCompanyCoreAssetPath(companyId, id, urlParams);

  return (
    <Card className={styles.card} padding={false}>
      <a href={url}>
        <div className={styles.image}>
          <img alt="Core Asset Preview" src={item.url.value} />
          <VideoInfo length={item.videoLength?.value} />
        </div>
      </a>
      <div className={styles.details}>
        <div>
          <div className="t-label-1">Core Asset ID</div>
          <h5><UuidDisplay url={url} uuid={id} /></h5>
        </div>
        <div>
          <div className="t-label-1">Activations</div>
          <h5>{ item.count?.value }</h5>
        </div>
      </div>
    </Card>
  );
}

Asset.propTypes = propTypes;

export default Asset;
