import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Tag.module.css';

const propTypes = {
  text: PropTypes.node.isRequired,
  style: PropTypes.oneOf(['red']),
};

const defaultProps = {
  style: undefined,
};

function Tag({ style, text }) {
  const classes = classnames(
    styles.tag,
    {
      [styles.tagRed]: style === 'red',
    },
  );

  return (
    <div className={classes}>
      <div className="t-caption-1">
        { text }
      </div>
    </div>
  );
}

Tag.propTypes = propTypes;
Tag.defaultProps = defaultProps;

export default Tag;
