import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import {
  Dashboard,
  useUppy,
} from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import { addToast } from 'components/organisms/Toasts';
import { logError } from 'utilities/logging';
import { getAuthenticityToken } from 'utilities/requests';

const CODEC_ERROR = 'DEMUXER_ERROR_NO_SUPPORTED_STREAMS';

const propTypes = {
  postUrl: PropTypes.string.isRequired,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
  authenticityToken: PropTypes.string,
  height: PropTypes.number,
  isPresignedUrl: PropTypes.bool,
  maxFileSize: PropTypes.number,
  maxFileUploads: PropTypes.number,
  maxVideoDuration: PropTypes.number,
  minFileSize: PropTypes.number,
  note: PropTypes.string,
  onNetworkError: PropTypes.func,
  onRemove: PropTypes.func,
  onSuccess: PropTypes.func,
  onUploadEnd: PropTypes.func,
  onUploadStart: PropTypes.func,
  onValidationError: PropTypes.func,
};

const defaultProps = {
  acceptedFileTypes: ['.mp4', '.mov', '.jpg', '.jpeg', '.gif', '.png', '.avi', '.wmv', '.qt'],
  authenticityToken: getAuthenticityToken(),
  height: 600,
  isPresignedUrl: false,
  maxFileSize: undefined,
  maxFileUploads: undefined,
  maxVideoDuration: undefined,
  minFileSize: 1,
  note: '',
  onNetworkError: () => {},
  onRemove: () => {},
  onSuccess: () => {},
  onUploadEnd: () => {},
  onUploadStart: () => {},
  onValidationError: () => {},
};

async function parseResponse(file, response) {
  // Cloned because response body is consumed by json()
  const responseCopy = response.clone();
  try {
    return await response.json();
  } catch (error) {
    if (responseCopy.status === 500) {
      addToast(
        'Something went wrong. Please reload the page and try again.',
        {
          size: 'large',
          type: 'error',
          duration: 10000,
        },
      );
    } else {
      logError('Uppy Parse Error', {
        fileName: file.name,
        fileSize: file.size,
        errorMessage: error.toString(),
        response: await responseCopy.text(),
        status: responseCopy.status,
      });
    }
    return null;
  }
}

function isMissingAttributes(file, data) {
  const hasMethod = data.method !== undefined;
  const hasUrl = data.url !== undefined;
  const hasFields = data.fields !== undefined;

  if (hasMethod && hasUrl && hasFields) {
    return false;
  }

  if (data.error?.toString().includes('Your session expired')) {
    addToast(
      'Something went wrong. Please reload the page and try again.',
      {
        size: 'large',
        type: 'error',
        duration: 10000,
      },
    );
  } else {
    logError('Uppy Missing Attribute Error', {
      fileName: file.name,
      fileSize: file.size,
      response: data,
    });
  }
  return true;
}

function captureVideoScreenshot(video) {
  const canvas = document.createElement('canvas');
  // set the canvas dimensions to be the same as the video
  // dimensions so that we can fit a full snapshot of the video
  // onto the canvas
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  const context = canvas.getContext('2d');
  context.drawImage(video, 0, 0);
  // generate a URL for the screenshot generated
  return canvas.toDataURL('image/jpeg');
}

// Generates and sets the thumbnail for videos
// from the first frame of the video
function generateVideoThumbnail(file, setThumbnail) {
  const url = window.URL || window.webkitURL;
  const fileURL = url.createObjectURL(file.data);

  const video = document.createElement('video');

  video.onseeked = (_e) => {
    const thumbnail = captureVideoScreenshot(video);
    setThumbnail(thumbnail);
  };

  // in order to take the screenshot the video
  // needs to be playing or set to a specific time
  video.onloadedmetadata = (_e) => {
    video.currentTime = 0; // triggers the seek event
  };

  video.preload = 'metadata';
  video.src = fileURL;
}

function validateImage(file, onValidate) {
  const img = document.createElement('img');

  const url = URL.createObjectURL(file.data);
  img.src = url;

  img.onload = () => {
    onValidate(false);
    URL.revokeObjectURL(url);
  };

  img.onerror = () => {
    onValidate(true);
    URL.revokeObjectURL(url);
  };
}

function validateVideo(file, onValidate) {
  const video = document.createElement('video');
  video.preload = 'metadata';

  const url = URL.createObjectURL(file.data);
  video.src = url;

  video.onloadedmetadata = () => {
    const { duration } = video;
    onValidate(false, duration);
    URL.revokeObjectURL(url);
  };

  video.onerror = () => {
    const mediaError = video.error;
    const errorType = mediaError.message.split(':')[0];
    // Allow videos to pass where the codec isn't supported in Chrome
    if (errorType === CODEC_ERROR) {
      onValidate(false);
    } else {
      onValidate(true);
    }
    URL.revokeObjectURL(url);
  };
}

function FileDrop({
  acceptedFileTypes,
  authenticityToken,
  height,
  isPresignedUrl,
  maxFileSize,
  maxFileUploads,
  maxVideoDuration,
  minFileSize,
  note,
  onNetworkError,
  onValidationError,
  onRemove,
  onSuccess,
  onUploadEnd,
  onUploadStart,
  postUrl,
}) {
  const uppyInstance = useUppy(() => {
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: acceptedFileTypes,
        maxNumberOfFiles: maxFileUploads,
        maxFileSize,
        minFileSize,
      },
    });

    uppy.setOptions({
      onBeforeFileAdded: (currentFile, _files) => {
        if (currentFile.name.charAt(0) === '.') {
          uppy.info('Your file name cannot begin with "."', 'error');
          return false;
        }

        return true;
      },
    });

    if (isPresignedUrl) {
      uppy.use(AwsS3, {
        // Increased from 30sec default to address timeout
        // issues on slower internet connections.
        timeout: 60 * 1000,
        // eslint-disable-next-line consistent-return
        getUploadParameters: async (file) => {
          try {
            // Send a request to the pretest upload signing endpoint
            const response = await fetch(postUrl, {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'X-CSRF-Token': authenticityToken,
              },
              body: JSON.stringify({
                filename: file.name,
              }),
            });

            // Parse pretest upload JSON response
            const data = await parseResponse(file, response);
            if (!data || isMissingAttributes(file, data)) return null;

            // Return an object in the right shape for S3
            return {
              method: data.method,
              url: data.url,
              fields: data.fields,
              // Provide content type header required by S3
              headers: { 'Content-Type': file.type },
            };
          } catch (error) {
            if (!error.toString().includes('NetworkError')) {
              logError('Uppy Fetch Error', {
                fileName: file.name,
                fileSize: file.size,
                errorMessage: error.toString(),
              });
            }
            return null;
          }
        },
      });
    } else {
      uppy.use(XHRUpload, {
        endpoint: postUrl,
        fieldName: 'file',
        headers: { 'X-CSRF-Token': authenticityToken },
        timeout: 120 * 1000,
      });
    }

    uppy.on('upload', (data) => {
      // Fire this method when an upload is started.
      // data looks like { id, fileIDs } where id is the upload id
      // and fileIDs is an array of file ids included in the upload.
      onUploadStart(data);
    });

    uppy.on('complete', (results) => {
      // Fire this method when all uploads are complete.
      // results looks like { failed, successful } where both are arrays
      // of uppy file objects.
      // https://uppy.io/docs/uppy/#File-Objects
      onUploadEnd(results);
    });

    uppy.on('upload-success', (file, response) => {
      // Ensure that we pull the file from the state.
      // The file passed into this method is not guaranteed to be the same as
      // the version in the Uppy state.
      // We do this to ensure the preview is on the file object returned
      onSuccess(uppy.getFile(file.id), response.body.location);
    });

    uppy.on('file-added', (file) => {
      // uppy does not provide support for video thumbnails
      // generate and set video thumbnails
      const handleError = ({ id, name }) => {
        uppy.removeFile(id);
        onValidationError(`The file ${name} has errors and cannot be uploaded.`);
      };

      if (file.type.includes('video')) {
        validateVideo(file, (hasError, length) => {
          if (hasError) {
            handleError(file);
          } else if (length > maxVideoDuration) {
            uppy.removeFile(file.id);
            onValidationError(`${file.name} exceeds the 3 minute limit. Please trim or choose another video.`);
          } else {
            generateVideoThumbnail(file, (thumbnail) => {
              uppy.setFileState(file.id, { preview: thumbnail });
            });
          }
        });
      } else {
        validateImage(file, (hasError) => {
          if (hasError) {
            handleError(file);
          }
        });
      }
    });

    uppy.on('file-removed', (file) => {
      onRemove(file);
    });

    uppy.on('upload-error', (file, error) => {
      if (error.isNetworkError) {
        // Let your users know that file upload could have failed due to
        // firewall or ISP issues
        onNetworkError();
      } else if (['Upload stalled', 'Request has expired', 'timeout'].some((err) => error.toString().includes(err))) {
        addToast(
          'Something went wrong. Please reload the page and try again.',
          {
            size: 'large',
            type: 'error',
            duration: 10000,
          },
        );
      } else if (!error.toString().includes('Cancelled')) {
        logError('Uppy Upload Error', {
          errorMessage: error.toString(),
          filename: file.name,
          filesize: file.size,
        });
      }
    });

    return uppy;
  });

  useEffect(() => () => uppyInstance.close(), []);

  const locale = {
    strings: {
      dropPaste: 'Drag and drop files here, or %{browse}',
      browse: 'upload manually',
    },
  };

  return (
    <Dashboard
      height={height}
      id="dashboard"
      locale={locale}
      note={note}
      proudlyDisplayPoweredByUppy={false}
      showLinkToFileUploadResult={false}
      showRemoveButtonAfterComplete={false}
      uppy={uppyInstance}
      width="100%" // max width
      hideProgressAfterFinish
      inline
    />
  );
}

FileDrop.propTypes = propTypes;
FileDrop.defaultProps = defaultProps;

export default FileDrop;
