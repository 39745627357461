import {
  toConciseCount,
  toConciseSpend,
  toPercent,
} from 'utilities/string';

// Only round for non-percentage charts.
// Percent charts They always go from 0-100%
// Example values:
//  values:
//  [445, 4647, 123,  80,  989,   5]
//
//  resulting maxes:
//  [500, 5000, 200, 100, 1000, 100]
//
// NOTE: If you use ceil when determining the power of 10, you would end up
// with the following maxes for the values:
//  values:
//  [ 445,  4647,  123,  80,  989,   5]
//
//  resulting maxes:
//  [1000, 10000, 1000, 100, 1000, 100]
function chartMax(value, isPercent) {
  if (isPercent) return 1;

  // Find the power of ten that gives you the value
  const powerOfTen = Math.log10(value);

  // NOTE: Use floor which allows more granularity with the axes of the chart
  // and prevents the chart from having a max value of 445 but using 1000 as
  // the max axes value (this makes the chart much harder to read)
  const floorPowerOfTen = Math.floor(powerOfTen);

  const valToRoundTo = Math.max(100, (10 ** floorPowerOfTen));
  return Math.ceil(value / valToRoundTo) * valToRoundTo;
}

export function tickFormat(maxValue, isPercent = false) {
  const max = chartMax(maxValue, isPercent);

  return { stepSize: (max / 4.0) };
}

export function axisFormat(maxValue, isPercent = false) {
  const max = chartMax(maxValue, isPercent);

  return {
    beginAtZero: true,
    max,
    min: 0,
    suggestedMax: max,
    suggestedMin: 0,
  };
}

export function formatTickLabel(value, dataType) {
  switch (dataType) {
    case 'percent':
      return toPercent(value);
    case 'spend':
      return toConciseSpend(value);
    default:
      return toConciseCount(value);
  }
}
