import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetHeader,
  WidgetContent,
} from '@duik/it';
import { Icon } from '@makeably/creativex-design-system';
import styles from './CollapsibleWidget.module.css';

const propTypes = {
  children: PropTypes.node.isRequired,
  headerText: PropTypes.string.isRequired,
  bodyClassName: PropTypes.string,
  defaultCollapsed: PropTypes.bool,
};

const defaultProps = {
  bodyClassName: undefined,
  defaultCollapsed: true,
};

function CollapsibleWidget({
  bodyClassName,
  children,
  headerText,
  defaultCollapsed,
}) {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  const handleHeaderClick = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const iconName = isCollapsed ? 'chevronRight' : 'chevronDown';

  return (
    <Widget className={styles.widget}>
      <WidgetHeader className={styles.header} onClick={handleHeaderClick}>
        <h5>{ headerText }</h5>
        <Icon color="grey" name={iconName} />
      </WidgetHeader>
      { !isCollapsed && (
        <WidgetContent className={classNames(styles.body, bodyClassName)}>
          { children }
        </WidgetContent>
      ) }
    </Widget>
  );
}

CollapsibleWidget.propTypes = propTypes;
CollapsibleWidget.defaultProps = defaultProps;

export default CollapsibleWidget;
