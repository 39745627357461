import React from 'react';
import { AddNewButton } from '@makeably/creativex-design-system';
import { newLinkSettingsPartnersPath } from 'utilities/routes';

function InvitePartnerButton() {
  return (
    <a href={newLinkSettingsPartnersPath()}>
      <AddNewButton label="Invite Partner" />
    </a>
  );
}

export default InvitePartnerButton;
