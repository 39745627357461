import React from "react"
import Button from "components/reusable/button"
import Column from "components/reusable/column"
import Loader from "components/reusable/Loader"
import PropTypes from "prop-types"
import PostAssets from "components/reusable/post_assets"
import Row from "components/reusable/row"

# This component is responsible for displaying a the comparison of assets for two post groups
class AssetsComparisonContainer extends React.Component
  @propTypes =
    morePostsPath: PropTypes.string
    totalPages: PropTypes.number          # Max number of pages for postGroups
    postGroups: PropTypes.arrayOf(
      PropTypes.shape(
        id: PropTypes.number.isRequired
        metrics: PropTypes.array
        posts: PropTypes.array.isRequired
      )
    )

  constructor: (props) ->
    super(props)

    @state =
      isLoadingData: false        # Whether we are currently loading data. If so, don't change state.
      currentPage: 1              # Posts are paginated and first page has been rendered
      postGroups: props.postGroups

  renderPostGroupAssets: (postGroup) ->
    `<Column size={6} key={postGroup.id}>
      <PostAssets className='postGroupAssets-imageGrid' posts={postGroup.posts} />
    </Column>`

  renderLoadMore: =>
    if @props.morePostsPath and @state.currentPage < @props.totalPages
      if @state.isLoadingData
        content = `<Loader />`
      else
        content = `<Button label='See More' onClick={this.loadMore} className='see-more' />`
      `<Row>
        <Column styleName='no-padding' className='center'>
          {content}
        </Column>
      </Row>`

  loadMore: =>
    return if @state.isLoadingData                              # ignore multiple clicks

    @setState(isLoadingData: true)

    $.get(
      @props.morePostsPath,
      page: @state.currentPage + 1
    ).done((response) =>
      # NB: response is object of {some_id: posts, some_other_id: posts}
      updatedPostGroups = []

      for postGroup in @state.postGroups
        updatedPostGroup = $.extend(true, {}, postGroup)
        updatedPostGroup.posts = updatedPostGroup.posts.concat(response[postGroup.id])
        updatedPostGroups.push(updatedPostGroup)

      @setState(
        currentPage: (@state.currentPage + 1)
        postGroups: updatedPostGroups
      )
    ).fail( ->
      Materialize.toast("Oops! Something went wrong...", 5000)
    ).always(=>
      @setState(isLoadingData: false)
    )

  render: ->
    `<Row>
      <Column styleName='no-padding'>
        <Row>
          {this.state.postGroups.map(this.renderPostGroupAssets)}
        </Row>
        {this.renderLoadMore()}
      </Column>
    </Row>`

export default AssetsComparisonContainer
