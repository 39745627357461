// Renders the Form and Change Log for the Brand Consitency Scoring
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormGroupContainer,
  TextField,
  Widget,
  WidgetContent,
} from '@duik/it';
import CardHeader from 'components/reusable/CardHeader';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import UpdateMethodInput from 'components/reusable/forms/UpdateMethodInput';
import FormSubmit from 'components/reusable/FormSubmit';
import ChangeLog, { propTypes as ChangeLogProps } from 'components/rules/ChangeLog';

export const propTypes = {
  ...ChangeLogProps,
  editable: PropTypes.bool.isRequired,
  formUrl: PropTypes.string.isRequired,
  imageThreshold: PropTypes.number.isRequired,
  videoThreshold: PropTypes.number.isRequired,
};

function BrandConsistency(props) {
  const {
    editable,
    formUrl,
    imageThreshold,
    logs,
    videoThreshold,
  } = props;

  let formButton;
  if (editable) {
    formButton = (
      <FormGroup>
        <FormSubmit label="Update" trackingLabel="Update Brand Consistency Scores" />
      </FormGroup>
    );
  }

  return (
    <div className="consistencyScore">
      <Widget className="consistencyScore-card">
        <CardHeader
          header="Brand Consistency Minimum Thresholds"
          subHeader="Select the minimum number of brand cues that need to be present for a post to be considered 'branded'."
        />
        <WidgetContent>
          <form action={formUrl} method="post">
            <AuthenticityTokenInput />
            <UpdateMethodInput />
            <FormGroupContainer>
              <FormGroup>
                <TextField
                  defaultValue={imageThreshold}
                  disabled={!editable}
                  label={'Imagery - Branded Threshold\nEnter minimum # of brand cues for an image to be considered branded.'}
                  max={100}
                  min={0}
                  name="image_min"
                  type="number"
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  defaultValue={videoThreshold}
                  disabled={!editable}
                  label={'Video - Branded Threshold\nEnter minimum # of brand cues for a video to be considered branded.'}
                  max={100}
                  min={0}
                  name="video_min"
                  type="number"
                />
              </FormGroup>
              { formButton }
            </FormGroupContainer>
          </form>
        </WidgetContent>
      </Widget>
      <ChangeLog logs={logs} />
    </div>
  );
}

BrandConsistency.propTypes = propTypes;

export default BrandConsistency;
