import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Dropdown,
  Pagination,
  Tabs,
} from '@makeably/creativex-design-system';
import QueueTable from 'components/admin/review_queue/QueueTable';
import Filters, { filterProps } from 'components/filters/AdminAuditFilters';
import {
  editAdminLogoAssetJobUrl,
  adminLogoAssetJobsUrl,
} from 'utilities/routes';

const ASSET_JOBS_PER_PAGE = 25;

const assetJobProps = PropTypes.shape({
  brand: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  logo: PropTypes.string.isRequired,
  skipReason: PropTypes.string,
});

export const propTypes = {
  assetJobs: PropTypes.arrayOf(assetJobProps).isRequired,
  filters: filterProps.isRequired,
  orgs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  selectedFiltersWithState: PropTypes.shape({
    org_id: PropTypes.string.isRequired,
    state: PropTypes.oneOf(['pending', 'reviewed', 'unreviewable']).isRequired,
    brand_ids: PropTypes.arrayOf(PropTypes.string),
    label_ids: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const tabs = [
  {
    label: 'Needs Review',
    url: adminLogoAssetJobsUrl({ state: 'pending' }),
  },
  {
    label: 'Reviewed',
    url: adminLogoAssetJobsUrl({ state: 'reviewed' }),
  },
  {
    label: 'Skipped',
    url: adminLogoAssetJobsUrl({ state: 'unreviewable' }),
  },
];

function getPageJobs(jobs, page) {
  return jobs.slice((page - 1) * ASSET_JOBS_PER_PAGE, page * ASSET_JOBS_PER_PAGE);
}

function getHeaders(state) {
  const headers = [
    {
      value: 'id',
      label: 'ID',
    },
    {
      value: 'company',
      label: 'Company',
    },
    {
      value: 'brand',
      label: 'Brand',
    },
    {
      value: 'logo',
      label: 'Logo',
    },
    {
      value: 'skipReason',
      label: 'Skip Reason',
      filter: ['unreviewable'],
    },
  ];

  return headers.filter((header) => (
    header.filter ? header.filter.includes(state) : true
  ));
}

function renderOrganizationDropdown(orgs, filters) {
  const orgId = parseInt(filters.org_id);
  const options = orgs.map((org) => ({
    label: org.name,
    value: org.id,
  }));
  const activeOption = options.find((option) => option.value === orgId);

  const handleCompanyClick = (option) => {
    window.location.href = adminLogoAssetJobsUrl({
      ...filters,
      org_id: option.value,
    });
  };

  return (
    <Dropdown
      label="COMPANY"
      options={options}
      selected={activeOption}
      onChange={(option) => handleCompanyClick(option)}
    />
  );
}

function AssetJobs({
  assetJobs,
  filters,
  orgs,
  selectedFiltersWithState,
}) {
  const formRef = useRef(null);
  const count = assetJobs.length;

  const [page, setPage] = useState(1);
  const [pageJobs, setPageJobs] = useState(getPageJobs(assetJobs, page));

  const formattedAssetJobs = pageJobs.map((assetJob) => ({
    ...assetJob,
    link: editAdminLogoAssetJobUrl(assetJob.id, selectedFiltersWithState),
  }));

  useEffect(() => {
    setPageJobs(getPageJobs(assetJobs, page));
  }, [assetJobs, page]);

  return (
    <>
      <Tabs
        currentParams={window.location.search}
        currentUrl={window.location.pathname}
        tabs={tabs}
      />
      <div className="u-flexRow">
        <Filters
          ref={formRef}
          filters={filters}
          formUrl={adminLogoAssetJobsUrl()}
          loading={false}
          orgId={selectedFiltersWithState.org_id}
          viewState={selectedFiltersWithState.state}
        />
        <Card className="u-flexGrow">
          <div className="u-flexRow u-justifyFlexEnd">
            { renderOrganizationDropdown(orgs, selectedFiltersWithState) }
          </div>
          <QueueTable
            headers={getHeaders(selectedFiltersWithState.state)}
            items={formattedAssetJobs}
          />
          <div className="u-marginTop-16">
            <Pagination
              currentPage={page}
              perPage={ASSET_JOBS_PER_PAGE}
              total={count}
              onPageChange={setPage}
            />
          </div>
        </Card>
      </div>
    </>
  );
}

AssetJobs.propTypes = propTypes;

export default AssetJobs;
