import React from 'react';
import PropTypes from 'prop-types';
import {
  Header,
  Tabs,
} from '@makeably/creativex-design-system';
import AdminNavigation, { adminPropTypes } from 'components/navigation/AdminNavigation';
import Navigation, { navPropTypes } from 'components/organisms/Navigation';
import styles from './Page.module.css';

export const pageSizes = ['full', 'normal', 'large', 'largest'];
export const headerPropTypes = {
  label: PropTypes.string.isRequired,
  section: PropTypes.string,
  url: PropTypes.string,
};
export const tabPropTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
};
export const pagePropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  header: PropTypes.shape(headerPropTypes).isRequired,
  admin: PropTypes.shape(adminPropTypes),
  nav: PropTypes.shape(navPropTypes),
  size: PropTypes.oneOf(pageSizes),
  tabs: PropTypes.arrayOf(
    PropTypes.shape(tabPropTypes),
  ),
};
const defaultProps = {
  admin: undefined,
  nav: undefined,
  size: 'normal',
  tabs: undefined,
};

function getFullLabel(label, tabs) {
  if (tabs?.length === 1) {
    return `${label}: ${tabs[0].label}`;
  }

  return label;
}

function Page({
  admin,
  children,
  header,
  nav,
  size,
  tabs,
}) {
  const {
    label, section, url,
  } = header;
  const fullLabel = getFullLabel(label, tabs);

  const renderHeader = () => (
    <div className="page-header">
      { admin && (
        <AdminNavigation {...admin} />
      ) }
      <Header
        label={fullLabel}
        section={section}
        url={url}
      />
    </div>
  );

  // There are two headers in order to solve competing layout issues
  // where the header doesn't extend the full width of the page
  return (
    <div className={styles.page}>
      { nav && <Navigation {...nav} /> }
      <div className={styles.contentLayout} id="page-content">
        { renderHeader() }
        <div className={styles.contentWrap}>
          { renderHeader() }
          <div className={styles.contentMargin}>
            <div className={styles.content}>
              <div className={styles[size]}>
                { tabs && (
                  <Tabs
                    currentParams={window.location.search}
                    currentUrl={window.location.pathname}
                    tabs={tabs}
                  />
                ) }
                { children }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Page.propTypes = pagePropTypes;
Page.defaultProps = defaultProps;

export default Page;
