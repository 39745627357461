import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Stepper,
} from '@makeably/creativex-design-system';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { getItemSortBy } from 'utilities/item';
import {
  track,
  useViewPage,
} from 'utilities/mixpanel';
import { getAuthenticityToken } from 'utilities/requests';
import { newSettingsLinkedPlatformAccountPath } from 'utilities/routes';
import styles from './Confirmation.module.css';

const stepProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
});
const advertiserProps = PropTypes.shape({
  apiId: PropTypes.string.isRequired,
  brandId: PropTypes.number.isRequired,
  market: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  partnerId: PropTypes.number.isRequired,
});
const entityProps = PropTypes.shape({
  countryCode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
});
const propTypes = {
  advertisers: PropTypes.arrayOf(advertiserProps).isRequired,
  brandsById: PropTypes.objectOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
  entity: entityProps.isRequired,
  formUrl: PropTypes.string.isRequired,
  nextUrl: PropTypes.string.isRequired,
  partnersById: PropTypes.objectOf(PropTypes.string).isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
};

const headers = [
  {
    key: 'entityName',
    label: 'Entity Name',
  },
  {
    key: 'name',
    label: 'Advertiser Name',
  },
  {
    key: 'id',
    label: 'Advertiser ID',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'partner',
    label: 'Partner',
  },
];

function getItems(entity, advertisers, brandsById, partnersById) {
  return advertisers.map((advertiser) => ({
    id: { value: advertiser.apiId },
    brand: { value: brandsById[advertiser.brandId] },
    entityName: { value: entity.name },
    market: { value: advertiser.market },
    name: { value: advertiser.name },
    partner: { value: partnersById[advertiser.partnerId] },
  }));
}

async function submitAdvertisers(entity, advertisers, url) {
  const advertisersData = advertisers.map((advertiser) => ({
    api_id: advertiser.apiId,
    brand_id: advertiser.brandId,
    currency: advertiser.currency,
    market: advertiser.market,
    name: advertiser.name,
    partner_id: advertiser.partnerId,
  }));
  const entityData = {
    profile_id: entity.profileId,
  };

  const formData = new FormData();
  formData.append('advertisers', JSON.stringify(advertisersData));
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('entity', JSON.stringify(entityData));

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });
  return response.json();
}

function Confirmation({
  advertisers,
  brandsById,
  currentStep,
  entity,
  formUrl,
  nextUrl,
  partnersById,
  steps,
}) {
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState();
  const [sortedItems, setSortedItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useViewPage('confirmation');

  useEffect(() => {
    setItems(getItems(entity, advertisers, brandsById, partnersById));
  }, [entity, advertisers, brandsById, partnersById]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    } else {
      setSortedItems(items);
    }
  }, [items, sort]);

  const handleContinue = async () => {
    setIsSubmitting(true);
    const result = await submitAdvertisers(entity, advertisers, formUrl);
    addToast(result.message, { type: result.success ? 'success' : 'error' });
    if (result.success) {
      track('submit_success');
      window.location.href = nextUrl;
    }
  };

  return (
    <>
      <div className={styles.stepper}>
        <Stepper currentStep={currentStep} steps={steps} />
      </div>
      <div className={styles.instructions}>
        <h5>Confirm each Advertiser is associated with the correct brand and partner</h5>
      </div>
      <Card>
        <ItemsTable
          headers={headers}
          items={sortedItems}
          page={1}
          perPage={Math.Infinity}
          sort={sort}
          onPageChange={() => {}}
          onSortChange={(value) => setSort(value)}
        />
      </Card>
      <div className={styles.buttons}>
        <div className="buttonGroup">
          <Button
            label="Cancel"
            url={newSettingsLinkedPlatformAccountPath()}
            variant="secondary"
          />
          <Button
            disabled={isSubmitting}
            label="Continue"
            onClick={handleContinue}
          />
        </div>
      </div>
    </>
  );
}

Confirmation.propTypes = propTypes;

export default Confirmation;
