import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@duik/it';

const propTypes = {
  currentStep: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onDotClick: PropTypes.func.isRequired,
};

function renderDirectionButton(label, value, display, callback) {
  if (!display) return null;

  return (
    <Button clear onClick={(_event) => callback(value)}>
      { label }
    </Button>
  );
}

function CarouselDot({
  active, onChange, step,
}) {
  const classes = classNames(
    'carouselControls-dot',
    'u-textAlignCenter',
    { 'carouselControls-dot--active': active },
  );

  return <div aria-hidden="true" className={classes} onClick={(_event) => onChange(step)} />;
}
CarouselDot.propTypes = {
  active: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

function CarouselControls({
  currentStep, onClick, onDotClick,
}) {
  const steps = [1, 2, 3, 4];

  return (
    <div className="carouselControls u-flexRow u-spaceBetween u-flexAlignCenter">
      <div className="u-flexGrow previousBtn">
        { renderDirectionButton('Prev', -1, (currentStep > 1), onClick) }
      </div>
      <div className="carouselControls-dots u-flexRow u-justifyCenter">
        {
          steps.map((step) => (
            <CarouselDot
              key={step}
              active={currentStep === step}
              step={step}
              onChange={onDotClick}
            />
          ))
        }
      </div>
      <div className="u-flexGrow u-textAlignRight nextBtn">
        { renderDirectionButton('Next', 1, (currentStep < 4), onClick) }
      </div>
    </div>
  );
}
CarouselControls.propTypes = propTypes;

export default CarouselControls;
