import titleize from 'titleize';
import { tr } from 'locales/translate';

function getAccountsPageTab(metric) {
  switch (metric) {
    case 'needsReconnecting':
    case 'missingBrandPages':
      return 'lapsed';
    case 'activeAccounts':
    case 'activeBrandPages':
      return 'active';
    case 'inactiveAccounts':
      return 'inactive';
    default:
      return undefined;
  }
}

function convertToObjs(opts, defaults, isMetric) {
  return opts.map((opt, idx) => {
    const defaultObj = {
      default: defaults.includes(opt),
      label: tr(opt) || titleize(opt),
      order: idx + 1,
      value: opt,
    };

    if (isMetric) {
      return ({
        ...defaultObj,
        accountPageTab: getAccountsPageTab(opt),
        isBrandPage: opt.toLowerCase().includes('brandpages'),
      });
    }

    return defaultObj;
  });
}

function segments() {
  const defaults = ['brand', 'market'];
  const opts = ['all', 'brand', 'market', 'platform', 'partner', 'owner'];
  return convertToObjs(opts, defaults, false);
}

function metrics() {
  const defaults = [
    'needsReconnecting',
    'activeAccounts',
    'missingBrandPages',
    'totalSpend',
  ];

  const opts = [
    'all',
    'needsReconnecting',
    'activeAccounts',
    'inactiveAccounts',
    'totalAccounts',
    'missingBrandPages',
    'activeBrandPages',
    'totalSpend',
  ];

  return convertToObjs(opts, defaults, true);
}

export const segmentOptions = segments();
export const defaultSegments = segmentOptions.filter((opt) => opt.default);

export const metricOptions = metrics();
export const defaultMetrics = metricOptions.filter((opt) => opt.default);
