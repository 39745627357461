import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
} from '@duik/it';
import {
  Icon,
  Tooltip,
} from '@makeably/creativex-design-system';
import Tag from 'components/atoms/Tag';
import { formatItemText } from 'utilities/itemText';
import styles from './InformationCard.module.css';

export const propTypes = {
  infoItem: PropTypes.shape({
    delta: PropTypes.number,
    format: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  url: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: undefined,
  url: undefined,
};

function containerProps(url, onClick) {
  if (!url) return {};

  const props = { href: url };
  if (onClick) props.onClick = onClick;

  return props;
}

function renderDelta(value) {
  let icon;
  let display;

  if (value === 0) {
    display = 'No change';
  } else if (value > 0) {
    display = formatItemText(value, 'number');
    icon = (
      <div className={styles.deltaIcon}>
        <Icon name="arrowUp" />
      </div>
    );
  }

  return (
    <div className="u-flexRow">
      { icon }
      { `${display} in the last 30 days` }
    </div>
  );
}

function renderAlertIcon(alert) {
  if (!alert) return undefined;

  return (
    <div className={styles.alertIcon}>
      <Icon color="red" name="exclamationCircle" />
    </div>
  );
}

function renderDeltaTag(delta, alert) {
  if (delta === undefined) return undefined;

  return (
    <Tag
      style={(alert && delta > 0) ? 'red' : undefined}
      text={renderDelta(delta)}
    />
  );
}

export function renderInformationContent(infoItem, alert = false) {
  const {
    delta,
    format,
    label,
    tooltip,
    value,
  } = infoItem;

  const formattedValue = formatItemText(value, format || 'number');

  return (
    <>
      <div className="u-flexRow u-flexAlignCenter">
        { renderAlertIcon(alert) }
        <div className="t-caption-1 u-marginRightSm">{ label }</div>
        { tooltip && <Tooltip label={tooltip} /> }
      </div>
      <div className={styles.valueWrapper}>
        <div>
          <h4>{ formattedValue }</h4>
        </div>
        { renderDeltaTag(delta, alert) }
      </div>
    </>
  );
}

function InformationCard({
  infoItem, url, onClick,
}) {
  const Container = url ? 'a' : Fragment;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container {...containerProps(url, onClick)}>
      <Widget className={styles.statCard}>
        <WidgetContent className="u-flexRow u-justifySpaceBetween u-flexAlignCenter">
          <div className={styles.statCardContent}>
            { renderInformationContent(infoItem, true) }
          </div>
          { url && <Icon display="outline" name="chevronRight" /> }
        </WidgetContent>
      </Widget>
    </Container>
  );
}

InformationCard.propTypes = propTypes;
InformationCard.defaultProps = defaultProps;

export default InformationCard;
