import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleWidget from 'components/molecules/CollapsibleWidget';

const propTypes = {
  text: PropTypes.string,
};

const defaultProps = {
  text: undefined,
};

function AltText({ text }) {
  if (!text) return null;

  return (
    <CollapsibleWidget headerText="Alt text">
      { text }
    </CollapsibleWidget>
  );
}

AltText.propTypes = propTypes;
AltText.defaultProps = defaultProps;

export default AltText;
