import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  MessageModal,
} from '@makeably/creativex-design-system';
import {
  challengePropType,
  checkPropType,
} from 'components/audit/challenge/CheckRow';
import { getLabelForRuleType } from 'components/audit/challenge/ruleLabels';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import { track } from 'utilities/mixpanel';
import { auditChallengesPath } from 'utilities/routes';
import styles from './Review.module.css';

const FORM_NAME = 'guidelineCheckChallengeForm';

const propTypes = {
  assetId: PropTypes.number.isRequired,
  challengeReasons: PropTypes.objectOf(PropTypes.string).isRequired,
  handleClose: PropTypes.func.isRequired,
  reviewableGuidelines: PropTypes.arrayOf(PropTypes.shape({
    guidelineChallenge: PropTypes.shape(challengePropType).isRequired,
    guidelineCheck: PropTypes.shape(checkPropType).isRequired,
  })).isRequired,
};

function getDetails({
  exemptionReason,
  matchingAssetId,
  reason,
}) {
  if (['identicalCreativePassed', 'identicalCreativeFailed'].includes(reason)) {
    return `CreativeX ID: ${matchingAssetId}`;
  } else if (reason === 'exemption') {
    return `"${exemptionReason}"`;
  }

  return null;
}

function Review(props) {
  const {
    assetId,
    challengeReasons,
    reviewableGuidelines,
    handleClose,
  } = props;
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onSubmit = () => {
    track('Click Submit Error Report');
    setSubmitDisabled(true);
    document[FORM_NAME].submit();
  };

  return (
    <MessageModal
      actionButtonDisabled={submitDisabled}
      actionButtonLabel="Submit"
      title="Report Error"
      isOpen
      onActionButtonClick={onSubmit}
      onClose={handleClose}
    >
      <form action={auditChallengesPath()} method="post" name={FORM_NAME}>
        <AuthenticityTokenInput />
        <HiddenInput name="asset_id" value={assetId} />
        <div className={styles.content}>
          { reviewableGuidelines.map(({ guidelineCheck, guidelineChallenge }) => {
            const {
              id,
              name,
              type,
            } = guidelineCheck;

            const {
              exemptionReason,
              matchingAssetId,
              reason,
            } = guidelineChallenge;

            const detailsClasses = classNames(styles.details, 't-caption-1');
            const changeIconsClasses = classNames(
              styles.icons,
              { [styles.reversed]: guidelineCheck.passed },
            );

            const hasExemptionReason = exemptionReason !== '';
            const details = getDetails(guidelineChallenge);

            return (
              <div key={id} className={styles.challenge}>
                <HiddenInput name="guideline_check_challenges[][guideline_check_id]" value={id} />
                <HiddenInput name="guideline_check_challenges[][matching_id]" value={matchingAssetId} />
                { hasExemptionReason && <HiddenInput name="guideline_check_challenges[][user_exemption]" value={exemptionReason} /> }
                <HiddenInput name="guideline_check_challenges[][user_reason]" value={reason} />
                <div className={styles.row}>
                  <div className={styles.description}>
                    <h5>{ name }</h5>
                    <div className="t-caption-1">{ challengeReasons[reason] }</div>
                    { details && <div className={detailsClasses}>{ details }</div> }
                  </div>
                  <div className="u-label u-flexGrow">{ getLabelForRuleType(type, false) }</div>
                  <div className={changeIconsClasses}>
                    <Icon color="red" name="xCircle" />
                    <Icon name="arrowRight" />
                    <Icon color="green" name="checkCircle" />
                  </div>
                </div>
              </div>
            );
          }) }
        </div>
      </form>
    </MessageModal>
  );
}

Review.propTypes = propTypes;

export default Review;
