/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import StringHelper from 'components/utils/StringHelper';
import {
  axisFormat,
  tickFormat,
} from 'utilities/chart';

// Do not remove this line, we don't always want chart data labels and they are
// automatically included on all charts without this line
Chart.unregister(ChartDataLabels);

export const propTypes = {
  chartType: PropTypes.oneOf([
    'currency',
    'largeCurrency',
    'largeNumber',
    'number',
  ]).isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineColor: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

class LineChart extends React.Component {
  static updateScales(data, chartType) {
    return LineChart.scaleOptions(Math.max(...data), chartType);
  }

  static scaleOptions(maxValue, chartType) {
    const isPercent = chartType === 'percentage';

    return {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 20,
        },
      },
      y: {
        ...axisFormat(maxValue, isPercent),
        border: {
          display: false,
        },
        grid: {
          drawTicks: false,
        },
        ticks: {
          ...tickFormat(maxValue, isPercent),
          callback(value, _index, _values) {
            const format = chartType;
            const opts = { sigFigs: 3 };
            return StringHelper.format(value, format, opts);
          },
          display: true,
        },
      },
    };
  }

  static tooltipOptions(chartType) {
    return ({
      callbacks: {
        label({ raw }) {
          return StringHelper.format(raw, chartType, { sigFigs: null });
        },
      },
    });
  }

  static chartOptions() {
    return ({
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    });
  }

  constructor(props) {
    super(props);
    // Chart definition allows us to update the chart in other components
    this.chart = undefined;
    this.chartContainerRef = React.createRef();
  }

  componentDidMount() {
    const lineChartRef = this.chartContainerRef.current.getContext('2d');
    const {
      chartType,
      data,
      labels,
      lineColor,
    } = this.props;

    const chartData = {
      datasets: [{
        backgroundColor: lineColor,
        borderColor: lineColor,
        pointBorderWidth: 2,
        borderWidth: 2,
        cubicInterpolationMode: 'linear',
        lineTension: 0,
        fill: false,
        data,
      }],
      labels,
    };

    const options = LineChart.chartOptions();
    options.scales = LineChart.updateScales(data, chartType);
    options.plugins.tooltip = LineChart.tooltipOptions(chartType);

    this.chart = new Chart(lineChartRef, {
      data: chartData,
      options,
      type: 'line',
    });
  }

  render() {
    const { className } = this.props;
    const classes = classNames(
      className,
      'line-container',
    );

    return (
      <div className={classes}>
        <canvas ref={this.chartContainerRef} />
      </div>
    );
  }
}

LineChart.propTypes = propTypes;
LineChart.defaultProps = defaultProps;

export default LineChart;
