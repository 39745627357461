import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Dropdown,
  Divider,
  Search,
} from '@makeably/creativex-design-system';
import NewCategoryModal from 'components/admin/categories/NewCategoryModal';
import ItemsTable from 'components/molecules/ItemsTable';
import { findObjectByValue } from 'utilities/array';
import { getItemSortBy } from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import {
  adminCategoryPath,
  editAdminCategoryPath,
} from 'utilities/routes';
import styles from './CategoryManager.module.css';

const categoryProps = PropTypes.shape({
  applicableBrands: PropTypes.number.isRequired,
  applicableCompanies: PropTypes.number.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  industry: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const propTypes = {
  canEditCategories: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(categoryProps),
  industryOptions: PropTypes.arrayOf(optionProps),
  selectedIndustry: PropTypes.number,
};

const defaultProps = {
  categories: [],
  industryOptions: [],
  selectedIndustry: undefined,
};

const headers = [
  {
    key: 'name',
    label: 'Category',
  },
  {
    key: 'industry',
    label: 'Industry',
  },
  {
    key: 'applicableCompanies',
    label: 'Applicable Companies',
  },
  {
    key: 'applicableBrands',
    label: 'Applicable Brands',
  },
  {
    key: 'dateUpdated',
    label: 'Date Updated',
  },
];

function getItems(
  canEditCategories,
  categories,
) {
  return categories.map((category) => setItemElement({
    id: { value: category.id },
    name: {
      ...canEditCategories && {
        display: {
          url: editAdminCategoryPath(category.id),
        },
      },
      value: category.name,
    },
    industry: { value: category.industry },
    applicableCompanies: {
      display: {
        url: adminCategoryPath(category.id),
      },
      value: category.applicableCompanies,
    },
    applicableBrands: {
      display: {
        url: adminCategoryPath(category.id),
      },
      value: category.applicableBrands,
    },
    dateUpdated: { value: category.dateUpdated },
  }));
}

function CategoryManager({
  canEditCategories,
  categories,
  industryOptions,
  selectedIndustry,
}) {
  const initialIndustry = findObjectByValue(industryOptions, selectedIndustry);
  const [industryFilter, setIndustryFilter] = useState(initialIndustry);
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState();
  const [sortedItems, setSortedItems] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);

  const hasFilterOrSearch = industryFilter || search;

  const clearFilterAndSearch = () => {
    setIndustryFilter(null);
    setSearch('');
  };

  useEffect(() => {
    const filtered = categories.filter(({
      industry,
      name,
    }) => {
      if (industryFilter && industry !== industryFilter.label) return false;
      if (search) {
        const term = search.toLowerCase();

        return name.toLowerCase().includes(term) || industry.toLowerCase().includes(term);
      }

      return true;
    });

    setFilteredCategories(filtered);
  }, [industryFilter, search]);

  useEffect(() => {
    setItems(getItems(canEditCategories, filteredCategories));
  }, [filteredCategories]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    } else {
      setSortedItems(items);
    }
  }, [items, sort]);

  return (
    <>
      <Card className={styles.card}>
        <div className="u-flexRow u-justifyBetween u-marginBottom-16 u-alignEnd">
          <div className="u-flexRow u-gap-16 u-alignEnd">
            <Dropdown
              disabled={industryOptions.length === 0}
              label="Industry"
              menuProps={{ size: 'medium' }}
              options={industryOptions}
              selected={industryFilter}
              size="medium"
              onChange={(value) => setIndustryFilter(value)}
            />
            <Search
              value={search}
              onChange={setSearch}
            />
            { hasFilterOrSearch && (
              <Button
                label="Clear"
                variant="tertiary"
                onClick={() => clearFilterAndSearch()}
              />
            ) }
          </div>
          <AddNewButton
            disabled={!canEditCategories}
            label="Add New Category"
            onClick={() => setShowNewModal(true)}
          />
        </div>
        <Divider />
        <div className={styles.table}>
          <ItemsTable
            headers={headers}
            items={sortedItems}
            page={page}
            sort={sort}
            onPageChange={(p) => setPage(p)}
            onSortChange={(value) => setSort(value)}
          />
        </div>
        { filteredCategories.length === 0 && (
          <div className="u-flexRow u-justifyCenter u-marginTop-16 t-empty">
            Click &quot;+Add New Category&quot; button to create a new Category
          </div>
        ) }
      </Card>
      <NewCategoryModal
        industryOptions={industryOptions}
        isOpen={showNewModal}
        onClose={() => setShowNewModal(false)}
      />
    </>
  );
}

CategoryManager.propTypes = propTypes;
CategoryManager.defaultProps = defaultProps;

export default CategoryManager;
