import React from 'react';
import PropTypes from 'prop-types';
import {
  CopyButton,
  Tooltip,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import styles from './UuidDisplay.module.css';

const propTypes = {
  uuid: PropTypes.string.isRequired,
  className: PropTypes.string,
  url: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  url: undefined,
};

function UuidDisplay({
  className,
  url,
  uuid,
}) {
  const shortenedId = `${uuid.slice(0, 6)}...`;

  return (
    <Tooltip label={uuid}>
      <div className={styles.display}>
        <div className="u-flexRow u-gap-8 u-alignCenter">
          { url && (
            <a className={className} href={url}>
              { shortenedId }
            </a>
          ) }
          { !url && (
            <div className={className}>
              { shortenedId }
            </div>
          ) }
          <CopyButton
            text={uuid}
            onClick={() => addToast('Copied to clipboard.')}
          />
        </div>
      </div>
    </Tooltip>
  );
}

UuidDisplay.propTypes = propTypes;
UuidDisplay.defaultProps = defaultProps;

export default UuidDisplay;
