import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  MessageModal,
  TextInput,
} from '@makeably/creativex-design-system';
import styles from './TaxonomyTestModal.module.css';

export const brandCodeProps = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const marketCodeProps = PropTypes.shape({
  alphaThreeCode: PropTypes.string.isRequired,
  alphaTwoCode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  customCode: PropTypes.string,
});

const propTypes = {
  brandCodes: PropTypes.arrayOf(brandCodeProps).isRequired,
  isOpen: PropTypes.bool.isRequired,
  keyBased: PropTypes.bool.isRequired,
  marketCodes: PropTypes.arrayOf(marketCodeProps).isRequired,
  onClose: PropTypes.func.isRequired,
  brandIndicator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  delimiter: PropTypes.string,
  marketIndicator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  brandIndicator: undefined,
  delimiter: undefined,
  marketIndicator: undefined,
};

function findCodeByKey(fields, key) {
  const field = fields.find((f) => f.startsWith(key));
  return field?.slice(key.length);
}

function findCode(keyBased, fields, indicator) {
  if (keyBased) return findCodeByKey(fields, indicator);

  // Position for indicator is 1-based, not 0-based
  return fields[indicator - 1];
}

function findMarketName(parsedCode, codes) {
  const parsedMarket = codes.find(({
    customCode, alphaTwoCode, alphaThreeCode,
  }) => {
    const loweredCode = parsedCode?.toLowerCase();
    if (customCode) return customCode?.toLowerCase() === loweredCode;
    return alphaTwoCode?.toLowerCase() === loweredCode
      || alphaThreeCode?.toLowerCase() === loweredCode;
  });

  return parsedMarket?.name;
}

function renderMessage(isSuccess) {
  const className = isSuccess ? styles.success : styles.error;
  const iconProps = isSuccess ? {
    color: 'green',
    name: 'checkCircle',
  } : {
    color: 'red',
    name: 'exclamationCircle',
  };

  return (
    <div className="u-flexRow u-alignCenter t-body-2">
      <div className={styles.icon}>
        <Icon {...iconProps} />
      </div>
      <div className={className}>
        { isSuccess ? 'Success' : 'Error' }
      </div>
    </div>
  );
}

function TaxonomyTestModal({
  brandCodes,
  brandIndicator,
  delimiter,
  isOpen,
  keyBased,
  marketCodes,
  marketIndicator,
  onClose,
}) {
  const [taxonomy, setTaxonomy] = useState('');
  const [brand, setBrand] = useState({});
  const [market, setMarket] = useState({});

  const isError = (indicator, { name, code }) => {
    const codeError = indicator && !code;
    const nameError = code && !name;

    return taxonomy && (codeError || nameError);
  };

  const isSuccess = ({ name, code }) => code && name;

  const brandError = isError(brandIndicator, brand);
  const brandSuccess = isSuccess(brand);
  const marketError = isError(marketIndicator, market);
  const marketSuccess = isSuccess(market);

  const parseFieldsForDimension = (dimension, fields, indicator, setDimension) => {
    let name;
    let display;

    const code = findCode(keyBased, fields, indicator);

    if (code) {
      if (dimension === 'Market') {
        name = findMarketName(code, marketCodes);
      } else {
        name = brandCodes.find((c) => c.code.toLowerCase() === code.toLowerCase())?.name;
      }

      if (name) {
        display = name;
      } else {
        display = `No ${dimension} For Code: ${code}`;
      }
    } else {
      display = `No ${dimension} Code Parsed`;
    }

    setDimension({
      display,
      code,
      name,
    });
  };

  useEffect(() => {
    if (taxonomy) {
      const fields = taxonomy.split(delimiter);

      if (brandIndicator) {
        parseFieldsForDimension('Brand', fields, brandIndicator, setBrand);
      } else {
        setBrand({});
      }

      if (marketIndicator) {
        parseFieldsForDimension('Market', fields, marketIndicator, setMarket);
      } else {
        setMarket({});
      }
    } else {
      setBrand({});
      setMarket({});
    }
  }, [taxonomy]);

  return (
    <MessageModal
      actionButtonLabel="Done"
      isOpen={isOpen}
      size="medium"
      subtitle="Paste an example taxonomy to confirm the rule's parsing accuracy"
      title="Test Taxonomy Structure"
      onActionButtonClick={onClose}
      onClose={onClose}
    >
      <div className="u-flexColumn u-gap-16">
        <div className="u-flexRow u-gap-8">
          <Button
            label="Paste"
            onClick={() => navigator.clipboard.readText().then((text) => setTaxonomy(text))}
          />
          <TextInput
            name="taxonomy"
            placeholder="Example Taxonomy"
            size="large"
            value={taxonomy}
            onChange={(key) => setTaxonomy(key)}
          />
        </div>
        <div className="u-flexColumn u-gap-8">
          <TextInput
            label="Brand"
            name="brand"
            placeholder="N/A"
            size="large"
            value={brand.display}
            disabled
          />
          { brandError && renderMessage(false) }
          { brandSuccess && renderMessage(true) }
        </div>
        <div className="u-flexColumn u-gap-8">
          <TextInput
            label="Market"
            name="market"
            placeholder="N/A"
            size="large"
            value={market.display}
            disabled
          />
          { marketError && renderMessage(false) }
          { marketSuccess && renderMessage(true) }
        </div>
      </div>
    </MessageModal>
  );
}

TaxonomyTestModal.propTypes = propTypes;
TaxonomyTestModal.defaultProps = defaultProps;

export default TaxonomyTestModal;
