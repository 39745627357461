// Renders a Selectable Card
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
} from '@duik/it';
import { HiddenInput } from 'components/reusable/HiddenInput';

export const propTypes = {
  active: PropTypes.bool.isRequired,
  headline: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  cta: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
};

const additionalPropTypes = {
  inputName: PropTypes.string.isRequired,
  onCardClick: PropTypes.func.isRequired,
};

const defaultProps = {
  cta: undefined,
  description: undefined,
  disabled: false,
  image: {},
};

function renderHiddenInput(active, inputName, value) {
  if (!active) return null;

  return (
    <HiddenInput name={inputName} value={value} />
  );
}

function renderContent({ src, alt }, headline, description) {
  let imgContent;

  if (src) {
    imgContent = <img alt={alt} src={src} />;
  }

  return (
    <div className="selectableCard-content">
      { imgContent }
      <h4>{ headline }</h4>
      <p>{ description }</p>
    </div>
  );
}

function renderCta(ctaText) {
  let cta;

  if (ctaText) {
    cta = <p className="selectableCard-cta">{ ctaText }</p>;
  }

  return cta;
}

function SelectableCard(props) {
  const {
    active,
    cta,
    description,
    disabled,
    headline,
    image,
    inputName,
    onCardClick,
    value,
  } = props;

  const classes = classNames([
    'selectableCard',
    { 'selectableCard--active': active },
    { 'selectableCard--disabled': disabled },
  ]);

  const onClick = () => !disabled && onCardClick(value);
  const tabIndex = disabled ? -1 : 0;

  return (
    <Widget
      aria-checked={active}
      aria-disabled={disabled}
      aria-label={headline}
      className={classes}
      disabled={disabled}
      role="radio"
      tabIndex={tabIndex}
      onClick={onClick}
    >
      <WidgetContent>
        { renderHiddenInput(active, inputName, value) }
        { renderContent(image, headline, description) }
        { renderCta(cta) }
        <div className="selectableCard-selectedIcon">
          <i className="creativex-icon creativex-icon--inverse creativex-icon-check" />
        </div>
      </WidgetContent>
    </Widget>
  );
}

SelectableCard.propTypes = {
  ...propTypes,
  ...additionalPropTypes,
};
SelectableCard.defaultProps = defaultProps;

export default SelectableCard;
