import React from 'react';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
} from '@duik/it';
import InteractivePlotlyChart from 'components/graphs/interactive_plotly_chart.coffee';
import NavDropdown from 'components/navigation/NavDropdown';
import DataTable from 'components/reusable/data_table.coffee';
import styles from './Rankings.module.css';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  barChart: PropTypes.object.isRequired,
  dataTable: PropTypes.shape({
    fixedColumns: PropTypes.arrayOf(
      PropTypes.string,
    ),
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
      }),
    ),
    includeRowNumber: PropTypes.bool,
    rows: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
        }),
      ),
    ),
    sortIndex: PropTypes.number,
    sortable: PropTypes.bool,
  }).isRequired,
  rankingOptions: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    selected: PropTypes.string,
  }).isRequired,
  viewType: PropTypes.string.isRequired,
};

class Rankings extends React.Component {
  constructor(props) {
    super(props);

    // note: Active metric is deeply nested because barChart props
    //       are tightly coupled to the chart component
    const {
      barChart: {
        selected: {
          primaryMetric: {
            bar,
          },
        },
      },
    } = props;

    this.state = {
      metric: bar,
    };
  }

  onMetricChange(metric) {
    this.setState({ metric });
  }

  generateDynamicTableHeadersAndRows(dataTable) {
    const {
      fixedColumns,
      headers,
      rows,
    } = dataTable;

    const {
      metric,
    } = this.state;

    let columns = fixedColumns;
    if (!fixedColumns.includes(metric)) {
      columns = fixedColumns.concat([metric]);
    }

    const filteredHeaders = headers.filter(({ key }) => columns.includes(key));
    const filteredRows = rows.map((row) => row.filter(({ key }) => columns.includes(key)));

    return [filteredHeaders, filteredRows];
  }

  renderRankingSelect() {
    const {
      rankingOptions: {
        options,
        selected,
      },
    } = this.props;

    const sanitizedOptions = NavDropdown.sanitize(options);
    const activeOption = sanitizedOptions.find(({ link }) => link === selected);

    const buttonProps = {
      id: 'js-rankingSelection',
    };

    return (
      <NavDropdown
        activeOption={activeOption}
        buttonProps={buttonProps}
        label="Segment By"
        options={sanitizedOptions}
      />
    );
  }

  render() {
    const {
      barChart,
      dataTable,
      viewType,
    } = this.props;

    const {
      headers,
      includeRowNumber,
      rows,
      sortIndex,
      sortable,
    } = dataTable;

    const chart = (
      <InteractivePlotlyChart
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...barChart}
        onMetricChange={(metric, _metricGroup) => this.onMetricChange(metric)}
      />
    );

    let filteredHeaders;
    let filteredRows;
    if (viewType === 'preflight') {
      [filteredHeaders, filteredRows] = [headers, rows];
    } else {
      [filteredHeaders, filteredRows] = this.generateDynamicTableHeadersAndRows(dataTable);
    }

    return (
      <div className="rankings">
        <Widget>
          <WidgetContent>
            { this.renderRankingSelect() }
            <div className="u-flexRow u-flexAlignCenter">
              <div>
                <h5>Past Performance</h5>
              </div>
            </div>
            { chart }
          </WidgetContent>
        </Widget>
        <Widget>
          <DataTable
            className={styles.tableWrapper}
            headers={filteredHeaders}
            includeRowNumber={includeRowNumber}
            rows={filteredRows}
            sortIndex={sortIndex}
            sortable={sortable}
          />
        </Widget>
      </div>
    );
  }
}

Rankings.propTypes = propTypes;

export default Rankings;
