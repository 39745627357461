import React from "react"
import AssetImage from "components/reusable/asset_image"
import PropTypes from "prop-types"

# Displays a card for a user created comparison report
class CustomComparisonCard extends React.Component
  @propTypes =
    id: PropTypes.string.isRequired # unique id for the post group
    linkUrl: PropTypes.string.isRequired # link for card's click action
    postGroups: PropTypes.arrayOf(
      PropTypes.shape(
        # descriptions of the post group assets
        description: PropTypes.oneOfType(
          [
            PropTypes.array,
            PropTypes.string
          ]
        )
        image: PropTypes.object # image props for post group display image
        label: PropTypes.string # post group label
        position: PropTypes.number
      )
    )

  componentKey: (position, idx) ->
    position || idx

  renderAssetImage: (postGroup, idx) =>
    key = @componentKey(postGroup.position, idx)
    `<AssetImage
      key={key}
      className='comparisonCard-image'
      {...postGroup.image}
    />`

  renderDescription: (item, idx) ->
    `<span key={idx}>{item}</span>`

  renderDescriptions: (postGroup, idx) =>
    key = @componentKey(postGroup.position, idx)
    content =
      if typeof postGroup.description is "string"
        `<span>{postGroup.description}</span>`
      else
        postGroup.description.map(@renderDescription)

    `<div key={key} className='comparisonCard-description'>
      {content}
    </div>`

  renderLabel: (postGroup, idx) =>
    key = @componentKey(postGroup.position, idx)
    `<div key={key} className='comparisonCard-label'>
      <span className='truncate'>{postGroup.label}</span>
    </div>`

  render: ->
    linkId = "comparisonCard_#{@props.id}"

    `<a id={linkId} href={this.props.linkUrl}>
      <div className='comparisonCard'>
        <div className='comparisonCard-versusCircle'>
          <span>vs</span>
        </div>
        <div className='comparisonCard-images'>
          {this.props.postGroups.map(this.renderAssetImage)}
        </div>
        <div className='comparisonCard-details'>
          <div className='comparisonCard-labels'>
            {this.props.postGroups.map(this.renderLabel)}
          </div>
          <div className='comparisonCard-divider'/>
          <div className='comparisonCard-descriptions'>
            {this.props.postGroups.map(this.renderDescriptions)}
          </div>
        </div>
      </div>
    </a>`

export default CustomComparisonCard
