function getColumnText(item, column) {
  const valueFn = column.valueFn ?? ((o) => o[column.key]);
  const value = valueFn(item);

  if (typeof value === 'number') {
    return value;
  }

  if (value.includes(',')) {
    return `"${value}"`;
  }
  return value;
}

/**
 * Transforms a collection of objects to a CSV string.
 *
 * @param data Collection of objects.
 * @param columns Optional column configuration.
 * @returns {string} CSV string.
 *
 * @example
 * // Inferred configuration is based on the first item. Header names are the keys.
 * getCsv({a: 1, b: 3})
 * // 'a,b\n1,3'
 *
 * @example
 * // Explicit configuration can supply specific columns and labels.
 * getCsv([{a: 1, b: 3, c: 'not included'}], [{key: 'b', label: 'custom label'}, {key: 'a'}])
 * // 'custom label,a\n3,1'
 *
 * @example
 * // Provide a valueFn instead of a key on a column to do custom transforms.
 * getCsv([{a: 1, b: 3}], [{label: 'sum', valueFn: item => item.a + item.b}])
 * // 'sum\n4'
 */
export function getCsv(data, columns) {
  const resolvedColumns = columns ?? Object.keys(data[0] ?? {}).map((key) => ({ key }));
  const labelRow = resolvedColumns.map((column) => (column.label ?? column.key).replace('\n', ' ')).join(',');
  const rows = data.map((item) => (
    resolvedColumns.map((column) => getColumnText(item, column)).join(',')
  ));

  return [labelRow, ...rows].join('\n');
}
