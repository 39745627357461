import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  currentStep: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
};

function NavProgressBubble({ currentStep, step }) {
  const classes = classNames(
    'navProgressBar-bubble',
    { 'navProgressBar-bubble--active': (step <= currentStep) },
  );

  return <div className={classes} />;
}
NavProgressBubble.propTypes = {
  currentStep: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
};

// @note: Steps go from 1..stepCount. Indexed from 1
function NavProgressBar({ currentStep, stepCount }) {
  const steps = Array.from(Array(stepCount), (_elm, index) => index + 1);

  return (
    <div className="navProgressBar">
      {
        steps.map((step) => <NavProgressBubble key={step} currentStep={currentStep} step={step} />)
      }
    </div>
  );
}

NavProgressBar.propTypes = propTypes;

export default NavProgressBar;
