import React from "react"
import Column from "components/reusable/column"
import Row from "components/reusable/row"
import PropTypes from "prop-types"

# Used to pretty display a field of a category's filter
class CategoryFilterField extends React.Component
  @propTypes =
    field: PropTypes.oneOf(['categories', 'country', 'hashtags', 'tags', 'users', 'words']).isRequired
    label: PropTypes.string # Used when you want to display something other than value
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired

  render: ->
    return null unless @props.value.length > 0

    fieldLabel = CategoryUtil.sectionFieldLabel(@props.field)
    value =
      if @props.field is 'tags'
        tagCount = @props.value.length
        if tagCount is 1
          "There is 1 visual recognition tag used " +
            "to classify your creatives into this category."
        else
          "There are #{tagCount} visual recognition tags used " +
            "to classify your creatives into this category."
      else
        if @props.label then @props.label else @props.value

    `<Row>
      <Column size={4}>
        <div className='categoryFilterShow-fieldLabel'>
          {fieldLabel}
        </div>
      </Column>
      <Column size={8}>
        <div>{value}</div>
      </Column>
    </Row>`

export default CategoryFilterField
