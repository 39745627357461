// Helper functions for saving things to local storage
//
export function saveToLocalStorage(item, key) {
  const itemAsString = JSON.stringify(item);
  localStorage.setItem(key, itemAsString);
}

export function retrieveFromLocalStorage(key) {
  const itemFromStorage = localStorage.getItem(key);
  // note: calling JSON.parse('undefined') throws an error
  if (itemFromStorage === 'undefined') return undefined;
  return JSON.parse(itemFromStorage);
}

export function retrieveFromLocalStorageAndRemove(key) {
  const itemFromStorage = retrieveFromLocalStorage(key);
  localStorage.removeItem(key);
  return itemFromStorage;
}
