// Renders a set of Brand, Market & Channel selectors
import React from 'react';
import PropTypes from 'prop-types';
import NavDropdown, {
  propTypes as navProps,
} from 'components/navigation/NavDropdown';
import SearchableNavDropdown, {
  propTypes as searchableNavProps,
} from 'components/navigation/SearchableNavDropdown';

const propTypes = {
  brandSelectorProps: PropTypes.shape(searchableNavProps).isRequired,
  channelSelectorProps: PropTypes.shape(navProps).isRequired,
  marketSelectorProps: PropTypes.shape(searchableNavProps).isRequired,
};

function renderSearchableSelector({
  activeOption, label, options,
}) {
  const buttonProps = {
    disabled: options.length <= 1,
  };

  return (
    <div className="u-marginRight">
      <SearchableNavDropdown
        activeOption={activeOption}
        buttonProps={buttonProps}
        label={label}
        options={options}
      />
    </div>
  );
}

function renderChannelSelector({
  activeOption, label, options,
}) {
  return (
    <div>
      <NavDropdown
        activeOption={activeOption}
        label={label}
        options={options}
      />
    </div>
  );
}

function BrandMarketChannel(props) {
  const {
    brandSelectorProps,
    channelSelectorProps,
    marketSelectorProps,
  } = props;

  return (
    <div className="row">
      <div className="col s12">
        <div className="u-flexRow">
          { renderSearchableSelector(brandSelectorProps) }
          { renderSearchableSelector(marketSelectorProps) }
          { renderChannelSelector(channelSelectorProps) }
        </div>
      </div>
    </div>
  );
}

BrandMarketChannel.propTypes = propTypes;

export default BrandMarketChannel;
