import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Option from "components/reusable/option"

class Select extends React.Component
  @propTypes =
    disabled: PropTypes.bool
    inputName: PropTypes.string
    label: PropTypes.string
    multiple: PropTypes.bool
    onChange: PropTypes.func
    options: PropTypes.oneOfType(
      [
        PropTypes.arrayOf(
          PropTypes.shape(
            disabled: PropTypes.bool
            label: PropTypes.string
            value: PropTypes.string
          )
        ),
        PropTypes.arrayOf(
          PropTypes.shape(
            optgroup: PropTypes.string
            options: PropTypes.arrayOf(
              PropTypes.shape(
                disabled: PropTypes.bool
                label: PropTypes.string
                value: PropTypes.string
              )
            )
          )
        )
      ]
    )
    placeholder: PropTypes.string # Placeholder for the select. Ex: "Select..."
    required: PropTypes.bool
    selected: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ])
    shrink: PropTypes.bool # Whether to shrink selector to content size
    size: PropTypes.oneOf(['small', 'default'])
    wrapperClass: PropTypes.string # Class for the wrapper when including a label

  @defaultProps =
    multiple: false
    required: false
    size: 'default'

  classes: ->
    classNames(
      "browser-default",
      "disabled": @props.disabled
      "reactSelect",
      "reactSelect--shrink": @props.shrink,
      "selectSize--#{@props.size}"
      @props.className,
    )

  options: ->
    return @props.children if @props.children
    for option in @props.options
      if option.optgroup
        @createOptgroup(option.optgroup, option.options)
      else
        @createOption(option)

  createOptions: (options) ->
    @createOption(option) for option in options

  #TODO: probably don't need uniqueId here
  createOption: (option) ->
    uniqueId = _.uniqueId("optionId-#{option.value}-")
    `<Option
      disabled={option.disabled}
      key={uniqueId}
      label={option.label}
      value={option.value}
    />`

  createOptgroup: (optgroupLabel, options) ->
    `<optgroup label={optgroupLabel} key={_.uniqueId("optGroup-")}>
      {this.createOptions(options)}
    </optgroup>`

  handleChange: (e) =>
    return if @props.disabled
    value = if @props.multiple then _.pluck(e.target.selectedOptions, 'value') else e.target.value
    @props.onChange(value, { inputName: @props.inputName })

  renderPlaceholder: ->
    return unless @props.placeholder
    `<Option
      disabled
      label={this.props.placeholder}
      selected
      value=""
    />`

  render: ->
    selectContent = `<select
      className={this.classes()}
      disabled={this.props.disabled}
      multiple={this.props.multiple}
      name={this.props.inputName}
      onChange={this.handleChange}
      required={this.props.required}
      value={this.props.selected}
    >
      {this.renderPlaceholder()}
      {this.options()}
    </select>`

    if @props.label
      `<div className={this.props.wrapperClass}>
        <label>{this.props.label}</label>
        {selectContent}
      </div>`
    else
      selectContent
export default Select
