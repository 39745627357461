import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormGroupContainer,
  Widget,
  WidgetContent,
} from '@duik/it';
import DataTable from 'components/reusable/data_table.coffee';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import {
  HiddenInput,
  HiddenInputs,
} from 'components/reusable/HiddenInput';
import SearchableSelect from 'components/reusable/SearchableSelect';
import ArrayHelper from 'components/utils/ArrayHelper';
import FormHelper from 'components/utils/FormHelper';

export const propTypes = {
  method: PropTypes.oneOf(['post', 'patch']).isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      company_type: PropTypes.string,
      holding_company: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  url: PropTypes.string.isRequired,
};

function SelectedPartners({ rows }) {
  const headers = [{ value: 'Partner' }, { value: 'Company Type' }, { value: 'Holding Company' }];

  return (
    <DataTable
      headers={headers}
      rows={rows}
    />
  );
}

SelectedPartners.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      }),
    ),
  ).isRequired,
};

class PartnerLinkingForm extends React.Component {
  constructor(props) {
    super(props);

    const {
      partners,
    } = this.props;

    this.options = partners.map((partner) => ({
      label: partner.name,
      value: partner.id,
    }));
    this.partners_lookup = new Map(partners.map((partner) => [partner.id, partner]));

    this.state = { selectedPartners: [] };
  }

  handleOptionClick(option) {
    this.setState((state) => ({
      selectedPartners: ArrayHelper.withOrWithoutObject(
        state.selectedPartners,
        option,
      ),
    }));
  }

  render() {
    const {
      method,
      url,
    } = this.props;

    const {
      selectedPartners,
    } = this.state;

    const { formMethod, hiddenMethod } = FormHelper.resolveRailsPatchPostMethod(method);

    const selectedOptions = selectedPartners
      .map((partner) => ([
        { value: this.partners_lookup.get(partner.value).name },
        { value: this.partners_lookup.get(partner.value).company_type },
        { value: this.partners_lookup.get(partner.value).holding_company ?? 'None' }]));

    return (
      <Widget>
        <WidgetContent>
          <form action={url} method={formMethod}>
            <HiddenInputs name="partners[]" values={ArrayHelper.values(selectedPartners)} />
            <AuthenticityTokenInput />
            <HiddenInput name="_method" value={hiddenMethod} />
            <FormGroupContainer>
              <FormGroup>
                <div className="u-flexRow">
                  <SearchableSelect
                    activeOption={selectedPartners}
                    options={this.options}
                    placeholder="Select a partner"
                    block
                    multiple
                    onOptionClick={(option) => this.handleOptionClick(option)}
                  />
                </div>
              </FormGroup>
              <SelectedPartners rows={selectedOptions} />
              <FormGroup className="u-textAlignRight">
                <input
                  className="btn btn-primary"
                  disabled={selectedPartners.length === 0}
                  name="commit"
                  type="submit"
                  value="Save"
                />
              </FormGroup>
            </FormGroupContainer>
          </form>
        </WidgetContent>
      </Widget>
    );
  }
}

PartnerLinkingForm.propTypes = propTypes;

export default PartnerLinkingForm;
