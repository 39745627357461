import React from 'react';
import PropTypes from 'prop-types';
import FacebookBrandsStepHelper from 'components/account_setup/facebook_brands/StepHelper';
import FacebookAdsStepHelper from 'components/account_setup/meta_ads/StepHelper';
import TiktokStepHelper from 'components/account_setup/tiktok_ads/StepHelper';

const propTypes = {
  channel: PropTypes.string,
  content: PropTypes.node,
  view: PropTypes.string,
};

const defaultProps = {
  channel: undefined,
  content: undefined,
  view: undefined,
};

export function getStepHelper(channel) {
  switch (channel) {
    case 'tiktok_ads':
      return TiktokStepHelper;
    case 'meta_ads':
      return FacebookAdsStepHelper;
    case 'facebook_brand_pages':
      return FacebookBrandsStepHelper;
    default:
      if (process.env.NODE_ENV === 'development') {
        /* eslint-disable-next-line no-console */
        console.warn(`Missing StepHelper for channel(${channel})`);
      }
      return null;
  }
}

export function getContentTag(channel, view) {
  return getStepHelper(channel).getContentTag(view);
}

function getInstructionContent(channel, view) {
  return getStepHelper(channel).getInstructionContent(view);
}

function renderContentObj(content) {
  const { href, label } = content.linkObject;

  let preContent;
  if (content.preLink) {
    preContent = `${content.preLink} `;
  }

  return (
    <>
      { preContent }
      <a href={href}>{ label }</a>
      { content.postLink }
    </>
  );
}

function Instructions({
  channel,
  content: instructionContent,
  view,
}) {
  let instructions;

  if (instructionContent) {
    instructions = instructionContent;
  } else {
    // Old functionality, convert to new way
    const content = getInstructionContent(channel, view);

    if (Array.isArray(content)) {
      instructions = content.map((obj) => renderContentObj(obj));
    } else if (typeof (content) === 'object') {
      instructions = renderContentObj(content);
    } else {
      // String case
      instructions = content;
    }
  }

  return (
    <div className="instructions">
      { instructions }
    </div>
  );
}

Instructions.propTypes = propTypes;
Instructions.defaultProps = defaultProps;

export default Instructions;
