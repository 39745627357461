import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CloseButton,
  Divider,
  Dropdown,
  TextInput,
} from '@makeably/creativex-design-system';
import FileDrop from 'components/reusable/file_drop.coffee';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import {
  adminLogoElementPath,
  adminLogoElementsPath,
  uploadAdminLogoElementsPath,
} from 'utilities/routes';
import styles from 'components/admin/annotation/ElementConfiguration.module.css';

const elementProps = {
  brandId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  exampleUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  id: PropTypes.number,
};

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const propTypes = {
  brandOptionsByCompany: PropTypes.objectOf(PropTypes.arrayOf(optionProps)).isRequired,
  companyOptions: PropTypes.arrayOf(optionProps).isRequired,
  element: PropTypes.shape(elementProps),
};

const defaultProps = {
  element: undefined,
};

const MAX_REFERENCES = 4;
const fileFormats = ['.jpg', '.jpeg', '.png'];

function ElementConfiguration({
  brandOptionsByCompany,
  companyOptions,
  element,
}) {
  const formUrl = element ? adminLogoElementPath(element.id) : adminLogoElementsPath();

  const [title, setTitle] = useState(element?.label ?? '');
  const initialBrandOptions = element ? brandOptionsByCompany[element.companyId] : [];
  const [brandOptions, setBrandOptions] = useState(initialBrandOptions);
  const [companyId, setCompanyId] = useState(element?.companyId ?? '');
  const [brandId, setBrandId] = useState(element?.brandId ?? '');
  const [exampleUrls, setExampleUrls] = useState(element?.exampleUrls ?? []);

  const referencesCapped = exampleUrls.length >= MAX_REFERENCES;

  const onCompanyChange = (opt) => {
    setCompanyId(opt.value);
    setBrandId('');
    setBrandOptions(brandOptionsByCompany[opt.value]);
  };

  const handleUploadSuccess = (imageUrl) => {
    const newUrls = [...exampleUrls];
    newUrls.push(imageUrl);
    setExampleUrls(newUrls);
  };

  const removeImageUrl = (imageUrl) => {
    const newUrls = exampleUrls.filter((url) => url !== imageUrl);
    setExampleUrls(newUrls);
  };

  const renderReferenceImage = (imageUrl) => (
    <div key={imageUrl} className="u-flexRow u-marginRight">
      <img
        alt="logo-example"
        className={styles.exampleImage}
        src={imageUrl}
      />
      <CloseButton onClick={() => removeImageUrl(imageUrl)} />
    </div>
  );

  return (
    <>
      <Card>
        <h5>{ `${element ? 'Edit' : 'Create New'} Element` }</h5>
        <Divider margin />
        <form action={formUrl} method="post">
          { element && <input name="_method" type="hidden" value="patch" /> }
          <AuthenticityTokenInput />
          <HiddenInput name="cx_label[organization_id]" value={companyId} />
          <HiddenInput name="cx_label[organization_brand_id]" value={brandId} />
          {
            exampleUrls.map((url) => (
              <HiddenInput key={url} name="example_urls[]" value={url} />
            ))
          }
          <TextInput
            disabled={element && element.state === 'active'}
            label="Title"
            name="cx_label[label]"
            placeholder="Enter Title Here"
            size="large"
            value={title}
            onChange={(value) => setTitle(value)}
          />
          <div className="u-flexRow u-marginTop-16">
            <div className="u-flexGrow">
              <Dropdown
                disabled={element && element.state === 'active'}
                label="Company"
                menuProps={{ size: 'large' }}
                options={companyOptions}
                selected={companyOptions.find((opt) => opt.value === companyId)}
                size="large"
                onChange={(opt) => onCompanyChange(opt)}
              />
            </div>
            <div className="u-flexGrow">
              <Dropdown
                disabled={element && element.state === 'active'}
                label="Brand"
                menuProps={{ size: 'large' }}
                options={brandOptions}
                selected={brandOptions.find((opt) => opt.value === brandId)}
                size="large"
                onChange={(opt) => setBrandId(opt.value)}
              />
            </div>
          </div>
          <div className="t-caption-1 u-marginTop-16">
            References
          </div>
          <Card className="u-marginTop-8">
            { !exampleUrls.length && (
              <span className="t-caption-1 u-flexRow u-justifyCenter">
                Upload reference images from below.
              </span>
            ) }
            <div className="u-flexRow">
              { exampleUrls.map((url) => renderReferenceImage(url)) }
            </div>
          </Card>
          <div className="u-flexRow u-justifyFlexEnd u-marginTop-16">
            <Button
              disabled={!(title && companyId && brandId && exampleUrls.length)}
              label={element ? 'Update' : 'Create'}
              type="submit"
            />
          </div>
        </form>
      </Card>
      <Card className="u-marginTop-16">
        <h5>Upload Images</h5>
        { referencesCapped && (
          <span className="t-body-1 u-flexRow u-justifyCenter">
            Reference image limit reached. Please remove a reference before adding more.
          </span>
        ) }
        { !referencesCapped && (
          <FileDrop
            key={exampleUrls.length}
            acceptedFileTypes={fileFormats}
            maxFileUploads={1}
            postUrl={uploadAdminLogoElementsPath()}
            onSuccess={(file, imageUrl) => handleUploadSuccess(imageUrl)}
          />
        ) }
      </Card>
    </>
  );
}

ElementConfiguration.propTypes = propTypes;
ElementConfiguration.defaultProps = defaultProps;

export default ElementConfiguration;
