import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  TextInput,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import { findObjectByValue } from 'utilities/array';
import {
  destroy,
  patch,
} from 'utilities/requests';
import {
  adminCategoriesPath,
  adminCategoryPath,
} from 'utilities/routes';
import styles from './CategoryConfiguration.module.css';

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const propTypes = {
  id: PropTypes.number.isRequired,
  industryId: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  industryOptions: PropTypes.arrayOf(optionProps),
};

const defaultProps = {
  industryOptions: [],
};

async function updateCategory(id, name, industryId) {
  const url = adminCategoryPath(id);

  const formData = new FormData();
  formData.append('name', name);
  formData.append('industry_id', industryId);

  const response = await patch(url, formData);
  return response.data;
}

async function deleteCategory(id) {
  const url = adminCategoryPath(id);

  const response = await destroy(url);
  return response.data;
}

function CategoryConfiguration({
  id,
  industryId,
  industryOptions,
  isEditable,
  name: initialName,
}) {
  const [name, setName] = useState(initialName);
  const [industry, setIndustry] = useState(findObjectByValue(industryOptions, industryId));

  const onSave = async () => {
    const data = await updateCategory(id, name, industry?.value);

    if (data.success) {
      addToast('Category name successfully updated');
    } else {
      addToast('Category already exists in the selected industry', { type: 'error' });
    }

    window.location.href = adminCategoriesPath();
  };

  const onDelete = async () => {
    const data = await deleteCategory(id);

    if (data.success) {
      addToast('Category successfully deleted');
    } else {
      addToast('Category already applied to company/brand. Cannot be deleted', { type: 'error' });
    }

    window.location.href = adminCategoriesPath();
  };

  return (
    <Card className={styles.card}>
      <div className="u-flexColumn u-gap-16">
        <h5>Edit Category</h5>
        <Divider />
        <Dropdown
          disabled={!isEditable}
          label="Industry"
          menuProps={{ size: 'medium' }}
          options={industryOptions}
          selected={industry}
          size="medium"
          onChange={(opt) => setIndustry(opt)}
        />
        <TextInput
          disabled={!isEditable}
          label="Category Name"
          placeholder="Category Name"
          size="large"
          value={name}
          onChange={(value) => setName(value)}
        />
        <div className="u-flexRow u-justifyEnd">
          <div className="buttonGroup">
            <Button
              disabled={name && name === initialName}
              label="Edit Name"
              onClick={onSave}
            />
            <Button
              disabled={name && !isEditable}
              label="Delete"
              variant="destructive"
              onClick={onDelete}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

CategoryConfiguration.propTypes = propTypes;
CategoryConfiguration.defaultProps = defaultProps;

export default CategoryConfiguration;
