import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import HorizontalGauge from "components/graphs/horizontal_gauge"

# This is responsible for displaying a paid visual impact table
class PaidStatsTable extends React.Component
  @propTypes =
    summaryMetrics: PropTypes.arrayOf(
      PropTypes.shape(
        comparison: PropTypes.shape(            # Represents how the metric compares to the account's metric.
          class: PropTypes.string,
          objectLabel: PropTypes.string,        # A label for the object we are comparing against
          value: PropTypes.string
        ),
        format: PropTypes.oneOf(['gauge', 'metric']),
        label: PropTypes.string.isRequired,
        metric: PropTypes.string,
        ratio: PropTypes.number,                    # Used for generating a gauge chart for the gauge format
        ratioPostfixDescription: PropTypes.string,  # Description appended to stats on a gauge chart
        value: PropTypes.number.isRequired
      )
    )

  renderMetricHeader: (summaryMetric) ->
    `<th key={_.uniqueId('paid-visual-impact-header')}>{summaryMetric.label}</th>`

  renderMetricComparison: (comparison) ->
    return if comparison.class is 'neutral'

    # TODO: Lets make some shared metric css
    # TODO: Make a MetricComponent
    comparisonClasses =
      classNames(
        'categoryCard-metricComparison',
        "metricComparison--#{comparison.class}"
      )

    `<span className={comparisonClasses}>
      ({comparison.value})
    </span>`

  renderSummaryMetric: (summaryMetric) =>
    if summaryMetric.format is 'gauge'
      gaugeChart = `<HorizontalGauge rate={summaryMetric.ratio}/>` if summaryMetric.ratio > 0

      `<td key={_.uniqueId('paid-visual-impact-row')}>
        <span className="visualImpactTable-metric">
          <span className='visualImpactTable-metricValue'>
            {FormatUtil.metricFormat('rate', summaryMetric.ratio)}
          </span>
          <span>
            ({FormatUtil.metricFormat(summaryMetric.metric, summaryMetric.value)})
          </span>
          <span className='visualImpactTable-metricDescription'>
            {summaryMetric.ratioPostfixDescription}
          </span>
        </span>
        {gaugeChart}
      </td>`
    else
      if summaryMetric.comparison
        comparison = @renderMetricComparison(summaryMetric.comparison)

      `<td key={_.uniqueId('paid-visual-impact-row')}>
        <span className="visualImpactTable-comparisonMetric">
          <span className="visualImpactTable-metric">
            <span className='visualImpactTable-metricValue'>
              {FormatUtil.metricFormat(summaryMetric.metric, summaryMetric.value)}
            </span>
            <span>
              {comparison}
            </span>
          </span>
        </span>
      </td>`

  render: ->
    `<table className='visualImpactTable'>
      <thead>
        <tr>
          {this.props.summaryMetrics.map(this.renderMetricHeader)}
        </tr>
      </thead>
      <tbody>
        <tr>
          {this.props.summaryMetrics.map(this.renderSummaryMetric)}
        </tr>
      </tbody>
    </table>`

export default PaidStatsTable
