import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { WidgetHeader } from '@duik/it';

const propTypes = {
  currentStep: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

function renderSteps(steps, activeStep) {
  const stepContent = steps.map(({ label, value }) => {
    const classes = classNames(
      'roadMapItem',
      { 'roadMapItem--active': value === activeStep },
    );

    return <div key={value} className={classes}>{ label }</div>;
  });

  return <div className="u-flexRow">{ stepContent }</div>;
}

function TextStepper({ currentStep, steps }) {
  return (
    <WidgetHeader>
      { renderSteps(steps, currentStep) }
    </WidgetHeader>
  );
}

TextStepper.propTypes = propTypes;

export default TextStepper;
