import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import AuditSegmentationCsvDownload from "components/audit/audit_segmentation_csv_download"
import AuditSegmentationDimensionSelect from "components/audit/audit_segmentation_dimension_select"
import MixpanelTracker from "components/utils/MixpanelTracker"
import { Select, Widget, WidgetContent } from  "@duik/it"

# This component is responsible for outputting a 2D segmentation report. The primary dimension run verticaly and the
# secondary dimension runs horizontally.
#
# This 2D segmentation report also allows for:
#   * the primary/secondary dimensions to be selected/filtered (see AuditSegmentationDimensionSelect),
#   * a CSV to be downloaded (see AuditSegmentationCsvDownload).
#
# data is a 2D array with the following example format (Brand as primary, Market as secondary):
# metric options can be found in metricOptions
#
#   [
#     'Other Half': [
#       'NYC': {'spend': 111.12, 'excellence_rate': 0.8976 },
#       'PDX': {'spend': 11.12, 'excellence_rate': 1 },
#     ]
#     'Baerlic': [
#       'PDX': {'spend': 131.14, 'excellence_rate': 0.8276 },
#       'SFO': {'spend': 911.32, 'excellence_rate': 0.4979 },
#     ]
#   ]
class AuditSegmentationCard extends React.Component
  @propTypes =
    data: PropTypes.object              # 2D array to metric lookup (see above for format)
    metricOptions: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string
        value: PropTypes.string
      )
    )
    primaryDimension: PropTypes.shape(
      type: PropTypes.string                              # type ex. brand / channel
      values: PropTypes.arrayOf(PropTypes.string)   # values ex. ['Adidas', 'Nike']
    )
    primaryDimensionSelectProps: PropTypes.object   # NavDropdown props
    secondaryDimension: PropTypes.shape(
      type: PropTypes.string                              # type ex. brand / channel
      values: PropTypes.arrayOf(PropTypes.string)   # values ex. ['Adidas', 'Nike']
    )
    secondaryDimensionSelectProps: PropTypes.object # NavDropdown props

  # Threshold for giving rate cells "good" / "bad" treament ex. > 80% is colored green
  GOOD_THRESHOLD = 0.8
  BAD_THRESHOLD = 0.5

  constructor: (props) ->
    super(props)

    @state =
      metric: 'excellence_rate'
      primaryDimensionValues: props.primaryDimension.values
      secondaryDimensionValues: props.secondaryDimension.values

  getMetricValue: (primaryDimensionValue, secondaryDimensionValue) =>
    values = @props.data[primaryDimensionValue][secondaryDimensionValue] || {}
    values[@state.metric]

  metricLabel: =>
    _.find(@props.metricOptions, (metricOption) => metricOption.value is @state.metric).label

  handleMetricSelect: (metricOption) =>
    MixpanelTracker.trackFilterSelection("Primary Metric", metricOption.value)
    @setState(metric: metricOption.value)

  # callback to select/remove dimension value for a
  # given @state dimensionLocation (primaryDimensionValues / secondaryDimensionValues)
  # if isSelected is true, then dimensionValue gets added to the dimensionLocation (if false, then removed).
  #
  # Ex. handleDimensionValueChange('primaryDimensionValues', 'Nike', true) will
  #     add Nike to @state.primaryDimensionValues.
  handleDimensionValueChange: (dimensionLocation, dimensionValue, isSelected) =>
    option =
      if dimensionLocation == 'primaryDimension'
        "Option 1"
      else
        "Option 2"

    MixpanelTracker.trackFilterSelection(option, dimensionValue)

    @setState(
      (prevState, props) ->
        if isSelected
          selectedDimensionValues = _.union(prevState[dimensionLocation], [dimensionValue])
        else
          selectedDimensionValues = _.without(prevState[dimensionLocation], dimensionValue)

        stateUpdates = {}
        stateUpdates[dimensionLocation] = _.sortBy(selectedDimensionValues, (val) -> val)
        stateUpdates
    )

  renderData: =>
    # This is the top row which is mostly full of blanks: | | secondaryDim label | | | ... |
    topRowCells = [
      `<td key='header-top-blank'/>`,
      `<td className='segmentation-secondaryDimensionHeader' key='header-top-secondary-dim-type'>
        {this.props.secondaryDimension.label}
      </td>`
    ]

    # This is the second row which is: | primaryDim label | secondaryDimVal1 | secondaryDimVal2 | ... |
    secondaryDimensionHeaderCells = [
      `<td className='segmentation-primaryDimensionHeader' key='header-primary-dim-type'>
        {this.props.primaryDimension.label}
      </td>`
    ]

    _.each(
      @state.secondaryDimensionValues,
      (secondaryDimensionValue, idx) ->
        # Pad with blank cells after first secondary dimension idx
        topRowCells.push(`<td className='segmentation-cell' key={'header-top-blank-' + idx}/>`) if idx > 0

        secondaryDimensionHeaderCells.push(
          `<td className='segmentation-secondaryDimension' key={'header-secondary-' + idx}>
            {secondaryDimensionValue}
          </td>`
        )
    )

    `<div className='segmentation-dataContainer'>
      <table className='segmentation-table'>
        <tbody>
          <tr>
            {topRowCells}
          </tr>
          <tr>
            {secondaryDimensionHeaderCells}
          </tr>
          {this.state.primaryDimensionValues.map(this.renderDataRow)}
        </tbody>
      </table>
    </div>`

  renderDataRow: (primaryDimensionValue) =>
    valueCells = []
    _.each(
      @state.secondaryDimensionValues,
      (secondaryDimensionValue, idx) =>
        metric = @state.metric # cannot inline below due to scope issues
        value = @getMetricValue(primaryDimensionValue, secondaryDimensionValue)
        formattedValue = @formatValue(value)
        valueCells.push(
          `<td className={formattedValue.formatClass} key={primaryDimensionValue + '-' + metric + '-' + idx}>
            {formattedValue.value}
          </td>`
        )
    )

    `<tr key={primaryDimensionValue + '-row'}>
      <td className='segmentation-primaryDimension' key={primaryDimensionValue + '-cell-label'}>
        {primaryDimensionValue}
      </td>
      {valueCells}
    </tr>`

  formatValue: (value) =>
    return { formatClass: undefined, value: '-' } if _.isUndefined(value)

    return { formatClass: undefined, value: value } if value is 'N/A'

    switch @state.metric
      when 'spend', 'excellent_spend', 'non_excellent_spend'
        {
          formatClass: undefined
          value: FormatUtil.metricFormat('spend', value, { currency_symbol: '$' })
        }
      when 'excellence_rate', 'excellence_spend_rate'
        {
          formatClass: classNames(
            'segmentation-performance--good': value > GOOD_THRESHOLD
            'segmentation-performance--bad': value < BAD_THRESHOLD
          )
          value: FormatUtil.asPercent(value)
        }
      when 'count'
        {
          formatClass: undefined
          value: FormatUtil.metricFormat('posts', value)
        }

  trackCsvButtonClick: ->
    MixpanelTracker.trackButtonClick("Download CSV")

  renderLegend: ->
    switch @state.metric
      when 'excellence_rate', 'excellence_spend_rate'
        `<div className='right-align segmentation-legend'>
          <div className='segmentation-legendKey segmentation-performance--good'/>
          Greater than {GOOD_THRESHOLD * 100}%
          <div className='u-marginLeft segmentation-legendKey segmentation-performance--bad'/>
          Less than {BAD_THRESHOLD * 100}%
        </div>`
      else
        # Return empty legend with fixed height so table does not jump up
        `<div className='segmentation-legend'/>`

  renderMetricSelect: ->
    options = @props.metricOptions
    activeOption = _.findWhere(options, value: @state.metric)

    `<div className='u-marginLeft'>
      <Select
        activeOption={activeOption}
        label='Metric Output'
        onOptionClick={this.handleMetricSelect}
        options={options}
      />
    </div>`

  render: ->
    `<Widget>
      <WidgetContent>
        <div className='u-flexRow u-spaceBetween'>
          <div className='u-flexRow'>
            <AuditSegmentationDimensionSelect
              className='js-primaryDimensionSelect'
              dimensionLocation='primaryDimensionValues'
              dimensionSelectProps={this.props.primaryDimensionSelectProps}
              handleDimensionValueChange={this.handleDimensionValueChange}
              selected={this.state.primaryDimensionValues}
              values={this.props.primaryDimension.values}
            />
            <AuditSegmentationDimensionSelect
              className='u-marginLeft js-secondaryDimensionSelect'
              dimensionLocation='secondaryDimensionValues'
              dimensionSelectProps={this.props.secondaryDimensionSelectProps}
              handleDimensionValueChange={this.handleDimensionValueChange}
              selected={this.state.secondaryDimensionValues}
              values={this.props.secondaryDimension.values}
            />
            {this.renderMetricSelect()}
          </div>
          <AuditSegmentationCsvDownload
            getMetricValue={this.getMetricValue}
            metric={this.state.metric}
            metricLabel={this.metricLabel()}
            onClick={this.trackCsvButtonClick}
            primaryDimensionLabel={this.props.primaryDimension.label}
            primaryDimensionValues={this.state.primaryDimensionValues}
            secondaryDimensionLabel={this.props.secondaryDimension.label}
            secondaryDimensionValues={this.state.secondaryDimensionValues}
          />
        </div>
        {this.renderLegend()}
        {this.renderData()}
      </WidgetContent>
    </Widget>`
export default AuditSegmentationCard
