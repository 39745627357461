// Used to announce new product features to customers within the application.
// There is only one version presented to users at any time. When making
// updates, remember to update the storageKey otherwise users won't see
// the new version of the modal.
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Modal,
} from '@duik/it';
import MixpanelTracker from 'components/utils/MixpanelTracker';

const propTypes = {
  environment: PropTypes.string.isRequired,
  link: PropTypes.string,
};

const defaultProps = {
  link: undefined,
};

// This component is responsible for generating a modal to indicate new product
// releases. Updates, should also update the localStorage key so new content will
// show up.
class ProductLaunchModal extends React.Component {
  static storageKey() {
    return 'productLaunch-siteMaintenance';
  }

  constructor(props) {
    super(props);
    // Check session on load to see if it has already been closed by the user
    const sessionClosed = localStorage.getItem(ProductLaunchModal.storageKey());
    let modalClosed = sessionClosed === 'closed';

    const maintenanceEnd = new Date('2022-02-13T23:59:59');
    const today = new Date();

    // If the modal has not already been closed by the user, close it after
    // 2022-02-13
    if (!modalClosed && today > maintenanceEnd) {
      modalClosed = true;
    }

    if (props.environment === 'test') {
      modalClosed = true;
    }

    this.state = {
      closed: modalClosed,
    };
  }

  closeModal(cta) {
    // Save to session so it doesn't continually pop up on new page loads if the
    // user has closed it
    localStorage.setItem(ProductLaunchModal.storageKey(), 'closed');

    if (cta === 'buttonLink') {
      MixpanelTracker.trackButtonClick('Product Launch Modal CTA');
    } else {
      // Otherwise the user exited the modal by clicking outside it
      MixpanelTracker.trackButtonClick('Product Launch Modal Exit');
    }

    this.setState({ closed: true }, () => {
      const { link } = this.props;

      if (cta === 'buttonLink' && link) {
        const url = new URL(link);
        window.open(url, '_blank');
      }
    });
  }

  render() {
    const { closed } = this.state;
    if (closed) return null;

    const header = 'Maintenance Update';

    return (
      <Modal
        className="productLaunchModal"
        handleClose={() => this.closeModal('close')}
        closeOnOuterClick
        hideCloseButton
        isOpen
      >
        <Modal.Body>
          <div className="productLaunchModal-content">
            <div>
              <h5>{ header }</h5>
              <p className="productLaunchModal-body">
                Our site will be offline for maintenance this weekend from Friday,
                February 11 at 9 pm ET through Sunday, February 13 at 12 pm ET. Any
                pre-flight submissions received after 12 pm ET on Friday will
                not be processed until Monday, February 14.
              </p>
              <p>We’re sorry for any inconvenience.</p>
              <p>
                Questions? Reach out to
                { ' ' }
                <a href="mailto: support@creativex.com">support@creativex.com</a>
                .
              </p>
            </div>
            <div>
              <Divider margin />
              <Button primary onClick={() => this.closeModal('buttonLink')}>Continue</Button>
            </div>
          </div>
          <div className="productLaunchModal-image" />
        </Modal.Body>
      </Modal>
    );
  }
}

ProductLaunchModal.propTypes = propTypes;
ProductLaunchModal.defaultProps = defaultProps;

export default ProductLaunchModal;
