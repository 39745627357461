import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroupContainer,
  FormGroup,
  Select,
  TextField,
  Widget,
  WidgetContent,
} from '@duik/it';
import CardHeader from 'components/reusable/CardHeader';
import { HiddenInput } from 'components/reusable/HiddenInput';
import SearchableSelect from 'components/reusable/SearchableSelect';
import FormHelper from 'components/utils/FormHelper';
import ObjectHelper from 'components/utils/ObjectHelper';

const propTypes = {
  action: PropTypes.oneOf(['new', 'edit']).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  companyTypes: PropTypes.arrayOf(
    PropTypes.shape({
      global: PropTypes.bool,
      hasHoldingCompany: PropTypes.bool,
      label: PropTypes.string,
    }),
  ).isRequired,
  holdingCompanies: PropTypes.arrayOf(PropTypes.string).isRequired,
  method: PropTypes.oneOf(['post', 'patch']).isRequired,
  url: PropTypes.string.isRequired,
  companyType: PropTypes.string,
  emailDomains: PropTypes.string,
  holdingCompany: PropTypes.string,
  name: PropTypes.string,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
};

const defaultProps = {
  companyType: undefined,
  emailDomains: '',
  holdingCompany: undefined,
  name: '',
  organizations: undefined,
};

const activeOptionProps = PropTypes.shape({
  label: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
});

const formSelectProps = {
  callback: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(activeOptionProps),
  activeOption: activeOptionProps,
  disabled: PropTypes.bool,
};

function CompanyTypeOptions(props) {
  const {
    activeOption,
    callback,
    options,
    disabled,
  } = props;

  let hiddenInput;
  if (activeOption) {
    hiddenInput = (
      <HiddenInput
        name="partner[company_type]"
        value={activeOption.value}
      />
    );
  }

  return (
    <FormGroup>
      { hiddenInput }
      <Select
        activeOption={activeOption}
        buttonProps={{ disabled }}
        label="Company Type"
        options={options}
        block
        onOptionClick={callback}
      />
    </FormGroup>
  );
}

CompanyTypeOptions.propTypes = {
  ...formSelectProps,
};
CompanyTypeOptions.defaultProps = {
  activeOption: undefined,
  disabled: false,
};

function HoldingCompanyOptions(props) {
  const {
    activeOption,
    callback,
    display,
    options,
  } = props;

  let content = null;

  if (display) {
    let hiddenInput;
    if (activeOption) {
      hiddenInput = (
        <HiddenInput
          name="partner[holding_company]"
          value={activeOption.value}
        />
      );
    }

    content = (
      <FormGroup>
        { hiddenInput }
        <Select
          activeOption={activeOption}
          label="Agency Holding Group"
          options={options}
          block
          onOptionClick={callback}
        />
      </FormGroup>
    );
  }

  return content;
}

HoldingCompanyOptions.propTypes = {
  ...formSelectProps,
  display: PropTypes.bool,
};
HoldingCompanyOptions.defaultProps = {
  activeOption: undefined,
  disabled: false,
  display: false,
};

function OrganizationOptions({
  activeOption,
  callback,
  display,
  options,
}) {
  let content = null;

  if (display) {
    let hiddenInput;
    if (activeOption) {
      hiddenInput = (
        <HiddenInput
          name="partner[organization_id]"
          value={activeOption.value}
        />
      );
    }

    content = (
      <FormGroup>
        { hiddenInput }
        <SearchableSelect
          activeOption={activeOption}
          input="partner[organization_id]"
          label="Linked Company"
          options={options}
          placeholder="Select a company"
          block
          onOptionClick={callback}
        />
      </FormGroup>
    );
  }

  return content;
}

OrganizationOptions.propTypes = {
  ...formSelectProps,
  display: PropTypes.bool,
};
OrganizationOptions.defaultProps = {
  activeOption: undefined,
  display: false,
  options: undefined,
};

class PartnerForm extends React.Component {
  static isIncomplete(state, action) {
    const {
      companyType,
      holdingCompany,
      name,
      organizationId,
    } = state;

    if (!name) {
      return true;
    }

    if (!companyType) {
      return true;
    }

    if (companyType.hasHoldingCompany) {
      if (!holdingCompany) {
        return true;
      }
    }

    if (!companyType.global) {
      if (action === 'edit') {
        return false;
      }
      if (!organizationId) {
        return true;
      }
    }

    return false;
  }

  static companyTypeAsOption(companyType) {
    const companyOption = ObjectHelper.stringToOption(companyType.label);
    companyOption.hasHoldingCompany = companyType.hasHoldingCompany;
    companyOption.global = companyType.global;
    return companyOption;
  }

  constructor(props) {
    super(props);

    const {
      companyType,
      companyTypes,
      holdingCompany,
      emailDomains,
      name,
      action,
    } = props;

    let activeCompanyType;

    if (companyType) {
      const selectedOption = companyTypes.find(({ label }) => label === companyType);
      activeCompanyType = PartnerForm.companyTypeAsOption(selectedOption);
    }

    this.header = (action === 'new' ? 'Add Partner' : `Edit ${name}`);

    this.state = {
      companyType: activeCompanyType,
      holdingCompany: ObjectHelper.stringToOption(holdingCompany),
      emailDomains,
      name,
      organizationId: undefined,
    };
  }

  handleEmailDomainsChange(value) {
    this.setState({ emailDomains: value });
  }

  handleNameChange(value) {
    this.setState({ name: value });
  }

  onHoldingCompanySelect(option) {
    this.setState({ holdingCompany: option });
  }

  onLinkedOrganizationSelect(option) {
    this.setState({ organizationId: option });
  }

  onCompanyTypeSelect(option) {
    const newState = { companyType: option };

    if (!option.hasHoldingCompany) {
      newState.holdingCompany = undefined;
    }

    if (option.global) {
      newState.organizationId = undefined;
    }

    this.setState(newState);
  }

  render() {
    const {
      action,
      authenticityToken,
      companyTypes,
      holdingCompanies,
      method,
      organizations,
      url,
    } = this.props;

    const {
      companyType,
      emailDomains,
      holdingCompany,
      name,
      organizationId,
    } = this.state;

    const { formMethod, hiddenMethod } = FormHelper.resolveRailsPatchPostMethod(method);

    const companyTypeOptions = companyTypes.map(PartnerForm.companyTypeAsOption);
    const holdingCompanyOptions = holdingCompanies.map(ObjectHelper.stringToOption);

    return (
      <div className="dashboardLayout">
        <Widget>
          <CardHeader header={this.header} />
          <WidgetContent>
            <form action={url} method={formMethod}>
              <HiddenInput name="authenticity_token" value={authenticityToken} />
              <HiddenInput name="_method" value={hiddenMethod} />
              <FormGroupContainer>
                <FormGroupContainer horizontal>
                  <FormGroup>
                    <TextField
                      label="Name"
                      name="partner[name]"
                      value={name}
                      autoFocus
                      required
                      onChange={(event) => this.handleNameChange(event.target.value)}
                    />
                  </FormGroup>
                  <CompanyTypeOptions
                    activeOption={companyType}
                    callback={(option) => this.onCompanyTypeSelect(option)}
                    disabled={companyTypeOptions.length === 1}
                    options={companyTypeOptions}
                  />
                  <HoldingCompanyOptions
                    activeOption={holdingCompany}
                    callback={(option) => this.onHoldingCompanySelect(option)}
                    display={companyType && companyType.hasHoldingCompany}
                    options={holdingCompanyOptions}
                  />
                  <OrganizationOptions
                    activeOption={organizationId}
                    callback={(option) => this.onLinkedOrganizationSelect(option)}
                    display={companyType && !companyType.global && action === 'new'}
                    options={organizations}
                  />

                </FormGroupContainer>
                <FormGroupContainer>
                  <FormGroup>
                    <TextField
                      label="Allowlisted Email Domains (comma separated)"
                      name="partner[allowlisted_email_domains]"
                      placeholder="Ex. creativex.com, google.com"
                      value={emailDomains}
                      onChange={(event) => this.handleEmailDomainsChange(event.target.value)}
                    />
                  </FormGroup>
                </FormGroupContainer>
                <FormGroup className="u-textAlignRight">
                  <input
                    className="btn btn-primary"
                    disabled={PartnerForm.isIncomplete(this.state, action)}
                    name="commit"
                    type="submit"
                    value="Save"
                  />
                </FormGroup>
              </FormGroupContainer>
            </form>
          </WidgetContent>
        </Widget>
      </div>
    );
  }
}

PartnerForm.propTypes = propTypes;
PartnerForm.defaultProps = defaultProps;

export default PartnerForm;
