import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  hideGrid: PropTypes.bool,
  lineColor: PropTypes.string,
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
  rectangleColor: PropTypes.string,
  selectedCoordinates: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  ),
  onSelection: PropTypes.func,
};

const defaultProps = {
  hideGrid: false,
  lineColor: 'white',
  offsetLeft: 0,
  offsetTop: 0,
  onSelection: () => undefined,
  rectangleColor: 'green',
  selectedCoordinates: [],
};

function renderRectangles(width, height, lineColor, selectedCoordinates, rectangleColor) {
  /* eslint-disable react/jsx-props-no-spreading */
  const rects = [];

  const rectProps = {
    stroke: lineColor,
    strokeWidth: 2,
    strokeOpacity: '50%',
    fillOpacity: '25%',
  };

  const widthPriority = width >= height;

  const widthCount = widthPriority ? 8 : 5;
  const heightCount = widthPriority ? 5 : 8;
  const widthInterval = width / widthCount;
  const heightInterval = height / heightCount;
  for (let y = 0; y < height; y += heightInterval) {
    for (let x = 0; x < width; x += widthInterval) {
      const isFilled = selectedCoordinates.findIndex((p) => {
        const nearX = Math.abs(p.x - x) <= 1;
        const nearY = Math.abs(p.y - y) <= 1;
        return nearX && nearY;
      }) !== -1;
      const fill = isFilled ? rectangleColor : 'none';
      const rect = (
        <rect
          key={`${x},${y}`}
          fill={fill}
          height={heightInterval}
          width={widthInterval}
          x={x}
          y={y}
          {...rectProps}
        />
      );
      rects.push(rect);
    }
  }
  return rects;
}

function Gridlines(props) {
  const {
    children,
    height,
    hideGrid,
    lineColor,
    offsetLeft,
    offsetTop,
    onSelection,
    selectedCoordinates,
    width,
    rectangleColor,
  } = props;

  const handleSelectRectangle = (event) => {
    const selectedRectangle = event.target;
    const rectangleX = selectedRectangle.x.baseVal.value;
    const rectangleY = selectedRectangle.y.baseVal.value;
    onSelection(rectangleX, rectangleY);
  };

  return (
    <div className="gridLines-wrapper">
      { children }
      { !hideGrid && (
        <svg
          height={height}
          style={{
            left: offsetLeft,
            top: offsetTop,
          }}
          width={width}
          onClick={handleSelectRectangle}
        >
          { renderRectangles(width, height, lineColor, selectedCoordinates, rectangleColor) }
        </svg>
      ) }
    </div>
  );
}

Gridlines.propTypes = propTypes;
Gridlines.defaultProps = defaultProps;

export default Gridlines;
