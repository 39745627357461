// TODO: Remove this temporary wrapper component for haml rendering once all
// pages are in React
import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@makeably/creativex-design-system';

const propTypes = {
  currentTab: PropTypes.string,
  showSingleTab: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      iconColor: PropTypes.string,
      tooltip: PropTypes.string,
      url: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  variant: PropTypes.oneOf(['link', 'button']),
};

const defaultProps = {
  currentTab: undefined,
  showSingleTab: false,
  tabs: undefined,
  variant: 'link',
};

function DsTabs({
  currentTab,
  showSingleTab,
  tabs,
  variant,
}) {
  if (!tabs) return null;

  return (
    <Tabs
      currentParams={window.location.search}
      currentTab={currentTab}
      currentUrl={window.location.pathname}
      showSingleTab={showSingleTab}
      tabs={tabs}
      variant={variant}
    />
  );
}

DsTabs.propTypes = propTypes;
DsTabs.defaultProps = defaultProps;

export default DsTabs;
