// This component represents the center card in Asset Job editing page
// The left column is AnnotationCanvas (for drawing bounding boxes on an image)
// The right column features form submission information and buttons
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  ClickableTag,
  Dropdown,
  Tag,
  Tooltip,
} from '@makeably/creativex-design-system';
import AnnotationCanvas from 'components/admin/annotation/AnnotationCanvas';
import { positionProps as boundingBoxProps } from 'components/admin/annotation/BoundingBox';
import {
  assetProps,
  logoProps,
} from 'components/admin/annotation/LogoDetection';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import styles from 'components/admin/annotation/LogoAnnotationCard.module.css';

export const propTypes = {
  asset: PropTypes.shape(assetProps).isRequired,
  boundingBoxes: PropTypes.arrayOf(PropTypes.shape(boundingBoxProps)).isRequired,
  formUrl: PropTypes.string.isRequired,
  logo: PropTypes.shape(logoProps).isRequired,
};

const SCALED_WIDTH = 680;

const skipDropdownProps = {
  menuProps: {
    size: 'medium',
    position: 'bottom-right',
  },
  options: [
    {
      label: 'Non-Brand Logos Present',
      value: 'Non-Brand Logos Present',
    },
    {
      label: 'More Than 5 Examples',
      value: 'More Than 5 Examples',
    },
    {
      label: 'Unsure/Escalate',
      value: 'Unsure/Escalate',
    },
    {
      label: 'Broken Asset',
      value: 'Broken Asset',
    },
  ],
  placeholder: 'Skip',
  size: 'medium',
};

function getFormattedBoxes(asset, boxes) {
  const downscaleFactor = SCALED_WIDTH < asset.width ? (SCALED_WIDTH / asset.width) : 1.0;
  return boxes.map((box) => (
    {
      top: box.top * downscaleFactor,
      left: box.left * downscaleFactor,
      height: box.height * downscaleFactor,
      width: box.width * downscaleFactor,
    }
  ));
}

function LogoAnnotationCard({
  asset,
  boundingBoxes: boxes,
  formUrl,
  logo,
}) {
  const scaleFactor = SCALED_WIDTH < asset.width ? (asset.width / SCALED_WIDTH) : 1.0;

  const [boundingBoxes, setBoundingBoxes] = useState(getFormattedBoxes(asset, boxes));
  const [noAnnotations, setNoAnnotations] = useState(
    boundingBoxes.length === 0
    && asset.job.state === 'completed'
    && !asset.job.skipReason,
  );
  const [currentBoxIdx, setCurrentBoxIdx] = useState(boxes.length);

  function onUpdate(boxPosition) {
    const updatedBox = { ...boxPosition };
    if (boundingBoxes.length === currentBoxIdx) {
      setBoundingBoxes((prevBoxes) => [...prevBoxes, updatedBox]);
    } else {
      setBoundingBoxes(boundingBoxes.map((box, index) => {
        if (index === currentBoxIdx) return updatedBox;

        return box;
      }));
    }
  }

  function onCreate() {
    setCurrentBoxIdx((prevId) => prevId + 1);
    setNoAnnotations(false);
  }

  function onRemove(boxIdx) {
    if (boxIdx !== currentBoxIdx) setCurrentBoxIdx((prevId) => prevId - 1);
    setBoundingBoxes((prev) => {
      prev.splice(boxIdx, 1);
      return prev;
    });
  }

  function renderBoxTag(box, index) {
    if (index === currentBoxIdx) return undefined;

    return (
      <ClickableTag
        key={index}
        label={(index + 1).toString()}
        onRemove={() => onRemove(index)}
      />
    );
  }

  function renderBoxInput(name, value) {
    const formattedName = `bounding_boxes[][${name}]`;
    return <HiddenInput name={formattedName} value={value * scaleFactor} />;
  }

  function renderBoxInputs(box, index) {
    if (index === currentBoxIdx) return undefined;
    return (
      <div>
        { renderBoxInput('top', box.top) }
        { renderBoxInput('left', box.left) }
        { renderBoxInput('height', box.height) }
        { renderBoxInput('width', box.width) }
      </div>
    );
  }

  const selectedSkip = {
    label: asset.job.skipReason,
    value: asset.job.skipReason,
  };

  function skipWithReason({ value }) {
    const skipInput = document.getElementById('skip_input');
    skipInput.setAttribute('value', value);
    document.form.submit();
  }

  return (
    <Card className="u-flexRow u-marginBottom-16">
      <AnnotationCanvas
        assetUrl={asset.url}
        boundingBoxes={boundingBoxes}
        createBox={() => onCreate()}
        currentBoxIdx={currentBoxIdx}
        removeDrawingBox={() => onRemove(currentBoxIdx)}
        updateDrawingBox={(boxPosition) => onUpdate(boxPosition)}
      />
      <div className="u-flexGrow u-marginLeft">
        <Card className="u-marginBottom-16">
          <h5 className="u-marginBottom-16">{ `Logo: ${logo.label}` }</h5>
          <div className="row u-marginTop-8">
            { noAnnotations && <Tag color="red" label="No Annotations" /> }
            { boundingBoxes.map(renderBoxTag) }
          </div>
          <div className="u-flexRow u-marginBottom-16">
            <div className="t-caption-1 u-marginRight">Example</div>
            <Tooltip
              direction="right"
              label="Annotations should match this example as closely as possible, but slight variations such as size/color can also be considered."
            />
          </div>
          <div className="u-flexRow">
            {
              logo.exampleUrls.map((url) => (
                <div key={url} className="u-marginRight">
                  <img
                    key={url}
                    alt="target-logo-example"
                    className={styles.exampleImage}
                    src={url}
                  />
                </div>
              ))
            }
          </div>
        </Card>
        <form action={formUrl} method="post" name="form">
          <AuthenticityTokenInput />
          <input name="_method" type="hidden" value="patch" />
          <input id="skip_input" name="skip_reason" type="hidden" />
          { boundingBoxes.map(renderBoxInputs) }
          <div className="u-flexRow u-justifySpaceBetween">
            <Button
              disabled={currentBoxIdx > 0}
              label="Nothing to bound"
              type="submit"
              variant="secondary"
            />
            <Button
              disabled={currentBoxIdx === 0}
              label="Submit"
              type="submit"
              variant="primary"
            />
            <Dropdown
              {...skipDropdownProps}
              selected={selectedSkip}
              onChange={(opt) => skipWithReason(opt)}
            />
          </div>
        </form>
      </div>
    </Card>
  );
}

LogoAnnotationCard.propTypes = propTypes;

export default LogoAnnotationCard;
