import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
} from '@duik/it';
import { Button } from '@makeably/creativex-design-system';
import GuidelineCheckDisplay, { checkProps } from 'components/admin/check_evaluation/GuidelineCheckDisplay';
import { getAuthenticityToken } from 'utilities/requests';
import {
  adminProcessControlCheckPath,
  editAdminProcessControlCheckUrl,
} from 'utilities/routes';
import styles from './ProcessControlEdit.module.css';

const propTypes = {
  check: PropTypes.shape({
    ...checkProps,
    auditAssetId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    brand: PropTypes.string,
    channel: PropTypes.string,
    market: PropTypes.string,
    passed: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
};

function formatForModal(check) {
  return {
    ...check,
    detailsDisplay: [
      {
        label: 'Brand',
        value: check.brand ?? 'N/A',
      },
      {
        label: 'Channel',
        value: check.channel ?? 'N/A',
      },
      {
        label: 'Market',
        value: check.market ?? 'N/A',
      },
      {
        label: 'Type',
        value: check.type ?? 'N/A',
      },
      {
        label: 'Status',
        value: check.status,
      },
    ],
    headersDisplay: [
      { value: check.organization },
      {
        label: 'Audit Asset ID',
        value: check.auditAssetId,
      },
      {
        label: 'Guideline Check ID',
        value: check.checkId,
      },
    ],
  };
}

function ProcessControlEdit({ check }) {
  const {
    id,
    passed: initialPassed,
  } = check;

  const [passed, setPassed] = useState(initialPassed);
  const [evaluating, setEvaluating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [loading, setLoading] = useState(true);

  const successMessage = 'Process control check saved';

  const handleEvaluate = async () => {
    const url = adminProcessControlCheckPath(id);
    const formToken = getAuthenticityToken();

    const formData = new FormData();
    formData.append('authenticity_token', formToken);
    formData.append('passed', passed);

    try {
      setEvaluating(true);
      setErrorMessage(null);
      const response = await fetch(url, {
        method: 'PATCH',
        body: formData,
      });

      if (response.ok) {
        const success = await response.json();

        if (success) {
          setShowSuccessBanner(true);
        } else {
          setErrorMessage('Could not save process control check');
        }
      } else {
        setErrorMessage(`Error saving process control check: ${response.statusText}`);
      }
    } catch (error) {
      setErrorMessage(`Error saving process control check: ${error}`);
    } finally {
      setEvaluating(false);
    }
  };

  return (
    <>
      { showSuccessBanner && <h4 className={styles.success}>{ successMessage }</h4> }
      <Widget className={styles.container}>
        <WidgetContent>
          <GuidelineCheckDisplay
            check={formatForModal(check)}
            editUrl={editAdminProcessControlCheckUrl(id)}
            errorMessage={errorMessage}
            passed={passed}
            setLoading={(value) => setLoading(value)}
            setPassed={(value) => setPassed(value)}
          />
          <div className={styles.buttonContainer}>
            <Button
              disabled={loading || evaluating || passed === null}
              label="Evaluate"
              variant="primary"
              onClick={handleEvaluate}
            />
          </div>
        </WidgetContent>
      </Widget>
    </>
  );
}

ProcessControlEdit.propTypes = propTypes;

export default ProcessControlEdit;
