// A dumb component that shows a spinner.
import React from 'react';
import PropTypes from 'prop-types';

function Loader({ classNames }) {
  return (
    <div className={classNames}>
      <div className="center-align">
        <div className="loader-wrapper">
          <div className="preloader-wrapper active">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle" />
              </div>
              <div className="gap-patch">
                <div className="circle" />
              </div>
              <div className="circle-clipper right">
                <div className="circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Loader.propTypes = { classNames: PropTypes.string };
Loader.defaultProps = { classNames: undefined };

export default Loader;
