import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import DarkHeaderCard from "components/reusable/dark_header_card"
import { HiddenInput } from "components/reusable/HiddenInput"
import MixpanelTracker from "components/utils/MixpanelTracker"
import {
  Button,
  Checkbox,
  Icon,
  RadioGroup,
  TextInput,
} from '@makeably/creativex-design-system'

# This component renders a multi-step form for creating/editing custom filters. Each custom filter
#   has a name, a dimension, and one or many filter_options. Each filter_option has a name and multiple
#   values
# Ex. A custom_filter named "Regions" may belong to the "market" dimension. Possible filter_option:
#   * name: "North America", values: ["USA", "Canada"]
#
# There are four "actions" that this form supports and each action contains a different set of
#   form "steps"
#
# The actions and steps are as follows:
#   * The "create_filter" action contains the following steps:
#     * filter_name
#     * filter_dimension
#     * filter_option_name
#     * filter_option_values
#   * The "edit_filter" action contains the following steps:
#     * filter_name
#   * The "create_filter_option" action contains the following steps:
#     * filter_option_name
#     * filter_option_values
#   * The "edit_filter_option" action contains the following steps:
#     * filter_option_name
#     * filter_option_values
#
# There is a render method for each form step, and its rendering depends on both the current form
#   action and step
#
# For each each step, the input data can be pre-populated (for edit actions) or blank (for create
#   actions)
class CustomFiltersFormContainer extends React.Component
  NAME_STEP = 'filter_name'
  DIMENSION_STEP = 'filter_dimension'
  OPTION_NAME_STEP = 'filter_option_name'
  OPTION_VALUES_STEP = 'filter_option_values'

  @propTypes =
    # Dropdown options allowing users to select a dimension for their filter
    dimensionOptions: PropTypes.arrayOf(
      PropTypes.shape(
        value: PropTypes.string
        label: PropTypes.string
      )
    )
    # We pass in option values for each each dimension grouped alphabetically by their starting
    #   character (Ex: 'markets' -> [{'A', ['Antigua', 'Afghanistan']}])
    filterOptionGroupsByDimension: PropTypes.arrayOf(
      PropTypes.shape(
        dimension: PropTypes.string
        optionGroups: PropTypes.arrayOf(
          PropTypes.shape(
            name: PropTypes.string
            options: PropTypes.arrayOf(
              PropTypes.shape(
                label: PropTypes.string
                value: PropTypes.string
              )
            )
          )
        )
      )
    )
    # For edit actions, we want to prepopulate the inputs associated with each form step and allow
    #   the user to edit the value from there
    formInputValues: PropTypes.shape(
      dimension: PropTypes.string
      id: PropTypes.number
      optionName: PropTypes.string
      optionValues: PropTypes.arrayOf(PropTypes.string)
      name: PropTypes.string
    )
    # Array of form steps that varies depending on the form action
    formSteps: PropTypes.arrayOf(
      PropTypes.shape(
        cardHeader: PropTypes.string
        description: PropTypes.string
        modalHeader: PropTypes.string
        name: PropTypes.string
      )
    )
    formTitle: PropTypes.string

  constructor: (props) ->
    super(props)

    @state =
      name: @props.formInputValues.name || ''
      dimension: @props.formInputValues.dimension || ''
      optionName: @props.formInputValues.optionName || ''
      optionValues: @props.formInputValues.optionValues || []
      completedSteps: []
      currentStep: @props.formSteps[0]
      isSaving: false

  setName: (name) =>
    @setState(name: name)

  setDimension: (event) =>
    @setState(dimension: event.target.value)

  setOptionName: (name) =>
    @setState(optionName: name)

  setOptionValues: (checkedValue, checked) =>
    if checked
      updatedOptionValues = @state.optionValues
      updatedOptionValues.push(checkedValue)
      @setState(optionValues: updatedOptionValues)
    else
      @setState(optionValues: _.reject(@state.optionValues, (value) -> value is checkedValue))

  removeOptionValue: (removedValue) =>
    =>
      @setState(optionValues: _.reject(@state.optionValues, (value) -> value is removedValue))

  trackFormSubmit: ->
    MixpanelTracker.trackButtonClick("Save")

  optionGroupsForDimension: =>
    _.find(@props.filterOptionGroupsByDimension, (option) =>
      option.dimension is @state.dimension
    ).optionGroups

  currentStepIdx: ->
    _.indexOf(@props.formSteps, @state.currentStep)

  onBackClick: =>
    completedSteps = @state.completedSteps
    completedSteps.pop()

    @setState(
      currentStep: @props.formSteps[@currentStepIdx() - 1],
      completedSteps: completedSteps
      optionValues: []
    )

  onNextClick: =>
    completedSteps = @state.completedSteps
    completedSteps.push(@state.currentStep.name)

    @setState(
      currentStep: @props.formSteps[@currentStepIdx() + 1],
      completedSteps: completedSteps
    )

  onSaveClick: (event) =>
    @setState(isSaving: true)
    this.trackFormSubmit()
    event.target.form.submit()

  currentStepIncomplete: ->
    switch @state.currentStep.name
      when NAME_STEP then !@state.name
      when DIMENSION_STEP then !@state.dimension
      when OPTION_NAME_STEP then !@state.optionName
      when OPTION_VALUES_STEP then _.isEmpty(@state.optionValues)

  renderNameStep: (completed) ->
    if @state.currentStep.name is NAME_STEP
      `<div>
        <TextInput
          placeholder='Name'
          name='name'
          size='large'
          value={this.state.name}
          onChange={this.setName}
        />
        <span className='customFiltersHelperText'>
          Ex. Regions, Agencies, Product Categories
        </span>
      </div>`
    else if completed
      `<div>
        <span>Name: {this.state.name}</span>
        <HiddenInput name='name' value={this.state.name}/>
      </div>`

  renderDimensionStep: (completed) =>
    if @state.currentStep.name is DIMENSION_STEP
      `<div>
        <RadioGroup
          name='dimension'
          options={this.props.dimensionOptions}
          value={this.state.dimension}
          onChange={this.setDimension}
        />
      </div>`
    else if completed
      `<div>
         <span>Category: {this.state.dimension}</span>
         <HiddenInput name='dimension' value={this.state.dimension}/>
       </div>`

  renderOptionNameStep: (completed) ->
    if @state.currentStep.name is OPTION_NAME_STEP
      `<div>
        <TextInput
          name='option_name'
          size='large'
          value={this.state.optionName}
          onChange={this.setOptionName}
        />
       <span className='customFiltersHelperText'>Ex. North America, Biscuits, Cookies</span>
      </div>`
    else if completed
      `<div>
        <span>Dropdown Option: {this.state.optionName}</span>
        <HiddenInput name='option_name' value={this.state.optionName}/>
      </div>`

  renderOptionValueHiddenInput: (value) ->
    `<HiddenInput key={value} name='option_values[]' value={value}/>`

  renderOptionValuesStep: =>
    if @state.currentStep.name is OPTION_VALUES_STEP
      `<div>
        {this.state.optionValues.map(this.renderOptionValueHiddenInput)}
        {this.optionGroupsForDimension().map(this.renderOptionsGroup)}
       </div>`

  renderOptionsGroup: (optionsGroup, key) =>
    checkBoxes = optionsGroup.options.map(@renderOptionCheckbox)

    `<div key={key} className='u-marginBelow'>
      <div className='u-marginBelowSm'>{optionsGroup.name}</div>
      <div className='u-flexColumn customFiltersOptionsGroup'>
        {checkBoxes}
      </div>
    </div>`

  renderOptionCheckbox: (option, idx) =>
    checked = _.include(@state.optionValues, option.value)

    `<Checkbox
      checked={checked}
      key={idx}
      label={option.label}
      onChange={(e) => this.setOptionValues(option.value, e.target.checked)}
    />`

  stepContent: (step, stepComplete) =>
    if step.name is NAME_STEP
      @renderNameStep(stepComplete)
    else if step.name is DIMENSION_STEP
      @renderDimensionStep(stepComplete)
    else if step.name is OPTION_NAME_STEP
      @renderOptionNameStep(stepComplete)
    else if step.name is OPTION_VALUES_STEP
      @renderOptionValuesStep(stepComplete)

  renderStep: (step) =>
    stepComplete = _.include(@state.completedSteps, step.name)
    classes = classNames(
      'customFiltersFormStep',
      'customFiltersFormStep--completed': stepComplete
    )

    `<div key={step.name} className={classes}>
       {this.stepContent(step, stepComplete)}
     </div>`

  renderSelectedOptionValue: (value, key) =>
    options = _.flatten(_.map(@optionGroupsForDimension(), (optionGroup) -> optionGroup.options))
    label = _.find(options, (option) -> option.value is value).label

    `<div key={key} className='customFiltersSelectedOptionValue'>
       <span>{label}</span>
       <span className='customFiltersRemoveValue' onClick={this.removeOptionValue(value)}>
         <Icon color='darkPurple' name='closeX'/>
       </span>
     </div>`

  renderSelectedOptionValues: ->
    if @state.currentStep.name is OPTION_VALUES_STEP and !_.isEmpty(@state.optionValues)
      `<div className='u-flexRow u-flexWrap customFiltersSelectedOptionValues'>
        {this.state.optionValues.map(this.renderSelectedOptionValue)}
      </div>`

  renderSteps: ->
    header = `<h5>{this.state.currentStep.cardHeader}</h5>`
    `<div>
       <DarkHeaderCard
         className='scrollable customFiltersFormSteps'
         headerClass='u-textUppercase customFiltersFormSteps-header'
         headerContent={header}
       >
         {this.props.formSteps.map(this.renderStep)}
       </DarkHeaderCard>
       {this.renderSelectedOptionValues()}
     </div>`

  renderFormHeader: ->
    `<div>
       <div className='u-textUppercase customFiltersFormHeader-title'>{this.props.formTitle}</div>
       <h5>{this.state.currentStep.modalHeader}</h5>
       <div>{this.state.currentStep.description}</div>
     </div>`

  renderEditHiddenInput: ->
    # The custom_filter_id will only be passed in on form edit actions
    if @props.formInputValues.id
      `<HiddenInput name='custom_filter_id' value={this.props.formInputValues.id}/>`

  renderBackButton: ->
    if @currentStepIdx() > 0
      `<Button
        label='Back'
        variant='secondary'
        onClick={this.onBackClick}
      />`

  renderNextButton: ->
    if @currentStepIdx() < _.size(@props.formSteps) - 1
      `<Button
        label='Next'
        disabled={this.currentStepIncomplete()}
        variant='secondary'
        onClick={this.onNextClick}
      />`

  renderSaveButton: ->
    if @currentStepIdx() is _.size(@props.formSteps) - 1
      `<Button
        disabled={this.currentStepIncomplete() || this.state.isSaving}
        label='Save'
        type='submit'
        onClick={this.onSaveClick}
      />`
    else
      # This prevents users from hitting enter to submit the form before the last step
      `<input className='u-displayNone' disabled={true} type='submit'/>`

  renderFormButtons: ->
    `<div className='u-flexRow u-justifyFlexEnd customFiltersFormButtons'>
       {this.renderBackButton()}
       {this.renderNextButton()}
       {this.renderSaveButton()}
     </div>`

  render: ->
    `<div>
       <div className='customFiltersFormHeader'>
         {this.renderFormHeader()}
       </div>
       <div className='customFiltersFormBody'>
         {this.renderSteps()}
         {this.renderEditHiddenInput()}
         {this.renderFormButtons()}
       </div>
     </div>`

export default CustomFiltersFormContainer
