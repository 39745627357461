import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
} from '@duik/it';
import CardHeader from 'components/reusable/CardHeader';

export const logProps = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export const propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      ...logProps,
    }),
  ).isRequired,
};

function ChangeLogEntry(props) {
  const {
    log: {
      date,
      description,
      status,
      title,
      userName,
    },
  } = props;

  const statusClasses = classNames(
    'changeLogStatus',
    {
      'changeLogStatus--inProgress': status === 'In Progress',
      'changeLogStatus--complete': status === 'Complete',
    },
  );

  return (
    <div className="u-flexRow u-spaceBetween changeLogEntry">
      <div className="changeLogEntry-date">{ date }</div>
      <div className="changeLogDetail">
        <div className="u-flexRow u-flexAlignCenter">
          <div className="changeLogDetail-title">{ title }</div>
          <div className={statusClasses}>{ status }</div>
        </div>
        <div className="changeLogDetail-destription">
          { description }
          { ' ' }
          by
          { ' ' }
          { userName }
        </div>
      </div>
    </div>
  );
}
ChangeLogEntry.propTypes = {
  log: PropTypes.shape(logProps).isRequired,
};

function ChangeLog(props) {
  const { logs } = props;

  if (logs.length === 0) return null;

  return (
    <Widget className="changeLog">
      <CardHeader header="Change Log" />
      <WidgetContent>
        { logs.map((log) => <ChangeLogEntry key={log.id} log={log} />) }
      </WidgetContent>
    </Widget>
  );
}

ChangeLog.propTypes = propTypes;

export default ChangeLog;
