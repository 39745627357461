import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  TextInput,
} from '@makeably/creativex-design-system';
import { HiddenInput } from 'components/reusable/HiddenInput';
import UserInfo from 'components/utils/content/UserInfo';
import ObjectHelper from 'components/utils/ObjectHelper';

const optionProps = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
});

const propTypes = {
  canModifyCompany: PropTypes.bool.isRequired,
  companyOptions: PropTypes.arrayOf(optionProps).isRequired,
  marketOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFieldChange: PropTypes.func.isRequired,
  company: optionProps,
  editingSelf: PropTypes.bool,
  email: PropTypes.string,
  firstName: PropTypes.string,
  jobFunction: optionProps,
  jobTitle: optionProps,
  lastName: PropTypes.string,
  market: optionProps,
};

const defaultProps = {
  company: undefined,
  editingSelf: false,
  email: undefined,
  firstName: undefined,
  jobFunction: undefined,
  jobTitle: undefined,
  lastName: undefined,
  market: undefined,
};

function renderOrgAffiliateInput(editingSelf, props) {
  if (editingSelf) return null;

  const { company } = props;
  if (!company) return null;

  return (
    <HiddenInput
      data-testid="orgAffiliateIdInput"
      name="user[organization_affiliate_id]"
      value={company.value}
    />
  );
}

function renderJobFunctionInput(jobFunction) {
  if (!jobFunction) return null;

  return (
    <HiddenInput
      data-testid="jobFunctionInput"
      name="user[job_function]"
      value={jobFunction.value}
    />
  );
}

function renderJobTitleInput(jobTitle) {
  if (!jobTitle) return null;

  return (
    <HiddenInput
      data-testid="jobTitleInput"
      name="user[job_title]"
      value={jobTitle.value}
    />
  );
}

function renderMarketInput(market) {
  if (!market) return null;

  return (
    <HiddenInput
      data-testid="marketInput"
      name="user[market]"
      value={market.value}
    />
  );
}

function formatMarketOptions(marketOptions) {
  const formattedMarketOptions = ObjectHelper.valuesToObjects(marketOptions);
  formattedMarketOptions.unshift({
    label: 'None',
    value: null,
  });

  return formattedMarketOptions;
}

function UserFields(props) {
  const {
    canModifyCompany,
    company,
    companyOptions,
    editingSelf,
    email,
    firstName,
    jobFunction,
    jobTitle,
    lastName,
    market,
    marketOptions,
    onFieldChange,
  } = props;

  const formattedMarket = market ?? {
    label: 'None',
    value: null,
  };

  const formattedMarketOptions = useMemo(
    () => formatMarketOptions(marketOptions),
    [marketOptions],
  );

  return (
    <div>
      { renderOrgAffiliateInput(editingSelf, props) }
      { renderJobFunctionInput(jobFunction) }
      { renderJobTitleInput(jobTitle) }
      { renderMarketInput(market) }
      <div className="userSettings-basicInfo u-flexRow">
        <div>
          <TextInput
            label="Email"
            name="user[email]"
            size="large"
            value={email}
            onChange={(value) => onFieldChange(value, 'email')}
          />
          <TextInput
            label="First Name"
            name="user[first_name]"
            size="large"
            value={firstName}
            onChange={(value) => onFieldChange(value, 'firstName')}
          />
          <TextInput
            label="Last Name"
            name="user[last_name]"
            size="large"
            value={lastName}
            onChange={(value) => onFieldChange(value, 'lastName')}
          />
        </div>
        <div className="u-marginLeftLg">
          <Dropdown
            disabled={!canModifyCompany}
            label="Partner"
            menuProps={{ size: 'large' }}
            options={companyOptions}
            selected={company}
            size="large"
            onChange={(v) => onFieldChange(v, 'company')}
          />
          <Dropdown
            label="Job Function"
            menuProps={{ size: 'medium' }}
            options={ObjectHelper.valuesToObjects(UserInfo.jobFunctionOptions())}
            selected={jobFunction}
            size="large"
            onChange={(v) => onFieldChange(v, 'jobFunction')}
          />
          <Dropdown
            label="Role"
            menuProps={{ size: 'medium' }}
            options={ObjectHelper.valuesToObjects(UserInfo.jobTitleOptions())}
            selected={jobTitle}
            size="large"
            onChange={(v) => onFieldChange(v, 'jobTitle')}
          />
          <Dropdown
            label="Market"
            menuProps={{ size: 'large' }}
            options={formattedMarketOptions}
            selected={formattedMarket}
            size="large"
            onChange={(v) => onFieldChange(v, 'market')}
          />
        </div>
      </div>
    </div>
  );
}

UserFields.propTypes = propTypes;
UserFields.defaultProps = defaultProps;

export default UserFields;
