import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Divider } from '@duik/it';
import EmptyDataView from 'components/account_setup/EmptyDataView';
import Instructions, { getContentTag } from 'components/account_setup/Instructions';
import Stepper, { propTypes as stepperProps } from 'components/molecules/Stepper';
import FormSubmit from 'components/reusable/FormSubmit';
import { track } from 'utilities/mixpanel';

export const propTypes = {
  ...stepperProps,
  channel: PropTypes.string.isRequired,
  // Content data shape depends on the content component being rendered, see
  // prop definition of specific component in "steps" dir for details
  // eslint-disable-next-line react/forbid-prop-types
  contentData: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  backUrl: PropTypes.string,
  // Only supported for Business Centers/Managers currently. Defaults to true
  hasData: PropTypes.bool,
};

const defaultProps = {
  backUrl: undefined,
  hasData: true,
};

const adAccountAssociation = 'associate_ad_accounts';
const confirmationStep = 'confirmation';

function renderBackUrl(backUrl) {
  if (!backUrl) return null;

  const onBackClicked = () => track('Click Back');

  return (
    <a className="u-marginRight" href={backUrl} onClick={onBackClicked}>
      Back
    </a>
  );
}

function AccountLinking({
  backUrl,
  channel,
  contentData,
  currentStep,
  formId,
  formUrl,
  hasData,
  steps,
  view,
}) {
  const isAdAccountAssociationPage = view === adAccountAssociation;
  const isConfirmationPage = view === confirmationStep;
  const submitLabel = isConfirmationPage ? 'Confirm' : 'Continue';

  const [formComplete, setFormComplete] = useState(isConfirmationPage);
  const [mixpanelData, setMixpanelData] = useState(null);

  const contentClasses = classNames(
    'content',
    'u-marginAboveLg',
    {
      'content-adAccountAssignment': isAdAccountAssociationPage,
      'content-confirmation': isConfirmationPage,
    },
  );

  const ContentTag = getContentTag(channel, view);
  const formData = {
    id: formId,
    url: formUrl,
  };

  const onFormSubmit = () => {
    if (mixpanelData) {
      track(view, {
        platform: channel,
        ...mixpanelData,
      });
    }
  };

  let instructionView = view;
  if (view === 'business_managers') {
    // if all business manager rows are disabled, we show a different instruction view
    if (contentData.table.rows.every(({ disabled }) => disabled)) {
      instructionView = `${view}_none_admin`;
    }
  }

  return (
    <div className="accountSetup u-flexColumn u-flexAlignCenter">
      <Stepper currentStep={currentStep} steps={steps} />
      <div className={contentClasses}>
        { hasData
          && (
            <>
              <Instructions channel={channel} view={instructionView} />
              <Divider margin />
              <div className="sectionContent">
                <ContentTag
                  addMixpanelData={(data) => setMixpanelData(data)}
                  formData={formData}
                  onFormAction={(complete) => setFormComplete(complete)}
                  {...contentData} // eslint-disable-line react/jsx-props-no-spreading
                />
              </div>
              <div className="actions u-flexRow u-justifyFlexEnd u-flexAlignCenter">
                { renderBackUrl(backUrl) }
                <FormSubmit
                  disabled={!formComplete}
                  form={formId}
                  label={submitLabel}
                  primary
                  onSubmit={onFormSubmit}
                />
              </div>
            </>
          ) }
        { !hasData && <EmptyDataView channel={channel} view={view} /> }
      </div>
    </div>
  );
}

AccountLinking.propTypes = propTypes;
AccountLinking.defaultProps = defaultProps;

export default AccountLinking;
