import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@duik/it';
import {
  Checkbox,
  Icon,
} from '@makeably/creativex-design-system';
import AdAccountContainer from 'components/account_setup/meta_ads/steps/AdAccountContainer';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  adAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  info: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  selectors: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      options: PropTypes.object,
    }),
  ).isRequired,
  onAdAccountChecked: PropTypes.func.isRequired,
  onOptionSelect: PropTypes.func.isRequired,
};

function AdAccountAssignmentSection({
  adAccounts,
  info,
  selectors,
  onAdAccountChecked,
  onOptionSelect,
}) {
  const [collapsed, setCollapsed] = useState(false);

  const completedAdAccounts = adAccounts.filter((adAct) => adAct.complete);
  const isChecked = adAccounts.every((adAct) => adAct.checked);

  const onBusinessChecked = () => {
    adAccounts.forEach((adAccount) => {
      onAdAccountChecked(
        !isChecked,
        adAccount.apiId,
        adAccount.businessCenterApiId,
      );
    });
  };

  const renderAdAccount = (adAccount) => {
    const onCheck = () => {
      onAdAccountChecked(
        !adAccount.checked,
        adAccount.apiId,
        adAccount.businessCenterApiId,
      );
    };

    const onSelect = (option, name) => (
      onOptionSelect(
        option,
        name,
        adAccount.apiId,
        adAccount.businessCenterApiId,
      )
    );

    return (
      <AdAccountContainer
        key={adAccount.apiId}
        adAccount={adAccount}
        checked={adAccount.checked}
        selectors={selectors}
        onCheck={onCheck}
        onSelect={onSelect}
      />
    );
  };

  return (
    <div className="adAccountGroupContainer">
      <div className="adAccountGroupContainer-header u-justifySpaceBetween u-flexRow u-flexAlignCenter">
        <div className="u-flexRow u-flexAlignCenter">
          <Checkbox autoComplete="off" checked={isChecked} onChange={() => onBusinessChecked()} />
          <h5 className="adAccountGroupContainer-headerText u-marginLeft">{ info.name }</h5>
          <Button className="collapsibleButton" clear sm square onClick={() => setCollapsed(!collapsed)}>
            <Icon name={collapsed ? 'chevronUp' : 'chevronDown'} />
          </Button>
        </div>
        <div>
          Ad Accounts Assigned:
          { ' ' }
          { completedAdAccounts.length }
          { ' ' }
          of
          { ' ' }
          { adAccounts.length }
        </div>
      </div>
      <div>
        { !collapsed && adAccounts.map((adAccount) => renderAdAccount(adAccount)) }
      </div>
    </div>
  );
}

AdAccountAssignmentSection.propTypes = propTypes;

export default AdAccountAssignmentSection;
