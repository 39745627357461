import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Search,
} from '@makeably/creativex-design-system';
import ItemsTable from 'components/molecules/ItemsTable';
import { saveFile } from 'utilities/file';
import {
  getItemSortBy,
  getItemsCsv,
} from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import styles from './IndustryBrands.module.css';

const brandProps = PropTypes.shape({
  companyName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

const propTypes = {
  name: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(brandProps),
};

const defaultProps = {
  brands: [],
};

const headers = [
  {
    key: 'name',
    label: 'Brands',
  },
  {
    key: 'company',
    label: 'Company',
  },
];

function getItems(brands) {
  return brands.map((brand) => setItemElement({
    id: { value: brand.id },
    name: { value: brand.name },
    company: { value: brand.companyName },
  }));
}

function IndustryBrands({
  brands,
  name,
}) {
  const [filteredBrands, setFilteredBrands] = useState(brands);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState();
  const [sortedItems, setSortedItems] = useState([]);

  const onExportCsv = () => {
    const fileName = 'industry_brands.csv';
    const csv = getItemsCsv(sortedItems, headers);
    saveFile(fileName, csv);
  };

  useEffect(() => {
    const filtered = brands.filter(({
      name: brandName,
      companyName,
    }) => {
      if (search) {
        const term = search.toLowerCase();

        return companyName.toLowerCase().includes(term) || brandName.toLowerCase().includes(term);
      }

      return true;
    });

    setFilteredBrands(filtered);
  }, [search]);

  useEffect(() => {
    setItems(getItems(filteredBrands));
  }, [filteredBrands]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    } else {
      setSortedItems(items);
    }
  }, [items, sort]);

  return (
    <Card className={styles.card}>
      <div className="u-flexColumn u-gap-16">
        <h2>{ `${name} (${brands.length})` }</h2>
        <Divider />
        <div className="u-flexRow u-justifyBetween">
          <Search
            value={search}
            onChange={setSearch}
          />
          <Button
            label="Export CSV"
            onClick={() => onExportCsv()}
          />
        </div>
        <div className={styles.table}>
          <ItemsTable
            headers={headers}
            items={sortedItems}
            page={page}
            sort={sort}
            onPageChange={(p) => setPage(p)}
            onSortChange={(value) => setSort(value)}
          />
        </div>
        { filteredBrands.length === 0 && (
          <div className="u-flexRow u-justifyCenter t-empty">
            Industry is not associated with any companies or brands.
          </div>
        ) }
      </div>
    </Card>
  );
}

IndustryBrands.propTypes = propTypes;
IndustryBrands.defaultProps = defaultProps;

export default IndustryBrands;
