import React from "react"
import PropTypes from "prop-types"
import SearchableSelect from "components/reusable/SearchableSelect"
import {
  Button,
  FormGroup,
  FormGroupContainer,
  Select,
  Widget,
  WidgetContent,
  WidgetHeader
} from  "@duik/it"

# Responsible for displaying available brands, markets and org affiliates for an ad account to link to.
class PlatformAccountForm extends React.Component
  @propTypes =
    accountClass: PropTypes.string
    accountState: PropTypes.string
    affiliateSelectProps: PropTypes.object
    organizationAffiliateId: PropTypes.number
    stateSelectProps: PropTypes.object
    userId: PropTypes.number
    userSelectProps: PropTypes.object

  constructor: (props) ->
    super(props)

    accountState =
      if props.accountState is 'active'
        'active'
      else
        # There are multiple non-active states, but we use inactive to
        # represent a need to deactivate the account
        'inactive'

    @state =
      accountState: accountState
      orgAffiliateId: props.organizationAffiliateId || ''
      userId: props.userId || ''

  onSelectChange: (option, name) =>
    @setState("#{name}": option.value)

  renderAccountState: ->
    options = @props.stateSelectProps.options
    selectedOption = _.findWhere(options, { value: @state.accountState })

    `<Select
      activeOption={selectedOption}
      label='Status'
      name='accountState'
      onOptionClick={this.onSelectChange}
      options={options}
    />`

  renderAffiliateSelect: ->
    options = @props.affiliateSelectProps.options
    selectedOption = _.findWhere(options, { value: @state.orgAffiliateId })

    `<SearchableSelect
      activeOption={selectedOption}
      label='Partner'
      name='orgAffiliateId'
      onOptionClick={this.onSelectChange}
      options={options}
    />`

  renderUserSelect: ->
    options = @props.userSelectProps.options
    selectedOption = _.findWhere(options, { value: @state.userId })

    `<SearchableSelect
      activeOption={selectedOption}
      label='Account Linked By'
      name='userId'
      onOptionClick={this.onSelectChange}
      options={options}
    />`

  validForm: ->
    @state.accountState and
      @state.orgAffiliateId and
      @state.userId

  render: ->
    invalidForm = !@validForm()
    `<Widget>
      <WidgetHeader>
        <h5>Modify Account Details</h5>
      </WidgetHeader>
      <WidgetContent>
        <FormGroupContainer className='platformAccountForm'>
          <input
            type='hidden'
            name='account_class'
            value={this.props.accountClass}
          />
          <input
            type='hidden'
            name={this.props.affiliateSelectProps.inputName}
            value={this.state.orgAffiliateId}
          />
          <input
            type='hidden'
            name={this.props.stateSelectProps.inputName}
            value={this.state.accountState}
          />
          <input
            type='hidden'
            name={this.props.userSelectProps.inputName}
            value={this.state.userId}
          />
          <FormGroup>
            {this.renderUserSelect()}
          </FormGroup>
          <FormGroup>
            {this.renderAffiliateSelect()}
          </FormGroup>
          <FormGroup>
            {this.renderAccountState()}
          </FormGroup>
          <FormGroup>
            <Button
              Component='input'
              className='right'
              disabled={invalidForm}
              name='commit'
              type='submit'
              value='Update Account'
            />
          </FormGroup>
        </FormGroupContainer>
      </WidgetContent>
    </Widget>`

export default PlatformAccountForm
