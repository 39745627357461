import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Divider,
  MessageModal,
  MultipleDropdown,
  Pagination,
  TextInput,
  toggleObject,
} from '@makeably/creativex-design-system';
import Campaign, { campaignShape } from 'components/creative_lifecycle/Campaign';
import CreateCampaignModal from 'components/creative_lifecycle/modal/CreateCampaignModal';
import { useViewPage } from 'utilities/mixpanel';
import { getAuthenticityToken } from 'utilities/requests';
import {
  creativeLifecycleCampaignPath,
  creativeLifecycleCampaignsPath,
} from 'utilities/routes';
import styles from './ViewCampaigns.module.css';

const NUM_CAMPAIGNS_PER_PAGE = 5;

const propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.shape(campaignShape)).isRequired,
};

function getOptions(campaigns, key) {
  const values = campaigns.reduce((vals, campaign) => vals.concat(campaign[key]), []);
  const sorted = [...new Set(values)].sort();
  return sorted.map((value) => ({
    label: value,
    value,
  }));
}

function getPageCampaigns(campaigns, page) {
  return campaigns.slice((page - 1) * NUM_CAMPAIGNS_PER_PAGE, page * NUM_CAMPAIGNS_PER_PAGE);
}

function ViewCampaigns({
  brands,
  campaigns,
}) {
  const [allCampaigns, setAllCampaigns] = useState(campaigns);
  const [brandFilter, setBrandFilter] = useState([]);
  const [campaign, setCampaign] = useState({ name: '' });
  const [nameFilter, setNameFilter] = useState([]);
  const [errorTextModal, setErrorTextModal] = useState();
  const [filteredCampaigns, setFilteredCampaigns] = useState(allCampaigns);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCampaigns, setPageCampaigns] = useState(getPageCampaigns(filteredCampaigns, page));
  const [partnerFilter, setPartnerFilter] = useState([]);
  const [showCreateCampaignModal, setShowCreateCampaignModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const numCampaigns = filteredCampaigns.length;
  const hasCampaigns = numCampaigns > 0;
  const brandOptions = getOptions(allCampaigns, 'brand');
  const partnerOptions = getOptions(allCampaigns, 'partners');
  const nameOptions = getOptions(allCampaigns, 'name');
  const hasFilters = brandFilter.length > 0 || partnerFilter.length > 0 || nameFilter.length > 0;

  useViewPage();

  const clearFilterOptions = () => {
    setBrandFilter([]);
    setPartnerFilter([]);
    setNameFilter([]);
    setFilteredCampaigns(allCampaigns);
  };

  useEffect(() => {
    clearFilterOptions();
  }, [allCampaigns]);

  useEffect(() => {
    const brandValues = brandFilter.map(({ value }) => value);
    const partnerValues = partnerFilter.map(({ value }) => value);
    const campaignNameValues = nameFilter.map(({ value }) => value);

    const filtered = allCampaigns.filter(({
      brand, partners, name,
    }) => {
      if (brandValues.length > 0 && !brandValues.includes(brand)) return false;
      if (partnerValues.length > 0 && !partnerValues.some((filter) => partners.includes(filter))) {
        return false;
      }
      if (campaignNameValues.length > 0 && !campaignNameValues.includes(name)) return false;

      return true;
    });

    setFilteredCampaigns(filtered);
    setPage(1);
  }, [brandFilter, partnerFilter, nameFilter]);

  useEffect(() => {
    setPageCampaigns(getPageCampaigns(filteredCampaigns, page));
  }, [filteredCampaigns, page]);

  const addCampaign = ({
    brand, id, name,
  }) => {
    const newCampaign = {
      assets: [],
      brand,
      id,
      name,
      partners: [],
    };

    setAllCampaigns([newCampaign, ...allCampaigns]);
  };

  const handleResponse = async (response) => {
    setIsSubmitting(false);

    if (response.ok) {
      const data = await response.json();
      if (data.success) {
        window.location.href = creativeLifecycleCampaignsPath();
      } else {
        setErrorTextModal(data.error);
      }
    } else {
      setErrorTextModal('Something went wrong, please try again');
    }
  };

  const updateCampaignName = async () => {
    setIsSubmitting(true);
    const response = await fetch(creativeLifecycleCampaignPath(campaign.id), {
      method: 'PATCH',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-CSRF-Token': getAuthenticityToken(),
      },
      body: JSON.stringify({
        name: campaign.name,
      }),
    });

    await handleResponse(response);
  };

  const onEditCampaignNameClose = () => {
    setCampaign({ name: '' });
    setShowEditModal(false);
    setErrorTextModal(undefined);
  };

  const deactivateCampaign = async () => {
    setIsSubmitting(true);
    const response = await fetch(creativeLifecycleCampaignPath(campaign.id), {
      method: 'DELETE',
      headers: { 'X-CSRF-Token': getAuthenticityToken() },
    });

    await handleResponse(response);
  };

  const onDeactivateCampaignClose = () => {
    setCampaign({ name: '' });
    setShowDeactivateModal(false);
    setErrorTextModal(undefined);
  };

  return (
    <>
      <Card padding={false}>
        <div className={styles.header}>
          <div className={styles.controls}>
            <MultipleDropdown
              disabled={brandOptions.length === 0}
              label="Brand"
              options={brandOptions}
              placeholder="All"
              selected={brandFilter}
              size="small"
              onChange={(value) => setBrandFilter((last) => toggleObject(last, value))}
            />
            <MultipleDropdown
              disabled={partnerOptions.length === 0}
              label="Production Partner"
              options={partnerOptions}
              placeholder="All"
              selected={partnerFilter}
              size="small"
              onChange={(value) => setPartnerFilter((last) => toggleObject(last, value))}
            />
            <MultipleDropdown
              disabled={nameOptions.length === 0}
              label="Campaign Name"
              options={nameOptions}
              placeholder="All"
              selected={nameFilter}
              size="small"
              onChange={(value) => setNameFilter((last) => toggleObject(last, value))}
            />
            { hasFilters && (
              <Button
                label="Clear"
                variant="tertiary"
                onClick={() => clearFilterOptions()}
              />
            ) }
          </div>
          <AddNewButton
            label="New Campaign"
            onClick={() => setShowCreateCampaignModal(true)}
          />
        </div>
        <Divider />
        { !hasCampaigns && (
          <div className={classNames(styles.emptyTable, 't-empty')}>
            There are no campaigns to display
          </div>
        ) }
        { hasCampaigns && pageCampaigns.map((c) => (
          <Campaign
            key={c.id}
            campaign={c}
            setCampaign={setCampaign}
            showDeactivateModal={() => setShowDeactivateModal(true)}
            showEditModal={() => setShowEditModal(true)}
          />
        )) }
        { numCampaigns > NUM_CAMPAIGNS_PER_PAGE && (
          <div className={styles.footer}>
            <Pagination
              currentPage={page}
              perPage={NUM_CAMPAIGNS_PER_PAGE}
              total={numCampaigns}
              onPageChange={setPage}
            />
          </div>
        ) }
      </Card>
      <CreateCampaignModal
        brandOptions={brands}
        isOpen={showCreateCampaignModal}
        onClose={() => setShowCreateCampaignModal(false)}
        onSuccess={addCampaign}
      />
      <MessageModal
        actionButtonDisabled={!campaign.name || isSubmitting}
        actionButtonLabel="Save"
        isOpen={showEditModal}
        subtitle="Update campaign name below"
        title="Edit Campaign Name"
        onActionButtonClick={updateCampaignName}
        onClose={onEditCampaignNameClose}
      >
        <TextInput
          size="medium"
          value={campaign.name}
          onChange={(name) => setCampaign((prevState) => ({
            ...prevState,
            name,
          }))}
        />
        <div />
        { errorTextModal && <div className={styles.errorText}>{ errorTextModal }</div> }
      </MessageModal>
      <MessageModal
        actionButtonLabel="Delete"
        actionButtonVariant="destructive"
        isOpen={showDeactivateModal}
        subtitle="This action cannot be undone."
        title={`Are you sure you want to delete the campaign: ${campaign.name}`}
        onActionButtonClick={deactivateCampaign}
        onClose={onDeactivateCampaignClose}
      >
        <div />
        { errorTextModal && <div className={styles.errorText}>{ errorTextModal }</div> }
      </MessageModal>
    </>
  );
}

ViewCampaigns.propTypes = propTypes;

export default ViewCampaigns;
