import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@duik/it';
import { Checkbox } from '@makeably/creativex-design-system';
import Select from 'components/atoms/Select';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  adAccount: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  selectors: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
        }),
      ),
    }),
  ).isRequired,
  onCheck: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

function AdAccountContainer({
  adAccount,
  checked,
  onCheck,
  onSelect,
  selectors,
}) {
  return (
    <Widget className="adAccountContainer">
      <div className="adAccountContainer-row u-flexRow u-flexAlignCenter">
        <Checkbox autoComplete="off" checked={checked} onChange={() => onCheck()} />
        <div className="u-flexColumn u-marginLeft">
          <div className="asLabel">Ad Account Name</div>
          { adAccount.name }
        </div>
      </div>
      <div className="adAccountContainer-dataRow u-flexRow u-justifySpaceBetween">
        <div>
          <div className="asLabel">Id</div>
          { adAccount.apiId }
        </div>
        <div>
          <div className="asLabel">Country</div>
          { adAccount.country }
        </div>
        <div />
      </div>
      <div className="adAccountContainer-formRow u-flexRow u-justifySpaceBetween">
        {
          selectors.map((selector, idx) => {
            const menuPosition = idx === (selectors.length - 1) ? 'bottom-left' : 'bottom-right';

            return (
              <div key={selector.label}>
                <Select
                  {...selector} // eslint-disable-line react/jsx-props-no-spreading
                  activeOption={adAccount[selector.name]}
                  menuPosition={menuPosition}
                  block
                  searchable
                  onOptionClick={onSelect}
                />
              </div>
            );
          })
        }
      </div>
    </Widget>
  );
}

AdAccountContainer.propTypes = propTypes;

export default AdAccountContainer;
