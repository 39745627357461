import React from "react"
import { Button } from  "@duik/it"
import PropTypes from "prop-types"

# This component is responsible for client side generation of a CSV and will provide a button to download said CSV.
class CsvButton extends React.Component
  @propTypes =
    filename: PropTypes.string
    label: PropTypes.string
    onClick: PropTypes.func
    # Arrays where each elt is an array of strings ex. [['h3', 'h4'], ['v1', 'v2']]
    rows: PropTypes.array

  csvContent: ->
    csvContentContent = _.map(
      @props.rows,
      (row) -> _.map(
        row,
        (item) -> if item and "," in item then "\"#{item}\"" else item
      ).join(",")
    ).join('\n')

  render: ->
    # blob solution from https://stackoverflow.com/a/25975345
    csvData = new Blob([@csvContent()], { type: 'text/csv' })
    csvUrl = URL.createObjectURL(csvData)

    btnProps =
      download: @props.filename
      href: csvUrl

    `<Button danger onClick={this.props.onClick} Component='a' {...btnProps}>
      {this.props.label}
    </Button>`

export default CsvButton
