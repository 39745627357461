import PropTypes from 'prop-types';
import MixpanelHelper from 'components/utils/MixpanelHelper';
import { saveToLocalStorage } from 'utilities/storage';

const propTypes = {
  pageName: PropTypes.string,
  productArea: PropTypes.string,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType(
        [
          PropTypes.string,
          PropTypes.number,
          PropTypes.array,
        ],
      ),
    }),
  ),
};
const defaultProps = {
  pageName: undefined,
  productArea: undefined,
  properties: [],
};

function MixpanelPageTracker(props) {
  const {
    pageName, productArea, properties,
  } = props;

  // Event-based mixpanel tracking will use the pageName and productArea attributes set
  saveToLocalStorage(pageName, 'pageName');
  saveToLocalStorage(productArea, 'productArea');

  if (!pageName || !productArea) return null;

  MixpanelHelper.trackPage(pageName, productArea, properties);

  return null;
}

MixpanelPageTracker.propTypes = propTypes;
MixpanelPageTracker.defaultProps = defaultProps;

export default MixpanelPageTracker;
