import React from "react"
import Select from "components/reusable/select"
import PropTypes from "prop-types"

# Responsible for displaying Channel, Placement and Campaign Objective eligibility dropdown options
# when creating/editing rule definitions. The options available for the Placements and Campaign Objectives
# dropdowns are based on the Channels dropdown.
class RuleDefinitionEligibility extends React.Component
  @propTypes =
    allOption: PropTypes.shape(
      label: PropTypes.string,
      value: PropTypes.string,
    ),
    adFormats: PropTypes.shape(
      inputName: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape(
          channel: PropTypes.string,
          label: PropTypes.string,
          value: PropTypes.string,
        )
      )
      selected: PropTypes.arrayOf(  # the existing values the definition was set up with; nil for new definitions
        PropTypes.string
      )
    ),
    brands: PropTypes.shape(
      inputName: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape(
          label: PropTypes.string,
          value: PropTypes.string,
        )
      )
      selected: PropTypes.arrayOf(  # the existing values the definition was set up with; nil for new definitions
        PropTypes.string
      )
    ),
    campaignObjectives: PropTypes.shape(
      inputName: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape(
          channel: PropTypes.string,
          label: PropTypes.string,
          value: PropTypes.string,
        )
      )
      selected: PropTypes.arrayOf(  # the existing values the definition was set up with; nil for new definitions
        PropTypes.string
      )
    )
    channels: PropTypes.shape(
      inputName: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape(
          label: PropTypes.string,
          value: PropTypes.string,
        )
      )
      selected: PropTypes.arrayOf(  # the existing values the definition was set up with; nil for new definitions
        PropTypes.string
      )
    ),
    contentTypes: PropTypes.shape(
      inputName: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape(
          label: PropTypes.string,
          value: PropTypes.string,
        )
      )
      selected: PropTypes.arrayOf(  # the existing values the definition was set up with; nil for new definitions
        PropTypes.string
      )
    ),
    markets: PropTypes.shape(
      inputName: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape(
          label: PropTypes.string,
          value: PropTypes.string,
        )
      )
      selected: PropTypes.arrayOf(  # the existing values the definition was set up with; nil for new definitions
        PropTypes.string
      )
    ),
    orientations: PropTypes.shape(
      inputName: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape(
          label: PropTypes.string,
          value: PropTypes.string,
        )
      )
      selected: PropTypes.arrayOf(  # the existing values the definition was set up with; nil for new definitions
        PropTypes.string
      )
    ),
    placements: PropTypes.shape(
      inputName: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape(
          channel: PropTypes.string,  # what channel this placement is for. Ex) instagram_paid
          label: PropTypes.string,
          value: PropTypes.string,
        )
      )
      selected: PropTypes.arrayOf(  # the existing values the definition was set up with; nil for new definitions
        PropTypes.string
      )
    ),
    publishers: PropTypes.shape(
      inputName: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape(
          channel: PropTypes.string,
          label: PropTypes.string,
          value: PropTypes.string,
        )
      )
      selected: PropTypes.arrayOf(
        PropTypes.string
      )
    ),
    restrictedChannels: PropTypes.bool

  constructor: (props) ->
    super(props)

    @state =
      selectedAdFormats: this.props.adFormats.selected || [this.props.allOption.value]
      selectedBrands: this.props.brands.selected || [this.props.allOption.value]
      selectedCampaignObjectives: this.props.campaignObjectives.selected || [this.props.allOption.value]
      selectedChannels: this.props.channels.selected || [this.props.allOption.value]
      selectedContentTypes: this.props.contentTypes.selected || [this.props.allOption.value]
      selectedMarkets: this.props.markets.selected || [this.props.allOption.value]
      selectedOrientations: this.props.orientations.selected || [this.props.allOption.value]
      selectedPlacements: this.props.placements.selected || [this.props.allOption.value]
      selectedPublishers: this.props.publishers.selected || [this.props.allOption.value]

  handleAdFormatsSelect: (adFormats) =>
    @setState(selectedAdFormats: adFormats)

  handleCampaignObjectiveSelect: (campaignObjectives) =>
    @setState(selectedCampaignObjectives: campaignObjectives)

  handleChannelSelect: (channels) =>
    # since placements are channel-specific, when selecting multiple channels or selecting a different
    # channel, the only available options for placements is All
    campaignObj =
      if _.isEqual(channels, ['facebook_paid', 'instagram_paid'])
      then @state.selectedCampaignObjectives
      else [@props.allOption.value]


    adFormats = []
    for channel in channels
      for option in @optionsForChannel(@props.adFormats.options, channel)
        adFormats.push option.value if @state.selectedAdFormats.includes(option.value)

    if adFormats.length == 0 or @state.selectedAdFormats.includes(@props.allOption.value)
    then adFormats.push @props.allOption.value

    @setState(
      selectedChannels: channels,
      selectedCampaignObjectives: campaignObj,
      selectedPublishers: [@props.allOption.value],
      selectedPlacements: [@props.allOption.value],
      selectedAdFormats: adFormats
    )

  handleContentTypeSelect: (contentTypes) =>
    @setState(selectedContentTypes: contentTypes)

  handlePublisherSelect: (publishers) =>
    @setState(selectedPublishers: publishers)

  handlePlacementSelect: (placements) =>
    @setState(selectedPlacements: placements)

  handleMarketSelect: (markets) =>
    @setState(selectedMarkets: markets)

  handleBrandSelect: (brands) =>
    @setState(selectedBrands: brands)

  handleOrientationSelect: (orientations) =>
    @setState(selectedOrientations: orientations)

  optionsForChannel: (options, channel) ->
    _.filter(options, (option) -> option['channel'] is channel)

  channelsWithPublisher: ->
    channels = []

    for option in @props.publishers.options
      channels.push option.channel if option.channel not in channels

    return channels

  renderPublishersSelect: ->
    options = [@props.allOption]

    if @state.selectedChannels.length is 1
      options = options.concat(@optionsForChannel(@props.publishers.options, @state.selectedChannels[0]))
    else if _.isEqual(@state.selectedChannels.sort(), this.channelsWithPublisher().sort())
      # NOTE: temporary solution to have only YouTube Ads show up if both DV360 and Google Ads are selected as channels
      # deprecate when eligibility criteria changes and current logic is changed
      publishersCopy = JSON.parse(JSON.stringify(@props.publishers.options))
      options = options.concat(@optionsForChannel(publishersCopy, 'youtube_paid'))
      # NOTE: temporary solution to show 'Display' for the two unique display labels when both Google Ads and DV360
      # are selected
      displayOpt = options.find((opt) -> opt.value is 'display')
      if displayOpt
        displayOpt.label = "Display"

    `<Select
      className='adminDefinitions-eligibilityDimensions'
      inputName={this.props.publishers.inputName}
      label={this.props.publishers.label}
      multiple={true}
      onChange={this.handlePublisherSelect}
      options={options}
      selected={this.state.selectedPublishers}
    />`

  renderPlacementSelect: ->
    options = [@props.allOption]

    if @state.selectedChannels.length is 1
      options = options.concat(@optionsForChannel(@props.placements.options, @state.selectedChannels[0]))

    `<Select
      className='adminDefinitions-eligibilityDimensions'
      inputName={this.props.placements.inputName}
      label={this.props.placements.label}
      multiple={true}
      onChange={this.handlePlacementSelect}
      options={options}
      selected={this.state.selectedPlacements}
    />`

  renderContentTypeSelect: ->
    options = [@props.allOption].concat(@props.contentTypes.options)

    `<Select
      className='adminDefinitions-eligibilityDimensions'
      inputName={this.props.contentTypes.inputName}
      label={this.props.contentTypes.label}
      multiple={true}
      onChange={this.handleContentTypeSelect}
      options={options}
      selected={this.state.selectedContentTypes}
    />`

  buildMultiChannelLabel: (channels) ->
    if _.isEqual(channels, ['facebook_paid', 'instagram_paid'])
      return 'Meta'
    individual_channel_labels = []
    for channel in channels
      channel_option = @props.channels.options.find((option) -> option.value is channel)
      individual_channel_labels.push(channel_option.label)
    return individual_channel_labels.join(', ')


  renderCampaignObjectiveSelect: ->
    options = [@props.allOption]
    seen_objectives = {}
    for channel in @state.selectedChannels
      for option in (@optionsForChannel(@props.campaignObjectives.options, channel))
        if seen_objectives[option.value]
          seen_objectives[option.value].channels.push(option.channel)
        else
          seen_objectives[option.value] = {
            label: option.label,
            channels: [option.channel]
          }
    for value, info of seen_objectives
      options.push({
        value: value,
        label: "#{this.buildMultiChannelLabel(info.channels)} - #{info.label}"
      })

    `<Select
      className='adminDefinitions-eligibilityDimensions'
      inputName={this.props.campaignObjectives.inputName}
      label={this.props.campaignObjectives.label}
      multiple={true}
      onChange={this.handleCampaignObjectiveSelect}
      options={options}
      selected={this.state.selectedCampaignObjectives}
    />`

  renderAdFormatsSelect: ->
    options = [@props.allOption]
    for channel in @state.selectedChannels
      options = options.concat(@optionsForChannel(@props.adFormats.options, channel))

    `<Select
      className='adminDefinitions-eligibilityDimensions'
      inputName={this.props.adFormats.inputName}
      label={this.props.adFormats.label}
      multiple={true}
      onChange={this.handleAdFormatsSelect}
      options={options}
      selected={this.state.selectedAdFormats}
    />`

  renderMarketsSelect: ->
    `<Select
      className='adminDefinitions-eligibilityDimensions'
      inputName={this.props.markets.inputName}
      label={this.props.markets.label}
      multiple={true}
      onChange={this.handleMarketSelect}
      options={this.props.markets.options}
      selected={this.state.selectedMarkets}
    />`

  renderBrandsSelect: ->
    `<Select
      className='adminDefinitions-eligibilityDimensions'
      inputName={this.props.brands.inputName}
      label={this.props.brands.label}
      multiple={true}
      onChange={this.handleBrandSelect}
      options={this.props.brands.options}
      selected={this.state.selectedBrands}
    />`

  renderOrientationsSelect: ->
    `<Select
      className='adminDefinitions-eligibilityDimensions'
      inputName={this.props.orientations.inputName}
      label={this.props.orientations.label}
      multiple={true}
      onChange={this.handleOrientationSelect}
      options={this.props.orientations.options}
      selected={this.state.selectedOrientations}
    />`

  render: ->
    options =
      if !@props.restrictedChannels
        [@props.allOption].concat(@props.channels.options)
      else
        @props.channels.options

    `<div className='form-group'>
      <div className='form-group adminDefinitions-eligibility'>
        <div className='form-group adminDefinitions-eligibilityGroups'>
          <Select
            className='adminDefinitions-eligibilityDimensions'
            inputName={this.props.channels.inputName}
            label={this.props.channels.label}
            multiple={true}
            onChange={this.handleChannelSelect}
            options={options}
            selected={this.state.selectedChannels}
          />
          {this.renderPublishersSelect()}
          {this.renderPlacementSelect()}
          {this.renderCampaignObjectiveSelect()}
        </div>
      </div>

      <div className='form-group adminDefinitions-eligibility'>
        <div className='adminDefinitions-eligibilityGroups'>
          {this.renderMarketsSelect()}
          {this.renderBrandsSelect()}
        </div>
      </div>

      <div className='form-group adminDefinitions-eligibility'>
        <div className='adminDefinitions-eligibilityGroups'>
          {this.renderContentTypeSelect()}
          {this.renderAdFormatsSelect()}
          {this.renderOrientationsSelect()}
        </div>
      </div>
    </div>`

export default RuleDefinitionEligibility
