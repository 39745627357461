import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Dropdown,
} from '@makeably/creativex-design-system';
import CheckDisplay from 'components/admin/review_queue/CheckDisplay';
import { propTypes as postInfoPropTypes } from 'components/admin/review_queue/post_info/PostInfo';
import EvaluationBanner from 'components/admin/review_queue/preflight/PreflightEvaluationBanner';
import { editAdminReviewGuidelineCheckUrl } from 'utilities/routes';
import styles from './Check.module.css';
import {
  getReviewabilityLabel,
  isBool,
  postEvaluated,
  postUnreviewable,
  processCheckData,
} from './shared';

const checkProps = PropTypes.shape({
  availableUnreviewableReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  brands: PropTypes.arrayOf(PropTypes.string).isRequired,
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  customer: PropTypes.string.isRequired,
  cv: PropTypes.bool.isRequired,
  definition: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  evaluableByUser: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  markets: PropTypes.arrayOf(PropTypes.string).isRequired,
  postInfo: PropTypes.shape(postInfoPropTypes).isRequired,
  pretests: PropTypes.arrayOf(PropTypes.bool).isRequired,
  reviewStartAt: PropTypes.string.isRequired,
  rule: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  passed: PropTypes.bool,
  unreviewableReason: PropTypes.string,
});

const propTypes = {
  check: checkProps.isRequired,
  guidelineId: PropTypes.number.isRequired,
  isPreflight: PropTypes.bool.isRequired,
};

function getReviewabilityOptions(reasons) {
  const values = [null].concat(reasons);

  return values.map((value) => ({
    label: getReviewabilityLabel(value),
    value,
  }));
}

function Check({
  check,
  guidelineId,
  isPreflight,
}) {
  const reviewabilityOptions = getReviewabilityOptions(check.availableUnreviewableReasons);
  const checkDetails = processCheckData(check);
  const checkNeedsValidation = check.status === 'needs_review' && check.cv && isBool(check.passed);
  const checkEnabled = check.editable
    && check.evaluableByUser
    && check.unreviewableReason === null;

  const [reviewabilitySelection, setReviewabilitySelection] = useState(null);
  const [needsValidation, setNeedsValidation] = useState(checkNeedsValidation);
  const [passed, setPassed] = useState(check.passed);
  const [isBusy, setIsBusy] = useState(false);

  const hasReviewabilityChange = Boolean(reviewabilitySelection);
  const hasPassedChange = needsValidation !== checkNeedsValidation || passed !== check.passed;
  const isActionable = hasReviewabilityChange || hasPassedChange;

  const handleReviewabilityChange = (option) => {
    if (option.value === check.unreviewableReason) {
      setReviewabilitySelection(null);
    } else {
      setReviewabilitySelection(option);
    }
  };

  const handleAction = async () => {
    setIsBusy(true);

    if (hasReviewabilityChange && !isPreflight) {
      await postUnreviewable(guidelineId, check.id, reviewabilitySelection.value);
    } else {
      await postEvaluated(guidelineId, check.id, passed, check.reviewStartAt);
    }

    setReviewabilitySelection(null);
    window.location.reload();
  };

  const renderControls = () => {
    if (!check.editable) return null;

    return (
      <div className={styles.controlsWrapper}>
        <div className={styles.controls}>
          { !isPreflight && (
            <Dropdown
              disabled={isBusy}
              menuProps={{
                size: 'large',
                position: 'top-right',
              }}
              options={reviewabilityOptions}
              placeholder="Change Reviewability"
              selected={reviewabilitySelection}
              size="large"
              onChange={handleReviewabilityChange}
            />
          ) }
          <Button
            disabled={isBusy || !isActionable}
            label={hasReviewabilityChange ? 'Save' : 'Evaluate'}
            variant="primary"
            onClick={handleAction}
          />
        </div>
        { isPreflight && <EvaluationBanner /> }
      </div>
    );
  };

  return (
    <Card>
      <CheckDisplay
        check={checkDetails}
        disabled={!checkEnabled}
        isBusy={isBusy}
        needsValidation={needsValidation}
        passed={passed}
        url={editAdminReviewGuidelineCheckUrl(guidelineId, check.id)}
        onNeedsValidationChange={(value) => setNeedsValidation(value)}
        onPassedChange={(value) => setPassed(value)}
      />
      { renderControls() }
    </Card>
  );
}

Check.propTypes = propTypes;

export default Check;
