import React from 'react';
import PropTypes from 'prop-types';
import SelectableTable, { propTypes as tableProps } from 'components/organisms/SelectableTable';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import ArrayHelper from 'components/utils/ArrayHelper';
import { track } from 'utilities/mixpanel';

const propTypes = {
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  table: PropTypes.shape({
    headers: tableProps.headers,
    rows: tableProps.rows,
    title: PropTypes.string,
  }).isRequired,
  onFormAction: PropTypes.func.isRequired,
  singleSelect: PropTypes.bool,
};

const defaultProps = {
  singleSelect: false,
};

class BusinessCenterSelection extends React.Component {
  constructor(props) {
    super(props);
    const {
      table: {
        rows,
      },
    } = props;
    this.hasDisabledAccounts = rows.some(({ disabled }) => disabled);
    this.state = {
      rows,
      allSelected: false,
      selected: [],
    };
  }

  componentDidMount() {
    track('complete_oauth');
  }

  onDataChange() {
    const { onFormAction } = this.props;
    const { selected } = this.state;

    onFormAction(selected.length > 0);
  }

  onAllSelect() {
    this.setState((prevState) => {
      const { table: { rows } } = this.props;
      const allSelected = !prevState.allSelected;

      let updatedValues = [];
      if (allSelected) {
        updatedValues = rows.filter((row) => !row.disabled).map(({ data }) => data);
      }

      return {
        allSelected,
        selected: updatedValues,
      };
    }, () => this.onDataChange());
  }

  onRowSelect(data) {
    this.setState((prevState) => {
      const updatedValues = ArrayHelper.withOrWithoutObject(prevState.selected, data);

      const { singleSelect, table: { rows } } = this.props;
      const allSelected = updatedValues.length === rows.length;
      let updatedRows = rows;

      if (singleSelect && updatedValues.length > 0) {
        updatedRows = rows.map((row) => {
          if (row.data.value !== data.value) {
            return {
              ...row,
              disabled: true,
            };
          }
          return row;
        });
      }

      return {
        allSelected,
        rows: updatedRows,
        selected: updatedValues,
      };
    }, () => this.onDataChange());
  }

  render() {
    const {
      formData: {
        id,
        url,
      },
      table: {
        headers,
        title: defaultTitle,
      },
      singleSelect,
    } = this.props;

    const { rows, selected } = this.state;

    const styledRows = rows.map((row) => {
      if (row.disabled) {
        return {
          ...row,
          columns: row.columns.map((column) => (<div className="businessCenter--disabled">{ column }</div>)),
        };
      }
      return row;
    });

    let title = defaultTitle;
    let subtitle;
    const selectableRowCount = rows.filter((row) => !row.disabled).length;

    // Need to handle the case where managers are disabled because of access and not single select
    if (selectableRowCount === 0) {
      title = 'Unavailable Business Managers';
    } else if (this.hasDisabledAccounts) {
      subtitle = 'Your user account does not have admin access to the disabled '
        + 'Business Managers below. Please log in using a different Facebook account, '
        + 'or invite a user to CreativeX with the required access to complete your connections.';
    }

    return (
      <>
        <SelectableTable
          headers={headers}
          rows={styledRows}
          selectAll={!singleSelect}
          selected={selected}
          subtitle={subtitle}
          title={title}
          onAllSelect={() => this.onAllSelect()}
          onRowSelect={(rowData) => this.onRowSelect(rowData)}
        />
        <form action={url} id={id} method="post">
          <AuthenticityTokenInput />
          {
            selected.map(({ name, value }) => (
              <React.Fragment key={value}>
                <HiddenInput
                  name="business_centers[][api_id]"
                  value={value}
                />
                <HiddenInput
                  name="business_centers[][name]"
                  value={name}
                />
              </React.Fragment>
            ))
          }
        </form>
      </>
    );
  }
}

BusinessCenterSelection.propTypes = propTypes;
BusinessCenterSelection.defaultProps = defaultProps;

export default BusinessCenterSelection;
