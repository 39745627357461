import React from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"

# Responsible for mounting components outside of the context they would otherwise be rendered in.
# This is useful if the component has z-index styling and interacts with other z-index stacking contexts.
# The solution to the stacking context problem, is to force all components to exist in the same stacking context
#
# REACT UPDATE NOTES: this is built-in behavior in react v16, see ReactDOM.createPortal() for details
class PopupPortal extends React.Component
  @propTypes =
    children: PropTypes.node

  constructor: (props) ->
    super(props)
    @node = document.createElement('div')

  # TODO: Need to update
  #  * Move data fetching code or side effects to componentDidUpdate.
  #  * If you're updating state whenever props change, refactor your code to
  #     use memoization techniques or move it to static getDerivedStateFromProps.
  #     Learn more at: https://fb.me/react-derived-state
  UNSAFE_componentWillReceiveProps: (newProps) ->
    @renderPortal(newProps)

  componentDidMount: ->
    document.body.appendChild(@node)
    @renderPortal(@props)

  componentWillUnmount: ->
    document.body.removeChild(@node)

  renderPortal: (props) ->
    ReactDOM.createPortal(props.children, @node)

  # see renderPortal for details on how this component renders it's children.
  # (hint: appends them to the body of the dom)
  render: ->
    @renderPortal(@props)

export default PopupPortal
