import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  MessageModal,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import TierForm from 'components/rules/tiers/TierForm';
import { tierProps } from 'components/rules/tiers/TierRow';
import { getAuthenticityToken } from 'utilities/requests';
import { tierPath } from 'utilities/routes';

const propTypes = {
  id: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  newTiers: PropTypes.arrayOf(
    PropTypes.shape(tierProps),
  ).isRequired,
  oldTiers: PropTypes.arrayOf(
    PropTypes.shape(tierProps),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

async function submitTiers(tiers, id) {
  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());

  const newTiers = tiers.map(({
    label,
    minimumCutoff,
    rank,
  }) => ({
    label,
    minimum_cutoff: minimumCutoff,
    rank,
  }));
  formData.append('tiers', JSON.stringify(newTiers));

  const response = await fetch(tierPath(id), {
    method: 'PATCH',
    body: formData,
  });

  if (response.ok) {
    return response.json();
  }

  return {};
}

function getToastMessage(changeType) {
  switch (changeType) {
    case 'label_change':
      return 'Tiers have been updated';
    case 'tier_change':
      return 'Tiers are now updating. You will receive an email when this is complete';
    default:
      return 'No changes made to tiers';
  }
}

function TierConfirmation({
  id,
  isOpen,
  newTiers,
  oldTiers,
  onClose,
}) {
  const [isBusy, setIsBusy] = useState(false);

  const onUpdate = async () => {
    setIsBusy(true);

    const { changeType, isValid } = await submitTiers(newTiers, id);

    if (isValid) {
      addToast(getToastMessage(changeType), { size: 'large' });
      window.location.href = tierPath(id);
    } else {
      addToast('Tiers invalid', {
        size: 'large',
        type: 'error',
      });
    }

    setIsBusy(false);
    return false;
  };

  return (
    <MessageModal
      actionButtonDisabled={isBusy}
      actionButtonLabel="Update"
      isOpen={isOpen}
      size="custom"
      subtitle="Updating the Scoring Tiers will alter the scores for all of your company's creatives. Time will be needed for these changes to be reflected in the dashboard."
      title="Please confirm the following is correct"
      onActionButtonClick={onUpdate}
      onClose={onClose}
    >
      <div className="u-flexRow">
        <div className="u-flexColumn">
          <div className="u-marginBottom-16">Current Tiers:</div>
          <TierForm tiers={oldTiers} />
        </div>
        <div className="u-flexColumn">
          <Divider margin vertical />
        </div>
        <div className="u-flexColumn">
          <div className="u-marginBottom-16">Updated Tiers:</div>
          <TierForm tiers={newTiers} />
        </div>
      </div>
    </MessageModal>
  );
}

TierConfirmation.propTypes = propTypes;

export default TierConfirmation;
