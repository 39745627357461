import React from "react"
import PropTypes from "prop-types"

class Option extends React.Component
  @propTypes =
    disabled: PropTypes.bool
    label: PropTypes.string
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])

  render: ->
    `<option disabled={this.props.disabled} value={this.props.value}>{this.props.label}</option>`

export default Option
