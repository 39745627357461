export const metricTooltips = {
  activationRate: 'Total core assets activated divided by total core assets',
  averageScore: 'The average score across your ads based on your company’s default score',
  assetsActivated: 'The number of core assets connected to an ad that has media spend',
  assetsNotActivated: 'The number of core assets not connected to an ad that has media spend',
  assets: 'Total core assets uploaded',
  repurposedRate: 'Total unique assets connected to a core asset divided by total core assets activated',
  reusageRate: 'Total ads connected to a core asset divided by total core assets activated',
  spend: 'Total media spend behind all activations of an activated core asset',
  impressions: 'Total impressions behind all activations of an activated core asset',
};
