import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  MultipleDropdown,
} from '@makeably/creativex-design-system';
import Asset from 'components/admin/core_assets/Asset';
import { selectFilterOptions } from 'utilities/filtering';
import {
  filterItems,
  getOptions,
  updateSelections,
} from 'utilities/itemFilter';
import { titleize } from 'utilities/string';
import {
  getParams,
  getParamsObj,
  selectParams,
  setParam,
} from 'utilities/url';
import styles from './CompanyOverview.module.css';

const companyProps = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

const coreAssetProps = {
  assetType: PropTypes.string.isRequired,
  videoLength: PropTypes.number,
};

const propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape(companyProps),
  ).isRequired,
  company: PropTypes.shape(companyProps).isRequired,
  coreAssets: PropTypes.arrayOf(
    PropTypes.shape(coreAssetProps),
  ).isRequired,
};

const filterDimensions = [
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'assetType',
    label: 'Asset Type',
  },
];
const filterKeys = filterDimensions.map((dim) => dim.key);

function getBrand(coreAsset) {
  const entries = coreAsset.brands.map((str) => str.split('::'));
  const options = entries.map(([value, label]) => ({
    value,
    label,
  }));
  const value = options.length === 1 ? options[0].value : 'Multiple';
  const label = options.length === 1 ? options[0].label : 'Multiple';

  return {
    label,
    options,
    value,
  };
}

function getItem(coreAsset) {
  return {
    assetType: {
      label: titleize(coreAsset.assetType),
      value: coreAsset.assetType,
    },
    brand: getBrand(coreAsset),
    count: { value: coreAsset.activationCount },
    id: { value: coreAsset.uuid },
    url: { value: coreAsset.url },
    videoLength: { value: coreAsset.videoLength },
  };
}

function CompanyOverview({
  companies,
  company,
  coreAssets,
}) {
  const params = getParams(window);
  const filterParamsObj = getParamsObj(params, filterKeys);
  const [items, setItems] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState({});
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    const allItems = coreAssets.map((coreAsset) => getItem(coreAsset));
    const allOptions = getOptions(filterDimensions, allItems);
    const filterValuesObj = selectParams(filterKeys, params);
    const allSelections = selectFilterOptions(filterValuesObj, allOptions);

    setItems(allItems);
    setFilterOptions(allOptions);
    setFilterSelections(allSelections);
  }, [coreAssets]);

  useEffect(() => {
    setFilteredItems(filterItems(items, filterSelections));
  }, [items, filterSelections]);

  const handleFilterChange = (key, selected) => {
    const updated = updateSelections(filterSelections, key, selected);

    if (key === 'brand') {
      const options = updated[key] ?? [];
      const values = options.map((option) => option.value);
      setParam(key, values, params, window);
    }

    setFilterSelections(updated);
  };

  return (
    <>
      <div className={styles.controls}>
        <Dropdown
          label="Company"
          menuProps={{ size: 'medium' }}
          options={companies}
          selected={company}
          size="medium"
        />
        { filterDimensions.map(({ key, label }) => (
          <MultipleDropdown
            key={key}
            label={label}
            options={filterOptions[key] ?? []}
            placeholder="All"
            selected={filterSelections[key]}
            onChange={(sel) => handleFilterChange(key, sel)}
          />
        )) }
        <Button
          disabled={Object.keys(filterSelections).length === 0}
          label="Clear"
          variant="tertiary"
          onClick={() => setFilterSelections({})}
        />
      </div>
      { filteredItems.length === 0 && (
        <div className={`t-empty ${styles.empty}`}>
          There are no core assets to display
        </div>
      ) }
      <div className={styles.assets}>
        { filteredItems.map((item) => (
          <Asset
            key={item.id.value}
            companyId={company.value}
            item={item}
            urlParams={filterParamsObj}
          />
        )) }
      </div>
    </>
  );
}

CompanyOverview.propTypes = propTypes;

export default CompanyOverview;
