import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroupContainer,
} from '@duik/it';
import {
  Toggle,
} from '@makeably/creativex-design-system';
import { HiddenInput } from 'components/reusable/HiddenInput';

const propTypes = {
  callback: PropTypes.func.isRequired,
  profile: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function AdminPermissions(props) {
  const {
    callback,
    profile,
    roles,
  } = props;

  const hiddenAdminInputs = roles.map((role) => (
    <HiddenInput
      key={role}
      data-testid={role}
      name="user[roles][]"
      value={role}
    />
  ));

  return (
    <FormGroupContainer>
      { hiddenAdminInputs }
      { profile === 'picasso_admin'
        && (
        <>
          <Toggle
            checked={roles.includes('manage_preflight_channels')}
            label="Manage Preflight Channels"
            name="manage_preflight_channels"
            right
            onChange={callback}
          />
          <Toggle
            checked={roles.includes('invite_cx_users')}
            label="Invite new CreativeX users"
            name="invite_cx_users"
            right
            onChange={callback}
          />
          <Toggle
            checked={roles.includes('manage_cx_permissions')}
            label="Manage CreativeX user permissions"
            name="manage_cx_permissions"
            right
            onChange={callback}
          />
          <Toggle
            checked={roles.includes('onboard_clients')}
            label="Onboard new clients by managing guidelines, guideline details and channel configurations"
            name="onboard_clients"
            right
            onChange={callback}
          />
          <Toggle
            checked={roles.includes('reprocess_audits')}
            label="Perform In-Flight Reprocessing"
            name="reprocess_audits"
            right
            onChange={callback}
          />
          <Toggle
            checked={roles.includes('bme_account_remapping')}
            label="Allow BME Account Remapping"
            name="bme_account_remapping"
            right
            onChange={callback}
          />
          <Toggle
            checked={roles.includes('manage_user_mfa')}
            label="Toggle User MFA"
            name="manage_user_mfa"
            right
            onChange={callback}
          />
        </>
        ) }
      <Toggle
        checked={roles.includes('manage_validation_reviews')}
        label="View Validation Reviews"
        name="manage_validation_reviews"
        right
        onChange={callback}
      />
      <Toggle
        checked={roles.includes('manage_challenge_queue')}
        label="View Challenge Queue"
        name="manage_challenge_queue"
        right
        onChange={callback}
      />
      <Toggle
        checked={roles.includes('manage_escalated_challenge_queue')}
        label="View Escalated Challenge Queue"
        name="manage_escalated_challenge_queue"
        right
        onChange={callback}
      />
      <Toggle
        checked={roles.includes('manage_process_control_checks')}
        label="View Process Control Queue"
        name="manage_process_control_checks"
        right
        onChange={callback}
      />
      <Toggle
        checked={roles.includes('manage_trial_guideline_checks')}
        label="View Trial Guideline Queue"
        name="manage_trial_guideline_checks"
        right
        onChange={callback}
      />
      <Toggle
        checked={roles.includes('manage_representation_queue')}
        label="View Representation Queue"
        name="manage_representation_queue"
        right
        onChange={callback}
      />
      <Toggle
        checked={roles.includes('manage_standard_score')}
        label="Manage Standard CQ Score"
        name="manage_standard_score"
        right
        onChange={callback}
      />
    </FormGroupContainer>
  );
}

AdminPermissions.propTypes = propTypes;

export default AdminPermissions;
