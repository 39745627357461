import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BasicModal,
  CloseButton,
} from '@makeably/creativex-design-system';
import styles from './AssetModal.module.css';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  videoSource: PropTypes.string,
};

const defaultProps = {
  videoSource: undefined,
};

function calcImageStyle(aspectRatio) {
  const maxPercent = 70;

  if (!aspectRatio) {
    return {};
  }

  if (aspectRatio > 1.0) {
    return {
      height: `${maxPercent / aspectRatio}vw`,
      width: `${maxPercent}vw`,
    };
  }

  return {
    height: `${maxPercent}vh`,
    width: `${aspectRatio * maxPercent}vh`,
  };
}

function AssetModal({
  isOpen,
  onClose,
  url,
  videoSource,
}) {
  const [aspectRatio, setAspectRatio] = useState();

  const handleImageLoad = ({ target }) => {
    setAspectRatio(target.width / target.height);
  };

  const handleVideoLoad = ({ target }) => {
    setAspectRatio(target.videoWidth / target.videoHeight);
  };

  const renderAsset = () => {
    if (videoSource) {
      return (
        <video
          alt="Core Asset Preview"
          src={videoSource}
          autoPlay
          controls
          onLoadedData={handleVideoLoad}
        />
      );
    }

    return (
      <img
        alt="Core Asset Preview"
        src={url}
        onLoad={handleImageLoad}
      />
    );
  };

  return (
    <div className={styles.modal}>
      <BasicModal
        isOpen={isOpen}
        padding={false}
        onClose={onClose}
      >
        <div className={styles.main}>
          <div className={styles.header}>
            <CloseButton onClick={onClose} />
          </div>
          <div className={styles.body}>
            <div
              className={styles.image}
              style={calcImageStyle(aspectRatio)}
            >
              { renderAsset() }
            </div>
          </div>
        </div>
      </BasicModal>
    </div>
  );
}

AssetModal.propTypes = propTypes;
AssetModal.defaultProps = defaultProps;

export default AssetModal;
