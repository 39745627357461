import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  Tooltip,
} from '@makeably/creativex-design-system';
import styles from './TabButton.module.css';

const propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  disabled: false,
  icon: undefined,
  label: undefined,
  onClick: undefined,
  selected: false,
};

function renderIcon(name) {
  if (!name) {
    return null;
  }

  return (
    <span className={styles.icon}>
      <Icon color="current" name={name} />
    </span>
  );
}

function TabButton({
  disabled,
  icon,
  label,
  onClick,
  selected,
}) {
  const classes = classnames(
    styles.button,
    {
      [styles.selected]: selected,
    },
  );

  const contentRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  // Conditionally wrap the button in a tooltip if the content overflows
  const checkShouldShowTooltip = () => {
    setShowTooltip(contentRef.current.scrollWidth > contentRef.current.clientWidth);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    checkShouldShowTooltip();
    window.addEventListener('resize', checkShouldShowTooltip);

    // remove resize listener again on "componentWillUnmount"
    return () => {
      window.removeEventListener('resize', checkShouldShowTooltip);
    };
  }, []);

  const tab = (
    <button
      className={classes}
      disabled={disabled}
      type="button"
      onClick={onClick}
    >
      { renderIcon(icon) }
      <span ref={contentRef} className={styles.content}>{ label }</span>
    </button>
  );

  return (
    <div className={styles.wrapper}>
      { showTooltip ? <Tooltip direction="right" label={label}>{ tab }</Tooltip> : tab }
    </div>
  );
}

TabButton.propTypes = propTypes;
TabButton.defaultProps = defaultProps;

export default TabButton;
