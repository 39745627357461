import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Table,
  Tag,
} from '@makeably/creativex-design-system';
import ChannelLogos from 'components/molecules/ChannelLogos';
import { guidelineProps } from 'components/scores/modal/ScoreModal';
import { sortObjectArray } from 'utilities/array';
import styles from './GuidelineTable.module.css';

const propTypes = {
  guidelines: PropTypes.arrayOf(
    PropTypes.shape({
      ...guidelineProps,
      isSelected: PropTypes.bool,
    }),
  ).isRequired,
  isSelectable: PropTypes.bool,
  onGuidelineSelect: PropTypes.func,
};

const defaultProps = {
  isSelectable: false,
  onGuidelineSelect: () => {},
};

const headers = [
  { value: 'Name' },
  { value: 'Description' },
  { value: 'Applicable Channels' },
];

function GuidelineTable({
  guidelines,
  isSelectable,
  onGuidelineSelect,
}) {
  const guidelinesGroupedById = guidelines.reduce((grouped, guideline) => {
    const newGrouped = { ...grouped };
    const {
      channelKey, channelLabel, id,
    } = guideline;
    if (newGrouped[id]) {
      newGrouped[id].channels.push({
        value: channelKey,
        label: channelLabel,
      });
      newGrouped[id].isActive ||= guideline.isActive;
    } else {
      newGrouped[id] = {
        ...guideline,
        channels: [{
          value: channelKey,
          label: channelLabel,
        }],
      };
    }

    return newGrouped;
  }, {});

  const sortedGuidelines = sortObjectArray(Object.values(guidelinesGroupedById), 'name');

  const formatRow = ({
    channels,
    description,
    id,
    name,
    isActive,
    isSelected,
  }) => {
    let nameCell = name;
    if (isSelectable) {
      nameCell = (
        <div className={styles.checkbox}>
          <Checkbox
            checked={isSelected}
            label={name}
            onChange={(e) => onGuidelineSelect(e.target.checked, id)}
          />
        </div>
      );
    }

    if (!isActive) {
      nameCell = (
        <div className={styles.inactive}>
          { nameCell }
          <Tag label="Inactive" />
        </div>
      );
    }

    const sortedChannels = sortObjectArray(channels, 'label');

    return {
      key: id.toString(),
      cells: [
        {
          className: styles.name,
          value: nameCell,
        },
        {
          className: styles.description,
          value: description || 'N/A',
        },
        { value: <ChannelLogos channels={sortedChannels} shownMax={5} /> },
      ],
    };
  };

  return (
    <Table
      className={styles.table}
      headers={headers}
      rows={sortedGuidelines.map(formatRow)}
    />
  );
}

GuidelineTable.propTypes = propTypes;
GuidelineTable.defaultProps = defaultProps;

export default GuidelineTable;
