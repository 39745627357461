import React from "react"
import PropTypes from "prop-types"
import Plotly from "plotly.js"

# An interface to PlotlyJS, a javascript plotting utility.
# Passing in traces is required, (what would we plot otherwise?)
# See plotly documentation for how traces and layout are formed
#
# If onClick is passed in, the value returned will be the trace that was clicked. See docs:
# https://plot.ly/javascript/click-events/
class PlotlyChart extends React.Component
  @propTypes =
    clickable: PropTypes.bool
    eventParams: PropTypes.shape(
      data: PropTypes.array # Array of objects where index corresponds to curveNumber
      route: PropTypes.string
    )
    # Default behavior is the pre-existing behavior. If the graph has a horizontal
    # legend and is a bar graph, we adjust the height of the graph to be the # of
    # traces * 50, or 500 whatever the max is. When you need a specific size for a
    # graph, you should set this prop to false and pass in the size format in the
    # layout.
    heightAdjust: PropTypes.bool
    layout: PropTypes.object
    traces: PropTypes.array.isRequired

  @defaultProps =
    heightAdjust: true

  constructor: (props) ->
    super(props)
    @state =
      id: _.uniqueId("plotlyChartId--")

  # Adds height to the div for horizontal layouts and lots of data
  heightAdjustedLayout: (layout, traces) ->
    return layout unless @props.heightAdjust

    traceCount =
      if layout.legend and layout.legend.orientation == 'h'
        if traces.length > 0 and traces[0].type == 'bar'
          traces[0].ids.length
        else
          'undefined'

    if _.isUndefined(traceCount)
      return layout
    else
      return $.extend(true, layout, {
        autosize: true,
        height: Math.max(500, 50 * traceCount)
      })

  drawGraph: ->
    # Copy over layout because Plotly.newPlot mutates!
    layout = $.extend(true, {}, @props.layout)

    # use Plotly.newPlot so old event listeners are destroyed
    Plotly.newPlot(@state.id,
                   @props.traces,
                   @heightAdjustedLayout(PlotlyUtil.layout(layout), @props.traces),
                   PlotlyUtil.modebarOptions())

  resize: =>
    el = document.getElementById(@state.id)
    Plotly.Plots.resize(el) if el

  setClickHandlers: ->
    if @props.clickable
      # NB: We don't need to remove existing click handlers,
      #     plotly does that internally since we use Plotly.newPlot above
      # NB: for reference: https://github.com/plotly/plotly.js/pull/117
      el = document.getElementById(@state.id)
      el.on('plotly_click', (data) =>
        @handleClick(data)
      )

  # TODO: Need to update
  #  * Move code with side effects to componentDidMount, and set initial state
  #     in the constructor.
  #  * See https://fb.me/react-unsafe-component-lifecycles for details.
  UNSAFE_componentWillMount: ->
    $(window).on('resize', _.debounce(@resize, 150))

  componentDidMount: ->
    @drawGraph()
    @setClickHandlers()

  componentDidUpdate: ->
    @drawGraph()

  handleClick: (data) =>
    curveNumber = data.points[0].curveNumber
    params = @props.eventParams.data[curveNumber]
    new App.ClickableModalOverlay().makeRequest(@props.eventParams.route, params) if params

  render: ->
    `<div id={this.state.id}></div>`

export default PlotlyChart
