// Non Navigation based tabs
// - Do NOT use for link navigation
//   Use navigation/tabs for that
// - These tabs should be used for controlling content within a component.
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Tabs as DuikTabs,
  TabItem,
  TabProps,
} from '@duik/it';
import { Tooltip } from '@makeably/creativex-design-system';

export const propTypes = {
  activeOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  callback: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    labelExtension: PropTypes.string,
    tooltipText: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  borderless: PropTypes.bool,
  children: PropTypes.node,
  duikTabsProps: PropTypes.shape(TabProps),
  tabColor: PropTypes.string,
};

const defaultProps = {
  borderless: false,
  children: undefined,
  duikTabsProps: {},
  tabColor: undefined,
};

function renderTabLabel(label, labelExtension) {
  return (
    <div>
      { label }
      <div className="componentTabs-subHeader u-marginAboveSm u-marginBelowSm">
        { labelExtension }
      </div>
    </div>
  );
}

function Tabs(props) {
  const {
    activeOption,
    borderless,
    callback,
    tabs,
    tabColor,
    duikTabsProps,
    children,
  } = props;

  const classes = classNames(
    'componentTabs',
    {
      'componentTabs--borderless': borderless,
      [`componentTabs--${tabColor}`]: tabColor,
    },
  );

  let extensionPresent = false;
  const renderedTabs = (
    tabs.map((
      {
        label,
        labelExtension,
        tooltipText,
        value,
      },
    ) => {
      let formattedLabel = label;
      if (labelExtension) {
        extensionPresent = true;
        formattedLabel = renderTabLabel(label, labelExtension, tooltipText);
      }

      const tooltipContent = tooltipText && <Tooltip label={tooltipText} />;

      return (
        <TabItem
          key={value}
          className={classNames({ active: activeOption === value })}
          rightEl={tooltipContent}
          onClick={() => callback(value)}
        >
          { formattedLabel }
        </TabItem>
      );
    })
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DuikTabs className={classes} sm={extensionPresent} xs={!extensionPresent} {...duikTabsProps}>
      { renderedTabs }
      { children }
    </DuikTabs>
  );
}

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
