import React from "react"
import Button from "components/reusable/button"
import PropTypes from "prop-types"
import classNames from "classnames"
import Column from "components/reusable/column"
import HorizontalBarChartComponent from "components/graphs/horizontal_bar_chart"
import Label from "components/reusable/label"
import Row from "components/reusable/row"

# Responsible for displaying the category analysis.
class CategoryAnalysis extends React.Component
  @propTypes =
    categoriesPath: PropTypes.string.isRequired # path to the overall categories page, used for link
    categoryLabels: PropTypes.array       # Array of category objects with label and optional path field if links.
    left: PropTypes.object.isRequired     # Data for left graph. Labels will be added from categoryLabels.
    right: PropTypes.object.isRequired    # Data for right graph. Labels will be added from categoryLabels.

  classes: ->
    classNames(
      'category-analysis',
      'metrics-chart'
    )

  categoryLabel: (category) ->
    `<div className="label-container" key={category.id}>
      <Label value={category.label} path={category.path} />
    </div>`

  subHeader: (header) ->
    `<div className="category-analysis-header underlined">
      {header}
    </div>`

  barChart: (direction) ->
    barChartProps = { labels: this.props.categoryLabels, direction: direction }
    `<HorizontalBarChartComponent {...$.extend(this.props[direction], barChartProps)}/>`

  render: ->
    `<div className={this.classes()}>
      <Row styleName='no-margin center-align'>
        <Column size={5} styleName='no-padding'>
          { this.subHeader(this.props.left.dataLabel)}
        </Column>
        <Column size={2}>
          { this.subHeader('Categories')}
        </Column>
        <Column size={5} styleName='no-padding'>
          { this.subHeader(this.props.right.dataLabel)}
        </Column>
      </Row>
      <Row styleName='no-margin'>
        <Column size={5} styleName='no-padding'>
          { this.barChart('left') }
        </Column>
        <Column size={2} className="category-labels">
          { this.props.categoryLabels.map(this.categoryLabel) }
        </Column>
        <Column size={5} styleName='no-padding'>
          { this.barChart('right') }
        </Column>
      </Row>
      <Row>
        <Column styleName='center-align'>
          <Button link={this.props.categoriesPath} label="See All Categories"/>
        </Column>
      </Row>
    </div>`

export default CategoryAnalysis
