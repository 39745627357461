import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ChannelLogo from "components/atoms/ChannelLogo"
import DsTabs from 'components/molecules/DsTabs'
import SafeZoneModal from "components/organisms/SafeZoneModal"
import StringHelper from "components/utils/StringHelper"
import {
  Icon,
  MaxWidthText,
  Tooltip,
} from '@makeably/creativex-design-system'

# Shows the post information, metrics, guideline/category pass/fail for an audit asset,
# and change log if there are multiple versions of the post.
class AuditPostDetail extends React.Component
  @propTypes =
    altText: PropTypes.string,
    campaignObjective: PropTypes.string
    categories: PropTypes.arrayOf(
      PropTypes.shape(
        description: PropTypes.string
        name: PropTypes.string
        passed: PropTypes.bool
      )
    )
    channel: PropTypes.string
    channelDisplay: PropTypes.string
    qualityRank: PropTypes.string
    guidelines: PropTypes.arrayOf(
      PropTypes.shape(
        description: PropTypes.string
        name: PropTypes.string
        passed: PropTypes.bool
        weight: PropTypes.string
        definitions: PropTypes.arrayOf(
          PropTypes.shape(
            description: PropTypes.string
            name: PropTypes.string
            passed: PropTypes.bool
            weight: PropTypes.string
          )
        )
      )
    )
    hypotheses: PropTypes.arrayOf(
      PropTypes.shape(
        description: PropTypes.string
        name: PropTypes.string
        passed: PropTypes.bool
        definitions: PropTypes.arrayOf(
          PropTypes.shape(
            description: PropTypes.string
            name: PropTypes.string
            passed: PropTypes.bool
          )
        )
      )
    )
    index: PropTypes.number
    regulatory: PropTypes.shape(
      rating: PropTypes.string
      rules: PropTypes.arrayOf(
        PropTypes.shape(
          description: PropTypes.string
          name: PropTypes.string
          passed: PropTypes.bool
        )
      )
    )
    score: PropTypes.string
    source: PropTypes.shape(
      creativeUrl: PropTypes.string
      isVideo: PropTypes.bool
    ),
    performance: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string
        value: PropTypes.string
      )
    )
    platform: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string
        value: PropTypes.string
      )
    )
    tabs: PropTypes.arrayOf(PropTypes.string)
    onTabChange: PropTypes.func
    placements: PropTypes.arrayOf(PropTypes.string)
    copy: PropTypes.string
    snapshots: PropTypes.arrayOf(
      PropTypes.shape(
        version: PropTypes.number,
        details: PropTypes.arrayOf(
          PropTypes.shape(
            label: PropTypes.string
            value: PropTypes.oneOfType([
              PropTypes.number,
              PropTypes.string
            ])
          )
        )
      )
    )
    view: PropTypes.oneOf([
      'Brand Cues',
      'Categories',
      'Compliance',
      'Details',
      'Guidelines',
      'Hypotheses',
      'Versions'
    ])

  @defaultProps =
    view: 'Details'

  constructor: (props) ->
    super(props)
    @state =
      safeZoneModalOpen: false
      tabOptions: props.tabs.slice()
      templateUrl: ''
      view: props.view

    @state.tabOptions.push('Versions') if props.snapshots

  # TODO: Need to update
  #  * Move data fetching code or side effects to componentDidUpdate.
  #  * If you're updating state whenever props change, refactor your code to
  #     use memoization techniques or move it to static getDerivedStateFromProps.
  #     Learn more at: https://fb.me/react-derived-state
  #
  # Update card view state if we get new props
  UNSAFE_componentWillReceiveProps: (props) ->
    @setState(view: props.view)

  toggleViewCallback: (view) =>
    @props.onTabChange(view)
    @setState(view: view)

  onViewModal: (templateUrl) =>
    @setState(templateUrl: templateUrl, safeZoneModalOpen: true)

  onSafeZoneModalClose: =>
    @setState(safeZoneModalOpen: false)

  isFullyReviewed: =>
    this.props.guidelines.every(({ passed }) -> passed != null)

  renderSnapshotDetail: (detail, idx) ->
    `<div key={idx} className='u-flexRow u-justifySpaceBetween postDetail-snapshotDetail'>
      <span>{detail.label}</span>
      <span>{detail.value}</span>
    </div>`

  renderSnapshot: (snapshot) =>
    version = snapshot.version
    snapshotDetails = snapshot.details.map(this.renderSnapshotDetail)

    `<div key={version} className='postDetail-snapshot'>
      <b>Version {version}</b>
      {snapshotDetails}
    </div>`

  renderItem: (item, idx) ->
    `<div key={idx} className='u-flexRow postDetail-item'>
      <div>{item.label}:</div>
      <div>{item.value}</div>
    </div>`

  renderPerformance: ->
    return unless @props.performance

    `<div className='postDetail-performance'>
      <h5>Performance</h5>
      {this.props.performance.map(this.renderItem)}
    </div>`

  renderPlatformInfo: ->
    return unless @props.platform

    `<div className='postDetail-performance'>
      <h5>Platform</h5>
      {this.props.platform.map(this.renderItem)}
    </div>`

  resultIcon: (result) ->
    if _.isNull(result.passed)
      {
        name: 'processing'
      }
    else if result.passed
      {
        color: 'green'
        name: 'checkCircle'
      }
    else
      {
        color: 'red'
        name: 'exclamationCircle'
      }

  renderSafeZoneButton: (templateUrl) ->
    return null if !templateUrl

    `<button
      className='safeZone-button'
      type="button"
      onClick={() => this.onViewModal(templateUrl)}
    >
      View
    </button>`

  renderLabelRow: (rule) =>
    return `<div><MaxWidthText text={rule.name} size='custom' /></div>` if rule.definitions

    direction =
      if this.props.index % 2 is 0
        'right'
      else
        'left'

    `<div className='u-flexRow u-alignCenter'>
      <MaxWidthText text={rule.name} size='custom' />
      { rule.description && <Tooltip label={rule.description} direction={direction} /> }
      { this.renderSafeZoneButton(rule.templateUrl) }
    </div>`

  renderCategory: (category, idx) =>
    icon = this.resultIcon(category)

    `<div key={idx} className='postDetail-guidelineRow postDetail-guidelineDetails'>
      { this.renderLabelRow(category) }
      <div>
        <Icon {...icon} />
      </div>
    </div>`

  renderDefinition: (definition, idx) =>
    passFailDotClasses = classNames(
      'pass-fail-dot',
      'pass-fail-dot--passed': definition.passed,
      'pass-fail-dot--failed': definition.passed is false,
    )

    direction =
      if this.props.index % 2 is 0
        'right'
      else
        'left'

    weight = @renderWeight(definition)

    `<div key={idx} className='postDetail-guidelineDetails'>
      <div className='u-flexRow u-alignCenter'>
        <span className={passFailDotClasses}>{definition.passed}</span>
        <span>{definition.name}</span>
        { definition.description && <Tooltip label={definition.description} direction={direction} /> }
        { this.renderSafeZoneButton(definition.templateUrl) }
      </div>
      <div>{weight}</div>
      <div/>
    </div>`

  renderWeight: (definition, idx) ->
    return null unless definition.weight
    `<div key={idx}>{definition.weight}</div>`

  # TODO: consolidate duplicate code. This logic should be a component.
  # Duplicated here and in renderDefinition above
  renderGuideline: (guideline, idx) =>
    label = this.renderLabelRow(guideline)
    icon = `<div><Icon {...this.resultIcon(guideline)} /></div>`

    # If a guideline has multiple definitions (ex. placement-specific guidelines),
    # expose the weight per definition, not on the guideline level
    if _.isArray(guideline.definitions)
      `<div key={idx} className='postDetail-guidelineRow'>
        <div className='postDetail-guidelineDetails'>
          { label }
          <div/>
          { icon }
        </div>
        {guideline.definitions.map(this.renderDefinition)}
      </div>`

    else
      `<div key={idx} className='postDetail-guidelineRow postDetail-guidelineDetails'>
        { label }
        <div>{ this.renderWeight(guideline) }</div>
        { icon }
      </div>`

  renderRules: (rules, hasWeight) ->
    weightHeader = `<div>Weight</div>` if hasWeight

    `<div className='postDetail-guidelinesTable u-flexColumn'>
      <div className='postDetail-guidelineHeader'>
        <div>Name</div>
        {weightHeader}
        <div>Status</div>
      </div>
      {_.isArray(rules) && rules.map(this.renderGuideline)}
    </div>`

  renderPlacement: (placement, idx) ->
    `<span key={idx}>{placement}</span>`

  renderPlacements: ->
    return unless @props.placements.length > 0

    `<div className='postDetail-placements'>
      <h5>Placements</h5>
      {this.props.placements.map(this.renderPlacement)}
    </div>`

  renderCampaignObjective: ->
    return unless @props.campaignObjective

    `<div className='postDetail-campaignObjective'>
      <h5>Campaign Objective</h5>
      <span>{this.props.campaignObjective}</span>
    </div>`

  renderAltText: ->
    return unless this.props.altText

    `<div className='postDetail-description'>
      <h5>Alt Text</h5>
      <span>{this.props.altText}</span>
    </div>`


  renderContent: ->
    switch @state.view
      when 'Details'
        `<div className='postDetail-content'>
          {this.renderCampaignObjective()}
          {this.renderPlacements()}
          <div className='postDetail-description'>
            <h5>Description</h5>
            <span>{this.props.copy}</span>
          </div>
          {this.renderAltText()}
          {this.renderPerformance()}
          {this.renderPlatformInfo()}
        </div>`
      when 'Guidelines'
        `<div>
          <div className='u-flexRow u-justifyFlexEnd u-marginBelowSm'>
            {this.isFullyReviewed() && this.renderScore()}
          </div>
          <div className='postDetail-guidelines'>
            {this.renderRules(this.props.guidelines, true)}
          </div>
        </div>`
      when 'Categories', 'Brand Cues'
        `<div className='postDetail-guidelines'>
          {this.props.categories.map(this.renderCategory)}
        </div>`
      when 'Compliance'
        @renderRegulatoryContent()
      when 'Versions'
        `<div>
          {this.props.snapshots.map(this.renderSnapshot)}
        </div>`
      when 'Hypotheses'
        `<div className='postDetail-guidelines'>
          {this.renderRules(this.props.hypotheses, false)}
        </div>`

  renderScore: ->
    scoreClasses = classNames(
      "auditScore",
      "auditScore--#{this.props.qualityRank}"
    )
    `<div className={scoreClasses}>{this.props.score}</div>`

  renderRegulatoryContent: =>
    return null unless @regulatoryEnabled()

    regulatoryProps = @props.regulatory
    return null if regulatoryProps.rating == 'unavailable'

    scoreClasses = classNames(
      "auditScore",
      "complianceRating--#{regulatoryProps.rating}"
    )

    `<div>
      <div className='u-flexRow u-justifyFlexEnd u-marginBelowSm'>
        <div className={scoreClasses}>{regulatoryProps.ratingLabel}</div>
      </div>
      <div className='postDetail-guidelines'>
        {this.renderRules(this.props.regulatory.rules, false)}
      </div>
    </div>`

  regulatoryEnabled: =>
    !_.isUndefined(@props.regulatory)

  renderHeader: ->
    tabs = @state.tabOptions.map((tab) => ({
      label: tab,
      onClick: this.toggleViewCallback
    }))

    `<div>
      <div className='postDetail-header'>
        <div className='postDetail-channel'>
          <ChannelLogo
            channel={this.props.channel}
            showPaid={false}
            size='size-32'
          />
          <h5>{this.props.channelDisplay}</h5>
        </div>
      </div>
      <DsTabs currentTab={this.state.view} tabs={tabs} variant='button' />
    </div>`

  renderSafeZone: ->
    `<SafeZoneModal
      isOpen={this.state.safeZoneModalOpen}
      source={this.props.source}
      templateUrl={this.state.templateUrl}
      onClose={this.onSafeZoneModalClose}
    />`

  render: ->
    `<div className='postDetail'>
      {this.renderHeader()}
      {this.renderContent()}
      {this.renderSafeZone()}
    </div>`

export default AuditPostDetail
