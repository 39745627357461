import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Select,
  Widget,
  WidgetContent,
} from '@duik/it';
import CsvButton from 'components/atoms/CsvButton';
import FilterDropdowns from 'components/filters/FilterDropdowns';
import InformationCard, { renderInformationContent } from 'components/molecules/InformationCard';
import ItemsTable from 'components/molecules/ItemsTable';
import NavDropdown from 'components/navigation/NavDropdown';
import { pageStorageKey as adAccountsStorageKey } from 'components/settings/AdAccounts';
import {
  BRAND_MARKET,
  UNIQUE,
} from 'components/settings/FacebookAdPages';
import ArrayHelper from 'components/utils/ArrayHelper';
import { tr } from 'locales/translate';
import { getCurrentMonthRange } from 'utilities/date';
import {
  getDefaultSelections,
  getArrayFilterTest,
} from 'utilities/filtering';
import { setItemsElement } from 'utilities/itemElement';
import { setItemText } from 'utilities/itemText';
import { track } from 'utilities/mixpanel';
import {
  connectionsAdAccountsPath,
  settingsFacebookAdPagesPath as adPagesPath,
  settingsLinkedPlatformAccountsPath as adAccountsPath,
} from 'utilities/routes';
import { byObjectValue } from 'utilities/sort';
import {
  retrieveFromLocalStorage,
  saveToLocalStorage,
} from 'utilities/storage';
import {
  changePage,
  getPage,
  getParams,
} from 'utilities/url';
import styles from './AdAccounts.module.css';
import {
  defaultMetrics,
  defaultSegments,
  metricOptions,
  segmentOptions,
} from './segmenting';

const metricProps = PropTypes.shape({
  delta: PropTypes.number,
  key: PropTypes.string,
  value: PropTypes.number,
});

const dataProps = PropTypes.shape({
  brand: PropTypes.string,
  counts: PropTypes.objectOf(PropTypes.number),
  market: PropTypes.string,
  owner: PropTypes.string,
  partner: PropTypes.string,
  platform: PropTypes.string,
  totalSpend: PropTypes.number,
});

export const propTypes = {
  dateRanges: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  needsAttentionStats: PropTypes.arrayOf(metricProps).isRequired,
  orgId: PropTypes.string.isRequired,
  rawData: PropTypes.arrayOf(dataProps).isRequired,
};

const pageStorageKey = 'dataCoverage';
const orgStorageKey = `${pageStorageKey}_orgId`;

const filterKeys = ['brand', 'market', 'platform', 'partner', 'owner'];
const addLabels = (obj) => (
  {
    ...obj,
    label: tr(obj.key),
    tooltip: tr('adAccountReportingTooltips', obj.key),
  }
);

// @note: Need to include the filters on the off chance that no properties
// are selected to ensure the table updates correctly
function determineTableKey(headers, filters) {
  const key = headers.map(({ key: headerKey }) => headerKey).join('_');

  const filterAddition = Object.keys(filters).reduce((prev, filterKey) => {
    const values = filters[filterKey];
    if (!values.length > 0) return prev;

    return `${prev}_${filterKey}_${values.join('_')}`;
  }, '');

  return key + filterAddition;
}

function getFilters(all, keys) {
  if (!all) {
    return [];
  }

  return keys.map((key) => {
    const options = [...new Set(all.map((item) => item[key]))]
      .map((value) => ({
        value,
        label: value,
      }))
      .sort(byObjectValue);

    return {
      key,
      options,
      label: key,
    };
  });
}

function renderStatCards(stats) {
  if (stats.length === 0) return null;

  return (
    <div className={styles.statCards}>
      {
        stats.map((stat) => {
          const url = stat.key === 'needsReconnecting' ? adAccountsPath() : adPagesPath({ view: UNIQUE });
          const onClick = () => track(stat.label, { [stat.key]: stat.value });
          return <InformationCard key={stat.key} infoItem={stat} url={url} onClick={onClick} />;
        })
      }
    </div>
  );
}

function renderTopLevelMetrics(metrics) {
  return (
    <div className="u-flexRow">
      {
        metrics.map((metric) => (
          <div key={metric.key} className={styles.topLevelMetric}>
            { renderInformationContent(metric) }
          </div>
        ))
      }
    </div>
  );
}

function updateSelection(existing, selected, allOptions) {
  if (selected === null) return [];

  if (selected.value === 'all') {
    return allOptions.filter(({ value }) => value !== 'all');
  }

  if (ArrayHelper.objectInArray(existing, selected)) {
    return ArrayHelper.removeObjectFromArray(existing, selected);
  }

  const updated = [...existing, selected];

  updated.sort((a, b) => (a.order > b.order ? 1 : -1));

  return updated;
}

// Setup the unique row key and the filter values for the links
function getKeyAndRowFilters(segments, datum) {
  const rowFilters = {};
  segments.forEach(({ value: segmentVal }) => {
    rowFilters[segmentVal] = datum[segmentVal];
  });

  const defaultKey = 'noPropertiesDataRow';
  const key = Object.values(rowFilters).join('').replace(/\s/g, '') || defaultKey;

  return {
    key,
    rowFilters,
  };
}

function defaultClickableMetricItem(rowFilters, isBrandPage, tab, column) {
  const onClick = () => {
    saveToLocalStorage({
      ...rowFilters,
      tab,
    }, adAccountsStorageKey);
    track('table_cell_link', { column });
  };

  return ({
    display: {
      method: 'get',
      onAuxClick: onClick, // middle mouse click
      onClick,
      onContextMenu: onClick, // right mouse click
      url: isBrandPage ? adPagesPath({ view: BRAND_MARKET }) : adAccountsPath(),
    },
    value: 0,
  });
}

function defaultDatumItem(key, rowFilters) {
  return ({
    id: { value: key },
    recentAccounts: { value: 0 },
    totalAccounts: defaultClickableMetricItem(rowFilters, false, 'all', 'totalAccounts'),
    totalSpend: {
      format: 'currency',
      value: 0,
    },
  });
}

function getItems(rawData, segments, filterSelections) {
  const filterTest = getArrayFilterTest(filterSelections);
  const aggregatedItems = {};

  rawData.forEach((datum) => {
    // Don't aggregate things that are filtered out
    if (!filterTest(datum)) return;

    const { key, rowFilters } = getKeyAndRowFilters(segments, datum);
    const itemValueForDatum = aggregatedItems[key] || defaultDatumItem(key, rowFilters);

    // Aggregate any recent accounts
    const recentAccounts = 'recentAccounts';
    itemValueForDatum[recentAccounts].value += datum.counts[recentAccounts] || 0;

    // Add in the metrics (active accounts, total accounts, etc)
    metricOptions.forEach(({
      isBrandPage, accountPageTab, value: metricKey,
    }) => {
      if (['all', 'totalAccounts'].includes(metricKey)) return;

      const isTotalSpend = metricKey === 'totalSpend';
      const metricValue = (isTotalSpend ? datum[metricKey] : datum.counts[metricKey]) || 0;

      const defaultMetricItem = defaultClickableMetricItem(
        rowFilters,
        isBrandPage,
        accountPageTab,
        metricKey,
      );
      itemValueForDatum[metricKey] ||= defaultMetricItem;
      itemValueForDatum[metricKey].value += metricValue;

      // @note: FB Pages are not included in the total accounts count
      if (isTotalSpend || isBrandPage) return;

      // Aggregated value for totalAccounts
      itemValueForDatum.totalAccounts.value += metricValue;
    });

    // Add in the segment property values (ie brand, market)
    segmentOptions.forEach(({ value: segmentVal }) => {
      if (segmentVal === 'all') return;

      itemValueForDatum[segmentVal] ||= { value: datum[segmentVal] };
    });

    aggregatedItems[key] = itemValueForDatum;
  });

  // Only need the values, as the keys were used to keep track of unique rows
  return setItemsElement(
    Object
      .values(aggregatedItems)
      .map((item) => setItemText(item)),
  );
}

function calculateAggregatedMetrics(items) {
  let totalSpend = 0;
  let activeAccounts = 0;
  let accountsDelta = 0;

  items.forEach(({
    activeAccounts: accounts, recentAccounts, totalSpend: spend,
  }) => {
    totalSpend += spend.value;
    activeAccounts += accounts.value;
    accountsDelta += recentAccounts.value;
  });

  return (
    [
      {
        format: 'currency',
        key: 'totalSpend',
        value: totalSpend,
      },
      {
        delta: accountsDelta,
        format: 'number',
        key: 'activeAccounts',
        value: activeAccounts,
      },
    ].map(addLabels)
  );
}

function mixpanelFilterPresent(filters, selectedFilters, filterKey) {
  // Handle the empty array case
  if (!selectedFilters) return false;

  const numFilters = selectedFilters.length;
  const maxNumFilters = filters.find((filter) => filter.key === filterKey).options.length;
  return numFilters > 0 && maxNumFilters !== numFilters;
}

function mixpanelCalculateFiltersApplied(allFilters, filterSelections) {
  return filterKeys.reduce((obj, key) => {
    const filterPresent = mixpanelFilterPresent(allFilters, filterSelections[key], key);
    return {
      ...obj,
      [key]: filterPresent,
    };
  }, {});
}

function mixpanelSelectedHeaders(allOptions, selectedOptions) {
  if ((allOptions.length - 1) === selectedOptions.length) {
    return ['All'];
  }

  return selectedOptions.map(({ label }) => label);
}

function mixpanelSegmentData(segments, metrics) {
  return {
    properties: mixpanelSelectedHeaders(segmentOptions, segments),
    metrics: mixpanelSelectedHeaders(metricOptions, metrics),
  };
}

function handleMixpanelFilterChangeEvent(
  filterKey,
  filterValue,
  allFilters,
  filtersApplied,
  setFiltersApplied,
) {
  const newIsFilteringBy = mixpanelFilterPresent(allFilters, filterValue, filterKey);
  if (filtersApplied[filterKey] !== newIsFilteringBy) {
    track('page_interaction', {
      ...filtersApplied,
      [filterKey]: newIsFilteringBy,
    });
    setFiltersApplied((prevState) => ({
      ...prevState,
      [filterKey]: newIsFilteringBy,
    }));
  }
}

function AdAccountReport({
  dateRanges,
  needsAttentionStats,
  orgId,
  rawData,
}) {
  const filterOptions = getFilters(rawData, filterKeys);

  // Page filters
  const emptyFilterSelections = getDefaultSelections(filterKeys, {});
  const [filterSelections, setFilterSelections] = useState(emptyFilterSelections);

  // Mixpanel filter attributes
  const emptyFiltersApplied = mixpanelCalculateFiltersApplied(filterOptions, {});
  const [mixpanelFiltersApplied, setMixpanelFiltersApplied] = useState(emptyFiltersApplied);

  useEffect(() => {
    track('view_page');
    const orgForFilters = retrieveFromLocalStorage(orgStorageKey);

    // Short circuit if the orgs do not match; Only relevant for CX Admin
    // behavior. Prevents assignment of filters with values that don't exist on
    // the current org
    if (orgForFilters !== orgId) return;

    const filtersFromStorage = retrieveFromLocalStorage(pageStorageKey);
    if (filtersFromStorage) {
      setFilterSelections(filtersFromStorage);
      setMixpanelFiltersApplied(mixpanelCalculateFiltersApplied(filterOptions, filtersFromStorage));
    }
  }, []);

  const params = getParams(window);

  const [headers, setHeaders] = useState([]);
  const [page, setPage] = useState(getPage(params));

  // Metric and Segment (Property) selectors to modify columns in the table
  const [metrics, setMetrics] = useState(defaultMetrics);
  const [segments, setSegments] = useState(defaultSegments);

  // @note: Needed for the csv download
  const [tableSort, setTableSort] = useState({});

  const items = getItems(rawData, segments, filterSelections);
  const topLevelMetrics = calculateAggregatedMetrics(items);

  useEffect(() => {
    const updatedHeaders = segments
      .concat(metrics)
      .map((obj) => ({
        key: obj.value,
        label: obj.label,
      }));

    setHeaders(updatedHeaders);
  }, [segments, metrics]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    changePage(newPage, params, window);
  };

  const handleSegmentChange = (segment) => {
    handlePageChange(1);
    const updatedSegments = updateSelection(segments, segment, segmentOptions);
    setSegments(updatedSegments);
    track('change_headers', mixpanelSegmentData(updatedSegments, metrics));
  };

  const handleMetricChange = (metric) => {
    handlePageChange(1);
    const updatedMetrics = updateSelection(metrics, metric, segmentOptions);
    setMetrics(updatedMetrics);
    track('change_headers', mixpanelSegmentData(segments, updatedMetrics));
  };

  const handleFilterChange = (key, value) => {
    handlePageChange(1);
    handleMixpanelFilterChangeEvent(
      key,
      value,
      filterOptions,
      mixpanelFiltersApplied,
      setMixpanelFiltersApplied,
    );
    setFilterSelections((selections) => {
      const updatedFilters = {
        ...selections,
        [key]: value,
      };
      saveToLocalStorage(orgId, orgStorageKey);
      saveToLocalStorage(updatedFilters, pageStorageKey);
      return updatedFilters;
    });
  };

  const dates = dateRanges.map((date) => ({
    ...date,
    link: connectionsAdAccountsPath({ date_range: date.value }),
    text: date.label,
    onClick: (option) => track('time_period', { period: option.value !== getCurrentMonthRange() }),
  }));
  const selectedDate = dates.find(({ active }) => active);

  const handleOnTableSortChange = (sortData) => {
    setTableSort(sortData);
    const header = headers.find((item) => item.key === sortData.key);
    track('table_sorting', {
      sorting: header.label,
      ascending: sortData.asc,
    });
  };

  return (
    <div className={styles.container}>
      { renderStatCards(needsAttentionStats.map(addLabels)) }
      <Widget>
        <WidgetContent>
          <div className={styles.filterRow}>
            <FilterDropdowns
              filters={filterOptions}
              selections={filterSelections}
              onChange={handleFilterChange}
            />
            <NavDropdown
              activeOption={selectedDate}
              menuPosition="bottom-left"
              options={dates}
              block
            />
          </div>
          <Divider margin />
          <div className="u-flexRow u-justifySpaceBetween">
            { renderTopLevelMetrics(topLevelMetrics) }
            <CsvButton
              filename="ad_accounts.csv"
              headers={headers}
              items={items}
              tableSort={tableSort}
              onClick={() => track('csv_export')}
            />
          </div>
          <Divider margin />
          <div className="u-flexRow">
            <div className={styles.propertySelects}>
              <Select
                activeOption={segments}
                className="u-marginBelowMd"
                label="Properties"
                options={segmentOptions}
                block
                multiple
                onOptionClick={handleSegmentChange}
              />
              <Select
                activeOption={metrics}
                label="Metrics"
                options={metricOptions}
                block
                multiple
                onOptionClick={handleMetricChange}
              />
            </div>
            <div className={styles.tableContainer}>
              <ItemsTable
                key={determineTableKey(headers, filterSelections)}
                className={styles.table}
                headers={headers}
                items={items}
                page={page}
                onPageChange={handlePageChange}
                onSortChange={handleOnTableSortChange}
              />
            </div>
          </div>
        </WidgetContent>
      </Widget>
    </div>
  );
}

AdAccountReport.propTypes = propTypes;

export default AdAccountReport;
