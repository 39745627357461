import React from "react"
import PropTypes from "prop-types"
import AuditCreative from 'components/audit/AuditCreative'
import AuditPostDetail from "components/audit/audit_post_detail"
import MixpanelTracker from "components/utils/MixpanelTracker"
import { Icon } from '@makeably/creativex-design-system'

#  Shows asset information and dated audit post information for a given audit asset
class AuditAssetOverview extends React.Component
  @propTypes =
    # Array of props for the AuditPostDetails to render
    auditPostDetails: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired
    # Array of audit or asset details to display to the user
    creativeDetails: PropTypes.arrayOf(
      PropTypes.shape(
        label: PropTypes.string
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      )
    ).isRequired
    closeModal: PropTypes.func
    # If the content is rendered in a modal
    isModalRender: PropTypes.bool
    # Direct link to the Overview
    linkUrl: PropTypes.string
    challengeUrl: PropTypes.string
    modalId: PropTypes.string
    postUrl: PropTypes.string
    renderArrowNavButton: PropTypes.func # Callback for navigating between audit assets
    source: PropTypes.string
    tabs: PropTypes.arrayOf(PropTypes.string)
    thumbnailUrl: PropTypes.string
    view: PropTypes.string
    video: PropTypes.bool.isRequired

  @defaultProps =
    isModalRender: true

  constructor: (props) ->
    super(props)
    @state =
      copySuccess: false
      view: props.view

  trackTab: (view) ->
    MixpanelTracker.trackTabClick("Modal", view)

  trackDetailsTab: (view) ->
    MixpanelTracker.trackTabClick("Modal Detail", view)

  renderAuditPostDetail: (auditPostDetail, idx) =>
    view = @state.view
    source = {
      creativeUrl: @props.source,
      isVideo: @props.video,
    }

    `<AuditPostDetail
      key={idx}
      index={idx}
      source={source}
      tabs={this.props.tabs}
      view={view}
      onTabChange={this.trackDetailsTab}
      {...auditPostDetail}
    />`

  renderCloseButton: ->
    return unless @props.isModalRender

    `<div className='scorecard-closeWrapper' onClick={this.props.closeModal}>
      <Icon name='closeX' />
    </div>`

  renderTab: (tab, index) =>
    onClick = =>
      this.trackTab(tab)
      @setState(view: tab)

    `<div key={tab} className='u-flexRow u-flexAlignCenter'>
      {index > 0 && <span>|</span>}
      <h5 onClick={onClick}>{tab}</h5>
    </div>`

  render: ->
    # The audit asset is displayed either in a modal or in its own page
    # (single asset view). When displayed in a modal, we show left and
    # right arrow buttons for navigating between modals.
    if @props.renderArrowNavButton
      leftArrow = this.props.renderArrowNavButton('left')
      rightArrow = this.props.renderArrowNavButton('right')

    `<div key={this.props.assetUrl} className='scorecard'>
      <AuditCreative
        creativeDetails={this.props.creativeDetails}
        challengeUrl={this.props.challengeUrl}
        isVideo={this.props.video}
        sourceUrl={this.props.source}
        externalUrl={this.props.postUrl}
        overviewUrl={this.props.linkUrl}
        thumbnailUrl={this.props.thumbnailUrl}
      />
      { leftArrow }
      <div className='scorecard-details'>
        <div className='scorecard-header'>
          <div className='scorecard-viewToggle'>
            { this.props.tabs.map(this.renderTab) }
          </div>
          {this.renderCloseButton()}
        </div>
        <div className='scorecard-postDetailsContainer'>
          {this.props.auditPostDetails.map(this.renderAuditPostDetail)}
        </div>
      </div>
      {rightArrow}
    </div>`

export default AuditAssetOverview
