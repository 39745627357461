// Renders the Inflight and Preflight queue tables
import React from 'react';
import PropTypes from 'prop-types';
import { WidgetTable } from '@duik/it';
import { Tooltip } from '@makeably/creativex-design-system';

const propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    tooltip: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

function renderTableHeaders(tableHeaders) {
  return (
    <thead>
      <tr>
        {
          tableHeaders.map(({ label, tooltip }) => {
            let content = label;
            if (tooltip) {
              content = (
                <div className="u-flexRow u-label">
                  <span className="u-marginRightSm">{ label }</span>
                  { tooltip && <Tooltip label={tooltip} /> }
                </div>
              );
            }
            return (<th key={label}>{ content }</th>);
          })
        }
      </tr>
    </thead>
  );
}

function renderTableRow(item, tableHeaders) {
  return (
    <tr key={item.id}>
      {
        tableHeaders.map(({ value: key }, idx) => {
          let content = item[key];

          if (key === 'id') {
            content = <a href={item.link}>{ content }</a>;
          }

          const tdKey = `${item.id}-${idx}`;
          return <td key={tdKey}>{ content }</td>;
        })
      }
    </tr>
  );
}

function renderTableBody(items, tableHeaders) {
  let content;

  if (items.length > 0) {
    content = items.map((item) => renderTableRow(item, tableHeaders));
  } else {
    content = (
      <tr>
        <td colSpan={tableHeaders.length}>Nothing to see here. This queue is empty!</td>
      </tr>
    );
  }

  return <tbody>{ content }</tbody>;
}

function QueueTable(props) {
  const {
    headers,
    items,
  } = props;

  return (
    <WidgetTable>
      { renderTableHeaders(headers) }
      { renderTableBody(items, headers) }
    </WidgetTable>
  );
}

QueueTable.propTypes = propTypes;

export default QueueTable;
