import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

class Column extends React.Component
  @propTypes =
    size: PropTypes.number # Number from 1-12. Column will take up size/12 of the total horizontal space.
    styleName: PropTypes.string

  @defaultProps =
    size: 12

  classes: ->
    classNames(
      'col',
      "s#{this.props.size}",
      this.props.className,
      this.props.styleName
    )

  render: ->
    `<div className={this.classes()}>
      {this.props.children}
    </div>`

export default Column
