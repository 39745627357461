import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
} from '@makeably/creativex-design-system';
import CreateJobsModal from 'components/admin/annotation/CreateJobsModal';
import ItemsTable from 'components/molecules/ItemsTable';
import { getItemSortBy } from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import {
  adminLogoElementPath,
  editAdminLogoElementPath,
  newAdminLogoElementPath,
} from 'utilities/routes';
import { titleize } from 'utilities/string';
import styles from './Elements.module.css';

const elementProps = PropTypes.shape({
  brand: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  jobs: PropTypes.shape({
    countWithLogo: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
});

const propTypes = {
  elements: PropTypes.arrayOf(elementProps).isRequired,
};

const headers = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'label',
    label: 'Element Name',
  },
  {
    key: 'dateCreated',
    label: 'Date Created',
  },
  {
    key: 'annotations',
    label: 'Annotations',
  },
  {
    key: 'company',
    label: 'Company',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];

function Elements({ elements }) {
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState();
  const [sortedItems, setSortedItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeElement, setActiveElement] = useState(undefined);

  const onGenerateJobsClick = (element) => {
    setActiveElement(element);
    setShowModal(true);
  };

  function createJobs(element) {
    return {
      label: element.state === 'editing' ? 'Activate' : 'Create Jobs',
      onClick: () => onGenerateJobsClick(element),
    };
  }

  function deactivateUrl(element) {
    return {
      label: 'Delete',
      method: 'delete',
      url: adminLogoElementPath(element.id),
      warning: true,
    };
  }

  function generateLabel(element) {
    return {
      display: { url: editAdminLogoElementPath(element.id) },
      value: element.label,
    };
  }

  function generateActions(element) {
    return {
      display: {
        urls: [
          createJobs(element),
          ...element.state === 'editing' ? [deactivateUrl(element)] : [],
        ],
      },
      value: '',
    };
  }

  function getItems() {
    return elements.map((element) => setItemElement({
      id: { value: element.id },
      label: generateLabel(element),
      dateCreated: { value: element.createdAt },
      annotations: { value: `${element.jobs.totalCount} (${element.jobs.countWithLogo} with logo)` },
      company: { value: element.company },
      brand: { value: element.brand },
      status: { value: titleize(element.state) },
      actions: generateActions(element),
    }));
  }

  useEffect(() => {
    setItems(getItems());
  }, [elements]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    } else {
      setSortedItems(items);
    }
  }, [items, sort]);

  return (
    <>
      <Card>
        <div className="u-flexRow u-marginBottom-16 u-spaceBetween">
          <h5 className="u-marginTop-8">
            List of Elements
          </h5>
          <AddNewButton
            label="Create New Element"
            url={newAdminLogoElementPath()}
          />
        </div>
        <div className={styles.table}>
          <ItemsTable
            headers={headers}
            items={sortedItems}
            page={1}
            sort={sort}
            onSortChange={(value) => setSort(value)}
          />
        </div>
      </Card>
      { showModal && (
        <CreateJobsModal
          id={activeElement.id}
          isOpen={showModal}
          label={activeElement.label}
          onClose={() => setShowModal(false)}
        />
      ) }
    </>
  );
}

Elements.propTypes = propTypes;

export default Elements;
