// Sets controller and user attributes for mixpanel tracking
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { saveToLocalStorage } from 'utilities/storage';

const propTypes = {
  controller: PropTypes.shape({
    action: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userAttributes: PropTypes.object.isRequired,
};

function Tracking({ controller, userAttributes }) {
  useEffect(() => {
    saveToLocalStorage(controller, 'controller');
    saveToLocalStorage(userAttributes, 'userAttributes');
  }, []);

  return null;
}

Tracking.propTypes = propTypes;

export default Tracking;
