import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '@duik/it';

export const hiddenInputProps = {
  'data-testid': PropTypes.string,
  form: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
};

const defaultHiddenInput = {
  'data-testid': undefined,
  form: undefined,
  name: undefined,
  value: undefined,
};

function invalidValue(value) {
  return (value === undefined || value === null);
}

function HiddenInput(props) {
  const {
    'data-testid': testId,
    form,
    name,
    value,
  } = props;

  if (invalidValue(value)) return null;

  return (
    <input
      data-testid={testId}
      form={form}
      name={name}
      type="hidden"
      value={value}
    />
  );
}
HiddenInput.propTypes = hiddenInputProps;
HiddenInput.defaultProps = defaultHiddenInput;

const hiddenInputsProps = {
  'data-testid': PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
};

const defaultHiddenInputs = {
  'data-testid': undefined,
  name: undefined,
  values: [],
};

function HiddenInputs(props) {
  const {
    'data-testid': testId,
    name,
    values,
  } = props;

  if (values.length <= 0) return null;

  return (
    <>
      {
        values.map((value) => (
          <HiddenInput
            key={value}
            data-testid={testId}
            name={name}
            value={value}
          />
        ))
      }
    </>
  );
}
HiddenInputs.propTypes = hiddenInputsProps;
HiddenInputs.defaultProps = defaultHiddenInputs;

// Used to render a form element with an associated hidden input element.
// @note: Does not currently support array values
function withHiddenInput(FormComponent, props) {
  const {
    className,
    inputName,
    inputValue,
    key,
    ...componentProps
  } = props;

  let value = inputValue;
  if (invalidValue(value)) value = componentProps.value;

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <FormGroup key={key} className={className}>
      <FormComponent {...componentProps} />
      <HiddenInput name={inputName} value={value} />
    </FormGroup>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

export {
  HiddenInput,
  HiddenInputs,
  withHiddenInput,
};
