// Renders form for creating/updating Rules
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  FormGroupContainer,
  TextArea,
  Select,
  TextField,
  Widget,
  WidgetContent,
} from '@duik/it';
import { Checkbox } from '@makeably/creativex-design-system';
import DuikSelectDate from 'components/molecules/DuikSelectDate';
import CardHeader from 'components/reusable/CardHeader';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import FormSubmit from 'components/reusable/FormSubmit';
import {
  hiddenInputProps,
  HiddenInput,
  withHiddenInput,
} from 'components/reusable/HiddenInput';
import FormHelper from 'components/utils/FormHelper';
import ObjectHelper from 'components/utils/ObjectHelper';
import StringHelper from 'components/utils/StringHelper';

const propTypes = {
  action: PropTypes.oneOf(['new', 'edit']).isRequired,
  applicabilityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  canModifyStandardGuidelines: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  formUrl: PropTypes.string.isRequired,
  guideline: PropTypes.shape({
    applicability: PropTypes.string.isRequired,
    branded: PropTypes.bool.isRequired,
    displayName: PropTypes.string.isRequired,
    evaluationOrder: PropTypes.number.isRequired,
    global: PropTypes.bool.isRequired,
    organizationOrder: PropTypes.number.isRequired,
    ruleType: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string,
    launchDate: PropTypes.string,
    name: PropTypes.string,
    samplingRate: PropTypes.number,
  }).isRequired,
  organizationName: PropTypes.string.isRequired,
  ruleTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  supportsMultipleReviews: PropTypes.bool.isRequired,
  supportsStandardScore: PropTypes.bool.isRequired,
};

const formFields = [
  {
    type: TextField,
    props: {
      autoFocus: true,
      inputName: 'guideline[name]',
      label: 'Rule Name',
      name: 'name',
      required: true,
    },
  },
  {
    type: Checkbox,
    standardGuidelineCheckbox: true,
    props: {
      inputName: 'guideline[standard]',
      label: 'Standard Guideline (for standard CQ calculation)',
      name: 'standard',
    },
  },
  {
    type: Checkbox,
    paidGuidelineCheckbox: true,
    props: {
      inputName: 'guideline[paid]',
      label: 'Include in Usage Reporting',
      name: 'paid',
    },
  },
  {
    type: TextArea,
    props: {
      inputName: 'guideline[description]',
      label: 'Brief Description',
      name: 'description',
      rows: 5,
    },
  },
  {
    type: TextField,
    props: {
      className: 'guidelines-numberInputs',
      type: 'number',
      inputName: 'guideline[organization_order]',
      min: 1,
      required: true,
      name: 'organizationOrder',
      label: 'Rule Position',
    },
  },
  {
    type: TextField,
    props: {
      className: 'guidelines-numberInputs',
      type: 'number',
      inputName: 'guideline[evaluation_order]',
      min: 1,
      required: true,
      name: 'evaluationOrder',
      label: 'Evaluation Page Position',
    },
  },
  {
    type: Select,
    optionKey: 'ruleTypeOptions',
    nonLive: true,
    props: {
      className: 'guidelines-select',
      inputName: 'guideline[rule_type]',
      label: 'Rule Type',
      name: 'ruleType',
    },
  },
  {
    type: Select,
    optionKey: 'applicabilityOptions',
    props: {
      className: 'guidelines-select',
      inputName: 'guideline[applicability]',
      label: 'Applicability',
      name: 'applicability',
    },
  },
  {
    type: Select,
    optionKey: 'globalOptions',
    nonLive: true,
    props: {
      className: 'guidelines-select',
      inputName: 'guideline[global]',
      label: 'Global or Local',
      name: 'global',
    },
  },
  {
    type: Select,
    optionKey: 'brandedOptions',
    nonLive: true,
    props: {
      className: 'branded-select',
      inputName: 'guideline[branded]',
      label: 'Brand-centric',
      name: 'branded',
    },
  },
];

const globalOptions = [
  {
    label: 'Global',
    value: true,
  },
  {
    label: 'Local',
    value: false,
  },
];

const brandedOptions = [
  {
    label: 'Branded',
    value: true,
  },
  {
    label: 'Not Branded',
    value: false,
  },
];

function SamplingRate({
  supported, onChange, value,
}) {
  if (!supported) return null;

  const label = (
    <>
      Sampling Rate (0-100%)
      <br />
      <span>This will specify the percentage of all assets that will undergo multiple reviews</span>
    </>
  );

  const fieldProps = {
    className: 'guidelines-samplingRate guidelines-numberInputs',
    inputName: 'guideline[sampling_rate]',
    label,
    max: 100,
    min: 0,
    onChange,
    required: true,
    rightEl: '%',
    type: 'number',
    value,
  };

  return withHiddenInput(TextField, fieldProps);
}
SamplingRate.propTypes = {
  supported: PropTypes.bool,
  value: hiddenInputProps.value,
  onChange: PropTypes.func,
};
SamplingRate.defaultProps = {
  onChange: undefined,
  supported: undefined,
  value: undefined,
};

function LaunchDateSelector({
  onChange, value, visible,
}) {
  if (!visible) return null;

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const dateProps = {
    inputName: 'guideline[launch_date]',
    inputValue: StringHelper.dateToString(value) || '',
    label: 'Launch date for rule',
    minDate: yesterday,
    onDateChange: onChange,
    value,
  };

  return (
    <div className="u-flexRow u-flexAlignEnd guidelines-select">
      { withHiddenInput(DuikSelectDate, dateProps) }
      <Button
        className="u-marginLeftSm"
        clear
        onClick={() => onChange(null)}
      >
        Clear Date
      </Button>
    </div>
  );
}
LaunchDateSelector.propTypes = {
  visible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
};
LaunchDateSelector.defaultProps = {
  value: null,
};

class GuidelineForm extends React.Component {
  static validForm(state) {
    const {
      evaluationOrder,
      name,
      organizationOrder,
      samplingRate,
    } = state;

    let valid = name.length > 0;

    valid &&= [organizationOrder, evaluationOrder].every((i) => parseInt(i) >= 0);

    const sample = parseInt(samplingRate);
    valid &&= (sample >= 0 && sample <= 100);

    return valid;
  }

  constructor(props) {
    super(props);

    const {
      applicabilityOptions,
      guideline,
      ruleTypes,
    } = props;

    guideline.description ||= '';
    guideline.launchDate ||= undefined;
    guideline.name ||= '';
    guideline.samplingRate ||= 0;

    const {
      applicability,
      global,
      branded,
      launchDate,
      ruleType,
    } = guideline;

    this.applicabilityOptions = applicabilityOptions;
    this.globalOptions = globalOptions;
    this.brandedOptions = brandedOptions;
    this.ruleTypeOptions = ObjectHelper.valuesToObjects(ruleTypes, true);

    this.state = {
      ...guideline,
      applicability: this.applicabilityOptions.find(({ value }) => value === applicability),
      global: this.globalOptions.find(({ value }) => value === global),
      branded: this.brandedOptions.find(({ value }) => value === branded),
      launchDate: StringHelper.stringToDate(launchDate),
      ruleType: this.ruleTypeOptions.find(({ value }) => value === ruleType),
    };
  }

  componentDidMount() {
    // NOTE: We need to add type=button for buttons in the DuikSelectDate component. Otherwise the
    // default type=submit will submit the form.
    const buttons = [...document.getElementsByClassName('datepicker-nav-btn')];
    buttons.forEach((button) => {
      // eslint-disable-next-line no-param-reassign
      button.type = 'button';
    });
  }

  onInputChange(name, value) {
    this.setState({ [name]: value });
  }

  renderFormField(
    formField,
    action,
    editing,
    branded,
    canModifyStandardGuidelines,
    supportsStandardScore,
  ) {
    const {
      nonLive,
      optionKey,
      props,
      standardGuidelineCheckbox,
      paidGuidelineCheckbox,
      type,
    } = formField;
    const { name: fieldName } = props;
    const { [fieldName]: fieldValue, ruleType } = this.state;

    // optionKey present for Select fields
    if (optionKey) {
      props.activeOption = fieldValue;
      props.onOptionClick = (value, name) => this.onInputChange(name, value);
      props.options = this[optionKey];
      props.inputValue = fieldValue.value;
    } else if (standardGuidelineCheckbox) {
      if (!supportsStandardScore) return null;
      if (ruleType.value !== 'guideline') return null;

      props.checked = fieldValue;
      props.disabled = !canModifyStandardGuidelines;
      props.onChange = (_e) => this.onInputChange(fieldName, !fieldValue);
      props.value = fieldValue;
    } else if (paidGuidelineCheckbox) {
      props.checked = fieldValue;
      props.disabled = (action === 'edit' && !editing);
      props.onChange = (_e) => this.onInputChange(fieldName, !fieldValue);
      props.value = fieldValue;
    } else {
      props.onChange = (e) => this.onInputChange(fieldName, e.target.value);
      props.value = fieldValue;
    }

    props.key = fieldName;

    if (nonLive) {
      let buttonDisabled = !editing;
      if (fieldName === 'branded') buttonDisabled &&= branded;
      props.buttonProps = { disabled: buttonDisabled };
    }

    return withHiddenInput(type, props);
  }

  render() {
    const {
      action,
      canModifyStandardGuidelines,
      editing,
      formUrl,
      guideline: {
        displayName,
        type,
        branded,
      },
      organizationName,
      supportsMultipleReviews,
      supportsStandardScore,
    } = this.props;

    const {
      launchDate,
      samplingRate,
    } = this.state;

    let method;
    let submitLabel;

    if (action === 'new') {
      method = 'post';
      submitLabel = 'Create & Add Definitions';
    } else {
      method = 'patch';
      submitLabel = 'Update';
    }
    const { formMethod, hiddenMethod } = FormHelper.resolveRailsPatchPostMethod(method);

    return (
      <div className="dashboardLayout">
        <Widget>
          <CardHeader
            header={`${StringHelper.titleize(action)} ${displayName} for ${organizationName}`}
          />
          <WidgetContent className="adminRuleForm">
            <form action={formUrl} method={formMethod}>
              <AuthenticityTokenInput />
              <HiddenInput name="_method" value={hiddenMethod} />
              <HiddenInput name="guideline[type]" value={type} />
              <FormGroupContainer>
                <div>
                  <div className="asLabel">Type</div>
                  <div>{ displayName }</div>
                </div>
                {
                  formFields.map((formField) => (
                    this.renderFormField(
                      formField,
                      action,
                      editing,
                      branded,
                      canModifyStandardGuidelines,
                      supportsStandardScore,
                    )
                  ))
                }
                <SamplingRate
                  supported={supportsMultipleReviews}
                  value={samplingRate}
                  onChange={(e) => this.onInputChange('samplingRate', e.target.value)}
                />
                <LaunchDateSelector
                  value={launchDate}
                  visible={editing}
                  onChange={(date) => this.onInputChange('launchDate', date)}
                />
                <FormGroup className="u-marginAboveLg">
                  <FormSubmit
                    className="guidelines-submitBtn"
                    disabled={!GuidelineForm.validForm(this.state)}
                    label={submitLabel}
                  />
                </FormGroup>
              </FormGroupContainer>
            </form>
          </WidgetContent>
        </Widget>
      </div>
    );
  }
}

GuidelineForm.propTypes = propTypes;

export default GuidelineForm;
