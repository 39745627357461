import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '@duik/it';
import {
  Slider as MUISlider,
  Tooltip,
} from '@material-ui/core';

const propTypes = {
  options: PropTypes.shape({
    label: PropTypes.string,
    marks: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    max: PropTypes.number,
    min: PropTypes.number,
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

function ValueLabelComponent(props) {
  const {
    children, open, value,
  } = props;

  return (
    <Tooltip enterTouchDelay={0} open={open} placement="top" title={value}>
      { children }
    </Tooltip>
  );
}
ValueLabelComponent.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

function Slider(props) {
  const {
    options: {
      label,
      marks,
      max,
      min,
    },
    onChange: callback,
    value,
  } = props;

  const onChange = (_, newValue) => {
    if (value !== newValue) {
      callback(newValue);
    }
  };

  return (
    <FormGroup key={label}>
      <label htmlFor={label}>{ label }</label>
      <MUISlider
        ValueLabelComponent={ValueLabelComponent}
        id={label}
        marks={marks}
        max={max}
        min={min}
        size="small"
        step={1}
        value={value}
        valueLabelDisplay="auto"
        onChange={onChange}
      />
    </FormGroup>
  );
}

Slider.propTypes = propTypes;

export default Slider;
