import React from "react"
import Button from "components/reusable/button"
import CheckboxGroup from "components/reusable/checkbox_group"
import Column from "components/reusable/column"
import Row from "components/reusable/row"
import PropTypes from "prop-types"
import { ModalLaunchButton } from "components/containers/miscellaneous_containers"

# Responsible for selecting metrics to display on a page. In the current stage this re-submits the current page with
# the selections appended. This is so we can add this component to a page without worrying about how it fits in with
# other form behavior.
#
# The future vision for this component is for it to work by manipulating what is displayed on the
# cards since usually all metrics are calculated and a page refresh just redoes a bunch of work. This will probably be
# folded into the ImageCardWithMetricsCollection components. The element does however need to inform the server by
# ajax request as to what the user selected so we can save the selection in the session. Also consider cookie
# interaction to avoid session manipulation. Many options!
#
# Another future thing is metric ordering.
class CardMetricSelector extends React.Component
  @propTypes =
    # Array of value/label options
    metrics: PropTypes.array.isRequired
    # Card metrics that are selected
    selectedMetrics: PropTypes.arrayOf(PropTypes.string).isRequired
    # Where to submit changes, the url should not have any card_metrics params on it
    url: PropTypes.string

  constructor: (props) ->
    super(props)
    @state =
      selectedMetrics: props.selectedMetrics

  handleMetricChange: (changedMetric, additionalFields) =>
    if additionalFields.isChecked
      newSelectedMetrics = _.union(@state.selectedMetrics, [changedMetric])
    else
      newSelectedMetrics = _.without(@state.selectedMetrics, changedMetric)

    # For now, keep it in the order of the options. Later we will use a re-order thingy instead of the checkbox.
    metricOptions = _.filter(@props.metrics, (metricOption) -> _.includes(newSelectedMetrics, metricOption.value))
    orderedMetrics = _.map(metricOptions, (option) -> option.value)

    @setState(selectedMetrics: orderedMetrics)

  link: =>
    divider = if @props.url.includes('?') then '&' else '?'
    @props.url + divider + $.param(card_metrics: @state.selectedMetrics)

  # Button text is off of the props and not state on purpose since the metric selections have not taken effect until
  # the user submits the selections.
  buttonText: ->
    if @props.selectedMetrics.length > 1
      @props.selectedMetrics.length + " Metrics Selected"
    else
      selectedMetric = @props.selectedMetrics[0]
      selectedMetricOption = _.find(@props.metrics, (metricOption) -> metricOption.value == selectedMetric)
      selectedMetricOption.label

  renderModal: ->
    `<div>
      <Row>
        <Column>
          <h5>
            Select Card Metrics
          </h5>
        </Column>
      </Row>
      <Row>
        <Column>
          <CheckboxGroup
            onSelect={this.handleMetricChange}
            options={this.props.metrics}
            selectedValues={this.state.selectedMetrics}
          />
        </Column>
      </Row>
      <Row styleName='center-align no-margin'>
        <Column>
          <Button label="Save Selections" link={this.link()} styleName='full-width'/>
        </Column>
      </Row>
    </div>`

  render: ->
    modalId = "card_metric_selection_modal"
    modalProps = {
      contentProps: {
        'data-target': modalId
        children: @buttonText()
        className: 'btn'
      }
      launchType: "click"
      modal: {
        children: @renderModal()
        id: modalId
        shrink: true
      }
    }

    `<div className='cardMetricSelector-launchButtonContainer'>
      <label>Selected Card Metrics</label>
      <ModalLaunchButton {...modalProps} />
    </div>`

export default CardMetricSelector
