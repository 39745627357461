import React from 'react';
import PropTypes from 'prop-types';
import TrialQueue from 'components/admin/review_queue/TrialQueue';
import { editAdminTrialGuidelineCheckPath } from 'utilities/routes';

const propTypes = {
  checks: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string,
      channel: PropTypes.string,
      guidelineId: PropTypes.number,
      guidelineName: PropTypes.string,
      id: PropTypes.number,
      market: PropTypes.string,
      organization: PropTypes.string,
    }),
  ).isRequired,
};

const headers = [
  {
    key: 'guidelineCheckId',
    label: 'Guideline Check ID',
  },
  {
    key: 'guideline',
    label: 'Guideline',
  },
  {
    key: 'organization',
    label: 'Organization',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
];

const filters = [
  {
    key: 'organization',
    label: 'Organization',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
  {
    key: 'guideline',
    label: 'Guideline',
  },
];

function getItems(checks) {
  return checks.map((check) => ({
    brand: { value: check.brand },
    channel: { value: check.channel },
    guideline: {
      text: `${check.guidelineId} - ${check.guidelineName}`,
      value: check.guidelineId,
    },
    guidelineCheckId: {
      display: { url: editAdminTrialGuidelineCheckPath(check.id) },
      value: check.id,
    },
    id: { value: check.id.toString() },
    organization: { value: check.organization },
    market: { value: check.market },
  }));
}

function TrialGuidelineCheckQueue({ checks }) {
  return (
    <TrialQueue
      checks={getItems(checks)}
      filterOptions={filters}
      headers={headers}
    />
  );
}

TrialGuidelineCheckQueue.propTypes = propTypes;

export default TrialGuidelineCheckQueue;
