import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CollapsibleWidget from 'components/molecules/CollapsibleWidget';
import styles from './AudioTranscription.module.css';

export const propTypes = {
  audioTranscription: PropTypes.shape({
    language: PropTypes.string,
    transcription: PropTypes.string,
  }),
};

export const defaultProps = {
  audioTranscription: undefined,
};

function AudioTranscription({ audioTranscription }) {
  if (!audioTranscription) return null;

  const {
    language,
    transcription,
  } = audioTranscription;

  const labelClasses = classNames('t-label-1', styles.label);

  return (
    <CollapsibleWidget headerText="Audio Tags">
      <div className={labelClasses}>Language</div>
      <div className="u-marginBelow">
        { language }
      </div>
      <div className={labelClasses}>Transcription</div>
      <div>
        { transcription }
      </div>
    </CollapsibleWidget>
  );
}

AudioTranscription.propTypes = propTypes;
AudioTranscription.defaultProps = defaultProps;

export default AudioTranscription;
