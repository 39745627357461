import React from "react"
import PlotlyChart from "components/reusable/plotly_chart"
import PropTypes from "prop-types"

# Responsible for ploting traces across two y axis. Primary will appear on the left and
# secondary will appear on the right.
class DualYAxisPlotlyChart extends React.Component
  @propTypes =
    primary: PropTypes.shape(
      layout: PropTypes.object
      traces: PropTypes.array.isRequired
    )
    secondary: PropTypes.shape(
      layout: PropTypes.object
      traces: PropTypes.array.isRequired
    )

  render: ->
    plotlyProps = $.extend(true, {}, @props.primary)

    if @props.secondary
      secondaryProps = $.extend(true, {}, @props.secondary)

      for trace in plotlyProps.traces
        trace.yaxis = "y"
        trace.visible = true

      for trace in secondaryProps.traces
        trace.yaxis = "y2"
        trace.visible = true
        plotlyProps.traces.push(trace)

      plotlyProps.layout.yaxis.side = 'left'
      plotlyProps.layout.yaxis2 = secondaryProps.layout.yaxis
      plotlyProps.layout.yaxis2.overlaying = 'y'
      plotlyProps.layout.yaxis2.side = 'right'

    `<PlotlyChart {...plotlyProps}/>`

export default DualYAxisPlotlyChart
