import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Widget,
  WidgetContent,
  WidgetHeader,
} from '@duik/it';
import { Tooltip } from '@makeably/creativex-design-system';
import StringHelper from 'components/utils/StringHelper';

const statProps = {
  format: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  opts: PropTypes.shape({
    sigFigs: PropTypes.number,
  }),
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
};

export const propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape(statProps),
  ).isRequired,
};

function renderLabelContent(label, tooltipText, tooltipPosition) {
  return (
    <div className="u-flexRow">
      <span className="u-marginRightSm">
        { label }
      </span>
      { tooltipText && <Tooltip direction={tooltipPosition} label={tooltipText} /> }
    </div>
  );
}

function TopLevelStat(props) {
  const {
    format,
    label,
    opts,
    tooltip: tooltipText,
    tooltipPosition,
    value,
  } = props;

  return (
    <Widget className="topLevelStat">
      <WidgetHeader className="topLevelStat-label">
        { renderLabelContent(label, tooltipText, tooltipPosition) }
      </WidgetHeader>
      <WidgetContent>
        <div className="topLevelStat-value">{ StringHelper.format(value, format, opts) }</div>
      </WidgetContent>
    </Widget>
  );
}
TopLevelStat.propTypes = statProps;
TopLevelStat.defaultProps = {
  opts: undefined,
  tooltip: undefined,
  tooltipPosition: 'top',
};

function TopLevelStats({ stats }) {
  const classes = classNames(
    'topLevelStats',
    `topLevelStats--${stats.length}`,
  );

  return (
    <div className={classes}>
      {
        stats.map((stat) => {
          const {
            format,
            label,
            sigFigs,
            tooltip,
            tooltipPosition,
            value,
          } = stat;

          const opts = {};
          if (sigFigs) opts.sigFigs = sigFigs;

          return (
            <TopLevelStat
              key={label}
              format={format}
              label={label}
              opts={opts}
              tooltip={tooltip}
              tooltipPosition={tooltipPosition}
              value={value}
            />
          );
        })
      }
    </div>
  );
}

TopLevelStats.propTypes = propTypes;

export default TopLevelStats;
