import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@makeably/creativex-design-system';
import ChannelLogo from 'components/atoms/ChannelLogo';
import styles from './ChannelLogos.module.css';

const propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  showTooltipForHidden: PropTypes.bool,
  shownMax: PropTypes.number,
  size: PropTypes.oneOf(['size-16', 'size-24', 'size-32']),
};

const defaultProps = {
  showTooltipForHidden: true,
  shownMax: undefined,
  size: 'size-24',
};

function ChannelLogos({
  channels,
  showTooltipForHidden,
  shownMax,
  size,
}) {
  const shownChannels = shownMax ? channels.slice(0, shownMax) : channels;
  const hiddenChannels = shownMax ? channels.slice(shownMax) : [];
  const hiddenCount = hiddenChannels.length;
  const hiddenNames = hiddenChannels.map(({ label }) => label).join(', ');
  const hiddenCountLabel = `+ ${hiddenCount} more`;

  const renderHiddenCount = () => {
    if (showTooltipForHidden) {
      return (
        <Tooltip direction="left" label={hiddenNames}>
          { hiddenCountLabel }
        </Tooltip>
      );
    }

    return hiddenCountLabel;
  };

  return (
    <div className={styles.logos}>
      { shownChannels.map(({ label, value }) => (
        <Tooltip key={value} label={label}>
          <ChannelLogo
            channel={value}
            showPaid={false}
            size={size}
          />
        </Tooltip>
      )) }
      { hiddenCount > 0 && renderHiddenCount() }
    </div>
  );
}

ChannelLogos.propTypes = propTypes;
ChannelLogos.defaultProps = defaultProps;

export default ChannelLogos;
