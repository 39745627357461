# TODO: Remove, Old Analytics Tool
import React from "react"
import Button from "components/reusable/button"
import Card from "components/reusable/card"
import PropTypes from "prop-types"
import classNames from "classnames"
import Column from "components/reusable/column"
import Pills from "components/reusable/Pills"
import Row from "components/reusable/row"
import ViewSelector from "components/reusable/view_selector"

# This is a container for data views on the master comparison report. Usage may be more general than that, but scoping
# to the master comparison report for now and common behavior shared between views.
#
# This container provides:
#     - A title
#     - A way to switch between data views (show data as percentage vs. values)
#     - A way to switch between metrics (uses ViewSelector and reloads page if onChange is not overridden)
#     - A see more button that can be shown and setup with a passed in callback (ex. show 10 more rows)
class ComparisonDataContainer extends React.Component
  @propTypes =
    dataViewSelectParams: PropTypes.shape(  # Params for data view selection (not displayed if null)
      onChange: PropTypes.func.isRequired
      options: PropTypes.array.isRequired
      selected: PropTypes.string.isRequired
    )
    metricSelectParams: PropTypes.shape(    # Params for metric selection (not displayed if null)
      onChange: PropTypes.func
      options: PropTypes.array.isRequired
      selected: PropTypes.string.isRequired
    )
    seeMoreParams: PropTypes.shape (        # Params for see more (not displayed if null)
      onClick: PropTypes.func.isRequired
    )
    title: PropTypes.string

  classes: ->
    classNames(
      'comparisonDataContainer'
    )

  renderSeeMoreRow: ->
    if this.props.seeMoreParams
      `<Row>
        <Column styleName='center-align'>
          <Button label='See More' {...this.props.seeMoreParams}/>
        </Column>
      </Row>`

  renderMetricSelect: ->
    if this.props.metricSelectParams
      `<ViewSelector force="select" {...this.props.metricSelectParams} />`

  renderDataViewSelect: ->
    if this.props.dataViewSelectParams
      `<Pills {...this.props.dataViewSelectParams} />`

  renderTitle: ->
    if this.props.title
      `<h5>{this.props.title}</h5>`

  render: ->
    `<Card className={this.classes()}>
      <Row className='comparisonDataContainer-header' styleName='no-margin'>
        <Column size={4} styleName='no-padding'>
          {this.renderTitle()}
        </Column>
        <Column size={8} className='comparisonDataContainer-selections' styleName='no-padding'>
          {this.renderMetricSelect()}
          {this.renderDataViewSelect()}
        </Column>
      </Row>
      {this.props.children}
      {this.renderSeeMoreRow()}
    </Card>`

export default ComparisonDataContainer
