import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
} from '@makeably/creativex-design-system';
import { findObjectByValue } from 'utilities/array';
import { adminCompanyBrandsPath } from 'utilities/routes';

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const propTypes = {
  companyId: PropTypes.number.isRequired,
  categoryId: PropTypes.number,
  categoryOptions: PropTypes.arrayOf(optionProps),
};

const defaultProps = {
  categoryId: undefined,
  categoryOptions: [],
};

function BrandFilters({
  categoryId,
  categoryOptions,
  companyId,
}) {
  const handleChange = async (category) => {
    const params = {
      category_id: category?.value,
    };

    window.location.href = adminCompanyBrandsPath(companyId, params);
  };

  const onClear = async () => {
    window.location.href = adminCompanyBrandsPath(companyId);
  };

  return (
    <div className="u-flexRow u-gap-16 u-alignEnd">
      <Dropdown
        label="Category"
        menuProps={{ size: 'large' }}
        options={categoryOptions}
        selected={findObjectByValue(categoryOptions, categoryId)}
        size="large"
        onChange={(value) => handleChange(value)}
      />
      { categoryId && (
        <Button
          label="Clear"
          variant="tertiary"
          onClick={onClear}
        />
      ) }
    </div>
  );
}

BrandFilters.propTypes = propTypes;
BrandFilters.defaultProps = defaultProps;

export default BrandFilters;
