import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentTitle,
  FormGroupContainer,
  Radio,
} from '@duik/it';
import {
  HiddenInput,
  HiddenInputs,
} from 'components/reusable/HiddenInput';
import SearchableSelect from 'components/reusable/SearchableSelect';
import ArrayHelper from 'components/utils/ArrayHelper';

const optionProps = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
);

const optionPair = PropTypes.shape({
  options: optionProps,
  selectedOptions: optionProps,
});

const propTypes = {
  brands: optionPair.isRequired,
  channels: optionPair.isRequired,
  companies: optionPair.isRequired,
  lapsedType: PropTypes.string.isRequired,
  markets: optionPair.isRequired,
};

function LapsedNotificationFilters(props) {
  const {
    activeOptions: {
      brands: activeBrands,
      channels: activeChannels,
      companies: activeCompanies,
      markets: activeMarkets,
    },
    filterOptions: {
      brands: filterBrands,
      channels: filterChannels,
      companies: filterCompanies,
      markets: filterMarkets,
    },
    onOptionClick,
    visible,
  } = props;

  if (!visible) return null;

  const filterSelectData = [
    {
      label: 'Brand',
      activeOption: activeBrands,
      filterOption: filterBrands,
      value: 'brand',
    },
    {
      label: 'Channel',
      activeOption: activeChannels,
      filterOption: filterChannels,
      value: 'channel',
    },
    {
      label: 'Market',
      activeOption: activeMarkets,
      filterOption: filterMarkets,
      value: 'market',
    },
    {
      label: 'Partner',
      activeOption: activeCompanies,
      filterOption: filterCompanies,
      value: 'partner',
    },
  ];

  return (
    <FormGroupContainer className="u-marginLeft">
      {
        filterSelectData.map(({
          label, activeOption, filterOption, value,
        }) => {
          const filterInput = `lapsed_notification[filters][${value}][]`;
          return (
            <div key={value}>
              <SearchableSelect
                key={label}
                activeOption={activeOption}
                label={label}
                name={`selected${label}s`}
                options={filterOption}
                placeholder={`Choose one or more ${value}...`}
                testId={`${value}Select`}
                multiple
                onOptionClick={onOptionClick}
              />
              <HiddenInputs name={filterInput} values={ArrayHelper.values(activeOption)} />
            </div>
          );
        })
      }
    </FormGroupContainer>
  );
}
LapsedNotificationFilters.propTypes = {
  activeOptions: PropTypes.shape({
    brands: optionProps,
    channels: optionProps,
    companies: optionProps,
    markets: optionProps,
  }).isRequired,
  filterOptions: PropTypes.shape({
    brands: optionProps,
    channels: optionProps,
    companies: optionProps,
    markets: optionProps,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
  onOptionClick: PropTypes.func.isRequired,
};

function LapsedNotificationRadio(props) {
  const {
    lapsedType,
    radioOnChange,
  } = props;

  const radioSelectData = [
    {
      label: "Accounts I've linked",
      value: 'linked',
    },
    {
      label: 'All Accounts',
      value: 'all',
    },
    {
      label: 'Filtered Accounts',
      value: 'filtered',
    },
  ];

  return (
    <FormGroupContainer className="u-marginLeft">
      {
        radioSelectData.map(({ label, value }) => {
          const checked = lapsedType === value;
          return (
            <div key={value}>
              <Radio
                checked={checked}
                label={label}
                value={value}
                onChange={radioOnChange}
              />
              { checked && <HiddenInput name="lapsed_notification[lapsed_type]" value={value} /> }
            </div>
          );
        })
      }
    </FormGroupContainer>
  );
}
LapsedNotificationRadio.propTypes = {
  lapsedType: PropTypes.string.isRequired,
  radioOnChange: PropTypes.func.isRequired,
};

class LapsedNotifications extends React.Component {
  constructor(props) {
    super(props);

    const {
      lapsedType,
      brands,
      channels,
      companies,
      markets,
    } = props;

    this.state = {
      lapsedType,
      selectedBrands: brands.selectedOptions,
      selectedChannels: channels.selectedOptions,
      selectedPartners: companies.selectedOptions,
      selectedMarkets: markets.selectedOptions,
    };
  }

  onLapsedNotificationChange(lapsedType) {
    this.setState({ lapsedType });
  }

  onFilterOptionChange(valueObj, name) {
    this.setState((prevState) => {
      let values = prevState[name];
      values = ArrayHelper.withOrWithoutObject(values, valueObj);

      return ({ [name]: values });
    });
  }

  render() {
    const {
      brands,
      channels,
      companies,
      markets,
    } = this.props;

    const {
      lapsedType,
      selectedBrands,
      selectedChannels,
      selectedPartners,
      selectedMarkets,
    } = this.state;

    const activeOptions = {
      brands: selectedBrands,
      channels: selectedChannels,
      companies: selectedPartners,
      markets: selectedMarkets,
    };

    const filterOptions = {
      brands: brands.options,
      channels: channels.options,
      companies: companies.options,
      markets: markets.options,
    };

    return (
      <FormGroupContainer>
        <ContentTitle>Lapsed Account Notifications</ContentTitle>
        <LapsedNotificationRadio
          lapsedType={lapsedType}
          radioOnChange={(event) => this.onLapsedNotificationChange(event.target.value)}
        />
        <LapsedNotificationFilters
          activeOptions={activeOptions}
          filterOptions={filterOptions}
          visible={lapsedType === 'filtered'}
          onOptionClick={(obj, name) => this.onFilterOptionChange(obj, name)}
        />
      </FormGroupContainer>
    );
  }
}

LapsedNotifications.propTypes = propTypes;

export default LapsedNotifications;
