import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Search } from '@makeably/creativex-design-system';
import ItemsTable from 'components/molecules/ItemsTable';
import { setItemElement } from 'utilities/itemElement';
import { searchItems as searchItemsFunc } from 'utilities/itemSearch';
import styles from './ChangeLog.module.css';

const PER_PAGE_LIMIT = 20;

export const logProps = {
  date: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  isCxAdmin: PropTypes.bool,
  logStatus: PropTypes.string,
  title: PropTypes.string,
  userName: PropTypes.string,
};

const propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape(logProps),
  ).isRequired,
};

const headers = [
  {
    key: 'details',
    label: 'Details',
    sortable: false,
  },
  {
    key: 'status',
    label: 'Status',
    sortable: false,
  },
  {
    key: 'date',
    label: 'Date',
    sortable: false,
  },
  {
    key: 'user',
    label: 'User',
    sortable: false,
  },
];
const headerKeys = headers.map(({ key }) => key);

function formatItems(logs) {
  return logs.map(({
    date,
    description,
    id,
    isCxAdmin,
    logStatus,
    title,
    userName,
  }) => {
    const details = description ? `${title}: ${description}` : title;

    return {
      date: { value: date },
      details: {
        element: <div>{ details }</div>,
        value: details,
      },
      id: { value: id },
      status: { value: logStatus === 'complete' ? 'Complete' : 'Calculating...' },
      user: { value: isCxAdmin ? 'CreativeX Admin' : userName },
    };
  });
}

function ChangeLog({ logs }) {
  const logItems = formatItems(logs);

  const [filteredItems, setFilteredItems] = useState(logItems);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  useEffect(() => setFilteredItems(searchItemsFunc(logItems, search, headerKeys)), [search]);

  const emptyState = (
    <div className="u-flexColumn u-alignCenter t-empty">
      { search === '' ? 'No changes have been made to this score' : 'No changes found' }
    </div>
  );

  return (
    <div className="u-flexColumn u-alignCenter u-gap-16">
      <div className={styles.header}>
        <Search
          size="large"
          value={search}
          onChange={setSearch}
        />
      </div>
      <div className={styles.table}>
        <ItemsTable
          emptyTableContent={emptyState}
          headers={headers}
          items={filteredItems.map(setItemElement)}
          maxWidthSize="custom"
          page={page}
          perPage={PER_PAGE_LIMIT}
          onPageChange={(newPage) => setPage(newPage)}
        />
      </div>
    </div>
  );
}

ChangeLog.propTypes = propTypes;

export default ChangeLog;
