import React, {
  useState,
  useEffect,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Divider } from '@duik/it';
import EmptyDataView from 'components/account_setup/EmptyDataView';
import Instructions from 'components/account_setup/Instructions';
import Confirmation from 'components/account_setup/shared_steps/AdformConfirmation';
import Association from 'components/account_setup/shared_steps/Association';
import TableSelection from 'components/account_setup/shared_steps/TableSelection';
import Stepper, { propTypes as stepperProps } from 'components/molecules/Stepper';
import FormSubmit from 'components/reusable/FormSubmit';
import { track } from 'utilities/mixpanel';
import {
  accountSetupAdformAdPath,
  accountSetupAdformAdsPath,
  processStepAccountSetupAdformAdPath,
  newSettingsUserPath,
} from 'utilities/routes';
import {
  pluralize,
  titleize,
} from 'utilities/string';

const propTypes = {
  ...stepperProps,
  // Content data shape depends on the content component being rendered, see
  // prop definition of specific component in "steps" dir for details
  // eslint-disable-next-line react/forbid-prop-types
  contentData: PropTypes.object.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  // Only supported for Advertisers. Defaults to true
  hasData: PropTypes.bool,
  nextStep: PropTypes.string,
  previousStep: PropTypes.string,
};

const defaultProps = {
  hasData: true,
  nextStep: undefined,
  previousStep: undefined,
};

const channel = 'adform';
const formId = 'adformAccountLinkForm';
const itemName = 'advertiser';

const viewDetails = {
  advertisers: {
    component: TableSelection,
    instructions: 'Select the Adform Advertisers you want to enable in CreativeX.',
  },
  associateAdvertisers: {
    component: Association,
    instructions: 'Associate each Advertiser with its correct brand, market, and partner.',
  },
  confirmation: {
    component: Confirmation, // Repeated everywhere
    instructions: 'Confirm each Advertiser is associated with the correct brand, market, and partner.',
    isConfirmation: true,
  },
};

function renderBackUrl(previousStep) {
  if (!previousStep) return null;

  const onBackClicked = () => track('Click Back');

  return (
    <a
      className="u-marginRight"
      href={accountSetupAdformAdPath(previousStep)}
      onClick={onBackClicked}
    >
      Back
    </a>
  );
}

function generateMissingDataInstructions() {
  const preLink = `This account does not have access to any ${pluralize(titleize(itemName))}. `
    + `Please try again using a different ${titleize(channel)} account, or `;

  const postLink = ' a user to CreativeX with the required access to complete your connections.';

  return (
    <>
      { preLink }
      <a href={newSettingsUserPath()}>invite</a>
      { postLink }
    </>
  );
}

function AdformAccountLinkingPage({
  contentData,
  currentStep,
  hasData,
  isLastStep,
  nextStep,
  previousStep,
  steps,
  view,
}) {
  useEffect(() => {
    if (currentStep === 2) {
      track('complete_oauth');
    }
  }, []);

  const {
    component: ContentComponent,
    instructions,
    isConfirmation,
  } = viewDetails[view];

  const [formComplete, setFormComplete] = useState(isConfirmation);
  const [mixpanelData, setMixpanelData] = useState(null);

  const contentClasses = classNames(
    'content',
    'u-marginAboveLg',
    { 'content-confirmation': isConfirmation },
  );

  const formData = {
    id: formId,
    url: isLastStep ? accountSetupAdformAdsPath() : processStepAccountSetupAdformAdPath(nextStep),
  };

  const onFormSubmit = () => {
    if (mixpanelData) {
      track(view, {
        platform: channel,
        ...mixpanelData,
      });
    }
  };

  return (
    <div className="accountSetup u-flexColumn u-flexAlignCenter">
      <Stepper currentStep={currentStep} steps={steps} />
      <div className={contentClasses}>
        { hasData
            && (
              <>
                <Instructions content={instructions} view={view} />
                <Divider margin />
                <ContentComponent
                  addMixpanelData={(data) => setMixpanelData(data)}
                  formData={formData}
                  inputName="advertisers"
                  itemName={itemName}
                  keyFields={['value']}
                  onFormAction={(complete) => setFormComplete(complete)}
                  {...contentData} // eslint-disable-line react/jsx-props-no-spreading
                />
                <div className="actions u-flexRow u-justifyFlexEnd u-flexAlignCenter">
                  { renderBackUrl(previousStep) }
                  <FormSubmit
                    disabled={!formComplete}
                    form={formId}
                    label={isConfirmation ? 'Confirm' : 'Continue'}
                    primary
                    onSubmit={onFormSubmit}
                  />
                </div>
              </>
            ) }
        { !hasData
            && (
              <EmptyDataView
                content={generateMissingDataInstructions()}
                emptyMessage={`No available ${pluralize(titleize(itemName))}`}
              />
            ) }
      </div>
    </div>
  );
}

AdformAccountLinkingPage.propTypes = propTypes;
AdformAccountLinkingPage.defaultProps = defaultProps;

export default AdformAccountLinkingPage;
