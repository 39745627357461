// Helper functions for async requests
import { logError } from 'utilities/logging';

export const FETCH_FAILED_STATUS = 500;

export function addFormDataObject(formData, name, obj) {
  Object.keys(obj).forEach((key) => {
    formData.append(`${name}[${key}]`, obj[key]);
  });
}

export function addFormDataArray(formData, name, arr) {
  arr.forEach((value) => {
    if (typeof value === 'object') {
      addFormDataObject(formData, `${name}[]`, value);
    } else {
      formData.append(`${name}[]`, value);
    }
  });
}

export function getAuthenticityToken() {
  const tokenEl = document.querySelector('meta[name="csrf-token"]');
  if (tokenEl) return tokenEl.content;

  logError('Cannot find csrf-token');
  return undefined;
}

function isFormData(obj) {
  return obj instanceof FormData;
}

function formatHeaders(body) {
  const headers = {
    accept: 'application/json',
    'X-CSRF-Token': getAuthenticityToken(),
  };

  if (isFormData(body)) {
    return headers;
  }

  return {
    ...headers,
    'content-type': 'application/json',
  };
}

function formatBody(body) {
  // NOTE: If body is a FormData object or already a json string, don't call stringify
  if (isFormData(body) || typeof body === 'string') {
    return body;
  }

  return JSON.stringify(body);
}

async function request(url, options) {
  try {
    const response = await fetch(url, options);

    if (response.ok) {
      return {
        data: await response.json(),
        isError: false,
        status: response.status,
      };
    }

    return {
      isError: true,
      status: response.status,
    };
  } catch (error) {
    return {
      errorMessage: error.message,
      isError: true,
      status: FETCH_FAILED_STATUS,
    };
  }
}

// API version (consuming REST endpoints using opinionated Api::BaseController)
async function apiRequest(url, options) {
  try {
    const response = await fetch(url, options);
    const isJson = response.headers.get('Content-Type')?.indexOf('application/json') !== -1;
    const json = isJson ? await response.json() : {};

    return {
      ...json,
      ok: response.ok,
      status: response.status,
    };
  } catch (error) {
    return {
      ok: false,
      status: FETCH_FAILED_STATUS,
      errors: ['Unknown error during fetch!'],
    };
  }
}

export async function get(url) {
  return request(url, { method: 'GET' });
}

export async function post(url, body) {
  return request(url, {
    headers: formatHeaders(body),
    method: 'POST',
    body: formatBody(body),
  });
}

export async function patch(url, body) {
  return request(url, {
    headers: formatHeaders(body),
    method: 'PATCH',
    body: formatBody(body),
  });
}

export async function destroy(url) {
  return request(url, {
    headers: formatHeaders(),
    method: 'DELETE',
  });
}

// API versions (consuming REST endpoints using opinionated Api::BaseController)

export async function apiGet(url) {
  return apiRequest(url, { method: 'GET' });
}

export async function apiPost(url, body) {
  return apiRequest(url, {
    headers: formatHeaders(body),
    method: 'POST',
    body: formatBody(body),
  });
}

export async function apiPatch(url, body) {
  return apiRequest(url, {
    headers: formatHeaders(body),
    method: 'PATCH',
    body: formatBody(body),
  });
}

export async function apiDestroy(url) {
  return apiRequest(url, {
    headers: formatHeaders(),
    method: 'DELETE',
  });
}
