import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  RadioGroup,
  TextInput,
} from '@makeably/creativex-design-system';
import DuikSelectDate from 'components/molecules/DuikSelectDate';
import { addToast } from 'components/organisms/Toasts';
import StringHelper from 'components/utils/StringHelper';
import {
  patch,
  post,
} from 'utilities/requests';
import {
  adminCompanyContractPath,
  adminCompanyContractsPath,
} from 'utilities/routes';
import { dateToString } from 'utilities/string';
import styles from './ContractForm.module.css';

const propTypes = {
  companyId: PropTypes.number.isRequired,
  contract: PropTypes.shape({
    dataPointsCap: PropTypes.number,
    endDate: PropTypes.string,
    id: PropTypes.number,
    mediaSpendCap: PropTypes.number,
    startDate: PropTypes.string,
  }),
};

const defaultProps = {
  contract: undefined,
};

const DATA_POINTS_OPTION = 'dataPoints';
const MEDIA_SPEND_OPTION = 'mediaSpend';

const radioOptions = [
  {
    label: 'Data Points',
    value: DATA_POINTS_OPTION,
  },
  {
    label: 'Media Spend',
    value: MEDIA_SPEND_OPTION,
  },
];

function contractFormData({
  type,
  dataPointsCap,
  mediaSpendCap,
  endDate,
  startDate,
}) {
  const formData = new FormData();
  formData.append('start_date', dateToString(startDate));
  formData.append('end_date', dateToString(endDate));

  if (type === DATA_POINTS_OPTION) {
    formData.append('data_points_cap', dataPointsCap);
  } else {
    formData.append('media_spend_cap', mediaSpendCap);
  }

  return formData;
}

async function updateContract(companyId, { id, ...rest }) {
  const url = adminCompanyContractPath(companyId, id);

  const response = await patch(url, contractFormData(rest));
  return response.data;
}

async function createContract(companyId, contract) {
  const url = adminCompanyContractsPath(companyId);

  const response = await post(url, contractFormData(contract));
  return response.data;
}

// TODO: replace DuikSelectDate with DS DatePicker when that is ready
function ContractForm({
  companyId,
  contract,
}) {
  // TODO: deprecate StringHelper.stringToDate when using new DatePicker
  const [startDate, setStartDate] = useState(StringHelper.stringToDate(contract?.startDate));
  const [endDate, setEndDate] = useState(StringHelper.stringToDate(contract?.endDate));
  const initialType = contract?.dataPointsCap ? DATA_POINTS_OPTION : MEDIA_SPEND_OPTION;
  const [type, setType] = useState(contract ? initialType : undefined);
  const [dataPointsCap, setDataPointsCap] = useState(contract?.dataPointsCap);
  const [mediaSpendCap, setMediaSpendCap] = useState(contract?.mediaSpendCap);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitDisabled = !startDate
    || !endDate
    || (type === DATA_POINTS_OPTION && !dataPointsCap)
    || (type === MEDIA_SPEND_OPTION && !mediaSpendCap);

  const handleSave = async () => {
    setIsSubmitting(true);
    let data;

    const requestData = {
      dataPointsCap,
      endDate,
      id: contract?.id,
      mediaSpendCap,
      startDate,
      type,
    };

    if (requestData.id) {
      data = await updateContract(companyId, requestData);
      if (data.success) {
        addToast('Contract updated.');
      }
    } else {
      data = await createContract(companyId, requestData);
      if (data.success) {
        addToast('Contract has successfully been added.');
      }
    }

    window.location.href = adminCompanyContractsPath(companyId);
  };

  return (
    <Card className={styles.container}>
      <div className="u-flexColumn u-gap-16">
        <h5>Configure Contract</h5>
        <Divider />
        <div className="u-flexRow u-gap-16">
          <div>
            <DuikSelectDate
              label="Contract Start Date"
              value={startDate}
              onDateChange={(date) => setStartDate(date)}
            />
          </div>
          <div>
            <DuikSelectDate
              label="Contract End Date"
              value={endDate}
              onDateChange={(date) => setEndDate(date)}
            />
          </div>
        </div>
        <RadioGroup
          name="typeSelectionRadioGroup"
          options={radioOptions}
          value={type}
          horizontal
          onChange={(e) => setType(e.target.value)}
        />
        { type === DATA_POINTS_OPTION && (
          <TextInput
            label="Data Points Cap"
            placeholder="Empty"
            size="medium"
            value={dataPointsCap}
            onChange={(value) => setDataPointsCap(value)}
          />
        ) }
        { type === MEDIA_SPEND_OPTION && (
          <TextInput
            label="Media Spend Cap ($)"
            placeholder="Empty"
            size="medium"
            value={mediaSpendCap}
            onChange={(value) => setMediaSpendCap(value)}
          />
        ) }
        <div className="u-flexRow u-justifyEnd">
          <Button
            disabled={submitDisabled || isSubmitting}
            label={`${contract ? 'Edit' : 'Add'} Contract`}
            onClick={handleSave}
          />
        </div>
      </div>
    </Card>
  );
}

ContractForm.propTypes = propTypes;
ContractForm.defaultProps = defaultProps;

export default ContractForm;
