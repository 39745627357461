// Table of checks that displays an evaluation page in a modal
// when clicked. Modal updates to next check on evaluation.
import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Modal,
} from '@makeably/creativex-design-system';
import GuidelineCheckDisplay from 'components/admin/check_evaluation/GuidelineCheckDisplay';
import FilterDropdowns from 'components/filters/FilterDropdowns';
import ItemsTable from 'components/molecules/ItemsTable';
import { getNextIndex } from 'utilities/array';
import {
  getFilters,
  getFilterSelections,
} from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import { getAuthenticityToken } from 'utilities/requests';
import {
  changePage,
  getPage,
  getParams,
  setParam,
} from 'utilities/url';
import styles from './CheckQueue.module.css';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  checks: PropTypes.arrayOf(PropTypes.object).isRequired,
  editUrl: PropTypes.func.isRequired,
  filterItems: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  formatForModal: PropTypes.func.isRequired,
  getItemElements: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  updateUrl: PropTypes.func.isRequired,
  checkType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  filterDisplays: PropTypes.object,
  headerRight: PropTypes.node,
  onItemsSorted: PropTypes.func,
};

const defaultProps = {
  checkType: 'guideline',
  filterDisplays: {},
  headerRight: undefined,
  onItemsSorted: () => {},
};

function CheckQueue({
  checkType,
  checks,
  editUrl,
  filterItems,
  filterOptions,
  filterDisplays,
  formatForModal,
  getItemElements,
  headerRight,
  headers,
  onItemsSorted,
  updateUrl,
}) {
  const params = getParams(window);
  const filterKeys = filterOptions.map(({ key }) => key);

  const [page, setPage] = useState(getPage(params));
  const [tableSort, setTableSort] = useState({
    key: 'id',
    asc: true,
  });

  const [currentCheck, setCurrentCheck] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const openCheck = (check) => {
    setCurrentCheck(check);
    setDialogOpen(true);
  };

  const [currentItems, setCurrentItems] = useState(getItemElements(checks, openCheck));
  const [filteredItems, setFilteredItems] = useState([]);
  const [sortedItems, setSortedItems] = useState([]);

  const [filters, setFilters] = useState(getFilters(currentItems, filterOptions, filterDisplays));
  const [filterSelections, setFilterSelections] = useState(getFilterSelections(filterKeys, params));

  const [errorMessage, setErrorMessage] = useState(null);
  const [evaluating, setEvaluating] = useState(false);
  const [passed, setPassed] = useState(null);
  const [isGuess, setIsGuess] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setFilters(getFilters(currentItems, filterOptions, filterDisplays));
  }, [currentItems]);

  useEffect(() => {
    const filtered = filterItems(currentItems, filterSelections);
    setFilteredItems(filtered);
  }, [currentItems, filterSelections]);

  useEffect(() => {
    const sortByKeyDir = getItemSortBy(tableSort.key, tableSort.asc);
    setSortedItems(filteredItems.sort(sortByKeyDir));
  }, [filteredItems, tableSort]);

  useEffect(() => {
    onItemsSorted(sortedItems);
  }, [sortedItems]);

  useEffect(() => {
    if (currentCheck) {
      setPassed(currentCheck.passed);
      setIsGuess(currentCheck.cv && (typeof currentCheck.passed === 'boolean'));
      setLoading(true);
    }
  }, [currentCheck]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    changePage(newPage, params, window);
  };

  const handleFilterChange = (key, value) => {
    setParam(key, value, params, window);

    handlePageChange(1);
    setFilterSelections((selections) => ({
      ...selections,
      [key]: value,
    }));
  };

  const handleSortChange = (sort) => {
    setPage(1);
    setTableSort(sort);
  };

  const handleCheckSaved = () => {
    const index = sortedItems.findIndex((item) => item.id.value === currentCheck.id);
    const nextIndex = getNextIndex(index, sortedItems.length);

    setCurrentItems(currentItems.filter((item) => item.id.value !== currentCheck.id));

    if (nextIndex >= 0) {
      const nextCheck = sortedItems[nextIndex];
      const openNextCheck = nextCheck.id.display.button;

      openNextCheck();
    } else {
      setDialogOpen(false);
      setCurrentCheck(null);
    }
  };

  const handleEvaluate = async () => {
    const formToken = getAuthenticityToken();

    const formData = new FormData();
    formData.append('authenticity_token', formToken);
    formData.append('passed', passed);

    try {
      setEvaluating(true);
      setErrorMessage(null);
      const response = await fetch(updateUrl(currentCheck.id), {
        method: 'PATCH',
        body: formData,
      });

      if (response.ok) {
        const success = await response.json();

        if (success) {
          handleCheckSaved();
        } else {
          setErrorMessage(`Could not save ${checkType} check`);
        }
      } else {
        setErrorMessage(`Error saving ${checkType} check: ${response.statusText}`);
      }
    } catch (error) {
      setErrorMessage(`Error saving ${checkType} check: ${error}`);
    } finally {
      setEvaluating(false);
    }
  };

  return (
    <>
      <div className={styles.card}>
        <Card>
          <div className={styles.header}>
            <FilterDropdowns
              filters={filters}
              selections={filterSelections}
              onChange={handleFilterChange}
            />
            { headerRight }
          </div>
          <div className={styles.table}>
            <ItemsTable
              headers={headers}
              items={filteredItems}
              page={page}
              sort={tableSort}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
            />
          </div>
        </Card>
      </div>
      { currentCheck && (
        <Modal
          actionButtonDisabled={loading || evaluating || isGuess || passed === null}
          isOpen={dialogOpen}
          scrollableContent
          onActionButtonClick={handleEvaluate}
          onClose={() => setDialogOpen(false)}
        >
          <GuidelineCheckDisplay
            check={formatForModal(currentCheck)}
            editUrl={editUrl(currentCheck.id)}
            errorMessage={errorMessage}
            evaluating={evaluating}
            isGuess={isGuess}
            passed={passed}
            setIsGuess={(value) => setIsGuess(value)}
            setLoading={(value) => setLoading(value)}
            setPassed={(value) => setPassed(value)}
          />
        </Modal>
      ) }
    </>
  );
}

CheckQueue.propTypes = propTypes;
CheckQueue.defaultProps = defaultProps;

export default CheckQueue;
