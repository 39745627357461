import React from 'react';
import PropTypes from 'prop-types';
import { renderOcrTag } from 'components/admin/review_queue/post_info/OcrTags';
import CollapsibleWidget from 'components/molecules/CollapsibleWidget';

export const propTypes = {
  thumbnail: PropTypes.shape({
    ocrTags: PropTypes.arrayOf(
      PropTypes.shape({
        startTime: PropTypes.number,
        text: PropTypes.string,
      }),
    ),
    url: PropTypes.string,
  }),
};

export const defaultProps = {
  thumbnail: undefined,
};

const THUMBNAIL_WIDTH = 200;

function ThumbnailInfo({ thumbnail }) {
  if (!thumbnail) return null;

  const {
    ocrTags,
    url,
  } = thumbnail;

  let thumbnailTags;
  if (ocrTags && ocrTags.length > 0) {
    thumbnailTags = (
      <CollapsibleWidget headerText="Thumbnail OCR">
        { ocrTags.map((tag, idx) => renderOcrTag(tag, idx)) }
      </CollapsibleWidget>
    );
  }

  return (
    <div>
      <CollapsibleWidget defaultCollapsed={false} headerText="Thumbnail Image">
        <img alt="Thumbnail" src={url} width={THUMBNAIL_WIDTH} />
      </CollapsibleWidget>
      { thumbnailTags }
    </div>
  );
}

ThumbnailInfo.propTypes = propTypes;
ThumbnailInfo.defaultProps = defaultProps;

export default ThumbnailInfo;
