import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  MessageModal,
  Tooltip,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import { bmeRemappingSummarySettingsLinkedPlatformAccountPath } from 'utilities/routes';
import styles from './BmeUtilityModal.module.css';

const propTypes = {
  accountClass: PropTypes.string.isRequired,
  accountId: PropTypes.number.isRequired,
  canPreserveBme: PropTypes.bool.isRequired,
  currentBrand: PropTypes.string.isRequired,
  currentMarket: PropTypes.string.isRequired,
  newBrand: PropTypes.string.isRequired,
  newMarket: PropTypes.string.isRequired,
  platformAccountId: PropTypes.string.isRequired,
  preserveBme: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
};

const headers = {
  1: 'Please confirm the proposed remapping',
  2: 'Please confirm the required guideline changes and reprocessing',
  3: 'Summary',
};

function renderHeader(step) {
  return (
    <div className="t-body-1 u-marginBottom-16">
      { headers[step] }
    </div>
  );
}

function renderChange(type, currentParam, newParam) {
  return (
    <>
      <div className="t-subtitle">
        { `${type} Change:` }
      </div>
      <div className={classNames(styles.changeRow, 't-body-1', 'u-marginBottom-16')}>
        { currentParam }
        <Icon color="darkPurple" name="arrowRight" />
        { newParam }
      </div>
    </>
  );
}

function renderDetails(startDate, assetCount, postCount) {
  return (
    <>
      <div className="u-flexRow">
        <span className="t-subtitle u-marginRightSm">Start Date:</span>
        <Tooltip label="The earliest date assets have been captured from this client" />
      </div>
      <div className="t-body-1 u-marginBottom-16">
        { startDate === null ? 'N/A' : startDate }
      </div>
      <div className="t-subtitle">
        Asset Count:
      </div>
      <div className="t-body-1 u-marginBottom-16">
        { assetCount }
      </div>
      <div className="t-subtitle">
        Post Count:
      </div>
      <div className="t-body-1 u-marginBottom-16">
        { postCount }
      </div>
    </>
  );
}

function renderEmptyGuidelines() {
  return (
    <div className="t-body-1">
      N/A
    </div>
  );
}

function renderGuideline(guideline) {
  return (
    <div key={guideline} className="t-body-1">
      { guideline }
    </div>
  );
}

function renderGuidelines(reprocessedGuidelines, addedGuidelines, removedGuidelines) {
  return (
    <>
      <div className="u-marginBottom-16">
        <div className="t-subtitle">
          Guidelines to reprocess:
        </div>
        { reprocessedGuidelines.length === 0 && renderEmptyGuidelines() }
        { reprocessedGuidelines.map((guideline) => renderGuideline(guideline)) }
      </div>
      <div className="u-marginBottom-16">
        <div className="t-subtitle">
          Guidelines to be added and processed:
        </div>
        { addedGuidelines.length === 0 && renderEmptyGuidelines() }
        { addedGuidelines.map((guideline) => renderGuideline(guideline)) }
      </div>
      <div className="u-marginBottom-16">
        <div className="t-subtitle">
          Guidelines to be removed:
        </div>
        { removedGuidelines.length === 0 && renderEmptyGuidelines() }
        { removedGuidelines.map((guideline) => renderGuideline(guideline)) }
      </div>
    </>
  );
}

function BmeUtilityModal({
  accountClass,
  accountId,
  canPreserveBme,
  currentBrand,
  currentMarket,
  newBrand,
  newMarket,
  platformAccountId,
  preserveBme,
  onModalClose,
  onSubmitForm,
  visible,
}) {
  const [step, setStep] = useState(1);
  const [reprocessedGuidelines, setReprocessedGuidelines] = useState([]);
  const [addedGuidelines, setAddedGuidelines] = useState([]);
  const [removedGuidelines, setRemovedGuidelines] = useState([]);
  const [assetCount, setAssetCount] = useState(0);
  const [postCount, setPostCount] = useState(0);
  const [startDate, setStartDate] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const showBrand = currentBrand !== newBrand;
  const showMarket = currentMarket !== newMarket;

  const getRemappingSummary = async () => {
    setLoading(true);

    const params = {
      account_class: accountClass,
      new_brand: newBrand,
      new_market: newMarket,
      preserve_bme: preserveBme,
    };

    const url = bmeRemappingSummarySettingsLinkedPlatformAccountPath(accountId, params);
    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();
      if (!data.error) {
        setReprocessedGuidelines(data.reprocessedGuidelines);
        setAddedGuidelines(data.addedGuidelines);
        setRemovedGuidelines(data.removedGuidelines);
        setAssetCount(data.assetCount);
        setPostCount(data.postCount);
        setStartDate(data.startDate);
        // In step 2, the user reviews any guidelines that will be reprocessed. If we are preserving
        //  the historical BME, we will not reprocess any guidelines, so no review is necessary.
        setStep(preserveBme ? 3 : 2);
      } else {
        addToast(data.error, {
          duration: 10000,
          type: 'error',
        });

        setStep(1);
        onModalClose();
      }
    } else {
      addToast('Something went wrong. Please try again.', {
        duration: 10000,
        type: 'error',
      });

      setStep(1);
      onModalClose();
    }
    setLoading(false);
  };

  const preserveBmeMessage = `${preserveBme ? 'Preserving' : 'Remapping'} Historical BME`;

  const handleActionClick = () => {
    switch (step) {
      case 1:
        getRemappingSummary();
        break;
      case 2:
        setStep(3);
        break;
      case 3:
        onSubmitForm();
        break;
      default:
        setStep(1);
    }
  };

  const handleClose = () => {
    switch (step) {
      case 1:
        onModalClose();
        break;
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
      default:
        setStep(1);
        onModalClose();
    }
  };

  return (
    <MessageModal
      actionButtonDisabled={loading}
      actionButtonLabel={step === 3 ? 'Remap' : 'Confirm'}
      actionButtonVariant={step === 3 ? 'destructive' : 'primary'}
      closeButtonLabel={step === 1 ? 'Cancel' : 'Back'}
      isOpen={visible}
      subtitle={`Associate Ad Account ID ${platformAccountId} with new Brand/Market`}
      title={`Brand/Market Remapping (${step}/3)`}
      onActionButtonClick={handleActionClick}
      onClose={handleClose}
    >
      { renderHeader(step) }
      { (showMarket && step !== 2) && renderChange('Market', currentMarket, newMarket) }
      { (showBrand && step !== 2) && renderChange('Brand', currentBrand, newBrand) }
      { canPreserveBme && step !== 2 && <div>{ preserveBmeMessage }</div> }
      { step === 3 && renderDetails(startDate, assetCount, postCount) }
      { step !== 1 && renderGuidelines(reprocessedGuidelines, addedGuidelines, removedGuidelines) }
    </MessageModal>
  );
}

BmeUtilityModal.propTypes = propTypes;

export default BmeUtilityModal;
