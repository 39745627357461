import React from 'react';
import { Icon } from '@makeably/creativex-design-system';
import styles from './PreflightBanner.module.css';

function PreflightBanner() {
  return (
    <div className={styles.container}>
      <Icon color="darkPurple" name="infoCircle" noWrapper />
      <div>
        Currently Viewing Pre-Flight
      </div>
    </div>
  );
}

export default PreflightBanner;
