import React from "react"
import PropTypes from "prop-types"
import MixpanelTracker from "components/utils/MixpanelTracker"
import { CustomFiltersForm } from "components/containers/miscellaneous_containers"
import ModalContainer from "components/higher_order_components/modal_container"
import { AddNewButton } from '@makeably/creativex-design-system'

ModalLaunchButton = ModalContainer(AddNewButton)

class CustomFiltersModal extends React.Component
  @propTypes =
    form: PropTypes.object
    label: PropTypes.string
    modalId: PropTypes.string

  trackModalLaunch: =>
    MixpanelTracker.trackButtonClick(@props.label)

  renderCustomFiltersForm: ->
    `<CustomFiltersForm {...this.props.form}/>`

  render: ->
    modalProps = {
      modal: {
        children: this.renderCustomFiltersForm()
        className: 'customFiltersForm'
        id: @props.modalId
        onOpen: this.trackModalLaunch
      },
      contentProps: {
        'data-target': @props.modalId,
        label: @props.label,
        className: 'btn'
      },
      launchType: 'addNew'
    }
    `<ModalLaunchButton {...modalProps} />`

export default CustomFiltersModal
