import MixpanelHelper from 'components/utils/MixpanelHelper';

class MixpanelTracker {
  static trackButtonClick(buttonName) {
    const eventName = `User Clicks ${buttonName}`;
    MixpanelHelper.trackEvent(eventName);
  }

  static trackFilter(filterName, properties) {
    const eventName = `User Clicks ${filterName}`;
    MixpanelHelper.trackEvent(eventName, properties);
  }

  static trackFilterSelection(filterName, selectedValue) {
    const capitalizedFilterName = filterName.charAt(0).toUpperCase() + filterName.slice(1);
    const eventName = `User Clicks ${capitalizedFilterName}`;
    MixpanelHelper.trackEvent(eventName, { selectedValue });
  }

  static trackTabClick(tabName, selectedValue) {
    const eventName = `User Clicks ${tabName} Tab`;
    MixpanelHelper.trackEvent(eventName, { selectedValue });
  }

  static trackEvent(eventName) {
    MixpanelHelper.trackEvent(eventName);
  }
}

export default MixpanelTracker;
