// Userflow integration
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import userflow from 'userflow.js';

const propTypes = {
  env: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userAttributes: PropTypes.object.isRequired,
};

function Userflow({
  env, token, userAttributes,
}) {
  useEffect(() => {
    const {
      actingAsUserId,
      actingAsUserType,
      companyId,
      companyName,
      createdAt,
      firstName,
      lastName,
      userEmail,
      userId,
      userType,
    } = userAttributes;

    if (env === 'production' && actingAsUserId) return;

    userflow.init(token);

    userflow.identify(userId, {
      created_at: createdAt,
      email: userEmail,
      first_name: firstName,
      last_name: lastName,
    });

    const role = actingAsUserId ? actingAsUserType : userType;
    userflow.group(companyId,
      { name: companyName },
      { membership: { role } });
  }, [token, userAttributes]);

  return null;
}

Userflow.propTypes = propTypes;

export default Userflow;
