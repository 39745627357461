import React from "react"
import PropTypes from "prop-types"
import NavDropdown from 'components/navigation/NavDropdown'
import { Button, Dropdown, DropdownItem } from  "@duik/it"

class CreativesActions extends React.Component
  @propTypes =
    sortingProps: PropTypes.object

  renderSortOptions: ->
    options = NavDropdown.sanitize(@props.sortingProps.options)
    activeOption = _.findWhere(options, link: @props.sortingProps.selected)

    dropdownProps =
      activeOption: activeOption
      menuPosition: 'bottom-left'
      options: options
      trackingName: 'Sort By Dropdown'

    `<NavDropdown {...dropdownProps} />`

  render: ->
    options = @props.sortingProps.options
    label = _.findWhere(options, value: @props.sortingProps.selected).label

    `<div className='row'>
      <div className='col s12 u-flexRow u-justifyFlexEnd'>
        {this.renderSortOptions()}
      </div>
    </div>`

export default CreativesActions
