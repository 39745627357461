import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Icon,
  MessageModal,
} from '@makeably/creativex-design-system';
import { confirmAccountSetupDisplayAdsAccessIndexPath } from 'utilities/routes';
import styles from './GrantAccess.module.css';

const accountProps = PropTypes.shape({
  advertiserName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
});

const dv360PartnerProps = PropTypes.shape({
  accounts: PropTypes.arrayOf(accountProps).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

const propTypes = {
  accountsByDv360Partner: PropTypes.arrayOf(dv360PartnerProps).isRequired,
};

function renderDv360Partner(dv360Partner) {
  const [showAccounts, setShowAccounts] = useState(false);

  return (
    <div key={dv360Partner.id} className="u-marginTop-16">
      <div className="u-flexRow">
        <div className="t-body-1">{ `DV360 Partner Name: [${dv360Partner.name}]` }</div>
        <button type="button" onClick={() => setShowAccounts(!showAccounts)}>
          <Icon color="grey" name={showAccounts ? 'chevronDown' : 'chevronUp'} />
        </button>
      </div>
      { showAccounts && (
        <ol className={styles.list}>
          {
            dv360Partner.accounts.map((account) => (
              <li key={account.id}>
                { `Advertiser ID: [${account.id}] Name: [${account.advertiserName}]` }
              </li>
            ))
          }
        </ol>
      ) }
    </div>
  );
}

function GrantAccess({ accountsByDv360Partner }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const handleConfirmClick = () => {
    setDisableSubmitButton(true);
    window.location.href = confirmAccountSetupDisplayAdsAccessIndexPath();
  };

  return (
    <>
      <Card>
        <h5>Enable Display Ads Access</h5>
        <div className="t-subtitle u-marginTop-8">
          Follow the steps below to grant CreativeX Partner Access. Additional information can
          be found&nbsp;
          <a href="https://support.google.com/displayvideo/answer/2723011?">here</a>
          .
        </div>
        <div className="t-body-1">
          <div className={styles.steps}>
            <div>
              Step 1. Log into your Display and Video 360 account.
            </div>
            <div>
              Step 2. Select &apos;User Management
              and Email Preferences&apos; from the navigation and then select &apos;User
              Management&apos;.
            </div>
            <div>
              Step 3. Click the &apos;New User&apos; button.
            </div>
            <div>
              Step 4. Add the following into the name and email
              <br />
              &emsp;Name: CreativeX
              <br />
              &emsp;Email: integrations@picassolabs.com
            </div>
            <div>
              Step 5. Click &apos;Add partners or advertisers&apos;, then select each partner
              and/or advertiser you want to grant access to.
            </div>
            <div>
              Step 6. When selecting the &apos;Role&apos;, select &apos;Read Only&apos; access for
              each partner and/or advertiser.
            </div>
            <div>
              Step 7. Click &apos;Save&apos;.
            </div>
            <div>
              Step 8. Navigate back to CreativeX and confirm the connection.
            </div>
          </div>
        </div>
        <div className="u-flexRow u-justifyFlexEnd u-marginAboveLg">
          <Button label="Confirm" onClick={() => setShowConfirmationModal(true)} />
        </div>
      </Card>
      <Card className="u-marginTop-16">
        <h5>Accounts That Need Connecting</h5>
        <div className="u-marginTop-16">
          { accountsByDv360Partner.map((partner) => renderDv360Partner(partner)) }
        </div>
      </Card>
      <MessageModal
        actionButtonDisabled={disableSubmitButton}
        actionButtonLabel="Confirm"
        closeButtonLabel="Cancel"
        isOpen={showConfirmationModal}
        subtitle="Please confirm that all steps have been completed in granting CreativeX Display Ads access to your partners and advertisers"
        title="Completed all of the steps?"
        onActionButtonClick={handleConfirmClick}
        onClose={() => setShowConfirmationModal(false)}
      />
    </>
  );
}

GrantAccess.propTypes = propTypes;

export default GrantAccess;
