import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Icon,
  TextInput,
  Tooltip,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import { findObjectByValue } from 'utilities/array';
import {
  patch,
  post,
} from 'utilities/requests';
import {
  adminCategoriesPath,
  adminCompanyBrandPath,
  adminCompanyBrandsPath,
  editBrandWordsAdminCompanyBrandPath,
  newAdminCompanyBrandPath,
} from 'utilities/routes';

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const propTypes = {
  companyId: PropTypes.number.isRequired,
  brandId: PropTypes.number,
  categoryId: PropTypes.number,
  categoryOptions: PropTypes.arrayOf(optionProps),
  name: PropTypes.string,
  omniBrand: PropTypes.bool,
};

const defaultProps = {
  brandId: undefined,
  categoryId: undefined,
  categoryOptions: [],
  name: '',
  omniBrand: false,
};

async function updateBrand(companyId, brandId, data) {
  const url = adminCompanyBrandPath(companyId, brandId, data);

  const response = await patch(url);
  return response.data;
}

async function createBrand(companyId, data) {
  const url = adminCompanyBrandsPath(companyId);

  const response = await post(url, data);
  return response.data;
}

function BrandConfiguration({
  brandId,
  categoryId,
  categoryOptions,
  companyId,
  name: initialName,
  omniBrand,
}) {
  const [name, setName] = useState(initialName);
  const [isOmniBrand, setIsOmniBrand] = useState(omniBrand);
  const [category, setCategory] = useState(findObjectByValue(categoryOptions, categoryId));

  const onSave = async () => {
    let data;

    const requestData = {
      name,
      omni_brand: isOmniBrand,
      category_id: category?.value,
    };

    if (brandId) {
      data = await updateBrand(companyId, brandId, requestData);

      if (data.success) {
        addToast(data.message, { size: 'large' });
        window.location.href = adminCompanyBrandsPath(companyId);
      } else {
        addToast(data.message, {
          size: 'large',
          type: 'error',
        });
        window.location.reload();
      }
    } else {
      data = await createBrand(companyId, requestData);

      if (data.success) {
        addToast(data.message, { size: 'large' });

        if (isOmniBrand) {
          addToast('Brand words do not apply to omni brands');
          window.location.href = newAdminCompanyBrandPath(companyId);
        } else {
          window.location.href = editBrandWordsAdminCompanyBrandPath(companyId, data.brand_id);
        }
      } else {
        addToast(data.message, {
          size: 'large',
          type: 'error',
        });
        window.location.reload();
      }
    }
  };

  return (
    <Card>
      <div className="u-flexColumn u-gap-16">
        <TextInput
          label="Brand Name"
          size="large"
          value={name}
          onChange={(value) => setName(value)}
        />
        <Checkbox
          checked={isOmniBrand}
          label="Set As Omni Brand"
          onChange={(e) => setIsOmniBrand(e.target.checked)}
        />
        <div className="u-flexColumn u-gap-8">
          <div className="u-flexRow u-gap-16 u-alignEnd">
            <Dropdown
              disabled={categoryOptions.length === 0 || categoryId}
              label="Category"
              menuProps={{ size: 'large' }}
              options={categoryOptions}
              selected={category}
              size="large"
              onChange={(opt) => setCategory(opt)}
            />
            { (!categoryId && category) && (
              <Button
                label="Clear"
                variant="tertiary"
                onClick={() => setCategory(undefined)}
              />
            ) }
          </div>
          <div className="u-flexRow u-alignCenter u-gap-8">
            <Tooltip
              direction="bottom"
              label="If your desired category is missing, click this link to open the Category Management page in a new tab and add it there."
            />
            <a className="u-flexRow u-alignCenter u-gap-8" href={adminCategoriesPath()}>
              <span>Category Manager</span>
              <Icon color="grey" name="externalLink" noWrapper />
            </a>
          </div>
        </div>
        <div>
          <Button
            disabled={!name}
            label={brandId ? 'Save' : 'Create'}
            onClick={onSave}
          />
        </div>
      </div>
    </Card>
  );
}

BrandConfiguration.propTypes = propTypes;
BrandConfiguration.defaultProps = defaultProps;

export default BrandConfiguration;
