import React from 'react';
import PropTypes from 'prop-types';
import { MultipleDropdown } from '@makeably/creativex-design-system';

const optionsProps = PropTypes.objectOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
);

const propTypes = {
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  options: optionsProps.isRequired,
  selections: optionsProps.isRequired,
  onSelect: PropTypes.func.isRequired,
};

function ItemsFilters({
  dimensions,
  options,
  selections,
  onSelect,
}) {
  const renderFilter = (key, label) => (
    <MultipleDropdown
      key={key}
      label={label}
      options={options[key] ?? []}
      selected={selections[key] ?? []}
      size="small"
      onChange={(selected) => onSelect(key, selected)}
    />
  );

  return (
    <>
      { dimensions.map(({ key, label }) => renderFilter(key, label)) }
    </>
  );
}

ItemsFilters.propTypes = propTypes;

export default ItemsFilters;
