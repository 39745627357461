// Helper functions for search

export function autocompleteLabels(objs, search) {
  const find = search.toLowerCase();

  return objs.filter((obj) => {
    const label = obj.label.toLowerCase();
    return label.indexOf(find) !== -1;
  });
}
