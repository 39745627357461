import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  DataVizSkeleton,
  Dropdown,
} from '@makeably/creativex-design-system';
import ItemsBarChart from 'components/molecules/ItemsBarChart';
import { NONE_KEY } from 'components/reporting/utilities';
import { itemProps } from 'utilities/item';
import { track } from 'utilities/mixpanel';
import styles from './MetricVisualization.module.css';

const metricProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});
const propTypes = {
  items: PropTypes.arrayOf(itemProps).isRequired,
  selectedMetrics: PropTypes.arrayOf(metricProps).isRequired,
  selectedSegments: PropTypes.arrayOf(metricProps).isRequired,
  displayMetric: metricProps,
  loading: PropTypes.bool,
  onDisplayMetricChange: PropTypes.func,
};
const defaultProps = {
  displayMetric: undefined,
  loading: false,
  onDisplayMetricChange: undefined,
};

const defaultShown = 10;
const padding = 16;
const perItemHeight = 28;

function getLabelKey(selectedSegments) {
  switch (selectedSegments.length) {
    case 0:
      return NONE_KEY;
    case 1:
      return selectedSegments[0].value;
    default:
      return 'index';
  }
}

function MetricVisualization({
  displayMetric,
  items,
  onDisplayMetricChange,
  selectedMetrics,
  selectedSegments,
  loading,
}) {
  const [allShown, setAllShown] = useState(false);
  const [shownItems, setShownItems] = useState([]);
  const [labelKey, setLabelKey] = useState('');
  const [tooltipKeys, setTooltipKeys] = useState([]);
  const haveMoreItems = items.length > defaultShown;
  const height = padding + (shownItems.length * perItemHeight);

  const mainClasses = classNames(styles.main, {
    [styles.mainWithDropdown]: onDisplayMetricChange,
  });

  useEffect(() => {
    setAllShown(false);
  }, [displayMetric?.value]);

  useEffect(() => {
    const shown = allShown ? items : items.slice(0, defaultShown);
    setShownItems(shown);
  }, [items, allShown]);

  useEffect(() => {
    setTooltipKeys(selectedSegments.map((dim) => dim.value));
    setLabelKey(getLabelKey(selectedSegments));
  }, [selectedSegments]);

  const handleShowAllButtonClick = () => {
    setAllShown((value) => !value);

    track('click_show_all');
  };

  const renderShowControls = () => {
    if (!haveMoreItems) return null;

    const label = allShown ? 'Show Less' : 'Show All';

    return (
      <div className={styles.showControls}>
        <Button
          label={label}
          variant="tertiary"
          onClick={handleShowAllButtonClick}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (items.length === 0 || !displayMetric || loading) {
      return (
        <div className={styles.empty}>
          <DataVizSkeleton animate={loading} />
        </div>
      );
    }

    return (
      <>
        <div className={styles.viz}>
          <div style={{ height: `${height}px` }}>
            <ItemsBarChart
              displayKey={displayMetric?.value}
              items={shownItems}
              labelKey={labelKey}
              tooltipKeys={tooltipKeys}
            />
          </div>
        </div>
        { renderShowControls() }
      </>
    );
  };

  return (
    <div className={mainClasses}>
      { onDisplayMetricChange && (
      <div className={styles.metricControls}>
        <Dropdown
          disabled={selectedMetrics.length === 0}
          label="View By"
          menuProps={{ size: 'large' }}
          options={selectedMetrics}
          placeholder="No Metrics Selected"
          selected={displayMetric}
          size="medium"
          onChange={onDisplayMetricChange}
        />
      </div>
      ) }
      { renderContent() }
    </div>
  );
}

MetricVisualization.propTypes = propTypes;
MetricVisualization.defaultProps = defaultProps;

export default MetricVisualization;
