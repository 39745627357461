import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Test from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
  options: PropTypes.object.isRequired, // eslint-disable-line
  type: PropTypes.string.isRequired,
};

function Chart({
  data,
  options,
  type,
}) {
  const [_, setChart] = useState();

  const updateChart = useCallback((canvas) => {
    if (canvas) {
      const context = canvas.getContext('2d');
      setChart((lastChart) => {
        if (lastChart) {
          lastChart.destroy();
        }

        return new Test(context, {
          data,
          options,
          plugins: [ChartDataLabels],
          type,
        });
      });
    }
  }, [data, options, type]);

  return <canvas ref={updateChart} />;
}

Chart.propTypes = propTypes;

export default Chart;
