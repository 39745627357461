import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  Tooltip,
} from '@makeably/creativex-design-system';

const propTypes = {
  label: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  tooltip: PropTypes.string,
};

const defaultProps = {
  tooltip: undefined,
};

function InputValidation(props) {
  const {
    value,
    success,
    label,
    tooltip,
  } = props;

  let iconColor;
  let iconName;
  let className;
  if (!value) {
    className = 'neutral-feedback';
    iconName = 'check';
  } else if (success) {
    className = 'valid-feedback';
    iconColor = 'green';
    iconName = 'check';
  } else {
    className = 'invalid-feedback';
    iconColor = 'red';
    iconName = 'exclamation';
  }
  return (
    <div className={classNames('u-flexRow', 'u-flexAlignCenter')}>
      <Icon color={iconColor} name={iconName} />
      <span className={classNames(className, 'u-marginHorizontalSm')}>{ label }</span>
      { tooltip && <Tooltip label={tooltip} /> }
    </div>
  );
}

InputValidation.defaultProps = defaultProps;
InputValidation.propTypes = propTypes;

export default InputValidation;
