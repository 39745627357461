// Renders a searchable select
// Search is:
//   * By label
//   * Case insensitive
//   * Finds keyword anywhere in value
import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@duik/it';
import { Tooltip } from '@makeably/creativex-design-system';
import MixpanelTracker from 'components/utils/MixpanelTracker';

export const activeOptionProps = {
  label: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      ...activeOptionProps,
      props: PropTypes.shape({
        Component: PropTypes.string,
        href: PropTypes.string,
      }),
    }),
  ).isRequired,
  activeOption: PropTypes.oneOfType([
    PropTypes.shape(activeOptionProps),
    PropTypes.arrayOf(
      PropTypes.shape(activeOptionProps),
    ),
  ]),
  block: PropTypes.bool,
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    'data-testid': PropTypes.string,
    disabled: PropTypes.bool,
    transparent: PropTypes.bool,
  }),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string,
      tooltipText: PropTypes.string,
    }),
  ]),
  labelTooltip: PropTypes.string,
  // menuPosition options can be found here: https://developer.dashboard-ui.com/docs/react/dropdown#menu-position
  menuPosition: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  onOptionClick: PropTypes.func,
};

export const defaultProps = {
  activeOption: undefined,
  block: false,
  buttonProps: {},
  label: undefined,
  labelTooltip: undefined,
  menuPosition: 'bottom-right',
  multiple: false,
  name: null,
  onOptionClick: undefined,
  placeholder: 'Select...',
  testId: 'SearchableSelect',
};

function renderLabel(label, tooltipText) {
  if (!label) return null;

  let labelText = label;
  let tooltip = tooltipText;

  if (typeof (label) === 'object') {
    tooltip = label.tooltipText;
    labelText = label.text;
  }

  return (
    <div className="u-flexRow">
      <span className="u-marginRightSm">{ labelText }</span>
      { tooltip && <Tooltip label={tooltip} /> }
    </div>
  );
}

class SearchableSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
    };
  }

  onSearch(value) {
    this.setState({ keyword: value });
  }

  onOptionClick(value, name, label) {
    this.setState({ keyword: '' });

    if (label || name) {
      MixpanelTracker.trackFilterSelection(label || name, value.text || value.label);
    }

    const { onOptionClick } = this.props;

    if (onOptionClick) {
      onOptionClick(value, name);
    }
  }

  render() {
    const { keyword } = this.state;
    const {
      activeOption,
      block,
      buttonProps,
      label,
      labelTooltip,
      menuPosition,
      multiple,
      name,
      options,
      placeholder,
      testId,
    } = this.props;

    if (testId) buttonProps['data-testid'] = testId;

    const inputSearchProps = {
      onChange: (event) => this.onSearch(event.target.value),
      value: keyword,
    };
    const filteredOptions = options.filter((option) => (
      option.label.toString().toLowerCase().includes(keyword.toLowerCase())
    ));

    return (
      <Select
        activeOption={activeOption}
        block={block}
        buttonProps={buttonProps}
        inputSearchProps={inputSearchProps}
        label={renderLabel(label, labelTooltip)}
        menuPosition={menuPosition}
        multiple={multiple}
        name={name}
        options={filteredOptions}
        placeholder={placeholder}
        searchable
        onOptionClick={
          (option, selectName) => this.onOptionClick(option, selectName, label)
        }
      />
    );
  }
}

SearchableSelect.propTypes = propTypes;
SearchableSelect.defaultProps = defaultProps;

export default SearchableSelect;
