import React from "react"
import Card from "components/reusable/card"
import Checkbox from "components/reusable/checkbox"
import Column from "components/reusable/column"
import PlotlyChart from "components/reusable/plotly_chart"
import Row from "components/reusable/row"
import PropTypes from "prop-types"

# A component that is responsible for manipulating plotly trace data for display
#
# NB: Only makes sense when you have one trace to display with lots of data, and you want to limit the
#     amount of data on the plot
class PlotlyChartWithControls extends React.Component

  ACTIVE_TRACES = 10

  @propTypes =
    clickable: PropTypes.bool
    deselectedIndices: PropTypes.arrayOf(PropTypes.number) # Indices to initially have deselected
    eventParams: PropTypes.shape(
      data: PropTypes.array # Array of objects where index corresponds to curveNumber
      route: PropTypes.string
    )
    filterableTitle: PropTypes.string
    layout: PropTypes.object
    traces: PropTypes.array.isRequired
    traceKey: PropTypes.string

  @defaultProps =
    deselectedIndices: []
    traceKey: 'y' # where does the label data live? (possible options are 'y', 'x', or 'text')

  constructor: (props) ->
    super(props)

    trace = props.traces[0]
    dataCount = trace[@props.traceKey].length

    activeIndexCount = if dataCount > ACTIVE_TRACES then ACTIVE_TRACES else dataCount

    # use Set type to store active indices. Set type does not have full support in IE,
    # check methods before using...
    # # https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
    # activeIndexSet should always be sorted by indices
    # example initial set (truncated e.g. purposes only): {0, 1, 2, 3, 4...}
    activeIndexSet = new Set()
    for index in [0..activeIndexCount]
      if @props.deselectedIndices.indexOf(index) is -1
        activeIndexSet.add(index)

    @state =
      activeIndexSet: activeIndexSet
      id: _.uniqueId("plotlyChartWithControls-") # Necessary if we have more than one of these components on a page

  gatherDataAtIndices: ->
    plotlyTraces = []
    for originalTrace in @props.traces
      # copy original trace
      traceCopy = _.extend({}, originalTrace)

      # reset array keys to []
      for key in _.keys(traceCopy)
        if _.isArray(traceCopy[key])
          traceCopy[key] = []
          # append values at active indices to array keys in trace
          @state.activeIndexSet.forEach( (index) -> traceCopy[key].push(originalTrace[key][index]) )

      plotlyTraces.push(traceCopy)

    return plotlyTraces

  plotlyProps: ->
    _.extend({}, @props, { traces: this.gatherDataAtIndices() })

  handleCheckboxClick: (value) =>
    value = parseInt(value)
    activeIndexSet = @state.activeIndexSet

    if activeIndexSet.has(value)
      activeIndexSet.delete(value)
    else
      activeIndexSet.add(value)

    sortedActiveIndicesSet = new Set(_.sortBy(Array.from(activeIndexSet), (activeIndex) -> activeIndex))

    @setState(activeIndexSet: sortedActiveIndicesSet)

  gatherFilters: ->
    selectedFilters = [] # selected things
    availableFilters = [] # unselected things

    for _value, index in @props.traces[0][@props.traceKey]
      selected = @state.activeIndexSet.has(index)
      filter = {
        label: @props.traces[0][@props.traceKey][index]
        value: index.toString(),
        selected: selected
      }
      if selected
        selectedFilters.push(filter)
      else
        availableFilters.push(filter)

    # filters should be displayed alphabetically by label
    sortedSelectedFilters = _.sortBy(selectedFilters, 'label')
    sortedAvailableFilters = _.sortBy(availableFilters, 'label')

    return [
      `<div>{sortedSelectedFilters.map(this.renderCheckbox)}</div>`,
      `<div>{sortedAvailableFilters.map(this.renderCheckbox)}</div>`
    ]

  renderCheckbox: (filter) =>
    selectHandler = @handleCheckboxClick
    key = "filter_" + this.state.id + '_' + filter.value
    `<Checkbox
       id={key}
       isChecked={filter.selected}
       key={key}
       label={filter.label}
       onSelect={selectHandler}
       value={filter.value}
     />`

  render: ->
    filters = @gatherFilters()
    `<Row className="plotlyChartWithControls no-margin">

       <Column size={10} className="no-padding">
         <PlotlyChart {...this.plotlyProps()} />
       </Column>

       <Column size={2} className="no-padding">
         <Card
           constrainHeight
           header={"Selected " + this.props.filterableTitle}
           >
           {filters[0]}
         </Card>

         <Card
           constrainHeight
           header={"Unselected " + this.props.filterableTitle}
           >
           {filters[1]}
         </Card>
       </Column>
     </Row>`

export default PlotlyChartWithControls
