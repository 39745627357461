// Returns clickable, removable tags showing which filter dimensions have been selected
// Takes the filter selections and filter dimensions
// Takes remove and click callbacks to allow selections to be removed and the filter
// opened on tag click
import React from 'react';
import PropTypes from 'prop-types';
import { ClickableTag } from '@makeably/creativex-design-system';
import { removeProperty } from 'utilities/object';
import styles from './FilterTags.module.css';

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
});

const optionPropsArray = PropTypes.arrayOf(optionProps);

const propTypes = {
  dimensions: optionPropsArray.isRequired,
  selections: PropTypes.objectOf(optionPropsArray).isRequired,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

const defaultProps = {
  onClick: undefined,
  onRemove: undefined,
};

function FilterTags({
  dimensions,
  onClick,
  onRemove,
  selections,
}) {
  const entries = Object.entries(selections);

  if (entries.length === 0) return null;

  const renderFilterEntry = ([key, values]) => {
    const name = dimensions.find(({ value }) => value === key)?.label ?? '';

    return (
      <ClickableTag
        key={key}
        color="purple"
        label={`${name} (${values.length})`}
        onClick={onClick}
        onRemove={() => onRemove(removeProperty(selections, key))}
      />
    );
  };

  return (
    <div className={styles.tags}>
      { entries.map((entry) => renderFilterEntry(entry)) }
    </div>
  );
}

FilterTags.propTypes = propTypes;
FilterTags.defaultProps = defaultProps;

export default FilterTags;
