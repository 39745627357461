import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleWidget from 'components/molecules/CollapsibleWidget';
import styles from './OcrTags.module.css';

export const propTypes = {
  ocrTags: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.number,
      text: PropTypes.string,
    }),
  ).isRequired,
};

export function renderOcrTag({ startTime, text }, key) {
  return (
    <div key={key} className={styles.ocrTag}>
      <span>{ text }</span>
      <span>{ startTime }</span>
    </div>
  );
}

function OcrTags({ ocrTags }) {
  if (!ocrTags.length > 0) return null;

  return (
    <CollapsibleWidget headerText="OCR Tags">
      { ocrTags.map(renderOcrTag) }
    </CollapsibleWidget>
  );
}

OcrTags.propTypes = propTypes;

export default OcrTags;
