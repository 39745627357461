import React from "react"
import PropTypes from "prop-types"
import { AsyncTypeahead as ReactBootstrapAsyncTypeahead } from "react-bootstrap-typeahead"
import { Typeahead as ReactBootstrapTypeahead } from "react-bootstrap-typeahead"

# This component is responsible for generating a typeahead.
# At this point we are wrapping https://github.com/ericgio/react-bootstrap-typeahead
# View documentation before implementing any new features.
# NB: If inputName is included a hiddenField will be added to support form behavior
class Typeahead extends React.Component
  @propTypes =
    # If a typeahead is being rendered inside an element that has css overflow:hidden
    # (`.card`, for example) the typeahead dropdown may overflow and be hidden. To avoid
    # this, we can append the overlay to the dom, so it exists outside the card context.
    # NB: be aware that this may cause issues if used inside modals, which are already
    #     append to the dom.
    bodyContainer: PropTypes.bool
    formId: PropTypes.string
    formValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array
    ])
    inputName: PropTypes.string
    isLoading: PropTypes.bool
    label: PropTypes.string
    labelKey: PropTypes.string
    multiple: PropTypes.bool
    onChange: PropTypes.func
    onSearch: PropTypes.func
    # Array of objects, need to have at least one key that matches the labelKey
    options: PropTypes.array.isRequired
    paginationText: PropTypes.string
    placeholder: PropTypes.string
    required: PropTypes.bool
    selected: PropTypes.array # Array of values, typically strings

  @defaultProps =
    multiple: true
    labelKey: 'label'
    paginationText: 'Display additional results...'
    placeholder: 'Type to Search'

  constructor: (props) ->
    super(props)
    @id = _.uniqueId("bootstrap-typeahead")

  renderFormInput: ->
    if @props.inputName
      `<input
        name={this.props.inputName}
        form={this.props.formId}
        required={this.props.required}
        type="hidden"
        value={this.props.formValue}
      />`

  renderLabel: ->
    if @props.label
      `<label>{this.props.label}</label>`

  renderTypeahead: ->
    if @props.onSearch
      `<ReactBootstrapAsyncTypeahead id={this.id} {...this.props} />`
    else
      `<ReactBootstrapTypeahead id={this.id} {...this.props} />`

  render: ->
    `<div>
      {this.renderFormInput()}
      {this.renderLabel()}
      {this.renderTypeahead()}
    </div>`

export default Typeahead
