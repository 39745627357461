import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  Dropdown,
  Tooltip,
} from '@makeably/creativex-design-system';
import EvaluationModal from 'components/admin/representation/EvaluationModal';
import AdminAuditFilters from 'components/filters/AdminAuditFilters';
import ItemsTable from 'components/molecules/ItemsTable';
import { sortItemsArray } from 'utilities/array';
import { getItemContent } from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import { adminRepresentationIndexPath } from 'utilities/routes';
import {
  splitSnakeCase,
  titleize,
} from 'utilities/string';
import {
  getParams,
  updateParams,
} from 'utilities/url';
import styles from './Queue.module.css';

const NUM_AUDIT_ASSETS_PER_PAGE = 25;

const propTypes = {
  auditAssets: PropTypes.arrayOf(
    PropTypes.shape({
      assetType: PropTypes.oneOf(['image', 'video']),
      brands: PropTypes.arrayOf(PropTypes.string),
      channels: PropTypes.arrayOf(PropTypes.string),
      createdAt: PropTypes.string,
      id: PropTypes.number,
      markets: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  channelLabelMap: PropTypes.objectOf(PropTypes.string).isRequired,
  contractScope: PropTypes.string.isRequired,
  contractScopes: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateRange: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  noData: PropTypes.bool.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    ),
  ).isRequired,
  selectedDate: PropTypes.string.isRequired,
  unreviewableReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  view: PropTypes.oneOf(['needs_review', 'unreviewable']).isRequired,
  organizationId: PropTypes.number,
};

const defaultProps = {
  organizationId: undefined,
};

const dateCapturedTooltip = 'This reflects the date that the asset was captured by our system';

const dateCapturedHeader = (
  <div className="u-flexRow">
    <span className="u-marginRight-8">Date Captured</span>
    <Tooltip label={dateCapturedTooltip} />
  </div>
);

const headers = [
  {
    key: 'id',
    label: 'Audit Asset ID',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
  {
    key: 'assetType',
    label: 'Asset Type',
  },
  {
    key: 'createdAt',
    label: dateCapturedHeader,
  },
];

const allOption = [{
  label: 'All',
  value: 'all',
}];

const filters = [
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
  {
    key: 'assetType',
    label: 'Asset Type',
  },
];
const filterKeys = filters.map((dim) => dim.key);

function getOrganizations(organizations) {
  return organizations.map(([name, id]) => ({
    label: name,
    value: id,
    url: adminRepresentationIndexPath({ org_id: id }),
  }));
}

function getContractScopes(scopes, params) {
  return scopes.map((scope) => ({
    label: titleize(splitSnakeCase(scope)),
    value: scope,
    url: adminRepresentationIndexPath({
      ...params,
      contract_scope: scope,
      month_start: null,
    }),
  }));
}

function getDateOptions(dates, params) {
  return dates.map(({ label, value }) => ({
    label,
    value,
    url: adminRepresentationIndexPath({
      ...params,
      month_start: value,
    }),
  }));
}

function formatItem(
  {
    assetType,
    brands,
    channels,
    createdAt,
    id,
    markets,
  },
  channelLabelMap,
  openAuditAsset,
) {
  const brand = brands.length > 1 ? 'Multiple' : brands[0];
  const formattedChannels = channels.map((channel) => channelLabelMap[channel]);
  const channel = formattedChannels.length > 1 ? 'Multiple' : formattedChannels[0];
  const market = markets.length > 1 ? 'Multiple' : markets[0];

  return setItemElement({
    assetType: { value: titleize(assetType) },
    brand: {
      value: brand,
      filter: brands,
    },
    channel: {
      value: channel,
      filter: formattedChannels,
    },
    createdAt: { value: createdAt },
    id: {
      display: { button: () => openAuditAsset(id) },
      value: id,
    },
    market: {
      value: market,
      filter: markets,
    },
  });
}

function formatItems(auditAssets, channelLabelMap, openAuditAsset) {
  return auditAssets.map((auditAsset) => (
    formatItem(auditAsset, channelLabelMap, openAuditAsset)
  ));
}

function generateFilterOptions(auditAssets, key) {
  const values = auditAssets
    .map((auditAsset) => auditAsset[key].filter ?? getItemContent(auditAsset, key))
    .flat();
  const sortedUnique = [...new Set(values)].sort();

  return allOption.concat(sortedUnique.map((val) => ({
    label: val,
    value: val,
  })));
}

function generateFilters(auditAssets, currentFilters) {
  return filters.map((filter) => {
    const options = generateFilterOptions(auditAssets, filter.key);

    let activeOption = allOption;
    if (currentFilters[filter.key]) {
      activeOption = options.filter((option) => currentFilters[filter.key].includes(option.value));
    }

    return (
      {
        activeOption,
        label: filter.label,
        field: filter.key,
        options,
        type: 'select',
      }
    );
  });
}

// TODO filter utilities
function parseFilterValues(options) {
  if (!options) return null;
  else if (options.length === 1) {
    return options[0];
  }
  return options.join(';');
}

function updateFilterParams(params, keys, selections) {
  keys.map((key) => {
    const value = parseFilterValues(selections[key]);

    if (value) {
      return params.set(key, value);
    }
    return params.delete(key);
  });
}

function getFilterSelections(params, keys) {
  return keys.reduce((all, key) => {
    const values = params.get(key)?.split(';');

    if (values?.length > 0) {
      return {
        ...all,
        [key]: values,
      };
    }

    return all;
  }, {});
}

function getFilterValues(formRef) {
  const filterValues = [...new FormData(formRef.current).entries()];

  return filterValues.reduce((grouped, entry) => {
    const [type, value] = entry;
    // Brackets in filter name indicate a multi-select filter
    const filterName = type.replace('[]', '');
    const groupedFilters = { ...grouped };
    groupedFilters[filterName] ||= [];
    groupedFilters[filterName].push(value);

    return groupedFilters;
  }, {});
}

function getFilterTest(filterSelections) {
  const filterEntries = Object.entries(filterSelections);

  return (datum) => filterEntries
    .every(([filterKey, filterValues]) => {
      const values = datum[filterKey].filter ?? [getItemContent(datum, filterKey)];
      return filterValues.length > 0 ? values.some((value) => filterValues.includes(value)) : true;
    });
}

function filterItems(items, filterSelections, evaluatedIds) {
  const filterTest = getFilterTest(filterSelections);
  return items.filter((item) => filterTest(item) && !evaluatedIds.includes(item.id.value));
}

function Queue({
  auditAssets,
  channelLabelMap,
  contractScope,
  contractScopes,
  dateRange,
  noData,
  organizationId,
  organizations,
  selectedDate,
  unreviewableReasons,
  view,
}) {
  if (noData) return <div className={styles.noData}>Nothing to see here. This queue is empty!</div>;

  const params = getParams(window);
  const formRef = useRef(null);

  const [currentAuditAssetId, setCurrentAuditAssetId] = useState();
  const [evaluatedIds, setEvaluatedIds] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    key: 'id',
    asc: true,
  });

  const openAuditAsset = (id) => {
    setCurrentAuditAssetId(id);
    setModalOpen(true);
  };

  const items = formatItems(auditAssets, channelLabelMap, openAuditAsset);
  const [filterSelections, setFilterSelections] = useState(getFilterSelections(params, filterKeys));
  const [filteredItems, setFilteredItems] = useState(
    filterItems(items, filterSelections, evaluatedIds),
  );
  const [sortedItems, setSortedItems] = useState(sortItemsArray(filteredItems, sort.key, sort.acs));

  useEffect(() => {
    setFilteredItems(filterItems(items, filterSelections, evaluatedIds));

    // Updating url params when applying filters
    updateFilterParams(params, filterKeys, filterSelections);
    updateParams(params, window);
  }, [filterSelections, evaluatedIds]);

  useEffect(() => {
    setSortedItems(sortItemsArray(filteredItems, sort.key, sort.asc));
  }, [filteredItems, sort]);

  const urlParams = {
    contract_scope: contractScope,
    month_start: selectedDate,
    org_id: organizationId,
    state: view,
  };

  const formattedOrganizations = getOrganizations(organizations);
  const currentOrg = formattedOrganizations.find(({ value }) => value === organizationId);

  const formattedContractScopes = getContractScopes(contractScopes, urlParams);
  const currentContractScope = formattedContractScopes.find(({ value }) => value === contractScope);

  const dateOptions = getDateOptions(dateRange, urlParams);
  const currentDate = dateOptions.find(({ value }) => value === selectedDate);

  const onFilterApply = () => {
    setPage(1);
    setFilterSelections(getFilterValues(formRef));
  };

  const onAuditAssetSaved = (id) => {
    const idIndex = sortedItems.findIndex(({ id: { value } }) => value === id);
    const nextAuditAsset = sortedItems[idIndex + 1];

    if (nextAuditAsset) {
      setCurrentAuditAssetId(nextAuditAsset.id.value);
    } else {
      setModalOpen(false);
    }

    setEvaluatedIds((prevIds) => [...prevIds, id]);
  };

  return (
    <div className={styles.queue}>
      <AdminAuditFilters
        ref={formRef}
        filters={generateFilters(items, filterSelections)}
        loading={false}
        onFormSubmit={onFilterApply}
      />
      <div className="col-12">
        <Card className={styles.card}>
          <div className="u-flexRow u-grid-gap">
            <Dropdown
              disabled={formattedOrganizations.length === 0}
              label="Customer"
              menuProps={{ size: 'large' }}
              options={formattedOrganizations}
              selected={currentOrg}
              size="medium"
              single
            />
            <Dropdown
              label="Contract Scope"
              menuProps={{ size: 'medium' }}
              options={formattedContractScopes}
              selected={currentContractScope}
              size="medium"
              single
            />
            <Dropdown
              label="Date Posted"
              options={dateOptions}
              selected={currentDate}
              single
            />
          </div>
          <Divider />
          <ItemsTable
            className={styles.table}
            emptyTableContent="Nothing to see here. This queue is empty!"
            headers={headers}
            items={sortedItems}
            page={page}
            perPage={NUM_AUDIT_ASSETS_PER_PAGE}
            sort={sort}
            onPageChange={(p) => setPage(p)}
            onSortChange={setSort}
          />
        </Card>
      </div>
      { currentAuditAssetId && (
        <EvaluationModal
          id={currentAuditAssetId}
          modalOpen={modalOpen}
          organization={currentOrg.label}
          unreviewableReasons={unreviewableReasons}
          onAuditAssetSaved={onAuditAssetSaved}
          onClose={() => setModalOpen(false)}
        />
      ) }
    </div>
  );
}

Queue.propTypes = propTypes;
Queue.defaultProps = defaultProps;

export default Queue;
