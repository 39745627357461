import React from "react"
import AssetImage from "components/reusable/asset_image"
import PropTypes from "prop-types"
import classNames from "classnames"

# This component is responsible for displaying a clickable/static card with metrics and an image.
# Why the verbose name you may ask... Named for presentation logic, not business logic.
class ImageCardWithMetrics extends React.Component
  @propTypes =
    # If set to false, the default modal behavior will be removed if present
    allowModal: PropTypes.bool
    clickAction: PropTypes.shape(
      # assetModal action will launch the Asset Modal
      # url action will make the card a link
      # modal action will open a dynamic modal
      action: PropTypes.oneOf(['assetModal', 'url', 'modal'])
      modalId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
      url: PropTypes.string                       # Link to a new location on click?
    ).isRequired
    editable: PropTypes.bool
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired
    image: PropTypes.object
    label: PropTypes.string
    metrics: PropTypes.arrayOf(
      PropTypes.shape(
        comparison: PropTypes.shape(            # Represents how the metric compares to the account's metric.
          class: PropTypes.string.isRequired
          objectLabel: PropTypes.string         # A label for the object we are comparing against
          value: PropTypes.string
        )
        label: PropTypes.string.isRequired
        metric: PropTypes.string.isRequired
        ratio: PropTypes.number
        ratioPostFixDescription: PropTypes.string
        value: PropTypes.oneOfType([            # Value can be a number or string (ex: 'N/A')
          PropTypes.string,
          PropTypes.number
        ]).isRequired
      )
    ).isRequired
    # Function to launch modal. Used when you want to launch the modal by an action other than clicking the card
    modalCallback: PropTypes.func
    size: PropTypes.oneOf(['small', 'default'])

  @defaultProps =
    allowModal: true
    size: 'default'

  classes: ->

    classNames(
      @props.className,
      "imageCardWithMetrics-size--#{@props.size}",
      'imageCardWithMetrics',
      'js-asset-modal': @props.clickAction.action == 'assetModal',
      'no-hover': @props.clickAction.action == 'url' and !@props.clickAction.url,
      'search-asset-card'  # Mooching off of search-asset-card styling. TODO: Remove this dependency.
    )

  # Use this to pass params through
  modalCallback: =>
    if @props.modalCallback
      (e) => @props.modalCallback(e, id: @props.id)

  launchAssetModal: (e) ->
    $elm = $(e.currentTarget)
    modal = new AssetModal($elm)
    modal.launch($elm)

  topLevelProps: ->
    className: @classes()
    id: "asset_#{@props.id}"   # in HTML5, ids must start with a letter

  renderMetricContent: ->
    if @props.modalCallback and @props.allowModal
      if @props.editable
        iconProps =
          className: 'secondaryClick-icon'
          icon: 'pencil'
      else
        iconProps =
          className: 'secondaryClick-icon secondaryClick-icon--small'
          stacked: true
          baseIcon: 'circle-thin'
          topIcon: 'info'

      secondaryClickProps = {
        callback: @modalCallback(),
        iconProps: iconProps
      }

  renderCardContent: ->
    `<div>
      <AssetImage className='imageCardWithMetrics-image' {...this.props.image}/>
      {this.renderMetricContent()}
    </div>`

  renderWithLink: ->
    `<a {...this.topLevelProps()} href={this.props.clickAction.url}>
      {this.renderCardContent()}
    </a>`

  renderWithoutLink: ->
    `<div {...this.topLevelProps()}>
      {this.renderCardContent()}
    </div>`

  renderWithAssetModal: ->
    `<div {...this.topLevelProps()}
          onClick={this.launchAssetModal}
          data-id={this.props.clickAction.modalId}
    >
      {this.renderCardContent()}
    </div>`

  renderWithModal: ->
    `<a {...this.topLevelProps()} data-target={this.props.clickAction.modalId}>
      {this.renderCardContent()}
    </a>`

  render: ->
    if @props.clickAction.action is 'url' and @props.clickAction.url
      @renderWithLink()
    else if @props.clickAction.action is 'assetModal'
      @renderWithAssetModal()
    else if @props.clickAction.action is 'modal'
      @renderWithModal()
    else
      @renderWithoutLink()

export default ImageCardWithMetrics
